import { Grid } from "@mui/material";
import SubscriptionPlan from "../FeePlans/SubscriptionPlan";
import EditManagementFeePage from "./EditManagementFeePage";
import EditPerformanceFeePage from "./EditPerformanceFeePage";

interface Props {
  data: any;
}

const RevenueProductsTab = (props: Props) => {
  const { data } = props;

  const isManagementFee = data?.revenueProducts?.includes("management_fee");
  const isPerformanceFee = data?.revenueProducts?.includes("performance_fee");
  const isSubscriptionFee = data?.revenueProducts?.includes("subscription_fee");

  return (
    <Grid container spacing={1}>
      {isManagementFee && (
        <Grid item xs={12}>
          <EditManagementFeePage />
        </Grid>
      )}

      {isPerformanceFee && (
        <Grid item xs={12} mt={2}>
          <EditPerformanceFeePage />
        </Grid>
      )}

      {isSubscriptionFee && (
        <Grid item xs={12}>
          <SubscriptionPlan />
        </Grid>
      )}
    </Grid>
  );
};

export default RevenueProductsTab;
