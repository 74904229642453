import Loader from "components/Loader";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Suspense, useMemo, useState } from "react";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  chartData: any;
}

const MultiLineChart = ({ chartData }: Props) => {
  const { theme } = ThemeContainer();

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      borderRadius: 5,
      backgroundColor: theme.palette.light.main,
      height: 660,
      spacing: [10, 10, 10, 10],
      marginRight: 50,
      style: {
        fontFamily: "Inter",
      },
    },
    tooltip: {
      formatter(this: any) {
        const formattedDate = Highcharts.dateFormat("%b %e, %Y", this.x);

        let content = `<b>${formattedDate}</b><br/>`;

        this.points.forEach((point: any) => {
          content += `<span style="color:${point.color}">\u25CF</span> ${
            point.series.name
          }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
        });

        return content;
      },
      pointFormatter(this: any) {
        return `<span style="color:${this?.series?.color}">${
          this?.series?.name
        }</span>: ${moneyCommaSeparator(this?.y)}<br/>`;
      },
      valueDecimals: 2,
      split: true,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    },
    title: {
      text: "",
      enabled: false,
      align: "left",
      style: {
        color: theme.palette.light.main,
        fontWeight: "bold",
        fontSize: 18,
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          const { value } = this;
          const absValue = Math.abs(value);
          let newValue = "";
          if (absValue >= 10000000) {
            newValue = `${(value / 10000000).toFixed(2)} Cr`;
          } else if (absValue >= 100000) {
            newValue = `${(value / 100000).toFixed(2)} Lakh`;
          } else if (absValue >= 1000) {
            newValue = `${(value / 1000).toFixed(2)} K`;
          } else {
            newValue = value;
          }
          return newValue;
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: theme.palette.dark.main,
        fontWeight: "bold",
      },
      symbolWidth: 20,
      symbolHeight: 10,
    },
    rangeSelector: {
      enabled: true,
      inputStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      labelStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      buttonTheme: {
        width: 50,
        fill: `#ffffff`,
        style: {
          background: "#ffffff",
          color: theme.palette.grey[600],
          fontWeight: "normal",
          textTransform: "uppercase",
        },
        states: {
          select: {
            fill: `#F8FAFC`,
            style: {
              fontWeight: "normal",
              color: theme.palette.grey[900],
            },
          },
        },
      },
    },
    navigator: {
      enabled: true,
      height: 30, // Adjust the height as needed
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%e of %b, %Y",
        },
      },
    },
    xAxis: {
      type: "date",
      dateTimeLabelFormats: {
        day: "%b %e, %Y",
        week: "%b %e, %Y",
        month: "%b %Y",
        year: "%Y",
      },
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          return Highcharts.dateFormat("%b %e, %Y", this.value);
        },
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  });

  useMemo(() => {
    setChartOptions(() => ({
      ...chartOptions,
      series: chartData?.map((data: any) => {
        return {
          ...data,
          color: theme.palette.primary.main,
        };
      }),
    }));
  }, [chartData]);

  return (
    <Suspense fallback={<Loader />}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Suspense>
  );
};

export default MultiLineChart;
