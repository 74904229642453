/* eslint-disable import/no-named-as-default */
import { Grid, IconButton, Typography } from "@mui/material";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { LuPenSquare } from "react-icons/lu";
import { useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { portfolioGroupDetailsPersist } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import EditPortfolioGroupDetails from "./EditPortfolioGroupDetails";
import { FaChartBar } from "react-icons/fa6";

const PortfolioGroupDetails = () => {
  useDocumentTitle("Portfolio Group Details");

  const { id } = useParams<{ id: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [singleGroupDetails, setSingleGroupDetails] = useState<any>();

  const {
    state: { commonFilter },
  } = useAppContext();

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const requestBody = {
    take: commonFilter?.portfolioGroupHolding?.take,
    skip: commonFilter?.portfolioGroupHolding?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioGroupHolding?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioGroupHolding?.search || "",
    show_zero_holding: commonFilter?.portfolioGroupHolding?.showNonZero,
    filter: JSON.stringify(commonFilter?.portfolioGroupHolding?.customFilter),
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { data: portfolioGroup, isLoading } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_GROUP_LIST, id, requestBody],
    route: `/portfolio-groups/${id}/holdings/`,
    requestBody,
  });

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { data: portfolioGroupData } = useFetchQuery({
    key: ["GET_PORTFOLIOGROUP_BY_ID"],
    route: `/portfolio-groups/${id}/`,
  });

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/portfolio-groups/${id}/holdings`}
        name={"PortfolioGroupHolding"}
        filter={JSON.stringify(
          commonFilter?.portfolioGroupHolding?.customFilter
        )}
      />
    );
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={portfolioGroup?.data?.totalCount || 0}
        name="portfolioGroupHolding"
      />
    );
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { data: portfoliosShortData } = useFetchQuery({
    key: [QueryKeys.PORTFOLIOS_SHORT_DATA],
    route: "/portfolios/all/",
  });

  return (
    <DashboardLayout title={"Portfolio Group Details"}>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={10}>
            <Typography fontWeight={600} fontSize={20}>
              {portfolioGroupData?.data?.name}
            </Typography>
            <Typography
              fontWeight={400}
              color="#697586"
              fontSize={12}
              display={"flex"}
              alignItems={"center"}
            >
              <FaChartBar
                size={16}
                style={{
                  marginRight: 4,
                }}
              />{" "}
              Portfolios
              <span
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: 4,
                }}
              >
                {portfolioGroupData?.data?.portfolios?.length}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <IconButton
              className="outline"
              onClick={() => {
                setSingleGroupDetails(portfolioGroupData?.data);
                setTimeout(() => {
                  setIsModalOpen(true);
                }, 100);
              }}
            >
              <LuPenSquare size={20} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GKDataGrid
            density="compact"
            loading={isLoading}
            rows={portfolioGroup?.data?.data || []}
            getRowId={(row) => row?.isin}
            columns={portfolioGroupDetailsPersist()}
            pinnedColumns={{
              left: ["name"],
            }}
            exportCsv={exportCsv}
            zeroHolding
            CustomPagination={!isLoading && cusPagination}
            name="portfolioGroupHolding"
            filterButton={false}
            tableName="portfolio_group_holding_list"
            headerFilter={<TableFiltering name="portfolioGroupHolding" />}
          />
        </Grid>
      </Grid>

      {isModalOpen && (
        <EditPortfolioGroupDetails
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          portfolioGroup={singleGroupDetails}
          portfoliosShortData={portfoliosShortData?.data || []}
        />
      )}
    </DashboardLayout>
  );
};

export default PortfolioGroupDetails;
