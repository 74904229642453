import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKOtpInput from "components/GKOTPInput";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  optApi: string;
  onSuccess: () => void;
}

const VerifyModal = (props: Props) => {
  const { open, setOpen, optApi, onSuccess } = props;

  const form = useFormContext();

  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState(false);

  const { mutate: handleVerifyOPT } = useMutation({
    mutationFn: (otpCode: any) =>
      axiosInstance.post(`/${optApi}/verify/`, {
        otp: otpCode,
      }),
    onSuccess: (response) => {
      form.setValue("token", response?.data?.data?.token);
      onSuccess();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });

  const { mutate: handleGetOPT, isLoading } = useMutation({
    mutationFn: () => axiosInstance.get(`/${optApi}/resend/`),
  });

  useEffect(() => {
    if (isDoneTyping) handleVerifyOPT(otpCode);
  }, [isDoneTyping]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Verify OTP"
      titleComponent={
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          onClick={() => handleGetOPT()}
        >
          Resend OTP
        </LoadingButton>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GKOtpInput
            value={otpCode}
            onChange={setOtpCode}
            setIsTyping={setIsDoneTyping}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default VerifyModal;
