import { Alert, Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

const ReviewDataTable = () => {
  const form = useFormContext();

  const reviewData = form.getValues("step2")?.invoiceList?.sheetData;

  const { theme } = ThemeContainer();

  const downloadSingleRowErrors = async () => {
    await axiosInstance
      ?.get(
        `/historical-invoice-migrate/${
          form.getValues("fileObject")?.fileKey
        }/error-download/`
      )
      .then((response: any) => {
        const a = document.createElement("a");
        a.href = window?.URL?.createObjectURL(new Blob([response.data]));
        a.download = `historical.csv`;
        a.click();
        a.remove();
      })
      .catch((response: any) => {
        const error = response?.json();
        toast.error(error?.message || "Something went wrong");
      });
  };

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display:
            reviewData?.filter((data: any) => data.error !== "")?.length === 0
              ? "none"
              : "flex",
          justifyContent: "space-between",
        }}
      >
        <Alert
          sx={{
            width: "100%",
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
          severity="error"
          icon={<AiOutlineCloseCircle size={14} />}
        >
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Typography>There are errors found in the import.</Typography>

            <GKButton
              style={{
                marginLeft: 10,
                padding: 0,
                textDecoration: "underline",
              }}
              color="error"
              variant="link"
              onClick={() => {
                downloadSingleRowErrors();
              }}
            >
              Download Errors
            </GKButton>
          </Grid>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <GKClientDataGrid
          name="historicalSheetTable"
          showFiltersButton={false}
          headerTitle={
            <div style={{ marginRight: 8 }}>
              <Typography fontSize={14} fontWeight={600} whiteSpace={"nowrap"}>
                Review Imported data
              </Typography>
              <Typography
                whiteSpace={"nowrap"}
                fontSize={12}
                color={theme?.palette?.grey[700]}
              >
                Remarks: {form.getValues("step2")?.invoiceList?.remarks || 0}
              </Typography>
            </div>
          }
          filterButton={false}
          pagination={false}
          disableRowSelectionOnClick
          maxHeight={"calc(100vh - 500px)"}
          rows={reviewData}
          columns={[
            {
              ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
              editable: false,
              renderCell: (params) => {
                return (
                  <Grid
                    container
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Typography
                      fontWeight={700}
                      maxWidth={"100%"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                    >
                      {params?.row?.clientName}
                    </Typography>
                    <Typography fontSize={12}>
                      {params?.row?.portfolioName} -{" "}
                      {params?.row?.portfolioCode}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              ...extraColumnsField("Fee Plan", "feePlan", 170, 1),
              editable: false,
            },
            {
              ...extraColumnsField("Fee Start Date", "startDate", 150, 1),
              valueFormatter: (params) => matchDate(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Fee End Date", "endDate", 170, 1),
              valueFormatter: (params) => matchDate(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Invoice Date", "invoiceDate", 170, 1),
              valueFormatter: (params) => matchDate(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Invoice Number", "invoiceNumber", 170, 1),
              editable: false,
            },
            {
              ...extraColumnsField("Management Fee", "managementFee", 170, 1),
              editable: false,
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
            },
            {
              ...extraColumnsField("Performance Fee", "performanceFee", 170, 1),
              editable: false,
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
            },
            {
              ...extraColumnsField("SGST", "sgst", 170, 1),
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("CGST", "cgst", 170, 1),
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("IGST", "igst", 170, 1),
              editable: false,
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
            },
            {
              ...extraColumnsField(
                "Fee Collection Date",
                "feeCollectionDate",
                170,
                1
              ),
              valueFormatter: (params) => matchDate(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField(
                "Fee Collection Amount",
                "feeCollectionAmount",
                170,
                1
              ),
              editable: false,
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
            },
            {
              ...extraColumnsField("Source", "source", 170, 1),
              editable: false,
            },
            {
              ...extraColumnsField("Reference", "ref", 170, 1),
              editable: false,
              valueFormatter: (params: any) =>
                moneyCommaSeparator(params?.value),
            },
            {
              ...extraColumnsField("Error", "error", 250, 1),
              editable: false,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ReviewDataTable;
