/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import { Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import GKCheckbox from "components/GKCheckbox";
import GKClientDataGrid from "components/GKClientDataGrid";
import { handleCapitalize } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";

interface Props {
  responseData: ResponseData[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

interface ResponseData {
  id: number;
  value: number;
  holdings: number;
  availableCash: number;
  percentage: number;
  plan: {
    allocation: number;
    amountAllocated: number;
    currentAmount: number;
    deviation: number;
    quantity: number;
    amount: number;
    cmp: number;
    "transaction type": string;
    gridCode: string;
    date: string;
    company: string;
    sector: string;
    isin: string;
    bse: string;
    nse: string;
    status: string;
    "portfolio code": string;
    "portfolio name": string;
  }[];
}

const ModelAllocationCreatOrder = (props: Props) => {
  const {
    responseData,
    freezeData,
    setFreezeData,
    holdData,
    setHoldData,
    form,
  } = props;

  const CompaniesTable = (params: GridRowParams) => {
    const { row } = params;

    const handleChildColumns = (type: string) => {
      switch (type) {
        case "allocation":
          return [
            {
              ...extraColumnsField("Company Name", "company", 150, 1),
              editable: false,
            },
            {
              ...extraColumnsField(
                "Model Weightage",
                "versionWeightage",
                150,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Portfolio Weightage",
                "portfolioWeightage",
                160,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Allocation Weightage",
                "allocationWeightage",
                170,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.allocationWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Transaction Type",
                "transaction type",
                150,
                1
              ),
              valueFormatter: (params) => handleCapitalize(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Quantity", "quantity", 100, 1),
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              valueGetter: (params) => params.row.quantity || "0",
              editable: false,
            },
            {
              ...extraColumnsField("CMP", "cmp", 130, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField("Trade Amount", "amount", 150, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField(
                "After Trade Weightage",
                "afterTradeWeightage",
                200,
                1
              ),
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField("Freeze", "freeze", 150, 1),
              editable: false,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        freezeData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          const selectedData = [
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ];
                          setFreezeData(selectedData);
                        } else {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Freeze</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: "0" }}
                    checked={
                      freezeData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = freezeData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });

                      const filterSameData = freezeData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        }
                      );
                      if (e.target.checked) {
                        setFreezeData([...freezeData, newData]);
                      } else {
                        setFreezeData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
            {
              ...extraColumnsField("Hold", "hold", 150, 1),
              editable: false,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        holdData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Hold</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: 0 }}
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return dataItem?.id !== row?.id;
                        }
                      );

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];
        case "exit":
          return [
            {
              ...extraColumnsField("Company Name", "company", 150, 1),
              editable: false,
            },
            {
              ...extraColumnsField(
                "Model Weightage",
                "versionWeightage",
                150,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Portfolio Weightage",
                "portfolioWeightage",
                160,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            // {
            //   field: "allocation",
            //   headerName: "Allocation  Weightage",
            //   flex: 1,
            //   minWidth: 160,
            //   disableColumnMenu: true,
            //   editable: false,
            //   valueFormatter: (value) => Number(value?.value || 0)?.toFixed(2),
            // },
            {
              ...extraColumnsField(
                "Transaction Type",
                "transaction type",
                150,
                1
              ),
              valueFormatter: (params) => handleCapitalize(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Quantity", "quantity", 100, 1),
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              valueGetter: (params) => params.row.quantity || "0",
              editable: false,
            },
            {
              ...extraColumnsField("CMP", "cmp", 130, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField("Exit Amount", "amount", 150, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField(
                "After Trade Weightage",
                "afterTradeWeightage",
                200,
                1
              ),
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField("Hold", "hold", 80, 1),
              editable: false,
              align: "right",
              headerAlign: "right",
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: 0 }}
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return dataItem?.id !== row?.id;
                        }
                      );

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];
        case "rebalance":
          return [
            {
              ...extraColumnsField("Company Name", "company", 150, 1),
              editable: false,
            },
            {
              ...extraColumnsField(
                "Model Weightage",
                "versionWeightage",
                150,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Portfolio Weightage",
                "portfolioWeightage",
                160,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Deviation Weightage",
                "deviationPercent",
                170,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.deviationPercent || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            // {
            //   field: "allocation",
            //   headerName: "Deviation Qty",
            //   flex: 1,
            //   minWidth: 170,
            //   disableColumnMenu: true,
            //   editable: false,
            //   valueFormatter: (value) => Number(value?.value || 0)?.toFixed(2),
            // },
            {
              ...extraColumnsField("Deviation Amount", "deviation", 170, 1),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {moneyCommaSeparator(Number(value?.row.deviation || 0))}
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Transaction Type",
                "transaction type",
                150,
                1
              ),
              valueFormatter: (params) => handleCapitalize(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Quantity", "Quantity", 100, 1),
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              valueGetter: (params) => params.row.quantity || "0",
              editable: false,
            },
            {
              ...extraColumnsField("CMP", "cmp", 130, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField("Trade Amount", "amount", 150, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField(
                "After Trade Weightage",
                "afterTradeWeightage",
                200,
                1
              ),
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField("Freeze", "freeze", 150, 1),
              editable: false,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        freezeData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Freeze</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      freezeData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = freezeData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });

                      const filterSameData = freezeData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        }
                      );

                      if (e.target.checked) {
                        setFreezeData([...freezeData, newData]);
                      } else {
                        setFreezeData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
            {
              ...extraColumnsField("Hold", "hold", 150, 0),
              editable: false,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        holdData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Hold</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = holdData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });
                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        }
                      );

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterSameData, ...filterData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];

        default:
          return [] as GridColDef[];
      }
    };
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <GKClientDataGrid
          name="modelAllocationCreateOrder"
          pagination={false}
          showHeaderGrid={false}
          density="compact"
          columns={handleChildColumns(form.getValues("allocationType"))}
          rows={
            row?.plan?.map((dataItem: any, i: any) => {
              return {
                ...dataItem,
                id: i,
              };
            }) || []
          }
        />
      </div>
    );
  };

  const handleParentColumnsRetuurn = (type: string) => {
    switch (type) {
      case "exit":
        return [
          {
            ...extraColumnsField(
              "Portfolio Name / Code",
              "portfolioCode",
              0,
              1
            ),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            ...extraColumnsField("Ledger Balance", "ledgerBalance", 0, 1),
            renderCell: (params) => {
              const { ledgerBalance } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
              );
            },
          },
          {
            ...extraColumnsField("Current Holdings", "currentAmount", 0, 1),
            renderCell: (params) => {
              const { currentAmount } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
              );
            },
          },

          {
            ...extraColumnsField("Exit % of holdings", "exitPercentage", 0, 1),
            renderCell: (params) => {
              const { exitWeightage } = params?.row;
              return (
                <Typography>{Number(exitWeightage).toFixed(2)}%</Typography>
              );
            },
          },
          {
            ...extraColumnsField("Exit Amount", "totalSellValue", 0, 1),
            renderCell: (params) => {
              const { totalSellValue } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(totalSellValue)}</Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "After Trade Ledger Balance",
              "afterTradeLedgerBalance",
              0,
              1
            ),
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "rebalance":
        return [
          {
            ...extraColumnsField(
              "Portfolio Name / Code",
              "portfolioCode",
              0,
              1
            ),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            ...extraColumnsField(
              "Current Holding Value",
              "currentAmount",
              0,
              1
            ),
            renderCell: (params) => {
              const { currentAmount } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
              );
            },
          },
          {
            ...extraColumnsField("Ledger Balance", "ledgerBalance", 0, 1),
            renderCell: (params) => {
              const { ledgerBalance } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
              );
            },
          },

          {
            ...extraColumnsField("Net Trade Amount", "Trade Amount", 0, 1),
            renderCell: (params) => {
              const { totalBuyValue, totalSellValue } = params?.row;

              return (
                <Typography>
                  {moneyCommaSeparator(
                    Number(totalBuyValue) - Number(totalSellValue)
                  )}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Rebalance Amount", "rebalanceValue", 0, 1),
            renderCell: (params) => {
              const { rebalanceValue } = params?.row;
              return (
                <Typography>
                  {moneyCommaSeparator(Number(rebalanceValue))}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "After Trade Ledger Balance",
              "afterTradeLedgerBalance",
              0,
              1
            ),
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "allocation":
        return [
          {
            ...extraColumnsField(
              "Portfolio Name / Code",
              "portfolioCode",
              0,
              1
            ),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            ...extraColumnsField("Portfolio Value", "portfolioValue", 0, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.portfolioValue || 0)}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Ledger Balance", "ledgerBalance", 0, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {`${moneyCommaSeparator(params?.row?.ledgerBalance || 0)} [${
                    params?.row?.availableCashPercentage || 0
                  }]`}
                </Typography>
              );
            },
          },

          {
            ...extraColumnsField(
              "Allocation % of Ledger",
              "allocationPercentage",
              0,
              1
            ),
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.percentage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Trade Amount", "amount", 0, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.totalBuyValue)}{" "}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "After Trade Ledger Balance",
              "afterTradeLedgerBalance",
              0,
              1
            ),
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      default:
        return [] as GridColDef[];
    }
  };

  return (
    <GKClientDataGrid
      name="modelAllocationCreateOrder"
      showHeaderGrid={false}
      columns={handleParentColumnsRetuurn(form.getValues("allocationType"))}
      getDetailPanelContent={CompaniesTable}
      rows={
        responseData?.map((data: any, i: number) => {
          return {
            ...data,
            index: i,
          };
        }) || []
      }
    />
  );
};

export default ModelAllocationCreatOrder;
