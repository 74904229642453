import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { FetchDataURL } from "utils/common";
import { QueryKeys } from "utils/queryKey";

interface Props {
  path?: string;
}

export const useClient = ({ path }: Props) => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const [isClientCreateModalOpen, setIsClientCreateModalOpen] = useState(false);

  const requestBody = {
    take: commonFilter?.client?.take,
    skip: commonFilter?.client?.skip,
    sort: JSON.stringify(
      commonFilter?.client?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.client?.search || "",
    show_zero_holding: commonFilter?.client?.showNonZero,
    filter: JSON.stringify(commonFilter?.client?.customFilter),
  };

  const { data: getClientList, isLoading: isClientLoading } = useFetchQuery({
    key: [QueryKeys.CLIENTS_LIST, requestBody],
    retry: 0,
    route: path || FetchDataURL.CLIENTS_LIST,
    requestBody,
  });

  return {
    getClientList: getClientList?.data,
    isClientLoading,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
    commonFilter,
  };
};
