import { DateRangePickerProps } from "@mui/lab";
import { InputLabel, Typography, styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React from "react";
import { ThemeContainer } from "utils/Theme";

interface GKDatePickerProps {
  inputLabel?: string;
  id?: string;
  name?: string;
  requiredField?: boolean;
  error?: boolean;
  helperText?: string;
}

const CustomDatePicker = styled(DateRangePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: "12px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline ": {
    border: "solid 1px",
  },
  "& .MuiOutlinedInput-root": {
    height: "42px !important",
    borderRadius: 8,
    paddingRight: "18px !important",
  },
  "& .MuiFormHelperText-root ": {
    margin: 0,
    color: theme.palette.error.main,
    fontWeight: 400,
  },
}));

const GKDateRangePicker: React.FC<GKDatePickerProps & DateRangePickerProps> = (
  props
) => {
  const { theme } = ThemeContainer();
  const { inputLabel, id, name, requiredField, helperText, error, minDate } =
    props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem
        label={
          inputLabel && (
            <InputLabel
              style={{
                color: "#000000",
                fontSize: "18px",
                fontFamily: "Manrope !important",
                textTransform: "capitalize",
              }}
              shrink
              htmlFor={name || id}
            >
              {inputLabel}
              {requiredField && (
                <span
                  style={{
                    color: theme.palette.error.main,
                  }}
                >
                  *
                </span>
              )}
            </InputLabel>
          )
        }
      >
        <CustomDatePicker
          {...props}
          disableFuture
          minDate={minDate || dayjs("01-01-1980")}
          slots={{ field: SingleInputDateRangeField }}
          sx={{
            marginTop: inputLabel ? "20px !important" : 0,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error
                ? theme.palette.error.main
                : theme.palette.primary.main,
            },
          }}
          format="DD-MM-YYYY"
        />
        {Boolean(helperText) && (
          <Typography
            color={theme.palette.error.main}
            fontSize={12}
            fontWeight={550}
            sx={{
              marginTop: "0 !important",
            }}
          >
            {helperText}
          </Typography>
        )}
      </DemoItem>
    </LocalizationProvider>
  );
};

export default GKDateRangePicker;
