import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";

interface Company {
  value: string;
  advisorCompany: string;
}

interface FormValues {
  mapping: Company[];
}

interface AddCompanyMappingModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
}

const padding = { padding: 16 };

const AddCompanyMappingModal: React.FC<AddCompanyMappingModalProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      mapping: [
        {
          value: "",
          advisorCompany: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mapping",
  });

  const { mutate: addCompanyMapping, isLoading } = useMutation({
    mutationKey: ["ADD_COMPANY_MAPPING"],
    mutationFn: (data: any) =>
      axiosInstance.post("/advisor-company-mappings/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const onSubmit = (values: FormValues) => {
    addCompanyMapping(values.mapping);
  };

  const {
    state: { companies },
  } = useAppContext();

  const { theme } = ThemeContainer();

  return (
    <GKModal
      modalTitle="Add New Company Mapping"
      setOpen={setOpen}
      open={open}
      footer={
        <Grid container justifyContent="space-between">
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => {
              append({
                value: "",
                advisorCompany: "",
              });
            }}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Company
          </GKButton>
          <LoadingButton
            variant="contained"
            type="submit"
            size="medium"
            form="add-company-mapping-form"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Grid>
      }
    >
      <form id="add-company-mapping-form">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={padding}>Company</TableCell>
                    <TableCell style={padding}>Mapping</TableCell>
                    <TableCell style={padding} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ border: "none", minWidth: "200px" }}>
                        <Controller
                          name={`mapping.${index}.value`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <GKSearchSelect
                              {...field}
                              disableClearable
                              inputLabel=""
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Typography
                                    color={theme.palette.light.main}
                                    fontSize={8}
                                    style={{
                                      background:
                                        option?.instType === "EQ"
                                          ? theme.palette.secondary.main
                                          : theme.palette.warning.main,
                                      padding: "2px 4px",
                                      borderRadius: 4,
                                    }}
                                  >
                                    {option?.instType}
                                  </Typography>
                                  <Typography whiteSpace={"nowrap"}>
                                    {option?.name}
                                  </Typography>
                                </li>
                              )}
                              options={companies || []}
                              value={
                                companies?.find(
                                  (data: any) => data?.value === field?.value
                                )?.name
                              }
                              onChange={(e, val) => field.onChange(val?.value)}
                              getOptionLabel={(option) => option.name || ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Company"
                                  placeholder="Select Company"
                                  error={!!error}
                                />
                              )}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`mapping.${index}.advisorCompany`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl variant="standard" fullWidth>
                              <GKTextField
                                {...field}
                                inputLabel=""
                                placeholder="Mapping"
                                error={!!error}
                              />
                            </FormControl>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          className="outline"
                          disabled={fields.length === 1}
                          onClick={() => {
                            remove(index);
                          }}
                          sx={{ ml: "16px" }}
                        >
                          <FiTrash2 />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AddCompanyMappingModal;
