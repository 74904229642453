import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const AssignRoleModal = (props: Props) => {
  const { id } = useParams();

  const { open, setOpen } = props;

  const [roleList, setRoleList] = useState([]);

  const { data } = useFetchQuery({
    key: ["ASSIGN_ROLE_FOR_EMPLOYEE"],
    route: `/employees/${id}/assign_role/`,
  });

  const { isFetching } = useFetchQuery({
    key: ["LIST_OF_ROLE"],
    route: "/role_permissions/",
    success: (response: any) =>
      setRoleList(
        response.data.data
          .filter((dataItem: any) => dataItem.permissionType === "employee")
          .map((role: any) => {
            return {
              name: role.name,
              value: role.id,
            };
          })
      ),
  });

  const { mutate: handleAssignRole, isLoading: assignRoleLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/employees/${id}/assign_role/`, data),
      onSuccess: (response) => {
        setOpen(false);
        refetchQuery(QueryKeys.EMPLOYEE_PERMISSION);
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const form = useForm({
    defaultValues: {
      role: "",
    },
    values: data?.data,
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Assign Role"}
      width={"40%"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            loading={assignRoleLoading}
            type="submit"
            variant="contained"
            size="medium"
            form="assign-role-form"
          >
            Update
          </LoadingButton>
        </Grid>
      }
    >
      <form
        id="assign-role-form"
        onSubmit={form.handleSubmit((values) => handleAssignRole(values))}
      >
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={form.control}
              name="role"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    loading={isFetching}
                    disableClearable
                    onChange={(e, val) => field.onChange(val.value)}
                    inputLabel="Select Role"
                    options={roleList || []}
                    value={
                      roleList?.find((role) => role.value === field.value)
                        ?.name || ""
                    }
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Role" />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AssignRoleModal;
