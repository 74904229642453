import {
  Box,
  Grid,
  IconButton,
  Modal,
  ModalProps,
  Typography,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { ThemeContainer } from "utils/Theme";
import SimpleLoader from "./SimpleLoader";

interface GKModalProps {
  open: boolean;
  setOpen?: any;
  modalTitle: string;
  maxHeight?: string;
  children: any;
  width?: string | number;
  subTitle?: any;
  footer?: any;
  titleComponent?: any;
  closeBtn?: any;
  escClose?: boolean;
  onCloseModal?: any;
  loading?: boolean;
}

const GKModal = (props: ModalProps & GKModalProps) => {
  const { theme } = ThemeContainer();
  const {
    open,
    setOpen,
    children,
    width,
    footer,
    modalTitle,
    titleComponent,
    subTitle,
    closeBtn = true,
    escClose = true,
    onCloseModal,
    loading,
    maxHeight,
  } = props;
  return (
    <Modal
      open={open}
      onClose={() => {
        if (escClose) {
          setOpen(false);
        }
      }}
      {...props}
      style={{
        borderRadius: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 0,
        }}
      >
        <Grid
          container
          width={width || "50%"}
          style={{
            padding: 16,
            borderRadius: 10,
            background: theme.palette.light?.main,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            pb={2}
          >
            <Typography
              color={theme.palette.dark.main}
              fontSize={18}
              fontWeight={700}
              whiteSpace={"nowrap"}
            >
              {modalTitle}
              {subTitle && (
                <Typography
                  color={theme.palette.primary.main}
                  fontSize={14}
                  fontWeight={400}
                >
                  {subTitle}
                </Typography>
              )}
            </Typography>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              {titleComponent}
              {closeBtn && (
                <IconButton
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onCloseModal && onCloseModal();
                    setOpen(false);
                  }}
                  sx={{
                    borderRadius: 6,
                    color: "#50526D",
                    background: "transparent",
                    border: "none",
                    "&:hover": {
                      color: "#50526D",
                      background: "transparent",
                      border: "none",
                    },
                  }}
                >
                  <RxCross2 size={20} />
                </IconButton>
              )}
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            maxHeight={maxHeight || "400px"}
            overflow={"auto"}
            sx={{
              "& .MuiTableContainer-root": {
                width: "unset",
              },
            }}
          >
            {loading ? <SimpleLoader /> : children}
          </Grid>

          {footer && (
            <Grid
              item
              pt={2}
              xs={12}
              justifyContent={"flex-end"}
              display={"flex"}
              gap="10px"
            >
              {footer}
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default GKModal;
