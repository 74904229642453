import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { useFamily } from "hooks/useFamily";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const Family = () => {
  useDocumentTitle("Family");

  const navigate = useNavigate();

  const {
    familyData,
    isInitialLoading,
    isFamilyCreateModalOpen,
    setIsFamilyCreateModalOpen,
  } = useFamily();

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={familyData?.totalCount || 0}
        name="family"
      />
    );
  };

  const exportCsv = () => {
    return <ExportCsv path={"/family"} name={"FamilyList"} />;
  };

  const familyScreenColumns: GridColDef[] = [
    {
      ...extraColumnsField("Family Name", "name", 150, 1),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={familyData?.data || []}
        columns={familyScreenColumns}
        loading={isInitialLoading}
        onRowClick={(value) => navigate(`/app/family/${value.id}`)}
        maxHeight={"calc(100vh - 312px)"}
        CustomPagination={!isInitialLoading && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        zeroHolding
        name="family"
        headerComponent={
          <GKButton
            className="add-new-family"
            variant="contained"
            size="medium"
            onClick={() => setIsFamilyCreateModalOpen(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Family
          </GKButton>
        }
      />
      {isFamilyCreateModalOpen && (
        <FamilyCreateModal
          isModalOpen={isFamilyCreateModalOpen}
          setIsModalOpen={setIsFamilyCreateModalOpen}
        />
      )}
    </>
  );
};

export default Family;
