/* eslint-disable jsx-a11y/media-has-caption */
import { Box, styled, Typography } from "@mui/material";

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 280px)",
}));

interface Props {
  additionalComponent?: any;
  text?: string;
  subText?: string;
}

const GKNoDataFound = ({ additionalComponent, text, subText }: Props) => {
  return (
    <StyledGridOverlay>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={1}
        p={2}
      >
        <img
          alt=""
          height={250}
          src="/LandingPage/noDataImg.svg"
          width={"auto"}
        />
        <Typography fontWeight={600} fontSize={20} color={"#000"}>
          {text || "No Data Found."}
        </Typography>
        <Typography fontWeight={400} fontSize={16} color={"#9AA4B2"}>
          {subText || "Sorry we don't have any related data to show."}
        </Typography>
        <div style={{ marginTop: "16px" }}>{additionalComponent}</div>
      </Box>
    </StyledGridOverlay>
  );
};

export default GKNoDataFound;
