import {
  Card,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Bars from "components/Bars";
import BarChart from "components/Highcharts/BarChart";
import NoData from "components/NoData";
import SimpleLoader from "components/SimpleLoader";
import dayjs from "dayjs";
import { useDashboard } from "hooks/useDashboard";
import DashboardLayout from "layouts/DashboardLayout";
import _ from "lodash";
import useDocumentTitle from "utils/useDocumentTitle";
import CardsCollection from "./CardsCollection";
import HighChartLine from "./HighChartLine";

const Dashboard = () => {
  useDocumentTitle("Dashboard");

  const {
    convertedData,
    AUMData,
    verticalBarData,
    availableCashData,
    chartOptions,
    loading,
    isPortfolioTab,
    setIsPortfolioTab,
    isCurrentTab,
    setIsCurrentTab,
    returnTopTenData,
    theme,
    LineChart,
  } = useDashboard();

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={1}>
        <Grid item container xs={12} spacing={1}>
          {convertedData?.map((item, index) => (
            <Grid
              key={index}
              item
              xs={2.4}
              sx={{
                height: {
                  xs: "25vh",
                  xl: "20vh",
                },
              }}
              display={"flex"}
              position={"relative"}
            >
              <Card
                style={{
                  margin: 0,
                  padding: 8,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "auto",
                  width: "100%",
                  height: "80%",
                }}
                key={item?.id}
              >
                <Grid container spacing={1}>
                  <Typography
                    style={{
                      position: "absolute",
                      width: 50,
                      height: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: `linear-gradient(to right,${theme.palette.primary.main}, ${theme.palette.secondary?.main})`,
                      borderRadius: 10,
                      top: 8,
                      left: 30,
                      boxShadow: `3px 3px 8px -2px ${theme.palette.secondary.main}`,
                    }}
                  >
                    <item.icon
                      color={theme.palette.light?.main}
                      size={24}
                      fontWeight={600}
                    />
                  </Typography>
                  <Grid item xs={12} textAlign={"right"}>
                    <Typography fontWeight={700} fontSize={13}>
                      {item?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />{" "}
                  </Grid>
                  <Grid item xs={12} textAlign={"right"}>
                    <Typography
                      fontWeight={700}
                      fontSize={16}
                      width={"100%"}
                      color={theme.palette.primary.main}
                    >
                      {loading ? (
                        <Skeleton variant="text" animation="wave" />
                      ) : (
                        item?.value
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={8}>
          {/** ----------------------------------------------------------------------------------------------------------- */}
          {loading ? (
            <SimpleLoader />
          ) : (
            <HighChartLine data={LineChart?.data} />
          )}
        </Grid>
        <Grid item xs={4}>
          {/** ----------------------------------------------------------------------------------------------------------- */}
          <CardsCollection data={AUMData?.data?.tickets} isLoading={loading} />
        </Grid>
        {!loading && (
          <Grid item xs={12}>
            {/** Top 10 Assets Under Management --------------------------------------------------------------------------- */}
            <Card>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography
                    fontWeight={600}
                    color={theme.palette.primary.main}
                    fontSize={18}
                  >
                    Top 10 Assets Under Management
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    Showing top 10 AUM for the selected value
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Tabs
                    className="gridkey"
                    variant="fullWidth"
                    value={isPortfolioTab}
                    onChange={(e, val) => {
                      setIsPortfolioTab(val);
                    }}
                  >
                    <Tab disableRipple label="Portfolio" />
                    <Tab disableRipple label="Client" />
                  </Tabs>
                </Grid>
                <Grid item xs={2}>
                  <Tabs
                    className="gridkey"
                    variant="fullWidth"
                    value={isCurrentTab}
                    onChange={(e, val) => {
                      setIsCurrentTab(val);
                    }}
                  >
                    <Tab disableRipple label="Current" />
                    <Tab disableRipple label="Invested" />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {returnTopTenData(isPortfolioTab)?.map(
                    (dataItem: any, index: number) => {
                      return (
                        <Bars
                          key={index}
                          value={
                            isCurrentTab === 0
                              ? dataItem.currentPlusCash
                              : dataItem.investment
                          }
                          name={dataItem?.name}
                          max={_.max(
                            returnTopTenData(isPortfolioTab)?.map(
                              (labelData: any) =>
                                isCurrentTab === 0
                                  ? Math.abs(labelData.currentPlusCash)
                                  : Math.abs(labelData.investment)
                            )
                          )}
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
        {!loading && (
          <Grid container spacing={1} item xs={12}>
            <Grid item xs={12}>
              <Card style={{ margin: 0 }}>
                {/** ----------------------------------------------------------------------------------------------------------- */}
                {!loading && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <BarChart
                        title={"Buy & Sell"}
                        categories={availableCashData?.data?.map(
                          (labelData: any) =>
                            dayjs(labelData[0]).format("MMM YY")
                        )}
                        data={[
                          {
                            name: "Buy",
                            data: verticalBarData?.data?.buySell?.monthly?.map(
                              (labelData: any) => labelData[1]
                            ),
                            color: theme.palette.primary.main,
                          },
                          {
                            name: "Sell",
                            data: verticalBarData?.data?.buySell?.monthly?.map(
                              (labelData: any) => labelData[2]
                            ),
                            color: theme.palette.secondary.main,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ margin: 0 }}>
                {/** ----------------------------------------------------------------------------------------------------------- */}
                {!loading && availableCashData?.data?.length ? (
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Typography
                        fontWeight={600}
                        color={theme.palette.primary.main}
                        fontSize={18}
                      >
                        Monthly Cash Balances
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {availableCashData?.data?.map(
                        (dataItem: any, index: number) => {
                          return (
                            <Bars
                              value={dataItem[1]}
                              key={index}
                              name={dayjs(dataItem[0]).format("MMM YY")}
                              max={_.max(
                                availableCashData?.data?.map((labelData: any) =>
                                  Math.abs(labelData[1])
                                )
                              )}
                            />
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <NoData />
                )}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ margin: 0 }}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography
                      fontWeight={600}
                      color={theme.palette.primary.main}
                      fontSize={18}
                    >
                      Top 10 Cash Balances{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {chartOptions?.chartData?.map(
                      (dataItem: any, index: number) => {
                        return (
                          <Bars
                            value={dataItem.y}
                            name={dataItem.name}
                            key={index}
                            max={_.max(
                              chartOptions?.chartData?.map((labelData: any) =>
                                Math.abs(labelData?.y)
                              )
                            )}
                          />
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ margin: 0 }}>
                {/** Cash InFlow & OutFlow ----------------------------------------------------------------------------------------------------------- */}
                {!loading && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <BarChart
                        title={"Cash Inflow & Outflow"}
                        categories={verticalBarData?.data?.cashInOut?.monthly?.map(
                          (labelData: any) =>
                            dayjs(labelData[0]).format("MMM YY")
                        )}
                        data={[
                          {
                            name: "Cash IN",
                            data: verticalBarData?.data?.cashInOut?.monthly?.map(
                              (labelData: any) => labelData[1]
                            ),
                            color: theme.palette.primary.main,
                          },
                          {
                            name: "Cash Out",
                            data: verticalBarData?.data?.cashInOut?.monthly?.map(
                              (labelData: any) => labelData[2]
                            ),
                            color: theme.palette.secondary.main,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
