import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKDataGrid from "components/GKDataGrid";
import GKSearchSelect from "components/GKSearchSelect";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { CommonFilterState } from "interfaces/CommonFilter";
import { orderBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import {
  extraColumnsField,
  handleDownloadFile,
  matchDate,
} from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import { financialYearFilter } from "../utils";
import SendPaymentModal from "./SendPaymentModal";

interface Props {
  name?: string;
  tableName?: string;
}

const InvoiceTab = (props: Props) => {
  useDocumentTitle("Invoice");

  const { name, tableName } = props;

  // const navigate = useNavigate();
  const { id } = useParams();

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const [invoiceId, setInvoiceId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const currentFilter =
    commonFilter?.[name as keyof CommonFilterState] || commonFilter?.invoice;

  const requestBody = {
    portfolio_id: id,
    take: currentFilter?.take,
    skip: currentFilter?.skip,
    sort: JSON.stringify(
      currentFilter?.sortModel?.map((data: any) => ({
        selector: data.field,
        desc: data.sort !== "asc",
      }))
    ),
    search: currentFilter?.search || "",
    filter: JSON.stringify(currentFilter?.customFilter),
  };

  const {
    data: invoiceHistory,
    isFetching,
    refetch,
  } = useFetchQuery({
    key: ["INVOICE_LIST", requestBody],
    route: "/invoice-history/",
    requestBody,
  });

  const { mutate: deleteInvoice, isLoading } = useMutation({
    mutationKey: ["DELETE_INVOICE"],
    mutationFn: (id) => axiosInstance.delete(`/invoice-history/${id}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
      setInvoiceId(null);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name={name || "invoice"}
        totalCount={invoiceHistory?.data?.totalCount || 0}
      />
    );
  };

  const updateFilter = (filterName: string, val: any) => {
    const targetName = filterName || "invoice";
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [targetName]: {
          ...commonFilter[targetName as keyof CommonFilterState],
          customFilter: {
            ...commonFilter[targetName as keyof CommonFilterState].customFilter,
            financialYear: val.value,
            start_date: val.dates.startDate,
            end_date: val.dates.endDate,
          },
        },
      },
    });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Invoice No.", "invoiceNumber", 150, 1),
    },
    {
      ...extraColumnsField("Date", "invoiceDate", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.invoiceDate).format("D MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Amount", "totalAmount", 150, 1),
      valueGetter: (row) => row?.row?.totalAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
    },
    {
      ...extraColumnsField("Proforma No.", "proformaNumber", 150, 1),
    },
    {
      ...extraColumnsField("Payment Status", "isPayment", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.isPayment.toString()} />
      ),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <Grid container gap={1} display={"flex"} justifyContent={"flex-end"}>
          {/* <Tooltip title="Send Payment Link">
            <IconButton
              disabled={
                params?.row?.isPayment === "completed" ||
                params?.row?.isRazorpaySend
              }
              onClick={(event) => {
                event.stopPropagation();
                setInvoiceId(params.row.id);
                setOpenPaymentModal(true);
              }}
            >
              <FiSend size={20} />
            </IconButton>
          </Tooltip> */}

          <Tooltip title="Download Invoice.">
            <IconButton
              onClick={() => {
                handleDownloadFile({
                  link: params?.row?.invoicePdf,
                  name: params?.row?.invoiceName,
                });
              }}
            >
              <MdOutlineFileDownload size={20} />
            </IconButton>
          </Tooltip>
          <IconButton
            className="outline"
            color="error"
            disabled={
              (params.row.id === invoiceId && isLoading) ||
              params.row.isPayment === "completed"
            }
            onClick={(event) => {
              event.stopPropagation();
              setInvoiceId(params.row.id);
              setConfirmationModal(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={invoiceHistory?.data?.data || []}
        maxHeight={"calc(100vh - 325px)"}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        name={name || "invoice"}
        tableName={tableName || "invoice_list"}
        headerComponent={
          <Grid
            container
            justifyContent={"flex-end"}
            gap={1}
            display={"flex"}
            alignItems={"center"}
          >
            {/* {!id && (
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => navigate("/app/invoice/payment-link")}
              >
                Payment Links
              </GKButton>
            )} */}
            <Grid item xs={4}>
              <GKSearchSelect
                size="small"
                loading={isFetching}
                options={orderBy(financialYearFilter, "name", "desc")}
                value={
                  financialYearFilter?.find(
                    (dataItem) =>
                      dataItem.value ===
                      (name
                        ? commonFilter?.[name as keyof CommonFilterState]
                            ?.customFilter?.financialYear
                        : commonFilter?.invoice?.customFilter?.financialYear)
                  )?.name
                }
                onChange={(e, val) => {
                  if (name) {
                    updateFilter(name, val);
                  } else {
                    updateFilter("invoice", val);
                  }
                }}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Select Financial Year"}
                  />
                )}
              />
            </Grid>
          </Grid>
        }
        headerFilter={<TableFiltering name={name || "invoice"} />}
      />

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteInvoice(invoiceId)}
          title={"Invoice"}
          isDelete
        />
      </Show.When>

      <Show.When isTrue={openPaymentModal}>
        <SendPaymentModal
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          invoiceId={invoiceId}
        />
      </Show.When>
    </>
  );
};

export default InvoiceTab;
