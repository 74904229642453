import { Alert, Button, Divider, Grid } from "@mui/material";
import GKTypography from "./GKTypography";

const AllComponents = () => {
  return (
    <Grid container spacing={1} p={1}>
      <Grid item xs={12}>
        <GKTypography width={"10%"}>Buttons</GKTypography>
        <Divider />
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Button Size</GKTypography>
        <Button variant="contained" size="large">
          Link Button
        </Button>
        <Button variant="contained" size="medium">
          Secondary Button
        </Button>
        <Button variant="contained" size="small">
          Landing Page Button
        </Button>
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Rest Type</GKTypography>
        <Button variant="link">Link Button</Button>
        <Button variant="outlined" color="dark">
          Secondary Button
        </Button>
        <Button variant="landingBtn">Landing Page Button</Button>
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Light Buttons</GKTypography>
        <Button variant="lightBg" color="error">
          Light Error
        </Button>
        <Button variant="lightBg" color="info">
          Light Info
        </Button>
        <Button variant="lightBg" color="primary">
          Light Primary
        </Button>
        <Button variant="lightBg" color="secondary">
          Light Secondary
        </Button>
        <Button variant="lightBg" color="success">
          Light Success
        </Button>
        <Button variant="lightBg" color="warning">
          Light Warning
        </Button>
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Text Buttons</GKTypography>
        <Button variant="text" color="error">
          Outline Error
        </Button>
        <Button variant="text" color="info">
          Outline Info
        </Button>
        <Button variant="text" color="primary">
          Outline Primary
        </Button>
        <Button variant="text" color="secondary">
          Outline Secondary
        </Button>
        <Button variant="text" color="success">
          Outline Success
        </Button>
        <Button variant="text" color="warning">
          Outline Warning
        </Button>
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Contained Buttons</GKTypography>
        <Button variant="contained" color="error">
          Outline Error
        </Button>
        <Button variant="contained" color="info">
          Outline Info
        </Button>
        <Button variant="contained" color="primary">
          Outline Primary
        </Button>
        <Button variant="contained" color="secondary">
          Outline Secondary
        </Button>
        <Button variant="contained" color="success">
          Outline Success
        </Button>
        <Button variant="contained" color="warning">
          Outline Warning
        </Button>
      </Grid>
      <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
        <GKTypography width={"10%"}>Outline Buttons</GKTypography>
        <Button variant="outlined" color="dark">
          Outline Error
        </Button>
        <Button variant="outlined" color="error">
          Outline Error
        </Button>
        <Button variant="outlined" color="info">
          Outline Info
        </Button>
        <Button variant="outlined" color="primary">
          Outline Primary
        </Button>
        <Button variant="outlined" color="secondary">
          Outline Secondary
        </Button>
        <Button variant="outlined" color="success">
          Outline Success
        </Button>
        <Button variant="outlined" color="warning">
          Outline Warning
        </Button>
      </Grid>

      <Grid item xs={12} my={1}>
        <GKTypography width={"10%"}>Alert</GKTypography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Alert severity="error">This is an error alert — check it out!</Alert>
        <Alert severity="warning">
          This is a warning alert — check it out!
        </Alert>
        <Alert severity="info">This is an info alert — check it out!</Alert>
        <Alert severity="success">
          This is a success alert — check it out!
        </Alert>
      </Grid>
    </Grid>
  );
};

export default AllComponents;
