import {
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import GKFilePreviewCard from "components/GKFilePreviewCard";
import GKTypography from "components/GKTypography";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCapitalize } from "utils/common";
import { getHexaColor } from "utils/constants";
import { handleRemoveUnderscore } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";

interface BasketDataProps {
  name: string;
  code: string;
  id: number;
  minInvestment: number;
  minimumInvestmentValueCheck: boolean;
  investmentPhilosophy: any;
  methodology: any;
  clientRebalanceTime: any;
  feePlans: any[];
  feePlansList: any[];
  productType: any;
  ownerType: string;
  benchmarkName: string;
  managedBy: string;
  investmentStyle: any[];
  riskProfile: string[];
  managementStyle: string[];
  overview: string;
  rationale: string;
  rebalanceTime: string;
  benchmark: string;
  createdOn: string;
  researchFiles: any[];
  volatility: string;
  timePeriod: string;
  published: boolean;
  allowAllocation: boolean;
  allowRebalance: boolean;
  rebalanceReferenceValue: string;
  selectedVersion: number;
  rebalanceReferenceWeightage: boolean;
  allocationReferenceWeightage: boolean;
}

const EquityBasketView = () => {
  useDocumentTitle("Basket View");

  const { id } = useParams();

  const navigate = useNavigate();

  /** ========================================================================================================= */
  const { data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
  });

  const basketData: BasketDataProps = data?.data;

  return (
    <DashboardLayout title="View Equity basket">
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <GKTypography fontWeight={600} fontSize={18}>
            {basketData?.name || "-"}
          </GKTypography>
          <GKTypography fontWeight={400} fontSize={12}>
            Managed by :{" "}
            <span style={{ fontWeight: 600 }}>
              {basketData?.managedBy || "-"}
            </span>
          </GKTypography>
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => {
              navigate(`/app/equity-basket/${id}/edit`);
            }}
          >
            Edit Basket
          </Button>
          {/* &nbsp;
          <Button
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => {
              navigate(`/app/equity-basket/${id}/settings`);
            }}
            sx={{
              minWidth: "unset",
            }}
          >
            <LuSettings size={20} />
          </Button> */}
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      width: "20%",
                      borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={"#9AA4B2"}
                    >
                      Risk Profile
                    </Typography>
                    {basketData?.riskProfile?.map((dataItem) => {
                      return (
                        <Chip
                          style={{
                            borderRadius: 8,
                            padding: "4px 0px",
                            height: "unset",
                            background: `${getHexaColor(dataItem)}22`,
                            color: getHexaColor(dataItem),
                            marginRight: 4,
                          }}
                          label={dataItem || "-"}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                      borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={"#9AA4B2"}
                    >
                      Volatility
                    </Typography>
                    <Typography textTransform={"capitalize"}>
                      {handleCapitalize(
                        `${basketData?.volatility} Volatile` || "-"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                      borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={"#9AA4B2"}
                    >
                      Time Horizon
                    </Typography>
                    <Typography>
                      {handleCapitalize(
                        handleRemoveUnderscore(basketData?.timePeriod || "-")
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                      borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={"#9AA4B2"}
                    >
                      Model Rebalance Frequency
                    </Typography>
                    <Typography>
                      {basketData?.rebalanceTime || "Not yet set"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={"#9AA4B2"}
                    >
                      Benchmark
                    </Typography>
                    <Typography>{basketData?.benchmarkName || "-"}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={7.9} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GKTypography fontWeight={400} fontSize={12} color={"#697586"}>
                Overview
              </GKTypography>
              <GKTypography fontSize={14} fontWeight={500}>
                {basketData?.overview || "-"}
              </GKTypography>
            </Grid>
            <Grid item xs={12}>
              <GKTypography fontWeight={400} fontSize={12} color={"#697586"}>
                Rationale
              </GKTypography>
              <GKTypography fontSize={14} fontWeight={500}>
                {basketData?.rationale || "-"}
              </GKTypography>
            </Grid>
            <Grid item xs={12}>
              <GKTypography fontWeight={400} fontSize={12} color={"#697586"}>
                Investment Philosophy
              </GKTypography>
              <GKTypography fontSize={14} fontWeight={500}>
                {basketData?.investmentPhilosophy || "-"}
              </GKTypography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight={400}
                fontSize={12}
                color={"#697586"}
                mb={1}
              >
                Investment Strategies
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 4,
                }}
              >
                {basketData?.investmentStyle?.map((dataItem) => {
                  return (
                    <Chip
                      style={{
                        borderRadius: 8,
                        padding: "4px 0px",
                        height: "unset",
                      }}
                      label={dataItem || "-"}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3.9}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Typography
                fontWeight={400}
                fontSize={12}
                color={"#697586"}
                mb={1}
              >
                Research File / Fact Sheet / Marketing Material
              </Typography>
              {basketData?.researchFiles?.map((dataItems) => {
                return (
                  <GKFilePreviewCard index={1} data={dataItems} size="-" />
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              borderTop={`1px solid ${paletteLightTheme.palette.border.main}`}
              mt={1}
            >
              <GKTypography fontWeight={400} fontSize={12} color={"#697586"}>
                Fee Plans
              </GKTypography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                {basketData?.feePlansList
                  ?.sort((a, b) => b.isDefault - a.isDefault)
                  ?.map((item, index) => {
                    return (
                      <Card
                        sx={{
                          p: 1,
                          minWidth: "-webkit-fill-available",
                          maxWidth: "250px",
                        }}
                        key={index}
                      >
                        <Grid
                          container
                          justifyContent={"space-between"}
                          flexDirection={"row"}
                          spacing={1}
                        >
                          <Grid item xs={6}>
                            <GKTypography fontWeight={600} fontSize={16}>
                              {item.name || "-"}
                            </GKTypography>
                          </Grid>
                          <Grid item xs={6} textAlign={"right"}>
                            {item?.isDefault && (
                              <Button
                                sx={{
                                  "&:hover": {
                                    cursor: "default",
                                    background:
                                      paletteLightTheme.palette.primary.main,
                                  },
                                }}
                                variant={
                                  item?.isDefault ? "contained" : "outlined"
                                }
                                color={item?.isDefault ? "primary" : "dark"}
                                size="extraSmall"
                              >
                                {item?.isDefault ? "Default" : "Set As Default"}
                              </Button>
                            )}
                          </Grid>
                          {item.managementFee?.fee && (
                            <Grid
                              item
                              xs={5}
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                            >
                              <Typography
                                textTransform={"capitalize"}
                                color={"#9AA4B2"}
                                fontSize={12}
                              >
                                {item.managementFee?.name || "-"}
                              </Typography>
                              <GKTypography fontWeight={600}>
                                {item.managementFee?.fee || "-"}%
                              </GKTypography>
                            </Grid>
                          )}
                          {item.performanceFee?.fee &&
                            item.managementFee?.fee && (
                              <Grid
                                item
                                xs={2}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <div
                                  style={{
                                    border: `1px solid ${paletteLightTheme.palette.border.main}`,
                                    borderRadius: 4,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: 4,
                                  }}
                                >
                                  <CgMathPlus color="#9AA4B2" />
                                </div>
                              </Grid>
                            )}
                          {item.performanceFee?.fee && (
                            <Grid
                              item
                              xs={5}
                              display={"flex"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                            >
                              <Typography
                                textTransform={"capitalize"}
                                color={"#9AA4B2"}
                                fontSize={12}
                              >
                                {item.performanceFee?.name || "-"}
                              </Typography>
                              <GKTypography fontWeight={600}>
                                {item.performanceFee?.fee || "-"}%
                              </GKTypography>
                            </Grid>
                          )}
                        </Grid>
                      </Card>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EquityBasketView;
