/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import { Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import GKCheckbox from "components/GKCheckbox";
import GKClientDataGrid from "components/GKClientDataGrid";
import { handleCapitalize } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";

interface Props {
  responseData: ResponseData[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

interface ResponseData {
  id: number;
  value: number;
  holdings: number;
  availableCash: number;
  percentage: number;
  plan: {
    allocation: number;
    amountAllocated: number;
    currentAmount: number;
    deviation: number;
    quantity: number;
    amount: number;
    cmp: number;
    "transaction type": string;
    gridCode: string;
    date: string;
    company: string;
    sector: string;
    isin: string;
    bse: string;
    nse: string;
    status: string;
    "portfolio code": string;
    "portfolio name": string;
  }[];
}

const PortfolioWiseExit = (props: Props) => {
  const { responseData, holdData, setHoldData } = props;

  const CompaniesTable = (params: GridRowParams) => {
    const { row } = params;

    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <GKClientDataGrid
          name="PortfolioWiseExit"
          pagination={false}
          showHeaderGrid={false}
          density="compact"
          columns={[
            {
              ...extraColumnsField("Company Name", "company", 150, 1),
              editable: false,
            },
            // {
            //   field: "versionWeightage",
            //   headerName: "Model Weightage",
            //   flex: 1,
            //   minWidth: 150,
            //   disableColumnMenu: true,
            //   editable: false,
            //   renderCell: (value) => {
            //     return (
            //       <Typography>
            //         {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
            //       </Typography>
            //     );
            //   },
            // },
            {
              ...extraColumnsField(
                "Portfolio Weightage",
                "portfolioWeightage",
                160,
                1
              ),
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              ...extraColumnsField(
                "Transaction Type",
                "transaction type",
                150,
                1
              ),
              valueFormatter: (params) => handleCapitalize(params?.value),
              editable: false,
            },
            {
              ...extraColumnsField("Quantity", "quantity", 100, 1),
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              valueGetter: (params) => params.row.quantity || "0",
              editable: false,
            },
            {
              ...extraColumnsField("CMP", "cmp", 130, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField("Exit Amount", "amount", 150, 1),
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              ...extraColumnsField(
                "After Trade Weightage",
                "afterTradeWeightage",
                200,
                1
              ),
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },

            {
              ...extraColumnsField("Hold", "hold", 80, 0),
              editable: false,
              align: "right",
              headerAlign: "right",
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData(filterData);
                      }
                    }}
                  />
                );
              },
            },
          ]}
          rows={
            row?.plan?.map((dataItem: any, i: any) => {
              return {
                ...dataItem,
                id: i,
              };
            }) || []
          }
        />
      </div>
    );
  };

  return (
    <GKClientDataGrid
      name="PortfolioWiseExit"
      showHeaderGrid={false}
      columns={[
        {
          ...extraColumnsField("Portfolio Name / Code", "portfolioCode", 0, 1),
          renderCell: (params) => {
            return (
              <div>
                <Typography fontWeight={700} whiteSpace={"nowrap"}>
                  {params?.row?.plan && params?.row?.plan[0][`portfolio name`]}
                </Typography>
                <Typography whiteSpace={"nowrap"}>
                  {params?.row?.plan && params?.row?.plan[0][`portfolio code`]}
                </Typography>{" "}
              </div>
            );
          },
        },

        {
          ...extraColumnsField("Ledger Balance", "ledgerBalance", 0, 1),
          renderCell: (params) => {
            const { ledgerBalance } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
            );
          },
        },
        {
          ...extraColumnsField("Current Holdings", "currentAmount", 0, 1),
          renderCell: (params) => {
            const { currentAmount } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
            );
          },
        },
        // {
        //   field: "exitPercentage",
        //   headerName: "Exit %",
        //   flex: 1,
        //   disableColumnMenu: true,
        //   renderCell: (params) => {
        //     const { exitWeightage } = params?.row;
        //     return <Typography>{Number(exitWeightage).toFixed(2)}%</Typography>;
        //   },
        // },
        {
          ...extraColumnsField("Exit Amount", "totalSellValue", 0, 1),
          renderCell: (params) => {
            const { totalSellValue } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(totalSellValue)}</Typography>
            );
          },
        },
        {
          ...extraColumnsField(
            "After Trade Ledger Balance",
            "afterTradeLedgerBalance",
            0,
            1
          ),
          disableColumnMenu: true,
          valueGetter: (value) => moneyCommaSeparator(value.value),
        },
      ]}
      getDetailPanelContent={CompaniesTable}
      rows={
        responseData?.map((data: any, i: number) => {
          return {
            ...data,
            index: i,
          };
        }) || []
      }
    />
  );
};

export default PortfolioWiseExit;
