export const SCHEDULER_TYPE = [
  { name: "Weekly", value: "Weekly" },
  { name: "Monthly", value: "Monthly" },
  { name: "Quarterly", value: "Quarterly" },
  { name: "Yearly", value: "Yearly" },
];

export const DAYS = [
  { name: "Sunday", value: "Sunday" },
  { name: "Monday", value: "Monday" },
  { name: "Tuesday", value: "Tuesday" },
  { name: "Wednesday", value: "Wednesday" },
  { name: "Thursday", value: "Thursday" },
  { name: "Friday", value: "Friday" },
  { name: "Saturday", value: "Saturday" },
];

export const MONTH = [
  { name: "1st of every month", value: 1 },
  { name: "2nd of every month", value: 2 },
  { name: "3nd of every month", value: 3 },
  { name: "4th of every month", value: 4 },
  { name: "5th of every month", value: 5 },
  { name: "6th of every month", value: 6 },
  { name: "7th of every month", value: 7 },
  { name: "8th of every month", value: 8 },
  { name: "9th of every month", value: 9 },
  { name: "10th of every month", value: 10 },
  { name: "11th of every month", value: 11 },
  { name: "12th of every month", value: 12 },
  { name: "13th of every month", value: 13 },
  { name: "14th of every month", value: 14 },
  { name: "15th of every month", value: 15 },
  { name: "16th of every month", value: 16 },
  { name: "17th of every month", value: 17 },
  { name: "18th of every month", value: 18 },
  { name: "19th of every month", value: 19 },
  { name: "20th of every month", value: 20 },
  { name: "21th of every month", value: 21 },
  { name: "22th of every month", value: 22 },
  { name: "23th of every month", value: 23 },
  { name: "24th of every month", value: 24 },
  { name: "25th of every month", value: 25 },
  { name: "26th of every month", value: 26 },
  { name: "27th of every month", value: 27 },
  { name: "28th of every month", value: 28 },
];
