import { Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { useNavigate } from "react-router-dom";

const BasketSection = () => {
  const navigate = useNavigate();

  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={12}
        lg={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        sx={{
          alignItems: {
            xs: "center",
            lg: "flex-start",
          },
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={{
            xs: 24,
            lg: 36,
          }}
          sx={{
            background:
              "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 100%)",
            WebkitBackgroundClip: " text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Wealth Basket{" "}
        </Typography>
        <Typography
          pt={3}
          fontSize={{
            xs: 14,
            lg: 18,
          }}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          Offers model baskets with unique investment strategies and increase
          revenue{" "}
        </Typography>
        <Typography
          py={2}
          fontSize={{
            xs: 12,
            lg: 16,
          }}
          color={"#999999"}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          Designed for Portfolio Managers, Fund Managers, RIA, RA, PMS, AIF,
          Venture Capital & Angel Funds to get new clients and grow AUM
          effortlessly.{" "}
        </Typography>
        <GKButton
          sx={{
            my: 3,
          }}
          variant="landingBtn"
          onClick={() => {
            navigate("/product/wealth-basket");
          }}
        >
          Read More
        </GKButton>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src={"/LandingPage/basketSection.svg"}
          alt=""
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BasketSection;
