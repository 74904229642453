import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import dayjs from "dayjs";
import useAddEmployee from "hooks/useAddEmployee";
import { Controller } from "react-hook-form";

const AddEmployee = ({ open, setOpen, refetch }: any) => {
  const { isSubmitting, form, onSubmit } = useAddEmployee({
    setOpen,
    refetch,
  });

  form.watch();

  return (
    <GKModal
      modalTitle="Add New Admin"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid item xs={12} textAlign={"right"}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            form="employee-form"
            loading={isSubmitting}
          >
            {"Submit"}
          </LoadingButton>
        </Grid>
      }
    >
      <form id="employee-form" onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.name"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Name"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.email"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Email"
                    placeholder="Email"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.phone"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    requiredField
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("user.countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("user.countryCode")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="position"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Position" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="salary"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} type="number" inputLabel="Salary" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.aadhar"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Aadhar Card"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.dateOfBirth"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Date of Birth"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.emergencyContact"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Emergency Contact Name" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.emergencyContactNum"
                control={form.control}
                rules={{
                  minLength: {
                    value: 10,
                    message: "Minimum length is 10 characters",
                  },
                  maxLength: {
                    value: 10,
                    message: "Maximum length is 10 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Emergency Contact Number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AddEmployee;
