import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

export const useCashManagement = () => {
  const navigate = useNavigate();

  const {
    state: { commonFilter, userData: userInfo },
  } = useAppContext();

  const [isPortfolioCash, setPortfolioCash] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<any>([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const [openCashModal, setOpenCashModal] = useState<boolean>(false);

  const requestBody = {
    take: commonFilter?.bankStatement?.take,
    skip: commonFilter?.bankStatement?.skip,
    sort: JSON.stringify(
      commonFilter?.bankStatement?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.bankStatement?.search || "",
    filter: JSON.stringify(commonFilter?.bankStatement?.customFilter),
  };

  const {
    data: cashTransactions,
    refetch: cashTransactionsRefetch,
    isFetching: isCashTransactionLoading,
  } = useFetchQuery({
    route: "/transaction_cash/",
    key: [QueryKeys.CASH_TRANSACTIONS_LIST, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete, isLoading: deleteLoading } = useMutation({
    mutationFn: (ids: any) =>
      axiosInstance?.delete(`/transaction_cash/bulk_delete/`, {
        data: { ids: ids },
      }),
    onSuccess: (response) => {
      cashTransactionsRefetch();
      refetchQuery(QueryKeys.CASH_TRANSACTIONS_LIST);
      refetchQuery(QueryKeys.PORTFOLIO_CASH_LIST);
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return {
    isPortfolioCash,
    setPortfolioCash,
    cashTransactions: cashTransactions?.data,
    isCashTransactionLoading,
    setOpenCashModal,
    openCashModal,
    userInfo,
    navigate,
    commonFilter,
    deleteConfirmation,
    setDeleteConfirmation,
    handleMultiDelete,
    selectedIds,
    setSelectedIds,
    deleteLoading,
  };
};
