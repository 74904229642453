import { Grid } from "@mui/material";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";

interface Props {
  open: boolean;
  submit: any;
  setOpen: any;
  setMappingModal: any;
  setUploadModal: any;
}

const SheetConfirmation = ({
  setOpen,
  open,
  submit,
  setMappingModal,
  setUploadModal,
}: Props) => {
  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Proceed"
      footer={
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <GKButton
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
              setMappingModal(false);
              setUploadModal(false);
              submit?.setFieldValue(`sheetTypeName`, "");
              submit.setFieldValue("file", []);
            }}
          >
            No
          </GKButton>
          <GKButton
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
              setMappingModal(false);
              setUploadModal(false);
              submit.handleSubmit();
            }}
          >
            Yes
          </GKButton>
        </Grid>
      }
    >
      <Grid container>
        <Grid item xs={12} textAlign={"center"}>
          Would you like to proceed with importing the trade data from this
          file?
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default SheetConfirmation;
