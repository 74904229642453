export const statesJson = [
  {
    state: "Andhra Pradesh",
  },
  {
    state: "Arunachal Pradesh",
  },
  {
    state: "Assam",
  },
  {
    state: "Bihar",
  },
  {
    state: "Chandigarh (UT)",
  },
  {
    state: "Chhattisgarh",
  },
  {
    state: "Dadra and Nagar Haveli (UT)",
  },
  {
    state: "Daman and Diu (UT)",
  },
  {
    state: "Delhi (NCT)",
  },
  {
    state: "Goa",
  },
  {
    state: "Gujarat",
  },
  {
    state: "Haryana",
  },
  {
    state: "Himachal Pradesh",
  },
  {
    state: "Jammu and Kashmir",
  },
  {
    state: "Jharkhand",
  },
  {
    state: "Karnataka",
  },
  {
    state: "Kerala",
  },
  {
    state: "Lakshadweep (UT)",
  },
  {
    state: "Madhya Pradesh",
  },
  {
    state: "Maharashtra",
  },
  {
    state: "Manipur",
  },
  {
    state: "Meghalaya",
  },
  {
    state: "Mizoram",
  },
  {
    state: "Nagaland",
  },
  {
    state: "Odisha",
  },
  {
    state: "Puducherry (UT)",
  },
  {
    state: "Punjab",
  },
  {
    state: "Rajasthan",
  },
  {
    state: "Sikkim",
  },
  {
    state: "Tamil Nadu",
  },
  {
    state: "Telangana",
  },
  {
    state: "Tripura",
  },
  {
    state: "Uttarakhand",
  },
  {
    state: "Uttar Pradesh",
  },
  {
    state: "West Bengal",
  },
];
