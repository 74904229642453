import { Card, Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import GKNoDataFound from "components/GKNoDataFound";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import { map } from "lodash";
import { CgMathPlus } from "react-icons/cg";
import { LuMail, LuPhone } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { removeUnderscoreAndFirstLetterCapital } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";

const PaymentGateway = () => {
  useDocumentTitle("Payment Gateway");

  const navigate = useNavigate();

  const { data, isLoading } = useFetchQuery({
    key: ["PAYMENT_GATEWAY_ACCOUNT_LIST"],
    route: "/razorpay-linked-account/",
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <Grid container spacing={1}>
      {data?.data?.data.length === 0 && (
        <Grid item xs={12}>
          <GKNoDataFound
            subText="Setup your payment gateway"
            additionalComponent={
              <GKButton
                variant="contained"
                onClick={() => navigate("/app/connect-payment-gateway")}
                startIcon={<CgMathPlus size={18} />}
              >
                Payment Configure
              </GKButton>
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {map(data?.data?.data, (dataItem: PaymentGatewayFormValues) => {
          return (
            <Card sx={{ p: 2 }}>
              <Grid container>
                <Grid item container xs={8} display={"flex"} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={18}
                      fontWeight={500}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      {dataItem?.beneficiaryName}{" "}
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        padding={"4px 6px"}
                        borderRadius={"4px"}
                        color={
                          dataItem.status === "activated"
                            ? "#15803D"
                            : "#C35245"
                        }
                        bgcolor={
                          dataItem.status === "activated"
                            ? "#F6FCF6"
                            : "#FAF1F0"
                        }
                      >
                        {removeUnderscoreAndFirstLetterCapital(
                          dataItem?.status
                        )}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} gap={1}>
                    <Typography>Account no.</Typography>
                    <Typography fontSize={14} fontWeight={500}>
                      {dataItem?.accountNumber}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} gap={1}>
                    <Typography>Business PAN</Typography>
                    <Typography fontSize={14} fontWeight={500}>
                      {dataItem?.businessPan}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontWeight={500}
                    color={"#697586"}
                  >
                    <LuMail size={16} /> {dataItem?.email}
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontWeight={500}
                    color={"#697586"}
                  >
                    <LuPhone size={16} /> {dataItem?.phone}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default PaymentGateway;
