/* eslint-disable @typescript-eslint/no-unused-expressions */
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKCheckbox from "components/GKCheckbox";
import GKRadio from "components/GKRadio";
import { useFetchQuery } from "hooks/useQueries";
import { EquitySettingDetailsInterface } from "interfaces/EquityBasket";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleReturnFormData } from "utils/formHelpers";
import { refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const EquityBasketSettings = () => {
  useDocumentTitle("Basket Settings");

  const { equityBasketId: id } = useParams();

  /** ========================================================================================================= */
  const form = useForm({
    defaultValues: {
      allocationReferenceWeightage: false,
      rebalanceReferenceWeightage: false,
      rebalanceReferenceValue: "",
      publishPlatform: false,
      allowAllocation: false,
      selectedVersion: null,
      allowRebalance: false,
      published: false,
    },
  });

  /** ========================================================================================================= */
  const { isLoading: basketDetailsLoading } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    success: (response: EquitySettingDetailsInterface) => {
      form.reset({
        allocationReferenceWeightage:
          response?.data?.allocationReferenceWeightage,
        rebalanceReferenceWeightage:
          response?.data?.rebalanceReferenceWeightage,
        rebalanceReferenceValue: response?.data?.rebalanceReferenceValue,
        allowAllocation: response?.data?.allowAllocation,
        selectedVersion: response?.data?.selectedVersion,
        allowRebalance: response?.data?.allowRebalance,
        published: response?.data?.published,
      });
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleUpdateEquityBasket, isLoading: updateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/model_portfolio/${id}/`, data),
      onSuccess: (response) => {
        refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  return (
    <DashboardLayout title="Basket Edit" loading={basketDetailsLoading}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          overflow={"hidden auto"}
          minHeight={`calc(100vh - 129px)`}
          maxHeight={`calc(100vh - 129px)`}
          pr={1}
        >
          <form
            id="equity-basket-meta-form"
            onSubmit={form.handleSubmit((values: any) => {
              handleUpdateEquityBasket(
                handleReturnFormData({
                  obj: values,
                  isEditForm: true,
                  dirtyFields: Object.keys(form.formState.dirtyFields),
                })
              );
            })}
          >
            <Grid container spacing={1}>
              <Grid item xs={3} display={"flex"}>
                {updateLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Controller
                    name="published"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <Switch
                          checked={field.value}
                          onChange={(e) => {
                            handleUpdateEquityBasket(
                              handleReturnFormData({
                                obj: { published: e.target.checked },
                                isEditForm: true,
                                dirtyFields: ["published"],
                              })
                            );
                          }}
                        />
                      );
                    }}
                  />
                )}
                <Typography fontSize={18} fontWeight={600} lineHeight={1}>
                  Publish Basket
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                    <Typography color={"#697586"}>Publish basket on</Typography>
                    <Controller
                      name="publishPlatform"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            label="Micro-Website"
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name="publishPlatform"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            label="Reporting Manager-Admins"
                            disabled
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name="publishPlatform"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            label="Channel Partners"
                            disabled
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name="publishPlatform"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            {...field}
                            disabled
                            label="Gridkey partners"
                            control={
                              <GKCheckbox
                                disableLeftPadding
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign={"end"}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Controller
                          name="allowAllocation"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                {...field}
                                sx={{
                                  "&.MuiFormControlLabel-root .MuiTypography-root":
                                    {
                                      fontSize: 20,
                                    },
                                }}
                                label="Allocation"
                                control={
                                  <GKCheckbox
                                    disableLeftPadding
                                    checked={field.value}
                                    onChange={(event) => {
                                      field.onChange(event.target.checked);
                                    }}
                                  />
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="allocationReferenceWeightage"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControl>
                                <FormLabel>
                                  Allocation Reference Weightage
                                </FormLabel>
                                <RadioGroup {...field}>
                                  <FormControlLabel
                                    value="true"
                                    control={<GKRadio />}
                                    label="Allocated"
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<GKRadio />}
                                    label="Current"
                                  />
                                </RadioGroup>
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} textAlign={"end"}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Controller
                          name="allowRebalance"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                {...field}
                                sx={{
                                  "&.MuiFormControlLabel-root .MuiTypography-root":
                                    {
                                      fontSize: 20,
                                    },
                                }}
                                label="Rebalance"
                                control={
                                  <GKCheckbox
                                    disableLeftPadding
                                    checked={field.value}
                                    onChange={(event) => {
                                      field.onChange(event.target.checked);
                                    }}
                                  />
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="rebalanceReferenceWeightage"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Rebalance Reference Weightage
                                </FormLabel>
                                <RadioGroup {...field}>
                                  <RadioGroup {...field}>
                                    <FormControlLabel
                                      value="true"
                                      control={<GKRadio />}
                                      label="Allocated"
                                    />
                                    <FormControlLabel
                                      value="false"
                                      control={<GKRadio />}
                                      label="Current"
                                    />
                                  </RadioGroup>
                                </RadioGroup>
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="rebalanceReferenceValue"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControl>
                                <FormLabel>Rebalance Reference Value</FormLabel>
                                <RadioGroup {...field}>
                                  <FormControlLabel
                                    value="committedValue"
                                    control={<GKRadio />}
                                    label="Committed Value"
                                  />
                                  <FormControlLabel
                                    value="ledgerBalance"
                                    control={<GKRadio />}
                                    label="Ledger Balance"
                                  />
                                </RadioGroup>
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} textAlign={"end"}>
          <Divider />
        </Grid>
        <Grid item xs={12} textAlign={"end"}>
          <LoadingButton
            type="submit"
            form="equity-basket-meta-form"
            variant="contained"
            size="medium"
            loading={updateLoading}
          >
            {id ? "Save" : "Submit"}
          </LoadingButton>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EquityBasketSettings;
