import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, FormControl, Grid, IconButton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import axios from "axios";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKOtpInput from "components/GKOTPInput";
import GKTextField from "components/GKTextField";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoMdEye } from "react-icons/io";
import { IoEyeOffOutline } from "react-icons/io5";
import axiosInstance from "utils/axiosInstance";
import * as Yup from "yup";

interface Props {
  open: any;
  setOpen: any;
}

const ChangePasswordModal = ({ open, setOpen }: Props) => {
  const {
    state: { userData },
  } = useAppContext();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must meet the requirements."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [verifyResponse, setVerifyResponse] = useState<any>(null);

  const { mutate: handleGetOPT, isLoading } = useMutation({
    mutationFn: () =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/otp/${userData?.countryCode}-${userData?.phone}/send/`
      ),
  });

  const { mutate: handleVerifyOPT } = useMutation({
    mutationFn: (otpCode: any) =>
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/otp/${userData?.countryCode}-${userData?.phone}/login/`,
        {
          otp: otpCode,
        }
      ),
    onSuccess: (response) => {
      setVerifyResponse(response?.data);
      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${response?.data?.token}`;
        return config;
      });
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  form.watch();

  const onSubmit = async (values: any) => {
    await axiosInstance
      .post(`${process.env.REACT_APP_BASE_URL}/user/forgot_password/`, {
        phone: userData?.phone,
        password: values?.password,
        confirm_password: values?.password,
      })
      .then(() => {
        setOpen(false);
        toast.success("Password change successfully");
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
  };

  useEffect(() => {
    if (isDoneTyping) handleVerifyOPT(otpCode);
  }, [isDoneTyping]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Change Password"
      titleComponent={
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          onClick={() => {
            handleGetOPT();
          }}
        >
          Resend OTP
        </LoadingButton>
      }
      footer={
        !verifyResponse ? (
          <GKButton
            color="dark"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Close
          </GKButton>
        ) : (
          <GKButton
            type="submit"
            variant="contained"
            size="medium"
            disabled={!verifyResponse}
            form="OPT-form"
          >
            Change Password
          </GKButton>
        )
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)} id="OPT-form">
        <Grid container spacing={1}>
          {!verifyResponse && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="filled">
                We have sent an OTP to your registered phone number. Please
                verify it to proceed further.
              </Alert>
            </Grid>
          )}
          {!verifyResponse && (
            <Grid item xs={12}>
              <GKOtpInput
                value={otpCode}
                onChange={setOtpCode}
                setIsTyping={setIsDoneTyping}
              />
            </Grid>
          )}
          {verifyResponse && (
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="password"
                  control={form.control}
                  render={({ field, formState: { errors, touchedFields } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Password"
                      type={showPassword ? "text" : "password"}
                      error={
                        Boolean(touchedFields.password) &&
                        Boolean(errors.password)
                      }
                      helperText={
                        Boolean(touchedFields.password) &&
                        errors.password?.message
                      }
                      InputProps={{
                        style: {
                          paddingRight: 12,
                        },
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            style={{
                              padding: 0,
                            }}
                          >
                            {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {verifyResponse && (
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="confirmPassword"
                  control={form.control}
                  render={({ field, formState: { errors, touchedFields } }) => (
                    <GKTextField
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      inputLabel="Confirm Password"
                      placeholder="Confirm Password"
                      error={
                        Boolean(touchedFields.confirmPassword) &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        Boolean(touchedFields.confirmPassword) &&
                        errors.confirmPassword?.message
                      }
                      InputProps={{
                        style: {
                          paddingRight: 12,
                        },
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                            style={{
                              padding: 0,
                            }}
                          >
                            {!showConfirmPassword ? (
                              <IoMdEye />
                            ) : (
                              <IoEyeOffOutline />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>
    </GKModal>
  );
};

export default ChangePasswordModal;
