import { useAppContext } from "AppContext";
import CompanyNameModal from "Pages/CompanyInformation/CompanyNameModal";
import NotFoundPage from "components/ErrorPage";
import Loader from "components/Loader";
import NoInternetWrapper from "components/NoInternetWrapper";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return <NotFoundPage error={error} resetErrorBoundary={resetErrorBoundary} />;
};

const AppLayout = () => {
  const {
    state: { userLoading, isFirstLogin },
    dispatch,
  } = useAppContext();

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {userLoading ? (
          <Loader />
        ) : (
          <NoInternetWrapper>
            <Outlet />
          </NoInternetWrapper>
        )}
      </ErrorBoundary>

      {isFirstLogin && (
        <CompanyNameModal
          open
          setOpen={() => {
            dispatch({ type: "IS_FIRST_LOGIN", data: !isFirstLogin });
          }}
        />
      )}
    </>
  );
};

export default AppLayout;
