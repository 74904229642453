import { Grid, Tab, Tabs } from "@mui/material";
import { Show } from "components/Show";
import { useState } from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import FamilyAdminAccess from "./FamilyAdminAccess";
import FamilyClientAccess from "./FamilyClientAccess";

const AccessTab = () => {
  useDocumentTitle("Family Access");

  const [accessTabs, setAccessTabs] = useState(0);

  const tabs = [
    { label: "Admin Access", component: FamilyAdminAccess },
    { label: "Client Access", component: FamilyClientAccess },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3} display={"flex"} alignItems={"center"}>
          <Tabs
            value={accessTabs}
            variant="standard"
            className="light"
            onChange={(e, val) => {
              setAccessTabs(val);
            }}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} disableTouchRipple />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={accessTabs === index}>
              <tab.component />
            </Show.When>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default AccessTab;
