import { FormControl, Grid, IconButton, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { BANK_ACCOUNT_TYPES } from "utils/constants";
import { QueryKeys } from "utils/queryKey";

const Bank = () => {
  const { id } = useParams<{ id: string }>();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [clientBankId, setClientBankId] = useState(0);

  const { data, refetch, isFetching } = useFetchQuery({
    key: [QueryKeys.CLIENT_BANK_ACCOUNT],
    route: `/client/${id}/banks/`,
  });

  const { mutate: onSubmitHandler } = useMutation({
    mutationKey: ["UPDATE_CLIENT_BANK"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/client/${id}/banks/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CLIENT_BANK"],
    mutationFn: () => axiosInstance.delete(`/client/${clientBankId}/banks/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      bankAccounts: [{ name: "", accountNum: "", type: "", ifsc: "" }],
    },
    values: {
      bankAccounts: data?.data?.bankAccounts.length
        ? data?.data?.bankAccounts.map((dataItem: any) => {
            return {
              ...dataItem,
              rowId: dataItem.id,
            };
          })
        : [{ name: "", accountNum: "", type: "", ifsc: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "bankAccounts",
  });

  return (
    <>
      <form
        id="bank-form"
        onSubmit={form.handleSubmit((values) => onSubmitHandler(values))}
      >
        {isFetching ? (
          <SimpleLoader />
        ) : (
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              {fields?.map((row: any, index) => {
                return (
                  <Grid item container xs={12} spacing={1} my={1} key={row.id}>
                    <Grid item xs={2.6}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`bankAccounts.${index}.name`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              inputLabel="Bank Account Name"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`bankAccounts.${index}.accountNum`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              type="number"
                              inputLabel="Bank Account Number"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`bankAccounts.${index}.type`}
                          control={form.control}
                          render={({ field }) => (
                            <GKSelect {...field} inputLabel="Bank Account Type">
                              {BANK_ACCOUNT_TYPES?.map((dataItem) => (
                                <MenuItem
                                  value={dataItem?.value}
                                  key={dataItem?.value}
                                >
                                  {dataItem?.name}
                                </MenuItem>
                              ))}
                            </GKSelect>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`bankAccounts.${index}.ifsc`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField {...field} inputLabel="IFSC Code" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={1.6}
                      textAlign={"right"}
                      mt={2}
                      alignSelf={"center"}
                    >
                      <IconButton
                        color="error"
                        disabled={fields.length === 1}
                        onClick={() => {
                          if (row?.rowId) {
                            setClientBankId(row?.rowId);
                            setConfirmationModal(true);
                          } else {
                            remove(index);
                          }
                        }}
                      >
                        <FiTrash2 />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={() =>
                  append({
                    name: "",
                    accountNum: "",
                    type: "",
                    ifsc: "",
                  })
                }
              >
                Add Bank{" "}
              </GKButton>
              <GKButton type="submit" variant="contained" size="medium">
                Save
              </GKButton>
            </Grid>
          </Grid>
        )}
      </form>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation()}
          title={"Client Bank"}
          isDelete
        />
      </Show.When>
    </>
  );
};

export default Bank;
