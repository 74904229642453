import { styled } from "@mui/material";
import { ThemeContainer } from "utils/Theme";
import GKTypography from "./GKTypography";

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 330px)",
  gap: 16,
}));

export const CustomLoader = () => {
  const { theme } = ThemeContainer();
  return (
    <StyledGridOverlay>
      <img
        src="/Logos/GridkeyGif.gif"
        alt=" "
        height={100}
        width={100}
        style={{
          filter: `drop-shadow(0px 0px 3px #00000077)`,
        }}
      />
      <GKTypography
        marginTop={1}
        fontWeight={600}
        fontSize={14}
        width={"60%"}
        textAlign={"center"}
        color={theme.palette.dark.main}
      >
        Hold on tight, we are fetching data.
      </GKTypography>
    </StyledGridOverlay>
  );
};

export const Pagination = () => {
  return <div> </div>;
};

export const EmptyData = (props: any) => {
  const { emptyButtons } = props;
  return (
    <StyledGridOverlay>
      <img
        alt=""
        src="/LandingPage/noDataImg.svg"
        height={200}
        width={"auto"}
      />
      <GKTypography fontWeight={600} fontSize={16}>
        No Data Found
      </GKTypography>
      {emptyButtons}
    </StyledGridOverlay>
  );
};
