import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ImportBrokerModal from "Pages/CustomBrokerImport/ImportBrokerModal";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { PiArrowSquareDownRightDuotone } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes, handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const PortfolioSummaryTradeBookTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    state: { commonFilter, userData: userInfo, userData },
  } = useAppContext();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [openImportBrokerModal, setOpenImportBrokerModal] = useState(false);

  const requestBody = {
    take: commonFilter?.portfolioTradeBook?.take,
    skip: commonFilter?.portfolioTradeBook?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioTradeBook?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioTradeBook?.search || "",
    filter: JSON.stringify(commonFilter?.portfolioTradeBook?.customFilter),
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: `/portfolios/${id}/transactions/`,
    key: [QueryKeys.TRANSACTIONS_LIST, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: () =>
      axiosInstance?.delete(`/transaction/bulk_delete/`, {
        data: { ids: selectedIds },
      }),
    onSuccess: (response) => {
      refetch();
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="portfolioTradeBook"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/portfolios/${id}/transactions`}
        name={"portfolioTradeBook"}
        filter={JSON.stringify(commonFilter?.portfolioTradeBook?.customFilter)}
      />
    );
  };

  const transactionColumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      valueGetter: (params) => params?.row?.nseCode || params?.row?.bseCode,
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
      valueFormatter: (params) => handleCapitalize(params?.value),
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
    },
    {
      ...extraColumnsField("Price", "price", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Brokerage", "brokerage", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.brokerage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        190,
        1
      ),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.amountWithBrokerage || "-",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("STT", "stt", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stt || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("GST", "gst", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.gst || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stampCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.transactionCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("SEBI Turnover Fees", "sebiTurnoverFees", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.sebiTurnoverFees || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 190, 1),
      valueGetter: (params) => params.row.contractNoteNum || "-",
      valueFormatter: (params) => params.value,
    },
    {
      ...extraColumnsField("Broker Name", "brokerName", 150, 1),
    },
  ];

  return (
    <>
      <GKDataGrid
        density="compact"
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        searchPlaceholder="Search for Transaction"
        columns={transactionColumns}
        rows={data?.data?.data || []}
        onRowClick={(row) => {
          if (row?.row?.transactionType !== "BUY_BACK") {
            navigate(`/app/edit-transaction/${row?.id}`);
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        CustomPagination={!isFetching && cusPagination}
        exportCsv={exportCsv}
        filterButton={false}
        name="portfolioTradeBook"
        headerComponent={
          userInfo?.userRole !== UserRoleTypes.client && (
            <Grid container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Bulk Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 &&
                userData?.broker?.adityaBirlaSupport && (
                  <GKButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      setOpenImportBrokerModal(true);
                    }}
                    startIcon={<PiArrowSquareDownRightDuotone size={16} />}
                  >
                    Import from broker
                  </GKButton>
                )}
              {selectedIds?.length === 0 && (
                <GKButton
                  onClick={() => {
                    navigate("/app/create-transaction", {
                      state: { id: id },
                    });
                  }}
                  variant="contained"
                  startIcon={<CgMathPlus size={14} />}
                >
                  Add Trade
                </GKButton>
              )}
            </Grid>
          )
        }
        tableName="portfolio_trade_book_list"
        headerFilter={<TableFiltering name="portfolioTradeBook" />}
      />

      <Show.When isTrue={openImportBrokerModal}>
        <ImportBrokerModal
          open={openImportBrokerModal}
          setOpen={setOpenImportBrokerModal}
          refetch={refetch}
          format="ADITYA_BIRLA_MONEY"
        />
      </Show.When>

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete()}
          title={"Bulk Statement"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected stocks & once deleted it cannot be reverted?`}
        />
      )}
    </>
  );
};

export default PortfolioSummaryTradeBookTab;
