/* eslint-disable import/no-named-as-default-member */
import { Card, FormControl, Grid, MenuItem, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import StatusField from "components/StatusField";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { KYC_OPTIONS } from "utils/constants";
import { panRegex } from "utils/validationSchema";

const ClientOnboardingKYC = () => {
  const form = useFormContext();

  const [reUploadKYC, setReUploadKYC] = useState(false);

  return form.getValues("kyc") && !reUploadKYC ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography fontSize={24} fontWeight={600}>
          KYC
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={"right"}>
        <GKButton
          variant="contained"
          onClick={() => {
            setReUploadKYC(true);
          }}
        >
          Request KYC
        </GKButton>
      </Grid>
      <Grid item xs={5}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontWeight={600} fontSize={20}>
                {form.getValues("kyc.name")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <StatusField statusString={form.getValues("kyc.kycStatus")} />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={1}
            >
              <Typography fontWeight={400} fontSize={14} color={"#475467"}>
                PAN :
              </Typography>
              <Typography fontWeight={600} fontSize={14}>
                {form.getValues("kyc.pan")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={1}
            >
              <Typography fontWeight={400} fontSize={14} color={"#475467"}>
                KYC ID :
              </Typography>
              <Typography fontWeight={600} fontSize={14}>
                {form.getValues("kyc.kycId")}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography fontSize={24} fontWeight={600}>
          KYC{" "}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Please enter PAN",
              },
              pattern: {
                value: panRegex,
                message: "PAN should have this format ABCDE1234A",
              },
              maxLength: {
                value: 10,
                message: "PAN should have 10 character",
              },
              minLength: {
                value: 10,
                message: "PAN should have 10 character",
              },
            }}
            name="personalDetails.pan"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <GKTextField
                {...field}
                inputLabel="PAN"
                requiredField
                error={!!error}
                onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                helperText={error?.message}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="identification_type"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please select identification type",
              },
            }}
            render={({ field, formState: { errors } }) => (
              <GKSelect
                {...field}
                requiredField
                inputLabel="KYC Type"
                error={Boolean(errors.identification_type)}
              >
                <MenuItem style={{ display: "none" }} value="">
                  Select Identification Type
                </MenuItem>
                {KYC_OPTIONS?.map((data) => (
                  <MenuItem value={data?.value} key={data?.value}>
                    {data?.name}
                  </MenuItem>
                ))}
              </GKSelect>
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ClientOnboardingKYC;
