import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useAgreement = () => {
  const { id } = useParams();

  const {
    data: getAgreement,
    refetch,
    isLoading: isAgreementLoading,
  } = useFetchQuery({
    key: [QueryKeys.AGREEMENT_LIST],
    enabled: Boolean(id),
    route: `/agreements/${id}/`,
  });
  return { getAgreement, isAgreementLoading, refetch };
};
