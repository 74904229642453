import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKTextField from "components/GKTextField";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import _ from "lodash";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { permissions } from "utils/permission";

interface Permission {
  permission: string;
  subHeading: string;
  isExist?: string[];
}

interface PermissionGroup {
  mainHeading: string;
  subPermission: Permission[];
}

interface FormData {
  name: string;
  permissions: {
    [key: string]: boolean;
  };
}

const CreateRoleWisePermission: React.FC = () => {
  const { id, role } = useParams();
  const navigate = useNavigate();

  const { data, isLoading: roleByIdLoading } = useFetchQuery({
    key: ["ROLE_BY_ID", id],
    route: `/role_permissions/${id}/`,
    enabled: !!id,
  });

  const { mutate: createRole, isLoading } = useMutation({
    mutationFn: (data: FormData) =>
      axiosInstance.post(`/role_permissions/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(-1);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: updateRole, isLoading: updateRoleLoading } = useMutation({
    mutationFn: (data: FormData) =>
      axiosInstance.patch(`/role_permissions/${id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(-1);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const defaultValues: FormData = {
    name: "",
    permissions: _.reduce(
      permissions,
      (acc: { [key: string]: boolean }, permissionGroup: PermissionGroup) => {
        _.forEach(permissionGroup.subPermission, (permission) => {
          acc[permission.permission] = false;
        });
        return acc;
      },
      {}
    ),
  };

  const { control, setValue, watch, handleSubmit } = useForm<FormData>({
    defaultValues,
    values: data?.data,
  });

  const handleChangePermission = (permission: string, value: boolean) => {
    setValue(`permissions.${permission}` as const, value);
  };

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (id) {
      updateRole(data);
    } else {
      createRole(data);
    }
  };

  const handleToggleAllPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    permissionGroup: PermissionGroup
  ) => {
    const filteredPermissions = _.filter(permissionGroup.subPermission, (x) =>
      role === "client"
        ? _.includes(x.isExist, "CLIENT")
        : role === "employee"
        ? _.includes(x.isExist, "EMPLOYEE")
        : _.includes(x.isExist, "PARTNER")
    );

    _.forEach(filteredPermissions, (permission) => {
      setValue(
        `permissions.${permission.permission}` as const,
        e.target.checked
      );
    });
  };

  return (
    <DashboardLayout title="Create Role" loading={!!id && roleByIdLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel={id ? "Edit Role Name" : "Enter Role Name"}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {_.map(
            _.filter(permissions, (x) =>
              role === "client"
                ? _.includes(x.isExist, "CLIENT")
                : role === "employee"
                ? _.includes(x.isExist, "EMPLOYEE")
                : _.includes(x.isExist, "PARTNER")
            ),
            (dataItem, index) => {
              const filteredSubPermissions = _.filter(
                dataItem.subPermission,
                (x) =>
                  role === "client"
                    ? _.includes(x.isExist, "CLIENT")
                    : role === "employee"
                    ? _.includes(x.isExist, "EMPLOYEE")
                    : _.includes(x.isExist, "PARTNER")
              );

              const allPermissionsEnabled = _.every(
                filteredSubPermissions,
                (permission) =>
                  watch(`permissions.${permission.permission}` as const)
              );
              return (
                <Grid container item xs={12} spacing={2}>
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    alignItems={"flex-start"}
                    key={index}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={allPermissionsEnabled}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleToggleAllPermissions(e, dataItem);
                          }}
                        />
                      }
                      label={
                        <Typography fontSize={16} fontWeight={600}>
                          {dataItem.mainHeading}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={9}
                    spacing={2}
                    display={"inline-flex"}
                    alignItems={"center"}
                  >
                    {_.map(
                      _.filter(dataItem.subPermission, (x) =>
                        role === "client"
                          ? _.includes(x.isExist, "CLIENT")
                          : role === "employee"
                          ? _.includes(x.isExist, "EMPLOYEE")
                          : _.includes(x.isExist, "PARTNER")
                      ),
                      (data, subIndex) => (
                        <Grid
                          item
                          xs={6}
                          display={"flex"}
                          alignItems={"center"}
                          key={subIndex}
                        >
                          <Controller
                            name={`permissions.${data.permission}` as const}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                {...field}
                                control={
                                  <Switch
                                    sx={{ marginTop: "1px" }}
                                    checked={field.value}
                                    onChange={(e) =>
                                      handleChangePermission(
                                        data.permission,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={
                                  <div>
                                    <Typography
                                      fontSize={14}
                                      fontWeight={500}
                                      sx={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        // whiteSpace: "nowrap",
                                        width: "100%",
                                      }}
                                    >
                                      {data.subHeading}
                                    </Typography>
                                    {/* <Typography fontSize={12} color="#4B5565">
                                      {"data.subText"}
                                    </Typography> */}
                                  </div>
                                }
                                sx={{
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          mt={2}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading || updateRoleLoading}
            size="medium"
          >
            {id ? "Update" : "Save"}
          </LoadingButton>
        </Grid>
      </form>
    </DashboardLayout>
  );
};

export default CreateRoleWisePermission;
