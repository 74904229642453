import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize, UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const EquityEtf = () => {
  useDocumentTitle("Equity/ETF");

  const navigate = useNavigate();

  const {
    state: { commonFilter, userData: userInfo },
  } = useAppContext();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const requestBody = {
    take: commonFilter?.tradeBook?.take,
    skip: commonFilter?.tradeBook?.skip,
    sort: JSON.stringify(
      commonFilter?.tradeBook?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.tradeBook?.search || "",
    filter: JSON.stringify(commonFilter?.tradeBook?.customFilter),
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: "/transaction/",
    key: [QueryKeys.TRANSACTIONS_LIST, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: () =>
      axiosInstance?.delete(`/transaction/bulk_delete/`, {
        data: { ids: selectedIds },
      }),
    onSuccess: (response) => {
      refetch();
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="tradeBook"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={"/transaction"}
        name={"TransactionList"}
        filter={JSON.stringify(commonFilter?.tradeBook?.customFilter)}
      />
    );
  };

  const transactionColumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.nseCode || params?.row?.bseCode}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
      valueFormatter: (params) => handleCapitalize(params?.value),
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
    },
    {
      ...extraColumnsField("Price", "price", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Brokerage", "brokerage", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.brokerage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        190,
        1
      ),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.amountWithBrokerage || "-",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("STT", "stt", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stt || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("GST", "gst", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.gst || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stampCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.transactionCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("SEBI Turnover Fees", "sebiTurnoverFees", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.sebiTurnoverFees || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 190, 1),
      valueGetter: (params) => params.row.contractNoteNum || "-",
      valueFormatter: (params) => params.value,
    },
    {
      ...extraColumnsField("Broker Name", "brokerName", 150, 1),
    },
  ];

  return (
    <>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        searchPlaceholder="Search for Transaction"
        columns={transactionColumns}
        rows={data?.data?.data || []}
        onRowClick={(row) => {
          if (row?.row?.transactionType !== "BUY_BACK") {
            navigate(`/app/edit-transaction/${row?.id}`);
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        name="tradeBook"
        headerComponent={
          userInfo?.userRole !== UserRoleTypes.client && (
            <Grid container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 && (
                <>
                  <GKButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      navigate("/app/create-transaction");
                    }}
                    startIcon={<CgMathPlus size={14} />}
                  >
                    Add Trade
                  </GKButton>
                </>
              )}
            </Grid>
          )
        }
        tableName="trade_book_list"
        headerFilter={<TableFiltering name="tradeBook" />}
      />

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete()}
          title={"Bulk Statement"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected stocks & once deleted it cannot be reverted?`}
        />
      )}
    </>
  );
};

export default EquityEtf;
