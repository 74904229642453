import { Grid, Skeleton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import ManualEntryModal from "Pages/UploadManager/ManualEntryModal";
import UploadManagerViewUpload from "Pages/UploadManager/UploadManagerViewUpload";
import UploadPopOverActions from "Pages/UploadManager/UploadPopOverActions";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import { useFetchQuery } from "hooks/useQueries";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { IoArchiveOutline } from "react-icons/io5";
import { PiArrowSquareDownRightDuotone } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ImportBrokerModal from "./ImportBrokerModal";

const CustomBrokerImport = () => {
  useDocumentTitle("Import From Broker");
  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const [openImportBrokerModal, setOpenImportBrokerModal] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState<number>();
  const [manualEntryTransactionID, setManualEntryTransactionID] =
    useState<number>();
  const [isManualEntryViewModal, setIsManualEntryViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState<any>();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewFileFormat, setViewFileFormat] = useState<string>();

  const [loadingStates, updateLoadingState] = useReducer(
    (oldPath: any, newStates: any) => ({
      ...oldPath,
      ...newStates,
    }),
    {
      isAutoImportLoading: false,
      isArchiveLoading: false,
      isViewFileLoading: false,
    }
  );

  const handleViewFileClick = async (
    id: number,
    fileType: string,
    fileFormat?: string
  ) => {
    updateLoadingState({ ...loadingStates, isViewFileLoading: true });
    await axiosInstance
      ?.post(`/transaction_upload/review/?id=${id}`)
      .then((reviewResponse) => {
        setViewModalData(reviewResponse?.data?.transactions);
        setClickedButtonId(id);
        setIsViewModalOpen(true);
        setViewFileFormat(fileFormat);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
    updateLoadingState({ ...loadingStates, isViewFileLoading: false });
  };

  const requestBody = {
    take: commonFilter?.customBroker?.take,
    skip: commonFilter?.customBroker?.skip,
    sort: JSON.stringify(
      commonFilter?.customBroker?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    filter: JSON.stringify(commonFilter?.customBroker?.customFilter),
    search: commonFilter?.customBroker?.search || "",
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { data, isFetching, refetch } = useFetchQuery({
    route: "/broker-api-data/",
    key: ["CUSTOM_BROKER_UPLOAD", requestBody],
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="customBroker"
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={"/broker-api-data"}
        name={"BrokerData"}
        filter={JSON.stringify(commonFilter?.customBroker?.customFilter)}
      />
    );
  };

  const uploadManagerColumns: GridColDef[] = [
    {
      ...extraColumnsField("Uploaded Date", "uploadedOn", 130, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("File Name", "fileName", 320, 1),
      renderCell: (item) => {
        return loadingStates?.isViewFileLoading &&
          clickedButtonId === item?.row?.id ? (
          <Skeleton width={"100%"} animation="wave" />
        ) : (
          <div
            className="hover"
            role="presentation"
            onClick={() => {
              if (item?.row?.source === "Manual Entry") {
                setManualEntryTransactionID(item?.row?.id);
                setIsManualEntryViewModal(true);
              } else
                handleViewFileClick(
                  item?.row?.id,
                  item?.row?.source,
                  item?.row?.fileFormat
                );
              setClickedButtonId(item?.row?.id);
            }}
          >
            <Typography
              fontWeight={600}
              style={{
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
            >
              {item?.row?.fileName}
            </Typography>
          </div>
        );
      },
    },
    {
      ...extraColumnsField("File Format", "brokerFormat", 150, 1),
      valueFormatter: (params) =>
        handleCapitalize(removeUnderscore(params?.value)),
    },
    {
      ...extraColumnsField("Upload Type", "source", 150, 1),
    },
    {
      ...extraColumnsField("Records", "remarks", 110, 1),
    },
    {
      ...extraColumnsField("Status", "status", 140, 1),
      renderCell: (item) => <StatusField statusString={item?.row?.status} />,
    },
    {
      ...extraColumnsField("Uploaded By", "userUploaded", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 90, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (item) => {
        return <UploadPopOverActions item={item.row} refetch={refetch} />;
      },
    },
  ];

  return (
    <Grid container spacing={1}>
      <GKDataGrid
        loading={isFetching}
        columns={uploadManagerColumns}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        maxHeight={"calc(100vh - 313px)"}
        headerComponent={
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <GKButton
              variant="outlined"
              color="dark"
              size="medium"
              onClick={() => {
                navigate("/app/upload/archive-manager");
              }}
              startIcon={<IoArchiveOutline size={16} />}
            >
              Archive
            </GKButton>
            <GKButton
              variant="contained"
              size="medium"
              onClick={() => {
                setOpenImportBrokerModal(true);
              }}
              startIcon={<PiArrowSquareDownRightDuotone size={16} />}
            >
              Import
            </GKButton>
          </Grid>
        }
        filterButton={false}
        exportCsv={exportCsv}
        name="customBroker"
        tableName="custom_broker"
        headerFilter={<TableFiltering name="customBroker" />}
      />

      <Show.When isTrue={openImportBrokerModal}>
        <ImportBrokerModal
          open={openImportBrokerModal}
          setOpen={setOpenImportBrokerModal}
          refetch={refetch}
        />
      </Show.When>

      {isManualEntryViewModal && (
        <ManualEntryModal
          isModalOpen={isManualEntryViewModal}
          setIsModalOpen={setIsManualEntryViewModal}
          transactionID={manualEntryTransactionID}
        />
      )}

      <Show.When isTrue={isViewModalOpen}>
        <UploadManagerViewUpload
          isModalOpen={isViewModalOpen}
          setIsModalOpen={setIsViewModalOpen}
          recordData={viewModalData}
          recordID={clickedButtonId}
          recordName={
            data?.data?.data?.find((item: any) => item?.id === clickedButtonId)
              ?.fileName
          }
          recordFormat={viewFileFormat}
        />
      </Show.When>
    </Grid>
  );
};

export default CustomBrokerImport;
