import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import GKFillRadioButton from "hooks/GKFillRadioButton";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IoMdEye } from "react-icons/io";
import { IoEyeOffOutline } from "react-icons/io5";
import { EMAIL_CONFIGURE_TSL } from "utils/constants";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import EmailConfigureStepModal from "./EmailConfigureStepModal";

interface EmailConfigFormValues {
  isDefault: boolean;
  host: string;
  senderName: string;
  email: string;
  password: string;
  useTls: string;
  dailyEmail: string;
}

interface Props {
  data: {
    company: number;
    dailyEmail: string;
    email: string;
    footer: string;
    host: string;
    id: string;
    introduction: string;
    isConnection: false;
    isDefault: boolean;
    password: string;
    senderName: string;
    useTls: string;
  };
  handleUpdate: any;
  isLoading: boolean;
  isFetching: boolean;
}

const EmailConfig = (props: Props) => {
  const { data, handleUpdate, isLoading, isFetching } = props;

  const {
    state: { emailHostList },
  } = useAppContext();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [stepsModal, setStepModal] = useState(false);

  const form = useForm<EmailConfigFormValues>({
    defaultValues: {
      isDefault: true,
      host: "",
      senderName: "",
      email: "",
      password: "",
      useTls: "",
      dailyEmail: "",
    },
    values: {
      isDefault: data?.isDefault,
      host: data?.host,
      senderName: data?.senderName,
      email: data?.email,
      password: data?.password,
      useTls: data?.useTls,
      dailyEmail: data?.dailyEmail,
    },
  });

  form.watch();

  const onSubmit: SubmitHandler<EmailConfigFormValues> = (values) =>
    handleUpdate({
      ...values,
      useTls: values.useTls ? "true" : "false",
    });

  return (
    <>
      <form id="email-config-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontSize={16} fontWeight={500} color={"#697586"}>
              Setup your own email
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item xs={12}>
              <Controller
                name="isDefault"
                control={form.control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <RadioGroup
                      {...field}
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) =>
                        field.onChange(e.target.value === "true")
                      }
                    >
                      <FormControlLabel
                        value
                        control={<GKFillRadioButton />}
                        label="Use noreply@gridkey.in to send all emails"
                        sx={{ color: "#000000" }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<GKFillRadioButton />}
                        label="Use my SMTP mail server to send all emails"
                        sx={{ color: "#000000" }}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            {!form.watch("isDefault") && (
              <>
                <Grid item xs={4}>
                  <Controller
                    name="host"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Select Mail Server",
                      },
                    }}
                    render={({ field, formState: { errors } }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          requiredField
                          disableClearable={false}
                          inputLabel="Outgoing Mail Server(SMTP)"
                          options={emailHostList || []}
                          onChange={(e, val) =>
                            field.onChange(val ? val.value : null)
                          }
                          value={
                            emailHostList?.find(
                              (data: any) => data.value === field.value
                            )?.name
                          }
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={"Select Outgoing Mail Server"}
                              error={!!errors.host}
                              helperText={errors.host?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="senderName"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Name",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Name"}
                          requiredField
                          error={!!errors.senderName}
                          helperText={errors.senderName?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="email"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Email",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Email"}
                          requiredField
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="password"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Password",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          type={showPassword ? "text" : "password"}
                          inputLabel={"Password"}
                          requiredField
                          InputProps={{
                            style: {
                              paddingRight: 12,
                            },
                            endAdornment: (
                              <IconButton
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                style={{
                                  padding: 0,
                                }}
                              >
                                {!showPassword ? (
                                  <IoMdEye />
                                ) : (
                                  <IoEyeOffOutline />
                                )}
                              </IconButton>
                            ),
                          }}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="useTls"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter SSL",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKSelect
                          {...field}
                          requiredField
                          loading={isFetching}
                          inputLabel={"TLS"}
                          error={!!errors.useTls}
                          helperText={errors.useTls?.message}
                        >
                          {EMAIL_CONFIGURE_TSL?.map((dataItem, i) => (
                            <MenuItem key={i} value={dataItem.value}>
                              {dataItem.name}
                            </MenuItem>
                          ))}
                        </GKSelect>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="dailyEmail"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Daily Limit",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Daily Limit"}
                          requiredField
                          error={!!errors.dailyEmail}
                          helperText={errors.dailyEmail?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={12} color={"#697586"} display={"flex"}>
                    Don’t know which password? Follow this &nbsp;
                    <Typography
                      fontSize={12}
                      color="#006580"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => setStepModal(true)}
                    >
                      Steps{" "}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
            mt={1}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              form="email-config-form"
              loading={isLoading}
              onClick={(e) => e.stopPropagation()}
            >
              Save
            </LoadingButton>
          </Grid>

          <Grid item xs={12} my={1}>
            <Divider />
          </Grid>
        </Grid>
      </form>

      {stepsModal && (
        <EmailConfigureStepModal open={stepsModal} setOpen={setStepModal} />
      )}
    </>
  );
};

export default EmailConfig;
