import { Card, Grid, Typography } from "@mui/material";
import SimpleLoader from "components/SimpleLoader";
import { ThemeContainer } from "utils/Theme";
import NetContributionChart from "./NetContributionChart";
import { numberToCurrency } from "utils/MathFunction";

const CardOne = ({ data, loading }: any) => {
  const { theme } = ThemeContainer();

  return (
    <Card sx={{ p: 0, height: "100%" }}>
      {loading ? (
        <SimpleLoader />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            p={2}
            borderBottom={`solid 1px ${theme.palette.grey[300]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              Net Contribution
            </Typography>
            <Typography fontSize={18} fontWeight={600}>
              {numberToCurrency(data?.netContribution)}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={2}>
            <NetContributionChart data={data} />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"16px"}
          />
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            py={"0px"}
            px={"16px"}
            mb={2}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontWeight={400}
              fontSize={12}
              color={"#9AA4B2"}
            >
              <span
                style={{
                  height: 26,
                  width: 4,
                  borderRadius: "10px",
                  marginRight: 8,
                  background: "#FCAA67",
                }}
              />
              Net Contribution
            </Typography>
            <Typography fontWeight={600} fontSize={12} color={"#495567"}>
              {numberToCurrency(data?.netContribution)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            py={"0px"}
            px={"16px"}
            mb={2}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontWeight={400}
              fontSize={12}
              color={"#9AA4B2"}
            >
              <span
                style={{
                  height: 26,
                  width: 4,
                  borderRadius: "10px",
                  marginRight: 8,
                  background: "#709D88",
                }}
              />
              Pay In
            </Typography>
            <Typography fontWeight={600} fontSize={12} color={"#495567"}>
              {numberToCurrency(data?.payIn)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={2}
            py={"0px"}
            px={"16px"}
          >
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontWeight={400}
              fontSize={12}
              color={"#9AA4B2"}
            >
              <span
                style={{
                  height: 26,
                  width: 4,
                  borderRadius: "10px",
                  marginRight: 8,
                  background: "#39627A",
                }}
              />
              Pay Out
            </Typography>
            <Typography fontWeight={600} fontSize={12} color={"#495567"}>
              {numberToCurrency(data?.payOut)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default CardOne;
