/** ========================================================================================================= */
export const riskDropdown = [
  "Conservative",
  "Very Conservative",
  "Moderate",
  "Aggressive",
  "Very Aggressive",
];
/** ========================================================================================================= */
export const volatilityDropdown = [
  {
    name: "High Volatile",
    value: "HIGH",
  },
  {
    name: "Medium Volatile",
    value: "MEDIUM",
  },
  {
    name: "Low Volatile",
    value: "LOW",
  },
];
/** ========================================================================================================= */
export const basketTypeDropdown = [
  {
    name: "Model Portfolio Basket",
    value: "Model",
  },
  {
    name: "Stock Recommendations Basket",
    value: "Asset",
  },
];
/** ========================================================================================================= */
export const timePerdioDropdown = [
  {
    name: "Short Term",
    value: "SHORT_TERM",
  },
  {
    name: "Medium Term",
    value: "MEDIUM_TERM",
  },
  {
    name: "Long Term",
    value: "LONG_TERM",
  },
];
/** ========================================================================================================= */
export const subscriptionDropdown = ["PMS", "Advisor", "Subscription"];
