import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { QueryKeys } from "utils/queryKey";

export const useFamily = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] = useState(false);

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.family?.take,
    skip: commonFilter?.family?.skip,
    sort: JSON.stringify(
      commonFilter?.family?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.family?.search || "",
    show_zero_holding: commonFilter?.family?.showNonZero,
  };

  const { data: familyData, isLoading: isInitialLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, requestBody],
    route: `/family/`,
    requestBody,
  });

  return {
    familyData: familyData?.data,
    isInitialLoading,
    commonFilter,
    isFamilyCreateModalOpen,
    setIsFamilyCreateModalOpen,
  };
};
