import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useLeadNotes = () => {
  const { id } = useParams<string>();

  const {
    data: getLeadNotes,
    isLoading: isLeadNotesLoading,
    refetch,
  } = useFetchQuery({
    key: [QueryKeys.NOTES_LIST],
    retry: 0,
    route: `/lead/${id}/notes/`,
  });

  return { getLeadNotes, isLeadNotesLoading, refetch };
};
