import { LuArrowLeft } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import GKButton from "./GKButton";
import { Show } from "./Show";

const CustomeBreadCrumbs = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const routesData = [
    "/app/dashboard",
    "/app/holdings",
    "/app/imports",
    "/app/crm",
    "/app/equity-basket",
    "/app/reports",
    "/app/insights-holdings",
    "/app/revenue",
    "/app/admin",
    "/app/company-onboarding",
  ];

  return (
    <Show.When isTrue={!routesData.includes(pathname)}>
      <GKButton
        className="outline"
        onClick={() => {
          navigate(-1);
        }}
        startIcon={<LuArrowLeft size={"16px"} />}
      >
        Back
      </GKButton>
    </Show.When>
  );
};

export default CustomeBreadCrumbs;
