import { Grid, Skeleton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import AutoImportModalParent from "Pages/AutoImport/AutoImportModalParent";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import { useFetchQuery } from "hooks/useQueries";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { PiArrowSquareDownRightDuotone } from "react-icons/pi";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import UploadManagerModal from "./ReconManagerModal";
import ReconManagerViewUpload from "./ReconManagerViewUpload";

const ReconManager = () => {
  useDocumentTitle("Recon Manager");

  const {
    state: { commonFilter },
  } = useAppContext();

  const [openUploadManagerModal, setOpenUploadManagerModal] = useState(false);

  const [loadingStates, updateLoadingState] = useReducer(
    (oldPath: any, newStates: any) => ({
      ...oldPath,
      ...newStates,
    }),
    {
      isAutoImportLoading: false,
      isArchiveLoading: false,
      isViewFileLoading: false,
    }
  );

  const [isAutoImportModal, setIsAutoImportModal] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [clickedButtonId, setClickedButtonId] = useState<number>();

  const [viewFileFormat, setViewFileFormat] = useState<string>();

  const [viewModalData, setViewModalData] = useState<any>();

  const exportCsv = () => {
    return (
      <ExportCsv
        path={"/recon_upload"}
        name={"TransactionUpload"}
        filter={JSON.stringify(commonFilter?.reconManager?.customFilter)}
      />
    );
  };

  const handleViewFileClick = async (
    id: number,
    fileType: string,
    fileFormat?: string
  ) => {
    updateLoadingState({ ...loadingStates, isViewFileLoading: true });
    await axiosInstance
      ?.post(`/recon_upload/${id}/review/`)
      .then((reviewResponse) => {
        setViewModalData(reviewResponse?.data?.data);
        setClickedButtonId(id);
        setIsViewModalOpen(true);
        setViewFileFormat(fileFormat);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
    updateLoadingState({ ...loadingStates, isViewFileLoading: false });
  };

  const requestBody = {
    take: commonFilter?.reconManager?.take,
    skip: commonFilter?.reconManager?.skip,
    sort: JSON.stringify(
      commonFilter?.reconManager?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    filter: JSON.stringify(commonFilter?.reconManager?.customFilter),
    search: commonFilter?.reconManager?.search || "",
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: "/recon_upload/",
    key: [QueryKeys.RECON_MANAGER_LIST, requestBody],
    requestBody,
  });

  const reconManagerColumns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name / Code", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Uploaded Date", "uploadedOn", 130, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("File Name", "fileName", 150, 1),
      renderCell: (item) => {
        return loadingStates?.isViewFileLoading &&
          clickedButtonId === item?.row?.id ? (
          <Skeleton width={"100%"} animation="wave" />
        ) : (
          <div
            className="hover"
            role="presentation"
            onClick={() => {
              handleViewFileClick(
                item?.row?.id,
                item?.row?.source,
                item?.row?.fileFormat
              );
              setClickedButtonId(item?.row?.id);
            }}
          >
            <Typography
              fontWeight={600}
              style={{
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
            >
              {item?.row?.fileName}
            </Typography>
          </div>
        );
      },
    },

    {
      ...extraColumnsField("File Format", "format", 150, 1),
      valueFormatter: (params) =>
        handleCapitalize(removeUnderscore(params?.value)),
    },
    {
      ...extraColumnsField("Records", "remarks", 110, 1),
    },
    {
      ...extraColumnsField("Status", "status", 140, 1),
      renderCell: (item) => <StatusField statusString={item?.row?.status} />,
    },
    {
      ...extraColumnsField("Uploaded By", "userUploaded", 150, 1),
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="reconManager"
      />
    );
  };

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={reconManagerColumns}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        maxHeight={"calc(100vh - 320px)"}
        headerComponent={
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <GKButton
              variant="contained"
              size="medium"
              onClick={() => {
                setOpenUploadManagerModal(true);
              }}
              className="upload-step-1"
              startIcon={<PiArrowSquareDownRightDuotone size={16} />}
            >
              Reconcile
            </GKButton>
          </Grid>
        }
        filterButton={false}
        exportCsv={exportCsv}
        name="reconManager"
        tableName="recon_manager_list"
        headerFilter={<TableFiltering name="reconManager" />}
      />

      {openUploadManagerModal && (
        <UploadManagerModal
          setOpen={setOpenUploadManagerModal}
          open={openUploadManagerModal}
          refetch={refetch}
        />
      )}
      {isAutoImportModal && (
        <AutoImportModalParent
          isModalOpen={isAutoImportModal}
          setIsModalOpen={setIsAutoImportModal}
        />
      )}

      <Show.When isTrue={isViewModalOpen}>
        <ReconManagerViewUpload
          isModalOpen={isViewModalOpen}
          setIsModalOpen={setIsViewModalOpen}
          recordData={viewModalData}
          recordID={clickedButtonId}
          recordName={
            data?.data?.data?.find((item: any) => item?.id === clickedButtonId)
              ?.fileName
          }
          recordFormat={viewFileFormat}
        />
      </Show.When>
    </>
  );
};

export default ReconManager;
