export const handleRemoveUnderscore = (str: string) => {
  return str.replace("_", " ");
};

export const handleConvertCamelCase = (str: string) => {
  const wordsArray = str.split(/(?=[A-Z])/);
  const capitalizedWithSpace = wordsArray
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWithSpace;
};

export const handeConvertToCapitalized = (str: string) => {
  const newStr = str.toLowerCase();

  const firstLetter = newStr.charAt(0);
  const remainingLetters = newStr.slice(1);
  const capitalizedWord = firstLetter.toUpperCase() + remainingLetters;

  return capitalizedWord;
};

export function removeUnderscoreAndFirstLetterCapital(str: string) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
