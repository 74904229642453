import { Button, FormControl } from "@mui/material";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";

interface Props {
  form: any;
  open: any;
  setOpen: any;
}

const UpdateNextRebalanceDate = ({ open, setOpen, form }: Props) => {
  return (
    <GKModal
      open={open}
      modalTitle="Next Rebalance Date"
      setOpen={setOpen}
      footer={
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="contained"
        >
          Submit
        </Button>
      }
    >
      <Controller
        control={form.control}
        name="nextRebalanceDate"
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <GKDatePicker
              {...field}
              onChange={(e) => {
                field.onChange(dayjs(e).format("YYYY-MM-DD"));
              }}
              value={dayjs(field.value)}
              inputLabel="Model Portfolio start date"
            />
          </FormControl>
        )}
      />
    </GKModal>
  );
};

export default UpdateNextRebalanceDate;
