import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";

export const useClientFamily = () => {
  const { id } = useParams();
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    requireTotalCount: true,
    take: 100,
    sort: JSON.stringify(
      commonFilter?.clientFamily?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.clientFamily?.search || "",
    show_zero_holding: commonFilter?.clientFamily?.showNonZero,
  };

  const { data: familyData, isLoading: isInitialLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, requestBody],
    route: `/client/${id}/families/`,
    requestBody,
  });

  return {
    familyData: familyData?.data,
    isInitialLoading,
    commonFilter,
  };
};
