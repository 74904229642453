import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, IconButton, Typography } from "@mui/material";
import GKTextField from "components/GKTextField";
import { useChangePassword } from "hooks/useChangePassword";
import { Controller } from "react-hook-form";
import { IoMdEye } from "react-icons/io";
import { IoEyeOffOutline } from "react-icons/io5";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const ChangePassword = () => {
  const { theme } = ThemeContainer();
  const {
    isSubmitting,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    form,
    onSubmit,
  } = useChangePassword();
  useDocumentTitle("Change Password");

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container gap={2} justifyContent={"center"}>
        <Grid
          item
          xs={12}
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"20px"}
          flexDirection={"column"}
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            className="textUnderlineLogin"
            color={theme.palette.dark.main}
            sx={{
              "&.textUnderlineLogin::before": {
                width: "210px !important",
              },
            }}
          >
            Change Password !
          </Typography>
          <Typography color={theme.palette.dark.main}>
            Please fill in your details to change your password
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={8} md={7} xl={6} gap={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="password"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    error={Boolean(form.formState.errors.password)}
                    helperText={form.formState.errors?.password?.message}
                    InputProps={{
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="confirmPassword"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="off"
                    InputProps={{
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showConfirmPassword ? (
                            <IoMdEye />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign={"end"}>
            <LoadingButton
              loading={isSubmitting}
              size="medium"
              variant="contained"
              type="submit"
            >
              Change Password
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <Typography color={theme.palette.dark.main}>
            GridKey is Private and Secure
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
