import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, Switch, Typography } from "@mui/material";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import useAccountSetting from "Pages/AccountSetting/useAccountSetting";
import { Controller, useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";
import { emailRegex } from "utils/validationSchema";

type FormFields = {
  showZeroHoldingTables: boolean;
  twoWayAgreementEnable: boolean;
};

const generalOptions = [
  {
    name: "Show zero holdings in all data tables",
    fieldName: "showZeroHoldingTables",
    description: "Turn on to show zero balance holdings in all tables.",
  },
  {
    name: "Include zero holdings in Reports",
    fieldName: "showZeroHolding",
    description: "Turn on to include zero balance holdings in reports.",
  },
  {
    name: "2-way agreement signing",
    fieldName: "twoWayAgreementEnable",
    description: "Turn on to allow both client and advisor to sign agreements.",
  },
];

const GeneralPreferences = () => {
  useDocumentTitle("General Preferences");

  const {
    getCompanySetting,
    handleUpdateCompanySetting,
    updateCompanyLoading,
  } = useAccountSetting();

  const form = useForm({
    defaultValues: {
      showZeroHoldingTables:
        getCompanySetting?.data?.showZeroHoldingTables || false,
      twoWayAgreementEnable:
        getCompanySetting?.data?.twoWayAgreementEnable || false,
      showZeroHolding: getCompanySetting?.data?.showZeroHolding || false,
      name: getCompanySetting?.data?.name || "",
      email: getCompanySetting?.data?.email || "",
      countryCode: getCompanySetting?.data?.countryCode || "+91",
      phoneNumber: getCompanySetting?.data?.phoneNumber || "+91",
    },
    values: {
      showZeroHoldingTables:
        getCompanySetting?.data?.showZeroHoldingTables || false,
      twoWayAgreementEnable:
        getCompanySetting?.data?.twoWayAgreementEnable || false,
      showZeroHolding: getCompanySetting?.data?.showZeroHolding || false,
      name: getCompanySetting?.data?.name || "",
      email: getCompanySetting?.data?.email || "",
      countryCode: getCompanySetting?.data?.countryCode || "+91",
      phoneNumber: getCompanySetting?.data?.phoneNumber || "+91",
    },
  });

  const twoWaySigning = form.watch("twoWayAgreementEnable");

  return (
    <form
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values)
      )}
    >
      <Grid container spacing={2}>
        {generalOptions.map((dataItem, index) => (
          <>
            <Grid item xs={12} key={index}>
              <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Controller
                  name={dataItem.fieldName as keyof FormFields}
                  control={form.control}
                  render={({ field }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textTransform={"none"}
                >
                  {dataItem.name}
                </Typography>
              </div>
              <Typography ml={"42px"} fontSize={12} color={"#4B5565"}>
                {dataItem.description}
              </Typography>
            </Grid>
          </>
        ))}

        {twoWaySigning && (
          <Grid item container xs={12} spacing={2} ml={3}>
            <Grid item xs={4}>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter name",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Advisor Name"
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid mail",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="email"
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="phoneNumber"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter phone",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <PhoneInput
                      {...field}
                      requiredField
                      onSelectChange={(event: any, val: any) => {
                        form.setValue("countryCode", val?.callingCodes);
                      }}
                      onTextChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                      onTextValue={field.value}
                      onSelectValue={form.getValues("countryCode")}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={updateCompanyLoading}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralPreferences;
