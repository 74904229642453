import { Card, FormControl, Grid, Tab, Tabs } from "@mui/material";
import GKTextField from "components/GKTextField";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";

type FormValues = {
  portfolioName: string;
  billedRevenue: string;
  lastFeePlan: string;
  unBilledRevenue: string;
  lastFeeCollected: string;
  partner: string;
};

const EditRevenuePortfolio = () => {
  const [tab, setTab] = useState(0);

  useDocumentTitle("Revenue Portfolio Details");

  const form = useForm<FormValues>({
    defaultValues: {
      portfolioName: "",
      billedRevenue: "",
      lastFeePlan: "",
      unBilledRevenue: "",
      lastFeeCollected: "",
      partner: "",
    },
  });

  return (
    <DashboardLayout title={"Portfolios"}>
      <Card>
        <Grid container spacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Portfolio Name"
                name="portfolioName"
                onChange={(e) => form.setValue("portfolioName", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Billed Revenue"
                name="billedRevenue"
                onChange={(e) => form.setValue("billedRevenue", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Last Fee Plan"
                name="lastFeePlan"
                onChange={(e) => form.setValue("lastFeePlan", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="unbilled Revenue"
                name="unBilledRevenue"
                onChange={(e) =>
                  form.setValue("unBilledRevenue", e.target.value)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Last Fee Collected"
                name="lastFeeCollected"
                onChange={(e) =>
                  form.setValue("lastFeeCollected", e.target.value)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Partner"
                name="partner"
                onChange={(e) => form.setValue("partner", e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(e, val) => setTab(val)}
            >
              <Tab label="Fee Plan & Metrics" disableRipple />
              <Tab label="Partner" disableRipple />
              <Tab label="Calculations" disableRipple />
              <Tab label="Invoices" disableRipple />
            </Tabs>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default EditRevenuePortfolio;
