import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import dayjs from "dayjs";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import axiosInstance from "utils/axiosInstance";
import { EXPENSE, INCOME, IncomeExpenseTransactionType } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import ExtraChargesModal from "./ExtraChargesModal";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  portfolioId?: number | string;
}

const IncomeExpenseModal = (props: Props) => {
  const { open, setOpen, portfolioId } = props;

  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [chargesIndex, setChargesIndex] = useState<number>(0);

  const {
    state: { portfolios },
  } = useAppContext();

  const { mutate: createIncomeExpense, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/income_expense/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(
        portfolioId
          ? QueryKeys.PORTFOLIO_INCOME_EXPENSE_LIST
          : QueryKeys.INCOME_EXPENSE_LIST
      );
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      transactionType: "",
      portfolio:
        portfolios.find((dataItem: any) => +dataItem.value === +portfolioId) ||
        "",
      date: "",
      transactions: [
        {
          billAmount: "",
          subTransactionType: "",
          remarks: "",
          tax: "",
          totalCharges: {
            other_charges: 0,
            stt: 0,
            gst: 0,
            stamp_charges: 0,
            transaction_charges: 0,
            sebi_turnover_fees: 0,
          },
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "transactions",
  });

  form.watch("transactions");

  const getTransactionType =
    form.getValues("transactionType") === "INCOME"
      ? "Select Income Type"
      : "Select Expense Type";

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"70%"}
      modalTitle={"Add Income/Expense"}
      footer={
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CgMathPlus size={16} />}
            onClick={() =>
              append({
                billAmount: "",
                subTransactionType: "",
                remarks: "",
                tax: "",
                totalCharges: {
                  other_charges: 0,
                  stt: 0,
                  gst: 0,
                  stamp_charges: 0,
                  transaction_charges: 0,
                  sebi_turnover_fees: 0,
                },
              })
            }
          >
            {form.getValues("transactionType") === "INCOME"
              ? "Add Income"
              : "Add Expense"}
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            form="income-expense-form"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Grid>
      }
    >
      <FormProvider {...form}>
        <form
          id="income-expense-form"
          onSubmit={form.handleSubmit((values: any) => {
            if (dayjs(values?.date).year() < 1980) {
              toast.error(
                "You are not allowed to add transaction for this date."
              );
            } else {
              createIncomeExpense({
                ...values,
                portfolio: values?.portfolio?.id,
              });
            }
          })}
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Controller
                name="transactionType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select type",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable={false}
                    inputLabel="Select Type"
                    requiredField
                    options={IncomeExpenseTransactionType || []}
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                      form.reset({
                        transactionType: val?.value,
                        portfolio: portfolioId
                          ? form.getValues("portfolio")
                          : "",
                        date: "",
                        transactions: [
                          {
                            billAmount: "",
                            subTransactionType: "",
                            remarks: "",
                            tax: "",
                            totalCharges: {
                              other_charges: 0,
                              stt: 0,
                              gst: 0,
                              stamp_charges: 0,
                              transaction_charges: 0,
                              sebi_turnover_fees: 0,
                            },
                          },
                        ],
                      });
                    }}
                    getOptionLabel={(option) => option.name || option}
                    value={
                      IncomeExpenseTransactionType?.find(
                        (data: any) => data?.value === field.value
                      )?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Type"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="portfolio"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select portfolio",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable={false}
                    disabled={!!portfolioId}
                    inputLabel="Select Portfolio"
                    requiredField
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    options={portfolios || []}
                    onChange={(e, val) => field.onChange(val || null)}
                    getOptionLabel={(option) => option.name || option}
                    value={
                      portfolioId
                        ? portfolios?.find(
                            (data: any) => +data?.value === +portfolioId
                          )?.name
                        : portfolios?.find(
                            (data: any) => +data?.value === +field.value
                          )?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="portfolio"
                        placeholder="Select Portfolio"
                        error={Boolean(errors.portfolio)}
                        helperText={errors.portfolio?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="date"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select transaction date",
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <GKDatePicker
                      {...field}
                      requiredField
                      inputLabel="Date"
                      value={dayjs(field.value)}
                      onChange={(value) => {
                        field.onChange(dayjs(value).format("YYYY-MM-DD"));
                      }}
                      disableFuture
                      slotProps={{
                        textField: {
                          error: Boolean(errors.date),
                          helperText: errors.date?.message,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {fields?.map((row, index) => {
                const isDividend =
                  form.getValues(`transactions.${index}.subTransactionType`) ===
                  "dividend";

                return (
                  <Grid item container xs={12} spacing={1} my={1} key={row.id}>
                    <Grid item xs={isDividend ? 3.5 : 3.4}>
                      <Controller
                        name={`transactions.${index}.subTransactionType`}
                        control={form.control}
                        render={({ field }) => (
                          <GKSearchSelect
                            {...field}
                            disableClearable={false}
                            inputLabel={getTransactionType}
                            options={
                              form.getValues("transactionType") === "INCOME"
                                ? INCOME
                                : EXPENSE || []
                            }
                            getOptionLabel={(option) => option?.name || option}
                            value={
                              (form.getValues("transactionType") === "INCOME"
                                ? INCOME
                                : EXPENSE
                              ).find(
                                (dataItem) => dataItem.value === field.value
                              )?.name || null
                            }
                            onChange={(e, val) => {
                              field.onChange(val ? val.value : null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={getTransactionType}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={isDividend ? 3 : 3.6}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`transactions.${index}.remarks`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField {...field} inputLabel="Remarks" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={isDividend ? 2.5 : 3.6}
                      display={"flex"}
                      alignItems={"flex-end"}
                      gap={1}
                    >
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={`transactions.${index}.billAmount`}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please enter amount",
                            },
                            min: {
                              value: 0.00000000001,
                              message: "Amount Should be greater than 1",
                            },
                          }}
                          render={({ field, formState: { errors } }) => (
                            <GKTextField
                              {...field}
                              type="number"
                              disabled={
                                form.getValues(
                                  `transactions.${index}.subTransactionType`
                                ) === "total_charges"
                              }
                              inputLabel={
                                form.getValues(
                                  `transactions.${index}.subTransactionType`
                                ) === "total_charges"
                                  ? "Total Charges"
                                  : "Amount"
                              }
                              requiredField
                              error={Boolean(
                                errors.transactions?.[index]?.billAmount
                              )}
                              helperText={
                                errors.transactions?.[index]?.billAmount
                                  ?.message
                              }
                              onChange={(e: any) =>
                                field.onChange(e.target.valueAsNumber)
                              }
                            />
                          )}
                        />
                      </FormControl>
                      {form.getValues(
                        `transactions.${index}.subTransactionType`
                      ) === "total_charges" && (
                        <IconButton
                          className="outline"
                          sx={{ mb: 1 }}
                          onClick={() => {
                            setChargesIndex(index);
                            setOpenChargeModal(true);
                          }}
                        >
                          <MdAdd />
                        </IconButton>
                      )}
                    </Grid>
                    {isDividend && (
                      <Grid
                        item
                        xs={2}
                        display={"flex"}
                        alignItems={"flex-end"}
                        gap={1}
                      >
                        <FormControl variant="standard" fullWidth>
                          <Controller
                            name={`transactions.${index}.tax`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message: "Please enter tax amount",
                              },
                            }}
                            render={({ field }) => (
                              <GKTextField
                                {...field}
                                type="number"
                                inputLabel="TDS"
                                onChange={(e: any) =>
                                  field.onChange(e.target.valueAsNumber)
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={1}
                      textAlign={"right"}
                      mt={2}
                      alignSelf={"center"}
                    >
                      <IconButton
                        className="outline"
                        color="error"
                        disabled={fields.length === 1}
                        onClick={() => remove(index)}
                      >
                        <FiTrash2 size={20} />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Show.When isTrue={openChargeModal}>
              <ExtraChargesModal
                open={openChargeModal}
                setOpen={setOpenChargeModal}
                index={chargesIndex}
              />
            </Show.When>
          </Grid>
        </form>
      </FormProvider>
    </GKModal>
  );
};

export default IncomeExpenseModal;
