import { Button, FormControl, Grid, TextField } from "@mui/material";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Controller } from "react-hook-form";

interface Props {
  open: boolean;
  setOpen: any;
  form: any;
}

const AddDeleteCashModal = (props: Props) => {
  const { open, setOpen, form } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Deposite or Withdraw Amount"
      footer={
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Save
        </Button>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="transactionType"
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                disableClearable={false}
                inputLabel="Transaction Type"
                sx={{
                  width: "100%",
                }}
                options={[
                  {
                    name: "Deposit",
                    value: "DEPOSIT",
                  },
                  {
                    name: "Withdraw",
                    value: "WITHDRAW",
                  },
                ]}
                getOptionLabel={(option) => option.name || option}
                onChange={(e, val) => {
                  field.onChange(val.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name={`transactionType`}
                    placeholder="Transaction Type"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="cash"
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  inputLabel="Amount"
                  placeholder="Amount"
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default AddDeleteCashModal;
