import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import { BANK_ACCOUNT_TYPES } from "utils/constants";

const ClientBank = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "bankAccounts",
  });

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        {fields?.map((row: any, index) => {
          return (
            <Grid item container xs={12} spacing={1} my={1} key={row.id}>
              <Grid item xs={2.6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`bankAccounts.${index}.name`}
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Bank Account Name" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`bankAccounts.${index}.accountNum`}
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        inputLabel="Bank Account Number"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`bankAccounts.${index}.type`}
                    control={form.control}
                    render={({ field }) => (
                      <GKSelect {...field} inputLabel="Bank Account Type">
                        <MenuItem style={{ display: "none" }} value="">
                          Select Account Type
                        </MenuItem>
                        {BANK_ACCOUNT_TYPES?.map((dataItem) => (
                          <MenuItem
                            value={dataItem?.value}
                            key={dataItem?.value}
                          >
                            {dataItem?.name}
                          </MenuItem>
                        ))}
                      </GKSelect>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`bankAccounts.${index}.ifsc`}
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="IFSC Code" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={1.6}
                textAlign={"right"}
                mt={2}
                alignSelf={"center"}
              >
                <IconButton
                  color="error"
                  disabled={fields.length === 1}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <MdDeleteOutline />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                name: "",
                accountNum: "",
                type: "",
                ifsc: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid>
    </Grid>
  );
};

export default ClientBank;
