import { Grid, Typography } from "@mui/material";
import GKModal from "components/GKModal";
import StatusField from "components/StatusField";
import { moneyCommaSeparatorPoint, numberToCurrency } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  open: boolean;
  setOpen: (s: boolean) => void;
  data: {
    id: number;
    date: string;
    name: string;
    stopLoss: any;
    targetPrice: any;
    deviation: any;
    status: string;
    rationale: any;
    timePeriod: any;
    volatility: any;
  };
}

const AssetHistoryDetailsModal = (props: Props) => {
  useDocumentTitle("Basket Assets History");

  const { open, setOpen, data } = props;
  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={data?.name || "-"}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent={"space-between"}>
          <Grid item display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={"#697586"}>
              Date
            </Typography>{" "}
            {data?.date}
          </Grid>
          <Grid item display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={"#697586"}>
              Status
            </Typography>{" "}
            <StatusField statusString={data?.status || "N/A"} />
          </Grid>
          <Grid item display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={"#697586"}>
              Target Price
            </Typography>
            {numberToCurrency(data?.targetPrice || 0)}
          </Grid>
          <Grid item display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={"#697586"}>
              Stoploss
            </Typography>
            {numberToCurrency(data?.stopLoss || 0)}
          </Grid>
          <Grid item display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={"#697586"}>
              Deviation
            </Typography>
            {moneyCommaSeparatorPoint(data?.deviation || 0)}%
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={12} color={"#697586"}>
            Rationale
          </Typography>
          <Typography fontSize={14}>{data.rationale || "-"}</Typography>
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default AssetHistoryDetailsModal;
