import {
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import queryClient from "client";
import ExportCsv from "components/ExportCsv";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import PortfolioStatusModal from "Pages/ModelPortfolio/ModelPortfolioMapping/PortfolioStatusModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { LuPenSquare } from "react-icons/lu";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const EquityBasketPortfolioMapping = () => {
  useDocumentTitle("Basket Portfolio Mapping");

  const { id } = useParams();

  const [selectedId, setSelectedId] = useState(null);

  const [showMapLoading, setShowMapLoading] = useState(false);

  const [openStausModal, setOpenStausModal] = useState(false);

  const { isFetching: isStatusGetLoading, data: allstatusByIdData } =
    useFetchQuery({
      key: ["GET_STATUS_BY_PORTFOLIO_ID"],
      route: `/model_portfolio/${id}/portfolio_status/?portfolio_id=${selectedId}`,
      enabled: Boolean(selectedId),
      success: () => {
        setOpenStausModal(true);
      },
    });

  const { isFetching: isLoading, data } = useFetchQuery({
    key: ["ALL_MAPPED_PORTFOLIOS"],
    route: `/model_portfolio/${id}/map/?get_all=true`,
  });

  const { isLoading: mapLoading, mutate: mapMutation } = useMutation({
    mutationFn: (portfolioId: number[]) =>
      axiosInstance.post(`/model_portfolio/${id}/map/`, {
        portfolio: portfolioId,
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setShowMapLoading(false);
      queryClient.refetchQueries(["ALL_MAPPED_PORTFOLIOS"]);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { isLoading: unmapLoading, mutate: unmapMutation } = useMutation({
    mutationFn: (portfolioId: number[]) =>
      axiosInstance.post(`/model_portfolio/${id}/unmap/`, {
        portfolio: portfolioId,
      }),
    onSuccess: (response) => {
      queryClient.refetchQueries(["ALL_MAPPED_PORTFOLIOS"]);
      toast.success(response.data?.message || "Action completed successfully.");
      setShowMapLoading(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/model_portfolio/${id}/map`}
        name={"modelportfolio-map"}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      headerName: "Portfolio",
      disableColumnMenu: true,
      flex: 1,
      minWidth: 250,
      field: "name",
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.name}>
            <Grid item xs={11}>
              <Typography
                fontWeight={700}
                maxWidth={"100%"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {params?.row?.clientName}
              </Typography>
              <Typography fontSize={12}>
                {params?.row?.name} - {params?.row?.portfolioId}
              </Typography>
            </Grid>
          </Tooltip>
        );
      },
    },
    {
      ...extraColumnsField("Mapped Status", "status", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (row) =>
        showMapLoading &&
        selectedId === row?.row?.id &&
        (mapLoading || unmapLoading) ? (
          <CircularProgress size={20} />
        ) : (
          <Switch
            checked={row?.row?.status?.toLowerCase() === "mapped"}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              row?.row?.status?.toLowerCase() === "unmapped"
                ? mapMutation([row?.row?.id])
                : unmapMutation([row?.row?.id]);
            }}
          />
        ),
    },
    {
      headerName: "Actions",
      minWidth: 10,
      field: "actions",
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (row) => {
        return (
          <IconButton
            disabled={selectedId === row.row.id && isStatusGetLoading}
            onClick={() => {
              setSelectedId(row.row.id);
            }}
          >
            {selectedId === row.row.id && isStatusGetLoading ? (
              <CircularProgress size={18} />
            ) : (
              <LuPenSquare size={18} />
            )}
          </IconButton>
        );
      },
    },
  ];

  const handleReturnSwitchText = (daa: any[]) => {
    const totalLength = daa?.length;
    const totalMappedLength = data?.data?.filter(
      (dataItem: any) => dataItem?.status === "MAPPED"
    )?.length;
    const totalUnmappedLength = data?.data?.filter(
      (dataItem: any) => dataItem?.status === "UNMAPPED"
    )?.length;
    if (totalMappedLength === totalLength) {
      return "Ummap All";
    }
    if (totalUnmappedLength === totalLength) {
      return "Map All";
    }
    return "Map All";
  };

  return (
    <DashboardLayout title="Model Portfolio Mapping">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="mappedPortfolio"
            columns={columns}
            rows={data?.data || []}
            loading={isLoading}
            exportCsv={exportCsv}
            headerComponent={
              <FormControlLabel
                control={
                  unmapLoading || mapLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Switch
                      checked={
                        data?.data?.filter(
                          (dataItem: any) => dataItem?.status === "UNMAPPED"
                        )?.length === 0
                      }
                      onChange={(event) => {
                        event.target.checked
                          ? mapMutation(
                              data?.data?.map((dataItem: any) => dataItem?.id)
                            )
                          : unmapMutation(
                              data?.data?.map((dataItem: any) => dataItem?.id)
                            );
                      }}
                    />
                  )
                }
                label={handleReturnSwitchText(data?.data)}
              />
            }
          />
        </Grid>
      </Grid>
      {openStausModal && (
        <PortfolioStatusModal
          setOpen={setOpenStausModal}
          open={openStausModal}
          data={allstatusByIdData?.data?.data}
          setSelectedId={setSelectedId}
          portfolioId={selectedId}
        />
      )}
    </DashboardLayout>
  );
};

export default EquityBasketPortfolioMapping;
