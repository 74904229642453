import { Grid } from "@mui/material";
import GKButton from "components/GKButton";
import { useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";
import PortfolioMetricsComponent from "./PortfolioMetricsComponent";
import useAccountSetting from "./useAccountSetting";

const PortfolioMetrics = () => {
  useDocumentTitle("Portfolio Metrics");

  const { getCompanySetting, handleUpdateCompanySetting } = useAccountSetting();

  const form = useForm({
    defaultValues: {
      // cashXirr: getCompanySetting?.data?.cashXirr || false,
      // cashNav: getCompanySetting?.data?.cashNav || false,
      tradesPlusCash: getCompanySetting?.data?.tradesPlusCash || false,
      includeFeeInDeposit:
        getCompanySetting?.data?.includeFeeInDeposit || false,
      showTwrr: getCompanySetting?.data?.showTwrr || false,
    },
    values: {
      // cashXirr: getCompanySetting?.data?.cashXirr || false,
      // cashNav: getCompanySetting?.data?.cashNav || false,
      tradesPlusCash: getCompanySetting?.data?.tradesPlusCash || false,
      includeFeeInDeposit:
        getCompanySetting?.data?.includeFeeInDeposit || false,
      showTwrr: getCompanySetting?.data?.showTwrr || false,
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values)
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PortfolioMetricsComponent
            control={form.control}
            name="tradesPlusCash"
            label="Default Metrics Configuration"
            trueLabel="NAV, XIRR and Net Contribution with cash flows"
            falseLabel="NAV, XIRR and Net Contribution without cash flows"
            descriptionTrue="Select this option to calculate portfolio metrics using cash flows only(Deposits and Withdrawals)"
            descriptionFalse="Select this option to calculate portfolio metrics using trades only(Buy, Sell, IPO, BuyBack)"
          />
        </Grid>
        {/* <Grid item xs={12} mt={1}>
          <PortfolioMetricsComponent
            control={form.control}
            name="cashNav"
            label="NAV"
            trueLabel="NAV with cash flows"
            falseLabel="NAV without cash flows"
            descriptionTrue="Calculates your Net Asset Value considering all Cashflows."
            descriptionFalse="Calculates your Net Asset Value considering only Trades."
          />
        </Grid> */}
        <Grid item xs={12} mt={1}>
          <PortfolioMetricsComponent
            control={form.control}
            name="includeFeeInDeposit"
            label="Include Fee Deposit"
            trueLabel="Include fees in returns calculations(XIRR, NAV, Net Contribution)"
            falseLabel="Exclude fees in returns calculation(XIRR, NAV, Net Contribution)"
            descriptionTrue="Select this option to include fee deposits in cash flows for return metrics((XIRR, NAV, Net Contribution)."
            descriptionFalse="Select this option to exclude fee deposits in cash flows for return metrics((XIRR, NAV, Net Contribution)."
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <PortfolioMetricsComponent
            control={form.control}
            name="showTwrr"
            label="Returns Metrics"
            falseLabel="XIRR"
            trueLabel="TWRR"
            descriptionFalse="XIRR(calculated using cash flows or trades) will be used as returns metrics in the portfolio dashboard, benchmark comparison, and reports."
            descriptionTrue="TWRR(calculated using NAVs) will be used as returns metrics in the portfolio dashboard, benchmark comparison, and reports."
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <GKButton variant="contained" type="submit">
            Save
          </GKButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default PortfolioMetrics;
