import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import axiosInstance from "utils/axiosInstance";
import { ExecutionSheetColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import ExecutionSheetModal from "./ExecutionSheetModal";

const ExecutionSheet = () => {
  useDocumentTitle("Execution Sheet");

  const [showSheetModal, setShowSheetModal] = useState(false);

  const [sheetDetails, setSheetDetails] = useState({});

  const [deleteId, setDeleteId] = useState();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    data: allSheetData,
    isLoading,
    refetch,
  } = useFetchQuery({
    key: ["ORDER_MANAGER_MAPPING"],
    route: `/order-manager-mappings/`,
  });

  const { mutate: handleDeleteExecutionSheet } = useMutation({
    mutationFn: (data) =>
      axiosInstance.delete(`/order-manager-mappings/${data}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <GKClientDataGrid
          name="executionSheet"
          showFilters={false}
          maxHeight={"calc(100vh - 342px)"}
          headerComponent={
            <GKButton
              onClick={() => {
                setSheetDetails({});
                setShowSheetModal(true);
              }}
              variant="outlined"
              size="medium"
              startIcon={<CgMathPlus size={18} />}
            >
              Add Execution Sheet
            </GKButton>
          }
          columns={ExecutionSheetColumns(
            refetch,
            setConfirmDelete,
            setDeleteId
          )}
          loading={isLoading}
          rows={allSheetData?.data || []}
          onRowClick={(params) => {
            setSheetDetails(params.row);
            setShowSheetModal(true);
          }}
        />
      </Grid>
      {showSheetModal && (
        <ExecutionSheetModal
          open={showSheetModal}
          setOpen={setShowSheetModal}
          sheetDetails={sheetDetails}
        />
      )}

      {confirmDelete && (
        <ConfirmDeleteModal
          open={confirmDelete}
          setOpen={setConfirmDelete}
          title="Portfolio"
          onDelete={() => handleDeleteExecutionSheet(deleteId)}
          isDelete
        />
      )}
    </Grid>
  );
};

export default ExecutionSheet;
