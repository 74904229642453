import { Grid, IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { LuPenSquare } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const RightIssue = () => {
  useDocumentTitle("Right Issue");

  const navigate = useNavigate();

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.RIGHT_ISSUE_LIST],
    route: "/convert-rightissue-instrument/list/",
  });

  const column: GridColDef[] = [
    {
      headerName: "Last Date For Premium Payment",
      field: "closingDate",
      flex: 1,
      minWidth: 170,
      disableColumnMenu: true,
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      headerName: "Company Name",
      field: "name",
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      valueGetter: (row) => row?.row?.name || "-",
    },
    {
      headerName: "Partly Paid Up Company Name",
      field: "convertName",
      flex: 1,
      minWidth: 170,
      disableColumnMenu: true,
      valueGetter: (row) => row?.row?.convertName || "-",
    },
    {
      headerName: "Premium",
      field: "premium",
      flex: 1,
      minWidth: 120,
      disableColumnMenu: true,
      valueGetter: (row) => row?.row?.premium || "-",
    },
    {
      headerName: "Actions",
      field: "action",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      minWidth: 200,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <>
            <GKButton
              variant="outlined"
              onClick={() => {
                navigate(
                  `/app/right-issue/${params.row.gridCode}/apply-right-issue`,
                  {
                    state: params.row,
                  }
                );
              }}
            >
              Apply For Part Payment
            </GKButton>
            &nbsp;
            <Tooltip title="View and edit already done part payments.">
              <IconButton
                style={{
                  borderRadius: 10,
                }}
                onClick={() => {
                  navigate(
                    `/app/right-issue/${params.row.gridCode}/update-right-issue`,
                    {
                      state: params.row,
                    }
                  );
                }}
              >
                <LuPenSquare size={20} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Grid container>
      <GKClientDataGrid
        name="rightIssue"
        loading={isFetching}
        columns={column}
        rows={data?.data?.data || []}
        columnsButton={false}
        maxHeight={"calc(100vh - 338px)"}
      />
    </Grid>
  );
};

export default RightIssue;
