import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import useAddPortfolioGroup from "hooks/useAddPortfolioGroup";
import { Controller } from "react-hook-form";

interface Props {
  open?: boolean;
  setOpen?: any;
}

const CreatePortfolioGrouping = ({ setOpen, open }: Props) => {
  const { portfolios, addGroupLoading, form, onSubmit } = useAddPortfolioGroup({
    setOpen,
  });

  return (
    <GKModal
      modalTitle="Create Portfolio Group"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          gap={1}
          alignItems={"center"}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            size="medium"
            form="portfolio-group-form"
            loading={addGroupLoading}
          >
            {"Create Portfolio Group"}
          </LoadingButton>
        </Grid>
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)} id="portfolio-group-form">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Portfolio Group Name"
                    placeholder="Portfolio Group Name"
                    error={Boolean(form?.formState?.errors?.name)}
                    helperText={form?.formState?.errors?.name?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="portfolios"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  disableClearable
                  multiple
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {option.clientName}
                        </Typography>
                        <Typography>{option.name}</Typography>
                      </li>
                    );
                  }}
                  options={portfolios?.filter((item: any) => item.name !== "")}
                  inputLabel="Portfolio"
                  getOptionLabel={(option) => option.name || option}
                  onChange={(e, val) => {
                    field.onChange(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Portfolio"
                      error={Boolean(form?.formState?.errors?.portfolios)}
                      helperText={form?.formState?.errors?.portfolios?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreatePortfolioGrouping;
