import { Grid, Typography } from "@mui/material";

const IntroSection = () => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        background: `linear-gradient(180deg,#102E2A 12%, rgba(0,0,0) 100%)`,
        px: {
          xs: 3,
          lg: 10,
        },
      }}
      py={"140px"}
      pt={"223px"}
    >
      <Grid item xs={12}>
        <Typography
          textAlign={"center"}
          fontSize={{
            xs: 32,
            lg: 44,
          }}
          fontWeight={700}
          style={{
            color: "#fff",
          }}
        >
          We are{" "}
          <span
            style={{
              background:
                "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
              WebkitBackgroundClip: " text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Gridkey
          </span>
        </Typography>
      </Grid>
      {/* <Grid item xs={12} mt={2} mb={1}>
        <Typography textAlign={"center"} fontSize={22} fontWeight={500}>
          Wealth management, Portfolio tracking, Manage multiple portfolios{" "}
        </Typography>
      </Grid> */}
      <Grid item xs={12} mb={3}>
        <Typography textAlign={"center"} fontSize={16} color={"#CCCDCD"}>
          {`We are passionate about simplifying wealth management for financial advisors and investors. Our journey began with a vision to empower businesses and individuals to make smarter financial decisions. With a commitment to innovation, integrity, and excellence, we've crafted a suite of products that redefine the landscape of wealth management. Founded and developed by finance enthusiasts, tech geeks, and entrepreneurs, Gridkey brings together a team with a wealth of experience and expertise in finance, technology, and business development.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroSection;
