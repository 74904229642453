import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { BsExclamationOctagon, BsInfoCircle } from "react-icons/bs";
import { CgMathPlus } from "react-icons/cg";
import { LuPenSquare } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { ThemeContainer } from "utils/Theme";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import AddPortfolioModal from "./AddPortfolioModal";
import PortfolioProfileEdit from "./ProfilePages";

interface Props {
  path?: string;
  csvPath?: string;
  showAddButton?: boolean;
  csvName?: string;
  rowData?: any[];
}

const PortfolioList = ({
  path,
  csvPath,
  csvName,
  rowData,
  showAddButton = true,
}: Props) => {
  const navigate = useNavigate();

  useDocumentTitle("Portfolio");

  const { theme } = ThemeContainer();

  const {
    state: { commonFilter, userData },
  } = useAppContext();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);

  const [portfolioData, setPortfolioData] = useState({});

  const [editPortfolioId, setEditPortfolioId] = useState<string | number>();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.portfolio?.take,
    skip: commonFilter?.portfolio?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolio?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolio?.search || "",
    show_zero_holding: commonFilter?.portfolio?.showNonZero,
    filter: JSON.stringify(commonFilter?.portfolio?.customFilter),
  };

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_PORTFOLIOS", requestBody],
    route: path || "/portfolios/",
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="portfolio"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={csvPath || "/portfolios"}
        name={csvName || "portfolios"}
        filter={JSON.stringify(commonFilter?.portfolio?.customFilter)}
      />
    );
  };

  const { isFetching: isLoading } = useFetchQuery({
    key: ["PORTFOLIO_UPDATE_DATA_BY_ID"],
    route: `/portfolios/${editPortfolioId}/`,
    enabled: Boolean(editPortfolioId),
    success: (data: any) => {
      setPortfolioData(data?.data);
      setOpenPortfolioModal(true);
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid container justifyContent={"flex-start"}>
            <Grid item xs={11}>
              <Tooltip title={params?.row?.name}>
                <Grid item xs={11}>
                  <Typography
                    fontWeight={700}
                    maxWidth={"100%"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {params?.row?.clientName}
                  </Typography>
                  <Typography fontSize={12}>
                    {params?.row?.name} - {params?.row?.portfolioId}
                  </Typography>
                </Grid>
              </Tooltip>{" "}
            </Grid>
            <Grid item xs={1} textAlign={"right"}>
              {!params?.row?.isValid && (
                <Tooltip title="There might be some discrepancies in the holdings of this portfolio.">
                  <IconButton
                    color="error"
                    sx={{
                      background: "transparent",
                      "&:hover": {
                        background: "transparent",
                        borderColor: "transparent",
                        color: theme.palette.error.main,
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <BsExclamationOctagon size={14} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.investedValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.currentValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField(
        "Returns",
        userData?.showTwrr ? "twrr" : "xirr",
        100,
        1
      ),
      type: "number",
      renderHeader: () => {
        return (
          <Tooltip title={`As of ${dayjs().format("DD/MM/YY ")} 12:01 am`}>
            <Typography
              fontSize={12}
              color={theme.palette.grey[600]}
              display={"flex"}
              alignItems={"center"}
            >
              Returns&nbsp;
              <BsInfoCircle />
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (param) =>
        userData?.showTwrr ? param?.row?.twrr : param?.row?.xirr,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (param) => (
        <Typography>
          {userData?.showTwrr
            ? param?.row?.twrr?.toFixed(2)
            : param?.row?.xirr?.toFixed(2) || "0"}
        </Typography>
      ),
    },
    {
      ...extraColumnsField(
        "Last Transaction Date",
        "lastTransactionDate",
        200,
        1
      ),
      valueFormatter: (params) => matchDate(params.value),
    },
    {
      ...extraColumnsField("Last Uploaded Date", "lastUploadDate", 200, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("Equity Basket", "equityBasket", 200, 1),
      valueGetter: (row) => row?.row?.lastOrderModelPortfolio || "-",
    },
    {
      ...extraColumnsField("Last Order Date", "lastOrderDate", 200, 1),
      valueFormatter: (params) => matchDate(params?.value),
      valueGetter: (row) => row?.row?.lastOrderDate || "-",
    },
    {
      ...extraColumnsField("Last Order Version", "lastOrderVersion", 200, 1),
      valueGetter: (row) => row?.row?.lastOrderVersion || "-",
    },
    {
      ...extraColumnsField("Last Order Template", "lastOrderTemplate", 200, 1),
      valueFormatter: (params) => removeUnderscore(params?.value),
      valueGetter: (row) => row?.row?.lastOrderTemplate || "-",
    },
    {
      ...extraColumnsField("Last Order Type", "lastOrderType", 200, 1),
      renderCell: (row) => {
        return (
          <Typography textTransform={"capitalize"}>
            {row?.row?.lastOrderType || "-"}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Execution Sheet", "lastExecutionSheet", 200, 1),
      valueFormatter: (params) => removeUnderscore(params?.value),
      valueGetter: (row) => row?.row?.lastExecutionSheet || "-",
    },
    {
      ...extraColumnsField("Equity %", "equityPercentage", 100, 1),
      valueGetter: (params) =>
        moneyCommaSeparator(params?.row?.equityPercentage),
    },
    {
      ...extraColumnsField("Cash %", "cash", 100, 1),
      valueGetter: (params) =>
        moneyCommaSeparator(params?.row?.ledgerPercentage),
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      renderCell: (item) => {
        return isLoading && editPortfolioId === item.row.id ? (
          <CircularProgress size={18} />
        ) : (
          <IconButton
            className="outline"
            onClick={(event) => {
              event.stopPropagation();
              setEditPortfolioId(item.row.id);
            }}
          >
            <LuPenSquare size={20} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns?.filter((dataItem) =>
          userData?.userRole !== UserRoleTypes.client
            ? ![
                "lastOrderDate",
                "lastOrderVersion",
                "lastOrderTemplate",
                "lastOrderType",
                "lastExecutionSheet",
              ].includes(dataItem?.field)
            : dataItem.field
        )}
        rows={rowData || data?.data?.data || []}
        maxHeight={"calc(100vh - 321px)"}
        searchPlaceholder="Search for Portfolios"
        onRowClick={(row) => {
          navigate(`/app/holdings/portfolio/${row?.id}`);
        }}
        CustomPagination={!isFetching && cusPagination}
        pinnedColumns={{
          left: ["hierarchy", "name"],
        }}
        filterButton={false}
        exportCsv={exportCsv}
        zeroHolding
        name="portfolio"
        headerComponent={
          showAddButton &&
          userData?.userRole !== UserRoleTypes.client && (
            <GKButton
              className="add-new-portfolio"
              onClick={() => {
                setIsCreateModalOpen(true);
              }}
              variant="contained"
              startIcon={<CgMathPlus size={14} />}
            >
              Create Portfolio
            </GKButton>
          )
        }
        initialState={{
          columns: {
            columnVisibilityModel: {
              lastOrderDate: true,
              lastOrderVersion: false,
              lastOrderTemplate: false,
              lastOrderType: false,
              lastExecutionSheet: false,
            },
          },
        }}
        tableName="portfolio_list"
        headerFilter={<TableFiltering name="portfolio" />}
      />

      {openPortfolioModal && (
        <PortfolioProfileEdit
          open={openPortfolioModal}
          setOpen={setOpenPortfolioModal}
          portfolioData={portfolioData}
          portfolioId={editPortfolioId}
          setPortfolioId={setEditPortfolioId}
        />
      )}

      {isCreateModalOpen && (
        <AddPortfolioModal
          setOpen={setIsCreateModalOpen}
          open={isCreateModalOpen}
        />
      )}
    </>
  );
};

export default PortfolioList;
