import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import toast from "react-hot-toast";

import GKButton from "components/GKButton";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import PhoneInput from "components/PhoneInput";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";
import AddStatusAndSouceModal from "./AddStatusAndSouceModal";
import TagModal from "./LeadTags/TagModal";

interface Props {
  open: boolean;
  setOpen: any;
}

const CreateLeadModal = (props: Props) => {
  const { open, setOpen } = props;

  const [addStatusModal, setAddStatusModal] = useState(false);

  const [addTagsModal, setAddTagsModal] = useState(false);

  const [type, setType] = useState("STATUS");

  const [updateValue, setUpdateValue] = useState({});

  const [sourceList, setSourceList] = useState([]);

  const { data: statusList } = useFetchQuery({
    route: "/lead-status/",
    key: ["GET_ALL_LEAD_STATUS"],
  });

  const { data: employeeList } = useFetchQuery({
    route: "/employees/all/",
    key: ["GET_ALL_LEAD_ASSIGNEE"],
  });

  const { data: tagsList } = useFetchQuery({
    route: "/lead-tags/",
    key: ["GET_ALL_LEAD_TAGS"],
  });

  const { mutate: createNewLead, isLoading } = useMutation({
    mutationFn: (data) => axiosInstance.post(`/lead/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);
      refetchQuery("GET_ALL_LEADS");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { refetch: sourceRefetch, isFetching } = useFetchQuery({
    key: [QueryKeys.GET_SOURCE],
    route: "/lead-source/",
    success: (data: any) => {
      setSourceList(
        data?.data?.data?.map((s: any) => ({
          name: s.leadsource,
          value: s.id,
        }))
      );
    },
  });

  const { mutate: handleDeleteSource } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/lead-source/${data}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);

      sourceRefetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      companyName: "",
      countryCode: "+91",
      phoneNumber: "",
      leadStatus: null,
      leadAssignedTo: null,
      sourceOfLead: null,
      tags: [],
    },
  });

  form.watch();

  return (
    <>
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle={"Create New Lead"}
        footer={
          <Grid container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="outlined"
              size="medium"
              onClick={() => setOpen(false)}
            >
              Cancel
            </GKButton>
            <GKButton
              variant="contained"
              type="submit"
              form="create-lead-form"
              size="medium"
              disabled={isLoading}
            >
              Submit
            </GKButton>
          </Grid>
        }
      >
        <form
          id="create-lead-form"
          onSubmit={form.handleSubmit((values: any) => createNewLead(values))}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="name"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter lead name",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Lead Name"
                      requiredField
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="companyName"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter company name",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Company Name"
                      requiredField
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="email"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter email",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Email"
                      requiredField
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="phoneNumber"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter phone number",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <PhoneInput
                      {...field}
                      onSelectChange={(event: any, val: any) => {
                        form.setValue("countryCode", val?.callingCodes);
                      }}
                      onTextChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                      onTextValue={field.value}
                      requiredField
                      onSelectValue={form.getValues("countryCode")}
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="leadStatus"
                rules={{
                  required: {
                    value: true,
                    message: "Please select status",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    requiredField
                    inputLabel="Status"
                    options={
                      statusList?.data?.data?.map(
                        (da: {
                          id: number;
                          leadstatus: string;
                          branch: string;
                        }) => {
                          return {
                            ...da,
                            name: da?.leadstatus,
                            value: da?.id,
                          };
                        }
                      ) || []
                    }
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    value={
                      statusList?.data?.data?.find(
                        (da: any) => da?.id === form.getValues("leadStatus")
                      )?.leadStatus
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Status"
                        error={!!error}
                        helperText={!!error && error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Please select assignee",
                  },
                }}
                name="leadAssignedTo"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Assigned"
                    requiredField
                    options={
                      employeeList?.data?.map(
                        (da: { id: number; name: string; phone: string }) => {
                          return {
                            ...da,
                            name: da?.name,
                            value: da?.id,
                          };
                        }
                      ) || []
                    }
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    value={
                      employeeList?.data?.find(
                        (da: any) => da?.id === form.getValues("leadAssignedTo")
                      )?.name
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Assignee"
                        error={!!error}
                        helperText={!!error && error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="sourceOfLead"
                  control={form.control}
                  render={({ field }) => (
                    <GKSelect
                      {...field}
                      loading={isFetching}
                      inputLabel="Source"
                      placeholder="Source"
                      renderValue={(selected) => {
                        const value =
                          selected &&
                          sourceList?.find((data) => data?.value === selected)
                            ?.name;

                        return value || "Select an option";
                      }}
                    >
                      {sourceList.map((data: any) => {
                        return (
                          <MenuItem
                            value={data?.value}
                            key={data?.value}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            <Typography>{data?.name}</Typography>
                            <span
                              style={{
                                display: "flex",
                                gap: "2px",
                              }}
                            >
                              <IconButton
                                style={{ padding: 0 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setType("SOURCE");
                                  setUpdateValue(data);
                                  setAddStatusModal(true);
                                }}
                              >
                                <AiOutlineEdit />
                              </IconButton>
                              <IconButton
                                className="outline"
                                style={{ padding: 0 }}
                                color="error"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteSource(data?.value);
                                }}
                              >
                                <FiTrash2 size={16} />
                              </IconButton>
                            </span>
                          </MenuItem>
                        );
                      })}
                      <MenuItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <GKButton
                          variant="link"
                          startIcon={<CgMathPlus />}
                          onClick={() => {
                            setType("SOURCE");
                            setUpdateValue({});
                            setAddStatusModal(true);
                          }}
                        >
                          Add New
                        </GKButton>
                      </MenuItem>
                    </GKSelect>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="tags"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable
                    inputLabel="Tags"
                    options={
                      tagsList?.data?.data?.map(
                        (da: { id: number; tag: string }) => {
                          return {
                            ...da,
                            name: da?.tag,
                            value: da?.id,
                          };
                        }
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                    }}
                    value={
                      tagsList?.data?.data
                        ?.map((da: { id: number; tag: string }) => {
                          return {
                            ...da,
                            name: da?.tag,
                            value: da?.id,
                          };
                        })
                        .filter((option: any) =>
                          field.value.includes(option.value)
                        ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Tags" />
                    )}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper
                          sx={{
                            fontFamily: "Inter !important",
                          }}
                        >
                          {children}
                          <GKButton
                            sx={{ p: 1 }}
                            fullWidth
                            variant="link"
                            onMouseDown={() => {
                              setAddTagsModal(true);
                            }}
                          >
                            + Add New
                          </GKButton>
                        </Paper>
                      );
                    }}
                    renderOption={(props, val) => {
                      return (
                        <li
                          {...props}
                          style={{
                            background: field.value?.includes(val?.id)
                              ? `${paletteLightTheme.palette.primary.main}22`
                              : "",
                          }}
                        >
                          {val?.tag}
                        </li>
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </GKModal>
      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStatusModal}
          type={type}
          sourceRefetch={sourceRefetch}
          updateValue={updateValue}
        />
      )}

      {addTagsModal && (
        <TagModal open={addTagsModal} setOpen={setAddTagsModal} />
      )}
    </>
  );
};

export default CreateLeadModal;
