import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

interface Props {
  open: boolean;
  setOpen: any;
  handleNext: any;
  form: any;
}

const SelectClientModal = ({ open, setOpen, handleNext, form }: Props) => {
  const [clients, setClients] = useState<any[]>([]);

  const [selectedClients, setSelectedClients] = useState("");

  /** =========================================================================================== */
  const { mutate: getClientById, isLoading } = useMutation({
    mutationKey: ["GET_CLIENT_BY_ID"],
    mutationFn: () => axiosInstance.get(`/client/${selectedClients}/`),
    onSuccess: (response) => {
      form.reset({
        ...form.getValues(),
        ...response.data,
        isExistingClient: true,
        // onBoard: true,
      });
      setOpen(false);
      handleNext();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });
  /** =========================================================================================== */

  const { mutate: getClientComplianceById, isLoading: kycLoading } =
    useMutation({
      mutationKey: ["GET_KYC_CLIENT_BY_ID"],
      mutationFn: () =>
        axiosInstance.get(`/compliance/?client_id=${selectedClients}`),
      onSuccess: (response) => {
        form.setValue("kyc", response?.data?.data?.kyc);
        form.setValue("riskProfile", response?.data?.data?.riskProfile);
        form.setValue("agreement", response?.data?.data?.agreement);
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong please try again in fetching nominees."
        );
      },
    });

  /** =========================================================================================== */
  const { isFetching } = useFetchQuery({
    key: [QueryKeys.CLIENTS_SHORT_DATA],
    route: `/client/all/`,
    success: (data: any) => {
      setClients(
        data?.data?.map((item: any) => ({
          name: `${item.name} - ${item?.clientCode}`,
          value: item.id,
        }))
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Select a client"
      footer={
        <Grid container justifyContent={"flex-end"}>
          <LoadingButton
            loading={isLoading || kycLoading}
            variant="contained"
            onClick={() => {
              getClientById();
              getClientComplianceById();
            }}
          >
            Confirm
          </LoadingButton>
        </Grid>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <GKSearchSelect
            disableClearable
            inputLabel="Client"
            requiredField
            options={clients || []}
            disabled={isFetching}
            onChange={(e, val) => {
              setSelectedClients(val.value);
            }}
            value={
              clients?.find((data) => data?.value === selectedClients)?.name
            }
            getOptionLabel={(option) => option.name || option}
            renderInput={(params) => <TextField {...params} name="Client" />}
          />{" "}
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default SelectClientModal;
