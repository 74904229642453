import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import { Show } from "components/Show";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";
import CalculationPage from "./CalculationPage";
import CalculationProformaModal from "./CalculationProformaModal";
import ManagementDeductionPage from "./ManagementDeductionPage";

const CreateCalculation = () => {
  useDocumentTitle("Calculation");

  const { id, portfolioId } = useParams();

  const navigate = useNavigate();

  const {
    state: { portfolios },
  } = useAppContext();

  const [stateModal, setStateModal] = useState(false);
  const [openDeductModal, setOpenDeductModal] = useState(false);
  const [selected, setSelected] = useState<readonly number[]>([]);
  // const [openWaterMarkModal, setOpenWaterMarkModal] = useState(false);

  const { data: calculationById, isFetching: calculationByIdLoading } =
    useFetchQuery({
      key: ["CALCULATION_BY_ID"],
      route: `/fee-calculation-history/${id}/`,
      enabled: Boolean(id),
    });

  const { data: feePlansList, isFetching: getFeePlansFetching } = useFetchQuery(
    {
      key: ["GET_FEE_PLANS_DROPDOWN"],
      route: "/fee-plans-dropdown/",
    }
  );

  const form = useForm<any>({
    defaultValues: {
      portfolio: "",
      isManagement: false,
      managementFee: 0,
      additionalFee: 0,
      isUpdateHighwaterMark: false,
      feePlans: [{ feePlan: "", startDate: "", endDate: "" }],
    },
    values: {
      portfolio: portfolios.find(
        (dataItem: any) => dataItem.value === +portfolioId
      ),
      isManagement: false,
      managementFee: 0,
      additionalFee: 0,
      isUpdateHighwaterMark: false,
      feePlans: [{ feePlan: "", startDate: "", endDate: "" }],
    },
  });

  form.watch();

  const { isFetching: feePlanByPortfolioLoading } = useFetchQuery({
    key: ["FEE_PLAN_BY_PORTFOLIO_ID"],
    route: `/portfolio-feeplan-mapping/get-fee-plan/${
      +portfolioId || form.getValues("portfolio")?.id
    }/`,
    enabled: !!+portfolioId || !!form.getValues("portfolio"),
    success: (response: any) => {
      form.setValue(`feePlans.${0}.feePlan`, response?.data?.data?.id);
      form.setValue("isManagement", response?.data?.data?.isGetManagement);
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "feePlans",
  });

  const {
    mutate: createCalculation,
    data,
    isLoading,
    reset,
  } = useMutation({
    mutationKey: ["CREATE_CALCULATION"],
    mutationFn: (data) =>
      axiosInstance.post("/portfolio-fee-calculation/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleSaveCalculation, isLoading: saveCalculationLoading } =
    useMutation({
      mutationKey: ["SAVE_CALCULATION"],
      mutationFn: () =>
        axiosInstance.post("/fee-calculation-history/", {
          calculationData: data?.data?.data,
          portfolio: data?.data?.data?.portfolio,
          feePlanName: data?.data?.data?.feePlanList,
          feeAmount: data?.data?.data?.totalAmount,
          feeDateRange: data?.data?.data?.feeDateRange,
          isUpdateHighwaterMark: form.getValues("isUpdateHighwaterMark"),
        }),
      onSuccess: (response) => {
        // setOpenWaterMarkModal(false);
        navigate(-1);
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const {
    data: feeDeductionData,
    mutate: getManagementFeeForDeduction,
    isLoading: feeDeductionLoading,
    reset: feeDeductionDataReset,
  } = useMutation({
    mutationKey: ["GET_MANAGEMENT_FEE"],
    mutationFn: (data) => axiosInstance.post("/get-management-fee/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      reset();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const onSubmit = (data: any) => {
    delete data.isManagement;
    delete data.additionalFee;

    createCalculation({ ...data, portfolio: data.portfolio.id });
    feeDeductionDataReset();
  };

  return (
    <DashboardLayout
      title="Create Calculation"
      loading={calculationByIdLoading}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {id ? (
          <Grid container spacing={1}>
            <Grid item xs={12} my={1}>
              <Typography fontSize={20} fontWeight={600}>
                {`${calculationById?.data?.data?.portfolioName} - ${calculationById?.data?.data?.portfolioId}`}
              </Typography>
              <Typography fontSize={12} display={"flex"} alignItems={"center"}>
                Client {"  "}
                <span style={{ fontWeight: 500, marginLeft: "4px" }}>
                  {calculationById?.data?.data?.clientName}
                  {" - "}
                  {calculationById?.data?.data?.clientCode}
                </span>{" "}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            {!id && (
              <>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  my={1}
                >
                  <Typography fontSize={18} fontWeight={600}>
                    Create calculation
                  </Typography>
                  <div style={{ gap: 1 }}>
                    <GKButton
                      variant="outlined"
                      color="dark"
                      size="medium"
                      onClick={() => {
                        form.reset();
                        reset();
                        feeDeductionDataReset();
                        form.setValue("portfolio", "");
                      }}
                    >
                      Reset
                    </GKButton>{" "}
                    &nbsp;
                    <LoadingButton
                      type="submit"
                      size="medium"
                      variant="contained"
                      loading={isLoading}
                    >
                      Calculate
                    </LoadingButton>
                  </div>
                </Grid>
              </>
            )}
            <Grid
              item
              container
              display={"flex"}
              alignItems={"end"}
              spacing={1}
            >
              <Grid item xs={form.getValues("isManagement") ? 3 : 4}>
                <Controller
                  name="portfolio"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select portfolio",
                    },
                  }}
                  render={({ field, formState: { errors } }) => {
                    return (
                      <GKSearchSelect
                        {...field}
                        requiredField
                        disabled={!!portfolioId}
                        disableClearable={false}
                        inputLabel="Portfolio"
                        renderOption={(props, option) => {
                          return (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography fontWeight={600}>
                                {option.clientName}
                              </Typography>
                              <Typography>{option.name}</Typography>
                            </li>
                          );
                        }}
                        options={portfolios || []}
                        onChange={(e, val) => field.onChange(val || "")}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="portfolio"
                            placeholder="Select Portfolio"
                            error={Boolean(errors?.portfolio)}
                            helperText={errors?.portfolio?.message as string}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>

              <Show.When
                isTrue={
                  form.getValues("portfolio") && !feePlanByPortfolioLoading
                }
              >
                {fields.map((row: any, index: number) => {
                  return (
                    <>
                      <Grid item xs={form.getValues("isManagement") ? 2.5 : 4}>
                        <Controller
                          name={
                            `feePlans[${index}].feePlan` as `feePlans.${number}.feePlan`
                          }
                          control={form.control}
                          render={({ field }) => (
                            <GKSearchSelect
                              {...field}
                              disableClearable={false}
                              inputLabel="Fee Plan"
                              loading={getFeePlansFetching}
                              options={feePlansList?.data?.data || []}
                              onChange={(e, val) => {
                                field.onChange(val ? val.value : null);
                                form.setValue(
                                  "isManagement",
                                  val?.isGetManagement
                                );
                              }}
                              value={
                                feePlansList?.data?.data?.find(
                                  (data: any) => data?.value === field.value
                                )?.name
                              }
                              getOptionLabel={(option) => option.name || option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`feePlans.${index}.feePlan`}
                                  placeholder={"Select Fee Plan"}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Controller
                            control={form.control}
                            name={
                              `feePlans[${index}].startDate` as `feePlans.${number}.startDate`
                            }
                            render={({ field }) => {
                              return (
                                <GKDatePicker
                                  {...field}
                                  inputLabel="Start Date"
                                  name={`feePlans[${index}].startDate`}
                                  value={dayjs(field.value)}
                                  onChange={(value) => {
                                    field.onChange(
                                      dayjs(value).format("YYYY-MM-DD")
                                    );
                                  }}
                                  disableToday
                                  disableFuture
                                  maxDate={dayjs()}
                                  slotProps={{
                                    textField: {
                                      error: false,
                                      helperText: false,
                                    },
                                  }}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Controller
                            control={form.control}
                            name={
                              `feePlans[${index}].endDate` as `feePlans.${number}.endDate`
                            }
                            render={({ field }) => {
                              return (
                                <GKDatePicker
                                  {...field}
                                  inputLabel="End Date"
                                  name={`feePlans[${index}].endDate`}
                                  value={dayjs(field.value)}
                                  onChange={(value) => {
                                    field.onChange(
                                      dayjs(value).format("YYYY-MM-DD")
                                    );
                                  }}
                                  disableFuture
                                  disableToday
                                  maxDate={dayjs(
                                    form.getValues(
                                      `feePlans.${index}.startDate`
                                    )
                                  ).add(365, "days")}
                                  slotProps={{
                                    textField: {
                                      error: false,
                                      helperText: false,
                                    },
                                  }}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {/* <Grid
                          item
                          xs={1}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          mt={2.7}
                        >
                          <IconButton
                            color="error"
                            disabled={fields.length === 1}
                            onClick={() => remove(index)}
                          >
                            <FiTrash2 size={16} />
                          </IconButton>
                        </Grid> */}
                    </>
                  );
                })}
                {/* <Grid item xs={12} mt={1}>
                    <Divider>
                      <IconButton
                        onClick={() => {
                          append({ feePlan: "", startDate: "", endDate: "" });
                        }}
                      >
                        <AiOutlinePlus />
                      </IconButton>
                    </Divider>
                  </Grid> */}

                <Show.When isTrue={form.getValues("isManagement")}>
                  <Grid item xs={2.5}>
                    <LoadingButton
                      loading={feeDeductionLoading}
                      size="large"
                      variant="contained"
                      disabled={!isLoading && data?.data?.data}
                      onClick={form.handleSubmit((values: any) => {
                        getManagementFeeForDeduction({
                          ...values,
                          portfolio: values.portfolio.id,
                        });
                        setOpenDeductModal(true);
                      })}
                    >
                      Deduct Management Fees
                    </LoadingButton>
                  </Grid>
                </Show.When>
              </Show.When>
            </Grid>
          </Grid>
        )}

        <Show.When isTrue={(!isLoading && data?.data?.data) || id}>
          <Grid container spacing={1}>
            <Grid item xs={12} mb={1} mt={2}>
              {!id && <Divider />}
            </Grid>
            <Grid item xs={12}>
              <CalculationPage
                calculationData={
                  id
                    ? calculationById?.data?.data?.calculationData
                    : data?.data?.data
                }
              />
            </Grid>
            <Show.When isTrue={!id}>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <LoadingButton
                  variant="outlined"
                  color="dark"
                  size="medium"
                  loading={saveCalculationLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    // setOpenWaterMarkModal(true);
                    handleSaveCalculation();
                  }}
                >
                  Save
                </LoadingButton>{" "}
                &nbsp;
                <LoadingButton
                  variant="contained"
                  size="medium"
                  onClick={() => setStateModal(true)}
                >
                  Save And Create Proforma
                </LoadingButton>
              </Grid>
            </Show.When>
          </Grid>
        </Show.When>
      </form>

      <Show.When isTrue={stateModal}>
        <CalculationProformaModal
          open={stateModal}
          setOpen={setStateModal}
          calculationData={data?.data?.data}
          isDefault={false}
        />
      </Show.When>

      {/* <Show.When isTrue={openWaterMarkModal}>
        <HighWaterMarkModal
          open={openWaterMarkModal}
          setOpen={setOpenWaterMarkModal}
          form={form}
          handleSaveCalculation={handleSaveCalculation}
        />
      </Show.When> */}

      <Show.When isTrue={openDeductModal}>
        <ManagementDeductionPage
          open={openDeductModal}
          setOpen={setOpenDeductModal}
          form={form}
          feeDeductionData={feeDeductionData}
          selected={selected}
          setSelected={setSelected}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default CreateCalculation;
