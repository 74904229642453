import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface LedgerBalanceInterface {
  portfolioId: number;
  brokerLedgerBalance: number;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  ledgerBalanceData: LedgerBalanceInterface;
}

const AvailableMarginModal = (props: Props) => {
  const { open, setOpen, ledgerBalanceData } = props;

  const form = useForm({
    defaultValues: {
      brokerLedgerBalance: +ledgerBalanceData?.brokerLedgerBalance || 0,
    },
  });

  const { mutate: handleUpdateMargin } = useMutation({
    mutationKey: ["UPDATE_AVAILABLE_MARGIN"],
    mutationFn: (data: { brokerLedgerBalance: number }) =>
      axiosInstance.patch(
        `/portfolios/${ledgerBalanceData?.portfolioId}/margin_bal/`,
        data
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.PORTFOLIO_CASH_LIST);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Edit Portfolio"
      width={"40%"}
      footer={
        <GKButton
          variant="contained"
          type="submit"
          form="available margin-form"
        >
          Save
        </GKButton>
      }
    >
      <form
        id="available margin-form"
        onSubmit={form.handleSubmit((values: { brokerLedgerBalance: number }) =>
          handleUpdateMargin(values)
        )}
      >
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="brokerLedgerBalance"
              control={form.control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel={"Available Margin"}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      if (event.target instanceof HTMLInputElement) {
                        field.onChange(event.target.valueAsNumber);
                      }
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AvailableMarginModal;
