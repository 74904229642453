/* eslint-disable react/no-danger */
import { Grid, IconButton, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useState } from "react";

import NoteModal from "Pages/AdviceRegisterNotes/NoteModal";
import { LuPenSquare } from "react-icons/lu";
import { AdviceRegisterColor } from "utils/queryKey";

interface Props {
  dataItem: any;
  index: number;
}

const CustomContainer = styled(Grid)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
}));

const AdviceAdviceRegister = (props: Props) => {
  const { dataItem, index } = props;

  const [openNoteModel, setOpenNoteModel] = useState(false);

  const [noteDetails, setNoteDetails] = useState({});

  return (
    <CustomContainer key={index} position={"relative"} item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={6} display={"flex"}>
          <Typography
            fontWeight={600}
            fontSize={12}
            p={"4px"}
            borderRadius={1}
            textTransform={"capitalize"}
            color={AdviceRegisterColor[dataItem?.type]}
            bgcolor={`${AdviceRegisterColor[dataItem?.type]}22`}
          >
            {dataItem?.noteTypesList.map((item: any, index: number) => {
              return (
                <span
                  style={{
                    color: item?.color,
                    textTransform: "capitalize",
                    textDecoration: "italics",
                  }}
                >
                  {item.name}
                  {dataItem?.noteTypesList.length > index + 1 && ", "}
                </span>
              );
            })}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <IconButton
            style={{
              borderRadius: 4,
            }}
            onClick={() => {
              setNoteDetails({
                id: dataItem?.data?.id,
                client: { id: dataItem.clientId },
                noteTypes: dataItem?.data?.noteTypes,
                content: dataItem?.data?.content || "",
                portfolios:
                  dataItem?.data?.portfolioList.map((data: any) => data?.id) ||
                  [],
                dateTime: dataItem?.data?.dateTime || dayjs(),
              });
              setOpenNoteModel(true);
            }}
          >
            <LuPenSquare size={14} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={10}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-start"}
        >
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            Portfolio Name :&nbsp;
            {dataItem?.data?.portfolioList?.length > 0 ? (
              dataItem?.data?.portfolioList?.map((data: any, index: number) => {
                return (
                  <Fragment key={data?.id}>
                    <Typography
                      fontWeight={700}
                      fontSize={12}
                      key={dataItem?.id}
                    >
                      {data?.name}
                    </Typography>
                    {index < dataItem?.data?.portfolioList.length - 1 && (
                      <span>,&nbsp;</span>
                    )}
                  </Fragment>
                );
              })
            ) : (
              <Typography fontWeight={700} fontSize={12}>
                -
              </Typography>
            )}
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            Description :&nbsp;
            <Typography
              fontWeight={700}
              fontSize={12}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              width={"450px"}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    dataItem?.data?.content?.split("\n").length > 1
                      ? `${dataItem?.data?.content?.split("\n")[0]} ...`
                      : dataItem?.data?.content?.split("\n")[0],
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography fontWeight={400} fontSize={12} color={"#9AA4B2"}>
            {dayjs(dataItem?.data?.dateTime).format("DD MMM YYYY  hh:mm a") ||
              "-"}
          </Typography>
        </Grid>
      </Grid>

      {openNoteModel && (
        <NoteModal
          open={openNoteModel}
          setOpen={setOpenNoteModel}
          noteDetails={noteDetails}
        />
      )}
    </CustomContainer>
  );
};

export default AdviceAdviceRegister;
