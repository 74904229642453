import {
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";

interface Props {
  data: any;
  isLoading: boolean;
}

const CardsCollection = ({ data, isLoading }: Props) => {
  const [enabled, setEnabled] = useState(false);

  const { theme } = ThemeContainer();
  const dataToDisplay = [
    {
      name: "0 ~ 10 lakhs",
      invested: data?.[1]?.invested,
      current: data?.[1]?.current,
    },
    {
      name: "10 ~ 25 lakhs",
      invested: data?.[2]?.invested,
      current: data?.[2]?.current,
    },
    {
      name: "25 ~ 50 lakhs",
      invested: data?.[3]?.invested,
      current: data?.[3]?.current,
    },
    {
      name: "50 lakhs ~ 1 crore",
      invested: data?.[4]?.invested,
      current: data?.[4]?.current,
    },
    {
      name: "1 crore ~ 1.5 crore",
      invested: data?.[5]?.invested,
      current: data?.[5]?.current,
    },
    {
      name: "1.5 crore & Above",
      invested: data?.[6]?.invested,
      current: data?.[6]?.current,
    },
  ];

  return (
    <Card
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          background: `${theme.palette.primary.main}22`,
          height: 120,
          width: 120,
          borderRadius: "100%",
          position: "absolute",
          bottom: -40,
          left: -40,
          zIndex: 0,
        }}
      >
        {" "}
      </div>
      <div
        style={{
          background: `${theme.palette.primary.main}22`,
          height: 130,
          width: 130,
          borderRadius: "100%",
          position: "absolute",
          top: -45,
          right: -45,
          zIndex: 0,
        }}
      >
        {" "}
      </div>

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontSize={16}
            color={theme.palette.primary.main}
            fontWeight={700}
          >
            Portfolio Ticket Wise
          </Typography>
          <FormControlLabel
            value="end"
            control={
              <Switch
                onChange={(event) => {
                  setEnabled(event.target.checked);
                }}
              />
            }
            label={enabled ? "Invested" : "Current"}
            labelPlacement="end"
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          {dataToDisplay?.map((card, index) => (
            <Grid item container xs={12} key={index}>
              <Grid
                item
                xs={8}
                p={2}
                style={{
                  border: `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: "10px 0 0 10px",
                }}
              >
                <Typography
                  width={"100%"}
                  fontSize={16}
                  fontWeight={700}
                  textAlign={"center"}
                  color={theme.palette.primary.main}
                >
                  {card?.name || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                p={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  border: `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: "0 10px 10px 0",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Typography
                    fontSize={16}
                    width={"100%"}
                    fontWeight={700}
                    textAlign={"center"}
                    color={theme.palette.primary.main}
                  >
                    {enabled && !isLoading ? card?.invested : card?.current}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardsCollection;
