import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { matchDate } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

const SettingPage = () => {
  const { id } = useParams();

  const { theme } = ThemeContainer();

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ADVANCE_FEE_COLLECTION_TAB"],
    route: `/advance-fee-collection/${id}/`,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    High Water Mark Date
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {isFetching ? (
                      <Skeleton />
                    ) : (
                      matchDate(data?.data?.data?.highWatermarkDate)
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    High Water Mark Amount
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {isFetching ? (
                      <Skeleton />
                    ) : (
                      numberToCurrency(data?.data?.data?.highWatermark || 0)
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Advance Amount Date
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {isFetching ? (
                      <Skeleton />
                    ) : (
                      matchDate(data?.data?.data?.advanceAmountDate)
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "16%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Advance Amount
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {isFetching ? (
                      <Skeleton />
                    ) : (
                      numberToCurrency(data?.data?.data?.advanceAmount || 0)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SettingPage;
