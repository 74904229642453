import { useEffect, useRef, useState } from "react";

export const useClickOutside = (initialOpen = false) => {
  const [outSideModalOpen, setOutSideModalOpen] = useState(initialOpen);
  const popperRef = useRef(null);

  const handleClickOutside = (event: any) => {
    if (popperRef.current && !popperRef.current.contains(event.target)) {
      setOutSideModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { outSideModalOpen, setOutSideModalOpen, popperRef };
};
