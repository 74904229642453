/* eslint-disable react/no-danger */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { groupBy } from "lodash";
import React from "react";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

const CalculationPage = ({ calculationData }: any) => {
  const { theme } = ThemeContainer();

  const tableCellStyle = {
    padding: "8px !important",
  };

  const renderCalculationTable = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 8,
        }}
      >
        {calculationData?.feeplanDetails?.map((dataItem: any) => {
          return (
            <Card
              sx={{
                p: 2,
                width:
                  calculationData?.feeplanDetails.length === 1 ? "100%" : "50%",
                background: "#F8FAFC",
              }}
            >
              <Grid container>
                <Grid item xs={8}>
                  <Typography fontSize={16} fontWeight={600}>
                    {`${dataItem?.feeplanName} - ${dataItem?.revenueProductName}` ||
                      "-"}
                  </Typography>
                  <Typography py={"8px"} fontSize={12}>
                    Fee {""}{" "}
                    <span style={{ fontWeight: 600, fontSize: 14 }}>
                      {dataItem?.fee}%
                    </span>
                  </Typography>
                  <Typography fontSize={12}>
                    {`${dataItem?.startDate}-${dataItem?.endDate}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Typography fontSize={24} fontWeight={600}>
                    {numberToCurrency(dataItem?.amount?.totalFee || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </div>
    );
  };

  const ParentAccordion = styled(Accordion)(() => {
    return {
      borderRadius: "14px !important",
      border: `1px solid #E1E8F1`,
      "& .MuiAccordion-root:before": {
        backgroundColor: "transparent !important",
      },
    };
  });

  const renderAnnexure = () => {
    const annexureData = calculationData?.feeplanDetails?.map(
      (dataItem: any) => dataItem?.annexureDetails?.length ?? 0
    );

    if (annexureData?.some((length: any) => length === undefined)) return null;

    return (
      <Grid item xs={12} my={1}>
        {Object.entries(groupBy(calculationData?.feeplanDetails, "feeplanName"))
          ?.map(([key, val]) => {
            return {
              plan: key,
              data: val,
            };
          })
          .map((planData, planIndex) => {
            return (
              <React.Fragment key={planIndex}>
                {planData?.data?.map((dataItem, index) => {
                  if (!dataItem?.annexureDetails?.length) return null;

                  return (
                    <ParentAccordion>
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                          },
                        }}
                      >
                        <Typography fontSize={16} fontWeight={600}>
                          {`Annexure - ${planData?.plan} - ${dataItem?.revenueProductName}` ||
                            "Plan"}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: 0,
                          "& .MuiTableContainer-root": {
                            border: "none",
                            maxHeight: "530px",
                            overflowY: "auto",
                          },
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ padding: 16 }}>
                                  {dataItem?.revenueProductName ===
                                  "Management Fee"
                                    ? "Date Period"
                                    : "Particulars"}
                                </TableCell>
                                {dataItem?.revenueProductName ===
                                  "Management Fee" && (
                                  <TableCell
                                    align="center"
                                    style={{ padding: 16 }}
                                  >
                                    Calculation
                                  </TableCell>
                                )}
                                <TableCell
                                  align="center"
                                  style={{ padding: 16 }}
                                >
                                  Fee
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* ADVANCE Header */}
                              {dataItem?.calculationMethod === "advance" && (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <Typography
                                      fontWeight={600}
                                      color={theme.palette.dark.main}
                                    >
                                      Outstanding Amount
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  />
                                  <TableCell
                                    align="center"
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <Typography
                                      fontWeight={600}
                                      color={theme.palette.dark.main}
                                    >
                                      {numberToCurrency(
                                        dataItem?.outstandingAdvanceAmount || 0
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}

                              {dataItem?.annexureDetails?.map(
                                (annexureData: any, index: number) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          ...tableCellStyle,
                                          padding: "16px !important",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            color={theme.palette.dark.main}
                                            fontWeight={
                                              annexureData?.feeInputs ===
                                              "MANAGEMENT FEE TOTAL"
                                                ? 600
                                                : 400
                                            }
                                          >
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: annexureData?.feeInputs,
                                              }}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            color={"#545864"}
                                            fontWeight={400}
                                          >
                                            {annexureData?.startDate}
                                          </Grid>
                                        </Grid>
                                      </TableCell>

                                      {dataItem?.revenueProductName ===
                                        "Management Fee" && (
                                        <TableCell
                                          sx={{
                                            ...tableCellStyle,
                                            padding: "16px !important",
                                            fontWeight: 400,
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          <Grid container spacing={0.5}>
                                            <Grid
                                              item
                                              xs={12}
                                              display="flex"
                                              justifyContent="center"
                                              color={theme.palette.dark.main}
                                            >
                                              {annexureData?.calculationTotal}
                                            </Grid>
                                            {annexureData?.feeInputs !==
                                              "MANAGEMENT FEE TOTAL" && (
                                              <Grid
                                                item
                                                xs={12}
                                                display={"flex"}
                                                justifyContent={"center"}
                                                color={theme.palette.dark.main}
                                              >
                                                <Divider
                                                  sx={{
                                                    borderColor:
                                                      theme.palette.grey[500],
                                                    width: "150px",
                                                  }}
                                                />
                                              </Grid>
                                            )}
                                            <Grid
                                              item
                                              xs={12}
                                              display="flex"
                                              justifyContent="center"
                                              color={theme.palette.dark.main}
                                            >
                                              {annexureData?.totalDays}
                                            </Grid>
                                          </Grid>
                                        </TableCell>
                                      )}
                                      <TableCell
                                        align="center"
                                        sx={{
                                          tableCellStyle,
                                          padding: "16px !important",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        <Typography
                                          color={theme.palette.dark.main}
                                          fontWeight={
                                            annexureData?.feeInputs ===
                                            "MANAGEMENT FEE TOTAL"
                                              ? 600
                                              : 400
                                          }
                                        >
                                          {numberToCurrency(
                                            annexureData?.totalAmount
                                          )}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}

                              {/* ADVANCE Footer */}
                              {dataItem?.calculationMethod === "advance" && (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <Typography
                                      fontWeight={600}
                                      color={theme.palette.dark.main}
                                    >
                                      Advance Fee
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  />
                                  <TableCell
                                    align="center"
                                    sx={{
                                      ...tableCellStyle,
                                      padding: "16px !important",
                                      color: theme.palette.dark.main,
                                      background: `${theme.palette.primary.main}22`,
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <Typography
                                      fontWeight={600}
                                      color={theme.palette.dark.main}
                                    >
                                      {numberToCurrency(
                                        dataItem?.advanceFeeAmount || 0
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </ParentAccordion>
                  );
                })}
              </React.Fragment>
            );
          })}
      </Grid>
    );
  };

  // const renderCashTransaction = () => {
  //   if (!calculationData?.cashTransaction?.length) return null;

  //   return (
  //     <Grid item xs={4}>
  //       <TableContainer>
  //         <Table>
  //           <TableHead>
  //             <TableRow>
  //               <TableCell colSpan={3} sx={{ padding: "16px !important" }}>
  //                 <Typography fontSize={18} fontWeight={600} color={"#000000"}>
  //                   Cash Flows
  //                 </Typography>
  //               </TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableHead>
  //             <TableRow>
  //               <TableCell sx={{ padding: "8px !important" }}>Date</TableCell>
  //               <TableCell sx={{ padding: "8px !important" }}>
  //                 Transaction Type
  //               </TableCell>
  //               <TableCell sx={{ padding: "8px !important" }}>Amount</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {calculationData?.cashTransaction.map(
  //               (dataItem: any, index: number) => (
  //                 <TableRow
  //                   key={index}
  //                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //                 >
  //                   <TableCell
  //                     sx={{
  //                       ...tableCellStyle,
  //                       color: theme.palette.dark.main,
  //                       fontWeight: 400,
  //                     }}
  //                     component="th"
  //                     scope="row"
  //                   >
  //                     <Typography
  //                       color={theme.palette.dark.main}
  //                       fontWeight={400}
  //                     >
  //                       {dayjs(dataItem?.date).format("DD-MM-YYYY")}
  //                     </Typography>
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{
  //                       ...tableCellStyle,
  //                       color:
  //                         dataItem?.transactionType === "WITHDRAW"
  //                           ? theme.palette.error.main
  //                           : theme.palette.success.main,
  //                     }}
  //                     component="th"
  //                     scope="row"
  //                   >
  //                     <Typography
  //                       color={theme.palette.dark.main}
  //                       fontWeight={400}
  //                     >
  //                       {dataItem?.transactionType || "-"}
  //                     </Typography>
  //                   </TableCell>
  //                   <TableCell
  //                     sx={{ ...tableCellStyle, color: theme.palette.dark.main }}
  //                     component="th"
  //                     scope="row"
  //                   >
  //                     <Typography
  //                       color={theme.palette.dark.main}
  //                       fontWeight={400}
  //                     >
  //                       {numberToCurrency(dataItem?.billAmount)}
  //                     </Typography>
  //                   </TableCell>
  //                 </TableRow>
  //               )
  //             )}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </Grid>
  //   );
  // };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {renderCalculationTable()}
        </Grid>
        {renderAnnexure()}
        {/* {renderCashTransaction()} */}
      </Grid>
    </>
  );
};

export default CalculationPage;
