import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberToCurrency } from "utils/MathFunction";

interface Props {
  title: string;
  data: any;
  categories: any;
}

const BarChart = (props: Props) => {
  const { title, categories, data } = props;

  const Options = {
    chart: {
      type: "bar",
      height: 500,
    },
    title: {
      text: `<h1 style="color:#01657F; font-weight: 600; font-size: 18;">${title}</h1>`,
      align: "left",
    },
    xAxis: {
      categories: categories,
      title: {
        text: "",
      },
      gridLineWidth: 0,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        align: "",
      },
      gridLineWidth: 1,
      labels: {
        overflow: "justify",
        formatter(this: any) {
          const { value } = this;
          const absValue = Math.abs(value);
          let newValue = "";
          if (absValue >= 10000000) {
            newValue = `${(value / 10000000).toFixed(2)} Cr`;
          } else if (absValue >= 100000) {
            newValue = `${(value / 100000).toFixed(2)} L`;
          } else if (absValue >= 1000) {
            newValue = `${(value / 1000).toFixed(2)} K`;
          } else {
            newValue = value;
          }
          return newValue;
        },
      },
    },
    tooltip: {
      formatter(this: any) {
        return `${this.series.name}: ${numberToCurrency(this.y)}`;
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
      bar: {
        borderRadius: "50%",
        width: 15,
        height: 15,
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: data,
  };

  return <HighchartsReact highcharts={Highcharts} options={Options} />;
};

export default BarChart;
