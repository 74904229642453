/* eslint-disable no-bitwise */
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKSearchSelect from "components/GKSearchSelect";
import GKSideModal from "components/GKSideModal";
import GKTextField from "components/GKTextField";
import GKTypography from "components/GKTypography";
import { Show } from "components/Show";
import dayjs from "dayjs";
import { useFetchQuery, useMultiQueriesFetch } from "hooks/useQueries";
import { sum } from "lodash";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { LuPenSquare } from "react-icons/lu";
import { useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { hanldeReturnNaturalRows } from "utils/formHelpers";
import { QueryKeys } from "utils/queryKey";
import { v4 as uuid } from "uuid";
import AddDeleteCashModal from "./AddDeleteCashModal";
import ImportModelPortfolio from "./ImportModelPortfolio";
import UpdateNextRebalanceDate from "./UpdateNextRebalanceDate";
import UpdateRebalanceDate from "./UpdateRebalanceDate";
import VersionUploadModal from "./VersionUploadModal";

const RebalanceModal = ({ setOpen, open, selectedId }: any) => {
  const { theme } = ThemeContainer();

  const {
    state: { columnsPersist },
    dispatch,
  } = useAppContext();

  const { id } = useParams();

  const [companyMasterData, setCompanyMasterData] = useState([]);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [importSheetModal, setImportSheetModal] = useState(false);

  const [openRebalanceDateModal, setOpenRebalanceDateModal] = useState(false);

  const [openNextRebalanceDateModal, setOpenNextRebalanceDateModal] =
    useState(false);

  const [openCashModal, setOpenCashModal] = useState(false);

  const [totalWeightage, setTotalWeightage] = useState(0);

  const form = useForm({
    defaultValues: {
      transactionType: "DEPOSIT",
      rebalanceValue: "",
      nextRebalanceDate: "",
      equalWeightage: "false",
      versionName: "",
      cash: 0,
      date: dayjs(),
      startValue: "",
      minimumInvestement: "",
      totalWeightage: 0,
      oldStocks: [],
      stocks: [
        {
          isinCode: "",
          allocation: null,
          targetPrice: null,
          stopLoss: null,
          weightage: null,
          rationale: "",
          sector: "",
          gridCode: "",
          cap: "",
          companyName: "",
          uuid: uuid(),
        },
      ],
    },
  });

  const { data: modelPortfolioData, isFetching } = useFetchQuery({
    key: ["GET_VERSION_BY_ID", selectedId],
    route: `model_portfolio/${id}/version/${selectedId}/`,
    enabled: Boolean(selectedId),
    success: (response: any) => {
      form.setValue("versionName", response?.data?.version);
      form.setValue("nextRebalanceDate", response?.data?.nextRebalanceDate);
      form.setValue("startValue", response?.data?.startValue);
      form.setValue("minimumInvestement", response?.data?.minimumInvestement);
      form.setValue(
        "stocks",
        response?.data?.allocItem?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
          };
        })
      );
      setTotalWeightage(
        sum(response?.data?.allocItem?.map((d: any) => d?.allocation))
      );
      form.setValue(
        "oldStocks",
        response?.data?.allocItem?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
          };
        })
      );
    },
  });

  const { isLoading, mutate } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(
        `/model_portfolio/${id}/version/${modelPortfolioData?.data?.id}/rebalance/`,
        data
      ),
    onSuccess: () => {
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message || "Something went wrong");
    },
  });

  useMultiQueriesFetch({
    queries: [
      {
        key: [QueryKeys.COMPANY_NAMES],
        route: "/intruments/list/",
        success: (companyNames: any) => {
          setCompanyMasterData(
            companyNames?.data?.data?.map((item: any) => ({
              name: item?.companyName,
              value: item?.isin,
              gridCode: item?.gridCode,
              ...item,
            }))
          );
        },
      },
    ],
  });

  const { append, replace } = useFieldArray({
    control: form.control,
    name: "stocks",
  });

  const handleSubmitFun = (data: any) => {
    const apiData = {
      version: modelPortfolioData?.data?.id,
      cash: Number(data?.cash),
      date: dayjs(data?.date).format("YYYY-MM-DD"),
      nextRebalanceDate: dayjs(data?.nextRebalanceDate).format("YYYY-MM-DD"),
      transactionType: data?.transactionType,
      remarks: data?.remarks,
      rebalancedVersion: {
        allocItem: data?.stocks,
      },
    };
    mutate(apiData);
  };

  console.log(' form.getValues("stocks")', form.getValues("stocks"));

  return (
    <GKSideModal
      onClose={() => {
        setOpen(false);
        form.reset();
      }}
      width="100%"
      setOpen={setOpen}
      open={open}
      modalTitle={"Model Rebalance"}
      titleComponent={
        <Grid
          container
          gap={1}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="contained"
            onClick={() => {
              setImportSheetModal(true);
            }}
          >
            Rebalance Via Trades
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenUploadModal(true);
            }}
          >
            Rebalance Via Weightage
          </Button>
        </Grid>
      }
      footerButton={
        <Grid container justifyContent={"space-between"} gap={1}>
          <Button
            onClick={() => {
              setOpenCashModal(true);
            }}
            variant="outlined"
            color="dark"
          >
            Deposit or Withdraw Amount
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            size="medium"
            form="equity-basket-rebalance-form"
            loading={isLoading}
          >
            {"Submit"}
          </LoadingButton>
        </Grid>
      }
    >
      <form
        onSubmit={form.handleSubmit(handleSubmitFun)}
        id="equity-basket-rebalance-form"
      >
        <Grid container spacing={2}>
          <Grid item xs={4} display={"flex"} alignItems={"center"}>
            <TableContainer
              sx={{
                "& .MuiTableCell-root": {
                  width: "33.4%",
                },
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <Button
                        sx={{
                          padding: "0",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          setOpenRebalanceDateModal(true);
                        }}
                      >
                        <GKTypography
                          loading={isFetching}
                          fontWeight={400}
                          fontSize={12}
                          color={theme.palette.grey[500]}
                        >
                          Date of Rebalance
                          <IconButton className="outline">
                            <LuPenSquare size={14} />
                          </IconButton>
                        </GKTypography>
                        <GKTypography
                          loading={isFetching}
                          fontWeight={600}
                          fontSize={12}
                          color={"#000"}
                        >
                          {form.getValues("date") === null
                            ? "-"
                            : dayjs(form.getValues("date"))?.format(
                                "DD MMM YYYY"
                              )}
                        </GKTypography>
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                      }}
                    >
                      <GKTypography
                        loading={isFetching}
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[500]}
                      >
                        Version
                      </GKTypography>
                      <GKTypography
                        loading={isFetching}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {modelPortfolioData?.data?.version || "-"}
                      </GKTypography>
                    </TableCell>
                    <TableCell>
                      <GKTypography
                        loading={isFetching}
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[500]}
                      >
                        Portfolio Value
                      </GKTypography>
                      <GKTypography
                        loading={isFetching}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {modelPortfolioData?.data?.portfolioValue
                          ? numberToCurrency(
                              modelPortfolioData?.data?.portfolioValue
                            )
                          : "-"}
                      </GKTypography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <Typography>Weightage Type</Typography>
            <Controller
              control={form.control}
              name="equalWeightage"
              render={({ field }) => {
                return (
                  <FormControl
                    sx={{
                      "& .MuiFormGroup-root.MuiRadioGroup-root": {
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      },
                    }}
                  >
                    <RadioGroup
                      {...field}
                      onChange={(eve, val) => {
                        field.onChange(val);
                        dispatch({
                          type: "COLUMN_PERSIST",
                          data: {
                            ...columnsPersist,
                            addModelPortfoliol: {
                              stock: true,
                              weightage: true,
                              currentAllocation: JSON.parse(val),
                              action: true,
                            },
                          },
                        });
                        if (JSON.parse(form.getValues("equalWeightage"))) {
                          const equalAllocation =
                            100 / form.getValues("stocks")?.length;
                          const newData = form
                            .getValues("stocks")
                            ?.map((da) => {
                              return {
                                ...da,
                                allocation: equalAllocation,
                                weightage: equalAllocation,
                              };
                            });
                          form.setValue("stocks", newData);
                        } else {
                          const da = form
                            .getValues("stocks")
                            ?.filter(
                              (d) =>
                                !form
                                  .getValues("oldStocks")
                                  ?.map((inD) => inD?.isinCode)
                                  ?.includes(d?.isinCode)
                            )
                            ?.map((d) => {
                              return {
                                ...d,
                                allocation: 0,
                                weightage: 0,
                              };
                            });

                          form.setValue("stocks", [
                            ...form.getValues("oldStocks"),
                            ...da,
                          ]);
                        }
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Custom Weighted"
                      />
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label="Equal Weighted"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <GKClientDataGrid
              maxHeight={"4000vh"}
              name="addModelPortfoliol"
              getRowId={(row) => row.uuid}
              loading={isFetching}
              disableRowSelectionOnClick
              showFiltersButton={false}
              rows={
                form.getValues("stocks")?.map((d, index) => {
                  return {
                    ...d,
                    uuidIndex: index,
                  };
                }) || []
              }
              columns={[
                {
                  minWidth: 300,
                  field: "stock",
                  disableColumnMenu: true,
                  valueGetter: (params) => {
                    return params?.row?.companyName || "";
                  },
                  renderCell: (params) => {
                    const index = params.row.uuidIndex;
                    return (
                      <Controller
                        rules={{
                          required: true,
                        }}
                        control={form.control}
                        name={`stocks.${index}.companyName`}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              disableClearable={false}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4,
                                  }}
                                >
                                  <Typography
                                    color={theme.palette.light.main}
                                    fontSize={8}
                                    style={{
                                      background:
                                        option?.instType === "EQ"
                                          ? theme.palette.secondary.main
                                          : theme.palette.warning.main,
                                      padding: "2px 4px",
                                      borderRadius: 4,
                                    }}
                                  >
                                    {option?.instType}
                                  </Typography>
                                  <Typography whiteSpace={"nowrap"}>
                                    {option?.name}
                                  </Typography>
                                </li>
                              )}
                              sx={{
                                width: "100%",
                              }}
                              options={
                                hanldeReturnNaturalRows({
                                  options: companyMasterData,
                                  values: form.getValues("stocks"),
                                  selectedValueKey: "isinCode",
                                  optionsValueKey: "isin",
                                }) || []
                              }
                              getOptionLabel={(option) => option.name || option}
                              onChange={(e, val) => {
                                form.setValue(
                                  `stocks.${index}.isinCode`,
                                  val?.value
                                );
                                form.setValue(`stocks.${index}.cap`, val?.cap);
                                form.setValue(
                                  `stocks.${index}.gridCode`,
                                  val?.gridCode
                                );
                                form.setValue(
                                  `stocks.${index}.companyName`,
                                  val?.name
                                );
                                form.setValue(
                                  `stocks.${index}.sector`,
                                  val?.sector
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  name={`stocks.${index}.isinCode`}
                                  placeholder="Search Company"
                                  error={!!error}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  minWidth: 300,
                  disableColumnMenu: true,
                  field: "weightage",
                  headerName: `Weightage (${totalWeightage?.toFixed(2)})`,
                  type: "number",
                  valueGetter: (params) => {
                    return params?.row?.allocation
                      ? +params?.row?.allocation
                      : 0;
                  },
                  renderCell: (params) => {
                    const index = params.row.uuidIndex;

                    return (
                      <Controller
                        control={form.control}
                        name={`stocks.${index}.allocation`}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return form.getValues("equalWeightage") === "true" ? (
                            <Typography>
                              {form
                                ?.getValues(`stocks.${index}.allocation`)
                                ?.toFixed(2)}
                            </Typography>
                          ) : (
                            <GKTextField
                              {...field}
                              onBlur={() => {
                                setTotalWeightage(
                                  sum(
                                    form
                                      .getValues("stocks")
                                      ?.map((d) => d?.allocation)
                                  )
                                );
                              }}
                              name={`stocks.${index}.allocation`}
                              type="number"
                              value={form.getValues(
                                `stocks.${index}.allocation`
                              )}
                              placeholder="Weightage"
                              onChange={(e) => {
                                form.setValue(`stocks.${index}`, {
                                  ...form.getValues(`stocks.${index}`),
                                  allocation: +e.target.value,
                                  weightage:
                                    (+form.getValues("startValue") /
                                      +e.target.value) *
                                    100,
                                });
                              }}
                              error={!!error}
                            />
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  field: "currentAllocation",
                  type: "number",
                  minWidth: 130,
                  valueFormatter: (params) =>
                    moneyCommaSeparator(params?.value),
                },
                {
                  field: "action",
                  headerName: "Actions",
                  disableColumnMenu: true,
                  align: "right",
                  headerAlign: "right",
                  sortable: false,
                  renderCell: (params) => {
                    const index = params.row.uuidIndex;
                    return (
                      <IconButton
                        disabled={form.getValues("stocks").length === 1}
                        onClick={() => {
                          const equalAllocation =
                            100 /
                            form
                              .getValues("stocks")
                              ?.filter((d, i) => i !== index)?.length;

                          const updatedData = JSON.parse(
                            form.getValues("equalWeightage")
                          )
                            ? form
                                .getValues("stocks")
                                ?.filter((d, i) => i !== index)
                                ?.map((da) => {
                                  return {
                                    ...da,
                                    allocation: equalAllocation,
                                    weightage: equalAllocation,
                                  };
                                })
                            : form
                                .getValues("stocks")
                                ?.filter((d, i) => i !== index);
                          setTotalWeightage(
                            sum(updatedData?.map((d) => d?.allocation))
                          );
                          form.setValue("stocks", updatedData);
                        }}
                        color="error"
                        className="outline"
                      >
                        <FiTrash2 size={18} />
                      </IconButton>
                    );
                  },
                },
              ]}
              headerComponent={
                <Grid container justifyContent={"flex-end"}>
                  <Button
                    onClick={() => {
                      append({
                        isinCode: "",
                        allocation: null,
                        targetPrice: null,
                        stopLoss: null,
                        weightage: null,
                        rationale: "",
                        sector: "",
                        gridCode: "",
                        cap: "",
                        companyName: "",
                        uuid: uuid(),
                      });
                      if (JSON.parse(form.getValues("equalWeightage"))) {
                        const equalAllocation =
                          100 / form.getValues("stocks")?.length;
                        const newData = form.getValues("stocks")?.map((da) => {
                          return {
                            ...da,
                            allocation: equalAllocation,
                            weightage: equalAllocation,
                          };
                        });
                        setTotalWeightage(
                          sum(newData?.map((d) => d?.allocation))
                        );
                        replace(newData);
                      }
                    }}
                    variant="lightBg"
                    size="small"
                  >
                    Add Stock
                  </Button>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </form>

      <Show.When isTrue={openUploadModal}>
        <VersionUploadModal
          open={openUploadModal}
          form={form}
          setOpen={setOpenUploadModal}
        />
      </Show.When>

      <Show.When isTrue={openCashModal}>
        <AddDeleteCashModal
          open={openCashModal}
          form={form}
          setOpen={setOpenCashModal}
        />
      </Show.When>
      <Show.When isTrue={openRebalanceDateModal}>
        <UpdateRebalanceDate
          open={openRebalanceDateModal}
          form={form}
          setOpen={setOpenRebalanceDateModal}
          selectedId={selectedId}
          id={id}
        />
      </Show.When>
      <Show.When isTrue={openNextRebalanceDateModal}>
        <UpdateNextRebalanceDate
          open={openNextRebalanceDateModal}
          form={form}
          setOpen={setOpenNextRebalanceDateModal}
        />
      </Show.When>
      <Show.When isTrue={importSheetModal}>
        <ImportModelPortfolio
          setOpen={setImportSheetModal}
          open={importSheetModal}
          isRebalanceModal
          setRebalanceModal={setOpen}
          rebalanceData={selectedId}
        />
      </Show.When>
    </GKSideModal>
  );
};

export default RebalanceModal;
