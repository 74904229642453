import { Card, Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const OnboardingCards = () => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card sx={{ p: 2, borderRadius: "14px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3.5}>
              <GKButton sx={{ p: 0 }}>
                <img
                  height={"auto"}
                  width={"145px"}
                  style={{
                    borderRadius: 16,
                  }}
                  alt=""
                  src="/Logos/mailCard.svg"
                  draggable="false"
                />
              </GKButton>
            </Grid>
            <Grid
              xs={8.5}
              item
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              gap={1}
            >
              <Typography fontWeight={600} fontSize={18}>
                Need assistance?
              </Typography>
              <Typography fontWeight={400} fontSize={14} color={"#4B5565"}>
                {`Write to us at info@gridkey.in and we'll answer you.`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ p: 2, borderRadius: "14px" }}>
          <Grid container spacing={2}>
            <Grid item xs={3.5}>
              <GKButton sx={{ p: 0 }}>
                <img
                  height={"auto"}
                  width={"145px"}
                  style={{
                    borderRadius: 16,
                  }}
                  alt=""
                  src="/Logos/faqCard.svg"
                  draggable="false"
                />
              </GKButton>
            </Grid>
            <Grid
              xs={8.5}
              item
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={1}
              flexDirection={"column"}
            >
              <Typography fontWeight={600} fontSize={18}>
                Get answers to your common questions.{" "}
              </Typography>

              <GKButton
                variant="link"
                sx={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate("/faq");
                }}
                endIcon={<FiArrowRight />}
              >
                FAQ
              </GKButton>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OnboardingCards;
