import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useLocation } from "react-router-dom";
import { portfolioHoldingTransactionsColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioHoldingTradeBook = () => {
  useDocumentTitle("Holding Trade Book");

  const { state } = useLocation();

  // ===###===###===###===###===###===###===###===###===###===###
  const {
    state: { commonFilter },
  } = useAppContext();

  // ===###===###===###===###===###===###===###===###===###===###
  const requestBody = {
    take: commonFilter?.portfolioHoldingTransaction?.take,
    skip: commonFilter?.portfolioHoldingTransaction?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioHoldingTransaction?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioHoldingTransaction?.search || "",
    folioNo: state?.folioNo,
  };

  // ===###===###===###===###===###===###===###===###===###===###
  const { data, isFetching, isLoading } = useFetchQuery({
    key: ["HOLDINGS_TRANSACTIONS", requestBody],
    route: `/${state?.type}/${state?.holdingId}/company_transactions/`,
    requestBody: {
      gcode: state?.gcode,
      ...requestBody,
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###
  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/${state?.type}/${state?.holdingId}/company_transactions`}
        name={"PortfoioHolingsList"}
        paramsValue={{ key: "gcode", value: state?.gcode }}
      />
    );
  };

  // ===###===###===###===###===###===###===###===###===###===###
  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="portfolioHoldingTransaction"
      />
    );
  };

  return (
    <GKDataGrid
      loading={isFetching}
      maxHeight={"calc(100vh - 312px)"}
      columns={portfolioHoldingTransactionsColumns}
      getRowClassName={(params) => {
        return params?.row?.valid ? "" : "back-red";
      }}
      rows={
        data?.data.data?.map((data: any, index: number) => {
          return {
            ...data,
            id: index,
          };
        }) || []
      }
      density="compact"
      exportCsv={exportCsv}
      CustomPagination={!isLoading && cusPagination}
      name="portfolioHoldingTransaction"
      filterButton={false}
    />
  );
};

export default PortfolioHoldingTradeBook;
