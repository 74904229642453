/* eslint-disable react/jsx-no-bind */
import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import {
  Avatar,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKNoDataFound from "components/GKNoDataFound";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { groupBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgMathPlus } from "react-icons/cg";
import { FiPhone } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import {
  LuCalendarClock,
  LuSearch,
  LuStickyNote,
  LuTable2,
  LuUserPlus2,
} from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbLayoutKanban } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { FetchDataURL } from "utils/common";
import { getHexaColor } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import AddNewMeeting from "./AddMeeting";
import AddNewNotes from "./AddNotes";
import AddStatusAndSouceModal from "./AddStatusAndSouceModal";
import CreateLeadModal from "./CreateLeadModal";

interface BoardType {
  columns: {
    id: number;
    title: string;
    cards: any[];
  }[];
}

interface TagLists {
  branch: number;
  createdAt: string;
  id: number;
  tag: string;
  updatedAt: string;
}

interface Props {
  isLeadStatus: boolean;
  setIsLeadStatus: (isLeadStatus: boolean) => void;
}

const LeadStatusPage = ({ isLeadStatus, setIsLeadStatus }: Props) => {
  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const [cardActionAnchor, setCardActionAnchor] =
    useState<HTMLButtonElement | null>(null);

  const { theme } = ThemeContainer();

  const [leadModalOpen, setLeadModalOpen] = useState(false);

  const [addStatusModal, setAddStuatusModal] = useState(false);

  const [controlledBoard, setBoard] = useState<BoardType>({
    columns: [],
  });

  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);

  const [meetingModal, setMeetingModal] = useState(false);

  const [leadId, setLeadId] = useState(null);

  const [openNoteModal, setOpenNoteModal] = useState(false);

  // ========================================================================================================================================
  const {
    isFetched: statusFetched,
    refetch: getStatusRefetch,
    isFetching: leadStatusLoading,
  } = useFetchQuery({
    key: [QueryKeys.GET_STATUS],
    route: "/lead-status/",
    success: (data: any) => {
      const newColumns = data?.data?.data?.map((dataItem: any) => {
        return {
          id: dataItem?.id,
          title: dataItem?.leadstatus,
          // @ts-ignore
          cards: [],
        };
      });

      setBoard({
        columns: newColumns,
      });
      refetchQuery("GET_ALL_LEADS");
    },
  });

  // ========================================================================================================================================
  const { refetch: getLeadsRefetch, isFetching: leadsLoading } = useFetchQuery({
    key: ["GET_ALL_LEADS", commonFilter],
    route: FetchDataURL.LEAD_LIST,
    requestBody: {
      search: commonFilter?.lead.search,
    },
    enabled: statusFetched,
    success: (data: any) => {
      const groupedData = groupBy(data?.data?.data, "leadStatusStr");
      const newColumns = controlledBoard?.columns?.map((dataItem) => {
        return {
          ...dataItem,
          cards:
            groupedData[dataItem?.title]?.map((item: any) => {
              return {
                ...item,
                title: item?.name,
              };
            }) || [],
        };
      });
      setBoard({
        columns: newColumns,
      });
    },
  });

  // ========================================================================================================================================
  const { mutate: updateLead } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/lead/${data?.id}/change-status/`, {
        leadStatus: data?.leadStatus,
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);
      refetchQuery("GET_ALL_LEADS");
    },
    onError: () => {
      getLeadsRefetch();
      getStatusRefetch();
      toast.error("Sorry we cannot move lead to desired status.");
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledConvertLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.post(`/client/${da}/convert/`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      setCardActionAnchor(null);
      refetchQuery("GET_ALL_LEADS");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledDeleteLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.delete(`/lead/${da}`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      getLeadsRefetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });

  // ========================================================================================================================================
  function handleCardMove(_card: any, source: any, destination: any) {
    updateLead({
      leadStatus: destination?.toColumnId,
      id: _card?.id,
    });
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
  }

  // ========================================================================================================================================
  const renderCard = (card: any) => {
    return (
      <Grid
        container
        minWidth={150}
        style={{
          background: theme.palette.light.main,
        }}
        borderRadius={2}
        border={`solid 1px #E3E8EF`}
        width={300}
        mt={2}
        spacing={1}
        p={2}
        onClick={() => {
          navigate(`/app/lead/detail/${card?.id}/summary`);
        }}
      >
        <Grid
          container
          item
          xs={12}
          pl={"0 !important"}
          pt={"0 !important"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Card
            sx={{
              p: "2px",
              display: "flex",
              alignItems: "center",
              borderRadius: 100,
            }}
          >
            {card?.leadAssignedToStr && (
              <Avatar
                sx={{
                  width: 20,
                  height: 20,
                  color: paletteLightTheme.palette.primary.main,
                  background: `${paletteLightTheme.palette.primary.main}11`,
                }}
              >
                <Typography fontSize={10}>
                  {card?.leadAssignedToStr?.charAt(0)}
                  {card?.leadAssignedToStr?.charAt(1)}
                </Typography>
              </Avatar>
            )}
            <Typography fontWeight={400} fontSize={10} color={"#697586"}>
              {card?.leadAssignedToStr || "-"}
            </Typography>
          </Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <LuCalendarClock
              size={14}
              color={
                card?.hasMeeting
                  ? paletteLightTheme.palette.success.main
                  : paletteLightTheme.palette.grey[400]
              }
            />
            <IconButton
              onClick={(event) => {
                setLeadId(card.id);
                setCardActionAnchor(event.currentTarget);
              }}
            >
              <BsThreeDotsVertical size={16} />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} pl={"0 !important"}>
          <Typography fontWeight={600} fontSize={16}>
            {card?.name}
          </Typography>
          <Typography fontWeight={400} fontSize={12} color={"#697586"}>
            {card?.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12} pl={"0 !important"}>
          <Typography
            fontSize={12}
            fontWeight={400}
            color={"#697586"}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <HiOutlineMail size={16} />
            {card?.email || "-"}
          </Typography>{" "}
        </Grid>
        <Grid item xs={12} pl={"0 !important"}>
          <Typography
            fontSize={12}
            fontWeight={400}
            color={"#697586"}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <FiPhone size={16} />
            {card?.phoneNumber || "-"}
          </Typography>{" "}
        </Grid>
        <Grid item xs={12} mt={1} mb={1} pl={"0 !important"}>
          <Divider />
        </Grid>
        <Grid
          item
          pl={"0 !important"}
          container
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            {card?.tagsList?.slice(0, 2)?.map((dataItem: TagLists) => {
              return (
                <Chip
                  sx={{
                    padding: 0,
                    height: 26,
                    background: `${getHexaColor(dataItem?.tag)}22`,
                    color: `${getHexaColor(dataItem?.tag)}`,
                    borderRadius: 2,
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": {
                      color: `${getHexaColor(dataItem?.tag)}`,
                    },
                  }}
                  label={dataItem?.tag}
                />
              );
            })}
            {card?.tagsList?.length > 2 && (
              <GKButton
                sx={{
                  padding: "12px 6px",
                  lineHeight: 0,
                  minWidth: "1px !important",
                }}
                variant="outlined"
                color="dark"
              >
                +{card?.tagsList?.length > 2 && card?.tagsList?.length - 2}
              </GKButton>
            )}
          </Grid>
          <Grid
            item
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={0.2}
          >
            <Typography color="#9AA4B2">{card?.notesCount}</Typography>
            <LuStickyNote color="#9AA4B2" />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // ========================================================================================================================================
  const renderColumnHeader = (column: any) => {
    return (
      <div
        style={{
          background: `${getHexaColor(column.title)}1`,
          border: `solid 1px ${getHexaColor(column.title)}`,
          borderRadius: 10,
          padding: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <GoDotFill color={getHexaColor(column.title)} />
        <Typography
          fontWeight={600}
          fontSize={14}
          style={{
            color: getHexaColor(column.title),
          }}
        >
          {column.title}
          <span
            style={{
              color: "#697586",
              fontWeight: 500,
            }}
          >
            {` (${column?.cards?.length})`}
          </span>
        </Typography>
      </div>
    );
  };

  // ========================================================================================================================================
  const loading = leadsLoading || leadStatusLoading;

  return (
    <>
      <Grid
        container
        spacing={1}
        p={1}
        sx={{
          "& .react-kanban-column": {
            backgroundColor: `${theme.palette.light.main}`,
            padding: 0,
          },
        }}
      >
        <Grid item xs={2}>
          <GKTextField
            placeholder={"Search..."}
            sx={{
              "& .MuiInputBase-root ": {
                height: "35px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E3E8EF",
              },
            }}
            value={commonFilter?.lead?.search}
            onChange={(event) => {
              dispatch({
                type: "FILTER",
                data: {
                  ...commonFilter,
                  lead: {
                    ...commonFilter.lead,
                    search: event.target.value,
                  },
                },
              });
            }}
            type="search"
            InputProps={{
              startAdornment: (
                <LuSearch size={26} color={theme.palette.grey[500]} />
              ),
            }}
          />{" "}
        </Grid>
        <Grid item xs={10}>
          <Grid container justifyContent={"flex-end"} gap={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 8,
                gap: 4,
                padding: 4,
                border: `1px solid ${paletteLightTheme.palette.border.main}`,
              }}
            >
              <IconButton
                sx={{
                  background: !isLeadStatus
                    ? "#fff"
                    : paletteLightTheme.palette.primary.main,
                  color: !isLeadStatus
                    ? "#697586"
                    : paletteLightTheme.palette.light.main,
                  borderRadius: 2,
                  ":hover": {
                    background: !isLeadStatus
                      ? "#fff"
                      : paletteLightTheme.palette.primary.main,
                    color: !isLeadStatus
                      ? "#697586"
                      : paletteLightTheme.palette.light.main,
                  },
                }}
                onClick={() => {
                  setIsLeadStatus(!isLeadStatus);
                }}
              >
                <TbLayoutKanban />
              </IconButton>
              <IconButton
                sx={{
                  background: isLeadStatus
                    ? "#fff"
                    : paletteLightTheme.palette.primary.main,
                  color: isLeadStatus
                    ? "#697586"
                    : paletteLightTheme.palette.light.main,
                  borderRadius: 2,
                  ":hover": {
                    background: isLeadStatus
                      ? "#fff"
                      : paletteLightTheme.palette.primary.main,
                    color: isLeadStatus
                      ? "#697586"
                      : paletteLightTheme.palette.light.main,
                  },
                }}
                onClick={() => {
                  setIsLeadStatus(!isLeadStatus);
                }}
              >
                <LuTable2 />
              </IconButton>
            </div>
            <GKButton
              variant="contained"
              size="medium"
              startIcon={<CgMathPlus size={14} />}
              onClick={() => setLeadModalOpen(true)}
            >
              Add New Lead
            </GKButton>
            <GKButton
              color="dark"
              size="medium"
              variant="outlined"
              startIcon={<CgMathPlus size={14} />}
              onClick={() => setAddStuatusModal(true)}
            >
              Add New Status
            </GKButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {!loading &&
        controlledBoard.columns?.map((i) => i.cards)?.flat()?.length === 0 ? (
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <GKNoDataFound />
          </Grid>
        ) : (
          <Board
            disableColumnDrag
            renderCard={renderCard}
            onCardDragEnd={handleCardMove}
            renderColumnHeader={renderColumnHeader}
          >
            {controlledBoard}
          </Board>
        )}
      </Grid>

      {leadModalOpen && (
        <CreateLeadModal open={leadModalOpen} setOpen={setLeadModalOpen} />
      )}

      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStuatusModal}
          type={"STATUS"}
        />
      )}

      <Popover
        open={Boolean(cardActionAnchor)}
        anchorEl={cardActionAnchor}
        onClose={() => {
          setCardActionAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "14%", borderRadius: 8 },
        }}
      >
        <Grid container sx={{ py: "6px" }}>
          <Grid
            item
            xs={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              onClick={() => {
                handledConvertLead(leadId);
              }}
              startIcon={<LuUserPlus2 size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Convert to Client
            </GKButton>
          </Grid>
          <Grid
            item
            xs={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={<LuCalendarClock size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setMeetingModal(true);
                setIsScheduleMeeting(false);
                setCardActionAnchor(null);
              }}
            >
              Add Meeting
            </GKButton>
          </Grid>
          <Grid
            item
            xs={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={<LuStickyNote size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setOpenNoteModal(true);
                setCardActionAnchor(null);
              }}
            >
              Add Notes
            </GKButton>
          </Grid>
          <Grid item xs={12}>
            <GKButton
              onClick={() => {
                handledDeleteLead(leadId);
                setCardActionAnchor(null);
              }}
              startIcon={<RiDeleteBinLine size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Delete
            </GKButton>
          </Grid>
        </Grid>
      </Popover>

      <Show.When isTrue={meetingModal}>
        <AddNewMeeting
          open={meetingModal}
          setOpen={setMeetingModal}
          leadId={leadId}
          meetingData={null}
          refetch={getLeadsRefetch}
          schedule={isScheduleMeeting}
        />
      </Show.When>

      <Show.When isTrue={openNoteModal}>
        <AddNewNotes
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          leadId={leadId}
          refetch={getLeadsRefetch}
          noteData={null}
        />
      </Show.When>
    </>
  );
};

export default LeadStatusPage;
