import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKSideModal from "components/GKSideModal";
import GKTextArea from "components/GKTextBar";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { LeadSchema, panRegex } from "utils/validationSchema";

interface Props {
  id: any;
  open: any;
  setOpen: any;
}

const UpdateLead = ({ id, open, setOpen }: Props) => {
  const {
    state: { cityList, countryList, stateList },
    dispatch,
  } = useAppContext();

  const { mutate: updateLead } = useMutation({
    mutationFn: (data: any) => axiosInstance.put(`/lead/${id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_ALL_LEADS");
      refetchQuery(QueryKeys.GET_LEAD);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { data: leadData, isFetching } = useFetchQuery({
    key: ["GET_LEAD_BY_ID"],
    route: `/lead/${id}/`,
    enabled: Boolean(id),
  });

  const form = useForm({
    defaultValues: {
      name: leadData?.data?.data ? leadData?.data?.data?.name : "",
      leadInformation: {
        companyName: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.companyName
          : "",
        leadScore: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadScore
          : 0,
        jobTitle: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.jobTitle
          : "",
        lead_score: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadScore
          : "",
        address1: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.address1
          : "",
        address2: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.address2
          : "",
        city: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.city
          : "",
        state: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.state
          : "",
        pincode: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.pincode
          : "",
        country: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.country
          : "",
        income: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.income
          : 0,
        leadSummary: leadData?.data?.data
          ? leadData?.data?.data?.leadInformation?.leadSummary
          : "",
      },
      phoneNumber: leadData?.data?.data
        ? leadData?.data?.data?.phoneNumber
        : "",
      countryCode: leadData?.data?.data
        ? leadData?.data?.data?.countryCode
        : "",
      lastContacted: leadData?.data?.data
        ? leadData?.data?.data?.lastContacted
        : "",
      email: leadData?.data?.data ? leadData?.data?.data?.email : "",
      tags: leadData?.data?.data ? leadData?.data?.data?.tags : "",
      leadAssignedTo: leadData?.data?.data
        ? leadData?.data?.data?.leadAssignedTo
        : "",
      sourceOfLead: leadData?.data?.data
        ? leadData?.data?.data?.sourceOfLead
        : "",
      leadStatus: leadData?.data?.data ? leadData?.data?.data?.leadStatus : "",
      panNumber: leadData?.data?.data ? leadData?.data?.data?.panNumber : "",
    },
    resolver: yupResolver(LeadSchema),
    values: leadData?.data?.data,
  });

  form.watch();

  return (
    <GKSideModal
      width="80%"
      open={open}
      setOpen={setOpen}
      modalTitle="Update Lead Details"
      footerButton={
        <Grid container justifyContent={"flex-end"}>
          <GKButton form="lead-update-form" type="submit" variant="contained">
            Update Lead
          </GKButton>
        </Grid>
      }
    >
      <form
        onSubmit={form.handleSubmit((values) => updateLead(values))}
        id="lead-update-form"
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Full Name"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="email"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Email Address"
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message as string}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="phoneNumber"
                control={form.control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("countryCode")}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="panNumber"
                control={form.control}
                rules={{
                  pattern: {
                    value: panRegex,
                    message: "PAN should have this format ABCDE1234A",
                  },
                  maxLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                  minLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="PAN"
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.jobTitle"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Position"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.companyName"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Company Name"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.leadScore"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Lead Value"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.address1"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Address 1"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.address2"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Address 2"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.pincode"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    loading={isFetching}
                    inputLabel="Pin Code"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.country"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="Country"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select Country" />
                    )}
                    options={countryList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      form.setValue("city", "");
                      form.setValue("state", "");
                      dispatch({
                        type: "SELECTED_COUNTRY",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.state"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="State"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select State" />
                    )}
                    options={stateList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      form.setValue("city", "");
                      dispatch({
                        type: "SELECTED_STATE",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="leadInformation.city"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="City"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select City" />
                    )}
                    options={cityList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      dispatch({
                        type: "SELECTED_CITY",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="lastContacted"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    loading={isFetching}
                    inputLabel="Last Contacted"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="leadInformation.leadSummary"
              control={form.control}
              render={({ field }) => (
                <GKTextArea
                  {...field}
                  minRows={5}
                  loading={isFetching}
                  inputLabel="Description"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKSideModal>
  );
};

export default UpdateLead;
