/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { MdExpandMore } from "react-icons/md";
import { handleCapitalize } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";

interface Props {
  responseData: any[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

const ModelCompanyAllocationCreatOrder = (props: Props) => {
  const { responseData, form } = props;

  const handleReturnCloumn = (type: string) => {
    switch (type) {
      case "allocation":
        return [
          {
            ...extraColumnsField(
              "Portfolio Name / Code",
              "portfolioCode",
              180,
              1
            ),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            ...extraColumnsField(
              "Portfolio Weightage",
              "portfolioWeightage",
              180,
              1
            ),
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.portfolioWeightage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Ledger Balance", "ledgerBalance", 180, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.ledgerBalance || 0)}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "Transaction Type",
              "transaction type",
              150,
              1
            ),
            valueFormatter: (params) => handleCapitalize(params?.value),
            editable: false,
          },
          {
            ...extraColumnsField("Quantity", "quantity", 100, 1),
            valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
            valueGetter: (params) => params.row.quantity || "0",
            editable: false,
          },
          {
            ...extraColumnsField("Trade Amount", "amount", 180, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.amount)}{" "}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "After Trade Weightage",
              "afterTradeWeightage",
              200,
              1
            ),
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "exit":
        return [
          {
            ...extraColumnsField(
              "Portfolio Name / Code",
              "portfolioCode",
              200,
              1
            ),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            ...extraColumnsField(
              "Portfolio Weightage",
              "portfolioWeightage",
              180,
              1
            ),
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.portfolioWeightage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Holding Qty", "currentQuantity", 130, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.currentQuantity)?.toFixed(0)}
                </Typography>
              );
            },
          },

          {
            ...extraColumnsField("Holding Amount", "prevAmount", 150, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(Number(params?.row?.prevAmount))}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "Transaction Type",
              "transaction type",
              150,
              1
            ),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row["transaction type"])}{" "}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Exit Weightage", "allocation", 180, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.allocation)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField("Exit Qty", "quantity", 100, 1),
            renderCell: (params) => {
              return <Typography>{params?.row?.quantity} </Typography>;
            },
          },
          {
            ...extraColumnsField("Exit Amount", "amount", 120, 1),
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.amount)}{" "}
                </Typography>
              );
            },
          },
          {
            ...extraColumnsField(
              "After Trade Ledger Balance",
              "afterTradeLedgerBalance",
              230,
              1
            ),
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
          {
            ...extraColumnsField(
              "After Trade Weightage",
              "afterTradeWeightage",
              200,
              1
            ),
            valueGetter: (value) => Number(value.value).toFixed(2),
          },
        ] as GridColDef[];

      default:
        return [] as GridColDef[];
    }
  };

  return (
    <div style={{ margin: "8px 0" }}>
      {form?.getValues("allocationType") === "allocation" ? (
        responseData?.map((dataItem: any) => {
          return (
            <Accordion key={dataItem?.id}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontWeight={600} fontSize={14}>
                      {dataItem?.portfolioName}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={400} fontSize={12}>
                      {dataItem?.portfolioCode}
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <GKClientDataGrid
                  name="modelCompanyAllocationCreatOrder"
                  density="compact"
                  showFiltersButton={false}
                  columns={[
                    {
                      ...extraColumnsField("Company Name", "company", 180, 1),
                    },

                    {
                      ...extraColumnsField(
                        "Portfolio Weightage",
                        "portfolioWeightage",
                        180,
                        1
                      ),
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {Number(params?.row?.portfolioWeightage)?.toFixed(
                              2
                            )}
                            %
                          </Typography>
                        );
                      },
                    },
                    {
                      ...extraColumnsField(
                        "Ledger Balance",
                        "ledgerBalance",
                        180,
                        1
                      ),
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {moneyCommaSeparator(
                              params?.row?.ledgerBalance || 0
                            )}
                          </Typography>
                        );
                      },
                    },
                    {
                      ...extraColumnsField(
                        "Transaction Type",
                        "transaction type",
                        150,
                        1
                      ),
                      valueFormatter: (params) =>
                        handleCapitalize(params?.value),
                      editable: false,
                    },
                    {
                      ...extraColumnsField("Quantity", "quantity", 100, 1),
                      valueFormatter: (params) =>
                        moneyCommaSeparatorPoint(params.value),
                      valueGetter: (params) => params.row.quantity || "0",

                      editable: false,
                    },
                    {
                      ...extraColumnsField("Trade Amount", "amount", 180, 1),
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {moneyCommaSeparator(params?.row?.amount)}{" "}
                          </Typography>
                        );
                      },
                    },
                    {
                      ...extraColumnsField(
                        "After Trade Weightage",
                        "afterTradeWeightage",
                        200,
                        1
                      ),
                      valueGetter: (value) => moneyCommaSeparator(value.value),
                    },
                  ]}
                  rows={
                    dataItem?.plan?.map((data: any, i: number) => {
                      return {
                        ...data,
                        index: i,
                        id: i,
                      };
                    }) || []
                  }
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <GKClientDataGrid
          name="modelCompanyAllocationCreatOrder"
          showFiltersButton={false}
          showSearch={false}
          headerComponent={
            <Show.When
              isTrue={form.getValues("allocationType") === "rebalance"}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    Company Name:
                    <Typography fontWeight={600}>
                      {responseData[0]?.plan[0]?.company || "-"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize={14}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    CMP:
                    <Typography fontWeight={600}>
                      {responseData[0]?.plan[0]?.cmp || "-"}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Show.When>
          }
          columns={handleReturnCloumn(form?.getValues("allocationType")) || []}
          rows={
            responseData?.map((data: any, i: number) => {
              return {
                ...data,
                ...data?.plan[0],
                index: i,
              };
            }) || []
          }
        />
      )}
    </div>
  );
};

export default ModelCompanyAllocationCreatOrder;
