import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

type Order = "asc" | "desc";

const usePortfolioHoldingDetails = () => {
  const { id } = useParams<string>();

  const {
    state: { columnsPersist, commonFilter },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [order, setOrder] = useState<Order>("desc");

  const [tableColumnModalOpen, setTableColumnModalOpen] = useState(false);

  const [filterModal, setFilterModal] = useState(false);

  const [isRowOpened, setIsRowOpened] = useState({
    cash: true,
    equity: true,
    mutualFund: true,
  });

  const [orderBy, setOrderBy] = useState<any>("companyName");

  const open = Boolean(anchorEl);

  const popperId = open ? "simple-popper" : undefined;

  const [allHoldings, setAllHoldings] = useState([]);

  const [showNonZero, setShowNonZero] = useState(false);

  const queryClient = useQueryClient();

  const [isXirrRefresh, setIsXirrRefresh] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // ============================================================
  const { isFetching: xirrLoading } = useFetchQuery({
    route: `/portfolios/${id}/refresh_xirr/`,
    key: ["GET_PORTFOLIO_XIRR"],
    enabled: isXirrRefresh,
    success: () => {
      queryClient?.refetchQueries(["GET_PORTFOLIO_HOLDINGS_CARD"]);
      setIsXirrRefresh(false);
    },
  });

  // ============================================================
  const requestBody = {
    take: 100000,
    skip: commonFilter?.portfolioHolding?.skip,
    search: searchText || "",
    show_zero_holding: commonFilter?.portfolioHolding?.showNonZero,
    filter: JSON.stringify(commonFilter?.portfolioHolding.customFilter),
  };

  // ============================================================
  const { isFetching: isLoading } = useFetchQuery({
    key: [QueryKeys.HOLDINGS_LIST, id, requestBody],
    route: `/portfolios/${+id}/holdings/`,
    requestBody,
    success: (data: any) => {
      const convertedData = data?.data?.data?.map((item: any, index: any) => {
        return {
          ...item,
          id: index,
          lastPrice: item?.isin === "INGridkeyCash" ? "" : item?.lastPrice,
          pastPrice: item?.isin === "INGridkeyCash" ? "" : item?.pastPrice,
          unrealisedGain:
            item?.isin === "INGridkeyCash" ? "" : item?.unrealisedGain,
          realisedGain:
            item?.isin === "INGridkeyCash" ? "" : item?.realisedGain,
          otherGains: item?.isin === "INGridkeyCash" ? "" : item?.otherGains,
          quantity: item?.isin === "INGridkeyCash" ? "" : item?.quantity,
          rawQuantity: item?.isin === "INGridkeyCash" ? "" : item?.rawQuantity,
          isValid: item?.isin === "INGridkeyCash" ? true : item?.isValid,
          investedAmount:
            item?.isin === "INGridkeyCash" ? "" : item?.investedAmount,
          totalNetInvestment:
            item?.isin === "INGridkeyCash" ? "" : item?.totalNetInvestment,
          currentAmount: item?.currentAmount,
          totalAmount: item?.isin === "INGridkeyCash" ? "" : item?.totalAmount,
          todaysGain: item?.isin === "INGridkeyCash" ? "" : item?.todaysGain,
          todaysGainPercentage:
            item?.isin === "INGridkeyCash" ? "" : item?.todaysGainPercentage,
          lastTradedPrice:
            item?.isin === "INGridkeyCash" ? "" : item?.lastTradedPrice,
          avgBuyPrice: item?.isin === "INGridkeyCash" ? "" : item?.avgBuyPrice,
          cmp: item?.isin === "INGridkeyCash" ? "" : item?.cmp,
          yesterdayValue:
            item?.isin === "INGridkeyCash" ? "" : item?.yesterdayValue,
          unrealisedGainPercentage:
            item?.isin === "INGridkeyCash"
              ? ""
              : item?.unrealisedGainPercentage,
          absoluteReturn:
            item?.isin === "INGridkeyCash" ? "" : item?.absoluteReturn,
          weightage: item?.weightage,
          ltHoldingsCount:
            item?.isin === "INGridkeyCash" ? "" : item?.ltHoldingsCount,
        };
      });

      setAllHoldings(convertedData);
    },
  });

  // ============================================================
  const { data: holdingTableParentRows, isFetching: tableLoading } =
    useFetchQuery({
      key: ["GET_PORTFOLIO_HOLDINGS_TABLE_PARENT_ROWS"],
      route: `/portfolios/${+id}/holdings-summary/`,
    });

  // ============================================================
  const { mutate: deletePortfolio } = useMutation({
    mutationKey: ["DELETE_PORTFOLIO"],
    mutationFn: (id: number) => axiosInstance.delete(`/portfolios/${id}/`),
    onSuccess: (response) => {
      navigate(-1);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // ============================================================
  const loading = xirrLoading || isLoading || tableLoading;
  // ============================================================
  return {
    id,
    deletePortfolio,
    loading,
    openConfirmationModal,
    setOpenConfirmationModal,
    navigate,
    allHoldings,
    showNonZero,
    setShowNonZero,
    setSearchText,
    searchText,
    columnsPersist,
    dispatch,
    tableColumnModalOpen,
    setTableColumnModalOpen,
    order,
    setOrder,
    anchorEl,
    setAnchorEl,
    popperId,
    filterModal,
    setFilterModal,
    isRowOpened,
    setIsRowOpened,
    orderBy,
    setOrderBy,
    open,
    holdingTableParentRows,
  };
};

export default usePortfolioHoldingDetails;
