import { Typography } from "@mui/material";
import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";
import React, { Fragment } from "react";
import { FiTrash2 } from "react-icons/fi";
import { paletteLightTheme } from "./ColorTheme";
import { matchDate } from "./helpers";

interface Props {
  name: string;
}
export const TableFiltering: React.FC<Props> = (props) => {
  const { name } = props;

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const handleDeleteFilter = (type: string, value: any) => {
    const updatedCustomFilter = {
      ...commonFilter[name as keyof CommonFilterState]?.customFilter,
    };

    switch (type) {
      case "start_date":
      case "end_date":
      case "financialYear":
        delete updatedCustomFilter.start_date;
        delete updatedCustomFilter.end_date;
        delete updatedCustomFilter.financialYear;
        break;

      case "date":
        delete updatedCustomFilter.date;
        break;

      case "portfolioFilterName":
        updatedCustomFilter.portfolioFilterName =
          updatedCustomFilter.portfolioFilterName.filter(
            (item: any) => item.value !== value
          );
        updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
          (item: any) => item !== value
        );
        break;

      case "type":
        updatedCustomFilter.type = updatedCustomFilter.type.filter(
          (item: any) => item !== value
        );
        break;

      case "format":
        updatedCustomFilter.format = updatedCustomFilter.format.filter(
          (item: any) => item !== value
        );
        break;

      case "source":
        updatedCustomFilter.source = updatedCustomFilter.source.filter(
          (item: any) => item !== value
        );
        break;

      case "company":
        updatedCustomFilter.company = updatedCustomFilter.company.filter(
          (item: any) => item.value !== value
        );
        updatedCustomFilter.gcode = updatedCustomFilter.gcode.filter(
          (item: any) => item !== value
        );
        break;

      case "employeeName":
        updatedCustomFilter.employeeName =
          updatedCustomFilter.employeeName.filter(
            (item: any) => item.value !== value
          );
        updatedCustomFilter.employee = updatedCustomFilter.employee.filter(
          (item: any) => item !== value
        );
        break;

      case "clientName":
        updatedCustomFilter.clientName = updatedCustomFilter.clientName.filter(
          (item: any) => item.value !== value
        );
        updatedCustomFilter.client = updatedCustomFilter.client.filter(
          (item: any) => item !== value
        );
        break;

      case "groupName":
        updatedCustomFilter.groupName = updatedCustomFilter.groupName.filter(
          (item: any) => item.value !== value
        );
        updatedCustomFilter.group = updatedCustomFilter.group.filter(
          (item: any) => item !== value
        );
        break;

      case "feePlanName":
        updatedCustomFilter.feePlanName =
          updatedCustomFilter.feePlanName.filter(
            (item: any) => item.value !== value
          );
        updatedCustomFilter.feePlan = updatedCustomFilter.feePlan.filter(
          (item: any) => item !== value
        );
        break;

      default:
        break;
    }

    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [name]: {
          ...commonFilter[name as keyof CommonFilterState],
          customFilter: updatedCustomFilter,
        },
      },
    });
  };

  // const handleDeleteFilter = (type: string, value: any) => {
  //   const updatedCustomFilter = {
  //     ...commonFilter[name as keyof CommonFilterState]?.customFilter,
  //   };

  //   if (
  //     type === "start_date" ||
  //     type === "end_date" ||
  //     type === "financialYear"
  //   ) {
  //     delete updatedCustomFilter.start_date;
  //     delete updatedCustomFilter.end_date;
  //     delete updatedCustomFilter.financialYear;
  //   } else if (type === "date") {
  //     delete updatedCustomFilter.date;
  //   } else if (type === "portfolioFilterName") {
  //     updatedCustomFilter.portfolioFilterName =
  //       updatedCustomFilter.portfolioFilterName.filter(
  //         (item: any) => item.value !== value
  //       );
  //     updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "type") {
  //     updatedCustomFilter.type = updatedCustomFilter.type.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "format") {
  //     updatedCustomFilter.format = updatedCustomFilter.format.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "source") {
  //     updatedCustomFilter.source = updatedCustomFilter.source.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "company") {
  //     updatedCustomFilter.company = updatedCustomFilter.company.filter(
  //       (item: any) => item.value !== value
  //     );
  //     updatedCustomFilter.gcode = updatedCustomFilter.gcode.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "employeeName") {
  //     updatedCustomFilter.employeeName =
  //       updatedCustomFilter.employeeName.filter(
  //         (item: any) => item.value !== value
  //       );
  //     updatedCustomFilter.employee = updatedCustomFilter.employee.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "clientName") {
  //     updatedCustomFilter.clientName = updatedCustomFilter.clientName.filter(
  //       (item: any) => item.value !== value
  //     );
  //     updatedCustomFilter.client = updatedCustomFilter.client.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "groupName") {
  //     updatedCustomFilter.groupName = updatedCustomFilter.groupName.filter(
  //       (item: any) => item.value !== value
  //     );
  //     updatedCustomFilter.group = updatedCustomFilter.group.filter(
  //       (item: any) => item !== value
  //     );
  //   } else if (type === "feePlanName") {
  //     updatedCustomFilter.feePlanName = updatedCustomFilter.feePlanName.filter(
  //       (item: any) => item.value !== value
  //     );
  //     updatedCustomFilter.feePlan = updatedCustomFilter.feePlan.filter(
  //       (item: any) => item !== value
  //     );
  //   }

  //   dispatch({
  //     type: "FILTER",
  //     data: {
  //       ...commonFilter,
  //       [name]: {
  //         ...commonFilter[name as keyof CommonFilterState],
  //         customFilter: updatedCustomFilter,
  //       },
  //     },
  //   });
  // };

  const customFilter =
    commonFilter[name as keyof CommonFilterState]?.customFilter || {};

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {customFilter?.date && (
        <>
          <div
            className="filter-item"
            style={{
              background: `${paletteLightTheme.palette.primary.main}22`,
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {matchDate(customFilter?.date)}
            </Typography>

            <FiTrash2
              size={14}
              color={paletteLightTheme.palette.error.main}
              cursor={"pointer"}
              onClick={() => handleDeleteFilter("date", customFilter.date)}
            />
          </div>
          &nbsp;
        </>
      )}

      {customFilter?.start_date && customFilter?.end_date && (
        <>
          <div
            className="filter-item"
            style={{
              background: `${paletteLightTheme.palette.primary.main}22`,
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {matchDate(customFilter?.start_date)} -{" "}
              {matchDate(customFilter?.end_date)}
            </Typography>

            <FiTrash2
              size={14}
              color={paletteLightTheme.palette.error.main}
              cursor={"pointer"}
              onClick={() => {
                handleDeleteFilter("start_date", "");
                handleDeleteFilter("end_date", "");
                handleDeleteFilter("financialYear", null);
              }}
            />
          </div>
          &nbsp;
        </>
      )}

      {customFilter?.company &&
        customFilter?.company?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("company", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}

      {customFilter?.employeeName?.map((dataItem: any, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem.name}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() =>
                  handleDeleteFilter("employeeName", dataItem.value)
                }
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.clientName &&
        customFilter?.clientName?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("clientName", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}

      {customFilter?.groupName &&
        customFilter?.groupName.map((dataItem: any, index: number) => {
          return (
            <Fragment key={index}>
              <div key={dataItem.value} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() =>
                    handleDeleteFilter("groupName", dataItem.value)
                  }
                />
              </div>{" "}
              &nbsp;
            </Fragment>
          );
        })}

      {customFilter?.portfolioFilterName &&
        customFilter?.portfolioFilterName?.map(
          (dataItem: any, index: number) => {
            return (
              <Fragment key={index}>
                <div key={dataItem?.name} className="filter-item">
                  <Typography fontSize={12} fontWeight={600}>
                    {dataItem?.name}
                  </Typography>

                  <FiTrash2
                    size={14}
                    color={paletteLightTheme.palette.error.main}
                    cursor={"pointer"}
                    onClick={() =>
                      handleDeleteFilter("portfolioFilterName", dataItem?.value)
                    }
                  />
                </div>{" "}
                &nbsp;
              </Fragment>
            );
          }
        )}

      {customFilter?.format?.map((dataItem: string, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem?.replaceAll("_", " ")}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() => handleDeleteFilter("format", dataItem)}
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.source?.map((dataItem: any, index: number) => {
        return (
          <Fragment key={index}>
            <div
              key={dataItem}
              className="filter-item"
              style={{
                background: `${paletteLightTheme.palette.primary.main}22`,
              }}
            >
              <Typography fontSize={12} fontWeight={600}>
                {dataItem}
              </Typography>

              <FiTrash2
                size={14}
                color={paletteLightTheme.palette.error.main}
                cursor={"pointer"}
                onClick={() => handleDeleteFilter("source", dataItem)}
              />
            </div>
            &nbsp;
          </Fragment>
        );
      })}

      {customFilter?.type &&
        customFilter?.type?.map((dataItem: any, index: number) => {
          return (
            <Fragment key={index}>
              <div key={dataItem} className="filter-item">
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  textTransform={"uppercase"}
                >
                  {dataItem?.replaceAll("_", " ")}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => handleDeleteFilter("type", dataItem)}
                />
              </div>{" "}
              &nbsp;
            </Fragment>
          );
        })}

      {customFilter?.feePlanName &&
        customFilter?.feePlanName?.map((dataItem: any) => {
          return (
            <>
              <div key={dataItem} className="filter-item">
                <Typography fontSize={12} fontWeight={600}>
                  {dataItem.name}
                </Typography>

                <FiTrash2
                  size={14}
                  color={paletteLightTheme.palette.error.main}
                  cursor={"pointer"}
                  onClick={() => {
                    handleDeleteFilter("feePlanName", dataItem.value);
                  }}
                />
              </div>{" "}
              &nbsp;
            </>
          );
        })}
    </div>
  );
};

// export const SingleNameFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             employeeName: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.employeeName.filter(
//               (item: any) => item.value !== value
//             ),
//             employee: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.employee.filter((id: any) => id !== value),
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter;

//   return (
//     customFilter?.employeeName &&
//     customFilter?.employeeName.map((dataItem: any, index: number) => {
//       return (
//         <Fragment key={index}>
//           <div key={dataItem.value} className="filter-item">
//             <Typography fontSize={12} fontWeight={600}>
//               {dataItem?.name}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter(dataItem.value)}
//             />
//           </div>{" "}
//          &nbsp;
//         </Fragment>
//       );
//     })
//   );
// };

// // COMPANY
// export const CompanyFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             company: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.company.filter(
//               (item: any) => item.value !== value
//             ),
//             gcode: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.gcode.filter((gcode: string) => gcode !== value),
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter;

//   return (
//     customFilter?.company &&
//     customFilter?.company?.map((dataItem: any, index: number) => {
//       return (
//         <Fragment key={index}>
//           <div key={dataItem} className="filter-item">
//             <Typography fontSize={12} fontWeight={600}>
//               {dataItem.name}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter(dataItem.value)}
//             />
//           </div>{" "}
//          &nbsp;
//         </Fragment>
//       );
//     })
//   );
// };

// // CLIENT_FILTER
// export const ClientFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             clientName: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.clientName.filter(
//               (item: any) => item.value !== value
//             ),
//             client: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.client.filter((id: any) => id !== value),
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter;

//   return (
//     customFilter?.clientName &&
//     customFilter?.clientName.map((dataItem: any, index: number) => {
//       return (
//         <Fragment key={index}>
//           <div key={dataItem.value} className="filter-item">
//             <Typography fontSize={12} fontWeight={600}>
//               {dataItem.name}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter(dataItem.value)}
//             />
//           </div>{" "}
//          &nbsp;
//         </Fragment>
//       );
//     })
//   );
// };

// // PORTFOLIO_GROUP
// export const PortfolioGroupFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             groupName: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.groupName.filter(
//               (item: any) => item.value !== value
//             ),
//             group: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.group.filter((id: any) => id !== value),
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter;

//   return (
//     customFilter?.groupName &&
//     customFilter?.groupName.map((dataItem: any, index: number) => {
//       return (
//         <Fragment key={index}>
//           <div key={dataItem.value} className="filter-item">
//             <Typography fontSize={12} fontWeight={600}>
//               {dataItem.name}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter(dataItem.value)}
//             />
//           </div>{" "}
//          &nbsp;
//         </Fragment>
//       );
//     })
//   );
// };

// // PORTFOLIO-LIST / AVL CASH BALANCE
// export const SinglePortfolioNameFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             portfolio: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.portfolio?.filter((data: any) => data !== value),
//             portfolioFilterName: commonFilter[
//               name as keyof CommonFilterState
//             ]?.customFilter?.portfolioFilterName?.filter(
//               (data: any) => data?.value !== value
//             ),
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter;

//   return (
//     customFilter?.portfolioFilterName &&
//     customFilter?.portfolioFilterName?.map((dataItem: any, index: number) => {
//       return (
//         <Fragment key={index}>
//           <div key={dataItem?.name} className="filter-item">
//             <Typography fontSize={12} fontWeight={600}>
//               {dataItem?.name}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter(dataItem?.value)}
//             />
//           </div>{" "}
//          &nbsp;
//         </Fragment>
//       );
//     })
//   );
// };

// // UPLOAD-MANAGER
// export const UploadManagerFilterComponent: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (type: string, value: any) => {
//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: {
//             ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//             [type]: Array.isArray(
//               commonFilter[name as keyof CommonFilterState]?.customFilter?.[
//                 type
//               ]
//             )
//               ? (
//                   commonFilter[name as keyof CommonFilterState]?.customFilter?.[
//                     type
//                   ] as any[]
//                 ).filter((item: any) => {
//                   if (type === "employeeName") {
//                     return item.value !== value;
//                   }
//                   return item !== value;
//                 })
//               : undefined,
//             employee: Array.isArray(
//               commonFilter[name as keyof CommonFilterState]?.customFilter
//                 ?.employee
//             )
//               ? (
//                   commonFilter[name as keyof CommonFilterState]?.customFilter
//                     ?.employee as any[]
//                 ).filter((item: any) => item !== value)
//               : undefined,
//           },
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter || {};

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>
//       {customFilter?.date && (
//         <>
//           <div
//             className="filter-item"
//             style={{
//               background: `${paletteLightTheme.palette.primary.main}22`,
//             }}
//           >
//             <Typography fontSize={12} fontWeight={600}>
//               {matchDate(customFilter?.date)}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => handleDeleteFilter("date", customFilter.date)}
//             />
//           </div>
//          &nbsp;
//         </>
//       )}

//       {customFilter?.employeeName?.map((dataItem: any, index: number) => {
//         return (
//           <Fragment key={index}>
//             <div
//               key={dataItem}
//               className="filter-item"
//               style={{
//                 background: `${paletteLightTheme.palette.primary.main}22`,
//               }}
//             >
//               <Typography fontSize={12} fontWeight={600}>
//                 {dataItem.name}
//               </Typography>

//               <FiTrash2
//                 size={14}
//                 color={paletteLightTheme.palette.error.main}
//                 cursor={"pointer"}
//                 onClick={() =>
//                   handleDeleteFilter("employeeName", dataItem.value)
//                 }
//               />
//             </div>
//            &nbsp;
//           </Fragment>
//         );
//       })}

//       {customFilter?.format?.map((dataItem: any, index: number) => {
//         return (
//           <Fragment key={index}>
//             <div
//               key={dataItem}
//               className="filter-item"
//               style={{
//                 background: `${paletteLightTheme.palette.primary.main}22`,
//               }}
//             >
//               <Typography fontSize={12} fontWeight={600}>
//                 {dataItem.replaceAll("_", " ")}
//               </Typography>

//               <FiTrash2
//                 size={14}
//                 color={paletteLightTheme.palette.error.main}
//                 cursor={"pointer"}
//                 onClick={() => handleDeleteFilter("format", dataItem)}
//               />
//             </div>
//            &nbsp;
//           </Fragment>
//         );
//       })}

//       {customFilter?.source?.map((dataItem: any, index: number) => {
//         return (
//           <Fragment key={index}>
//             <div
//               key={dataItem}
//               className="filter-item"
//               style={{
//                 background: `${paletteLightTheme.palette.primary.main}22`,
//               }}
//             >
//               <Typography fontSize={12} fontWeight={600}>
//                 {dataItem}
//               </Typography>

//               <FiTrash2
//                 size={14}
//                 color={paletteLightTheme.palette.error.main}
//                 cursor={"pointer"}
//                 onClick={() => handleDeleteFilter("source", dataItem)}
//               />
//             </div>
//            &nbsp;
//           </Fragment>
//         );
//       })}
//     </div>
//   );
// };

// // BANK-STATEMENT
// export const BankStatementFilterComponent: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (type: string, value: any) => {
//     const updatedCustomFilter = {
//       ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//     };

//     if (type === "start_date" || type === "end_date") {
//       delete updatedCustomFilter.start_date;
//       delete updatedCustomFilter.end_date;
//     } else if (type === "portfolioFilterName") {
//       updatedCustomFilter.portfolioFilterName =
//         updatedCustomFilter.portfolioFilterName.filter(
//           (item: any) => item.value !== value
//         );
//       updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
//         (item: any) => item !== value
//       );
//     } else if (type === "type") {
//       updatedCustomFilter.type = updatedCustomFilter.type.filter(
//         (item: any) => item !== value
//       );
//     }

//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: updatedCustomFilter,
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter || {};

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>
//       {customFilter?.start_date && customFilter?.end_date && (
//         <>
//           <div
//             className="filter-item"
//             style={{
//               background: `${paletteLightTheme.palette.primary.main}22`,
//             }}
//           >
//             <Typography fontSize={12} fontWeight={600}>
//               {matchDate(customFilter?.start_date)} -{" "}
//               {matchDate(customFilter?.end_date)}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => {
//                 handleDeleteFilter("start_date", "");
//                 handleDeleteFilter("end_date", "");
//               }}
//             />
//           </div>
//          &nbsp;
//         </>
//       )}

//       {customFilter?.portfolioFilterName &&
//         customFilter?.portfolioFilterName?.map(
//           (dataItem: any, index: number) => {
//             return (
//               <Fragment key={index}>
//                 <div key={dataItem?.name} className="filter-item">
//                   <Typography fontSize={12} fontWeight={600}>
//                     {dataItem?.name}
//                   </Typography>

//                   <FiTrash2
//                     size={14}
//                     color={paletteLightTheme.palette.error.main}
//                     cursor={"pointer"}
//                     onClick={() =>
//                       handleDeleteFilter("portfolioFilterName", dataItem?.value)
//                     }
//                   />
//                 </div>{" "}
//                &nbsp;
//               </Fragment>
//             );
//           }
//         )}

//       {customFilter?.type &&
//         customFilter?.type?.map((dataItem: any, index: number) => {
//           return (
//             <Fragment key={index}>
//               <div key={dataItem} className="filter-item">
//                 <Typography
//                   fontSize={12}
//                   fontWeight={600}
//                   textTransform={"uppercase"}
//                 >
//                   {dataItem}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => handleDeleteFilter("type", dataItem)}
//                 />
//               </div>{" "}
//              &nbsp;
//             </Fragment>
//           );
//         })}
//     </div>
//   );
// };

// export const TradeBookFilterComponent: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (type: string, value: any) => {
//     const updatedCustomFilter = {
//       ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//     };

//     if (type === "start_date" || type === "end_date") {
//       delete updatedCustomFilter.start_date;
//       delete updatedCustomFilter.end_date;
//     } else if (type === "portfolioFilterName") {
//       updatedCustomFilter.portfolioFilterName =
//         updatedCustomFilter.portfolioFilterName.filter(
//           (item: any) => item.value !== value
//         );
//       updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
//         (item: any) => item !== value
//       );
//     } else if (type === "type") {
//       updatedCustomFilter.type = updatedCustomFilter.type.filter(
//         (item: any) => item !== value
//       );
//     } else if (type === "company") {
//       updatedCustomFilter.company = updatedCustomFilter.company.filter(
//         (item: any) => item.value !== value
//       );
//       updatedCustomFilter.gcode = updatedCustomFilter.gcode.filter(
//         (item: any) => item !== value
//       );
//     }

//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: updatedCustomFilter,
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter || {};

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>
//       {customFilter?.start_date && customFilter?.end_date && (
//         <>
//           <div
//             className="filter-item"
//             style={{
//               background: `${paletteLightTheme.palette.primary.main}22`,
//             }}
//           >
//             <Typography fontSize={12} fontWeight={600}>
//               {matchDate(customFilter?.start_date)} -{" "}
//               {matchDate(customFilter?.end_date)}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => {
//                 handleDeleteFilter("start_date", "");
//                 handleDeleteFilter("end_date", "");
//               }}
//             />
//           </div>
//          &nbsp;
//         </>
//       )}

//       {customFilter?.portfolioFilterName &&
//         customFilter?.portfolioFilterName?.map(
//           (dataItem: any, index: number) => {
//             return (
//               <Fragment key={index}>
//                 <div key={dataItem?.name} className="filter-item">
//                   <Typography fontSize={12} fontWeight={600}>
//                     {dataItem?.name}
//                   </Typography>

//                   <FiTrash2
//                     size={14}
//                     color={paletteLightTheme.palette.error.main}
//                     cursor={"pointer"}
//                     onClick={() =>
//                       handleDeleteFilter("portfolioFilterName", dataItem?.value)
//                     }
//                   />
//                 </div>{" "}
//                &nbsp;
//               </Fragment>
//             );
//           }
//         )}

//       {customFilter?.company &&
//         customFilter?.company?.map((dataItem: any) => {
//           return (
//             <>
//               <div key={dataItem} className="filter-item">
//                 <Typography fontSize={12} fontWeight={600}>
//                   {dataItem.name}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => {
//                     handleDeleteFilter("company", dataItem.value);
//                   }}
//                 />
//               </div>{" "}
//              &nbsp;
//             </>
//           );
//         })}

//       {customFilter?.type &&
//         customFilter?.type?.map((dataItem: any, index: number) => {
//           return (
//             <Fragment key={index}>
//               <div key={dataItem} className="filter-item">
//                 <Typography
//                   fontSize={12}
//                   fontWeight={600}
//                   textTransform={"uppercase"}
//                 >
//                   {dataItem}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => handleDeleteFilter("type", dataItem)}
//                 />
//               </div>{" "}
//              &nbsp;
//             </Fragment>
//           );
//         })}
//     </div>
//   );
// };

// export const RevenueFeeCalculationFiltering: React.FC<Props> = (props) => {
//   const { name } = props;

//   const {
//     state: { commonFilter },
//     dispatch,
//   } = useAppContext();

//   const handleDeleteFilter = (type: string, value: any) => {
//     const updatedCustomFilter = {
//       ...commonFilter[name as keyof CommonFilterState]?.customFilter,
//     };

//     if (
//       type === "start_date" ||
//       type === "end_date" ||
//       type === "financialYear"
//     ) {
//       delete updatedCustomFilter.start_date;
//       delete updatedCustomFilter.end_date;
//       delete updatedCustomFilter.financialYear;
//     } else if (type === "portfolioFilterName") {
//       updatedCustomFilter.portfolioFilterName =
//         updatedCustomFilter.portfolioFilterName.filter(
//           (item: any) => item.value !== value
//         );
//       updatedCustomFilter.portfolio = updatedCustomFilter.portfolio.filter(
//         (item: any) => item !== value
//       );
//     } else if (type === "clientName") {
//       updatedCustomFilter.clientName = updatedCustomFilter.clientName.filter(
//         (item: any) => item.value !== value
//       );
//       updatedCustomFilter.client = updatedCustomFilter.client.filter(
//         (item: any) => item !== value
//       );
//     } else if (type === "feePlanName") {
//       updatedCustomFilter.feePlanName = updatedCustomFilter.feePlanName.filter(
//         (item: any) => item.value !== value
//       );
//       updatedCustomFilter.feePlan = updatedCustomFilter.feePlan.filter(
//         (item: any) => item !== value
//       );
//     }

//     dispatch({
//       type: "FILTER",
//       data: {
//         ...commonFilter,
//         [name]: {
//           ...commonFilter[name as keyof CommonFilterState],
//           customFilter: updatedCustomFilter,
//         },
//       },
//     });
//   };

//   const customFilter =
//     commonFilter[name as keyof CommonFilterState]?.customFilter || {};

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>
//       {customFilter?.start_date && customFilter?.end_date && (
//         <>
//           <div
//             className="filter-item"
//             style={{
//               background: `${paletteLightTheme.palette.primary.main}22`,
//             }}
//           >
//             <Typography fontSize={12} fontWeight={600}>
//               {matchDate(customFilter?.start_date)} -{" "}
//               {matchDate(customFilter?.end_date)}
//             </Typography>

//             <FiTrash2
//               size={14}
//               color={paletteLightTheme.palette.error.main}
//               cursor={"pointer"}
//               onClick={() => {
//                 handleDeleteFilter("start_date", "");
//                 handleDeleteFilter("end_date", "");
//                 handleDeleteFilter("financialYear", null);
//               }}
//             />
//           </div>
//          &nbsp;
//         </>
//       )}

//       {customFilter?.portfolioFilterName &&
//         customFilter?.portfolioFilterName?.map((dataItem: any) => {
//           return (
//             <>
//               <div key={dataItem?.name} className="filter-item">
//                 <Typography fontSize={12} fontWeight={600}>
//                   {dataItem?.name}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => {
//                     handleDeleteFilter("portfolioFilterName", dataItem?.value);
//                   }}
//                 />
//               </div>{" "}
//              &nbsp;
//             </>
//           );
//         })}

//       {customFilter?.clientName &&
//         customFilter?.clientName?.map((dataItem: any) => {
//           return (
//             <>
//               <div key={dataItem} className="filter-item">
//                 <Typography fontSize={12} fontWeight={600}>
//                   {dataItem.name}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => {
//                     handleDeleteFilter("clientName", dataItem.value);
//                   }}
//                 />
//               </div>{" "}
//              &nbsp;
//             </>
//           );
//         })}

//       {customFilter?.feePlanName &&
//         customFilter?.feePlanName?.map((dataItem: any) => {
//           return (
//             <>
//               <div key={dataItem} className="filter-item">
//                 <Typography fontSize={12} fontWeight={600}>
//                   {dataItem.name}
//                 </Typography>

//                 <FiTrash2
//                   size={14}
//                   color={paletteLightTheme.palette.error.main}
//                   cursor={"pointer"}
//                   onClick={() => {
//                     handleDeleteFilter("feePlanName", dataItem.value);
//                   }}
//                 />
//               </div>{" "}
//              &nbsp;
//             </>
//           );
//         })}
//     </div>
//   );
// };

export const importManagerFormats = [
  { name: "Gridkey CSV", value: "GRIDKEY_CSV" },
  // { name: "Daily Auto CSV", value: "DAILY_AUTO_CSV" },
  { name: "Cash Entries", value: "GRIDKEY_CASH_CSV" },
  // { name: "Gridkey Contract CSV", value: "GRIDKEY_CONTRACT_CSV" },
  // {
  //   name: "Gridkey Model Portfolio CSV",
  //   value: "GRIDKEY_MODEL_PORTFOLIO_CSV",
  // },
];

export const importManagerSource = [
  { name: "Auto Contract Note", value: "Auto Contract Note" },
  { name: "Manual Import", value: "Manual Upload" },
  // { name: "Manual Entry", value: "Manual Entry" },
  // { name: "Cash Manual Entry", value: "Cash Manual Entry" },
];

export const transactionTypeIncomeExpense = [
  { name: "Expense", value: "expense" },
  { name: "Income", value: "income" },
];

export const transactionTypeCashEntry = [
  { name: "Deposit", value: "deposit" },
  { name: "Withdraw", value: "withdraw" },
];

export const expensesType = [
  { name: "INTEREST", value: "interest" },
  { name: "DP CHARGES", value: "dp_charges" },
  { name: "AMC CHARGES", value: "amc_charges" },
  { name: "BROKERAGE", value: "brokerage" },
  { name: "OTHER CHARGES", value: "total_charges" },
  { name: "MISC EXP", value: "misc_exp" },
  { name: "LATE PAYMENT CHARGES", value: "late_payment_charges" },
];

export const incomeType = [
  { name: "DIVIDEND", value: "dividend" },
  { name: "INTEREST", value: "interest" },
  { name: "MISC INCOME ", value: "misc_income" },
];

export const tradeBookTransactionType = [
  { name: "Buy", value: "buy" },
  { name: "Sell", value: "sell" },
  { name: "IPO", value: "ipo" },
  { name: "BUY BACK", value: "buy_back" },
  { name: "RIGHT ISSUE", value: "right_issue" },
  { name: "PART PAYMENT", value: "partly_paid" },
];

export const LEDGER_TRANSACTION_TYPE = [
  ...tradeBookTransactionType,
  { name: "Income", value: "INCOME" },
  { name: "Expense", value: "EXPENSE" },
];

export const portfolioOption = (portfolios: any, form: any) => {
  return portfolios?.filter(
    (data: any) =>
      !form
        .getValues("portfolio")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value)
  );
};

export const portfolioGroupOption = (portfolioGroupList: any, form: any) => {
  return portfolioGroupList?.filter(
    (data: any) =>
      !form
        .getValues("group")
        ?.map((dataItem: any) => dataItem)
        .includes(data?.value)
  );
};

export const companiesOption = (companies: any, form: any) => {
  return companies?.filter(
    (data: any) =>
      !form
        .getValues("gcode")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value)
  );
};

export const clientOption = (clientList: any, form: any) => {
  return clientList?.filter(
    (data: any) =>
      !form
        .getValues("client")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value)
  );
};

export const employeeOption = (employeeList: any, form: any) => {
  return employeeList?.filter(
    (data: any) =>
      !form
        .getValues("employee")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value)
  );
};

export const feePlanOption = (feePlanList: any, form: any) => {
  return feePlanList?.filter(
    (data: any) =>
      !form
        .getValues("feePlan")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value)
  );
};
