import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

export const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const validationSchema = object({
    phone: string().required("Please Enter Phone Number"),
    countryCode: string(),
  });

  const form = useForm({
    defaultValues: {
      phone: "",
      countryCode: "+91",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (value: any) => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/otp/${value?.countryCode}-${value?.phone}/send/`
    );
    if (response && response?.status === 200) {
      setIsLoading(false);
      navigate("/login/opt-login", {
        state: {
          phone: value.phone,
          isForgetPassword: true,
          countryCode: value?.countryCode,
        },
      });
      toast.success("OTP sent successfully.");
    } else {
      setIsLoading(false);

      toast.error("Something went wrong, please try again.");
    }
  };

  return {
    isLoading,
    navigate,
    form,
    onSubmit,
  };
};
