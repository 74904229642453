import { Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import InvoiceTab from "Pages/RevenueManagement/Invoice/InvoiceTab";
import PerformaInvoiceTab from "Pages/RevenueManagement/Invoice/PerformaInvoiceTab";
import RevenueFeeCalculations from "Pages/RevenueManagement/RevenueManager/RevenueFeeCalculation";
import { useParams } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import FeeCollectionTab from "./FeeCollectionTab";
import FeePlansTab from "./FeePlansTab";
import SettingPage from "./SettingPage";

interface TabConfig {
  label: string;
  component: React.ComponentType<any>;
  props?: Record<string, any>;
}

const PortfolioRevenue = () => {
  useDocumentTitle("Portfolio Revenue");

  const { id } = useParams();

  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const tabs: TabConfig[] = [
    {
      label: "Fee Plans",
      component: FeePlansTab,
      props: {
        action: true,
      },
    },
    {
      label: "Fee Calculation",
      component: RevenueFeeCalculations,
      props: {
        name: "portfolioCalculation",
        route: `/app/revenue-manager/create-calculation/${id}`,
        tableName: "portfolio_calculation",
      },
    },
    {
      label: "Proforma",
      component: PerformaInvoiceTab,
      props: {
        name: "portfolioProforma",
        tableName: "portfolio_proforma",
      },
    },
    {
      label: "Invoice",
      component: InvoiceTab,
      props: {
        name: "portfolioInvoice",
        tableName: "portfolio_invoice",
      },
    },
    { label: "Fee Collection", component: FeeCollectionTab },
    { label: "Settings", component: SettingPage },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          onChange={(e, val) => {
            dispatch({
              type: "TAB",
              data: { ...tabValue, tabOfPortfolioRevenue: val },
            });
          }}
          value={tabValue?.tabOfPortfolioRevenue}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} disableTouchRipple />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {tabs.map((tab, index) => (
          <Show.When
            key={index}
            isTrue={tabValue?.tabOfPortfolioRevenue === index}
          >
            <AnimationComponent>
              <tab.component {...(tab?.props || {})} />
            </AnimationComponent>
          </Show.When>
        ))}
      </Grid>
    </Grid>
  );
};

export default PortfolioRevenue;
