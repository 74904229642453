/* eslint-disable react/no-this-in-sfc */
import { Card } from "@mui/material";
import NoData from "components/NoData";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  data: [number, number][];
}

const HighChartLine = ({ data }: Props) => {
  const { theme } = ThemeContainer();
  const options = {
    chart: {
      backgroundColor: theme.palette.light.main,
      borderRadius: 5,
      type: "area",
      height: 500,
      style: {
        fontFamily: "Inter",
      },
    },
    credits: {
      text: "",
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    rangeSelector: {
      inputStyle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      labelStyle: {
        color: theme.palette.primary.main,
      },
      buttonTheme: {
        width: 50,
        style: {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.grey[700],
          fontWeight: "bold",
          textTransform: "uppercase",
        },
        states: {
          select: {
            color: theme.palette.primary.main,
            fill: `${theme.palette.primary.main}22`,
            style: {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    title: {
      text: `<h1 id="chart-title">Total Assets Under Management</h1>`,
      align: "left",
      style: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 18,
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        formatter(this: any) {
          const { value } = this;
          const absValue = Math.abs(value);
          let newValue = "";
          if (absValue >= 10000000) {
            newValue = `${(value / 10000000).toFixed(2)} Cr`;
          } else if (absValue >= 100000) {
            newValue = `${(value / 100000).toFixed(2)} Lakh`;
          } else if (absValue >= 1000) {
            newValue = `${(value / 1000).toFixed(2)} K`;
          } else {
            newValue = value;
          }
          return newValue;
        },
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    },
    tooltip: {
      formatter(this: any) {
        const formattedDate = Highcharts.dateFormat("%b %e, %Y", this.x);

        let content = `<b>${formattedDate}</b><br/>`;

        this.points.forEach((point: any) => {
          content += `<span style="color:${point.color}">\u25CF</span> ${
            point.series.name
          }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
        });

        return content;
      },
    },
    series: [
      {
        name: "Total Assets Under Management",
        data: data?.sort((a, b) => a[0] - b[0]) || [],
        tooltip: {
          valueDecimals: 2,
          pointFormatter(this: any) {
            return `<span style="color:${this.color}">\u25CF</span> ${
              this.series.name
            }: <b>${moneyCommaSeparator(this.y)}</b><br/>`;
          },
          formatter(this: any) {
            return `<b></b>`;
          },
        },
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        fillColor: {
          linearGradient: [0, 0, 0, 400],
          stops: [
            [0, theme.palette.primary.main],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
      },
    ],
  };

  return (
    <Card>
      {data?.length === 0 ? (
        <NoData />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={options}
        />
      )}
    </Card>
  );
};

export default HighChartLine;
