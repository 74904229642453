import { useQueries, useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  CityResponseInterface,
  ClientListInterface,
  ClientResponseInterface,
  CompaniesListInterface,
  CompaniesResponseInterface,
  CountryResponseInterface,
  EmailHostListInterface,
  EmailHostResponseInterface,
  EmployeeListInterface,
  EmployeeResponseInterface,
  PortfolioGroupListInterface,
  PortfolioGroupResponseInterface,
  PortfolioListInterface,
  PortfolioResponseInterface,
  StateResponseInterface,
} from "interfaces/PrivateRouteInterface";
import toast from "react-hot-toast";
import { Navigate, useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { DashboardType } from "utils/common";
import { QueryKeys } from "utils/queryKey";

const PrivateRoute = ({ children }: any) => {
  const location = useLocation();

  const {
    state: { commonFilter, selectedCountry, selectedState },
    dispatch,
  } = useAppContext();

  const token = Boolean(localStorage.getItem(`token`));

  useQuery({
    queryKey: [QueryKeys.GET_USER_DETAIL],
    queryFn: () => axiosInstance.get(`/user-details/`),
    enabled:
      token &&
      !location.pathname?.includes("/broker-integration") &&
      location?.pathname !== "client-risk-profile" &&
      !location.pathname?.includes("/broker-integration/order") &&
      !location.pathname?.includes("/broker-integration/broker"),
    onSuccess: (data) => {
      if (!localStorage?.getItem("role")) {
        localStorage?.setItem(
          "role",
          data?.data?.data?.isEmployee ? "advisor" : "client"
        );
      }

      dispatch({
        type: "CURRENT_USER",
        data: {
          ...data?.data?.data,
          userType:
            localStorage?.getItem("role") === "advisor"
              ? DashboardType.ADVISOR
              : DashboardType.INVESTOR,
        },
      });
      dispatch({ type: "IS_FIRST_LOGIN", data: data?.data?.data?.isFirst });

      const d = Object.entries(commonFilter)?.map(([key, val]: [any, any]) => {
        return {
          [key]: {
            ...val,
            showNonZero: data?.data?.data?.showZeroHoldingTables,
          },
        };
      });

      const combinedObject: any = d.reduce((acc, obj) => {
        const item = Object.keys(obj)[0] as any;
        acc[item] = obj[item];
        return acc;
      }, {});

      dispatch({ type: "FILTER", data: combinedObject });
      dispatch({ type: "USER_LOADING", data: false });
    },
    onError(error: {
      response: { status: number; data: { message: string } };
    }) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
      if (error?.response?.status === 401) {
        <Navigate to={"/"} replace />;
        localStorage.removeItem(`token`);
        localStorage.removeItem(`isInvestor`);
        localStorage.removeItem(`isAdvisor`);
        localStorage.removeItem(`userInfo`);
        localStorage.removeItem(`version`);
        localStorage.removeItem("breadcumbs");
        window.location.reload();
        dispatch({ type: "USER_LOADING", data: false });
      }
    },
  });

  useQueries({
    queries: [
      {
        queryKey: [QueryKeys.PORTFOLIOS_SHORT_DATA],
        queryFn: () => axiosInstance.get(`/portfolios/all/`),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          location?.pathname !== "client-risk-profile" &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (data: PortfolioResponseInterface) => {
          dispatch({
            type: "PORTFOLIOS",
            data: data?.data?.map((item: PortfolioListInterface) => ({
              ...item,
              name: `${item.name} - ${item.portfolioId}`,
              value: item.id,
              portfolioName: item.name,
            })),
          });
        },
      },
      {
        queryKey: ["FETCH_COMPANIES"],
        queryFn: () => axiosInstance.get("/intruments/list/"),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          location?.pathname !== "client-risk-profile" &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (data: CompaniesResponseInterface) => {
          dispatch({
            type: "COMPANIES",
            data: data?.data?.data?.map((item: CompaniesListInterface) => ({
              ...item,
              name: item.name,
              value: item.gridCode,
            })),
          });
        },
      },
      {
        queryKey: ["FETCH_ALL_PORTFOLIO_GROUP"],
        queryFn: () => axiosInstance.get("/portfolio-groups/all/?role=advisor"),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          location?.pathname !== "client-risk-profile" &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (data: PortfolioGroupResponseInterface) => {
          dispatch({
            type: "PORTFOLIO_GROUP_LIST",
            data: data?.data?.map((item: PortfolioGroupListInterface) => ({
              name: item.name,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: [QueryKeys.CLIENTS_SHORT_DATA],
        queryFn: () => axiosInstance.get(`/client/all/`),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          location?.pathname !== "client-risk-profile" &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (data: ClientResponseInterface) => {
          dispatch({
            type: "CLIENT_LIST",
            data: data?.data?.map((item: ClientListInterface) => ({
              ...item,
              name: `${item.name} - ${item?.clientCode}`,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: [QueryKeys.EMPLOYEE_LIST],
        queryFn: () => axiosInstance.get("/employees/all/"),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          location?.pathname !== "client-risk-profile" &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (data: EmployeeResponseInterface) => {
          dispatch({
            type: "EMPLOYEE_LIST",
            data: data?.data?.map((item: EmployeeListInterface) => ({
              name: `${item.name || "-"}`,
              value: item.id,
            })),
          });
        },
      },
      {
        queryKey: ["EMAIL_HOST_LIST"],
        queryFn: () => axiosInstance.get("/email-host/"),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (response: EmailHostResponseInterface) => {
          dispatch({
            type: "EMAIL_HOST_LIST",
            data: response?.data?.data.map(
              (dataItem: EmailHostListInterface) => {
                return {
                  ...dataItem,
                  name: `${dataItem?.serverName} - ${dataItem?.emailHost}`,
                  value: dataItem?.id,
                };
              }
            ),
          });
        },
      },
      {
        queryKey: ["GET_ALL_COUNTRIES"],
        queryFn: () => axiosInstance.get("/country/"),
        enabled:
          token &&
          !location.pathname?.includes("/broker-integration") &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (response: CountryResponseInterface) => {
          dispatch({
            type: "COUNTRY_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_STATE", selectedCountry],
        queryFn: () =>
          axiosInstance.get(`/state/?country_id=${selectedCountry}`),
        enabled:
          token &&
          Boolean(selectedCountry) &&
          !location.pathname?.includes("/broker-integration") &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (response: StateResponseInterface) => {
          dispatch({
            type: "STATE_LIST",
            data: response?.data?.data,
          });
        },
      },
      {
        queryKey: ["GET_CITY", selectedState],
        queryFn: () => axiosInstance.get(`/city/?state_id=${selectedState}`),
        enabled:
          Boolean(selectedState) &&
          token &&
          !location.pathname?.includes("/broker-integration") &&
          !location.pathname?.includes("/broker-integration/order") &&
          !location.pathname?.includes("/broker-integration/broker"),
        onSuccess: (response: CityResponseInterface) => {
          dispatch({
            type: "CITY_LIST",
            data: response?.data?.data,
          });
        },
      },
    ],
  });

  return localStorage.getItem("token") ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
