import AccountSetting from "Pages/AccountSetting";
import ForgotPassword from "Pages/Authentication/ForgotPassword";
import Register from "Pages/Authentication/Register";
import ClientDetailsPage from "Pages/Client/ClientDetailsPage";
import ClientOnboarding from "Pages/ClientOnboarding";
import CompanyOnboarding from "Pages/CompanyInformation/CompanyOnboarding";
import ExternalRiskProfile from "Pages/Compliance/ExternalRiskProfile";
import EquityBasket from "Pages/EquityBasket";
import AddPortfolioDetailsModal from "Pages/EquityBasket/AddPortfolioDetailsModal";
import EquityBasketAssets from "Pages/EquityBasket/EquityBasketAssets";
import EquityBasketEdit from "Pages/EquityBasket/EquityBasketEdit";
import EquityBasketOrderManager from "Pages/EquityBasket/EquityBasketOrderManager";
import OrderManagerPortfolios from "Pages/EquityBasket/EquityBasketOrderManager/OrderManagerPortfolios";
import EquityBasketPortfolioMapping from "Pages/EquityBasket/EquityBasketPortfolioMapping";
import EquityBasketSettings from "Pages/EquityBasket/EquityBasketSettings";
import EquityBasketView from "Pages/EquityBasket/EquityBasketView";
import AddModelportfolioVersionModal from "Pages/EquityBasket/ModelPortfolioVersion/AddModelportfolioVersionModal";
import ModelPortfolioVersionForm from "Pages/EquityBasket/ModelPortfolioVersion/ModelPortfolioVersion";
import EquityEtfForm from "Pages/EquityEtf/EquityEtfForm";
import FamilyDetails from "Pages/Family/FamilyDetails";
import ExternalLeadRisk from "Pages/Lead/ExternalLeadRisk";
import ModelPortfoioAllocation from "Pages/ModelPortfolio/ModelPortfolioAllocation";
import MapUnmapPortfolios from "Pages/ModelPortfolio/ModelPortfolioMapping/MapUnmapPortfolios";
import TotalOrders from "Pages/ModelPortfolio/TotalOrders";
import AdminDetails from "Pages/Organisations/AdminDetails";
import ConfigurePaymentForm from "Pages/PaymentGateway/ConfigurePaymentForm";
import PortfolioDetailsPage from "Pages/Portfolio/PortfolioDetailsPage";
import PortfolioETFTransaction from "Pages/Portfolio/PortfolioETFTransaction";
import NAV from "Pages/Portfolio/SummaryPages/NAV";
import GenerateReport from "Pages/ReportManager/GenerateReports";
import PaymentLinkList from "Pages/RevenueManagement/Invoice/PaymentLinkList";
import CreateRoleWisePermission from "Pages/Settings/CreateRoleWisePermission";
import SmallCase from "Pages/SmallCase";
import SmallCaseOrder from "Pages/SmallCase/SmallCaseOrder";
import Wallet from "Pages/Wallet";
import AboutUs from "Pages/Website/Aboutus/AboutUs";
import FAQPage from "Pages/Website/FAQPage";
import HomePage from "Pages/Website/Home";
import PrivacyPolicy from "Pages/Website/PrivacyPolicy";
import TermsCondition from "Pages/Website/TermsCondition";
import WealthBasket from "Pages/Website/WealthBasket/WealthBasket";
import WealthPartner from "Pages/Website/WealthPartner/WealthPartner";
import WealthWise from "Pages/Website/WealthWise/WealthWise";
import CRMMenu from "SubMenuPages/CRMMenu";
import DashboardMenu from "SubMenuPages/DashboardMenu";
import ImportsMenu from "SubMenuPages/ImportsMenu";
import OrganisationMenu from "SubMenuPages/OrganisationMenu";
import ReportsMenu from "SubMenuPages/ReportsMenu";
import RevenueMenu from "SubMenuPages/RevenueMenu";
import AllComponents from "components/AllComponents";
import PrivateRoute from "components/PrivateRoute";
import SmallCaseLayout from "layouts/SmallCaseLayout";
import {
  AdviceRegisterNotes,
  AppLayout,
  ApplyRightIssue,
  ArchiveManager,
  AuthenticationLayout,
  ChangePassword,
  ClientAgreement,
  ClientKYC,
  ClientRiskProfilingPage,
  CommissionManager,
  CompliancePage,
  CreateCalculation,
  CreateCollection,
  CreateFeePlans,
  Dashboard,
  EditCommissionManager,
  EditFeePlan,
  EditRevenuePortfolio,
  EditRightIssue,
  ErrorPage,
  FeeCollection,
  FeePlans,
  InsightsHoldings,
  InsightsHoldingsDetails,
  Lead,
  LeadDetail,
  LoginPage,
  ModelPortfolioPerformance,
  ModelPortfolioTimeLine,
  OTP,
  PortfolioGroupDetails,
  PortfolioHoldingTransactions,
  Questionnaire,
  RegisterDetails,
  RightIssue,
  SheetPortfolioMapping,
  Transaction,
  UploadManager,
  ViewQuestionnaire,
  Website,
} from "./Pages";

export const productRoutes = [
  {
    path: "/",
    element: <Website />,
    child: [
      {
        element: <HomePage />,
        path: "/home",
      },

      {
        element: <WealthBasket />,
        path: "product/wealth-basket",
      },
      {
        element: <WealthWise />,
        path: "product/wealth-wise",
      },
      {
        element: <WealthPartner />,
        path: "product/wealth-partner",
      },
      {
        element: <AboutUs />,
        path: "about-us",
      },
      {
        element: <TermsCondition />,
        path: "terms-condition",
      },
      {
        element: <PrivacyPolicy />,
        path: "privacy-policy",
      },
      {
        element: <FAQPage />,
        path: "faq",
      },
    ],
  },
  {
    path: "/broker-integration",
    elements: <SmallCaseLayout />,
    child: [
      {
        path: "broker",
        element: <SmallCase />,
      },
      {
        path: "order",
        element: <SmallCaseOrder />,
      },
    ],
  },
  {
    element: <AuthenticationLayout />,
    path: "/login",
    child: [
      {
        element: <OTP />,
        path: "opt-login",
      },
      {
        element: <Register />,
        path: "authentication",
      },
      {
        element: <ChangePassword />,
        path: "password",
      },
      {
        element: <ForgotPassword />,
        path: "forgot-password",
      },
      {
        element: <RegisterDetails />,
        path: "register-details",
      },
      {
        element: <LoginPage />,
        path: "",
      },
    ],
  },
  {
    element: <AllComponents />,
    path: "/all-gk-components",
  },
  {
    element: <ExternalRiskProfile />,
    path: "/client-risk-profile",
  },
  {
    element: <ExternalLeadRisk />,
    path: "/lead-risk-profile",
  },
  {
    element: <ErrorPage />,
    path: "*",
  },
  {
    path: "/app",
    element: (
      <PrivateRoute>
        <AppLayout />
      </PrivateRoute>
    ),
    child: [
      {
        element: <Wallet />,
        path: "wallet",
      },
      // {
      //   element: <UserProfile />,
      //   path: "user-profile",
      // },
      {
        element: <CompanyOnboarding />,
        path: "company-onboarding",
      },
      {
        element: <EquityEtfForm />,
        path: "create-transaction",
      },
      {
        element: <EquityEtfForm />,
        path: "edit-transaction/:id",
      },
      {
        element: <InsightsHoldings />,
        path: "insights-holdings",
      },
      {
        element: <InsightsHoldingsDetails />,
        path: "insights-holdings/:id/details",
      },
      // {
      //   element: <PortfolioHoldingDetails />,
      //   path: "holdings/:id",
      // },
      {
        element: <PortfolioHoldingTransactions />,
        path: "holdings/:id/transactions",
      },
      {
        element: <UploadManager />,
        path: "upload",
      },
      {
        element: <ClientRiskProfilingPage />,
        path: "risk-profile/:id",
      },
      {
        element: <Questionnaire />,
        path: "risk-profile/:id/questionnaire",
      },
      {
        element: <ViewQuestionnaire />,
        path: "risk-profile/:id/questionnaire/:questionnaireId",
      },
      {
        element: <ArchiveManager />,
        path: "upload/archive-manager",
      },
      {
        element: <FamilyDetails />,
        path: "family/:id",
      },
      {
        element: <PortfolioGroupDetails />,
        path: "portfolio/group/:id",
      },
      {
        element: <ClientDetailsPage />,
        path: "clients/:id",
      },
      {
        element: <ClientKYC />,
        path: "clients/:id/kyc",
      },
      {
        element: <ClientAgreement />,
        path: "clients/:id/agreement",
      },
      {
        element: <EquityBasket />,
        path: "equity-basket",
      },
      {
        element: <EquityBasketEdit />,
        path: "equity-basket/form",
      },
      {
        element: <TotalOrders />,
        path: "equity-basket/orders",
      },
      {
        element: <EquityBasketView />,
        path: "equity-basket/:id",
      },
      {
        element: <EquityBasketEdit />,
        path: "equity-basket/:id/edit",
      },
      {
        element: <EquityBasketSettings />,
        path: "equity-basket/:equityBasketId/settings",
      },
      {
        element: <EquityBasketAssets />,
        path: "equity-basket/:id/assets",
      },
      {
        element: <AddPortfolioDetailsModal />,
        path: "equity-basket/:id/assets/:assetId/details",
      },
      {
        element: <ModelPortfolioVersionForm />,
        path: "equity-basket/:id/model-portfolio",
      },
      {
        element: <AddModelportfolioVersionModal />,
        path: "equity-basket/:id/model-portfolio/:modelPortfolioId",
      },
      {
        element: <AddModelportfolioVersionModal />,
        path: "equity-basket/:id/model-portfolio/:modelPortfolioId/edit",
      },
      {
        element: <AddModelportfolioVersionModal />,
        path: "equity-basket/:id/model-portfolio/add",
      },
      {
        element: <ModelPortfoioAllocation />,
        path: "equity-basket/:id/new-allocation",
      },
      {
        element: <EquityBasketOrderManager />,
        path: "equity-basket/:id/order-manager",
      },
      {
        element: <OrderManagerPortfolios />,
        path: "equity-basket/:id/order-manager/:ordetId/portfolios",
      },
      {
        element: <ModelPortfolioPerformance />,
        path: "equity-basket/performance",
      },
      {
        element: <EquityBasketPortfolioMapping />,
        path: "equity-basket/:id/portfolio",
      },
      {
        element: <MapUnmapPortfolios />,
        path: "equity-basket/:id/mappings",
      },
      {
        element: <ModelPortfolioTimeLine />,
        path: "equity-basket/:id/time-line",
      },
      {
        element: <Transaction />,
        path: "transaction",
      },
      {
        element: <SheetPortfolioMapping />,
        path: "user-profile/:id/portfolio-mapping",
      },
      // {
      //   element: <CompanyInformation />,
      //   path: "user-profile/company-information",
      // },
      {
        element: <AccountSetting />,
        path: "user-profile/settings",
      },
      {
        element: <GenerateReport />,
        path: "generate-report",
      },
      {
        element: <CompliancePage />,
        path: "compliance",
      },
      {
        element: <Lead />,
        path: "lead",
      },

      {
        element: <LeadDetail />,
        path: "lead/detail/:id/profile",
      },
      {
        element: <LeadDetail />,
        path: "lead/detail/:id/summary",
      },
      {
        element: <LeadDetail />,
        path: "lead/detail/:id/meeting",
      },
      {
        element: <LeadDetail />,
        path: "lead/detail/:id/notes",
      },
      {
        element: <Questionnaire />,
        path: ":type/:id/questionnaire",
      },
      {
        element: <RightIssue />,
        path: "right-issue",
      },
      {
        element: <ApplyRightIssue />,
        path: "right-issue/:id/apply-right-issue",
      },
      {
        element: <EditRightIssue />,
        path: "right-issue/:id/update-right-issue",
      },
      {
        element: <AdviceRegisterNotes />,
        path: "advice-register-notes",
      },
      {
        element: <FeePlans />,
        path: "fee-plans",
      },
      {
        element: <CreateFeePlans />,
        path: "fee-plans/create-fee-plan",
      },
      {
        element: <EditFeePlan />,
        path: "fee-plans/:id/edit-fee-plan",
      },
      {
        element: <EditRevenuePortfolio />,
        path: "revenue-manager/:id/edit-portfolio",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/create-calculation",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/create-calculation/:portfolioId",
      },
      {
        element: <CreateCalculation />,
        path: "revenue-manager/:id/edit-calculation",
      },
      {
        element: <CommissionManager />,
        path: "commission-manager",
      },
      {
        element: <EditCommissionManager />,
        path: "commission-manager/:id/edit-commission-manager",
      },
      {
        element: <FeeCollection />,
        path: "fee-collection",
      },
      {
        element: <CreateCollection />,
        path: "collection/create",
      },
      {
        element: <CreateCollection />,
        path: "collection/:id/edit",
      },

      {
        element: <DashboardMenu />,
        path: "holdings",
      },
      {
        element: <Dashboard />,
        path: "dashboard",
      },
      {
        element: <OrganisationMenu />,
        path: "admin",
      },
      {
        element: <AdminDetails />,
        path: "admin/:id",
      },
      {
        element: <CRMMenu />,
        path: "crm",
      },
      {
        element: <RevenueMenu />,
        path: "revenue",
      },
      {
        element: <ReportsMenu />,
        path: "reports",
      },
      {
        element: <ImportsMenu />,
        path: "imports",
      },
      {
        element: <PortfolioDetailsPage />,
        path: "holdings/portfolio/:id",
      },
      {
        element: <PortfolioETFTransaction />,
        path: "holdings/portfolio/:id/etf-transaction",
      },
      {
        element: <CreateRoleWisePermission />,
        path: ":role/permission",
      },
      {
        element: <CreateRoleWisePermission />,
        path: ":role/permission/:id",
      },
      {
        element: <ClientOnboarding />,
        path: "client-onboarding",
      },
      { element: <NAV />, path: "nav/:id" },
      { element: <PaymentLinkList />, path: "invoice/payment-link" },
      { element: <ConfigurePaymentForm />, path: "connect-payment-gateway" },
      {
        element: <ConfigurePaymentForm />,
        path: "connect-payment-gateway/:id",
      },
    ],
  },
];
