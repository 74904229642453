/* eslint-disable import/no-cycle */
import axios from "axios";
import { token } from "./common";

const userInfo = localStorage?.getItem("role");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Token ${JSON.parse(token)}`,
    "x-gridkey-user-role": userInfo,
  },
});

export default axiosInstance;
