import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import { UploadManager } from "Pages";
import CustomBrokerImport from "Pages/CustomBrokerImport";
import ReconManager from "Pages/ReconManager";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";

const ImportsMenu = () => {
  const {
    state: { tab, userData },
    dispatch,
  } = useAppContext();

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: "#fff",
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, tabValueOfImport: val },
                });
              }}
              value={tab?.tabValueOfImport}
            >
              <Tab label="Import Manager" disableRipple />
              <Tab label="Recon Manager" disableRipple />
              {userData?.broker?.adityaBirlaSupport && (
                <Tab label="Import from Broker" disableRipple />
              )}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Show.When isTrue={tab?.tabValueOfImport === 0}>
            <AnimationComponent>
              <UploadManager />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueOfImport === 1}>
            <AnimationComponent>
              <ReconManager />
            </AnimationComponent>
          </Show.When>
          <Show.When
            isTrue={
              tab?.tabValueOfImport === 2 &&
              userData?.broker?.adityaBirlaSupport
            }
          >
            <AnimationComponent>
              <CustomBrokerImport />
            </AnimationComponent>
          </Show.When>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ImportsMenu;
