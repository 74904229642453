import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKTextField from "components/GKTextField";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { max, min, sum } from "lodash";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { MdOutlineExpandMore } from "react-icons/md";
import { RiCloseLine } from "react-icons/ri";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

const OptionsList = ({
  optionFields,
  removeOption,
  question,
  questionIndex,
  control,
  handleDeleteQuestionOptions,
  handleAddOption,
  handleRemoveQuestion,
}: any) => (
  <Grid item container xs={12} spacing={1}>
    <Grid item xs={8}>
      <Typography color={"#4B5565"}>
        Options
        <span style={{ color: paletteLightTheme.palette.error.main }}>*</span>
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography color={"#4B5565"}>
        Points
        <span style={{ color: paletteLightTheme.palette.error.main }}>*</span>
      </Typography>
    </Grid>

    {optionFields.map((option: any, optionIndex: number) => (
      <Grid item xs={12} key={option.id} container spacing={1}>
        <Grid item xs={8}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name={`riskQuestions.${questionIndex}.options.${optionIndex}.option`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Option text is required",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={``}
                  requiredField
                  placeholder="Option"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} display={"flex"}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name={`riskQuestions.${questionIndex}.options.${optionIndex}.optionPoints`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Option Points is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKTextField
                  {...field}
                  inputLabel={``}
                  requiredField
                  placeholder="Option Points"
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FormControl>
          <IconButton
            sx={{ ml: "4px" }}
            onClick={() => {
              if (option.optionId) {
                handleDeleteQuestionOptions({
                  id: question.questionId,
                  option: option.option,
                });
              } else {
                removeOption(optionIndex);
              }
            }}
            color="error"
            className="outline"
          >
            <RiCloseLine size={18} />
          </IconButton>
        </Grid>
      </Grid>
    ))}

    <Grid item xs={8} mt={1}>
      <GKButton
        variant="outlined"
        color="dark"
        startIcon={<CgMathPlus size={18} />}
        onClick={handleAddOption}
      >
        Add Option
      </GKButton>
    </Grid>
    <Grid item xs={4} mt={1} display={"flex"} justifyContent={"flex-end"}>
      <GKButton
        variant="outlined"
        color="error"
        onClick={handleRemoveQuestion}
        startIcon={<FiTrash2 size={18} />}
      >
        Delete Question
      </GKButton>
    </Grid>
  </Grid>
);

// ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
const QuestionAccordion = ({
  question,
  questionIndex,
  setExpanded,
  expanded,
  control,
  handleDeleteQuestion,
  handleDeleteQuestionOptions,
  getValues,
  setValue,
  removeQuestion,
}: any) => {
  const { theme } = ThemeContainer();

  const { fields: optionFields, remove: removeOption } = useFieldArray({
    control,
    name: `riskQuestions.${questionIndex}.options`,
  });

  const handleChange = () => {
    setExpanded(questionIndex === expanded ? -1 : questionIndex);
  };

  const handleAddOption = () => {
    const newOptions = getValues(`riskQuestions.${questionIndex}.options`);
    newOptions.push({ option: "", optionPoints: "" });
    setValue(`riskQuestions.${questionIndex}.options`, newOptions);
  };

  const handleRemoveQuestion = () => {
    if (question?.questionId) {
      handleDeleteQuestion(question?.questionId);
    } else {
      removeQuestion(questionIndex);
    }
  };

  return (
    <Grid item xs={12}>
      <Accordion
        onChange={handleChange}
        expanded={questionIndex === expanded}
        style={{
          border: `solid 1px ${theme.palette.border.main}`,
          borderRadius: 10,
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore size={20} />}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <Typography fontSize={16} fontWeight={500} color={"#121926"}>
            {questionIndex + 1}. {question.questionText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`riskQuestions.${questionIndex}.questionText`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Question text is required",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel={
                        question?.questionId ? "Edit Question" : "Add Question"
                      }
                      requiredField
                      placeholder="Question Text"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <OptionsList
              optionFields={optionFields}
              removeOption={removeOption}
              question={question}
              questionIndex={questionIndex}
              control={control}
              handleDeleteQuestionOptions={handleDeleteQuestionOptions}
              handleAddOption={handleAddOption}
              handleRemoveQuestion={handleRemoveQuestion}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

// ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
const RiskProfiling = () => {
  useDocumentTitle("Risk Profiling");

  const [expanded, setExpanded] = useState(0);
  const [loading, setLoading] = useState(false);

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const {
    data: riskdatalist,
    isLoading,
    isFetching,
    refetch,
  } = useFetchQuery({
    key: ["RISK_PROFILE"],
    route: `/riskprofile/`,
  });

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const form = useForm({
    defaultValues: {
      field1: "Very Conservative",
      field2: "Conservative",
      field3: "Moderate",
      field4: "Aggressive",
      field5: "Very Aggressive",
      riskStatusOption: 5,
      riskQuestions: [
        {
          questionText: "Question 1",
          options: [
            {
              option: "",
              optionPoints: "",
            },
          ],
        },
      ],
    },
    values: {
      ...riskdatalist?.data?.data?.data,
      field1:
        riskdatalist?.data?.data?.data?.pointsDetails[0] || "Very Conservative",
      field2:
        riskdatalist?.data?.data?.data?.pointsDetails[1] || "Conservative",
      field3: riskdatalist?.data?.data?.data?.pointsDetails[2] || "Moderate",
      field4: riskdatalist?.data?.data?.data?.pointsDetails[3] || "Aggressive",
      field5:
        riskdatalist?.data?.data?.data?.pointsDetails[4] || "Very Aggressive",
      riskStatusOption:
        riskdatalist?.data?.data?.data?.riskStatusOption === "three" ? 3 : 5,
      riskQuestions: riskdatalist?.data?.data?.data.riskQuestions.map(
        (dataItem: any) => {
          return {
            ...dataItem,
            questionId: dataItem.id,
            options: dataItem.options.map((optionItem: any) => {
              return {
                ...optionItem,
                optionId: optionItem.id,
              };
            }),
          };
        }
      ),
    },
  });

  const { control, handleSubmit, setValue, getValues, watch } = form;

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "riskQuestions",
  });

  watch();

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const returnPoints = ({
    questionFields,
    step,
  }: {
    step: number;
    questionFields: any[];
  }) => {
    const maxVal = sum(
      questionFields?.map((item: any) =>
        max(item?.options?.map((i: any) => +i.optionPoints))
      )
    );
    const minValue = sum(
      questionFields?.map((item: any) =>
        min(item?.options?.map((i: any) => +i.optionPoints))
      )
    );

    let range = Math.floor((maxVal - minValue) / step);

    range =
      Math.ceil((maxVal - minValue) / step) === (maxVal - minValue) / step
        ? range - 1
        : range;

    const ranges = [];

    for (let i = 0; i < step; i++) {
      const start = i > 0 ? minValue + i * range + i : minValue + i * range;
      const end = i === step - 1 ? maxVal : start + range;
      ranges.push(`${start} - ${end}`);
    }

    return ranges;
  };

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const { mutate: handleDeleteQuestion } = useMutation({
    mutationKey: ["DELETE_RISK_PROFILE_QUESTION"],
    mutationFn: (id: number) =>
      axiosInstance.delete(`/deleteRiskProfileQuestion/${id}/`),
    onSuccess: () => {
      refetch();
      setLoading(false);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const { mutate: handleDeleteQuestionOptions } = useMutation({
    mutationKey: ["DELETE_RISK_PROFILE_QUESTION_OPTION"],
    mutationFn: ({ id, option }: { id: number; option: any }) =>
      axiosInstance.delete(
        `/deleteRiskProfileQuestionOption/${id}/?option=${option}`
      ),
    onSuccess: () => {
      refetch();
      setLoading(false);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // ===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx===xxx
  const onSubmit = (values: any) => {
    const requestData = {
      ...values,
      riskStatusOption: values?.riskStatusOption === 5 ? "five" : "three",
      pointsDetails:
        values?.riskStatusOption === 5
          ? [
              values?.field1,
              values?.field2,
              values?.field3,
              values?.field4,
              values?.field5,
            ]
          : [values?.field1, values?.field2, values?.field3],
      pointsRange: returnPoints({
        questionFields: getValues("riskQuestions"),
        step: getValues("riskStatusOption"),
      }),
    };
    setLoading(true);
    const request = riskdatalist?.data?.data?.data?.id
      ? axiosInstance.put(
          `/riskprofile/${riskdatalist?.data?.data?.data?.id}/`,
          requestData
        )
      : axiosInstance.post(`/riskprofile/`, requestData);

    request
      .then(() => {
        setLoading(false);
        toast.success("Risk profile successfully created");
        refetch();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
  };

  if (isFetching || loading) return <SimpleLoader />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)} id="risk-form">
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={2}>
              <Typography>Select number of risk categories</Typography>
              <div style={{ display: "flex" }}>
                <GKButton
                  variant={
                    getValues("riskStatusOption") === 3
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setValue("riskStatusOption", 3);
                  }}
                >
                  3 Categories
                </GKButton>
                <Typography
                  mx={1}
                  display={"flex"}
                  alignItems={"center"}
                  fontSize={12}
                  color={"#9AA4B2"}
                >
                  OR
                </Typography>
                <GKButton
                  variant={
                    getValues("riskStatusOption") === 5
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setValue("riskStatusOption", 5);
                  }}
                >
                  5 Categories
                </GKButton>
              </div>
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="field1"
                    control={control}
                    render={({ field }) => (
                      <Card sx={{ padding: "10px 14px" }}>
                        <GKTextField
                          {...field}
                          sx={{
                            "& .MuiInputBase-input": {
                              p: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          placeholder="Enter Category Name"
                        />
                        <Typography
                          fontSize={14}
                          width={"-webkit-fill-available"}
                          color={"#888C96"}
                          mt={"4px"}
                          fontWeight={500}
                        >
                          {
                            returnPoints({
                              questionFields: getValues("riskQuestions"),
                              step: getValues("riskStatusOption"),
                            })[0]
                          }
                        </Typography>
                      </Card>
                    )}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="field2"
                    control={control}
                    render={({ field }) => (
                      <Card sx={{ padding: "10px 14px" }}>
                        <GKTextField
                          {...field}
                          sx={{
                            "& .MuiInputBase-input": {
                              p: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          placeholder="Enter Category Name"
                        />
                        <Typography
                          fontSize={14}
                          width={"-webkit-fill-available"}
                          color={"#888C96"}
                          fontWeight={500}
                        >
                          {
                            returnPoints({
                              questionFields: getValues("riskQuestions"),
                              step: getValues("riskStatusOption"),
                            })[1]
                          }
                        </Typography>
                      </Card>
                    )}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="field3"
                    control={control}
                    render={({ field }) => (
                      <Card sx={{ padding: "10px 14px" }}>
                        <GKTextField
                          {...field}
                          sx={{
                            "& .MuiInputBase-input": {
                              p: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          placeholder="Enter Category Name"
                        />
                        <Typography
                          fontSize={14}
                          width={"-webkit-fill-available"}
                          color={"#888C96"}
                          fontWeight={500}
                        >
                          {
                            returnPoints({
                              questionFields: getValues("riskQuestions"),
                              step: getValues("riskStatusOption"),
                            })[2]
                          }
                        </Typography>
                      </Card>
                    )}
                  />
                </FormControl>
                {getValues("riskStatusOption") === 5 && (
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="field4"
                      control={control}
                      render={({ field }) => (
                        <Card sx={{ padding: "10px 14px" }}>
                          <GKTextField
                            {...field}
                            sx={{
                              "& .MuiInputBase-input": {
                                p: 0,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            placeholder="Enter Category Name"
                          />
                          <Typography
                            fontSize={14}
                            width={"-webkit-fill-available"}
                            color={"#888C96"}
                          >
                            {
                              returnPoints({
                                questionFields: getValues("riskQuestions"),
                                step: getValues("riskStatusOption"),
                              })[3]
                            }
                          </Typography>
                        </Card>
                      )}
                    />
                  </FormControl>
                )}
                {getValues("riskStatusOption") === 5 && (
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="field5"
                      control={control}
                      render={({ field }) => (
                        <Card sx={{ padding: "10px 14px" }}>
                          <GKTextField
                            {...field}
                            sx={{
                              "& .MuiInputBase-input": {
                                p: 0,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            placeholder="Enter Category Name"
                          />
                          <Typography
                            fontSize={14}
                            width={"-webkit-fill-available"}
                            color={"#888C96"}
                          >
                            {
                              returnPoints({
                                questionFields: getValues("riskQuestions"),
                                step: getValues("riskStatusOption"),
                              })[4]
                            }
                          </Typography>
                        </Card>
                      )}
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} mt={1}>
                <Typography fontSize={12} fontWeight={400} color={"#999"}>
                  Tip: Just click on the risk category title to change it!
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} mt={1} display={"flex"} alignItems={"center"}>
              <Typography fontSize={18} fontWeight={500} color={"#4B5565"}>
                Questions
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              mt={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <Button
                onClick={() =>
                  appendQuestion({
                    questionText: `Question ${questionFields.length + 1}`,
                    options: [{ option: "", optionPoints: "" }],
                  })
                }
                variant="outlined"
                color="dark"
                disabled={isLoading}
                startIcon={<CgMathPlus size={18} />}
              >
                Add Question
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                form="risk-form"
                size="medium"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Grid>
            {questionFields.map((question, questionIndex) => (
              <QuestionAccordion
                key={question.id}
                question={question}
                questionIndex={questionIndex}
                setExpanded={setExpanded}
                expanded={expanded}
                control={control}
                handleDeleteQuestion={handleDeleteQuestion}
                handleDeleteQuestionOptions={handleDeleteQuestionOptions}
                getValues={getValues}
                setValue={setValue}
                removeQuestion={removeQuestion}
              />
            ))}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default RiskProfiling;
