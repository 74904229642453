/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Button, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppContext } from "AppContext";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";
import { matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ImportModelPortfolio from "./ImportModelPortfolio";
import RebalanceModal from "./RebalanceModal";
import { moneyCommaSeparator } from "utils/MathFunction";

interface ModelPortfolioListInterface {
  id: number;
  version: string;
  isRebalanced: boolean;
  rebalanced: boolean;
  isARebalance: boolean;
  active: boolean;
  date: string;
  primary: boolean;
  lastRebalanceDate: string;
  nextRebalanceDate: any;
  minimumInvestmentValueCheck: boolean;
  investedValue: number;
  currentValue: number;
  ceAmount: number;
  currentValueWoCe: number;
  quantity: number;
  realisedGain: number;
  otherGains: number;
  unrealisedGain: number;
  todaysGain: number;
  absoluteReturn: number;
  todaysGainPercentage: number;
  yesterdayInvestment: number;
  yesterdayValue: number;
  isValid: boolean;
  availableCash: number;
  netContribution: number;
  netContributionWithFee: number;
  netContributionWithDividend: number;
  totalWithdraw: number;
  totalDeposit: number;
  inceptionDate: string;
  modelPortfolios: string;
  isModelPortfolioMapped: boolean;
  lastOrderModelPortfolio: string;
  lastOrderVersion: string;
  lastOrderTemplate: string;
  lastOrderType: string;
  lastOrderDate: any;
  lastExecutionSheet: string;
  totalDepositWithFee: number;
  lastTransactionDate: any;
  lastUploadDate: any;
  lastCashDate: any;
  lastCashUploadDate: any;
  lastXirrRefresh: string;
  mutualFunds: number;
  xirr: number;
  portfolioId: string;
  name: string;
  portfolioValue: number;
  lastRefreshTime: string;
  holdingCount: number;
}

const ModelPortfolioVersionForm = () => {
  useDocumentTitle("Basket Model Portfolio");

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    state: { companies },
  } = useAppContext();

  const [allVersions, setAllVersions] = useState<ModelPortfolioListInterface[]>(
    []
  );

  const [isSelected, setIsSelected] = useState(null);

  const [openModelToRebalance, setOpenModelToRebalance] = useState(false);

  const [importSheetModal, setImportSheetModal] = useState(false);

  /** ========================================================================================================= */
  const { isFetching } = useFetchQuery({
    key: ["GET_ALL_VERSIONS"],
    route: `model_portfolio/${id}/versions_list/`,
    success: (response: any) => {
      setAllVersions(response?.data);
    },
  });

  /** ========================================================================================================= */
  const modalPortFolioVersionCoumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 150,
      field: "version",
      headerName: "Model Portfolio",
      disableColumnMenu: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "date",
      headerName: "Creation Date",
      disableColumnMenu: true,
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      flex: 1,
      minWidth: 150,
      field: "portfolioValue",
      headerName: "Portfolio Amount",
      type: "number",
      disableColumnMenu: true,
      valueFormatter: (val) => moneyCommaSeparator(val.value),
    },
    {
      flex: 1,
      minWidth: 150,
      field: "investedValue",
      headerName: "Invested Value",
      type: "number",
      disableColumnMenu: true,
      valueFormatter: (val) => moneyCommaSeparator(val.value),
    },
    {
      flex: 1,
      minWidth: 150,
      type: "number",
      field: "currentValue",
      headerName: "Current Value",
      disableColumnMenu: true,
      valueFormatter: (val) => moneyCommaSeparator(val.value),
    },
    {
      flex: 1,
      minWidth: 150,
      field: "xirr",
      type: "number",
      headerName: "XIRR",
      disableColumnMenu: true,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "holdingCount",
      type: "number",
      headerName: "Asset Count",
      disableColumnMenu: true,
    },

    {
      flex: 1,
      minWidth: 100,
      field: "action",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      disableColumnMenu: true,
      renderCell: (cell) => {
        return (
          <Grid container gap={1} justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              color="dark"
              onClick={(e) => {
                e.stopPropagation();
                setIsSelected(cell.row);
                setOpenModelToRebalance(true);
              }}
            >
              Rebalance
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <DashboardLayout title="Basket Model Portfolio">
      <Grid container>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="modelPortfolioVersionForm"
            showFiltersButton={false}
            loading={isFetching}
            columns={modalPortFolioVersionCoumns}
            rows={allVersions || []}
            getRowClassName={(params) => {
              return params?.row?.primary ? "primary-row" : "";
            }}
            onRowClick={(row) => {
              setIsSelected(row.row);
              navigate(
                `/app/equity-basket/${id}/model-portfolio/${row?.row?.id}`
              );
            }}
            footerComponent={
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
                pl={2}
              >
                *Row with background color
                <div
                  style={{
                    width: 16,
                    height: 16,
                    background: "#00970525",
                  }}
                />{" "}
                indicates primary model portfolio
              </Typography>
            }
            headerComponent={
              <Grid
                container
                justifyContent={"flex-end"}
                gap={1}
                alignItems={"center"}
              >
                <Button
                  size="medium"
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    setImportSheetModal(true);
                  }}
                >
                  Import Model Portfolio
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    navigate(`/app/equity-basket/${id}/model-portfolio/add`);
                  }}
                >
                  Add Model Portfolio
                </Button>
              </Grid>
            }
          />
        </Grid>
      </Grid>

      <Show.When isTrue={openModelToRebalance}>
        <RebalanceModal
          setOpen={setOpenModelToRebalance}
          open={openModelToRebalance}
          companyMasterData={companies}
          selectedId={isSelected?.id}
        />
      </Show.When>
      <Show.When isTrue={importSheetModal}>
        <ImportModelPortfolio
          setOpen={setImportSheetModal}
          open={importSheetModal}
          isRebalanceModal={false}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default ModelPortfolioVersionForm;
