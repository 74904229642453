/* eslint-disable react/destructuring-assignment */
import { Grid, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import CreatePortfolioGrouping from "Pages/PortfolioGrouping/CreatePortfolioGrouping";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioGroupList = () => {
  const navigate = useNavigate();
  const {
    state: { commonFilter },
  } = useAppContext();

  useDocumentTitle("Portfolio Groups");

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.portfolioGroup?.take,
    skip: commonFilter?.portfolioGroup?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioGroup?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioGroup?.search || "",
    show_zero_holding: commonFilter?.portfolioGroup?.showNonZero,
    filter: JSON.stringify(commonFilter?.portfolioGroup?.customFilter),
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { isFetching: isLoading, data: portfolioGroups } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_GROUP_LIST, requestBody],
    route: "/portfolio-groups/",
    requestBody,
  });

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const exportCsv = () => {
    return (
      <ExportCsv
        path={"/portfolio-groups"}
        name={"PortfolioGroupsList"}
        filter={JSON.stringify(commonFilter?.portfolioGroup?.customFilter)}
      />
    );
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const cusPagination = () => {
    return (
      <CommonPagination
        name="portfolioGroup"
        totalCount={portfolioGroups?.data?.totalCount || 0}
      />
    );
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const PortfolioDetailTable = (params: GridRowParams) => {
    const { row } = params;

    const columns: GridColDef[] = [
      {
        ...extraColumnsField("Portfolio", "clientName", 250, 1),
        renderCell: (params) => {
          return (
            <Grid
              container
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Typography fontWeight={700}>
                {params?.row?.clientName}
              </Typography>
              <Typography fontSize={12}>
                {params?.row?.name} - {params?.row?.portfolioId}
              </Typography>
            </Grid>
          );
        },
      },
      {
        ...extraColumnsField("Invested Value", "investedValue", 150, 1),
        type: "number",
        valueGetter: (param) => param?.row?.investedValue,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
      },
      {
        ...extraColumnsField("Current Value", "currentValue", 150, 1),
        type: "number",
        valueGetter: (param) => param?.row?.currentValue,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
      },
      {
        ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
        type: "number",
        valueGetter: (params) => params.row.unrealisedGain,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
        renderCell: (value) => {
          const { unrealisedGain } = value?.row;
          return (
            <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
              {moneyCommaSeparator(unrealisedGain)}
            </Typography>
          );
        },
      },
      {
        ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
        type: "number",
        valueGetter: (params) => params.row.absoluteReturn,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
        renderCell: (value) => {
          return (
            <Typography
              color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
            >
              {moneyCommaSeparator(value?.row?.absoluteReturn)}%
            </Typography>
          );
        },
      },
      {
        ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
        type: "number",
        valueGetter: (params) => params.row.todaysGain,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
        renderCell: (value) => {
          const { todaysGain } = value?.row;
          return (
            <Typography color={handleReturnPositiveNegative(todaysGain)}>
              {moneyCommaSeparator(todaysGain)}
            </Typography>
          );
        },
      },
      {
        ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
        type: "number",
        valueGetter: (params) => params.row.todaysGainPercentage,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
        renderCell: (value) => {
          return (
            <Typography
              color={handleReturnPositiveNegative(
                value?.row?.todaysGainPercentage
              )}
            >
              {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
            </Typography>
          );
        },
      },
      {
        ...extraColumnsField("XIRR", "xirr", 70, 1),
        type: "number",
        valueGetter: (param) => param?.row?.xirr,
        valueFormatter: (params) => moneyCommaSeparator(params.value),
        renderCell: (param) => (
          <Typography>{param?.row?.xirr?.toFixed(2) || "0"}</Typography>
        ),
      },
    ];

    return (
      <Grid container p={2}>
        <GKClientDataGrid
          name="portfolioGroup"
          showHeaderGrid={false}
          columns={columns}
          rows={row?.portfolioList || []}
          searchPlaceholder="Search for Portfolios"
          onRowClick={(row) => {
            navigate(`/app/holdings/portfolio/${row?.id}`);
          }}
        />
      </Grid>
    );
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const portfolioGroupListColumns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Group Name", "name", 200, 1),
      editable: false,
      valueGetter: (params) => params.row.name,
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      editable: false,
      valueGetter: (row) => row?.row?.summary?.investedValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      editable: false,
      valueGetter: (row) => row?.row?.summary?.currentValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      type: "number",
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row?.summary;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row?.summary?.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.summary?.absoluteReturn
            )}
          >
            {moneyCommaSeparator(value?.row?.summary?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row?.summary;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.summary?.todaysGainPercentage
            )}
          >
            {moneyCommaSeparator(value?.row?.summary?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("XIRR", "xirr", 70, 1),
      editable: false,
      type: "number",
      valueGetter: (row) => row?.row?.summary?.xirr,
      valueFormatter: (params) => moneyCommaSeparator(params.value) || 0,
      renderCell: (row) => {
        return <Typography>{row?.row?.summary?.xirr}</Typography>;
      },
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Portfolio Count", "count", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.count || "0",
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isLoading}
        rows={portfolioGroups?.data?.data || []}
        columns={portfolioGroupListColumns}
        pinnedColumns={{
          right: ["action"],
        }}
        maxHeight={"calc(100vh - 321px)"}
        searchPlaceholder="Search for Portfolio Group"
        onRowClick={(item) => {
          navigate(`/app/portfolio/group/${item?.row?.id}`, {
            state: {
              name: item?.row?.name,
            },
          });
        }}
        getDetailPanelContent={PortfolioDetailTable}
        CustomPagination={!isLoading && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        headerComponent={
          <GKButton
            className="add-new-portfolio-group"
            onClick={() => {
              setIsCreateGroupModalOpen(true);
            }}
            variant="contained"
            size="medium"
            startIcon={<CgMathPlus size={14} />}
          >
            Create Portfolio Group
          </GKButton>
        }
        zeroHolding
        name="portfolioGroup"
        tableName="portfolio_group_list"
        headerFilter={<TableFiltering name="portfolioGroup" />}
      />

      {isCreateGroupModalOpen && (
        <CreatePortfolioGrouping
          open={isCreateGroupModalOpen}
          setOpen={setIsCreateGroupModalOpen}
        />
      )}
    </>
  );
};

export default PortfolioGroupList;
