import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField } from "@mui/material";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import useAddPortfolioModal from "hooks/useAddPortfolioModal";
import { Controller } from "react-hook-form";
import { CgMathPlus } from "react-icons/cg";
import ClientModal from "./ClientModal";

interface Props {
  setOpen?: (value: boolean) => void;
  open?: boolean;
  showClientButton?: boolean;
}

const AddPortfolioModal = ({
  setOpen,
  open,
  showClientButton = true,
}: Props) => {
  const {
    state: { clientList },
  } = useAppContext();

  const {
    isSubmitting,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
    id,
    form,
    onSubmit,
  } = useAddPortfolioModal({ setOpen });

  return (
    <GKModal
      modalTitle="Create Portfolio"
      open={open}
      setOpen={setOpen}
      titleComponent={
        showClientButton && (
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => setIsClientCreateModalOpen(true)}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Client
          </GKButton>
        )
      }
      footer={
        <LoadingButton
          variant="contained"
          type="submit"
          size="medium"
          form="add-portfolio-form"
          loading={isSubmitting}
        >
          {"Create Portfolio"}
        </LoadingButton>
      }
    >
      <>
        <form id={"add-portfolio-form"} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <GKTextField
                        {...field}
                        inputLabel="Portfolio Name"
                        placeholder="Portfolio Name"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="portfolioId"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      onChange={(event) => {
                        field.onChange(event.target.value.trim());
                      }}
                      inputLabel="Portfolio Code"
                      placeholder="like UCC of Demat A/c"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {id ? (
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        name={"name"}
                        disabled
                        inputLabel="Client"
                        placeholder="Client"
                        requiredField
                        value={
                          clientList?.find((data) => data?.value === Number(id))
                            ?.name
                        }
                      />
                    )}
                  />
                </FormControl>
              ) : (
                <Controller
                  name="client"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKSearchSelect
                      disableClearable
                      inputLabel="Client"
                      requiredField
                      options={clientList || []}
                      onChange={(e, val) => {
                        field.onChange(val.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="client"
                          placeholder="Select Client"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </form>

        {isClientCreateModalOpen && (
          <ClientModal
            isModalOpen={isClientCreateModalOpen}
            setIsModalOpen={setIsClientCreateModalOpen}
          />
        )}
      </>
    </GKModal>
  );
};

export default AddPortfolioModal;
