import { useMutation, useQueries } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface UseAutoImportProps {
  setOpen: any;
}

const useAutoImport = ({ setOpen }: UseAutoImportProps) => {
  const { id } = useParams();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      portfolio: Number(id) || "",
      broker: "",
      password: "",
    },
  });

  const [
    { isFetching: portfoliosDataLoading, data: portfolioList },
    { data: brokersList, isFetching: brokerLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: [QueryKeys.PORTFOLIOS_SHORT_DATA],
        queryFn: () => axiosInstance.get("/portfolios/all/"),
      },
      {
        queryKey: [QueryKeys.BROKERS_LIST],
        queryFn: () => axiosInstance.get("/auto_forward/get-brokers/"),
      },
    ],
  });

  const { mutate, isLoading: addLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/auto_forward/`, {
        portfolio: id,
        broker: data?.broker?.name,
        password: data?.password,
      }),

    onSuccess: () => {
      refetchQuery("AUTO_FORWARD_DATA");
      setOpen(false);
    },

    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handleFormSubmit = (data: any) => {
    mutate(data);
  };

  return {
    handleFormSubmit,
    addLoading,
    portfoliosDataLoading,
    portfolioList,
    brokersList,
    brokerLoading,
    control,
    handleSubmit,
    id,
  };
};

export default useAutoImport;
