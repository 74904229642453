import { Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { BsDot } from "react-icons/bs";

const Section9 = () => {
  return (
    <Grid
      container
      py={10}
      alignItems={"center"}
      spacing={3}
      pt={0}
      sx={{
        flexDirection: {
          xs: "column-reverse",
          lg: "row",
        },
        px: {
          xs: 3,
          lg: 10,
        },
        background: "#020707",
      }}
    >
      <Grid item xs={12} lg={6}>
        <img
          width={"100%"}
          src="/LandingPage/wws9.svg"
          alt=""
          style={{
            padding: 30,
            borderRadius: 20,
            border: "1px solid #323232",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        container
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          gap={2}
          sx={{
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            justifyContent: {
              xs: "center",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              lg: "flex-start",
            },
          }}
        >
          <img width={"74px"} src="/LandingPage/convertLeads.svg" alt="" />
          <Typography
            fontSize={32}
            fontWeight={700}
            sx={{
              textAlign: {
                xs: "center",
                lg: "left",
              },
            }}
          >
            Convert more leads through automated lead process{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: {
              xs: "center",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              lg: "flex-start",
            },
            display: {
              xs: "flex",
              lg: "unset",
            },
          }}
        >
          <List>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Optimize your custom lead process to your unique needs
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Create online/offline lead meetings and manage notes of lead
                communications.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Complete risk profile and financial planning of the lead and
                send marketing materials with a single click.
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Section9;
