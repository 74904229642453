import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { PortfolioSummary } from "Pages";
import NoteModal from "Pages/AdviceRegisterNotes/NoteModal";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import { Show } from "components/Show";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { GrUserSettings } from "react-icons/gr";
import { LuCalendarDays } from "react-icons/lu";
import { TbRefresh } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ModelPortfolioTab from "./PortfolioBaskets/ModelPortfolioTab";
import PortfolioHoldingDetails from "./PortfolioHoldingsDetails/PortfolioHoldingDetails";
import PortfolioNotes from "./PortfolioNotes";
import PortfolioRevenue from "./PortfolioRevenue";
import AddRevenueModal from "./PortfolioRevenue/AddRevenueModal";
import SettingTab from "./SettingTab";
import PortfolioSettingsEdit from "./SettingsPages";
import Benchmark from "./SummaryPages/Benchmark";
import PortfolioUtilityEdit from "./UtilityPages";

const PortfolioDetailsPage = () => {
  useDocumentTitle("Portfolio Details");

  const { theme } = ThemeContainer();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    state: { userData },
  } = useAppContext();

  const [portfolioTabs, setPortfolioTabs] = useState(0);

  const [openNoteModel, setOpenNoteModel] = useState(false);

  const [isXirrRefresh, setIsXirrRefresh] = useState(false);

  const [portfolioExtendedViewTabs, setPortfolioExtendedViewTabs] = useState(0);

  const [portfolioAdminViewTabs, setPortfolioAdminViewTabs] = useState(0);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [revenueModal, setRevenueModal] = useState(false);

  const { isFetching: xirrLoading } = useFetchQuery({
    route: `/portfolios/${id}/refresh_xirr/`,
    key: ["GET_PORTFOLIO_XIRR"],
    enabled: isXirrRefresh,
    success: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_CARDS");
      setIsXirrRefresh(false);
    },
  });

  const { mutate: deletePortfolio, isLoading: deleteLoading } = useMutation({
    mutationKey: ["DELETE_PORTFOLIO"],
    mutationFn: () => axiosInstance.delete(`/portfolios/${id}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(`/app/holdings`);
    },
    onError: (error: any) => {
      refetchQuery("GET_PORTFOLIO_DETAIL");
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { data: portfolioDetailData, isFetching } = useFetchQuery({
    key: ["GET_PORTFOLIO_DETAIL_BY_ID", id],
    route: `/portfolios/${id}/`,
  });

  const { isFetching: cardDataLoading, data: holdingSummaryDetails } =
    useFetchQuery({
      key: ["GET_PORTFOLIO_DETAIL_CARDS", id],
      route: `/portfolios/${id}/holdings/summary/`,
    });

  return (
    <>
      <DashboardLayout title="PortfolioDetailsPage" loading={isFetching}>
        <Grid container spacing={2}>
          <Grid item xs={4} container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={20} color={"#202939"}>
                {portfolioDetailData?.data?.clientName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <Typography color={"#000000"} fontWeight={600} fontSize={12}>
                <span
                  style={{
                    color: "#697586",
                    marginRight: 4,
                    fontWeight: 400,
                  }}
                >
                  Portfolio Name:
                </span>
                {portfolioDetailData?.data?.name}
              </Typography>
              <Typography color={"#000000"} fontWeight={600} fontSize={12}>
                <span
                  style={{
                    color: "#697586",
                    marginRight: 4,
                    fontWeight: 400,
                  }}
                >
                  Portfolio Code:
                </span>
                {portfolioDetailData?.data?.portfolioId}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={2}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                color={"#697586"}
                fontSize={12}
              >
                <GrUserSettings size={14} style={{ marginRight: 4 }} />
                Family Name:
                <span
                  style={{
                    color: "#000",
                    fontWeight: 600,
                    marginLeft: 4,
                  }}
                >
                  {portfolioDetailData?.data?.familyName}
                </span>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                color={"#697586"}
                fontSize={12}
              >
                <LuCalendarDays size={14} style={{ marginRight: 4 }} />
                Inception Date:{" "}
                <span
                  style={{
                    color: "#000",
                    fontWeight: 600,
                    marginLeft: 4,
                  }}
                >
                  {dayjs(holdingSummaryDetails?.data?.inceptionDate).format(
                    "DD MMM YYYY"
                  )}
                </span>
              </Typography>
            </div>
            <Divider orientation="vertical" />
            <Tabs
              className="light"
              variant="standard"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              onChange={(e, val) => {
                setPortfolioExtendedViewTabs(0);
                setPortfolioTabs(val);
              }}
              value={portfolioTabs}
            >
              <Tab value={0} label="Holding View" />
              <Tab value={1} label="Admin View" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Show.When isTrue={portfolioExtendedViewTabs === 0}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: 16,
                          width: "16%",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          Net Contribution
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {cardDataLoading ? (
                            <Skeleton />
                          ) : (
                            numberToCurrency(
                              holdingSummaryDetails?.data?.netContribution || 0
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: 16,
                          width: "16%",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          Ledger Balance
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {cardDataLoading ? (
                            <Skeleton />
                          ) : (
                            numberToCurrency(
                              holdingSummaryDetails?.data?.availableCash || 0
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: 16,
                          width: "16%",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          Dividend
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {cardDataLoading ? (
                            <Skeleton />
                          ) : (
                            numberToCurrency(
                              holdingSummaryDetails?.data?.otherGains || 0
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: 16,
                          width: "16%",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          Portfolio Value
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {cardDataLoading ? (
                            <Skeleton />
                          ) : (
                            numberToCurrency(
                              holdingSummaryDetails?.data?.portfolioValue || 0
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: 16,
                          width: "16%",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          Total Gain
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {cardDataLoading ? (
                            <Skeleton />
                          ) : (
                            numberToCurrency(
                              holdingSummaryDetails?.data?.realisedGain +
                                holdingSummaryDetails?.data?.unrealisedGain +
                                holdingSummaryDetails?.data?.otherGains || 0
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: 16,
                          width: "20%",
                          borderBottom: "none",
                        }}
                      >
                        <Typography fontSize={12} color={"#697586"}>
                          {userData?.showTwrr ? "TWRR" : "XIRR"}{" "}
                          {!userData?.showTwrr && (
                            <IconButton
                              disabled={xirrLoading}
                              onClick={() => {
                                setIsXirrRefresh(true);
                              }}
                            >
                              {xirrLoading ? (
                                <CircularProgress size={12} />
                              ) : (
                                <TbRefresh size={12} />
                              )}
                            </IconButton>
                          )}
                        </Typography>
                        <Typography fontWeight={600} fontSize={18}>
                          {userData?.showTwrr
                            ? holdingSummaryDetails?.data?.twrr?.toFixed(2)
                            : holdingSummaryDetails?.data?.xirr?.toFixed(2) ||
                              "0"}
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#9AA4B2",
                            }}
                          >
                            (*As of{" "}
                            {holdingSummaryDetails?.data?.lastTwrrRefresh
                              ? holdingSummaryDetails?.data?.lastTwrrRefresh
                              : holdingSummaryDetails?.data?.lastRefreshTime}
                            )
                          </span>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Show.When>
          <Show.When isTrue={portfolioTabs === 0}>
            <Grid item xs={12} container>
              <Grid item xs={10}>
                <Tabs
                  value={portfolioExtendedViewTabs}
                  onChange={(e, val) => {
                    setPortfolioExtendedViewTabs(val);
                  }}
                >
                  <Tab label="Holdings" />
                  <Tab label="Overview" />
                </Tabs>
              </Grid>
              {portfolioExtendedViewTabs === 1 && (
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={500} fontSize={12}>
                    XIRR:&nbsp;
                  </Typography>
                  <Typography fontWeight={600} fontSize={18}>
                    {holdingSummaryDetails?.data?.xirr
                      ? `${holdingSummaryDetails?.data?.xirr?.toFixed(2)}%`
                      : "-"}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioExtendedViewTabs === 1 && portfolioTabs === 0}
          >
            <Grid item xs={12}>
              <PortfolioSummary />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioExtendedViewTabs === 0 && portfolioTabs === 0}
          >
            <Grid item xs={12}>
              <PortfolioHoldingDetails />
            </Grid>
          </Show.When>
          <Show.When isTrue={portfolioTabs === 1}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Tabs
                  value={portfolioAdminViewTabs}
                  onChange={(e, val) => {
                    setPortfolioAdminViewTabs(val);
                  }}
                >
                  <Tab label="Imports" value={0} />

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Notes" value={1} />
                  )}

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Benchmark" value={2} />
                  )}

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Baskets" value={3} />
                  )}

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Revenue" value={4} />
                  )}

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Access" value={5} />
                  )}

                  {userData?.userRole !== UserRoleTypes.client && (
                    <Tab label="Settings" value={6} />
                  )}
                </Tabs>
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                <Show.When isTrue={portfolioAdminViewTabs === 1}>
                  <GKButton
                    onClick={() => setOpenNoteModel(true)}
                    variant="contained"
                  >
                    Add New Note
                  </GKButton>
                </Show.When>
                <Show.When isTrue={portfolioAdminViewTabs === 6}>
                  <LoadingButton
                    loading={deleteLoading}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                    }}
                    variant="outlined"
                    color="error"
                  >
                    Delete Portfolio
                  </LoadingButton>
                </Show.When>
                <Show.When isTrue={portfolioAdminViewTabs === 4}>
                  <GKButton
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      setRevenueModal(true);
                    }}
                  >
                    Add Advance Amount
                  </GKButton>
                </Show.When>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 0}
          >
            <Grid item xs={12}>
              <PortfolioUtilityEdit />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 1}
          >
            <Grid item xs={12}>
              <PortfolioNotes />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 2}
          >
            <Grid item xs={12}>
              <Benchmark benchmarkId={portfolioDetailData?.data?.benchmark} />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 3}
          >
            <Grid item xs={12}>
              <ModelPortfolioTab
                holdingSummaryDetails={holdingSummaryDetails?.data}
                portfolioDetailData={portfolioDetailData?.data}
              />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 4}
          >
            <Grid item xs={12}>
              <PortfolioRevenue />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 5}
          >
            <Grid item xs={12}>
              <PortfolioSettingsEdit />
            </Grid>
          </Show.When>
          <Show.When
            isTrue={portfolioTabs === 1 && portfolioAdminViewTabs === 6}
          >
            <Grid item xs={12}>
              <SettingTab data={portfolioDetailData?.data} />
            </Grid>
          </Show.When>
        </Grid>
      </DashboardLayout>

      <Show.When isTrue={openNoteModel}>
        <NoteModal
          open={openNoteModel}
          setOpen={setOpenNoteModel}
          portfolioId={id}
          clientId={portfolioDetailData?.data?.client}
        />
      </Show.When>

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Portfolio"
          onDelete={() => deletePortfolio()}
          isDelete
          deleteMessage="Are you sure you want to delete this portfolio? This client will permanently be removed. This action cannot be undone."
        />
      )}

      <Show.When isTrue={revenueModal}>
        <AddRevenueModal open={revenueModal} setOpen={setRevenueModal} />
      </Show.When>
    </>
  );
};

export default PortfolioDetailsPage;
