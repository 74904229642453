import { LoadingButton } from "@mui/lab";
import { Card, Divider, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import NoData from "components/NoData";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { adviseRegisterFilters } from "utils/constants";
import { AdviceRegisterKey, NotesColor } from "utils/queryKey";
import AdviceAdviceRegister from "./AdviceAdviceRegister";
import AgreementAdviceRegister from "./AgreementAdviceRegister";
import KYCAdviceRegister from "./KYCAdviceRegister";
import OrderAdviceRegister from "./OrderAdviceRegister";
import RiskProfileAdviceRegister from "./RiskProfileAdviceRegister";

const AdviceRegister = () => {
  const { theme } = ThemeContainer();

  const { id } = useParams();

  const {
    state: { adviseRegisterFilter },
    dispatch,
  } = useAppContext();

  const [pageData, setPageData] = useState(10);

  const [adviceRegisterList, setAdviceRegisterList] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState([]);

  const requestBody = {
    take: pageData,
    filterType: JSON.stringify(
      adviseRegisterFilter?.map((data: any) => {
        return data;
      }) || []
    ),
  };

  const { data, isFetching } = useQuery({
    queryKey: ["ADVISE_REGISTER_LIST", requestBody],
    queryFn: () =>
      axiosInstance.get(`/advice-register/${id}/`, {
        params: requestBody,
      }),
    keepPreviousData: true,
    onSuccess: (data) => {
      setAdviceRegisterList(data?.data?.data);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <>
      <Card style={{ height: "cal(1000vh - 86px)", overflow: "auto" }}>
        <Grid container position={"relative"}>
          <Grid item xs={12}>
            <LoadingButton
              onClick={() => {
                setPageData(10);
                dispatch({ type: "ADVICE_REGISTER_FILTER", data: [] });
                setSelectedFilter([]);
              }}
              style={{
                border: "1px solid",
                background:
                  selectedFilter?.length === 0
                    ? `#53B795`
                    : theme.palette.light.main,
                color:
                  selectedFilter?.length === 0
                    ? theme.palette.light.main
                    : `#53B795`,
                borderColor: `#53B795`,
                marginRight: 10,
              }}
              loading={isFetching && selectedFilter?.length === 0}
            >
              {"#All"}
            </LoadingButton>
            {adviseRegisterFilters.map((dataItem, index) => (
              <LoadingButton
                onClick={() => {
                  setPageData(10);
                  if (
                    selectedFilter?.length !== 0 &&
                    selectedFilter?.includes(dataItem?.name)
                  ) {
                    dispatch({
                      type: "ADVICE_REGISTER_FILTER",
                      data: adviseRegisterFilter?.filter(
                        (item: any) => !dataItem?.value?.includes(item)
                      ),
                    });

                    setSelectedFilter(
                      selectedFilter?.filter((item) => item !== dataItem?.name)
                    );
                  } else {
                    const newFilter = selectedFilter?.filter(
                      (item) => item !== "#all"
                    );
                    dispatch({
                      type: "ADVICE_REGISTER_FILTER",
                      data: [...adviseRegisterFilter, ...dataItem.value],
                    });
                    setSelectedFilter([...newFilter, dataItem?.name]);
                  }
                }}
                key={index}
                style={{
                  border: "1px solid",
                  background: selectedFilter?.includes(dataItem?.name)
                    ? dataItem?.color
                    : theme.palette.light.main,
                  color: selectedFilter?.includes(dataItem?.name)
                    ? theme.palette.light.main
                    : `${dataItem?.color}`,
                  borderColor: `${dataItem?.color}`,
                  marginRight: 10,
                }}
                loading={isFetching && selectedFilter?.includes(dataItem?.name)}
              >
                {dataItem?.name}
              </LoadingButton>
            ))}
          </Grid>
          <Grid item xs={12} my={2}>
            <Divider />
          </Grid>
          {!adviceRegisterList.length && (
            <Grid item xs={12}>
              <NoData />
            </Grid>
          )}
          {adviceRegisterList?.map((dataItem: any, index: number) => {
            return (
              <Fragment key={index}>
                {dataItem.type === AdviceRegisterKey.RISK_PROFILE && (
                  <RiskProfileAdviceRegister
                    dataItem={dataItem}
                    index={index}
                  />
                )}

                {dataItem.type === AdviceRegisterKey.KYC && (
                  <KYCAdviceRegister dataItem={dataItem} index={index} />
                )}

                {dataItem.type === AdviceRegisterKey.AGREEMENT && (
                  <AgreementAdviceRegister dataItem={dataItem} index={index} />
                )}

                {dataItem.type === AdviceRegisterKey.ADVICE && (
                  <AdviceAdviceRegister
                    dataItem={{
                      ...dataItem,
                      noteTypesList: dataItem?.data?.noteTypesList?.map(
                        (dataItem: any) => {
                          return {
                            ...dataItem,
                            color: NotesColor[dataItem?.name],
                          };
                        }
                      ),
                    }}
                    index={index}
                  />
                )}

                {dataItem.type === AdviceRegisterKey.ORDER && (
                  <OrderAdviceRegister dataItem={dataItem} index={index} />
                )}
              </Fragment>
            );
          })}

          {adviceRegisterList.length < data?.data?.totalCount && (
            <Grid item xs={12}>
              <LoadingButton
                loading={isFetching}
                fullWidth
                variant="outlined"
                onClick={() => {
                  setPageData(pageData + 10);
                }}
              >
                Load More{" "}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default AdviceRegister;
