/* eslint-disable no-unsafe-optional-chaining */
import { Grid, TextField } from "@mui/material";
import DragDrop from "components/DragDrop";
import GKSearchSelect from "components/GKSearchSelect";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";

interface Props {
  formik?: any;
  favoriteUploadSamples?: any;
  setShowFavoriteModal?: any;
  menuOptions?: any;
  selectionModal?: any;
  setSelectionModal?: any;
  facLoading?: any;
  id?: any;
  setAllFiles?: any;
  handleDownloadSampleFiles?: any;
}

const ReconStepOne = ({ formik, id, setAllFiles }: Props) => {
  const [uploadError, setUploadError] = useState<string | null>(null);

  const { data: menuOptions } = useFetchQuery({
    key: ["RECON_UPLOAD_FORMATS"],
    route: `/recon_upload/upload_format/`,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} id={id} className="upload-step-2">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <GKSearchSelect
              inputLabel="Select Type"
              getOptionLabel={(option) => option?.title || option}
              options={menuOptions?.data?.data || []}
              onChange={(event, val) => {
                formik?.setFieldValue("fileType", val?.fileType);
                formik?.setFieldValue("broker", val?.broker);
                formik?.setFieldValue("version", val?.version);
                formik?.setFieldValue("title", val?.title);
                formik?.setFieldValue(
                  "isPasswordRequired",
                  val?.isPasswordRequired
                );
              }}
              value={formik?.values.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Broker" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DragDrop
              uploadError={uploadError}
              setUploadError={setUploadError}
              acceptType={".csv, .pdf,.xlsx,.xls"}
              value={formik?.values?.file}
              supportName="CSV or PDF or XLSX"
              onChange={(event) => {
                formik.setFieldValue("file", event);
                setAllFiles(
                  event?.map((data) => {
                    return {
                      file: data,
                      isFinished: false,
                    };
                  })
                );
              }}
              multiple
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ReconStepOne;
