import { Card, Grid, Skeleton, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import RateCardModal from "./RateCardModal";
import WalletFullHistory from "./WalletFullHistory";
import WalletHistory from "./WalletHistory";
import WalletTopupModal from "./WalletTopupModal";
import { moneyCommaSeparator } from "utils/MathFunction";

const Wallet = () => {
  const { theme } = ThemeContainer();

  const [openTopUp, setOpenTopUp] = useState(false);
  const [openRateCardModal, setOpenRateCardModal] = useState(false);

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_WALLET_BALANCE"],
    route: "/wallet-top-up/",
  });

  return (
    <DashboardLayout title="Wallet">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  display={"flex"}
                  alignItems={"center"}
                  color={theme.palette.grey[400]}
                >
                  My Balance
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={24}
                  color={theme.palette.dark[950]}
                >
                  {isFetching ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `₹ ${moneyCommaSeparator(+data?.data?.data?.amount)}` || (
                      <Skeleton animation="wave" />
                    )
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
              >
                <GKButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenRateCardModal(true)}
                  sx={{ background: "#FFF" }}
                >
                  Add-ons
                </GKButton>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenTopUp(true)}
                >
                  Add Balance
                </Button> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4} container>
          <WalletFullHistory />
        </Grid>
        <Grid item xs={8}>
          <WalletHistory />
        </Grid>
      </Grid>

      {openTopUp && (
        <WalletTopupModal
          open={openTopUp}
          setOpen={setOpenTopUp}
          refetch={refetch}
        />
      )}

      {openRateCardModal && (
        <RateCardModal
          open={openRateCardModal}
          setOpen={setOpenRateCardModal}
        />
      )}
    </DashboardLayout>
  );
};

export default Wallet;
