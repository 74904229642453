import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { SelectOption } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useEditClient = () => {
  const { id: clientID } = useParams();

  const [clientData, setClientData] = useState(null);

  // const [branchList, setBranchList] = useState<SelectOption[]>([]);

  const [familyList, setFamilyList] = useState<SelectOption[]>([]);

  const [basketList, setBasketList] = useState([]);

  const { isFetching: basketListFetching } = useFetchQuery({
    key: ["CUSTOM_AGREEMENT_MODAL_PORTFOLIOS_EDIT_CLIENT"],
    route: "/custom-agreement/model-portfolios/",
    success: (response: any) => {
      setBasketList(
        response?.data?.data?.map((dataItem: { name: string; id: number }) => {
          return {
            name: dataItem?.name,
            value: dataItem?.id,
          };
        })
      );
    },
  });

  const { isFetching: clientLoading, isFetched } = useFetchQuery({
    key: ["GET_CLIENT_BY_ID"],
    route: `/client/${clientID}/`,
    success: (data: any) => {
      setClientData(data?.data);
    },
  });

  // const { isFetching: branchStateLoading } = useFetchQuery({
  //   key: [QueryKeys.GET_ALL_BRANCHES],
  //   route: "/branch/has_manager/",
  //   success: (data: any) => {
  //     setBranchList(
  //       data?.data?.map((x: any) => ({
  //         name: x.name,
  //         value: x.id,
  //       }))
  //     );
  //   },
  // });

  const { isFetching: familyStateLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST],
    route: "/family/all/",
    success: (data: any) => {
      setFamilyList(
        data?.data?.map((x: any) => ({
          name: x.name,
          value: x.id,
        }))
      );
    },
  });

  const { mutate: handleClientUpdate, isLoading } = useMutation({
    mutationFn: (data) => axiosInstance.patch(`/client/${clientID}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.CLIENTS_LIST);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      branch: null,
      family: [],
      phone: "",
      countryCode: "",
      personalDetails: {
        id: null,
        email: "",
        name: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: "",
        address: "",
        city: null,
        state: null,
        country: null,
        pincode: null,
        income: 0,
        occupation: "",
        pan: "",
        aadhar: "",
        emergencyContact: "",
        emergencyContactNum: "",
      },
      portfolioCount: 0,
      clientCode: "",
      lead: null,
      bankAccounts: [],
      dematAccounts: [],
      nominees: [],
      familyName: "",
      basket: [],
    },
    values: clientData,
  });

  const onSubmit = (values: any) => handleClientUpdate(values);

  return {
    clientData,
    clientLoading,
    isFetched,
    // branchStateLoading,
    // branchList,
    familyStateLoading,
    familyList,
    isLoading,
    form,
    onSubmit,
    basketList,
    basketListFetching,
  };
};
