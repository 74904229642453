export const handleToFixed = (num: number) => {
  return typeof num === "number" ? num.toFixed(2) : "";
};

export const moneyCommaSeparator = (value: number) => {
  return value === Infinity || value === -Infinity || Number.isNaN(value)
    ? "0.00"
    : value?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
};

export const moneyCommaSeparatorPoint = (value: number) => {
  return value === Infinity || value === -Infinity || Number.isNaN(value)
    ? "0.00"
    : value?.toLocaleString("en-IN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
};

export const numberToCurrency = (value: number) => {
  const formattedValue = value?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return Math.abs(value) === 0
    ? formattedValue.replace("-", "")
    : formattedValue;
};

export const exponentialToDecimal = (value: number) => {
  if (value.toString().includes("e")) {
    return Number(value).toFixed(2);
  }
  return String(value);
};

export const convertZeros = (number: number) => `${number / 100000} lakhs.`;

export const convertZerosToCrs = (number: number) => `${number / 10000000} Cr.`;

export const handleZeros = (number: number, fixedUpto: number) => {
  if (Math.abs(number) >= 10000000) {
    const fixed = (number / 10000000).toFixed(fixedUpto);
    return `₹${fixed} Cr.`;
  }
  if (Math.abs(number) >= 100000) {
    const fixed = (number / 100000).toFixed(fixedUpto);
    return `₹${fixed} lakhs`;
  }

  return `₹${number.toFixed(2)}`;
};

export const handleInfinityOrNan = (value: number) => {
  if (value === Infinity || value === -Infinity || Number.isNaN(value)) {
    return 0;
  }
  return value;
};

export const handleFoatValues = ({
  num,
  toFixed,
}: {
  num: number;
  toFixed?: number;
}) => {
  return num % num < 0 ? num : num?.toFixed(toFixed || 2);
};
