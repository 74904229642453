import { reduce } from "lodash";

export const handleReturnFormData = ({
  obj,
  dirtyFields,
  multipleFilesField,
  isEditForm,
}: {
  obj: any;
  dirtyFields?: string[];
  multipleFilesField?: string;
  isEditForm: boolean;
}) => {
  const formData = new FormData();

  if (multipleFilesField) {
    obj[multipleFilesField]?.forEach((file: any, index: any) => {
      formData.append(`researchFiles[${index}]`, file);
    });
  }

  const handleReturnVal = (val: any) => {
    if (Array.isArray(val) && val?.length !== 0) {
      return JSON.stringify(val);
    }
    if (val?.size) {
      return JSON.stringify(val[0]);
    }
    if (!isEditForm && !val) {
      return null;
    }
    if (!isEditForm && val?.length === 0) {
      return JSON.stringify([]);
    }
    return val;
  };

  if (isEditForm) {
    Object.entries(obj)
      .map(([key, val]: any) => {
        return {
          name: key,
          value: handleReturnVal(val),
        };
      })
      ?.filter(
        (da) =>
          dirtyFields.includes(da?.name) &&
          !dirtyFields.includes(multipleFilesField)
      )
      ?.map((data) => formData.append(data.name, data?.value));
  } else {
    Object.entries(obj)
      .map(([key, val]: any) => {
        return {
          name: key,
          value: handleReturnVal(val),
        };
      })
      ?.map((data) => formData.append(data.name, data?.value));
  }

  return formData;
};

// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const hanldeReturnNaturalRows = ({
  options,
  values,
  selectedValueKey,
  optionsValueKey,
}: {
  options: any[];
  values: any[];
  selectedValueKey: string;
  optionsValueKey: string;
}) => {
  const selectedValues = values?.map((data) => data[selectedValueKey]);
  const newOptions = options?.filter(
    (da) => !selectedValues?.includes(da[optionsValueKey])
  );

  return newOptions;
};
// ===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###===###
export const handleReturnChangedObject = ({
  dirtyFields,
  values,
}: {
  dirtyFields: any;
  values: any;
}) => {
  const filterDirtyFields = Object.keys(dirtyFields).map((d: any) => {
    return { [d]: values[d] };
  });

  const convertToObject = reduce(
    filterDirtyFields,
    (result: any, item: any) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    },
    {}
  );
  console.log(
    "🚀 ~ filterDirtyFields ~ filterDirtyFields:",
    filterDirtyFields,
    convertToObject
  );

  return convertToObject;
};
