import { GridColDef } from "@mui/x-data-grid";
import queryClient from "client";
import dayjs from "dayjs";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import { handleCapitalize } from "./common";
import { handleFoatValues, moneyCommaSeparator } from "./MathFunction";

export const formatDate = (date: string) => {
  if (date) {
    return date.replace(/-/g, "/");
  }
  return date;
};

export const errorHandler = (error: any) => {
  if (error.status === 401) return "Please login again, session expired";
  return error.errors.message || "Something went wrong, please try again later";
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
};

export const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const matchDateTime = (date: string) => {
  if (date === null || date === "-") {
    return "N/A";
  }

  return dayjs(date).format("DD MMM YYYY  hh:mm a");
};

export const matchDate = (date: any) => {
  if (date === null || date === "-" || date === "") {
    return "N/A";
  }

  return dayjs(date).format("DD MMM YYYY");
};

export const matchTime = (date: any) => {
  if (date === null || date === "-") {
    return "N/A";
  }

  return dayjs(date).format("hh:mm a");
};

export const validationForPercentage = (number: number | string) => {
  const newNumber = Number(number);

  if (newNumber >= 0 && newNumber <= 100) {
    return newNumber;
  }

  return 100;
};

export const getRowIndex = (params: any) => {
  return params.api.getRowIndexRelativeToVisibleRows(params?.row?.id);
};

export const generateRandomNumberId = (length: number) => {
  let id = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return id;
};

export const extraColumnsField = (
  headerName: string,
  field: string,
  minWidth?: number,
  flex?: number
) => {
  return {
    disableColumnMenu: true,
    disableExport: true,
    headerName: headerName,
    field: field,
    minWidth: minWidth || 150,
    flex: flex,
    sortable: field !== "action",
  };
};

/** ======================================================================================================================== */
export const returnReviewHeaders = (text: string) => {
  switch (text.toLowerCase()) {
    case "cash":
      return [
        {
          ...extraColumnsField("Date", "date", 150, 1),
          valueFormatter: (params) => matchDate(params?.value),
        },
        {
          ...extraColumnsField("Portfolio Code", "portfolioCode", 150, 1),
        },
        {
          ...extraColumnsField("Portfolio Name", "portfolioName", 150, 1),
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
          valueFormatter: (params) => handleCapitalize(params?.value),
          renderCell: (data: any) => data?.value || " ",
        },
        {
          ...extraColumnsField(
            "Transaction Sub Type",
            "subTransactionType",
            150,
            1
          ),
          valueFormatter: (params) => handleCapitalize(params?.value),
          renderCell: (data: any) =>
            removeUnderscore(handleCapitalize(data?.value)) || " ",
        },
        {
          ...extraColumnsField("Amount", "billAmount", 150, 1),
          valueGetter: (data: any) => moneyCommaSeparator(data?.value),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Remarks", "remarks", 150, 1),
          renderCell: (data: any) => data?.value || " ",
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "portfolio":
      return [
        {
          ...extraColumnsField("Portfolio Name", "name", 150, 1),
        },
        {
          ...extraColumnsField("Client name", "clientName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.name || "-";
          },
        },
        {
          ...extraColumnsField("Portfolio Code", "portfolioId", 150, 1),
          valueGetter: (params) => {
            return params?.row?.portfolioId || "-";
          },
        },
        {
          ...extraColumnsField("Phone Number", "phoneNumber", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.phone || "-";
          },
        },
        {
          ...extraColumnsField("PAN", "pan", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.pan || "-";
          },
        },
        {
          ...extraColumnsField("Email", "email", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.email || "-";
          },
        },
        {
          ...extraColumnsField("Joining Date", "joiningDate", 150, 1),
        },
        {
          ...extraColumnsField("Billing Cycle", "billingCycle", 150, 1),
        },
        {
          ...extraColumnsField("AUM Fee", "aumFee", 150, 1),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Performance Fee", "performanceFee", 150, 1),
          align: "right",
          headerAlign: "right",
        },
        {
          ...extraColumnsField("Soft Hurdle", "softHurdle", 150, 1),
        },
        {
          ...extraColumnsField("Hard Hurdle", "hardHurdle", 150, 1),
        },
        {
          ...extraColumnsField("Date Of Birth", "dateOfBirth", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.dateOfBirth || "-";
          },
        },
        {
          ...extraColumnsField("Gender", "gender", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.gender || "-";
          },
        },
        {
          ...extraColumnsField("Marital Status", "maritalStatus", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.maritalStatus || "-";
          },
        },
        {
          ...extraColumnsField("Father's Name", "fatherName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.fatherName || "-";
          },
        },
        {
          ...extraColumnsField("Mother's Name", "motherName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.motherName || "-";
          },
        },
        {
          ...extraColumnsField("Family Name", "familyName", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.family[0]?.name || "-";
          },
        },
        {
          ...extraColumnsField("Nominee Added", "nominees", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.nominees?.length || "-";
          },
        },
        {
          ...extraColumnsField("Address", "address", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.address || "-";
          },
        },
        {
          ...extraColumnsField("Occupation", "occupation", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.occupation || "-";
          },
        },
        {
          ...extraColumnsField("Income", "income", 150, 1),
          valueGetter: (params) => {
            return params?.row?.client?.personalDetails?.income || "-";
          },
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "stock":
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("BSE / NSE Code", "bseCode", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.bseCode || "-"} / ${params.row.nseCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFoatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
    case "mutual":
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Company Code", "isinCode", 170, 1),
          editable: false,
          valueGetter: (params: any) => `${params.row.isinCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFoatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];

    default:
      return [
        {
          ...extraColumnsField("Date", "date", 170, 1),
          valueFormatter: (params) => matchDate(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Portfolio", "portfolioName", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.portfolioName} / ${params.row.portfolioCode}`,
        },
        {
          ...extraColumnsField("Company Name", "companyName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("BSE / NSE Code", "bseCode", 170, 1),
          editable: false,
          valueGetter: (params: any) =>
            `${params.row.bseCode || "-"} / ${params.row.nseCode || "-"}`,
        },
        {
          ...extraColumnsField("Transaction Type", "transactionType", 170, 1),
          editable: false,
          valueFormatter: (params) => handleCapitalize(params?.value),
        },
        {
          ...extraColumnsField("Quantity", "quantity", 170, 1),
          editable: false,
          renderCell: (params) => {
            return handleFoatValues({
              num: params?.row?.quantity,
              toFixed: 4,
            });
          },
        },
        {
          ...extraColumnsField("Price", "price", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Brokerage", "brokerage", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField(
            "Amount With Brokerage",
            "amountWithBrokerage",
            200,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("STT", "stt", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("GST", "gst", 170, 1),
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Transaction Charges",
            "transactionCharges",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField(
            "Sebi Turnover Fees",
            "sebiTurnoverFees",
            170,
            1
          ),
          editable: false,
          valueFormatter: (params: any) => moneyCommaSeparator(params?.value),
        },
        {
          ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.otherCharges || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
          align: "right",
          headerAlign: "right",
          valueGetter: (params) => params.row.billAmount || "0",
          valueFormatter: (params) => moneyCommaSeparator(params.value),
        },
        {
          ...extraColumnsField(
            "Contract Note Number",
            "contractNoteNum",
            170,
            1
          ),
          editable: false,
        },
        {
          ...extraColumnsField("Broker Name", "brokerName", 170, 1),
          editable: false,
        },
        {
          ...extraColumnsField("Error", "error", 150, 1),
          renderCell: (data: any) => data?.value || "-",
        },
      ] as GridColDef[];
  }
};

/** ======================================================================================================================== */
export const generateUUID = (size: number) => {
  const nums = Array.from({ length: 10 }, (_, i) =>
    String.fromCharCode("0".charCodeAt(0) + i)
  );
  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("a".charCodeAt(0) + i)
  );
  const chars = [...nums, ...alphabets];
  const rand = (length: number) => Math.floor(Math.random() * length);
  return Array.from({ length: size }, () => chars[rand(chars.length)]).join("");
};
/** ======================================================================================================================== */
export const refetchQuery = (query: string) => {
  return queryClient.refetchQueries([query]);
};

export const smallCaseBroker = [
  { name: "Zerodha", value: "kite" },
  { name: "5Paisa", value: "fivepaisa" },
  { name: "Alice Blue", value: "aliceblue" },
  { name: "Angel One", value: "angelbroking" },
  { name: "Dhan", value: "dhan" },
  { name: "Edelweiss", value: "edelweiss" },
  { name: "Fisdom", value: "fisdom" },
  { name: "FundzBazar", value: "Frame" },
  { name: "Groww", value: "groww" },
  { name: "HDFC Securities", value: "hdfc" },
  { name: "ICICIdirect", value: "icici" },
  { name: "IIFL", value: "iifl" },
  { name: "Kotak Securities", value: "kotak" },
  { name: "Motilal Oswal ", value: "motilal" },
  { name: "Trustline", value: "trustline" },
  { name: "Upstox", value: "upstox" },
  { name: "Axis Securities", value: "axis" },
];

export const handleOnboardingCalculation = ({
  obj,
  objKey,
}: {
  obj: any;
  objKey: string;
}) => {
  const numTrue = Object.values(obj[objKey] || {}).filter(
    (value) => value === true
  ).length;
  const percentTrue = (numTrue / Object.values(obj[objKey] || {}).length) * 100;

  const str = `${numTrue || 0}/${Object.values(obj[objKey] || {}).length || 0}`;
  return { value: percentTrue, str: str };
};

export const handleDownloadFile = ({
  name,
  link,
}: {
  link: string;
  name?: string;
}) => {
  const aLink = document.createElement("a");
  aLink.href = link;
  aLink.download = name || "Sample_File";
  aLink.click();
};
