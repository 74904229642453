import { useGridApiRef } from "@mui/x-data-grid";
import { GridApiPro } from "@mui/x-data-grid-pro";
import {
  CommonFilterState,
  initialCommonFilterState,
} from "interfaces/CommonFilter";
import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { columnsPersist } from "utils/columnsHide";

interface State {
  userData: any;
  userLoading: boolean;
  isFirstLogin: boolean;
  sidebarOpen: boolean;
  documentTitle: string;
  tab: {
    familyERMPageTabs: number;
    defaultPermissionTabs: number;
    modelPortfolioTabs: number;
    employeeERMTabs: number;
    portfolioSummaryTabs: number;
    portfolioUtilityTabs: number;
    settingsPageTabs: number;
    tabValueOfCRM: number;
    tabValueOfRevenue: number;
    tabOfPortfolioRevenue: number;
    tabValueDashboard: number;
    tabValueOfImport: number;
    tabValueOfReports: number;
    editFeePlanTab: number;
    complianceTabs: number;
    clientERMPageTabs: number;
    leadDetailsTabs: number;
    assetDetailsTabs: number;
  };
  noteFilter: number[];
  adviseRegisterFilter: number[];
  columnsPersist: any;
  portfolios: any[];
  companies: any[];
  portfolioGroupList: any[];
  clientList: any[];
  employeeList: any[];
  emailHostList: any[];
  commonFilter: CommonFilterState;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  selectedCountry: number;
  selectedState: number;
  settingTab: {
    type: string;
    name: string;
  };
}

interface Action {
  type: string;
  data?: any;
}

interface AppContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
  apiRef: any;
}

const initialState: State = {
  userData: {},
  userLoading: true,
  isFirstLogin: false,
  sidebarOpen: JSON.parse(localStorage.getItem("sidebar")),
  documentTitle: "Dashboard",
  tab: {
    familyERMPageTabs: 0,
    leadDetailsTabs: 0,
    defaultPermissionTabs: 0,
    modelPortfolioTabs: 0,
    employeeERMTabs: 0,
    portfolioSummaryTabs: 0,
    portfolioUtilityTabs: 0,
    settingsPageTabs: 0,
    tabValueOfCRM: 0,
    tabValueOfRevenue: 0,
    tabOfPortfolioRevenue: 0,
    tabValueDashboard: 0,
    tabValueOfImport: 0,
    tabValueOfReports: 0,
    editFeePlanTab: 0,
    complianceTabs: 0,
    clientERMPageTabs: 0,
    assetDetailsTabs: 0,
  },
  noteFilter: [],
  adviseRegisterFilter: [],
  columnsPersist: columnsPersist,
  portfolios: [],
  companies: [],
  portfolioGroupList: [],
  clientList: [],
  employeeList: [],
  emailHostList: [],
  countryList: [],
  stateList: [],
  cityList: [],
  selectedCountry: null,
  selectedState: null,
  commonFilter: initialCommonFilterState,
  settingTab: {
    type: "USER_PROFILE",
    name: "User Profile",
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CURRENT_USER":
      return { ...state, userData: action.data };
    case "USER_LOADING":
      return { ...state, userLoading: action.data };
    case "IS_FIRST_LOGIN":
      return { ...state, isFirstLogin: action.data };
    case "SIDEBAR_OPEN":
      return { ...state, sidebarOpen: action.data };
    case "DOCUMENT_TITLE":
      return { ...state, documentTitle: action.data };
    case "TAB":
      return { ...state, tab: action.data };
    case "NOTE_FILTER":
      return { ...state, noteFilter: action.data };
    case "ADVICE_REGISTER_FILTER":
      return { ...state, adviseRegisterFilter: action.data };
    case "COLUMN_PERSIST":
      return { ...state, columnsPersist: action.data };
    case "PORTFOLIOS":
      return { ...state, portfolios: action.data };
    case "COMPANIES":
      return { ...state, companies: action.data };
    case "PORTFOLIO_GROUP_LIST":
      return { ...state, portfolioGroupList: action.data };
    case "CLIENT_LIST":
      return { ...state, clientList: action.data };
    case "EMPLOYEE_LIST":
      return { ...state, employeeList: action.data };
    case "EMAIL_HOST_LIST":
      return { ...state, emailHostList: action.data };
    case "FILTER":
      return { ...state, commonFilter: action.data };
    case "COUNTRY_LIST":
      return { ...state, countryList: action.data };
    case "STATE_LIST":
      return { ...state, stateList: action.data };
    case "CITY_LIST":
      return { ...state, cityList: action.data };
    case "SELECTED_COUNTRY":
      return { ...state, selectedCountry: action.data };
    case "SELECTED_STATE":
      return { ...state, selectedState: action.data };
    case "SETTING_TAB":
      return { ...state, settingTab: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const apiRef = useGridApiRef<GridApiPro>();

  const value = useMemo(
    () => ({ state, dispatch, apiRef }),
    [state, dispatch, apiRef]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

export { AppContextProvider, useAppContext };
