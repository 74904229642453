import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC, memo } from "react";
import { handleToFixed } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

type BenchmarkKey =
  | "1M"
  | "3M"
  | "6M"
  | "1Y"
  | "thisFy"
  | "previousFy"
  | "sinceInception";

interface BenchmarkData {
  [key: string]: Partial<Record<BenchmarkKey, number>>;
}

interface BenchmarkTableProps {
  data: BenchmarkData;
}

const BenchmarkTable: FC<BenchmarkTableProps> = memo(({ data }) => {
  const { theme } = ThemeContainer();

  const columns: { key: BenchmarkKey; label: string }[] = [
    { key: "1M", label: "MTD" },
    { key: "3M", label: "QTD" },
    { key: "6M", label: "6 M" },
    { key: "1Y", label: "1 Y" },
    { key: "thisFy", label: "This FY" },
    { key: "previousFy", label: "Last FY" },
    { key: "sinceInception", label: "Since Inception" },
  ];

  const convertedData = React.useMemo(
    () =>
      Object.entries(data).map(([key, val]) => ({
        name: key,
        val,
      })),
    [data]
  );

  const stickyCellStyle: SxProps = {
    position: "sticky",
    left: 0,
    background: theme.palette.light.main,
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
    p: 1,
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={stickyCellStyle}> </TableCell>
            {columns.map((col) => (
              <TableCell key={col.key} sx={{ fontWeight: 600, fontSize: 14 }}>
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {convertedData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell sx={stickyCellStyle}>{row.name}</TableCell>
              {columns.map((col) => (
                <TableCell key={col.key} sx={{ p: 1 }}>
                  {handleToFixed(row.val[col.key] ?? 0)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={columns.length + 1} sx={{ fontSize: 12 }}>
              *Calculation of performances is done using absolute return for
              periods less than a year and internal rate of return (IRR) for
              periods greater than a year.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default BenchmarkTable;
