import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Popper } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ClickOutside from "components/ClickOutside";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import ApproveAutoContract from "./ApproveAutoContract";
import ApproveModal from "./ApproveModal";
import UploadPasswordModal from "./UploadPasswordModal";

interface Props {
  item: any;
  refetch?: any;
}

const UploadPopOverActions = ({ item, refetch }: Props) => {
  const { theme } = ThemeContainer();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const id = open ? "upload-popper" : undefined;

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [isUndoModalOpen, setIsUndoModalOpen] = useState(false);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const [approveModalData, setApproveModalData] = useState<any>([]);

  const [nullCompanies, setNullCompanies] = useState([]);

  const [nullCompaniesModal, setNullCompaniesModal] = useState(false);

  const [approveCompanyId, setApproveCompanyId] = useState(null);

  const navigate = useNavigate();

  const [uploadManagerBulkData, setUploadManagerBulkData] = useState<any>([]);

  /** ============================================================================================================================ */
  const { mutate: handleDownloadErrors, isLoading: errorLoading } = useMutation(
    {
      mutationFn: (fileId: any) =>
        axiosInstance?.get(
          `/transaction_upload/${fileId}/download_errors/?full=false`
        ),
      onSuccess: async (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${item?.fileName?.split(".csv")[0]}_error.csv`;
        link.click();
        link.remove();
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    }
  );
  /** ============================================================================================================================ */
  const { mutate: handleUndoTransaction } = useMutation({
    mutationFn: () =>
      axiosInstance?.post(`/transaction_upload/${item?.id}/undo_transactions/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });
  /** ============================================================================================================================ */
  const { mutate: handleContractApprove, isLoading: contractLoading } =
    useMutation({
      mutationFn: () =>
        axiosInstance?.get(`/transaction_upload/${item?.id}/company_mappings/`),
      onSuccess: async (data) => {
        const companies = Object.entries(data?.data)
          .filter(([, val]) => val === null)
          .map(([key]) => {
            return {
              advisorCompany: key,
              value: "",
              nseBseCode: "",
              company: null,
            };
          });
        if (companies?.length === 0) {
          setAnchorEl(null);
          await axiosInstance
            .post(`/transaction_upload/review/?id=${item?.id}`)
            .then((reviewResponse) => {
              setApproveModalData(reviewResponse?.data?.transactions);
              setIsApproveModalOpen(true);
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again."
              );
            });
        } else {
          setNullCompanies(companies);
          setNullCompaniesModal(true);
          setApproveCompanyId(item.id);
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });
  /** ============================================================================================================================ */
  const { mutate: handleArchiveTransaction } = useMutation({
    mutationFn: () =>
      axiosInstance?.post(`/transaction_upload/${item?.id}/add_archive/`),
    onSuccess: (response) => {
      refetch();
      setAnchorEl(null);
      setUploadManagerBulkData(
        uploadManagerBulkData.filter((upload: any) => upload.id !== item.id)
      );
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <>
      <IconButton onClick={handleClick}>
        <IoSettingsOutline size={20} />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: 150,
        }}
      >
        <ClickOutside
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.border.main}`,
              borderRadius: "10px",
              boxShadow: `0px 0px 5px 4px ${theme.palette.dark.main}11`,
              p: 1,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {item?.hasErrors && (
              <LoadingButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  handleDownloadErrors(item?.id);
                }}
                loading={errorLoading}
              >
                Error{" "}
              </LoadingButton>
            )}
            {item.source === "Manual Entry" && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                disabled
                onClick={() => {
                  navigate(`/app/edit-transaction/${item.transactionId}`);
                  setAnchorEl(null);
                }}
              >
                Edit
              </GKButton>
            )}
            {(item.status === "FINISHED" ||
              item.status === "PARTIALLY FINISHED") && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  setIsUndoModalOpen(true);
                  setAnchorEl(null);
                }}
              >
                Undo Import
              </GKButton>
            )}
            {item.source === "Auto Contract Note" &&
              !item?.invalidPassword &&
              item.status !== "FINISHED" && (
                <LoadingButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  fullWidth
                  onClick={() => handleContractApprove()}
                  loading={contractLoading}
                >
                  Approve
                </LoadingButton>
              )}
            {item?.invalidPassword && (
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                fullWidth
                onClick={() => {
                  setAnchorEl(null);
                  setShowPasswordModal(true);
                }}
              >
                Retry
              </GKButton>
            )}
            <GKButton
              variant="outlined"
              color="dark"
              size="small"
              fullWidth
              onClick={() => {
                setAnchorEl(null);
                setIsArchiveModalOpen(true);
              }}
            >
              Archive
            </GKButton>
          </Box>
        </ClickOutside>
      </Popper>

      {isArchiveModalOpen && (
        <ConfirmDeleteModal
          open={isArchiveModalOpen}
          setOpen={setIsArchiveModalOpen}
          title="Archive Transaction"
          messageContent="Are you sure you want to archive this item? This item will be removed from the upload manager, you can un-archive it from the archive manager."
          onDelete={() => handleArchiveTransaction()}
          customButtonText="Archive"
        />
      )}

      {isUndoModalOpen && (
        <ConfirmDeleteModal
          open={isUndoModalOpen}
          setOpen={setIsUndoModalOpen}
          title="Undo Transaction"
          messageContent="This action will undo the imports of all the transactions in the file, are you sure you want to continue?"
          onDelete={() => handleUndoTransaction()}
        />
      )}

      {!!isApproveModalOpen && (
        <ApproveAutoContract
          open={isApproveModalOpen}
          setOpen={setIsApproveModalOpen}
          recordID={item?.id}
          recordData={approveModalData}
        />
      )}

      {nullCompaniesModal && (
        <ApproveModal
          open={nullCompaniesModal}
          setOpen={setNullCompaniesModal}
          nullCompanies={nullCompanies}
          approveCompanyId={approveCompanyId}
        />
      )}
      {showPasswordModal && (
        <UploadPasswordModal
          open={showPasswordModal}
          setOpen={setShowPasswordModal}
          portfolio={item?.portfolio}
          broker={item?.broker}
          fileId={item?.id}
        />
      )}
    </>
  );
};

export default UploadPopOverActions;
