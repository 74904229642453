import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import * as yup from "yup";

type FormValues = {
  password?: string;
  confirmPassword?: string;
};

export const useChangePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    state: { phone, response: prevResponse },
  } = useLocation();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationRules = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])?[A-Za-z\d@$!%*?&]{8,}$/,
        "Password Should be a unique"
      ),
  });

  const form = useForm<FormValues>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationRules),
  });

  const onSubmit = async (values: FormValues) => {
    if (values.confirmPassword !== values.password) {
      toast.error("Passwords do not match");
    } else {
      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${prevResponse?.token}`;
        return config;
      });

      await axiosInstance
        .post(`${process.env.REACT_APP_BASE_URL}/user/forgot_password/`, {
          phone: phone,
          password: values?.password,
          confirm_password: values?.password,
        })
        .then((response) => {
          setIsSubmitting(false);
          toast.success(
            response?.data?.message || "Password changed successfully"
          );

          navigate("/login", { replace: true });
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          );
        });
    }
  };

  return {
    isSubmitting,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    form,
    onSubmit,
  };
};
