import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import axios from "axios";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { DashboardType } from "utils/common";
import { currentVersion } from "utils/constants";
import { object, string } from "yup";

export const useLogin = () => {
  const { dispatch } = useAppContext();

  const navigate = useNavigate();

  const { mutate: handleLogin, isLoading: isSubmitting } = useMutation({
    mutationFn: (values: any) =>
      axios.post(`${process.env.REACT_APP_BASE_URL}/login/`, {
        phone: values.phone,
        password: values.password,
        countryCode: values.countryCode,
      }),

    onSuccess: (response: any) => {
      axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Token ${response?.data?.token}`;
        config.headers[`x-gridkey-user-role`] = response?.data?.isEmployee
          ? `advisor`
          : `client`;
        return config;
      });
      // SETTING USERDATA IN STATE
      dispatch({
        type: "CURRENT_USER",
        data: {
          ...response.data,
          userType: response.data?.isEmployee
            ? DashboardType.ADVISOR
            : DashboardType.INVESTOR,
        },
      });

      // SETTING USERDATA IN LOCALSTAGE
      localStorage.setItem(
        "breadcumbs",
        JSON.stringify([{ name: "Dashboard", path: "/app/dashboard" }])
      );
      localStorage?.setItem("token", JSON.stringify(response?.data?.token));
      localStorage?.setItem(
        "role",
        response?.data?.isEmployee ? "advisor" : "client"
      );
      localStorage?.setItem("product-version", currentVersion);

      // CONDITIONS AFTER LOGIN
      if (response?.data?.isFirst) {
        navigate("/app/company-onboarding", { replace: true });
      } else if (!response?.data?.profileDetails?.profileComplete) {
        navigate("/app/company-onboarding", { replace: true });
      } else {
        navigate("/app/holdings");
      }
      window.location.reload();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const validationSchema = object({
    phone: string().required("Please enter phone number"),
    countryCode: string(),
    password: string().required("Please enter password"),
  });

  const form = useForm({
    defaultValues: {
      password: "",
      phone: "",
      countryCode: "+91",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: any) => handleLogin(values);

  return {
    isSubmitting,
    navigate,
    form,
    onSubmit,
  };
};
