import { LoadingButton } from "@mui/lab";
import { Card, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import DashboardLayout from "layouts/DashboardLayout";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import OnboardingCards from "./OnboardingCards";
import OnboardingClientAccordian from "./OnboardingClientAccordian";
import OnboardingOrgAccordian from "./OnboardingOrgAccordian";
import OnboardingPortfolioAccordian from "./OnboardingPortfolioAccordian";
import OnboardingRevenueAccordian from "./OnboardingRevenueAccordian";
import OnboardingTradesAccordian from "./OnboardingTradeAccordian";

const CompanyOnboarding = () => {
  const {
    state: { userData, userLoading },
  } = useAppContext();

  useDocumentTitle("Company Onboarding");

  const { mutate: handleMarkAllAsComplete, isLoading } = useMutation({
    mutationFn: () => axiosInstance.post(`/user-details/mark-as-complete/`),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
  });

  const { mutate: handleMarkAsComplete } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/user-details/mark-as-complete-single/`, {
        updateField: data,
      }),
    onSuccess: () => {
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <DashboardLayout title="Company Onboarding" loading={userLoading}>
      {userData?.id && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={28}>
              {`Namaste ${userData?.companyName}`}
            </Typography>
            <Typography fontWeight={400} fontSize={14} color={"#4B5565"}>
              Welcome to Gridkey: Simplify, Manage, and Grow Your Wealth
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item>
                  <GKButton
                    sx={{
                      border: `solid 1px`,
                      borderColor: paletteLightTheme.palette.border.main,
                    }}
                  >
                    <img
                      height={"auto"}
                      style={{
                        borderRadius: 16,
                      }}
                      alt=""
                      src="/Logos/comingSoon.svg"
                      draggable="false"
                    />
                  </GKButton>
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Typography fontWeight={600} fontSize={24}>
                    Your Gridkey Guide
                  </Typography>
                  <Typography fontWeight={400} fontSize={14} color={"#4B5565"}>
                    Watch this quick video to explore how Gridkey can transform
                    your wealth management experience.
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} my={2}>
            <Divider />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={10} container>
              <Grid item xs={12}>
                <Typography fontWeight={600} fontSize={20}>
                  Let’s get you started
                </Typography>
                <Typography fontWeight={400} fontSize={14} color={"#4B5565"}>
                  Get a headstart in setting up your Gridkey account by
                  following these simple steps.{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={1}
              >
                <LinearProgress
                  variant="buffer"
                  color="success"
                  value={
                    userData?.profileDetails?.profileCompletenessPercentage || 0
                  }
                  sx={{ height: 6, borderRadius: 2, width: "20%" }}
                />
                <Typography>
                  {userData?.profileDetails?.profileCompletenessPercentage || 0}
                  % completed
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {!userData?.profileDetails?.profileComplete &&
                userData?.profileDetails?.profileCompletenessPercentage >
                  80 && (
                  <LoadingButton
                    sx={{ textTransform: "none" }}
                    loading={isLoading}
                    variant="contained"
                    onClick={() => {
                      handleMarkAllAsComplete();
                    }}
                  >
                    Mark as Complete
                  </LoadingButton>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OnboardingOrgAccordian
              handleMarkAsComplete={handleMarkAsComplete}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardingClientAccordian
              handleMarkAsComplete={handleMarkAsComplete}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardingPortfolioAccordian
              handleMarkAsComplete={handleMarkAsComplete}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardingTradesAccordian
              handleMarkAsComplete={handleMarkAsComplete}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardingRevenueAccordian
              handleMarkAsComplete={handleMarkAsComplete}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardingCards />
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default CompanyOnboarding;
