import PortfolioList from "Pages/Portfolio/PortfolioList";
import { useParams } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";

const ClientList = () => {
  const { id } = useParams();

  useDocumentTitle("Client Portfolios");

  return (
    <PortfolioList
      path={`/client/${id}/portfolios/`}
      csvPath={`/client/${id}/portfolios`}
      csvName={"client-portfolio"}
    />
  );
};

export default ClientList;
