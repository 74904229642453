import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { useAppContext } from "AppContext";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import GKTextField from "components/GKTextField";
import useAddBranchModal from "hooks/useAddBranchModal";
import { Controller } from "react-hook-form";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  setOpen?: (value: boolean) => void;
  open: boolean;
  isEditModal: boolean;
  updateData: any;
}

const AddBranchModal = (props: Props) => {
  const { open, setOpen, updateData, isEditModal } = props;

  const { branchData, isSubmitting, isFetching, form, onSubmit } =
    useAddBranchModal({
      setOpen,
      updateData,
      isEditModal,
    });

  const {
    state: { cityList, countryList, stateList },
    dispatch,
  } = useAppContext();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={isEditModal ? "Update Branch" : "Add New Branch"}
      width={"50%"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            size="medium"
            form="branch-form"
          >
            {isEditModal ? "Update" : "Submit"}
          </LoadingButton>
        </Grid>
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)} id="branch-form">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Name is require",
                  },
                }}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Branch Name"
                    placeholder="Branch Name"
                    requiredField
                    error={Boolean(form?.formState?.errors?.name)}
                    helperText={
                      form?.formState?.errors?.name?.message as string
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="parent"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Reporting Branch is required",
                  },
                }}
                render={({ field }) => (
                  <GKSelect
                    {...field}
                    name="parent"
                    inputLabel="Reporting Branch"
                    placeholder="Reporting Branch"
                    requiredField
                    error={Boolean(form?.formState?.errors.parent)}
                    helperText={
                      form?.formState?.errors.parent?.message as string
                    }
                    disabled={isFetching}
                  >
                    <MenuItem style={{ display: "none" }} value="">
                      Select reporting branch
                    </MenuItem>
                    {branchData?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data?.name}
                      </MenuItem>
                    ))}
                  </GKSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="address"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Address is required",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Address"
                    placeholder="Address"
                    requiredField
                    name="address"
                    error={Boolean(form?.formState?.errors.address)}
                    helperText={
                      form?.formState?.errors.address?.message as string
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="country"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="Country"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select Country" />
                    )}
                    options={countryList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      form.setValue("city", "");
                      form.setValue("state", "");
                      dispatch({
                        type: "SELECTED_COUNTRY",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="state"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="State"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select State" />
                    )}
                    options={stateList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      form.setValue("city", "");
                      dispatch({
                        type: "SELECTED_STATE",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="city"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    inputLabel="City"
                    renderInput={(er) => (
                      <TextField {...er} placeholder="Select City" />
                    )}
                    options={cityList || []}
                    getOptionLabel={(e) => e.name || e}
                    onChange={(e, val) => {
                      field.onChange(val.name);
                      dispatch({
                        type: "SELECTED_CITY",
                        data: val.id,
                      });
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="pincode"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Branch Pin code"
                    placeholder="Branch Pin code"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AddBranchModal;
