import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: any;
  editQuoteValue: any;
}

const QuoteModal = (props: Props) => {
  const { open, setOpen, editQuoteValue } = props;

  const { theme } = ThemeContainer();

  const CHARACTER_LIMIT = 100;

  const { mutate: createQuote } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/report_quotes/", data),
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("QUOTE_PMS_REPORT");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: updateQuote } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/report_quotes/${editQuoteValue?.id}/?role=advisor`,
        data
      ),
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("QUOTE_PMS_REPORT");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: { quote: editQuoteValue?.id ? editQuoteValue?.value : "" },
  });

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={editQuoteValue?.id ? "Update Quote" : "Add New Quote"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="create-lead-form"
            size="medium"
          >
            {editQuoteValue?.id ? "Update" : "Save"}
          </GKButton>
        </Grid>
      }
    >
      <form
        id="create-lead-form"
        onSubmit={form.handleSubmit((values) =>
          editQuoteValue?.id ? updateQuote(values) : createQuote(values)
        )}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="quote"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Quote"
                    inputProps={{ maxLength: CHARACTER_LIMIT }}
                    helperText={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: theme.palette.dark.main,
                          marginTop: 2,
                        }}
                      >
                        {form.getValues("quote").length}/{CHARACTER_LIMIT}
                      </span>
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default QuoteModal;
