import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  HoldingDataInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  portfolioDetailData?: PortfolioDetailsInterface;
  holdingSummaryDetails?: HoldingDataInterface;
}

const PortfolioPreviewCardDetails = (props: Props) => {
  const { portfolioDetailData, holdingSummaryDetails } = props;

  const { theme } = ThemeContainer();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "20%",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {portfolioDetailData?.clientName}
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color={"#697586"}>
                    <Typography fontSize={12} color={"#697586"}>
                      {portfolioDetailData?.name} -
                      {portfolioDetailData?.portfolioId}
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "20%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Portfolio Value
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {numberToCurrency(
                      holdingSummaryDetails?.portfolioValue || 0
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "20%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Current Value
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {numberToCurrency(holdingSummaryDetails?.currentValue || 0)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "20%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Total Gain
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {numberToCurrency(
                      holdingSummaryDetails?.realisedGain +
                        holdingSummaryDetails?.unrealisedGain +
                        holdingSummaryDetails?.otherGains || 0
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: `solid 1px ${theme.palette.grey[300]}`,
                    borderBottom: "none",
                    padding: 16,
                    width: "20%",
                  }}
                >
                  <Typography fontSize={12} color={"#697586"}>
                    Ledger Balance
                  </Typography>
                  <Typography fontWeight={500} fontSize={14}>
                    {numberToCurrency(
                      holdingSummaryDetails?.availableCash || 0
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PortfolioPreviewCardDetails;
