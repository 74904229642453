/* eslint-disable react/no-danger */
import { Grid } from "@mui/material";
import GKModal from "components/GKModal";
import NoData from "components/NoData";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  content: string;
}

const RationaleModal = (props: Props) => {
  const { open, setOpen, content } = props;

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={"Rationale"}>
      <Grid container>
        {content ? (
          <Grid item xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <NoData />
          </Grid>
        )}
      </Grid>
    </GKModal>
  );
};

export default RationaleModal;
