/* eslint-disable no-unsafe-optional-chaining */
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import DragDrop from "components/DragDrop";
import GKButton from "components/GKButton";
import GKSearchSelect from "components/GKSearchSelect";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { sortBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import {
  MdOutlineFileDownload,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { RiRadioButtonFill } from "react-icons/ri";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import UploadSheetModal from "./UploadSheetModal";

interface Props {
  formik?: any;
  favoriteUploadSamples?: any;
  setShowFavoriteModal?: any;
  menuOptions?: any;
  showFavoriteModal?: any;
  favoriteRefetch?: any;
  handleDeleteFav?: any;
  handleAddToFav?: any;
  facLoading?: any;
  id?: any;
  setAllFiles?: any;
  handleDownloadSampleFiles?: any;
  selectedId: any;
  setSelectedId: any;
  templatesTabs: any;
  portfolioFormik: any;
  companyFormik: any;
  setTemplatesTabs: any;
}

const StyledTabs = styled(Tabs)(() => {
  return {
    color: "#6B7280",
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,
    padding: 1,
    background: "#F3F4F6",
    "& .MuiButtonBase-root.MuiTab-root": {
      padding: "8px !important",
      fontSize: {
        xs: 10,
        lg: 18,
      },
    },
    "& .MuiTabs-flexContainer ": {
      gap: "0px !important",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#000",
      background: "#fff",
      fontWeight: 400,
      padding: "8px !important",
      borderRadius: 8,
    },
  };
});

const UploadStepOne = ({
  formik,
  favoriteUploadSamples,
  handleDeleteFav,
  id,
  setAllFiles,
  handleDownloadSampleFiles,
  selectedId,
  setSelectedId,
  templatesTabs,
  setTemplatesTabs,
  portfolioFormik,
  companyFormik,
}: Props) => {
  const { theme } = ThemeContainer();

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [customOpenModal, setCustomOpenModal] = useState(false);

  const { data: menuOptions } = useFetchQuery({
    key: [QueryKeys.UPLOAD_MANAGER_SELECT_OPTIONS],
    route: `/transaction_upload/contract_note/`,
  });

  const { mutate: handleChangesFav } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/transaction_upload/${data.id}/make_fav/`),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
    onSuccess: (response: any) => {
      refetchQuery(QueryKeys.UPLOAD_MANAGER_FAVORITE_SAMPLES);
      toast.success(
        response?.data?.message || "Something went wrong, please try again."
      );
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} id={id} className="upload-step-2">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <StyledTabs
              value={templatesTabs}
              onChange={(e, val) => {
                setTemplatesTabs(val);
                formik.resetForm();
                setAllFiles([]);
                setSelectedId(null);
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab label="All  Template" value={0} disableRipple />
              <Tab label="Favourite" value={1} disableRipple />
              <Tab label="Broker Template" value={2} disableRipple />
            </StyledTabs>
          </Grid>
          <Show.When isTrue={templatesTabs === 2}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <GKSearchSelect
                  inputLabel="Select Broker"
                  getOptionLabel={(option) =>
                    removeUnderscore(option?.templateName) || option
                  }
                  options={menuOptions?.data || []}
                  onChange={(event, val) => {
                    const fileType =
                      menuOptions?.data?.find(
                        (item: any) => item?.templateName === val?.templateName
                      )?.options?.length === 1
                        ? menuOptions?.data?.find(
                            (item: any) =>
                              item?.templateName === val?.templateName
                          )?.options[0]?.fileType
                        : "";

                    const fileVersion =
                      menuOptions?.data?.find(
                        (item: any) => item?.templateName === val?.templateName
                      )?.options[0]?.versions?.length === 1
                        ? menuOptions?.data?.find(
                            (item: any) =>
                              item?.templateName === val?.templateName
                          )?.options[0]?.versions[0]?.value
                        : "";
                    const fileObject =
                      menuOptions?.data?.find(
                        (item: any) => item?.templateName === val?.templateName
                      )?.options[0]?.versions?.length === 1
                        ? menuOptions?.data?.find(
                            (item: any) =>
                              item?.templateName === val?.templateName
                          )?.options[0]?.versions[0]
                        : "";

                    setTimeout(() => {
                      formik?.setFieldValue("favoriteType", "");
                      formik.setFieldValue("fileBrokerType", val?.templateName);
                      formik.setFieldValue("fileUploadType", "Stock");
                      formik.setFieldValue("fileType", fileType);
                      formik.setFieldValue("fileVersion", fileVersion);
                      formik.setFieldValue("fileObject", fileObject);
                      portfolioFormik.setFieldValue("fileObject", fileObject);
                      companyFormik.setFieldValue("fileObject", fileObject);
                    }, 100);
                  }}
                  value={formik?.values.fileBrokerType}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Broker" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mb: 1,
                  }}
                >
                  Select File Type
                </Typography>

                {menuOptions?.data
                  ?.find(
                    (item: any) =>
                      item?.templateName === formik.values?.fileBrokerType
                  )
                  ?.options?.map((fileTypeItem: any) => {
                    return (
                      <Chip
                        label={fileTypeItem?.fileType}
                        sx={{
                          mr: 1,
                          mb: 1,
                          borderRadius: 2,
                        }}
                        variant={
                          formik?.values.fileType === fileTypeItem?.fileType
                            ? "filled"
                            : "outlined"
                        }
                        color={
                          formik?.values.fileType === fileTypeItem?.fileType
                            ? "primary"
                            : "default"
                        }
                        onClick={() => {
                          formik?.setFieldValue("favoriteType", "");
                          formik.setFieldValue(
                            "fileType",
                            fileTypeItem?.fileType
                          );
                          const fileVersion = menuOptions?.data
                            ?.find(
                              (item: any) =>
                                item?.templateName ===
                                formik?.values.fileBrokerType
                            )
                            ?.options?.find(
                              (item: any) =>
                                item?.fileType === fileTypeItem?.fileType
                            )?.versions[0]?.value;
                          const versionSampleAvailable = menuOptions?.data
                            ?.find(
                              (item: any) =>
                                item?.templateName ===
                                formik?.values.fileBrokerType
                            )
                            ?.options?.find(
                              (item: any) =>
                                item?.fileType === fileTypeItem?.fileType
                            )?.versions[0];
                          formik.setFieldValue(
                            "fileObject",
                            versionSampleAvailable
                          );
                          companyFormik.setFieldValue(
                            "fileObject",
                            versionSampleAvailable
                          );
                          portfolioFormik.setFieldValue(
                            "fileObject",
                            versionSampleAvailable
                          );
                          formik.setFieldValue("fileVersion", fileVersion);
                          formik.setFieldValue(
                            "sampleAvailable",
                            versionSampleAvailable?.sampleAvailable
                          );
                        }}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </Show.When>
          <Show.When isTrue={templatesTabs !== 2}>
            <Grid container item xs={6} spacing={1}>
              <Grid item xs={12} minHeight={250}>
                <Grid
                  item
                  xs={12}
                  container
                  maxHeight={250}
                  overflow={"auto"}
                  className="hidden"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  px={1}
                >
                  {favoriteUploadSamples?.data &&
                  [...favoriteUploadSamples?.data?.favUpload.flat()]?.filter(
                    (data) => (templatesTabs === 0 ? data : data?.isFav)
                  )?.length === 0 ? (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      gap={1}
                      p={2}
                    >
                      <img
                        alt=""
                        height={130}
                        src="/LandingPage/noDataImg.svg"
                        width={"auto"}
                      />
                      <Typography fontWeight={600} fontSize={18} color={"#000"}>
                        {"No Data Found."}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        color={"#9AA4B2"}
                      >
                        {"Sorry we don't have any related data to show."}
                      </Typography>
                    </Box>
                  ) : (
                    favoriteUploadSamples?.data &&
                    sortBy(
                      [
                        ...favoriteUploadSamples?.data?.favUpload.flat(),
                      ]?.filter((data) =>
                        templatesTabs === 0 ? data : data?.isFav
                      ),
                      [
                        (o) => {
                          return o.title;
                        },
                      ]
                    )?.map((dataItem: any, index) => {
                      const totalLength =
                        favoriteUploadSamples?.data &&
                        [
                          ...favoriteUploadSamples?.data?.favUpload.flat(),
                        ]?.filter((data) =>
                          templatesTabs === 0 ? data : data?.isFav
                        )?.length;

                      return (
                        <Grid
                          item
                          xs={12}
                          key={index}
                          display={"flex"}
                          alignItems={"center"}
                          border={`solid 1px ${theme.palette.grey[400]}`}
                          borderBottom={
                            index + 1 === totalLength
                              ? `solid 1px ${theme.palette.grey[400]}`
                              : `none`
                          }
                          style={{
                            borderTopRightRadius: index === 0 ? 8 : 0,
                            borderTopLeftRadius: index === 0 ? 8 : 0,
                            borderBottomRightRadius:
                              index + 1 === totalLength ? 8 : 0,
                            borderBottomLeftRadius:
                              index + 1 === totalLength ? 8 : 0,
                          }}
                          pr={0.3}
                        >
                          <GKButton
                            fullWidth
                            size="medium"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: theme.palette.dark.main,
                            }}
                            onClick={() => {
                              setSelectedId(dataItem?.id);
                              setUploadError(null);
                              if (dataItem?.type === "cash") {
                                formik.resetForm();
                                formik?.setFieldValue("fileUploadType", "Cash");
                                formik?.setFieldValue("fileType", "cash");
                              } else if (dataItem?.type === "portfolio") {
                                formik.resetForm();
                                formik?.setFieldValue(
                                  "fileUploadType",
                                  "Portfolio"
                                );
                              } else if (
                                [
                                  ...favoriteUploadSamples?.data?.favUpload.flat(),
                                ]?.find((data) => data?.id === dataItem?.id)
                                  ?.type === "temp"
                              ) {
                                formik.resetForm();
                                formik?.setFieldValue(
                                  "sheetTypeName",
                                  dataItem?.title
                                );
                                formik?.setFieldValue(
                                  "sheetTypeId",
                                  dataItem?.id
                                );
                              } else {
                                formik.resetForm();
                                formik?.setFieldValue(
                                  "favoriteType",
                                  dataItem?.id
                                );
                                formik?.setFieldValue(
                                  "fileType",
                                  [
                                    ...favoriteUploadSamples?.data?.favUpload?.flat(),
                                  ]?.find(
                                    (items: any) => items?.id === dataItem?.id
                                  )?.fileType
                                );
                                formik?.setFieldValue(
                                  "fileUploadType",
                                  [
                                    ...favoriteUploadSamples?.data?.favUpload?.flat(),
                                  ]?.find(
                                    (items: any) => items?.id === dataItem?.id
                                  )?.uploadFile
                                );
                                formik?.setFieldValue(
                                  "fileBrokerType",
                                  [
                                    ...favoriteUploadSamples?.data?.favUpload?.flat(),
                                  ]?.find(
                                    (items: any) => items?.id === dataItem?.id
                                  )?.broker
                                );
                                formik?.setFieldValue(
                                  "fileVersion",
                                  [
                                    ...favoriteUploadSamples?.data?.favUpload?.flat(),
                                  ]?.find(
                                    (items: any) => items?.id === dataItem?.id
                                  )?.version
                                );
                                formik?.setFieldValue(
                                  "sampleAvailable",
                                  [
                                    ...favoriteUploadSamples?.data?.favUpload?.flat(),
                                  ]?.find(
                                    (items: any) => items?.id === dataItem?.id
                                  )?.sampleAvailable
                                );
                              }
                              formik.setFieldValue("fileObject", dataItem);
                              portfolioFormik.setFieldValue(
                                "fileObject",
                                dataItem
                              );
                              companyFormik.setFieldValue(
                                "fileObject",
                                dataItem
                              );
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: theme.palette.dark.main,
                                gap: 8,
                              }}
                            >
                              {dataItem?.id !== selectedId ? (
                                <MdOutlineRadioButtonUnchecked
                                  size={18}
                                  color={"#D0D5DD"}
                                />
                              ) : (
                                <RiRadioButtonFill
                                  size={18}
                                  color={theme.palette.primary.main}
                                />
                              )}
                              <Typography>{dataItem?.title}</Typography>
                            </span>
                            <span>
                              <Typography
                                fontWeight={600}
                                fontSize={12}
                                color={
                                  dataItem?.fileType
                                    ?.toLowerCase()
                                    ?.includes("pdf")
                                    ? theme.palette.error.main
                                    : theme.palette.success.main
                                }
                                display={"flex"}
                              >
                                <span
                                  style={{
                                    color: theme.palette.dark.main,
                                    display:
                                      dataItem?.type === "permanent"
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  {dataItem?.broker?.replace(/_/g, " ")}
                                </span>
                                <Divider
                                  orientation="vertical"
                                  style={{
                                    height: "18px",
                                    margin: "0 4px",
                                  }}
                                />
                                {dataItem?.fileType?.replace(/_/g, " ")}
                              </Typography>
                            </span>
                          </GKButton>
                          <IconButton
                            onClick={() => {
                              handleChangesFav({
                                id: dataItem?.id,
                              });
                            }}
                            style={{
                              height: 26,
                              width: 26,
                            }}
                          >
                            {dataItem?.isFav ? (
                              <AiFillStar color={theme.palette.primary.main} />
                            ) : (
                              <AiOutlineStar />
                            )}
                          </IconButton>
                          {dataItem?.type !== "permanent" && (
                            <IconButton
                              className="outline"
                              onClick={() =>
                                handleDeleteFav({
                                  id: dataItem?.id,
                                  type: dataItem?.type,
                                })
                              }
                              color="error"
                              style={{
                                height: 26,
                                width: 26,
                              }}
                            >
                              <FiTrash2 />
                            </IconButton>
                          )}
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Show.When>
          <Grid
            item
            xs={templatesTabs === 2 ? 12 : 6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
            maxHeight={250}
            px={1}
            overflow={"auto"}
          >
            <DragDrop
              uploadError={uploadError}
              setUploadError={setUploadError}
              showSupportedFormat={false}
              acceptType={".csv, .pdf,.xlsx,.html,.htm,.xls"}
              value={formik?.values?.file}
              supportName="CSV or PDF or XLSX"
              onChange={(event) => {
                formik.setFieldValue("file", event);
                setAllFiles(
                  event?.map((data) => {
                    return {
                      file: data,
                      isFinished: false,
                    };
                  })
                );
              }}
              multiple
            />
            <Show.When isTrue={formik.values.sampleAvailable}>
              <GKButton
                sx={{
                  p: 0,
                  color: theme.palette.grey[500],
                  display: "flex",
                  alignItems: "center",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                onClick={() => {
                  handleDownloadSampleFiles(formik);
                }}
                startIcon={<MdOutlineFileDownload size={14} />}
              >
                Download Sample File
              </GKButton>
            </Show.When>
          </Grid>
        </Grid>
      </form>

      {customOpenModal && (
        <UploadSheetModal
          open={customOpenModal}
          setOpen={setCustomOpenModal}
          formik={formik}
          setAllFiles={setAllFiles}
        />
      )}
    </>
  );
};

export default UploadStepOne;
