import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import { FeeCollection, FeePlans } from "Pages";
import InvoiceTab from "Pages/RevenueManagement/Invoice/InvoiceTab";
import PerformaInvoiceTab from "Pages/RevenueManagement/Invoice/PerformaInvoiceTab";
import RevenueFeeCalculations from "Pages/RevenueManagement/RevenueManager/RevenueFeeCalculation";
import RevenuePortfolioTab from "Pages/RevenueManagement/RevenueManager/RevenuePortfolioTab";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";

const RevenueMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const tabs = [
    { label: "Fee Plan Setup", component: FeePlans },
    { label: "Revenue Manager", component: RevenuePortfolioTab },
    { label: "Fee Calculation", component: RevenueFeeCalculations },
    { label: "Proforma", component: PerformaInvoiceTab },
    { label: "Invoice", component: InvoiceTab },
    { label: "Fee Collection", component: FeeCollection },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: "#fff",
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfRevenue: val },
                });
              }}
              value={tabValue?.tabValueOfRevenue}
              variant="scrollable"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableTouchRipple />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfRevenue === index}
            >
              <AnimationComponent>
                <tab.component />
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default RevenueMenu;
