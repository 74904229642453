import { Divider, FormControl, Grid, IconButton } from "@mui/material";
import GKTextField from "components/GKTextField";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { MdAdd, MdDeleteOutline } from "react-icons/md";

const ClientDemat = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "dematAccounts",
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.map((demat: any, index: number) => (
          <Grid item container xs={12} spacing={1} my={1} key={index}>
            <Grid item xs={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.brokerName`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Demat Broker Name" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.dematId`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Demat Id" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.uniqClientCode`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel="UCC"
                      placeholder="Like UCC of Demat A/c"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.depositary`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Depository"
                      placeholder="Like NSDL, CDSL"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.6} textAlign={"right"} alignSelf={"center"} mt={2}>
              <IconButton
                color="error"
                disabled={fields.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <MdDeleteOutline />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                brokerName: "",
                dematId: "",
                uniqClientCode: "",
                depositary: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid>
    </Grid>
  );
};

export default ClientDemat;
