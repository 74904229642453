import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";
import { TableFiltering } from "utils/TableFilters";
import useDocumentTitle from "utils/useDocumentTitle";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 200, 1),
    renderCell: (params) => {
      return (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioId}
          </Typography>
        </Grid>
      );
    },
  },
  {
    ...extraColumnsField("Particulars", "transactionType", 200),
    renderCell: (params) => {
      return (
        <Typography
          fontWeight={600}
          style={{
            width: "fit-content",
            background: `${returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            )}33`,
            color: returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            ),
            padding: "4px 8px",
            borderRadius: 6,
          }}
          textTransform={"capitalize"}
        >
          {params?.row?.transactionType?.toLowerCase() === "sell" ||
          params?.row?.transactionType?.toLowerCase() === "buy"
            ? `BILLAMOUNT - ${params?.row?.transactionType}`
            : params?.row?.transactionType}
          &nbsp;
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
              ? `/ ${removeUnderscore(
                  params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                    ?.addedField
                )}`
              : ""}
          </span>
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Debit", "debit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.debit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Credit", "credit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.credit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
];

const BankLedger = () => {
  useDocumentTitle("Ledger");

  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.bankLedger?.take,
    skip: commonFilter?.bankLedger?.skip,
    sort: JSON.stringify(
      commonFilter?.bankLedger?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.bankLedger?.search || "",
    filter: JSON.stringify(commonFilter?.bankLedger?.customFilter),
  };

  const { data, isFetching } = useFetchQuery({
    route: "/ledger/",
    key: [QueryKeys.BANK_LEDGER_LIST, requestBody],
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="bankLedger"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={"/ledger"}
        name={"bankLedgerList"}
        filter={JSON.stringify(commonFilter?.bankLedger?.customFilter)}
      />
    );
  };

  return (
    <GKDataGrid
      loading={isFetching}
      columns={columns || []}
      rows={data?.data?.data || []}
      CustomPagination={!isFetching && cusPagination}
      filterButton={false}
      exportCsv={exportCsv}
      name="bankLedger"
      tableName="bank_ledger_list"
      maxHeight={"calc(100vh - 309px)"}
      headerFilter={<TableFiltering name="bankLedger" />}
    />
  );
};

export default BankLedger;
