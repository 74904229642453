/* eslint-disable prefer-destructuring */
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import GKTextArea from "components/GKTextBar";
import NoData from "components/NoData";
import { Show } from "components/Show";
import ViewPdf from "components/ViewPdf";
import dayjs from "dayjs";
import useReport from "hooks/useReport";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { BsArrowRepeat } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { ThemeContainer } from "utils/Theme";
import {
  PMS_REPORT_BANK_STATEMENT,
  PMS_REPORT_EXTRA_OPTIONS_FIELD,
  PMS_REPORT_HOLDING_STATEMENT,
  PMS_REPORT_OPTIONAL_FIELD,
  PMS_REPORT_OPTION_TO_INCLUDE,
} from "utils/constants";
import TemplateModal from "./TemplateModal";

interface Props {
  selectedIndex: {
    index: number;
    loading: boolean;
  };
  setSelectedIndex: any;
  quoteList: any;
  benchmarkList: any;
  benchmarkLoading: boolean;
  setEditQuoteValue: any;
  setOpenQuoteModal: (openQuoteModal: boolean) => void;
}

enum PeriodOptions {
  inception = "inception",
  lastFY = "lastFY",
  custom = "custom",
  thisFY = "thisFY",
}

const GenerateReportForm = (props: Props) => {
  const form = useFormContext();
  const { handleDeleteQuote, generateQuote, portfolioList, isInitialLoading } =
    useReport();
  const { theme } = ThemeContainer();

  const {
    state: { companies },
  } = useAppContext();

  // const [isPreviewHovered, setIsPreviewHovered] = useState(false);
  const [openPdfView, setOpenPdfView] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const {
    selectedIndex,
    setSelectedIndex,
    quoteList,
    benchmarkList,
    benchmarkLoading,
    setEditQuoteValue,
    setOpenQuoteModal,
  } = props;

  const { setValue, getValues } = form;

  const handleClickPeriods = (val: string) => {
    setValue("currentOption", val);

    if (val === PeriodOptions.inception) {
      setValue("from_date", "1990-01-01");
      setValue("to_date", new Date().toISOString().split("T")[0]);
    }

    if (val === PeriodOptions.lastFY) {
      let fromDate = "";
      let toDate = "";
      if (dayjs().date() >= 3) {
        fromDate = new Date(new Date().getFullYear() - 1, 3, 2)
          .toISOString()
          .split("T")[0];
        toDate = new Date(new Date().getFullYear() - 0, 3, 1)
          .toISOString()
          .split("T")[0];
      } else {
        fromDate = new Date(new Date().getFullYear() - 2, 3, 2)
          .toISOString()
          .split("T")[0];
        toDate = new Date(new Date().getFullYear() - 1, 3, 1)
          .toISOString()
          .split("T")[0];
      }

      setValue("from_date", fromDate);
      setValue("to_date", toDate);
    }

    if (val === PeriodOptions.thisFY) {
      let fromDate = "";
      if (dayjs().date() >= 3) {
        fromDate = new Date(new Date().getFullYear() - 0, 3, 2)
          .toISOString()
          .split("T")[0];
      } else {
        fromDate = new Date(new Date().getFullYear() - 1, 3, 2)
          .toISOString()
          .split("T")[0];
      }

      setValue("from_date", fromDate);
      setValue("to_date", new Date().toISOString().split("T")[0]);
    }

    if (val === PeriodOptions.custom) {
      setValue("from_date", "");
      setValue("to_date", "");
    }
  };

  const handleClickTimePeriods = (option: any) => {
    const updatedOptions = getValues("availableTimePeriods").includes(option)
      ? getValues("availableTimePeriods").filter((item: any) => item !== option)
      : [...getValues("availableTimePeriods"), option];

    setValue("availableTimePeriods", updatedOptions);
  };

  const notAccessThisSectionReport =
    getValues("selectedReported")?.reportName !== "custom_report" &&
    getValues("selectedReported")?.reportName !==
      "portfolio_performance_report" &&
    getValues("selectedReported")?.reportName !==
      "portfolio_summary_detailed_report" &&
    getValues("selectedReported")?.reportName !== "xirr_report" &&
    getValues("selectedReported")?.reportName !==
      "unrealized_capital_gain_summary_report" &&
    getValues("selectedReported")?.reportName !==
      "unrealized_capital_gain_detailed_report" &&
    getValues("selectedReported")?.reportName !==
      "historical_valuation_report" &&
    getValues("selectedReported")?.reportName !== "stock_wise_holdings_report";

  return (
    <>
      <Show.When isTrue={isEmpty(getValues("selectedReported"))}>
        <NoData
          customImg={<img src="/LandingPage/noReport.svg" alt="" />}
          text="Report Preview"
          subText="Please select the report type for see the preview of report"
        />
      </Show.When>
      <Show.When isTrue={!isEmpty(getValues("selectedReported"))}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography
              fontWeight={600}
              fontSize={18}
              textTransform={"capitalize"}
            >
              {getValues("selectedReported")?.name}
            </Typography>
            <Typography>
              {getValues("selectedReported")?.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              sx={{
                background: "#fff",
                padding: "2px",
                ":hover": {
                  background: "#00000033",
                },
              }}
              onClick={() => setOpenPdfView(true)}
              onMouseEnter={() => setIsPreviewHovered(true)}
              onMouseLeave={() => setIsPreviewHovered(false)}
            >
              <img
                src={
                  isPreviewHovered
                    ? "/LandingPage/previewHover.svg"
                    : "/LandingPage/previewImg.svg"
                }
                alt=""
              />
            </Button>
          </Grid> */}

          {getValues("selectedReported")?.reportName !==
            "stock_wise_holdings_report" &&
            (getValues("selectedReported")?.reportName !== "custom_report" ||
              getValues("selectedReported")?.reportType === "template") && (
              <Grid item xs={12}>
                <Controller
                  name="portfolio"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKSearchSelect
                      {...field}
                      disabled={selectedIndex.loading}
                      inputLabel="Portfolio, Client or Family"
                      multiple
                      loading={isInitialLoading}
                      onChange={(event, val) => {
                        field.onChange(val);
                      }}
                      groupBy={(option: any) => option.type?.toUpperCase()}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography fontWeight={600}>
                              {option.clientName}
                            </Typography>
                            <Typography>{option.name}</Typography>
                          </li>
                        );
                      }}
                      options={
                        portfolioList?.data?.map((dataItem: any) => {
                          return {
                            ...dataItem,
                            name: `${dataItem.name} - ${dataItem.code}`,
                            value: dataItem.id,
                          };
                        }) || []
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Portfolio, Client or Family"
                          error={!!errors?.portfolio}
                          helperText={errors?.portfolio?.message as string}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

          {/* {getValues("selectedReported")?.reportName !==
            "stock_wise_holdings_report" &&
            (getValues("selectedReported")?.reportName !== "custom_report" ||
              getValues("selectedReported")?.reportType === "template") && (
              <Grid item xs={12}>
                <Controller
                  name="instaType"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKSearchSelect
                      {...field}
                      inputLabel="Select Equity, Mutual Fund or Both"
                      onChange={(event, val) => {
                        field.onChange(val);
                      }}
                      options={[
                        {
                          name: "Equity",
                          value: "NCE",
                        },
                        {
                          name: "Mutual Fund",
                          value: "MF",
                        },
                        {
                          name: "Both",
                          value: "ALL",
                        },
                      ]}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Equity, Mutual Fund or Both"
                          error={!!errors?.portfolio}
                          helperText={errors?.portfolio?.message as string}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )} */}

          {notAccessThisSectionReport && (
            <>
              <Controller
                name="currentOption"
                control={form.control}
                render={({ field }) => (
                  <Grid container item xs={12} display={"flex"} spacing={1}>
                    <Grid item xs={12}>
                      <Typography color={theme.palette.dark.main}>
                        Available Time Periods
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "inception" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("inception")}
                      >
                        Since Inception
                      </GKButton>
                    </Grid>
                    <Grid item xs={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "thisFY" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("thisFY")}
                      >
                        This FY
                      </GKButton>
                    </Grid>
                    <Grid item xs={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "lastFY" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("lastFY")}
                      >
                        Previous FY
                      </GKButton>
                    </Grid>
                    <Grid item xs={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        variant={
                          field.value === "custom" ? "contained" : "outlined"
                        }
                        fullWidth
                        onClick={() => handleClickPeriods("custom")}
                      >
                        Custom
                      </GKButton>
                    </Grid>
                  </Grid>
                )}
              />
              {getValues("currentOption") === "custom" && (
                <Grid item xs={12} mt={1} display={"flex"} gap={2}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="from_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="Start Date"
                          value={dayjs(field.value)}
                          onChange={(value: any) =>
                            field.onChange(dayjs(value).format("YYYY-MM-DD"))
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="to_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="End Date"
                          value={dayjs(field.value)}
                          minDate={
                            dayjs(field.value).isValid()
                              ? dayjs(field.value)
                              : null
                          }
                          onChange={(value: any) =>
                            field.onChange(dayjs(value).format("YYYY-MM-DD"))
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          {(getValues("selectedReported")?.reportName === "xirr_report" ||
            getValues("selectedReported")?.reportName ===
              "historical_valuation_report" ||
            getValues("selectedReported")?.reportName ===
              "unrealized_capital_gain_detailed_report" ||
            (getValues("selectedReported")?.reportName ===
              "unrealized_capital_gain_summary_report" &&
              getValues("selectedReported")?.reportName !== "custom_report") ||
            getValues("selectedReported")?.reportType === "template") && (
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="end_date"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKDatePicker
                      {...field}
                      inputLabel="Date of Generation"
                      value={dayjs(field.value)}
                      onChange={(value: any) =>
                        field.onChange(dayjs(value).format("YYYY-MM-DD"))
                      }
                      disableFuture
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Show.When
            isTrue={getValues("selectedReported")?.reportType !== "template"}
          >
            {getValues("selectedReported")?.reportName === "custom_report" && (
              <Grid container item xs={12} spacing={2}>
                <Controller
                  name="availableTimePeriods"
                  control={form.control}
                  render={({ field }) => (
                    <Grid container item xs={12} display={"flex"} spacing={1}>
                      <Grid item xs={12}>
                        <Typography color={theme.palette.dark.main}>
                          Available Time Periods
                        </Typography>
                      </Grid>
                      <Grid item xs={2.4}>
                        <GKButton
                          style={{
                            height: "100%",
                          }}
                          fullWidth
                          variant={
                            field.value.includes("since_inception")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods("since_inception")
                          }
                        >
                          Since Inception
                        </GKButton>
                      </Grid>
                      <Grid item xs={2.4}>
                        <GKButton
                          style={{
                            height: "100%",
                          }}
                          fullWidth
                          variant={
                            field.value.includes("month_to_date")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods("month_to_date")
                          }
                        >
                          MTD
                        </GKButton>
                      </Grid>
                      <Grid item xs={2.4}>
                        <GKButton
                          style={{
                            height: "100%",
                          }}
                          fullWidth
                          variant={
                            field.value.includes("quarter_to_date")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods("quarter_to_date")
                          }
                        >
                          QTD
                        </GKButton>
                      </Grid>
                      <Grid item xs={2.4}>
                        <GKButton
                          style={{
                            height: "100%",
                          }}
                          fullWidth
                          variant={
                            field.value.includes("year_to_date")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleClickTimePeriods("year_to_date")}
                        >
                          YTD
                        </GKButton>
                      </Grid>
                      <Grid item xs={2.4}>
                        <GKButton
                          style={{
                            height: "100%",
                          }}
                          fullWidth
                          variant={
                            field.value.includes("custom_date_range")
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() =>
                            handleClickTimePeriods("custom_date_range")
                          }
                        >
                          Custom
                        </GKButton>
                      </Grid>
                    </Grid>
                  )}
                />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  {getValues("availableTimePeriods").includes(
                    "custom_date_range"
                  ) && (
                    <>
                      <Grid container item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <Controller
                            name="custom_date.start_date"
                            control={form.control}
                            render={({ field }) => (
                              <GKDatePicker
                                {...field}
                                inputLabel="Start Date"
                                value={dayjs(field.value)}
                                onChange={(value) =>
                                  field.onChange(
                                    value
                                      ? dayjs(value).format("YYYY-MM-DD")
                                      : ""
                                  )
                                }
                                disableFuture
                                slotProps={{
                                  textField: {
                                    error: false,
                                    helperText: false,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <Controller
                            name="custom_date.end_date"
                            control={form.control}
                            render={({ field }) => (
                              <GKDatePicker
                                {...field}
                                inputLabel="End Date"
                                value={dayjs(field.value)}
                                onChange={(value) =>
                                  field.onChange(
                                    value
                                      ? dayjs(value).format("YYYY-MM-DD")
                                      : ""
                                  )
                                }
                                maxDate={dayjs()}
                                disableFuture
                                slotProps={{
                                  textField: {
                                    error: false,
                                    helperText: false,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography color={theme.palette.dark.main}>
                      Section to includes
                    </Typography>
                    <FormControl component="fieldset">
                      <Controller
                        name={"optionsToInclude"}
                        control={form.control}
                        render={({ field }) => (
                          <FormGroup>
                            {PMS_REPORT_OPTION_TO_INCLUDE.map((option) => (
                              <FormControlLabel
                                {...field}
                                key={option.value}
                                control={
                                  <GKCheckbox
                                    sx={{ paddingLeft: "0px" }}
                                    checked={field.value.includes(option.value)}
                                    onChange={() => {
                                      const updatedOptions =
                                        field.value.includes(option.value)
                                          ? field.value.filter(
                                              (item: any) =>
                                                item !== option.value
                                            )
                                          : [...field.value, option.value];

                                      field.onChange(updatedOptions);
                                    }}
                                  />
                                }
                                label={option.name}
                              />
                            ))}
                          </FormGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={"benchmark"}
                      control={form.control}
                      render={({ field, formState: { errors } }) => (
                        <GKSearchSelect
                          {...field}
                          disableClearable
                          inputLabel="Benchmark"
                          loading={benchmarkLoading}
                          onChange={(event, val) => field.onChange(val.value)}
                          value={
                            benchmarkList.find(
                              (data: any) => data?.value === field.value
                            )?.name || []
                          }
                          options={benchmarkList || []}
                          getOptionLabel={(option) =>
                            Array.isArray(option) ? "" : option.name || option
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select benchmark"
                              error={!!errors?.benchmark}
                              helperText={errors?.benchmark?.message as string}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={"disclaimer"}
                      control={form.control}
                      render={({ field }) => (
                        <GKTextArea
                          {...field}
                          minRows={4}
                          inputLabel="Disclaimer"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color={theme.palette.dark.main}>
                      Quote
                    </Typography>
                    <Grid
                      container
                      item
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={2}
                    >
                      <Grid item xs={10}>
                        <Controller
                          name={"quote"}
                          control={form.control}
                          render={({ field }) => (
                            <GKSelect
                              {...field}
                              inputLabel=""
                              placeholder="Select Quote"
                              renderValue={(selected) => {
                                const value =
                                  selected &&
                                  quoteList?.find(
                                    (data: any) => data?.value === selected
                                  )?.name;

                                return value || "Select an option";
                              }}
                            >
                              {quoteList.map((data: any) => {
                                return (
                                  <MenuItem
                                    value={data?.value}
                                    key={data?.value}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography>{data?.name}</Typography>
                                    <span
                                      style={{
                                        display: "flex",
                                        gap: "2px",
                                      }}
                                    >
                                      <IconButton
                                        style={{ padding: 0 }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditQuoteValue(data);
                                          setOpenQuoteModal(true);
                                        }}
                                      >
                                        <AiOutlineEdit />
                                      </IconButton>
                                      <IconButton
                                        style={{ padding: 0 }}
                                        className="outline"
                                        color="error"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteQuote(data?.id);
                                        }}
                                      >
                                        <FiTrash2 size={18} />
                                      </IconButton>
                                    </span>
                                  </MenuItem>
                                );
                              })}
                            </GKSelect>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        gap={1}
                      >
                        <IconButton
                          onClick={() => generateQuote(quoteList, form)}
                        >
                          <BsArrowRepeat />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setEditQuoteValue({});
                            setOpenQuoteModal(true);
                          }}
                        >
                          <IoMdAdd />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  {getValues("optionsToInclude")?.includes(
                    "holding_statement"
                  ) && (
                    <Grid item xs={6}>
                      <Typography color={theme.palette.dark.main}>
                        Holding Statement
                      </Typography>
                      <FormControl component="fieldset">
                        <Controller
                          name="holdingsStatement"
                          control={form.control}
                          render={({ field }) => (
                            <FormGroup>
                              {PMS_REPORT_HOLDING_STATEMENT.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  control={
                                    <GKCheckbox
                                      {...field}
                                      sx={{ paddingLeft: "0px" }}
                                      checked={field.value.includes(
                                        option.value
                                      )}
                                      onChange={() => {
                                        const updatedOptions =
                                          field.value.includes(option.value)
                                            ? field.value.filter(
                                                (item: any) =>
                                                  item !== option.value
                                              )
                                            : [...field.value, option.value];

                                        field.onChange(updatedOptions);
                                      }}
                                    />
                                  }
                                  label={option.name}
                                />
                              ))}
                            </FormGroup>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {getValues("optionsToInclude").includes("bank_statement") && (
                    <Grid item xs={6}>
                      <Typography color={theme.palette.dark.main}>
                        Bank Statement
                      </Typography>
                      <FormControl component="fieldset">
                        <Controller
                          name="bankStatement"
                          control={form.control}
                          render={({ field }) => (
                            <FormGroup>
                              {PMS_REPORT_BANK_STATEMENT.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  control={
                                    <GKCheckbox
                                      {...field}
                                      sx={{ paddingLeft: "0px" }}
                                      checked={field.value.includes(
                                        option.value
                                      )}
                                      onChange={() => {
                                        const updatedOptions =
                                          field.value.includes(option.value)
                                            ? field.value.filter(
                                                (item: any) =>
                                                  item !== option.value
                                              )
                                            : [...field.value, option.value];

                                        field.onChange(updatedOptions);
                                      }}
                                    />
                                  }
                                  label={option.name}
                                />
                              ))}
                            </FormGroup>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography color={theme.palette.dark.main}>
                      Optional Field
                    </Typography>
                    <FormControl component="fieldset">
                      <Controller
                        name="optionalField"
                        control={form.control}
                        render={({ field }) => (
                          <FormGroup row>
                            {PMS_REPORT_OPTIONAL_FIELD.map((option) => {
                              return (
                                <FormControlLabel
                                  key={option.value}
                                  control={
                                    <GKCheckbox
                                      {...field}
                                      sx={{ paddingLeft: "0px" }}
                                      checked={field.value?.includes(
                                        option.value
                                      )}
                                      onChange={() => {
                                        const updatedOptions =
                                          field.value.includes(option.value)
                                            ? field.value.filter(
                                                (item: any) =>
                                                  item !== option.value
                                              )
                                            : [...field.value, option.value];

                                        field.onChange(updatedOptions);
                                      }}
                                    />
                                  }
                                  label={option.name}
                                />
                              );
                            })}
                          </FormGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color={theme.palette.dark.main}>
                      Portfolio Calculation Preference
                    </Typography>
                    <FormControl component="fieldset">
                      <Controller
                        name="tradesPlusCash"
                        control={form.control}
                        render={({ field }) => (
                          <FormGroup row>
                            {PMS_REPORT_EXTRA_OPTIONS_FIELD.map((option) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Radio
                                      {...field}
                                      sx={{ paddingLeft: "0px" }}
                                      checked={field.value === option.value}
                                      onChange={() => {
                                        const updatedOption = option.value;
                                        field.onChange(updatedOption);
                                      }}
                                    />
                                  }
                                  label={option.name}
                                />
                              );
                            })}
                          </FormGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Show.When>

          {getValues("selectedReported")?.reportName ===
            "stock_wise_holdings_report" && (
            <>
              <Grid item xs={12}>
                <Controller
                  name={"gcode"}
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable
                      inputLabel="Company"
                      onChange={(event, val) => {
                        field.onChange(val.value);
                      }}
                      value={
                        companies.find(
                          (data: any) => data?.value === field.value
                        )?.name || []
                      }
                      options={companies || []}
                      getOptionLabel={(option) =>
                        Array.isArray(option) ? "" : option.name || option
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Company"
                          error={!!errors?.gcode}
                          helperText={errors?.gcode?.message as string}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={"to_date"}
                    control={form.control}
                    render={({ field, formState: { errors } }) => (
                      <GKDatePicker
                        inputLabel="Date"
                        name="to_date"
                        value={dayjs(field.value)}
                        onChange={(value: any) =>
                          field.onChange(dayjs(value).format("YYYY-MM-DD"))
                        }
                        disableFuture
                        slotProps={{
                          textField: {
                            error: !!errors?.to_date,
                            helperText: errors?.to_date?.message as string,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            {(getValues("selectedReported")?.reportName !== "custom_report" ||
              getValues("selectedReported")?.reportType === "template") && (
              <LoadingButton
                variant="contained"
                loading={selectedIndex.index === 0 && selectedIndex.loading}
                form="report-form"
                type="submit"
                disabled={
                  selectedIndex.loading ||
                  (getValues("selectedReported")?.reportName ===
                    "custom_report" &&
                    getValues("selectedReported")?.reportType !== "template")
                }
                onClick={() =>
                  setSelectedIndex((prev: any) => {
                    return {
                      ...prev,
                      index: 0,
                    };
                  })
                }
              >
                {`Generate PDF`}
              </LoadingButton>
            )}
            &nbsp;
            {(getValues("selectedReported")?.reportName !== "custom_report" ||
              getValues("selectedReported")?.reportType === "template") && (
              <LoadingButton
                variant="contained"
                type="submit"
                form="report-form"
                onClick={() =>
                  setSelectedIndex((prev: any) => {
                    return {
                      ...prev,
                      index: 1,
                    };
                  })
                }
                disabled={
                  getValues("selectedReported")?.reportName ===
                    "custom_report" ||
                  getValues("selectedReported")?.reportName ===
                    "portfolio_performance_report" ||
                  getValues("selectedReported")?.reportName ===
                    "stock_wise_holdings_report" ||
                  selectedIndex.loading
                }
                loading={selectedIndex.index === 1 && selectedIndex.loading}
              >
                {`Generate EXCEL`}
              </LoadingButton>
            )}
            &nbsp;
            {getValues("selectedReported")?.reportName === "custom_report" &&
              getValues("selectedReported")?.reportType !== "template" && (
                <GKButton
                  variant="contained"
                  onClick={() => {
                    setOpenTemplateModal(true);
                  }}
                >
                  save as template
                </GKButton>
              )}
          </Grid>
        </Grid>
      </Show.When>

      <Show.When isTrue={openPdfView}>
        <ViewPdf
          open={openPdfView}
          setOpen={setOpenPdfView}
          file={getValues("selectedReported")?.sampleFile}
        />
      </Show.When>

      <Show.When isTrue={openTemplateModal}>
        <TemplateModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
        />
      </Show.When>
    </>
  );
};

export default GenerateReportForm;
