import { LoadingButton } from "@mui/lab";
import { Card, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import AccessTab from "./AccessTab";
import ClientTab from "./ClientTab";
import EditFamilyModal from "./EditFamilyModal";
import FamilyCard from "./FamilyCard";
import HoldingTab from "./HoldingTab";
import PortfolioTab from "./PortfolioTab";

const FamilyDetails = () => {
  useDocumentTitle("Family Details");

  const navigate = useNavigate();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const tabs = ["Clients", "Holdings", "Portfolios", "Access"];

  const { id } = useParams<{ id: string }>();

  const [openFamilyModal, setOpenFamilyModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, id],
    route: `/family/${id}/`,
  });

  const { mutate: handleDeleteFamily, isLoading: deleteLoading } = useMutation({
    mutationFn: (id: any) => axiosInstance.delete(`/family/${id}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(-1);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <DashboardLayout title="Family Details" loading={isFetching}>
      <Grid container spacing={1}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <Typography fontWeight={600} fontSize={20}>
            {data?.data?.name}
            <span style={{ marginLeft: "8px", fontSize: 12, fontWeight: 500 }}>
              {data?.data?.code}
            </span>
          </Typography>
          <Card
            sx={{
              display: "flex",
              padding: "6px",
              cursor: "pointer",
              marginLeft: "8px",
            }}
            onClick={() => setOpenFamilyModal(true)}
          >
            <BiEdit fontSize={18} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FamilyCard />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Grid
            item
            xs={12}
            container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Tabs
              variant="standard"
              value={tab.familyERMPageTabs}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, familyERMPageTabs: val },
                });
              }}
            >
              {tabs?.map((dataItem, index) => (
                <Tab key={index} label={dataItem} disableRipple />
              ))}
            </Tabs>
            <Show.When isTrue={tab?.familyERMPageTabs === 3}>
              <LoadingButton
                onClick={() => setIsDeleteModalOpen(true)}
                color="error"
                variant="outlined"
                size="medium"
                loading={deleteLoading}
              >
                Delete Family
              </LoadingButton>
            </Show.When>
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {tab?.familyERMPageTabs === 0 && <ClientTab data={data} />}
          {tab?.familyERMPageTabs === 1 && <HoldingTab />}
          {tab?.familyERMPageTabs === 2 && <PortfolioTab />}
          {tab?.familyERMPageTabs === 3 && <AccessTab />}
        </Grid>
      </Grid>

      <Show.When isTrue={openFamilyModal}>
        <EditFamilyModal
          open={openFamilyModal}
          setOpen={setOpenFamilyModal}
          data={data?.data}
        />
      </Show.When>

      <Show.When isTrue={isDeleteModalOpen}>
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Family"
          onDelete={() => handleDeleteFamily(id)}
          isDelete
          deleteMessage="Are you sure you want to delete this family? This family will permanently be removed. This action cannot be undone."
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default FamilyDetails;
