import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import IntroSection from "./IntroSection";
import Section1 from "./Section1";
import Section4 from "./Section4";

const AboutUs = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container>
        <div ref={myRef} />
        <Grid item xs={12} borderBottom={"1px solid #323232"} zIndex={521}>
          <IntroSection />
        </Grid>
        <Grid item xs={12} borderBottom={"1px solid #323232"} zIndex={51}>
          <Section1 />
        </Grid>
        <Grid
          item
          xs={12}
          borderTop={"1px solid #323232"}
          borderBottom={"1px solid #323232"}
          zIndex={1}
        >
          <Section4 />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default AboutUs;
