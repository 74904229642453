import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioRealisedInventory = ({ portfolioId }: any) => {
  useDocumentTitle("Holding Realised Inventory");

  const { id } = useParams();

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ALL_REALISED_INVENTORY"],
    route: `/portfolios/${portfolioId}/sold_inventory/`,
    requestBody: {
      gcode: id,
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Quantity", "quantity", 0, 1),
      align: "right",
      valueGetter: (params) => params.row.quantity || "0",
      headerAlign: "right",
      type: "number",
    },
    {
      ...extraColumnsField("Buy Price", "purchasePrice", 0, 1),
      align: "right",
      headerAlign: "right",
      type: "number",
      valueGetter: (params) => params.row.purchasePrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Buy Date", "purchaseDate", 0, 1),
      valueGetter: (row) => row?.row?.purchaseDate || "N/A",
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Sell Price", "sellPrice", 0, 1),
      align: "right",
      headerAlign: "right",
      type: "number",
      valueGetter: (params) => params.row.sellPrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Sell Date", "sellDate", 0, 1),
      valueGetter: (row) => row?.row?.sellDate || "N/A",
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Realised Gain", "realisedGain", 0, 1),
      type: "number",
      renderCell: (value) => {
        const { realisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(realisedGain)}>
            {moneyCommaSeparator(realisedGain)}
          </Typography>
        );
      },
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <GKClientDataGrid
          name="longTermHolding"
          density="compact"
          loading={isFetching}
          columns={columns}
          rows={
            data?.data?.data.map((dataItem: any, index: number) => {
              return {
                id: index,
                ...dataItem,
              };
            }) || []
          }
        />
      </Grid>
    </Grid>
  );
};

export default PortfolioRealisedInventory;
