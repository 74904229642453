import { FormControl, Grid, TextField } from "@mui/material";
import { useAppContext } from "AppContext";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Controller, useFormContext } from "react-hook-form";

const BasicInfoTab = ({ isDisable }: { isDisable: boolean }) => {
  const form = useFormContext();

  const {
    state: { clientList },
  } = useAppContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.name"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Portfolio name"
                inputProps={{
                  readOnly: isDisable,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.portfolioId"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Portfolio Code"
                inputProps={{
                  readOnly: isDisable,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="portfolio.client"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              disableClearable
              options={clientList || []}
              getOptionLabel={(option) => option?.name || option}
              renderInput={(params) => <TextField {...params} />}
              inputLabel="Client"
              onChange={(event, val) => field.onChange(val?.id)}
              value={
                clientList?.find((data: any) => data?.id === field.value)?.name
              }
              readOnly={isDisable}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.committedValue"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Committed Value"
                type="number"
                inputProps={{
                  readOnly: isDisable,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BasicInfoTab;
