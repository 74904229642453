/* eslint-disable jsx-a11y/media-has-caption */
import { Grid, Typography } from "@mui/material";
import { ThemeContainer } from "utils/Theme";

interface Props {
  message?: string;
}

const Loader = ({ message }: Props) => {
  const { theme } = ThemeContainer();
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 9999,
        background: `#EEF5F6`,
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <img
          src="/Logos/GridkeyGif.gif"
          alt=" "
          height={100}
          width={100}
          style={{
            filter: `drop-shadow(0px 0px 3px #00000077)`,
          }}
        />

        <Typography
          fontWeight={700}
          fontSize={16}
          color={theme.palette.dark.main}
        >
          {message || " Your Experience is Worth the Wait!"}{" "}
        </Typography>
      </Grid>
    </div>
  );
};

export default Loader;
