import { Grid, Typography } from "@mui/material";

const IntroSection = () => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        background: `linear-gradient(180deg,#102E2A 12%, rgba(0,0,0) 100%)`,
        px: {
          xs: 3,
          lg: 10,
        },
      }}
      py={"140px"}
      pt={"223px"}
    >
      <Grid item xs={12}>
        <Typography
          textAlign={"center"}
          fontSize={44}
          fontWeight={700}
          style={{
            background:
              "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
            WebkitBackgroundClip: " text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Wealth Basket{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2} mb={1}>
        <Typography textAlign={"center"} fontSize={22} fontWeight={500}>
          Unlocking the potential of fund managers and growing their AUM{" "}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        mb={3}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography
          textAlign={"center"}
          fontSize={16}
          color={"#999999"}
          width={"60%"}
        >
          Our data-driven platform brings clarity to your model portfolios,
          simplifies your decision-making, and enables investors to subscribe to
          your baskets.
          <br /> Simple. Intuitive. Transparent. And never boring.
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Button variant="landingBtn"> Get Started</Button>
      </Grid> */}
    </Grid>
  );
};

export default IntroSection;
