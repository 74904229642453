import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MdAdd } from "react-icons/md";
import axiosInstance from "utils/axiosInstance";
import { EXPENSE, INCOME, IncomeExpenseTransactionType } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import ExtraChargesModal from "./ExtraChargesModal";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  portfolioId?: any;
  data: any;
}

const IncomeExpenseEditModal = (props: Props) => {
  const { open, setOpen, portfolioId, data } = props;

  const {
    state: { portfolios },
  } = useAppContext();

  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [chargesIndex, setChargesIndex] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);

  const { mutate: updateIncomeExpense, isLoading } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.patch(`/income_expense/${data.id}/`, values),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(
        portfolioId
          ? QueryKeys.PORTFOLIO_INCOME_EXPENSE_LIST
          : QueryKeys.INCOME_EXPENSE_LIST
      );
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: deleteIncomeExpense, isLoading: deleteIncomeExpenseLoading } =
    useMutation({
      mutationFn: () => axiosInstance.delete(`/income_expense/${data.id}/`),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        refetchQuery(
          portfolioId
            ? QueryKeys.PORTFOLIO_INCOME_EXPENSE_LIST
            : QueryKeys.INCOME_EXPENSE_LIST
        );
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const form = useForm({
    defaultValues: {
      transactionType: data?.transactionType,
      portfolio: data?.portfolio || "",
      date: data?.date || "",
      billAmount: data?.billAmount || 0,
      subTransactionType: data?.subTransactionType || "",
      remarks: data?.remarks || "",
      tax: data?.tax || "",
      totalCharges: data?.totalCharges || {
        otherCharges: 0,
        stt: 0,
        gst: 0,
        stampCharges: 0,
        transactionCharges: 0,
        sebiTurnoverFees: 0,
      },
    },
  });

  form.watch();

  const getTransactionType =
    form.getValues("transactionType") === "INCOME"
      ? "Select Income Type"
      : "Select Expense Type";

  const isDividend = form.getValues("subTransactionType") === "dividend";

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"70%"}
      modalTitle={"Edit Income/Expense"}
      titleComponent={
        data?.id && (
          <LoadingButton
            loading={deleteIncomeExpenseLoading}
            color="error"
            variant="outlined"
            onClick={() => setConfirmationModal(true)}
          >
            Delete
          </LoadingButton>
        )
      }
      footer={
        <Grid
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <LoadingButton
            disabled={!!isDividend}
            variant="contained"
            size="medium"
            type="submit"
            form="income-expense-edit-form"
            loading={isLoading}
          >
            {data?.id ? "Update" : "Save"}
          </LoadingButton>
        </Grid>
      }
    >
      <FormProvider {...form}>
        <form
          id="income-expense-edit-form"
          onSubmit={form.handleSubmit((values) => updateIncomeExpense(values))}
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Controller
                name="transactionType"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select type",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    disabled
                    disableClearable={false}
                    inputLabel="Select Type"
                    requiredField
                    options={IncomeExpenseTransactionType || []}
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                      form.reset({
                        transactionType: val?.value,
                        portfolio: form.getValues("portfolio") || "",
                        date: form.getValues("date") || "",
                        billAmount: 0,
                        subTransactionType: "",
                        remarks: "",
                        totalCharges: {
                          otherCharges: 0,
                          stt: 0,
                          gst: 0,
                          stampCharges: 0,
                          transactionCharges: 0,
                          sebiTurnoverFees: 0,
                        },
                      });
                    }}
                    getOptionLabel={(option) => option.name || option}
                    value={
                      IncomeExpenseTransactionType?.find(
                        (data: any) => data?.value === field.value
                      )?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Type"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="portfolio"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Select Portfolio",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    disabled={!!portfolioId}
                    disableClearable
                    inputLabel="Select Portfolio"
                    requiredField
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    options={portfolios || []}
                    value={
                      portfolios?.find(
                        (dataItem: any) => dataItem.value === field.value
                      ) || null
                    }
                    onChange={(e, val) => field.onChange(val.value)}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="portfolio"
                        placeholder="Select Portfolio"
                        error={Boolean(errors.portfolio)}
                        helperText={errors.portfolio?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="date"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please Select Transaction Date",
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <GKDatePicker
                      {...field}
                      requiredField
                      inputLabel="Date"
                      value={dayjs(field.value)}
                      onChange={(value) => {
                        field.onChange(dayjs(value).format("YYYY-MM-DD"));
                      }}
                      disableFuture
                      slotProps={{
                        textField: {
                          error: Boolean(errors.date),
                          helperText: errors.date?.message as string,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid item container xs={12} spacing={1} my={1}>
                <Grid item xs={isDividend ? 3 : 4}>
                  <Controller
                    name={"subTransactionType"}
                    control={form.control}
                    render={({ field }) => (
                      <GKSearchSelect
                        {...field}
                        disableClearable={false}
                        inputLabel={getTransactionType}
                        options={
                          form.getValues("transactionType") === "INCOME"
                            ? INCOME
                            : EXPENSE || []
                        }
                        getOptionLabel={(option) => option?.name || option}
                        value={
                          (form.getValues("transactionType") === "INCOME"
                            ? INCOME
                            : EXPENSE
                          ).find((dataItem) => dataItem.value === field.value)
                            ?.name || null
                        }
                        onChange={(e, val) => {
                          field.onChange(val ? val.value : null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={getTransactionType}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={isDividend ? 3 : 4}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={"remarks"}
                      control={form.control}
                      render={({ field }) => (
                        <GKTextField {...field} inputLabel="Remarks" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={isDividend ? 3 : 4}
                  display={"flex"}
                  alignItems={"flex-end"}
                  gap={1}
                >
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name={"billAmount"}
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Enter Amount",
                        },
                      }}
                      render={({ field, formState: { errors } }) => (
                        <GKTextField
                          {...field}
                          type="number"
                          disabled={
                            form.getValues("subTransactionType") ===
                            "total_charges"
                          }
                          inputLabel={
                            form.getValues("subTransactionType") ===
                            "total_charges"
                              ? "Total Charges"
                              : "Amount"
                          }
                          requiredField
                          error={Boolean(errors.billAmount)}
                          helperText={errors.billAmount?.message as string}
                          onChange={(e: any) =>
                            field.onChange(e.target.valueAsNumber)
                          }
                        />
                      )}
                    />
                  </FormControl>
                  {form.getValues("subTransactionType") === "total_charges" && (
                    <IconButton
                      className="outline"
                      sx={{ mb: 1 }}
                      onClick={() => {
                        setChargesIndex(form.getValues("totalCharges"));
                        setOpenChargeModal(true);
                      }}
                    >
                      <MdAdd />
                    </IconButton>
                  )}
                </Grid>
                {isDividend && (
                  <Grid
                    item
                    xs={isDividend ? 3 : 4}
                    display={"flex"}
                    alignItems={"flex-end"}
                    gap={1}
                  >
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name={"tax"}
                        control={form.control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter tax amount",
                          },
                        }}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            type="number"
                            inputLabel="TDS"
                            onChange={(e: any) =>
                              field.onChange(e.target.valueAsNumber)
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Show.When isTrue={openChargeModal}>
              <ExtraChargesModal
                open={openChargeModal}
                setOpen={setOpenChargeModal}
                index={chargesIndex}
              />
            </Show.When>

            <Show.When isTrue={confirmationModal}>
              <ConfirmDeleteModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                onDelete={() => deleteIncomeExpense()}
                title={"Income/Expense"}
                isDelete
              />
            </Show.When>
          </Grid>
        </form>
      </FormProvider>
    </GKModal>
  );
};

export default IncomeExpenseEditModal;
