import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import { CompliancePage, Lead } from "Pages";
import Client from "Pages/Client";
import Family from "Pages/Family";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";

const CRMMenu = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: "#fff",
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, tabValueOfCRM: val },
                });
              }}
              value={tab?.tabValueOfCRM}
            >
              <Tab label="Client" disableRipple />
              <Tab label="Lead" disableRipple />
              <Tab label="Onboarding" disableRipple />
              <Tab label="Family" disableRipple />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Show.When isTrue={tab?.tabValueOfCRM === 0}>
            <AnimationComponent>
              <Client />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueOfCRM === 1}>
            <AnimationComponent>
              <Lead />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueOfCRM === 2}>
            <AnimationComponent>
              <CompliancePage />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueOfCRM === 3}>
            <AnimationComponent>
              <Family />
            </AnimationComponent>
          </Show.When>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CRMMenu;
