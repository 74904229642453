import { Grid } from "@mui/material";
import ComplianceKYCModal from "Pages/Client/ComplianceTabs/ComplianceKYCModal";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { clientKYCColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import KYCCard from "./KYCCard";
import KYCExtendedRow from "./KYCExtendedRow";

const ClientKYC = () => {
  useDocumentTitle("Client KYC");

  const { id } = useParams();

  const [allKYCdata, setAllKYCData] = useState<any>([]);

  const [addClientKyc, setAddClientKyc] = useState(false);

  const { isLoading, refetch } = useFetchQuery({
    key: [QueryKeys.KYC_CLIENT],
    route: `/kyc/${id}/`,
    success: (data: any) => setAllKYCData(data?.data?.data),
  });

  const { data: clientData } = useFetchQuery({
    key: [QueryKeys.CLIENT_DETAILS],
    route: `/client/${id}/`,
  });

  const handleSubmit = async (status: string) => {
    await axiosInstance
      .post(`/kyc/status/`, { kyc_id: allKYCdata?.kycId, status })
      .then((response) => {
        refetch();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
  };

  return (
    <DashboardLayout title="Client KYC">
      <KYCCard
        setAddClientKyc={setAddClientKyc}
        allKYCdata={allKYCdata}
        handleSubmit={handleSubmit}
      />
      <Grid
        mt={2}
        container
        height={allKYCdata?.documents?.length === 0 ? "100%" : "auto"}
      >
        <Grid item xs={12}>
          <GKClientDataGrid
            name="clientKYC"
            showSearch={false}
            showFilters={false}
            headerGridStyle={{
              justifyContent: "flex-end",
            }}
            getDetailPanelContent={KYCExtendedRow}
            searchPlaceholder="Search For KYC"
            loading={isLoading}
            showHeaderGrid={false}
            columns={clientKYCColumns}
            rows={allKYCdata?.documents || []}
            rowHeight={100}
          />
        </Grid>

        {addClientKyc && (
          <ComplianceKYCModal
            setOpen={setAddClientKyc}
            open={addClientKyc}
            showData={allKYCdata}
            refetch={refetch}
            clientData={clientData?.data}
          />
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default ClientKYC;
