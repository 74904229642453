import { Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { PiDownload, PiWarningOctagonLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import ImportModal from "./ImportModal";

const FeeCollection = () => {
  useDocumentTitle("Fee Collection");

  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const [feeCollectionId, setFeeCollectionId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const requestBody = {
    take: commonFilter?.feeCollection?.take,
    skip: commonFilter?.feeCollection?.skip,
    sort: JSON.stringify(
      commonFilter?.feeCollection?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.feeCollection?.search || "",
  };

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_LIST_OF_FEE_COLLECTION", requestBody],
    route: "/client-fee-collection/",
    requestBody,
  });

  const { mutate: deleteFeeCollection, isLoading } = useMutation({
    mutationKey: ["DELETE_FEE_PLAN"],
    mutationFn: (id) => axiosInstance.delete(`/client-fee-collection/${id}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
      setFeeCollectionId(null);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="feeCollection"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 100, 1),
      valueFormatter: (value) => matchDate(value.value),
      renderCell: (props) => {
        return (
          <Typography>
            {dayjs(props?.row?.date).format("D MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Client Name", "client", 150, 1),
      renderCell: (props) => {
        return <Typography>{props.row.client || "-"}</Typography>;
      },
    },
    {
      ...extraColumnsField("Amount", "amount", 150, 1),
      valueGetter: (row) => row?.row?.amount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Payment Type", "paymentType", 150, 1),
      renderCell: (row) => <StatusField statusString={row?.row?.paymentType} />,
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      renderCell: (row) => <StatusField statusString={row?.row?.status} />,
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      type: "action",
      renderCell: (params) => {
        return (
          <IconButton
            className="outline"
            color="error"
            disabled={params.row.id === feeCollectionId && isLoading}
            onClick={(event) => {
              event.stopPropagation();
              setFeeCollectionId(params.row.id);
              setConfirmationModal(true);
            }}
          >
            <FiTrash2 size={20} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        headerComponent={
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <GKButton
              variant="contained"
              onClick={() => {
                navigate("/app/collection/create");
              }}
              startIcon={<CgMathPlus size={16} />}
            >
              Fee Collection
            </GKButton>
            <GKButton
              variant="contained"
              onClick={() => setOpenImportModal(true)}
              startIcon={<PiDownload size={16} />}
            >
              Import Fees
            </GKButton>
          </Grid>
        }
        onRowClick={(params) => {
          if (params.row.isHistoricallyMigrated) {
            toast(() => (
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                <PiWarningOctagonLight
                  size={24}
                  color={paletteLightTheme.palette.warning.main}
                />
                <Typography>
                  This fee collection cannot be viewed because it is
                  historically migrated
                </Typography>
              </span>
            ));
          } else {
            navigate(`/app/collection/${params.row.id}/edit`);
          }
        }}
        maxHeight={"calc(100vh - 306px)"}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        name="feeCollection"
      />

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => deleteFeeCollection(feeCollectionId)}
          title={"Fee Collection"}
          isDelete
        />
      </Show.When>

      <Show.When isTrue={openImportModal}>
        <ImportModal open={openImportModal} setOpen={setOpenImportModal} />
      </Show.When>
    </>
  );
};

export default FeeCollection;
