/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import { CircularProgress, Divider, Grid, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { ThemeContainer } from "utils/Theme";

interface Props {
  activeStep: number;
  loading?: boolean;
  formik?: any;
  format?: string;
}

const CustomeSection = styled(`section`)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  width: "100%",
}));

const UploadManagerStepperModal = ({
  activeStep,
  loading,
  formik,
  format,
}: Props) => {
  const { theme } = ThemeContainer();

  const currentActiveStep = activeStep - 1;

  const steps = [
    {
      label: "File Upload",
      subLabel: "File uploaded successfully.",
      show: true,
    },
    {
      label: "Portfolio Mapping",
      subLabel: "Mappings successfully.",
      show: true,
    },
    {
      label: "Company Mapping",
      subLabel: "Company Mapping successfully.",
      show:
        formik?.values?.fileUploadType?.toLowerCase() !== "cash" &&
        format !== "ADITYA_BIRLA_MONEY_LEDGER",
    },
    {
      label: "Review File",
      subLabel: "Review successfully.",
      show: true,
    },
    {
      label: "Transaction Upload",
      subLabel: "Transactions uploaded successfully.",
      show: true,
    },
  ];

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      {steps.map((step, index) => {
        return (
          step.show && (
            <Grid
              item
              key={step.label}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <CustomeSection>
                {currentActiveStep < index && (
                  <BsCircle
                    width={40}
                    height={40}
                    color={theme.palette.grey[600]}
                  />
                )}

                {loading && currentActiveStep === index && (
                  <CircularProgress size={15} />
                )}
                {currentActiveStep > index && (
                  <BsCheckCircle
                    width={40}
                    height={40}
                    color={theme.palette.success.main}
                  />
                )}
                <Typography
                  whiteSpace={"nowrap"}
                  fontWeight={700}
                  fontSize={10}
                  color={theme.palette.grey[700]}
                >
                  {step?.label}
                </Typography>
                <Divider
                  sx={{
                    width: "30px",
                    marginRight: "8px",
                  }}
                />
              </CustomeSection>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default UploadManagerStepperModal;
