import { Box, Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <Grid
      container
      pb={4}
      spacing={3}
      sx={{
        backgroundImage: `url(LandingPage/ctaBg.svg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        px: {
          xs: 3,
          lg: 10,
        },
      }}
    >
      <Grid
        item
        xs={12}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          paddingTop: 0,
        }}
      >
        <div
          style={{
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            padding: 10,
            background: "#000",
            paddingTop: 0,
            width: "-webkit-fill-available",
            maxWidth: "794px",
          }}
        >
          <Box
            sx={{
              borderBottomLeftRadius: 24,
              width: "100%",
              borderBottomRightRadius: 24,
              border: "solid 1px #353535",
              borderTop: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              padding: {
                xs: "32px",
                lg: "64px",
              },
            }}
          >
            <div>
              <Typography
                fontSize={32}
                fontWeight={700}
                sx={{
                  fontSize: {
                    xs: 16,
                    lg: 32,
                  },
                }}
              >
                {`Unlock your demo, let's connect now!`}
              </Typography>
              <Typography fontWeight={400} fontSize={14}>
                {`We will provide detailed information about our product`}
              </Typography>
            </div>

            <GKButton
              variant="landingBtn"
              sx={{ whiteSpace: "nowrap" }}
              href="https://calendly.com/gridkey/introduction-to-gridkey-platform?month=2024-01"
              target="_blank"
            >
              Contact us
            </GKButton>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12} lg={6} container spacing={1}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={{
            xs: "center",
            lg: "flex-start",
          }}
          flexDirection={"column"}
        >
          <img src="/LandingPage/newLogo.svg" alt="" />
          <Typography
            fontSize={14}
            textAlign={{ xs: "center", lg: "left" }}
            mt={{ xs: 2 }}
          >
            Gridkey Technologies Private Limited
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={12}
            textAlign={{ xs: "center", lg: "left" }}
            mt={{ xs: 2 }}
            textTransform={"uppercase"}
            color={"#999999"}
          >
            Address
          </Typography>
          <Typography fontSize={14} textAlign={{ xs: "center", lg: "left" }}>
            509, International Finance Center,
            <br /> Fire Station, VIP Road, Vesu,
            <br />
            Surat, Gujarat 395007
          </Typography>
          <Typography
            fontSize={12}
            textAlign={{ xs: "center", lg: "left" }}
            mt={{ xs: 2 }}
            textTransform={"uppercase"}
            color={"#999999"}
          >
            Email
          </Typography>
          <Typography fontSize={14} textAlign={{ xs: "center", lg: "left" }}>
            info@gridkey.in
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        container
        justifyContent={"center"}
        alignItems={"center"}
        rowSpacing={4}
      >
        <Grid
          item
          xs={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          alignItems={{
            xs: "center",
          }}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={{
              xs: "center",
              lg: "flex-start",
            }}
            justifyContent={"center"}
            gap={2}
          >
            <Typography
              fontSize={12}
              textAlign={{ xs: "center", lg: "left" }}
              mt={{ xs: 2 }}
              textTransform={"uppercase"}
              color={"#999999"}
            >
              COMPANY
            </Typography>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/about-us"
            >
              About us
            </Link>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/terms-condition"
            >
              Term & Condition
            </Link>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/privacy-policy"
            >
              Privacy Policy{" "}
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display={"flex"}
          flexDirection={"column"}
          alignItems={{
            xs: "center",
          }}
          justifyContent={"center"}
          gap={2}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={{
              xs: "center",
              lg: "flex-start",
            }}
            justifyContent={"center"}
            gap={2}
          >
            <Typography
              fontSize={12}
              textAlign={{ xs: "center", lg: "left" }}
              mt={{ xs: 2 }}
              textTransform={"uppercase"}
              color={"#999999"}
            >
              PRODUCT
            </Typography>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/product/wealth-wise"
            >
              Wealth Wise
            </Link>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/product/wealth-basket"
            >
              Wealth Basket
            </Link>
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              to="/product/wealth-partner"
            >
              Wealth Partner
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        mt={3}
        xs={12}
        container
        alignItems={"center"}
        justifyContent={"center"}
        borderTop={"solid 1px #323232"}
      >
        <Grid item>
          <Typography fontSize={12} color={"#999999"}>
            Copyright © 2024 Gridkey Technologies Private Limited. All rights
            reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterSection;
