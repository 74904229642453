import { Grid, Typography } from "@mui/material";

const IntroSection = () => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        background: `linear-gradient(180deg,#102E2A 12%, rgba(0,0,0) 100%)`,
        px: {
          xs: 3,
          lg: 10,
        },
      }}
      py={"140px"}
      pt={"223px"}
    >
      <Grid item xs={12}>
        <Typography
          textAlign={"center"}
          fontSize={44}
          fontWeight={700}
          style={{
            background:
              "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
            WebkitBackgroundClip: " text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Privacy Policy
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroSection;
