import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import MutualFundModal from "./MutualFundModal";
import { moneyCommaSeparator } from "utils/MathFunction";

interface Props {
  id?: number | string;
  name?: string;
  tableName?: string;
}

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 200, 1),
    renderCell: (params) => {
      return (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioCode}
          </Typography>
        </Grid>
      );
    },
  },
  {
    ...extraColumnsField("Folio No.", "folioNo", 150, 1),
  },
  {
    ...extraColumnsField("Company Name", "companyName", 200, 1),
  },
  {
    ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
    align: "right",
    headerAlign: "right",
  },
  {
    ...extraColumnsField("Trade Type", "transactionType", 120, 1),
    valueFormatter: (params) =>
      handleCapitalize(params?.value === "BUY" ? "Purchase" : "Redemption"),
  },
  {
    ...extraColumnsField("Transaction Remarks", "transactionRemarks", 150, 1),
  },
  {
    ...extraColumnsField("Quantity", "quantity", 120, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params?.row?.quantity || "0",
    valueFormatter: (params: any) => params?.value,
  },
  {
    ...extraColumnsField("Price", "price", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params?.row?.price || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Brokerage", "brokerage", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.brokerage || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.stampCharges || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.billAmount || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
];

const MutualFunds = (props: Props) => {
  useDocumentTitle("Mutual Funds");

  const { id, name, tableName } = props;

  const {
    state: { commonFilter },
  } = useAppContext();

  const [openMutualFundModal, setOpenMutualFundModal] = useState(false);
  const [mutualFundData, setMutualFundData] = useState({});

  const [selectedIds, setSelectedIds] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const filterName = name
    ? commonFilter["portfolioMutualFunds"]
    : commonFilter?.mutualFund;

  const requestBody = {
    take: filterName?.take,
    skip: filterName?.skip,
    sort: JSON.stringify(
      filterName?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: filterName?.search || "",
    filter: JSON.stringify(filterName?.customFilter),
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: id
      ? `/portfolios/${id}/mutual-fund/transactions/`
      : "/mutual_funds_transaction/",
    key: [QueryKeys.MUTUAL_FUND_LIST, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: () =>
      axiosInstance?.delete(`/mutual_funds_transaction/bulk_delete/`, {
        data: { ids: selectedIds },
      }),
    onSuccess: (response) => {
      refetch();
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name={name ? "portfolioMutualFunds" : "mutualFund"}
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={
          id ? `/portfolios/${id}/transactions` : "/mutual_funds_transaction"
        }
        name={"ExportMutualFund"}
        filter={JSON.stringify(filterName?.customFilter)}
        paramsValue={{
          key: "inst_type",
          value: "MF",
        }}
      />
    );
  };

  return (
    <Grid container>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        searchPlaceholder="Search for Mutual Funds"
        maxHeight={"calc(100vh - 309px)"}
        columns={columns || []}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        name={name ? "portfolioMutualFunds" : "mutualFund"}
        onRowClick={(params) => {
          setMutualFundData(params.row);
          setOpenMutualFundModal(true);
        }}
        headerComponent={
          <Grid container gap={1} justifyContent={"end"}>
            {selectedIds?.length !== 0 && (
              <GKButton
                variant="outlined"
                size="medium"
                color="error"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              >
                Delete
              </GKButton>
            )}
            {selectedIds?.length !== 0 && (
              <GKButton
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => {
                  setSelectedIds([]);
                }}
              >
                Cancel
              </GKButton>
            )}
            {selectedIds?.length === 0 && (
              <GKButton
                variant="contained"
                size="medium"
                onClick={() => {
                  setMutualFundData({});
                  setOpenMutualFundModal(true);
                }}
                startIcon={<CgMathPlus size={14} />}
              >
                Add Trade
              </GKButton>
            )}
          </Grid>
        }
        tableName={tableName || "mutual_funds_list"}
        headerFilter={
          <TableFiltering name={name ? "portfolioMutualFunds" : "mutualFund"} />
        }
      />

      <Show.When isTrue={openMutualFundModal}>
        <MutualFundModal
          open={openMutualFundModal}
          setOpen={setOpenMutualFundModal}
          mutualFundData={mutualFundData}
          portfolioId={id}
        />
      </Show.When>

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete()}
          title={"Mutual Fund"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected entries & once deleted it cannot be reverted?`}
        />
      )}
    </Grid>
  );
};

export default MutualFunds;
