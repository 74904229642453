import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: any;
  refetch?: any;
}

interface FormValues {
  amount: string;
  user?: number;
}

const WalletTopupModal = (props: Props) => {
  const { open, setOpen, refetch } = props;

  const {
    state: {
      userData: { id },
    },
  } = useAppContext();

  const { mutate: handleWalletTopup } = useMutation({
    mutationFn: (data) => axiosInstance.post(`/wallet-top-up/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      amount: "",
      user: id,
    },
  });
  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"30%"}
      modalTitle={"Wallet Topup"}
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="wallet-topup-form"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid>
      }
    >
      <form
        id="wallet-topup-form"
        onSubmit={form.handleSubmit((values: any) => handleWalletTopup(values))}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name={"amount"}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel={"Amount"} />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default WalletTopupModal;
