import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { holdingsMainPageColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const HoldingsMainPage = () => {
  useDocumentTitle("Client Holdings");

  const { id } = useParams();

  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.clientHolding?.take,
    skip: commonFilter?.clientHolding?.skip,
    sort: JSON.stringify(
      commonFilter?.clientHolding?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.clientHolding?.search || "",
    show_zero_holding: commonFilter?.clientHolding?.showNonZero,
    filter: JSON.stringify(commonFilter?.clientHolding?.customFilter),
  };

  const {
    data: holdingTotalData,
    isFetching,
    isLoading,
  } = useFetchQuery({
    key: [QueryKeys.CLIENT_HOLDINGS, requestBody],
    route: `client/${id}/holdings/`,
    requestBody,
  });

  const totalCurrentAmount = () =>
    !isFetching &&
    holdingTotalData?.data?.data?.reduce(
      (acc: number, obj: any) => acc + obj.currentAmount,
      0
    );

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/client/${id}/holdings`}
        name={"ClientHoldingList"}
        filter={JSON.stringify(commonFilter?.clientHolding?.customFilter)}
      />
    );
  };

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={holdingTotalData?.data?.totalCount || 0}
        name="clientHolding"
      />
    );
  };

  return (
    <GKDataGrid
      searchPlaceholder="Search for Assets"
      loading={isFetching}
      columns={holdingsMainPageColumns(totalCurrentAmount())}
      rows={holdingTotalData?.data?.data || []}
      getRowId={(row) => row?.isin}
      zeroHolding
      // onRowClick={(row: any) => {
      //   navigate(`/app/holdings/${id}/transactions`, {
      //     state: {
      //       holdingId: id,
      //       name: row?.row?.name,
      //       gcode: row?.row?.gcode,
      //       currentAmount: row?.row?.currentAmount,
      //       investedAmount: row?.row?.investedAmount,
      //       quantity: row?.row?.quantity,
      //       type: "client",
      //     },
      //   });
      // }}
      exportCsv={exportCsv}
      maxHeight={"calc(100vh - 350px)"}
      CustomPagination={!isLoading && cusPagination}
      name="clientHolding"
      filterButton={false}
      tableName="client_holding_list"
      headerFilter={<TableFiltering name="clientHolding" />}
    />
  );
};

export default HoldingsMainPage;
