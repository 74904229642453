import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import StatusField from "components/StatusField";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

interface TradesOfPortfolioInterface {
  isConverted: boolean;
  id: string;
  nse: string;
  bse: string;
  company: string;
  quantity: number;
  price: number;
  transactionType: string;
  smallcaseStatus: string;
  smallcaseError: any;
  filledQuantity: number;
  orderDate: string;
}

interface Props {
  open: boolean;
  portfolioData: any;
  setOpen: (d: boolean) => void;
  allTradesOfPortfolio: TradesOfPortfolioInterface[];
}

const OrderPortfolioTradesModal = ({
  open,
  setOpen,
  allTradesOfPortfolio,
  portfolioData,
}: Props) => {
  const { ordetId } = useParams();

  const [selectedIds, setSelectedIds] = useState([]);

  const { mutate: handleConvertToTrade, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(
        `/model_portfolio/${ordetId}/order-manager-trade/${portfolioData?.id}/transaction-convert/`,
        data
      ),
  });
  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Portfolio Trades"
      width={"60%"}
      footer={
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() => {
            handleConvertToTrade({
              order_execution_id: selectedIds,
            });
          }}
        >
          Add to Trade Book{" "}
        </LoadingButton>
      }
    >
      <GKClientDataGrid
        rows={allTradesOfPortfolio || []}
        name="tradesOfPortfolio"
        density="compact"
        checkboxSelection={portfolioData?.sendingMode === "Gateway"}
        rowSelectionModel={selectedIds}
        maxHeight={`calc(100vh - 380px)`}
        pagination={allTradesOfPortfolio?.length > 25}
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              company: true,
              uniqueCode: true,
              transactionType: true,
              quantity: true,
              price: true,
              isConverted: true,
              executedQuantity: portfolioData?.sendingMode === "Gateway",
              smallcaseStatus: portfolioData?.sendingMode === "Gateway",
              smallcaseError: portfolioData?.sendingMode === "Gateway",
            },
          },
        }}
        columns={[
          {
            ...extraColumnsField("Company", "company", 0, 1),
          },
          {
            ...extraColumnsField("NSE/BSE Code", "uniqueCode", 0, 1),
            renderCell: (params) => {
              const { nse, bse } = params?.row;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography>{nse || "-"}</Typography>
                  <Typography>/</Typography>
                  <Typography>{bse || "-"}</Typography>
                </div>
              );
            },
          },
          { ...extraColumnsField("Transaction Type", "transactionType", 0, 1) },
          {
            ...extraColumnsField("Order Qty", "quantity", 0, 1),
            type: "number",
          },
          {
            ...extraColumnsField("Executed Qty", "executedQuantity", 0, 1),
            type: "number",
          },
          {
            ...extraColumnsField("Price", "price", 0, 1),
            type: "number",
            valueFormatter: (params) => moneyCommaSeparator(params?.value),
          },
          {
            ...extraColumnsField("Status", "smallcaseStatus", 150, 1),
            renderCell: (item) => item?.row?.smallcaseStatus || "-",
          },
          {
            ...extraColumnsField("Added to Trade Book", "isConverted", 0, 1),
            renderCell: (params) => {
              const { isConverted } = params?.row;
              return (
                <StatusField
                  statusString={isConverted ? "Completed" : "Pending"}
                />
              );
            },
          },
          {
            ...extraColumnsField("Error", "smallcaseError", 200, 1),
            renderCell: (item) => (
              <Typography sx={{ whiteSpace: "normal", fontSize: 12 }}>
                {item?.row?.smallcaseError || "-"}
              </Typography>
            ),
          },
        ]}
      />
    </GKModal>
  );
};

export default OrderPortfolioTradesModal;
