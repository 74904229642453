import { LoadingButton } from "@mui/lab";
import {
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller } from "react-hook-form";
import { FiTrash2 } from "react-icons/fi";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { extraColumnsField, validationForPercentage } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ModelAllocationCreatOrder from "./ModelAllocationCreatOrder";
import ModelCompanyAllocationCreatOrder from "./ModelCompanyCreateOrder";
import OrderRationaleModal from "./OrderRationaleModal";
import PortfolioWiseExit from "./PortfolioWiseExit";
import SelectPortfolioModal from "./SelectPortfolioModal";
import StockWiseRebalanceOrders from "./StockWiseRebalanceOrders";
import useModelAllocation from "./useModelAllocation";
import { moneyCommaSeparator } from "utils/MathFunction";

const ModelPortfoioAllocation = () => {
  const {
    theme,
    openPortfolioModal,
    setOpenPortfolioModal,
    fields,
    replace,
    companiesFields,
    companyReplace,
    form,
    formSubmit,
    allocationType,
    transactionType,
    companiesData,
    companiedFetching,
    allVersionsData,
    portfolioDataData,
    responseData,
    allocationByData,
    rebalanceRefrenceType,
    formCompanySubmit,
    setResponseData,
    isLoading,
    freezeData,
    setFreezeData,
    holdData,
    setHoldData,
    handleRecalculate,
    createLoading,
    handleCreateOrder,
    companyWiseRebalanceData,
    setCompanyWiseRebalanceData,
    handleRecalculateStockRebalance,
    handleCreateRebalanceOrder,
    allocationByExitData,
    openGenerateRationaleModal,
    setOpenGenerateRationaleModal,
    exitRefrenceType,
    weightageDistributionMode,
  } = useModelAllocation();

  useDocumentTitle("Equity Basket Allocation");

  const { state } = useLocation();

  const returnPortfolioTableColumns = (type: any) => {
    switch (type) {
      case "allocation":
        return [
          {
            ...extraColumnsField("Portfolio", "portfolioCode", 0, 1),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            ...extraColumnsField(
              form.getValues("rebalance_using") === "committedValue"
                ? "Committed Value"
                : "Ledger Balance",
              form.getValues("rebalance_using") === "committedValue"
                ? "committedValue"
                : "availableCash",
              0,
              1
            ),
            sortable: false,
            valueGetter: (val) => val.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val.value) || 0,
          },
          {
            ...extraColumnsField("Allocation Percentage", "percentage", 0, 1),
            sortable: false,
            renderCell: (params) => {
              const { index, totalCash, committedValue } = params?.row;

              const cashValue =
                form.getValues("rebalance_using") === "committedValue"
                  ? committedValue
                  : totalCash;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.percentage`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Allocation Percentage"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          percentage: +e.target.value,
                          cash:
                            (
                              (parseFloat(cashValue) *
                                parseFloat(e.target.value)) /
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Allocation Amount", "allocationAmount", 0, 1),
            sortable: false,
            renderCell: (params) => {
              const { index, committedValue, totalCash } = params?.row;

              const cashValue =
                form.getValues("rebalance_using") === "committedValue"
                  ? committedValue
                  : totalCash;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Amount"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          percentage:
                            (
                              (Number(e.target.value) / Number(cashValue)) *
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Action", "action", 0, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      case "rebalance":
        return [
          {
            ...extraColumnsField("Portfolio", "portfolioCode", 0, 1),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            ...extraColumnsField("Current Holdings", "currentAmount", 0, 1),
            sortable: false,
          },
          {
            ...extraColumnsField(
              "Cash for Rebalance",
              "cashForRebalance",
              0,
              1
            ),
            sortable: false,
            renderCell: (params) => {
              const { index, totalCash, currentAmount } = params.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  defaultValue={totalCash}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Cash for Rebalance"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          portfolioValue: +currentAmount + +e.target.value,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Total amount", "portfolioValue", 0, 1),
            sortable: false,
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.portfolioValue`}
                  render={({ field }) => (
                    <GKTextField
                      disabled
                      {...field}
                      placeholder="Cash for Rebalance"
                      type="number"
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Action", "action", 0),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      case "exit":
        return [
          {
            ...extraColumnsField("Portfolio", "portfolioCode", 0, 1),
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            ...extraColumnsField("Current Holdings", "currentAmount", 0, 1),
            sortable: false,
            valueGetter: (val) => val.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val.value),
          },
          {
            ...extraColumnsField("Exit Percentage", "percentage", 0, 1),
            sortable: false,
            renderCell: (params) => {
              const { index, currentAmount } = params?.row;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.percentage`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Exit Percentage"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          percentage: +e.target.value,
                          cash:
                            (
                              (parseFloat(currentAmount) *
                                parseFloat(e.target.value)) /
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Exit amount", "allocationAmount", 0, 1),
            sortable: false,
            renderCell: (params) => {
              const { index, currentAmount } = params?.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      placeholder="Exit Amount"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          percentage:
                            (
                              (Number(e.target.value) / Number(currentAmount)) *
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            ...extraColumnsField("Action", "action", 0, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      default:
        return [];
    }
  };

  return (
    <DashboardLayout title="Basket Allocation">
      <form
        onSubmit={form.handleSubmit(
          form.getValues?.("allocationBy") === "company" ||
            form.getValues?.("allocationBy") === "companyWeightage"
            ? formCompanySubmit
            : formSubmit
        )}
      >
        <Card>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography fontWeight={600} color={theme.palette.primary.main}>
                {state?.name || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={form.control}
                name="allocationType"
                render={({ field }) => (
                  <Grid container gap={1} alignItems={"center"}>
                    <FormLabel
                      sx={{
                        color: form.formState.errors.allocationType
                          ? theme.palette.error.main
                          : theme.palette.primary.main,
                      }}
                    >
                      Select Allocation Type
                    </FormLabel>
                    {allocationType?.map((transData) => {
                      return (
                        <Chip
                          key={transData.value}
                          sx={{
                            mr: 0.5,
                          }}
                          onClick={() => {
                            form.reset();
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                            setTimeout(() => {
                              if (transData.value === "rebalance") {
                                form.setValue("allocationBy", "version");
                              }
                              field.onChange(transData.value);
                            }, 100);
                          }}
                          color={
                            transData.value === form.getValues("allocationType")
                              ? "primary"
                              : "default"
                          }
                          icon={
                            transData.value ===
                            form.getValues("allocationType") ? (
                              <IoMdRadioButtonOn size={18} />
                            ) : (
                              <IoMdRadioButtonOff size={18} />
                            )
                          }
                          label={transData.name}
                          variant={
                            transData.value === form.getValues("allocationType")
                              ? "filled"
                              : "outlined"
                          }
                        />
                      );
                    })}
                  </Grid>
                )}
              />
            </Grid>
            {/** REBALANCE TYPE================= */}
            {form.getValues("allocationType") === "rebalance" && (
              <Grid item xs={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Portfolio Wise Rebalance</Typography>
                  <Controller
                    name="rebalanceType"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <Switch
                          {...field}
                          onChange={(e) => {
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                            form.setValue("companies", []);
                            form.setValue("transactionType", "");
                            form.setValue("portfolios", []);
                            field.onChange(e.target.checked);
                          }}
                        />
                      );
                    }}
                  />
                  <Typography>Stock Wise Rebalance</Typography>
                </Stack>
              </Grid>
            )}
            {form.getValues("allocationType") !== "rebalance" && (
              <Grid item xs={6} />
            )}
            <Grid item xs={12} />
            {/** ALLOCATION BY WITHOUT EXIT ================== */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationType") === "allocation" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="allocationBy"
                    render={({ field }) => (
                      <GKSearchSelect
                        disableClearable
                        loading={companiedFetching}
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          form.resetField("companies");
                          form.resetField("portfolios");
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={
                          allocationByData?.filter((item) =>
                            form.getValues("allocationType") === "rebalance"
                              ? item?.value !== "company"
                              : item
                          ) || []
                        }
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Allocation By"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Allocation By"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** ALLOCATION BY WITHOUT EXIT ================== */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationType") === "exit" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="allocationBy"
                    render={({ field }) => (
                      <GKSearchSelect
                        disableClearable
                        loading={companiedFetching}
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={allocationByExitData || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Exit By"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Exit By"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** Version Refrence Weightage ===== */}
            {form.getValues("allocationBy") !== "" &&
              form.getValues("allocationType") !== "exit" &&
              form.getValues("allocationBy") === "version" && (
                <Grid item xs={3}>
                  <Controller
                    control={form.control}
                    name="transactionType"
                    render={({ field }) => (
                      <GKSearchSelect
                        disableClearable
                        loading={companiedFetching}
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={transactionType || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Model Reference Weightage"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Model Reference Weightage"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** Rebalance/Allocation Refrence Weightage === */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationBy") !== "" &&
              form.getValues("allocationType") !== "exit" && (
                <Grid item xs={3}>
                  <Controller
                    control={form.control}
                    name="rebalance_using"
                    render={({ field }) => (
                      <GKSearchSelect
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={rebalanceRefrenceType?.filter((da) =>
                          form.getValues("allocationType") === "allocation" &&
                          form.getValues("allocationBy") !== "company"
                            ? da.value === "committedValue" ||
                              da.value === "ledgerBalance"
                            : da
                        )}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel={`${form.getValues(
                          "allocationType"
                        )} Reference Value`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder={`${
                              form.getValues("allocationType") === "allocation"
                                ? "Allocation"
                                : "Rebalance"
                            } Reference Value`}
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** Rebalance Refrence Weightage === */}
            {form.getValues("allocationType") !== " " &&
              form.getValues("allocationBy") === "companyWeightage" &&
              form.getValues("allocationType") === "exit" && (
                <Grid item xs={3}>
                  <Controller
                    control={form.control}
                    name="rebalance_using"
                    render={({ field }) => (
                      <GKSearchSelect
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={exitRefrenceType}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel={`Exit Reference Value`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder={`${
                              form.getValues("allocationType") === "allocation"
                                ? "Allocation"
                                : "Rebalance"
                            } Reference Value`}
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** Rebalance Refrence Weightage === */}
            {(form.getValues("allocationType") === "allocation" ||
              form.getValues("allocationType") === "rebalance") &&
              form.getValues("allocationBy") !== "" &&
              form.getValues("allocationBy") === "version" &&
              form.getValues("allocationType") !== "exit" && (
                <Grid item xs={3}>
                  <Controller
                    control={form.control}
                    name="weightageDistributionMethod"
                    render={({ field }) => (
                      <GKSearchSelect
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={weightageDistributionMode}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel={`Weightage Distribution Method`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={`Weightage Distribution Method`}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** REBALANCE COMPANY DD============ */}
            {form.getValues("allocationType") === "rebalance" &&
              form.getValues("rebalanceType") && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="companies"
                    render={({ field }) => (
                      <GKSearchSelect
                        limitTags={2}
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                          companyReplace(val);
                        }}
                        options={companiesData?.data?.data || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Select Companies"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Select Companies"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** COMPANY DD====================== */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationBy") === "company" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="companies"
                    render={({ field }) => (
                      <GKSearchSelect
                        multiple
                        limitTags={1}
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          companyReplace(val);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={companiesData?.data?.data || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Select Companies"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Select Companies"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** COMPANY DD====================== */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationBy") === "companyWeightage" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="companies"
                    render={({ field }) => (
                      <GKSearchSelect
                        multiple
                        limitTags={1}
                        loading={companiedFetching}
                        disableClearable
                        onChange={(e, val) => {
                          companyReplace(val);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        options={companiesData?.data?.data || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel="Select Companies"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Select Companies"
                            error={Boolean(form.formState.errors.companies)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** VERSION DD======================= */}
            {form.getValues("allocationType") !== "" &&
              form.getValues("allocationBy") === "version" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="version"
                    render={({ field }) => (
                      <GKSearchSelect
                        onChange={(e, val) => {
                          field.onChange(val);
                          setResponseData([]);
                          setCompanyWiseRebalanceData([]);
                        }}
                        loading={companiedFetching}
                        options={allVersionsData?.data?.data || []}
                        getOptionLabel={(option) => option?.version || option}
                        inputLabel="Select Model"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Select Model"
                            error={Boolean(form.formState.errors.version)}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            {/** WEIGHTAGE TEXTFIELD======================= */}
            {!form.getValues("rebalanceType") &&
              form.getValues("allocationBy") === "version" &&
              form.getValues("allocationType") !== "" &&
              form.getValues("allocationType") === "exit" && (
                <Grid item xs={2}>
                  <Controller
                    control={form.control}
                    name="exitWeightage"
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          inputLabel="Weightage"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
            {/** PORTOLIO DD======================= */}
            {form.getValues("allocationBy") !== "" &&
              form.getValues("allocationType") !== "" && (
                <Grid item xs={2} mt={"16px"}>
                  <GKButton
                    variant="contained"
                    size="large"
                    className="extra-large"
                    fullWidth
                    onClick={() => {
                      setOpenPortfolioModal(true);
                    }}
                  >
                    Select Portfolio
                  </GKButton>
                </Grid>
              )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              {(companyWiseRebalanceData?.length !== 0 ||
                responseData?.length !== 0) && (
                <LoadingButton
                  onClick={() => {
                    setOpenGenerateRationaleModal(true);
                  }}
                  color="dark"
                  variant="outlined"
                  loading={createLoading}
                >
                  Generate Trade Order Sheet
                </LoadingButton>
              )}
              {companyWiseRebalanceData?.length === 0 &&
                responseData?.length === 0 && (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={isLoading}
                  >
                    Calculate
                  </LoadingButton>
                )}
              {(companyWiseRebalanceData?.length !== 0 ||
                responseData?.length !== 0) && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={
                    form?.getValues("rebalanceType")
                      ? handleRecalculateStockRebalance
                      : handleRecalculate
                  }
                  loading={isLoading}
                >
                  Re-Calculate
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Card>

        {/** COMPANY TABLE================== */}
        {responseData?.length === 0 &&
          !form.getValues("rebalanceType") &&
          form.getValues("companies").length !== 0 &&
          (form.getValues("allocationBy") === "company" ||
            form.getValues("allocationBy") === "companyWeightage") &&
          form.getValues("allocationType") !== "" && (
            <GKClientDataGrid
              name="companyAllocation"
              showHeaderGrid={false}
              maxHeight={
                form.getValues("allocationType") !== "rebalance"
                  ? `calc(100vh - 498px)`
                  : `calc(100vh - 482px)`
              }
              getRowId={(op) => op?.gridCode}
              rows={
                companiesFields?.map((da, i) => {
                  return {
                    ...da,
                    index: i,
                    limitValue: da.limitValue || "",
                  };
                }) || []
              }
              columns={[
                {
                  ...extraColumnsField("Company", "name", 0, 1),
                },
                {
                  ...extraColumnsField("Weightage", "weightage", 0, 1),
                  renderCell: (params) => {
                    const { index } = params?.row;

                    return (
                      <Controller
                        control={form.control}
                        rules={{
                          max: 100,
                        }}
                        name={`companies.${index}.weightage`}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            type="number"
                            placeholder="Weightage"
                            onChange={(e) => {
                              field.onChange(
                                validationForPercentage(+e.target.value)
                              );
                            }}
                          />
                        )}
                      />
                    );
                  },
                },
                // Conditionally add the Limit value column
                ...(form.getValues("allocationType") === "allocation"
                  ? [
                      {
                        ...extraColumnsField("Limit value", "limitValue", 0, 1),
                        renderCell: (params: { row: { index: any } }) => {
                          const { index } = params?.row;
                          return (
                            <Controller
                              control={form.control}
                              name={`companies.${index}.limitValue`}
                              render={({ field }) => (
                                <GKTextField
                                  {...field}
                                  type="number"
                                  placeholder="Limit value"
                                  onChange={(e) => {
                                    field.onChange(Number(e.target.value));
                                  }}
                                />
                              )}
                            />
                          );
                        },
                      },
                    ]
                  : []),
              ]}
            />
          )}
        {/** PORTFOLIO TABLE ================== */}
        {!form.getValues("rebalanceType") &&
          responseData?.length === 0 &&
          form.getValues("portfolios").length !== 0 &&
          form.getValues("allocationBy") === "version" &&
          form.getValues("allocationType") !== "" &&
          form.getValues("allocationType") !== "exit" && (
            <GKClientDataGrid
              name="portfolioAllocation"
              showHeaderGrid={false}
              maxHeight={
                form.getValues("allocationType") !== "rebalance"
                  ? `calc(100vh - 458px)`
                  : `calc(100vh - 482px)`
              }
              getRowId={(op) => op?.portfolioId}
              rows={
                fields?.map((da: any, i: any) => {
                  return {
                    ...da,
                    index: i,
                  };
                }) || []
              }
              columns={returnPortfolioTableColumns(
                form.getValues("allocationType")
              )}
            />
          )}
        {/** PORTFOLIO TABLE ================== */}
        {!form.getValues("rebalanceType") &&
          responseData?.length === 0 &&
          form.getValues("portfolios").length !== 0 &&
          form.getValues("allocationBy") === "portfolio" &&
          form.getValues("allocationType") !== "" &&
          form.getValues("allocationType") === "exit" && (
            <GKClientDataGrid
              name="portfolioRebalanceAllocation"
              showHeaderGrid={false}
              maxHeight={
                form.getValues("allocationType") !== "rebalance"
                  ? `calc(100vh - 546px)`
                  : `calc(100vh - 482px)`
              }
              getRowId={(op) => op?.portfolioId}
              rows={
                fields?.map((da: any, i: any) => {
                  return {
                    ...da,
                    index: i,
                  };
                }) || []
              }
              columns={[
                {
                  ...extraColumnsField("Portfolio", "portfolioCode", 0, 1),
                  renderCell: (params) => {
                    return (
                      <div>
                        <Typography fontWeight={700} whiteSpace={"nowrap"}>
                          {params?.row?.portfolioName}
                        </Typography>
                        <Typography whiteSpace={"nowrap"}>
                          {params?.row?.portfolioCode}
                        </Typography>{" "}
                      </div>
                    );
                  },
                },
                {
                  ...extraColumnsField("Exit Weightage", "exitWeightage", 0, 1),

                  renderCell: (params) => {
                    const { index } = params?.row;
                    return (
                      <Controller
                        control={form.control}
                        name={`portfolios.${index}.exitWeightage`}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            placeholder="Amount"
                            type="number"
                            onChange={(e) => {
                              form.setValue(`portfolios.${index}`, {
                                ...params.row,
                                exitWeightage: +e.target.value,
                              });
                            }}
                          />
                        )}
                      />
                    );
                  },
                },
                {
                  ...extraColumnsField("Action", "action", 0, 0),
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => {
                    const { index } = params?.row;
                    return (
                      <IconButton
                        color="error"
                        className="outline"
                        onClick={() => {
                          const newArr = form
                            .getValues("portfolios")
                            ?.filter((d, i) => i !== index);
                          replace(newArr);
                        }}
                      >
                        <FiTrash2 />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          )}

        {/** RESPONSE TABLE================================================== */}
        {form.getValues("allocationBy") === "version" &&
          !form.getValues("rebalanceType") &&
          responseData?.length !== 0 && (
            <Grid item xs={12}>
              <ModelAllocationCreatOrder
                responseData={responseData}
                form={form}
                freezeData={freezeData}
                setFreezeData={setFreezeData}
                holdData={holdData}
                setHoldData={setHoldData}
              />
            </Grid>
          )}
        {/** RESPONSE TABLE================== */}
        {form.getValues("allocationBy") === "portfolio" &&
          !form.getValues("rebalanceType") &&
          responseData?.length !== 0 && (
            <Grid item xs={12}>
              <PortfolioWiseExit
                responseData={responseData}
                form={form}
                freezeData={freezeData}
                setFreezeData={setFreezeData}
                holdData={holdData}
                setHoldData={setHoldData}
              />
            </Grid>
          )}
        {/** RESPONSE TABLE================== */}
        {(form.getValues("allocationBy") === "company" ||
          form.getValues("allocationBy") === "companyWeightage") &&
          !form.getValues("rebalanceType") &&
          responseData?.length !== 0 && (
            <Grid item xs={12}>
              <ModelCompanyAllocationCreatOrder
                responseData={responseData}
                form={form}
                freezeData={freezeData}
                setFreezeData={setFreezeData}
                holdData={holdData}
                setHoldData={setHoldData}
              />
            </Grid>
          )}
        {/** REBALANCE COMPANY RESPONSE TABLE================== */}
        {form.getValues("rebalanceType") &&
          companyWiseRebalanceData?.length !== 0 && (
            <StockWiseRebalanceOrders
              setCompanyWiseRebalanceData={setCompanyWiseRebalanceData}
              companyWiseRebalanceData={companyWiseRebalanceData || []}
            />
          )}
      </form>
      {openPortfolioModal && (
        <SelectPortfolioModal
          open={openPortfolioModal}
          setOpen={setOpenPortfolioModal}
          data={portfolioDataData?.data?.data}
          replace={replace}
          form={form}
          setResponseData={setResponseData}
          setCompanyWiseRebalanceData={setCompanyWiseRebalanceData}
        />
      )}

      {openGenerateRationaleModal && (
        <OrderRationaleModal
          open={openGenerateRationaleModal}
          setOpen={setOpenGenerateRationaleModal}
          form={form}
          submit={() => {
            form?.getValues("rebalanceType")
              ? handleCreateRebalanceOrder()
              : handleCreateOrder();
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default ModelPortfoioAllocation;
