import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
}

const EditFamilyModal = (props: Props) => {
  const { open, setOpen, data } = props;

  const { id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["UPDATE_FAMILY"],
    mutationFn: (data) => axiosInstance.patch(`/family/${id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.FAMILY_LIST);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      name: data?.name || "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"40%"}
      modalTitle={"Edit Family"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          form="edit-family-form"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="edit-family-form"
        onSubmit={form.handleSubmit((values: any) => mutate(values))}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Name is required",
                },
                validate: validateNotWhitespaceOnly,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth variant="standard">
                  <GKTextField
                    {...field}
                    inputLabel={"Family Name"}
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default EditFamilyModal;
