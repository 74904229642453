/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import ClientOnboardingRiskModal from "./ClientOnboardingRiskModal";

interface Props {
  questionAnswers: any;
  setQuestionAnswers: any;
  questionValidation: any;
  setQuestionValidation: any;
  data: any;
  handleNext: any;
}

const ClientOnboardingRisk = ({
  questionAnswers,
  setQuestionAnswers,
  questionValidation,
  setQuestionValidation,
  data,
  handleNext,
}: Props) => {
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const [openRiskModal, setOpenRiskModal] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
    onSuccess: (res: any) => {
      handleNext();
      toast.success(res?.data?.message || "Action Performed Successfully");
    },
  });

  const handleOptionChange = (questionId: any, optionItem: any) => {
    const dataToSubmit = {
      question: questionId,
      option: optionItem.id,
      optionPoints: optionItem.optionPoints,
    };

    const isExistQuestion = questionAnswers
      .map((item: any) => item.question)
      .includes(dataToSubmit.question);

    if (isExistQuestion) {
      const indexToRemove = questionAnswers.findIndex(
        (item: any) => item.question === dataToSubmit.question
      );

      if (indexToRemove !== -1) {
        questionAnswers.splice(indexToRemove, 1);
      }
    }

    setQuestionAnswers((prevValue: any) => [...prevValue, dataToSubmit]);

    const updatedValidation = { ...questionValidation, [questionId]: true };
    setQuestionValidation(updatedValidation);
  };

  return (
    <>
      {form.getValues("riskProfile") ? (
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={600}>
              Risk Profile
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              variant="contained"
              onClick={() => setOpenRiskModal(true)}
              startIcon={<CgMathPlus size={14} />}
            >
              Risk Assessment
            </GKButton>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography fontSize={16} fontWeight={700}>
                    {form.getValues("personalDetails.name") || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReactSpeedometer
                    maxSegmentLabels={3}
                    height={200}
                    labelFontSize="12"
                    ringWidth={30}
                    segmentColors={[
                      "rgb(100, 240, 1)",
                      "rgb(101, 240, 9)",
                      "rgb(102, 240, 1)",
                      "rgb(103, 240, 1)",
                      "rgb(104, 240, 1)",
                      "rgb(105, 240, 1)",
                      "rgb(106, 240, 1)",
                      "rgb(107, 240, 1)",
                      "rgb(108, 240, 1)",
                      "rgb(109, 240, 1)",
                      "rgb(110, 240, 1)",
                      "rgb(111, 240, 1)",
                      "rgb(112, 240, 1)",
                      "rgb(113, 240, 1)",
                      "rgb(114, 240, 1)",
                      "rgb(115, 240, 1)",
                      "rgb(116, 240, 1)",
                      "rgb(117, 240, 1)",
                      "rgb(118, 240, 1)",
                      "rgb(119, 240, 1)",
                      "rgb(120, 240, 1)",
                      "rgb(121, 240, 1)",
                      "rgb(122, 240, 1)",
                      "rgb(123, 240, 1)",
                      "rgb(124, 240, 1)",
                      "rgb(125, 240, 1)",
                      "rgb(126, 240, 1)",
                      "rgb(127, 240, 1)",
                      "rgb(128, 240, 1)",
                      "rgb(129, 240, 1)",
                      "rgb(130, 240, 1)",
                      "rgb(131, 240, 1)",
                      "rgb(132, 240, 1)",
                      "rgb(133, 240, 1)",
                      "rgb(134, 240, 1)",
                      "rgb(135, 240, 1)",
                      "rgb(136, 240, 1)",
                      "rgb(137, 240, 1)",
                      "rgb(138, 240, 1)",
                      "rgb(139, 240, 1)",
                      "rgb(140, 240, 1)",
                      "rgb(141, 240, 1)",
                      "rgb(142, 240, 1)",
                      "rgb(143, 240, 1)",
                      "rgb(144, 240, 1)",
                      "rgb(145, 240, 1)",
                      "rgb(146, 240, 1)",
                      "rgb(147, 240, 1)",
                      "rgb(148, 240, 1)",
                      "rgb(149, 240, 1)",
                      "rgb(150, 240, 1)",
                      "rgb(151, 240, 1)",
                      "rgb(152, 240, 1)",
                      "rgb(153, 240, 1)",
                      "rgb(154, 240, 1)",
                      "rgb(155, 240, 1)",
                      "rgb(156, 240, 1)",
                      "rgb(157, 240, 1)",
                      "rgb(158, 240, 1)",
                      "rgb(159, 240, 1)",
                      "rgb(160, 240, 1)",
                      "rgb(161, 240, 1)",
                      "rgb(162, 240, 1)",
                      "rgb(163, 240, 1)",
                      "rgb(164, 240, 1)",
                      "rgb(165, 240, 1)",
                      "rgb(166, 240, 1)",
                      "rgb(167, 240, 1)",
                      "rgb(168, 240, 1)",
                      "rgb(169, 240, 1)",
                      "rgb(170, 240, 1)",
                      "rgb(171, 240, 1)",
                      "rgb(172, 240, 1)",
                      "rgb(173, 240, 1)",
                      "rgb(174, 240, 1)",
                      "rgb(175, 240, 1)",
                      "rgb(176, 240, 1)",
                      "rgb(177, 240, 1)",
                      "rgb(178, 240, 1)",
                      "rgb(179, 240, 1)",
                      "rgb(180, 240, 1)",
                      "rgb(181, 240, 1)",
                      "rgb(182, 240, 1)",
                      "rgb(183, 240, 1)",
                      "rgb(184, 240, 1)",
                      "rgb(185, 240, 1)",
                      "rgb(186, 240, 1)",
                      "rgb(187, 240, 1)",
                      "rgb(188, 240, 1)",
                      "rgb(189, 240, 1)",
                      "rgb(190, 240, 1)",
                      "rgb(191, 240, 1)",
                      "rgb(192, 240, 1)",
                      "rgb(193, 240, 1)",
                      "rgb(194, 240, 1)",
                      "rgb(195, 240, 1)",
                      "rgb(196, 240, 1)",
                      "rgb(197, 240, 1)",
                      "rgb(198, 240, 1)",
                      "rgb(199, 240, 1)",
                      "rgb(200, 240, 1)",
                      "rgb(201, 240, 1)",
                      "rgb(202, 240, 1)",
                      "rgb(203, 240, 1)",
                      "rgb(204, 240, 1)",
                      "rgb(205, 240, 1)",
                      "rgb(206, 240, 1)",
                      "rgb(207, 240, 1)",
                      "rgb(208, 240, 1)",
                      "rgb(209, 240, 1)",
                      "rgb(210, 240, 1)",
                      "rgb(211, 240, 1)",
                      "rgb(212, 240, 1)",
                      "rgb(213, 240, 1)",
                      "rgb(214, 240, 1)",
                      "rgb(215, 240, 1)",
                      "rgb(216, 240, 1)",
                      "rgb(217, 240, 1)",
                      "rgb(218, 240, 1)",
                      "rgb(219, 240, 1)",
                      "rgb(220, 240, 1)",
                      "rgb(221, 240, 1)",
                      "rgb(222, 240, 1)",
                      "rgb(223, 240, 1)",
                      "rgb(224, 240, 1)",
                      "rgb(225, 240, 1)",
                      "rgb(226, 240, 1)",
                      "rgb(227, 240, 1)",
                      "rgb(228, 240, 1)",
                      "rgb(229, 240, 1)",
                      "rgb(230, 240, 1)",
                      "rgb(231, 240, 1)",
                      "rgb(232, 240, 1)",
                      "rgb(233, 240, 1)",
                      "rgb(234, 240, 1)",
                      "rgb(235, 240, 1)",
                      "rgb(236, 240, 1)",
                      "rgb(237, 240, 1)",
                      "rgb(238, 240, 1)",
                      "rgb(239, 240, 1)",
                      "rgb(240, 240, 1)",
                      "rgb(241, 240, 1)",
                      "rgb(242, 240, 1)",
                      "rgb(243, 240, 1)",
                      "rgb(244, 240, 1)",
                      "rgb(245, 240, 1)",
                      "rgb(246, 240, 1)",
                      "rgb(247, 240, 1)",
                      "rgb(248, 240, 1)",
                      "rgb(249, 240, 1)",
                      "rgb(250, 240, 1)",
                      "rgb(251, 240, 1)",
                      "rgb(252, 240, 1)",
                      "rgb(253, 240, 1)",
                      "rgb(254, 240, 1)",
                      "rgb(255, 240, 1)",
                      "rgb(255, 240, 1)",
                      "rgb(255, 239, 1)",
                      "rgb(255, 238, 1)",
                      "rgb(255, 237, 1)",
                      "rgb(255, 236, 1)",
                      "rgb(255, 235, 1)",
                      "rgb(255, 234, 1)",
                      "rgb(255, 233, 1)",
                      "rgb(255, 232, 1)",
                      "rgb(255, 231, 1)",
                      "rgb(255, 230, 1)",
                      "rgb(255, 229, 1)",
                      "rgb(255, 228, 1)",
                      "rgb(255, 227, 1)",
                      "rgb(255, 226, 1)",
                      "rgb(255, 225, 1)",
                      "rgb(255, 224, 1)",
                      "rgb(255, 223, 1)",
                      "rgb(255, 222, 1)",
                      "rgb(255, 221, 1)",
                      "rgb(255, 220, 1)",
                      "rgb(255, 219, 1)",
                      "rgb(255, 218, 1)",
                      "rgb(255, 217, 1)",
                      "rgb(255, 216, 1)",
                      "rgb(255, 215, 1)",
                      "rgb(255, 214, 1)",
                      "rgb(255, 213, 1)",
                      "rgb(255, 212, 1)",
                      "rgb(255, 211, 1)",
                      "rgb(255, 210, 1)",
                      "rgb(255, 209, 1)",
                      "rgb(255, 208, 1)",
                      "rgb(255, 207, 1)",
                      "rgb(255, 206, 1)",
                      "rgb(255, 205, 1)",
                      "rgb(255, 204, 1)",
                      "rgb(255, 203, 1)",
                      "rgb(255, 202, 1)",
                      "rgb(255, 201, 1)",
                      "rgb(255, 200, 1)",
                      "rgb(255, 199, 1)",
                      "rgb(255, 198, 1)",
                      "rgb(255, 197, 1)",
                      "rgb(255, 196, 1)",
                      "rgb(255, 195, 1)",
                      "rgb(255, 194, 1)",
                      "rgb(255, 193, 1)",
                      "rgb(255, 192, 1)",
                      "rgb(255, 191, 1)",
                      "rgb(255, 190, 1)",
                      "rgb(255, 189, 1)",
                      "rgb(255, 188, 1)",
                      "rgb(255, 187, 1)",
                      "rgb(255, 186, 1)",
                      "rgb(255, 185, 1)",
                      "rgb(255, 184, 1)",
                      "rgb(255, 183, 1)",
                      "rgb(255, 182, 1)",
                      "rgb(255, 181, 1)",
                      "rgb(255, 180, 1)",
                      "rgb(255, 179, 1)",
                      "rgb(255, 178, 1)",
                      "rgb(255, 177, 1)",
                      "rgb(255, 176, 1)",
                      "rgb(255, 175, 1)",
                      "rgb(255, 174, 1)",
                      "rgb(255, 173, 1)",
                      "rgb(255, 172, 1)",
                      "rgb(255, 171, 1)",
                      "rgb(255, 170, 1)",
                      "rgb(255, 169, 1)",
                      "rgb(255, 168, 1)",
                      "rgb(255, 167, 1)",
                      "rgb(255, 166, 1)",
                      "rgb(255, 165, 1)",
                      "rgb(255, 164, 1)",
                      "rgb(255, 163, 1)",
                      "rgb(255, 162, 1)",
                      "rgb(255, 161, 1)",
                      "rgb(255, 160, 1)",
                      "rgb(255, 159, 1)",
                      "rgb(255, 158, 1)",
                      "rgb(255, 157, 1)",
                      "rgb(255, 156, 1)",
                      "rgb(255, 155, 1)",
                      "rgb(255, 154, 1)",
                      "rgb(255, 153, 1)",
                      "rgb(255, 152, 1)",
                      "rgb(255, 151, 1)",
                      "rgb(255, 150, 1)",
                      "rgb(255, 149, 1)",
                      "rgb(255, 148, 1)",
                      "rgb(255, 147, 1)",
                      "rgb(255, 146, 1)",
                      "rgb(255, 145, 1)",
                      "rgb(255, 144, 1)",
                      "rgb(255, 143, 1)",
                      "rgb(255, 142, 1)",
                      "rgb(255, 141, 1)",
                      "rgb(255, 140, 1)",
                      "rgb(255, 139, 1)",
                      "rgb(255, 138, 1)",
                      "rgb(255, 137, 1)",
                      "rgb(255, 136, 1)",
                      "rgb(255, 135, 1)",
                      "rgb(255, 134, 1)",
                      "rgb(255, 133, 1)",
                      "rgb(255, 132, 1)",
                      "rgb(255, 131, 1)",
                      "rgb(255, 130, 1)",
                      "rgb(255, 129, 1)",
                      "rgb(255, 128, 1)",
                      "rgb(255, 127, 1)",
                      "rgb(255, 126, 1)",
                      "rgb(255, 125, 1)",
                      "rgb(255, 124, 1)",
                      "rgb(255, 123, 1)",
                      "rgb(255, 122, 1)",
                      "rgb(255, 121, 1)",
                      "rgb(255, 120, 1)",
                      "rgb(255, 119, 1)",
                      "rgb(255, 118, 1)",
                      "rgb(255, 117, 1)",
                      "rgb(255, 116, 1)",
                      "rgb(255, 115, 1)",
                      "rgb(255, 114, 1)",
                      "rgb(255, 113, 1)",
                      "rgb(255, 112, 1)",
                      "rgb(255, 111, 1)",
                      "rgb(255, 110, 1)",
                      "rgb(255, 109, 1)",
                      "rgb(255, 108, 1)",
                      "rgb(255, 107, 1)",
                      "rgb(255, 106, 1)",
                      "rgb(255, 105, 1)",
                      "rgb(255, 104, 1)",
                      "rgb(255, 103, 1)",
                      "rgb(255, 102, 1)",
                      "rgb(255, 101, 1)",
                      "rgb(255, 100, 1)",
                    ]}
                    customSegmentLabels={[
                      ...[
                        {
                          text: "  ",
                          color: paletteLightTheme.palette.dark.main,
                          position: "OUTSIDE" as any,
                        },
                        {
                          text: " ",
                          color: paletteLightTheme.palette.dark.main,
                          position: "OUTSIDE" as any,
                        },
                        {
                          text: " ",
                          position: "OUTSIDE" as any,

                          color: paletteLightTheme.palette.dark.main,
                        },
                      ],
                      ...Array.from({ length: 293 }, () => ({
                        text: " ",
                        color: paletteLightTheme.palette.dark.main,
                        position: "OUTSIDE",
                      })),
                    ].flat()}
                    needleHeightRatio={0.7}
                    segments={296}
                    width={350}
                    value={form.getValues("riskProfile.points")}
                    maxValue={form.getValues("riskProfile.totalPoints")}
                    currentValueText=" "
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6} mt={"60px"}>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={"#697586"}
                    >
                      Risk Score{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"} mt={"60px"}>
                    <Typography fontWeight={600} fontSize={16}>
                      {form.getValues("riskProfile.points")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={10}
                      fontWeight={400}
                      color={"#9AA4B2"}
                    >
                      {`(Min Value: 0 - Max Value : ${
                        form.getValues("riskProfile.totalPoints") || 0
                      })`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={"20px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={"#697586"}
                    >
                      Risk Status
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {form.getValues("riskProfile.riskStatus")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={600}>
              Risk Profile
            </Typography>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => {
                mutate(form.getValues("id"));
              }}
            >
              Send To Client
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            {data?.data?.data?.data?.riskQuestions?.map(
              (dataItem: any, index: number) => {
                return (
                  <Grid item container key={dataItem?.id}>
                    <Grid item xs={12}>
                      <FormControl>
                        <Typography
                          color={theme.palette.primary.main}
                          fontWeight={800}
                        >
                          <span>{index + 1}. </span>
                          {dataItem.questionText}
                        </Typography>
                        <RadioGroup>
                          {dataItem?.options?.map((optionItem: any) => {
                            return (
                              <FormControlLabel
                                key={optionItem?.id}
                                value={optionItem?.id}
                                onChange={() => {
                                  handleOptionChange(dataItem.id, {
                                    ...optionItem,
                                  });
                                }}
                                control={<Radio />}
                                label={optionItem?.option}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      )}

      {openRiskModal && (
        <ClientOnboardingRiskModal
          setOpen={setOpenRiskModal}
          open={openRiskModal}
          form={form}
        />
      )}
    </>
  );
};

export default ClientOnboardingRisk;
