import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { addPortfolioGroupSchema } from "utils/validationSchema";

const useAddPortfolioGroup = ({ setOpen }: any) => {
  const {
    state: { portfolios },
  } = useAppContext();

  // =================================================================================================
  const { mutate: handleAddGroup, isLoading: addGroupLoading } = useMutation({
    mutationFn: (state: any) =>
      axiosInstance.post(`/portfolio-groups/`, {
        ...state,
        portfolios: state?.portfolios?.map((data: any) => data?.value),
      }),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.PORTFOLIO_GROUP_LIST);
      refetchQuery("FETCH_ALL_PORTFOLIO_GROUP");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // =================================================================================================
  const form = useForm({
    defaultValues: {
      name: "",
      portfolios: [],
    },
    resolver: yupResolver(addPortfolioGroupSchema),
  });

  const onSubmit = (values: any) => handleAddGroup(values);

  return {
    portfolios,
    addGroupLoading,
    form,
    onSubmit,
  };
};

export default useAddPortfolioGroup;
