/* eslint-disable no-bitwise */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { List, ListItem, Typography } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsSunburst from "highcharts/modules/sunburst";
import _ from "lodash";
import { ThemeContainer } from "utils/Theme";
import { PieChartColors } from "utils/constants";

HighchartsSunburst(Highcharts);

const HoldingsSunburst = ({ form }: any) => {
  const { theme } = ThemeContainer();

  // ==============================================================================================================================
  const allCompaniesData =
    form
      ?.filter((dataItem: any) => dataItem?.isin !== "INGridkeyCash")
      ?.map((dataItem: any, index: any) => {
        return {
          parent: dataItem?.cap || "small",
          value: +dataItem?.weightage || 0,
          color: PieChartColors[index < 12 ? index : index % 12],
          id: dataItem?.name || "1.2",
          name: dataItem?.name || "1.2",
        };
      }) || [];
  const capData =
    _.uniqBy(
      form
        ?.filter((dataItem: any) => dataItem?.isin !== "INGridkeyCash")
        ?.map((dataItem: any, index: any) => {
          const filterSector = form?.filter(
            (item: any) => item?.cap === dataItem?.cap
          );

          const sumData = _.sum(
            filterSector?.map((vData: any) => +vData?.weightage)
          );

          return {
            parent: "cap",
            value: sumData || 0,
            color: PieChartColors[index < 12 ? index : index % 12],
            id: dataItem?.cap || "small",
            name: dataItem?.cap || "small",
          };
        }),
      "id"
    ) || [];

  // ==============================================================================================================================
  const chartSunburstOptions = {
    title: {
      text: "Size Wise Distribution",
      style: {
        fontSize: "12px",
      },
    },
    chart: {
      backgroundColor: theme.palette.light.main,
      style: {
        fontFamily: "Inter",
      },
      height: 210,
      width: 210,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
      sunburst: {
        borderWidth: 0.5,
        gapSize: 10,
        borderColor: theme.palette.light.main,
        innerSize: "40%",
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            color: theme.palette.light.main,
          },
        },
      },
    },
    series: [
      {
        allowDrillToNode: true,
        type: "sunburst",
        data: [...allCompaniesData, ...capData],
        name: "Root",
        borderRadius: 0,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      // @ts-ignore
      formatter: function () {
        // @ts-ignore
        return `${this.point.name}: <b>${
          // @ts-ignore
          this.point?.value?.toFixed(2)
        } %</b>`;
      },
      style: {
        color: "#000",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
  };

  // ==============================================================================================================================
  const pieChartDataWithColor =
    form &&
    Object.entries(
      _.groupBy(
        form?.filter((dataItem: any) => dataItem?.isin !== "INGridkeyCash"),
        "sector"
      )
    )
      .map(([key, value]) => {
        return [key, value.reduce((acc, curr) => acc + curr.weightage, 0)];
      })
      ?.map((item, index) => {
        return [...item, PieChartColors[index < 12 ? index : index % 12]];
      });

  // ==============================================================================================================================
  const chartPieOptions = {
    title: {
      text: "Sector Wise Distribution",
      style: {
        fontSize: "12px", // Set the font size here
      },
    },
    chart: {
      backgroundColor: theme.palette.light.main,
      style: {
        fontFamily: "Inter",
      },
      height: 210,
      width: 210,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
      sunburst: {
        pie: 0.5,
        gapSize: 10,
        borderColor: theme.palette.light.main,
        innerSize: "50%",
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            color: theme.palette.light.main,
          },
        },
      },
    },
    tooltip: {
      // @ts-ignore
      formatter: function () {
        // @ts-ignore
        return `${this.point.name}: <b>${
          // @ts-ignore
          this.point?.percentage?.toFixed(2)
        } %</b>`;
      },
      style: {
        color: "#000",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    series: [
      {
        allowDrillToNode: true,
        innerSize: "50%",
        type: "pie",
        colors: pieChartDataWithColor?.map((item) => item[2]),
        data: pieChartDataWithColor.map((item) => {
          return [item[0], item[1]];
        }),
        borderRadius: 0,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={chartPieOptions} />
        <List
          style={{
            width: "60%",
            maxHeight: "150px",
            overflow: "auto",
            paddingRight: 10,
          }}
        >
          {pieChartDataWithColor?.map((item) => {
            return (
              <ListItem
                style={{
                  padding: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item[2],
                      height: 10,
                      width: 10,
                    }}
                  />
                  <Typography fontSize={10} fontWeight={600}>
                    {item[0]}
                  </Typography>
                  <Typography fontSize={10} fontWeight={600} ml={"auto"}>
                    {item[1]?.toFixed(2)}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={chartSunburstOptions}
        />
        <List
          style={{
            width: "60%",
            maxHeight: "150px",
            overflow: "auto",
            paddingRight: 10,
          }}
        >
          {[...allCompaniesData]?.map((item) => {
            return (
              <ListItem
                style={{
                  padding: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item?.color,
                      height: 10,
                      width: 10,
                    }}
                  />
                  <Typography fontSize={10} fontWeight={600}>
                    {item?.name}
                  </Typography>
                  <Typography fontSize={10} fontWeight={600} ml={"auto"}>
                    {item?.value?.toFixed(2)}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default HoldingsSunburst;
