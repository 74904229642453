import { QueryClient } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 60 * 60 * 60 * 1000,
      retry: false,
      onError: (error: any) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem(`token`);
          localStorage.removeItem(`isInvestor`);
          localStorage.removeItem(`isAdvisor`);
          localStorage.removeItem(`userInfo`);
          localStorage.removeItem(`version`);
          localStorage.removeItem("breadcumbs");
          <Navigate to={"/"} replace />;
          window.location.reload();
        }
      },
    },
  },
});

export default queryClient;
