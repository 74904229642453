import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

const useRevenueManager = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { mutate: createFeePlan } = useMutation({
    mutationKey: ["CREATE_FEE_PLAN"],
    mutationFn: (data) => axiosInstance.post("/fee-plans/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(-1);
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const {
    data: getFeePlanById,
    isFetching: getFeePlanFetching,
    isFetched: getFeePlanFetched,
  } = useFetchQuery({
    key: ["GET_FEE_PLAN_BY_ID"],
    route: `/fee-plans/${id}/`,
    enabled: Boolean(id),
  });

  const { mutate: updateFeePlan, isLoading: updateFeePlanLoading } =
    useMutation({
      mutationKey: ["UPDATE_FEE_PLAN"],
      mutationFn: (data) => axiosInstance.patch(`/fee-plans/${id}/`, data),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        navigate(-1);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const { data: feePlansList, isFetching: getFeePlansFetching } = useFetchQuery(
    {
      key: ["GET_FEE_PLANS_DROPDOWN"],
      route: "/fee-plans-dropdown/",
    }
  );

  return {
    createFeePlan,
    getFeePlanById,
    getFeePlanFetching,
    updateFeePlan,
    getFeePlanFetched,
    feePlansList,
    getFeePlansFetching,
    updateFeePlanLoading,
  };
};

export default useRevenueManager;
