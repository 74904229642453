import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import NoteModal from "Pages/AdviceRegisterNotes/NoteModal";
import NotesList from "Pages/AdviceRegisterNotes/NotesList";
import NoData from "components/NoData";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { NotesColor } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioNotes = () => {
  useDocumentTitle("Portfolio Notes");

  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [noteList, setNoteList] = useState([]);

  const [openNoteModel, setOpenNoteModel] = useState(false);

  const [noteDetails, setNoteDetails] = useState(false);

  const [pageData, setPageData] = useState(10);

  const [noteFilter, setNoteFilter] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState([]);

  const requestBody = {
    portfolio_id: id,
    take: pageData,
    filter: JSON.stringify(noteFilter ? { noteTypes: noteFilter } : []),
    operator: "or",
  };

  const { refetch, isFetching } = useQuery({
    queryKey: ["PORTFOLIO_NOTES_LISTS", requestBody],
    queryFn: () =>
      axiosInstance.get("/advice-notes-list/", { params: requestBody }),
    keepPreviousData: true,
    onSuccess: (data) => setNoteList(data?.data?.data),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { data: getNoteTypeList, isFetching: noteTypesLoading } = useFetchQuery(
    {
      key: ["GET_NOTE_TYPE_LIST"],
      route: "/note-types/",
    }
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            height: `calc(100vh - 297px)`,
          }}
        >
          {isFetching ? (
            <SimpleLoader />
          ) : (
            <Grid
              item
              xs={12}
              position={"relative"}
              container
              display={"flex"}
              justifyContent={"center"}
              spacing={1}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <LoadingButton
                  onClick={() => {
                    setPageData(10);
                    setNoteFilter([]);
                    setSelectedFilter([]);
                  }}
                  style={{
                    border: "1px solid",
                    background:
                      !noteFilter.length && selectedFilter?.length === 0
                        ? `#53B795`
                        : theme.palette.light.main,
                    color:
                      !noteFilter.length && selectedFilter?.length === 0
                        ? theme.palette.light.main
                        : `#53B795`,
                    borderColor: `#53B795`,
                    marginRight: 10,
                  }}
                  loading={
                    isFetching &&
                    noteTypesLoading &&
                    selectedFilter?.length === 0
                  }
                >
                  {"#All"}
                </LoadingButton>
                {getNoteTypeList?.data?.data.map(
                  (dataItem: any, index: number) => {
                    return (
                      <LoadingButton
                        onClick={() => {
                          setPageData(10);
                          if (
                            selectedFilter?.length !== 0 &&
                            selectedFilter?.includes(dataItem?.id)
                          ) {
                            setNoteFilter(
                              noteFilter?.filter(
                                (item: any) => dataItem?.id !== item
                              )
                            );
                            setSelectedFilter(
                              selectedFilter?.filter(
                                (item) => item !== dataItem?.id
                              )
                            );
                          } else {
                            setNoteFilter([...noteFilter, dataItem?.id]);
                            setSelectedFilter([
                              ...selectedFilter,
                              dataItem?.id,
                            ]);
                          }
                        }}
                        key={index}
                        style={{
                          border: "1px solid",
                          background:
                            noteFilter.length &&
                            noteFilter
                              .map((element: any) => element)
                              ?.includes(dataItem?.id)
                              ? dataItem?.color
                              : theme.palette.light.main,
                          color:
                            noteFilter.length &&
                            noteFilter
                              .map((element: any) => element)
                              ?.includes(dataItem?.id)
                              ? theme.palette.light.main
                              : `${dataItem?.color}`,
                          borderColor: `${dataItem?.color}`,
                          marginRight: 10,
                        }}
                        loading={
                          isFetching &&
                          noteTypesLoading &&
                          selectedFilter?.includes(dataItem?.name)
                        }
                      >
                        {dataItem?.name}
                      </LoadingButton>
                    );
                  }
                )}
              </Grid>
              {noteList?.length !== 0 &&
                noteList?.map((dataItem: any, index: number) => {
                  return (
                    <Grid item xs={12} position={"relative"} key={index}>
                      <NotesList
                        dataItem={{
                          ...dataItem,
                          noteTypesList: dataItem?.noteTypesList?.map(
                            (dataItem: any) => {
                              return {
                                ...dataItem,
                                color: NotesColor[dataItem?.name],
                              };
                            }
                          ),
                        }}
                        index={index}
                        refetch={refetch}
                        setOpenNoteModel={setOpenNoteModel}
                        setNoteDetails={setNoteDetails}
                      />
                    </Grid>
                  );
                })}{" "}
              {noteList?.length === 0 && <NoData />}{" "}
            </Grid>
          )}
        </Grid>
      </Grid>

      {openNoteModel && (
        <NoteModal
          open={openNoteModel}
          setOpen={setOpenNoteModel}
          noteDetails={noteDetails}
          portfolioId={id}
        />
      )}
    </>
  );
};

export default PortfolioNotes;
