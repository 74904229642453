import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";
import {
  FeeMethod,
  FeeType,
  PERFORMANCE_PLAN_FEE_PARAMETER,
} from "utils/constants";

const EditPerformanceFeePage = () => {
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "performanceFee.managementFeeSlab",
  });

  form.watch(["performanceFee.feeType"]);

  const isFeeTypeFix = form.getValues("performanceFee.feeType") !== "fixed";

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={500}>
          Performance Fee
        </Typography>
      </Grid>
      <Grid item xs={12} display={"flex"} gap={1}>
        <Controller
          name="performanceFee.minesManagementFee"
          control={form.control}
          render={({ field }) => (
            <FormControlLabel
              control={<GKCheckbox {...field} checked={field.value} />}
              label={"Management Fee Deduction"}
            />
          )}
        />
        <Controller
          name="performanceFee.highwatermark"
          control={form.control}
          render={({ field }) => (
            <FormControlLabel
              control={<GKCheckbox {...field} checked={field.value} />}
              label={"High Water Mark"}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="performanceFee.feeType"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              inputLabel="Fee Type"
              disableClearable={false}
              options={
                FeeType.filter((dataItem) => dataItem.value !== "fixed") || []
              }
              onChange={(e, val) => {
                field.onChange(val.value);
              }}
              value={
                FeeType.find((dataItem) => dataItem.value === field.value) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  name="performanceFee.feeType"
                  placeholder="Select Fee Type"
                />
              )}
            />
          )}
        />
      </Grid>
      {isFeeTypeFix && (
        <>
          <Grid item xs={4}>
            <Controller
              name="performanceFee.feeMethod"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  inputLabel="Fee Method"
                  disableClearable={false}
                  options={FeeMethod || []}
                  onChange={(e, val) => field.onChange(val.value)}
                  value={
                    FeeMethod.find(
                      (dataItem) => dataItem.value === field.value
                    ) || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      name="performanceFee.feeMethod"
                      placeholder="Select Fee Method"
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="performanceFee.calculationBenchmark"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  inputLabel="Fee Parameter"
                  disableClearable={false}
                  options={PERFORMANCE_PLAN_FEE_PARAMETER}
                  onChange={(e, val) => field.onChange(val.value)}
                  value={
                    PERFORMANCE_PLAN_FEE_PARAMETER.find(
                      (dataItem) => dataItem.value === field.value
                    ) || ""
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      name="performanceFee.calculationBenchmark"
                      placeholder="Select Fee Parameter"
                    />
                  )}
                />
              )}
            />
          </Grid>
        </>
      )}

      {/* <Grid item xs={4}>
        <Controller
          name="performanceFee.calculationFrequency"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              inputLabel="Calculation Frequency"
              disableClearable={false}
              options={calculationFrequency || []}
              onChange={(e, val) => field.onChange(val.value)}
              value={
                calculationFrequency.find(
                  (dataItem) => dataItem.value === field.value
                ) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  name="performanceFee.calculationFrequency"
                  placeholder="Select Calculation Frequency"
                />
              )}
            />
          )}
        />
      </Grid> */}
      <Grid item xs={4}>
        <Controller
          name="performanceFee.fee"
          control={form.control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <GKTextField
                {...field}
                inputLabel={!isFeeTypeFix ? "Fee Amount" : "Fee %"}
                type="number"
              />
            </FormControl>
          )}
        />
      </Grid>
      {/* <Grid item xs={4}>
        <Controller
          name="performanceFee.billFrequency"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              inputLabel="Billing Frequency"
              disableClearable={false}
              options={billingFrequency || []}
              onChange={(e, val) => field.onChange(val.value)}
              value={
                billingFrequency.find(
                  (dataItem) => dataItem.value === field.value
                ) || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  name="performanceFee.billFrequency"
                  placeholder="Select Billing Frequency"
                />
              )}
            />
          )}
        />
      </Grid> */}
      <Grid item xs={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="performanceFee.outOfPocketExpenses"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Out Of Pocket Expense"
                name="performanceFee.outOfPocketExpenses"
                type="number"
              />
            )}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="performanceFee.minimumFee"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Minimum Fee"
                name="performanceFee.minimumFee"
                type="number"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="performanceFee.maximumFee"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Maximum Fee"
                name="performanceFee.maximumFee"
                type="number"
              />
            )}
          />
        </FormControl>
      </Grid> */}
      {/* <Grid item xs={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="performanceFee.softHurdle"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Soft Hurdle"
                name="performanceFee.softHurdle"
                type="number"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="performanceFee.hardHurdle"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Hard Hurdle"
                name="performanceFee.hardHurdle"
                type="number"
              />
            )}
          />
        </FormControl>
      </Grid> */}

      {/* {isFeeTypeFix && (
        <Grid item xs={4}>
          <Controller
            name="performanceFee.calculationMethod"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                inputLabel="Calculation Method"
                disableClearable={false}
                options={
                  managementFeeCalculationMethod.filter(
                    (dataItem) => dataItem.value !== "advance"
                  ) || []
                }
                onChange={(e, val) => field.onChange(val.value)}
                value={
                  managementFeeCalculationMethod.find(
                    (dataItem) => dataItem.value === field.value
                  ) || ""
                }
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="performanceFee.calculationMethod"
                    placeholder="Select Calculation Method"
                  />
                )}
              />
            )}
          />
        </Grid>
      )} */}

      {/* {form.getValues("managementFee.highwatermark") && (
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="performanceFee.highwatermarkAmount"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  inputLabel="High Water Mark Amount"
                  name="performanceFee.highwatermarkAmount"
                  type="number"
                />
              )}
            />
          </FormControl>
        </Grid>
      )} */}

      {form.getValues("performanceFee.feeMethod") === "slabs" && (
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, minHeight: 100 }}
          >
            <Table
              sx={{ minWidth: 500 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    style={{
                      padding: 12,
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={theme.palette.primary.main}
                    >
                      Slabs
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="right"
                    style={{
                      padding: 12,
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      color={theme.palette.primary.main}
                    >
                      <GKButton
                        variant="outlined"
                        onClick={() => {
                          append({
                            lowerLimit: "",
                            upperLimit: "",
                            slabFee: "",
                          });
                        }}
                        startIcon={<AiOutlinePlus />}
                      >
                        Add Slab
                      </GKButton>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Management Fee&nbsp;(%)</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row: any, index: number) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Controller
                          name={`performanceFee.managementFeeSlab[${index}].lowerLimit`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              name={`performanceFee.managementFeeSlab[${index}].lowerLimit`}
                              type="number"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`performanceFee.managementFeeSlab[${index}].upperLimit`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              name={`performanceFee.managementFeeSlab[${index}].upperLimit`}
                              type="number"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`performanceFee.managementFeeSlab[${index}].slabFee`}
                          control={form.control}
                          render={({ field }) => (
                            <GKTextField
                              {...field}
                              name={`performanceFee.managementFeeSlab[${index}].slabFee`}
                              type="number"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="error"
                          className="outline"
                          disabled={fields.length === 1}
                          onClick={() => remove(index)}
                        >
                          <FiTrash2 size={16} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default EditPerformanceFeePage;
