/* eslint-disable array-callback-return */
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import DragDrop from "components/DragDrop";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKNoDataFound from "components/GKNoDataFound";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import GKTextArea from "components/GKTextBar";
import GKTextField from "components/GKTextField";
import StatusField from "components/StatusField";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { handleReturnFormData } from "utils/formHelpers";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import AssetHistoryDetailsModal from "./AssetHistoryDetailsModal";
import AssetsStatusModal from "./AssetsStatusModal";
import {
  riskDropdown,
  timePerdioDropdown,
  volatilityDropdown,
} from "./equityBasketHelper";

const AddPortfolioDetailsModal = () => {
  useDocumentTitle("Basket Asset Details");

  const {
    dispatch,
    state: { tab },
  } = useAppContext();

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showAssetDetails, setShowAssetDetails] = useState(null);

  const { id, assetId } = useParams();

  const form = useForm({
    defaultValues: {
      stockName: "",
      rationale: "",
      status: "",
      riskProfile: "",
      timePeriod: "",
      volatility: "",
      target_price: "",
      deviation: "",
      stop_loss: "",
      research_files: [],
    },
  });

  const {
    data: addDetailsData,
    isFetching,
    isRefetching,
  } = useFetchQuery({
    key: ["GET_STOCK_DETAILS", id, assetId],
    route: `/model_portfolio/${id}/stock_item/${assetId}/`,
    success: (res: any) => {
      form.reset({
        stockName: res?.data?.stockName,
        rationale: res?.data?.rationale,
        volatility: res?.data?.volatility,
        riskProfile: res?.data?.riskProfile,
        timePeriod: res?.data?.timePeriod,
        deviation: res?.data?.deviation,
        status: res?.data?.status,
        target_price: res?.data?.targetPrice,
        stop_loss: res?.data?.stopLoss,
        research_files: [],
      });
    },
  });

  const { mutate: handleSubmit, isLoading: submitLoading } = useMutation({
    mutationKey: ["ADD_PORTFOLIO_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/`,
        data
      ),
    onSuccess: (response) => {
      refetchQuery("GET_STOCK_DETAILS");
      toast.success(
        response?.data?.message || "Action Performed Successfully."
      );
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteResearchFiles, isLoading: researchFilesLoading } =
    useMutation({
      mutationKey: ["DELETE_RESEARCH_FILES"],
      mutationFn: (data: any) =>
        axiosInstance.delete(
          `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/delete_research_file/?role=advisor&file_id=${data}`
        ),
      onSuccess: (response) => {
        refetchQuery("GET_STOCK_DETAILS");
        toast.success(
          response?.data?.message || "Action Performed Successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const {
    mutate: handlePublishResearchFiles,
    isLoading: researchUpdateFilesLoading,
  } = useMutation({
    mutationKey: ["PUBLISH_RESEARCH_FILES"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.data?.id}/update_research_status/?role=advisor&file_id=${data}`
      ),
    onSuccess: (response) => {
      refetchQuery("GET_STOCK_DETAILS");
      toast.success(
        response?.data?.message || "Action Performed Successfully."
      );
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handleFormSubmit = (values: any) => {
    handleSubmit(
      handleReturnFormData({
        obj: values,
        isEditForm: true,
        dirtyFields: Object.keys(form.formState.dirtyFields),
      })
    );
  };

  return (
    <>
      <DashboardLayout
        title="Assets Detials"
        loading={isFetching && !isRefetching}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {addDetailsData?.data?.id !== "" && (
              <form
                onSubmit={form.handleSubmit(handleFormSubmit)}
                id="stock-details-form"
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography fontWeight={600} fontSize={24}>
                      {addDetailsData?.data?.companyName}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setIsStatusModalOpen(true);
                      }}
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      Set Portfolio Status
                    </Button>
                    {/* <Button variant="outlined" color="dark" size="small">
                      Settings
                    </Button>
                    <Button variant="outlined" color="dark" size="small">
                      Notify
                    </Button> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs
                      onChange={(e, val) => {
                        dispatch({
                          type: "TAB",
                          data: { ...tab, assetDetailsTabs: val },
                        });
                      }}
                      value={tab?.assetDetailsTabs}
                    >
                      <Tab label="Details" />
                      <Tab label="Assets History" />
                      <Tab label="Research Files" />
                      <Tab label="Linked Model Portfolio" />
                    </Tabs>
                    <Divider />
                  </Grid>
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={4}>
                      <Controller
                        name="status"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <FormControl variant="standard" fullWidth>
                              <GKSelect
                                {...field}
                                inputLabel="Status"
                                placeholder="Status"
                              >
                                <MenuItem style={{ display: "none" }} value="">
                                  Select Status
                                </MenuItem>
                                <MenuItem value={"BUY"}>Buy</MenuItem>
                                <MenuItem value={"HOLD"}>Hold</MenuItem>
                                <MenuItem value={"EXIT"}>Exit</MenuItem>
                              </GKSelect>
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={4}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name="target_price"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <GKTextField
                                {...field}
                                type="number"
                                inputLabel="Latest Target"
                                placeholder="Latest Target"
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={4}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name="stop_loss"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <GKTextField
                                {...field}
                                type="number"
                                inputLabel="Stop Loss"
                                placeholder="Stop Loss"
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={3}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name="deviation"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <GKTextField
                                {...field}
                                type="number"
                                inputLabel="Deviation %"
                                placeholder="Deviation %"
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={3}>
                      <Controller
                        name="riskProfile"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              multiple
                              {...field}
                              disableClearable={false}
                              onChange={(e, val) => {
                                field.onChange(val);
                              }}
                              value={field.value}
                              options={riskDropdown}
                              inputLabel="Risk Profile"
                              renderInput={(params) => {
                                return (
                                  <GKTextField
                                    {...params}
                                    placeholder="Risk Profile"
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={3}>
                      <Controller
                        name="volatility"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              onChange={(e, val) => {
                                field.onChange(val.value);
                              }}
                              disableClearable={false}
                              value={
                                volatilityDropdown?.find(
                                  (da) => da?.value === field.value
                                )?.name
                              }
                              options={volatilityDropdown}
                              getOptionLabel={(opt) => opt?.name || opt}
                              inputLabel="Volatility"
                              renderInput={(params) => {
                                return (
                                  <GKTextField
                                    {...params}
                                    placeholder="Volatility"
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={3}>
                      <Controller
                        name="timePeriod"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <GKSearchSelect
                              {...field}
                              onChange={(e, val) => {
                                field.onChange(val.value);
                              }}
                              disableClearable={false}
                              value={
                                timePerdioDropdown?.find(
                                  (da) => da?.value === field.value
                                )?.name
                              }
                              options={timePerdioDropdown}
                              getOptionLabel={(opt) => opt?.name || opt}
                              inputLabel="Time Horizon"
                              renderInput={(params) => {
                                return (
                                  <GKTextField
                                    {...params}
                                    placeholder="Time Horizon"
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 0 && (
                    <Grid item xs={12}>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name="rationale"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <GKTextArea
                                {...field}
                                minRows={3}
                                inputLabel="Rationale"
                                placeholder="Rationale"
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {tab?.assetDetailsTabs === 2 && (
                    <Grid item xs={12}>
                      <Typography>Research Files</Typography>
                      <Controller
                        name="research_files"
                        control={form.control}
                        render={({ field }) => {
                          return (
                            <DragDrop
                              multiple
                              uploadError={uploadError}
                              setUploadError={setUploadError}
                              acceptType=".pdf, .csv, .docx"
                              supportName="PDF, CSV & DOCX"
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                e.map((file) => {
                                  setTimeout(() => {
                                    const data = new FormData();
                                    data.append("research_files", file);
                                    handleSubmit(data);
                                  }, 200);
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Grid>
          {tab?.assetDetailsTabs === 1 &&
            addDetailsData?.data?.stockUniverseItemHistory?.length !== 0 && (
              <Grid item xs={12}>
                <GKClientDataGrid
                  name="assetHistory"
                  density="compact"
                  columnsButton={false}
                  pagination={false}
                  rows={addDetailsData?.data?.stockUniverseItemHistory || []}
                  onRowClick={(params) => {
                    setShowAssetDetails({
                      ...params.row,
                      name: addDetailsData?.data?.companyName,
                    });
                    setShowAssetModal(true);
                  }}
                  columns={[
                    {
                      ...extraColumnsField("Date", "date", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                    {
                      ...extraColumnsField("Status", "status", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      renderCell: (params) => {
                        return (
                          <StatusField
                            statusString={params.row.status || "N/A"}
                          />
                        );
                      },
                    },
                    {
                      ...extraColumnsField("Target Price", "targetPrice", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },
                    {
                      ...extraColumnsField("Stop Loss", "stopLoss", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },
                    {
                      ...extraColumnsField("Deviation", "deviation", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                      type: "number",
                    },

                    {
                      ...extraColumnsField("Volatility", "volatility", 1, 1),
                      valueFormatter: (params) =>
                        handleCapitalize(removeUnderscore(params?.value)),
                    },
                    {
                      ...extraColumnsField("Time Horizon", "timePeriod", 1, 1),
                      valueFormatter: (params) =>
                        handleCapitalize(removeUnderscore(params?.value)),
                    },

                    {
                      ...extraColumnsField("Rationale", "rationale", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                  ]}
                />
              </Grid>
            )}
          {tab?.assetDetailsTabs === 1 &&
            addDetailsData?.data?.stockUniverseItemHistory?.length === 0 && (
              <Grid item xs={12}>
                <GKNoDataFound />
              </Grid>
            )}
          {tab?.assetDetailsTabs === 2 &&
            addDetailsData?.data?.researchFiles?.length !== 0 && (
              <Grid item xs={12}>
                <GKClientDataGrid
                  name="assetResearchFiles"
                  density="compact"
                  columnsButton={false}
                  pagination={false}
                  rows={addDetailsData?.data?.researchFiles || []}
                  columns={[
                    {
                      ...extraColumnsField("Name", "name", 1, 1),
                      valueFormatter: (params) => params.value || "-",
                    },
                    {
                      ...extraColumnsField("Uploaded By", "uploadedBy", 1, 1),
                    },
                    {
                      ...extraColumnsField("Date", "createdOn", 1, 1),
                      valueFormatter: (params) =>
                        dayjs(params.value).format("DD MMM YYYY hh:ss") || "-",
                    },
                    {
                      ...extraColumnsField("Publish File", "visible ", 1, 1),
                      headerAlign: "right",
                      align: "right",
                      renderCell: (params) => {
                        return (
                          <Switch
                            onChange={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handlePublishResearchFiles(params?.row.id);
                            }}
                            checked={params?.row?.visible}
                            disabled={researchUpdateFilesLoading}
                          />
                        );
                      },
                    },
                    {
                      ...extraColumnsField("Acion", "action", 1, 1),
                      headerAlign: "right",
                      align: "right",
                      renderCell: (params) => {
                        return (
                          <LoadingButton
                            color="error"
                            size="extraSmall"
                            loading={researchFilesLoading}
                            variant="text"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteResearchFiles(params?.row.id);
                            }}
                          >
                            Delete
                          </LoadingButton>
                        );
                      },
                    },
                  ]}
                />
              </Grid>
            )}
          {tab?.assetDetailsTabs === 3 &&
            addDetailsData?.data?.linkedVersions?.length !== 0 && (
              <Grid item xs={12}>
                <GKClientDataGrid
                  name="linkedVersion"
                  columnsButton={false}
                  density="compact"
                  pagination={false}
                  getRowId={(pa) => pa?.itemId}
                  rows={addDetailsData?.data?.linkedVersions || []}
                  columns={[
                    {
                      ...extraColumnsField("Version", "version", 120, 0),
                    },
                    {
                      type: "number",
                      ...extraColumnsField("Weightage", "weightage", 1, 0),
                    },
                  ]}
                />
              </Grid>
            )}
          {tab?.assetDetailsTabs === 0 && (
            <Grid
              item
              p={1}
              xs={12}
              bottom={"-8px"}
              position={"sticky"}
              textAlign={"right"}
              bgcolor={"#fff"}
            >
              <LoadingButton
                variant="contained"
                type="submit"
                form="stock-details-form"
                size="medium"
                loading={submitLoading}
              >
                {"Submit"}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </DashboardLayout>
      {isStatusModalOpen && (
        <AssetsStatusModal
          open={isStatusModalOpen}
          setOpen={setIsStatusModalOpen}
        />
      )}
      {showAssetModal && (
        <AssetHistoryDetailsModal
          open={showAssetModal}
          setOpen={setShowAssetModal}
          data={showAssetDetails}
        />
      )}
    </>
  );
};

export default AddPortfolioDetailsModal;
