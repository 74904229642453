import {
  colors,
  LARGE_CAP_COLOR,
  largeCapColors,
  mediumCapColors,
  MID_CAP_COLOR,
  SMALL_CAP_COLOR,
  smallCapColors,
} from "utils/constants";

export const handleCapColor = (capSize: string) => {
  if (capSize === "large")
    return largeCapColors[Math.floor(Math.random() * largeCapColors.length)];
  if (capSize === "medium")
    return mediumCapColors[Math.floor(Math.random() * mediumCapColors.length)];
  if (capSize === "small")
    return smallCapColors[Math.floor(Math.random() * smallCapColors.length)];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const removeUnderscore = (text: string) => {
  return text?.replace(/_/gm, " ");
};

export const convertToCamelCase = (str: string) => {
  return str?.replace(/_([a-z])/g, (match, p1) => {
    return p1?.toUpperCase();
  });
};

export const handleCapColors = (str: string) => {
  switch (str) {
    case "small":
      return SMALL_CAP_COLOR;
    case "medium":
      return MID_CAP_COLOR;
    case "large":
      return LARGE_CAP_COLOR;
    case "small cap":
      return SMALL_CAP_COLOR;
    case "medium cap":
      return MID_CAP_COLOR;
    case "large cap":
      return LARGE_CAP_COLOR;

    default:
      return LARGE_CAP_COLOR;
  }
};
