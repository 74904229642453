import { LoadingButton } from "@mui/lab";
import { Divider, Grid, Step, StepButton, Stepper } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientDetails from "./ClientDetails";
import ClientOnboardingAgreement from "./ClientOnboardingAgreement";
import ClientOnboardingCustomAgreement from "./ClientOnboardingCustomAgreement";
import ClientOnboardingFinished from "./ClientOnboardingFinished";
import ClientOnboardingKYC from "./ClientOnboardingKYC";
import ClientOnboardingRisk from "./ClientOnboardingRisk";
import ClientTypeSelection from "./ClientTypeSelection";

type FormValues = {
  personalDetails?: {
    email?: string;
    name?: string;
    gender?: string;
    maritalStatus?: string;
    fatherName?: string;
    motherName?: string;
    dateOfBirth?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    pincode?: string;
    income?: string;
    occupation?: string;
    pan?: string;
    aadhar?: string;
    emergencyContact?: string;
    emergencyContactNum?: string;
  };
  branch?: string;
  family?: number[];
  signatureType?: string;
  isExistingClient?: boolean;
  phone?: string;
  countryCode?: string;
  bankAccounts?: any[];
  dematAccounts?: any[];
  nominees?: any[];
  kyc?: any;
  document?: any;
  id?: any;
  sendOptions?: any;
  manual?: any;
  identification_type?: any;
  riskProfile?: any;
  agreement?: any;
  // onBoard: boolean;
  // basketIds: any[];
};

const ClientOnboarding = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [customAgreement, setCustomAgreement] = useState(false);

  const [questionValidation, setQuestionValidation] = useState<any>({});

  const [questionAnswers, setQuestionAnswers] = useState([]);

  useDocumentTitle("Client Onboarding");

  const form = useForm<FormValues>({
    defaultValues: {
      isExistingClient: false,
      branch: null,
      id: null,
      family: [],
      personalDetails: {
        email: "",
        name: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        income: null,
        occupation: "",
        pan: "",
        aadhar: "",
        emergencyContact: "",
        emergencyContactNum: "",
      },
      phone: "",
      identification_type: "",
      countryCode: "+91",
      bankAccounts: [],
      dematAccounts: [],
      nominees: [],
      signatureType: "",
      sendOptions: "",
      document: [],
      kyc: null,
      manual: false,
      riskProfile: null,
      agreement: null,
      // onBoard: true,
      // basketIds: [],
    },
  });

  form.watch();

  // ================================================================================================================= clientData={addedClientData}====
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  // ================================================================================================================= clientData={addedClientData}====
  const handlePrevious = () => {
    setActiveStep((prev) => prev - 1);
  };

  // ================================================================================================================= clientData={addedClientData}====
  const { data: branchData } = useFetchQuery({
    key: ["GET_ALL_BRANCHES"],
    route: "/branch/has_manager/",
  });

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleAddClient, isLoading: addClientLoading } = useMutation({
    mutationFn: (clientState: any) =>
      axiosInstance.post("/client/", {
        ...clientState,
        family: clientState.family,
        branch: branchData.data[0].id,
      }),
    onSuccess: (response) => {
      form.reset({ ...form.getValues(), ...response?.data });
      toast.success(response.data?.message || "Action completed successfully.");
      handleNext();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleUpdateClient, isLoading: updateClientLoading } =
    useMutation({
      mutationFn: (clientState: any) =>
        axiosInstance.patch(`/client/${clientState.id}/`, {
          ...clientState,
          branch: branchData.data[0].id,
          family: clientState.family,
        }),
      onSuccess: (response) => {
        setActiveStep((prev) => prev + 1);
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleRiskProfile, isLoading: riskLoading } = useMutation({
    mutationFn: () =>
      axiosInstance.post(`/riskprofileresponse/${form.getValues("id")}/`, {
        client: form.getValues("id"),
        response: questionAnswers,
      }),
    onSuccess: (response) => {
      refetchQuery("GET_COMPLIANCE_CLIENT_BY_ID");
      handleNext();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleKYC, isLoading: kycLoading } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.post(`/kyc/${form.getValues("id")}/`, {
        client: values?.id,
        pan: values.personalDetails.pan,
        identificationType: values.identification_type,
        kycStatus: values.kyc_status,
      }),
    onSuccess: (response) => {
      refetchQuery("GET_COMPLIANCE_CLIENT_BY_ID");
      handleNext();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });
  // ================================================================================================================= clientData={addedClientData}====
  const { mutate: handleAgreement, isLoading: agreementLoading } = useMutation({
    mutationFn: (values: any) =>
      axiosInstance.post(
        `/agreements/${form.getValues("id")}/?manual=${form.getValues(
          "manual"
        )}`,
        values
      ),
    onSuccess: (response) => {
      handleNext();
      refetchQuery("GET_COMPLIANCE_CLIENT_BY_ID");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });
  // ================================================================================================================= clientData={addedClientData}====
  const isQuestionAnswered = (questionId: any) => {
    return questionValidation[questionId] || false;
  };

  // ================================================================================================================= clientData={addedClientData}====
  const { data: getAllRiskData } = useFetchQuery({
    key: ["RISK_PROFILE_LIST"],
    route: `/riskprofile/`,
  });
  // ================================================================================================================= clientData={addedClientData}====

  const allQuestionsAnswered =
    getAllRiskData?.data?.data?.data?.riskQuestions?.every((dataItem: any) =>
      isQuestionAnswered(dataItem.id)
    );

  // ================================================================================================================= clientData={addedClientData}====
  const handleSubmit = (values: any) => {
    if (activeStep === 1 && !form.getValues("id")) {
      handleAddClient(values);
    } else if (activeStep === 1 && form.getValues("id")) {
      handleUpdateClient(values);
    } else if (activeStep === 2) {
      handleKYC(values);
    } else if (activeStep === 3) {
      if (!allQuestionsAnswered) {
        toast.error(
          "Please answer all questions before submitting or skip this step."
        );
        return;
      }

      handleRiskProfile();
    } else if (activeStep === 4) {
      const filedata = new FormData();
      filedata.append("document", values.document[0]);
      filedata.append("sendOptions", values.sendOptions);
      filedata.append("signatureType", values.signatureType);
      filedata.append("client", values?.id);

      handleAgreement(filedata);
    }
  };

  // ================================================================================================================= clientData={addedClientData}====
  const stepsData = [
    {
      name: "Choose",
      completed: activeStep > 0,
    },
    {
      name: "Details",
      completed: activeStep !== 1 && form.getValues("id"),
    },
    {
      name: "KYC",
      completed: activeStep !== 2 && form.getValues("kyc"),
    },
    {
      name: "Risk Profile",
      completed: activeStep !== 3 && form.getValues("riskProfile"),
    },
    {
      name: "Agreement",
      completed: activeStep !== 4 && form.getValues("agreement"),
    },
    {
      name: "Complete",
      completed: false,
    },
  ];
  /** =========================================================================================== */
  useFetchQuery({
    key: ["GET_COMPLIANCE_CLIENT_BY_ID"],
    route: `/compliance/?client_id=${form.getValues("id")}`,
    enabled: Boolean(form.getValues("id")),
    success: (response: any) => {
      form.setValue("kyc", response?.data?.data?.kyc);
      form.setValue("riskProfile", response?.data?.data?.riskProfile);
      form.setValue("agreement", response?.data?.data?.agreement);
    },
  });
  // ================================================================================================================= clientData={addedClientData}====
  const handleReturnDisable = ({ active }: { active: number }) => {
    if (active === 1) {
      return !form.formState.isDirty;
    }
    if (active === 2) {
      return !form.getFieldState("identification_type").isDirty;
    }
    if (active === 3) {
      return !allQuestionsAnswered;
    }
    if (active === 4) {
      return (
        (!form.getFieldState("document").isDirty &&
          !form.getFieldState("manual").isDirty) ||
        (!form.getFieldState("document").isDirty &&
          !form.getFieldState("sendOptions").isDirty &&
          !form.getFieldState("signatureType").isDirty)
      );
    }
    return true;
  };

  const handleReturnIcon = ({
    completed,
    index,
    active,
  }: {
    completed: boolean;
    index: number;
    active: number;
  }) => {
    if (completed) {
      return (
        <FaCircleCheck
          color={paletteLightTheme?.palette.success.main}
          size={20}
        />
      );
    }
    if (index === active) {
      return (
        <div
          style={{
            width: 20,
            height: 20,
            fontSize: 14,
            display: "flex",
            color: "#FFF",
            borderRadius: 100,
            alignItems: "center",
            justifyContent: "center",
            background: paletteLightTheme?.palette.primary.main,
            border: `1px solid ${paletteLightTheme?.palette.primary.main}`,
          }}
        >
          {index + 1}
        </div>
      );
    }
    return (
      <div
        style={{
          width: 20,
          height: 20,
          fontSize: 14,
          display: "flex",
          color: "#697586",
          borderRadius: 100,
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${paletteLightTheme?.palette.border.main}`,
        }}
      >
        {index + 1}
      </div>
    );
  };

  return (
    <DashboardLayout title="ClientOnboarding">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stepper
                nonLinear={form.getValues("isExistingClient")}
                activeStep={activeStep}
                sx={{
                  padding: "16px 0 8px 0",
                  "& .MuiSvgIcon-root.Mui-completed": {
                    color: paletteLightTheme?.palette.success.main,
                  },
                }}
                connector={
                  <MdOutlineKeyboardArrowRight color="#9AA4B2" size={18} />
                }
              >
                {stepsData?.map((dataItem, i) => {
                  return (
                    <Step
                      key={i}
                      completed={dataItem?.completed}
                      disabled={i === 0}
                    >
                      <StepButton
                        icon={handleReturnIcon({
                          completed: dataItem?.completed,
                          index: i,
                          active: activeStep,
                        })}
                        onClick={() => {
                          if (form.getValues("isExistingClient")) {
                            setActiveStep(i);
                          }
                        }}
                        sx={{
                          "& .MuiStepLabel-label": {
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        }}
                      >
                        {dataItem.name}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              overflow={"hidden auto"}
              minHeight={`calc(100vh - 218px)`}
              maxHeight={`calc(100vh - 218px)`}
            >
              {activeStep === 0 && (
                <ClientTypeSelection handleNext={handleNext} />
              )}
              {activeStep === 1 && <ClientDetails />}
              {activeStep === 2 && <ClientOnboardingKYC />}
              {activeStep === 3 && (
                <ClientOnboardingRisk
                  questionAnswers={questionAnswers}
                  handleNext={handleNext}
                  setQuestionAnswers={setQuestionAnswers}
                  questionValidation={questionValidation}
                  setQuestionValidation={setQuestionValidation}
                  data={getAllRiskData}
                />
              )}
              {activeStep === 4 && (
                <ClientOnboardingAgreement
                  setCustomAgreement={setCustomAgreement}
                />
              )}
              {activeStep === 5 && (
                <ClientOnboardingFinished setActiveStep={setActiveStep} />
              )}
            </Grid>
            {activeStep !== 5 && activeStep !== 0 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              display={activeStep !== 5 && activeStep !== 0 ? "flex" : "none"}
              justifyContent={"space-between"}
              gap={1}
              alignItems={"center"}
            >
              {activeStep !== 1 && (
                <GKButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    handlePrevious();
                  }}
                  startIcon={<IoMdArrowBack />}
                >
                  Previous
                </GKButton>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ ml: "auto" }}
                disabled={handleReturnDisable({ active: activeStep })}
                loading={
                  agreementLoading ||
                  riskLoading ||
                  addClientLoading ||
                  kycLoading ||
                  updateClientLoading
                }
                endIcon={<IoMdArrowForward />}
              >
                Save & Next&nbsp;
              </LoadingButton>
              {form.getValues("id") && (
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  endIcon={<IoMdArrowForward />}
                >
                  Next&nbsp;
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Show.When isTrue={customAgreement}>
        <ClientOnboardingCustomAgreement
          setOpen={setCustomAgreement}
          open={customAgreement}
          clientId={form.getValues("id")}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default ClientOnboarding;
