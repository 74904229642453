import { Checkbox, CheckboxProps } from "@mui/material";
import { ReactComponent as CheckboxCheckedIcon } from "../assets/Icons/checkbox-checked.svg";
import { ReactComponent as CheckboxIcon } from "../assets/Icons/checkbox.svg";

const GKCheckbox = (
  props: CheckboxProps & { disableLeftPadding?: boolean; padding?: any }
) => {
  const { disableLeftPadding, padding } = props;
  return (
    <Checkbox
      {...props}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      sx={{
        paddingLeft: disableLeftPadding ? 0 : padding,
      }}
    />
  );
};

export default GKCheckbox;
