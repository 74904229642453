import { LoadingButton } from "@mui/lab";
import { Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import AdminAccess from "./AdminAccess";
import AssignRoleModal from "./AssignRoleModal";
import EmployeesPermissions from "./BranchAdminsPermissions";
import EditAdminModal from "./EditAdminModal";

const AdminDetails = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { id } = useParams<string>();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [assignRoleModal, setAssignRoleModal] = useState(false);

  const { data: employeeDetails, isFetching: isInitialLoading } = useFetchQuery(
    {
      key: [QueryKeys.EMPLOYEE_LIST, id],
      route: `/employees/${id}/`,
    }
  );

  const { mutate: sendRegistrationLink, isLoading: linkLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/send-admin-reg/", { employee: data }),
    mutationKey: ["SEND_REGISTRATION_LINK"],
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });

  return (
    <DashboardLayout title="Branch Admin" loading={isInitialLoading}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography
            fontWeight={600}
            fontSize={18}
            textTransform={"capitalize"}
          >
            {employeeDetails?.data?.user?.personalDetails?.name || "-"}:{" "}
            {employeeDetails?.data?.code || "-"}
          </Typography>
          <Typography fontSize={12} textTransform={"capitalize"}>
            {employeeDetails?.data?.role || "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign={"right"}>
          <LoadingButton
            loading={linkLoading}
            onClick={() => {
              sendRegistrationLink(id);
            }}
            variant="outlined"
            color="dark"
          >
            Send Registration Link
          </LoadingButton>
          &nbsp;
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => setAssignRoleModal(true)}
          >
            Assign Role
          </GKButton>{" "}
          &nbsp;
          <GKButton
            variant="outlined"
            color="dark"
            onClick={() => setOpenEditModal(true)}
          >
            Profile Details
          </GKButton>
        </Grid>
        <Grid item xs={12} my={1}>
          <Tabs
            onChange={(e, val) => {
              dispatch({ type: "TAB", data: { ...tab, employeeERMTabs: val } });
            }}
            value={tab?.employeeERMTabs}
          >
            <Tab label="Permissions" value={0} />
            <Tab label="Access" value={1} />
          </Tabs>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Show.When isTrue={tab?.employeeERMTabs === 0}>
            <EmployeesPermissions />
          </Show.When>
        </Grid>
        <Grid item xs={12}>
          <Show.When isTrue={tab?.employeeERMTabs === 1}>
            <AdminAccess />
          </Show.When>
        </Grid>
      </Grid>

      <Show.When isTrue={openEditModal}>
        <EditAdminModal open={openEditModal} setOpen={setOpenEditModal} />
      </Show.When>

      <Show.When isTrue={assignRoleModal}>
        <AssignRoleModal open={assignRoleModal} setOpen={setAssignRoleModal} />
      </Show.When>
    </DashboardLayout>
  );
};

export default AdminDetails;
