import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import Details from "./Details";
import IntroSection from "./IntroSection";

const PrivacyPolicy = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container>
        <div ref={myRef} />

        <Grid item xs={12}>
          <IntroSection />
        </Grid>
        <Grid item xs={12}>
          <Details />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default PrivacyPolicy;
