import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKSelect from "components/GKSelect";
import { useFetchQuery } from "hooks/useQueries";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, generateRandomNumberId } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: any;
  setSelectedId: any;
  portfolioId: any;
  data: {
    id: any;
    portfolioName: any;
    portfolioCode: any;
    companyName: any;
    status: any;
    portfolio: any;
    universeItem: any;
  }[];
}

const PortfolioStatusModal = (props: Props) => {
  const { open, setOpen, data, setSelectedId, portfolioId } = props;

  const { id } = useParams();

  const [allData, setAllData] = useState([]);

  const [changedIds, setChangedIds] = useState([]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `model_portfolio/${id}/portfolio_status/custom_create_update/`,
        data
      ),
    onSuccess: (response) => {
      setSelectedId(null);
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handleSubmit = () => {
    const NewData = allData
      ?.filter((item: any) => !item?.id)
      ?.map((dtaItm) => {
        return {
          status: dtaItm?.status,
          portfolio: portfolioId,
          universe_item: dtaItm?.universeItem,
        };
      });

    const updatedAndNewObjects = allData
      ?.filter((dta) => {
        return dta.id && changedIds.includes(dta.id);
      })
      .map((cData) => {
        return {
          id: cData?.id,
          portfolioName: cData?.portfolioName,
          portfolioCode: cData?.portfolioCode,
          companyName: cData?.companyName,
          status: cData?.status,
          portfolio: cData?.portfolio,
          universeItem: cData?.universeItem,
        };
      });

    mutate([...updatedAndNewObjects, ...NewData]);
  };

  const { data: companies } = useFetchQuery({
    route: `model_portfolio/${id}/universe_items/`,
    key: ["GET_COMPANY_BY_STATUS_ID"],
  });

  useEffect(() => {
    if (allData?.length === 0)
      setAllData(
        data?.map((item) => {
          return {
            ...item,
            uniqueId: generateRandomNumberId(item.id),
          };
        })
      );
  }, []);

  const companyMasterFilterData = companies?.data.filter(
    (dataItem: any) =>
      !allData?.map((item: any) => item?.isinCode)?.includes(dataItem?.isinCode)
  );

  return (
    <GKModal
      open={open}
      onCloseModal={() => {
        setSelectedId(null);
      }}
      setOpen={setOpen}
      modalTitle="Portfolio Status"
      footer={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </LoadingButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="portfolioStatusModal"
            showFiltersButton={false}
            headerComponent={
              <GKButton
                variant="contained"
                onClick={() => {
                  setAllData([
                    {
                      uniqueId: generateRandomNumberId(allData.length),
                      portfolioName: "",
                      portfolioCode: "",
                      companyName: "",
                      status: "BUY",
                      portfolio: "",
                      universeItem: "",
                    },
                    ...allData,
                  ]);
                }}
              >
                Add Company
              </GKButton>
            }
            rows={allData}
            getRowId={(val) => val?.uniqueId}
            maxHeight={`calc(100vh - 420px)`}
            columns={[
              {
                ...extraColumnsField("Company Name", "companyName", 0, 1),
                renderCell: (row) => {
                  const { companyName, uniqueId, id: rowId } = row.row;
                  return rowId ? (
                    companyName
                  ) : (
                    <GKSearchSelect
                      disableClearable
                      sx={{
                        width: "100%",
                      }}
                      options={companyMasterFilterData || []}
                      value={companyName}
                      getOptionLabel={(option) => option.companyName || option}
                      onChange={(event, val) => {
                        const newArr = allData?.map((dataItem) => {
                          return dataItem?.uniqueId === uniqueId
                            ? {
                                ...dataItem,
                                isin:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.isinCode
                                    : dataItem?.status,
                                portfolioName:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.portfolioName
                                    : dataItem?.portfolioName,
                                portfolioCode:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.portfolioCode
                                    : dataItem?.portfolioCode,
                                companyName:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.companyName
                                    : dataItem?.companyName,
                                status: "BUY",
                                portfolio:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.portfolio
                                    : dataItem?.portfolio,
                                universeItem:
                                  dataItem?.uniqueId === uniqueId
                                    ? val?.id
                                    : dataItem?.universeItem,
                              }
                            : dataItem;
                        });
                        setAllData(newArr);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  );
                },
              },
              {
                ...extraColumnsField("Status", "status", 0, 1),
                align: "right",
                headerAlign: "right",
                renderCell: (row) => {
                  const { uniqueId, status, id: rowId } = row?.row;
                  return (
                    <GKSelect
                      sx={{
                        width: 80,
                      }}
                      value={status || "BUY"}
                      onChange={(event) => {
                        const newArr = allData?.map((dataItem) => {
                          return {
                            ...dataItem,
                            status:
                              dataItem?.uniqueId === uniqueId
                                ? event?.target?.value
                                : dataItem?.status,
                          };
                        });
                        setChangedIds([...changedIds, rowId]);
                        setAllData(newArr);
                      }}
                    >
                      <MenuItem style={{ display: "none" }} value="">
                        Select Status
                      </MenuItem>
                      <MenuItem value={"BUY"}>Buy</MenuItem>
                      <MenuItem value={"HOLD"}>Hold</MenuItem>
                      <MenuItem value={"EXIT"}>Exit</MenuItem>{" "}
                    </GKSelect>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default PortfolioStatusModal;
