import { Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PieChart from "components/Highcharts/PieChart";
import { ThemeContainer } from "utils/Theme";

interface PieChartSectionProps {
  pieData: any;
  pieClickData: any;
  pieHoverData: any;
  setPieClickData: any;
  setPieHoverData: any;
  pieRef: any;
}

const PieChartSection = (props: PieChartSectionProps) => {
  const {
    pieData,
    pieClickData,
    pieHoverData,
    setPieClickData,
    setPieHoverData,
    pieRef,
  } = props;

  const { theme } = ThemeContainer();

  return (
    <Grid container>
      <Grid
        item
        xs={7}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <PieChart
          data={pieData}
          pieClickData={pieClickData}
          onClick={setPieClickData}
          onHover={setPieHoverData}
        />
      </Grid>
      <Grid item xs={5}>
        <Typography
          display={"flex"}
          justifyContent={"center"}
          fontWeight={500}
          color={theme.palette.dark.main}
          fontSize={16}
        >
          Sector-wise Distribution
        </Typography>
        <div
          style={{
            maxHeight: 300,
            overflow: "auto",
            marginTop: "10px",
          }}
        >
          {pieData?.map((legends: any) => (
            <Grid
              container
              key={legends.id}
              alignItems="center"
              justifyContent="space-between"
              style={{
                fontWeight: "600",
                borderRadius: "4px",
                backgroundColor:
                  pieClickData && pieClickData?.id === legends.id
                    ? `${theme.palette.dark.main}44`
                    : "transparent",
                color:
                  pieHoverData?.id === legends.id
                    ? theme.palette.primary.dark
                    : theme.palette.text.secondary,
              }}
            >
              <Grid
                item
                xs={9}
                container
                alignItems="center"
                style={{ padding: "4px" }}
              >
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: legends.color,
                    marginRight: "8px",
                  }}
                />
                <Tooltip title={legends.id} arrow placement="top">
                  <Typography
                    display={"flex"}
                    fontSize={12}
                    fontWeight={600}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    width={"70%"}
                    overflow={"hidden"}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setPieClickData(legends)}
                    ref={pieHoverData?.id === legends?.id ? pieRef : undefined}
                  >
                    {legends.id}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={3} pl={1}>
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  color={theme.palette.dark.main}
                >
                  {legends?.value?.toFixed(2)}%
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default PieChartSection;
