/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { FormControl, Grid, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSelect from "components/GKSelect";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import SheetConfirmation from "./SheetConfirmation";

interface Props {
  open: any;
  setOpen: any;
  data: any;
  setUploadModal: any;
  otherData?: any;
  formik?: any;
}

const SheetMappingModal = ({
  open,
  setOpen,
  data: dummyData,
  otherData,
  setUploadModal,
  formik,
}: Props) => {
  const [nullCompanies, setNullCompanies] = useState(dummyData);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutate: handleSheetSubmit } = useMutation({
    mutationFn: (data) =>
      axiosInstance.put(
        `/transactions-upload-format-fields/${dummyData?.formatData}/`,
        {
          format: data,
          format_name: otherData || "",
          file: dummyData?.transactionUpload?.id,
        }
      ),
    onSuccess: () => {
      setShowConfirmation(true);
      refetchQuery(QueryKeys.UPLOAD_MANAGER_FAVORITE_SAMPLES);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handleUpdateAllField = async (data: any) => {
    delete data?.notMatch;
    await handleSheetSubmit(data);
  };

  return (
    <>
      <GKModal
        modalTitle="Sheet Mapping"
        open={open}
        setOpen={setOpen}
        width={"40%"}
        footer={
          <Grid container justifyContent={"flex-end"} gap={1}>
            <GKButton
              onClick={() => {
                handleUpdateAllField(nullCompanies);
              }}
              variant="contained"
              size="medium"
            >
              Submit
            </GKButton>
          </Grid>
        }
      >
        <Grid container spacing={1}>
          {Object.entries(dummyData)
            ?.filter(([, value]) => value === null)
            ?.map(([key, val]) => (
              <Grid item xs={12} key={key}>
                <FormControl variant="standard" fullWidth>
                  <GKSelect
                    inputLabel={key}
                    name={key}
                    onChange={(event) => {
                      setNullCompanies({
                        ...nullCompanies,
                        [key]:
                          key === event.target.name ? event.target.value : val,
                      });
                    }}
                  >
                    {Object.entries(dummyData?.notMatch)?.map(
                      ([, val]: any) => (
                        <MenuItem value={val} key={val}>
                          {val}
                        </MenuItem>
                      )
                    )}
                  </GKSelect>
                </FormControl>
              </Grid>
            ))}
        </Grid>
      </GKModal>
      {showConfirmation && (
        <SheetConfirmation
          open={showConfirmation}
          setOpen={setShowConfirmation}
          submit={formik}
          setMappingModal={setOpen}
          setUploadModal={setUploadModal}
        />
      )}
    </>
  );
};

export default SheetMappingModal;
