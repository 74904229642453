import { Grid } from "@mui/material";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioSummary } from "Pages";
import { useParams } from "react-router-dom";
import HoldingsSunburst from "./HoldingsSunburst";

interface Props {
  selectedId: any;
  oldIdSelected: any;
}

const ModelOverview = (props: Props) => {
  const { selectedId, oldIdSelected } = props;

  const { id } = useParams();

  const { data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_HOLDINGS", oldIdSelected],
    route: `/model_portfolio/${id}/version/${
      oldIdSelected?.snapshotVersion || selectedId
    }/version_holdings/`,
    enabled: Boolean(selectedId),
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PortfolioSummary />
      </Grid>
      <Grid item xs={12}>
        {data?.data?.data && <HoldingsSunburst form={data?.data?.data?.data} />}
      </Grid>
    </Grid>
  );
};

export default ModelOverview;
