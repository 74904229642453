import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import ApproveAutoContract from "./ApproveAutoContract";

interface Props {
  open: any;
  setOpen: any;
  portfolio: any;
  broker: any;
  fileId: any;
}

const UploadPasswordModal = ({
  open,
  setOpen,
  portfolio,
  broker,
  fileId,
}: Props) => {
  const [approveModalData, setApproveModalData] = React.useState<any>([]);

  const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);

  const { mutate: handleRetry, isLoading: retryLoading } = useMutation({
    mutationFn: (data: { portfolio: any; broker: any; id: any }) =>
      axiosInstance.post(`/refresh-auto-forward/`, data),
    onSuccess: (response: any) => {
      setApproveModalData(response?.data?.data?.transactions);
      setIsApproveModalOpen(true);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleSetPassword, isLoading: passwordLoading } = useMutation(
    {
      mutationFn: (data: { portfolio: any; broker: any; password: any }) =>
        axiosInstance?.post(`/auto-forward-password/`, data),
      onSuccess: () => {
        handleRetry({
          portfolio: portfolio,
          broker: broker,
          id: fileId,
        });
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    }
  );

  const form = useForm({
    defaultValues: {
      portfolio: portfolio,
      broker: broker,
      password: "",
    },
  });

  return (
    <>
      <GKModal
        open={open}
        setOpen={setOpen}
        modalTitle="Confirm Paassword"
        footer={
          <LoadingButton
            variant="contained"
            form="password-form"
            type="submit"
            loading={passwordLoading || retryLoading}
          >
            {"Submit"}
          </LoadingButton>
        }
      >
        <form
          onSubmit={form.handleSubmit((values) => handleSetPassword(values))}
          id="password-form"
        >
          <Grid container>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  control={form.control}
                  name="password"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Password"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </GKModal>
      {isApproveModalOpen && (
        <ApproveAutoContract
          open={isApproveModalOpen}
          setOpen={setIsApproveModalOpen}
          recordID={fileId}
          recordData={approveModalData}
          setPasswordModal={setOpen}
          passwordModal={open}
        />
      )}
    </>
  );
};

export default UploadPasswordModal;
