/* eslint-disable import/no-named-as-default-member */
import { Grid } from "@mui/material";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import ClientCustomAgreement from "Pages/Compliance/ClientCustomAgreement";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ClientAgreementColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import ComplianceAgreementModal from "./ComplianceAgreementModal";

const ComplianceAgreement = () => {
  const { id } = useParams();

  const [customAgreement, setCustomAgreement] = useState(false);

  const [addClientAgreement, setAddClientAgreement] = useState(false);

  const { data: getAgreement, isLoading: isAgreementLoading } = useFetchQuery({
    key: [QueryKeys.AGREEMENT_LIST],
    route: `/agreements/${id}/`,
    enabled: Boolean(id),
  });

  return (
    <>
      <GKClientDataGrid
        name="clientAgreement"
        headerGridStyle={{
          justifyContent: "flex-end",
        }}
        searchPlaceholder="Search For Agreement"
        loading={isAgreementLoading}
        columns={ClientAgreementColumns()}
        rows={getAgreement?.data?.data?.response || []}
        headerComponent={
          <Grid
            container
            display={
              getAgreement?.data?.data?.response?.length === 0 ? "none" : "flex"
            }
            justifyContent={"end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              onClick={() => setCustomAgreement(true)}
              variant="outlined"
              color="dark"
            >
              Generate Agreement
            </GKButton>
            <GKButton
              onClick={() => setAddClientAgreement(true)}
              variant="outlined"
              color="dark"
            >
              Upload Agreement
            </GKButton>
          </Grid>
        }
        emptyButtons={
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              onClick={() => setCustomAgreement(true)}
              variant="outlined"
              size="small"
              color="dark"
            >
              Generate Agreement
            </GKButton>
            <GKButton
              onClick={() => setAddClientAgreement(true)}
              variant="outlined"
              size="small"
              color="dark"
            >
              Upload Agreement
            </GKButton>
          </Grid>
        }
      />

      <Show.When isTrue={addClientAgreement}>
        <ComplianceAgreementModal
          setOpen={setAddClientAgreement}
          open={addClientAgreement}
        />
      </Show.When>
      <Show.When isTrue={customAgreement}>
        <ClientCustomAgreement
          setOpen={setCustomAgreement}
          open={customAgreement}
        />
      </Show.When>
    </>
  );
};

export default ComplianceAgreement;
