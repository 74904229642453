import { Card, Grid, Typography } from "@mui/material";
import ComplianceAgreementModal from "Pages/Client/ComplianceTabs/ComplianceAgreementModal";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { useAgreement } from "hooks/useAgreement";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import { ClientAgreementColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientCustomAgreement from "./ClientCustomAgreement";

const ClientAgreement = () => {
  useDocumentTitle("Client Agreement");

  const [addClientAgreement, setAddClientAgreement] = useState(false);

  const [customAgreement, setCustomAgreement] = useState(false);

  const { getAgreement, isAgreementLoading } = useAgreement();

  const { theme } = ThemeContainer();

  return (
    <DashboardLayout title="Client Agreement">
      <Card>
        <Grid container>
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography fontSize={14} fontWeight={500}>
                Client Name
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.main}
              >
                {getAgreement?.data?.data?.name || "-"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              gap={1}
            >
              <GKButton
                onClick={() => setCustomAgreement(true)}
                variant="outlined"
                color="dark"
              >
                Generate Agreement
              </GKButton>
              <GKButton
                onClick={() => setAddClientAgreement(true)}
                variant="outlined"
                color="dark"
              >
                Upload Agreement
              </GKButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid
        mt={2}
        container
        height={
          getAgreement?.data?.data?.response?.length === 0 ? "100%" : "auto"
        }
      >
        <GKClientDataGrid
          name="clientAgreement"
          showSearch={false}
          showFilters={false}
          headerGridStyle={{
            justifyContent: "flex-end",
          }}
          searchPlaceholder="Search For Agreement"
          loading={isAgreementLoading}
          columns={ClientAgreementColumns()}
          rows={getAgreement?.data?.data?.response || []}
          showHeaderGrid={false}
        />

        <Show.When isTrue={addClientAgreement}>
          <ComplianceAgreementModal
            setOpen={setAddClientAgreement}
            open={addClientAgreement}
          />
        </Show.When>
        <Show.When isTrue={customAgreement}>
          <ClientCustomAgreement
            setOpen={setCustomAgreement}
            open={customAgreement}
          />
        </Show.When>
      </Grid>
    </DashboardLayout>
  );
};

export default ClientAgreement;
