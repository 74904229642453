import { useAppContext } from "AppContext";
import { useEffect } from "react";

const useDocumentTitle = (title: string) => {
  const { dispatch } = useAppContext();

  useEffect(() => {
    document.title = `GridKey | ${title}`;
    dispatch({ type: "DOCUMENT_TITLE", data: title });
  }, [title]);
};

export default useDocumentTitle;
