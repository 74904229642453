import { Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import { useState } from "react";
import { extraColumnsField } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";

interface Props {
  open: boolean;
  setOpen: any;
  setResponseData: any;
  setCompanyWiseRebalanceData: any;
  form: any;
  data: {
    availableCash: any;
    availableCashEquivalent: any;
    availableCashEquivalentPercentage: any;
    availableCashPercentage: any;
    currentAmount: any;
    currentValueNoEtfCe: any;
    investedAmount: any;
    portfolioCode: any;
    portfolioId: any;
    portfolioName: any;
    portfolioValue: any;
    totalCash: any;
    totalCashPercentageTotal: any;
  }[];
  replace: any;
}

const SelectPortfolioModal = (props: Props) => {
  const {
    open,
    setOpen,
    data,
    replace,
    form,
    setResponseData,
    setCompanyWiseRebalanceData,
  } = props;

  const [selectedIds, setSelectedIds] = useState(
    form?.getValues("portfolios")?.map((data: any) => data?.portfolioId)
  );

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Select Portfolio"
      width={"80%"}
      footer={
        <GKButton
          onClick={() => {
            const filteredData = data?.filter(
              (row: any) =>
                !form
                  ?.getValues("portfolios")
                  ?.map((data: any) => data?.portfolioId)
                  ?.includes(row.portfolioId)
            );

            const selectedRowData = [
              ...filteredData,
              ...form.getValues("portfolios"),
            ]
              ?.map((dataasd) => {
                return {
                  ...dataasd,
                  cash: dataasd?.cash || 0,
                };
              })
              ?.filter((row: any) => selectedIds.includes(row.portfolioId));
            replace(selectedRowData);

            form?.setValue("portfolios", selectedRowData);
            setOpen(false);
            setResponseData([]);
            setCompanyWiseRebalanceData([]);
          }}
          variant="contained"
        >
          Finish
        </GKButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="selectPortfolioModal"
            density="compact"
            rows={data || []}
            showFiltersButton={false}
            getRowId={(op) => op?.portfolioId}
            maxHeight={`calc(100vh - 508px)`}
            checkboxSelection
            keepNonExistentRowsSelected
            onRowSelectionModelChange={(params) => {
              setSelectedIds(params);
            }}
            rowSelectionModel={selectedIds}
            columns={[
              {
                ...extraColumnsField("Portfolio", "clientName", 300, 0),
                renderCell: (params) => {
                  return (
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Typography
                        fontWeight={700}
                        maxWidth={"100%"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {params?.row?.clientName}
                      </Typography>
                      <Typography fontSize={12}>
                        {params?.row?.portfolioName} -{" "}
                        {params?.row?.portfolioCode}
                      </Typography>
                    </Grid>
                  );
                },
              },
              {
                ...extraColumnsField(
                  "Portfolio Value",
                  "portfolioValue",
                  190,
                  1
                ),
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                ...extraColumnsField("Current Value", "currentAmount", 190, 1),
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                ...extraColumnsField("Available Cash", "availableCash", 190, 1),
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                ...extraColumnsField(
                  "Cash Equivalent",
                  "availableCashEquivalent",
                  190,
                  1
                ),
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                ...extraColumnsField("Total Cash", "totalCash", 190, 1),
                valueFormatter: (params) => numberToCurrency(params.value),
              },
            ]}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default SelectPortfolioModal;
