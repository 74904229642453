import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import DragDrop from "components/DragDrop";
import GKButton from "components/GKButton";
import GKSelect from "components/GKSelect";
import GKSideModal from "components/GKSideModal";
import GKTextField from "components/GKTextField";
import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface Props {
  setOpen: any;
  open: boolean;
  sheetDetails?: any;
}

const ExecutionSheetModal = (props: Props) => {
  const { setOpen, open, sheetDetails } = props;

  const [files, setFiles] = React.useState<File[]>([]);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [responseData, setResponseData] = useState(null);

  const { mutate } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/order-manager-mappings/", data),
    onSuccess: (response: any) => {
      setResponseData(response?.data);
      refetchQuery("ORDER_MANAGER_MAPPING");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handleSubmitFile = (file: any) => {
    const data = new FormData();
    file.forEach((file: any) => {
      data.append("file", file);
    });
    mutate(data);
  };

  return (
    <GKSideModal
      width="80%"
      setOpen={setOpen}
      open={open}
      modalTitle="Add Execution Sheet"
      footerButton={
        <Grid
          container
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          {sheetDetails?.id || responseData ? (
            <GKButton
              variant="contained"
              type="submit"
              size="medium"
              form="execution-sheet-form"
            >
              {sheetDetails?.id ? "Update" : "Submit"}
            </GKButton>
          ) : (
            <GKButton
              variant="contained"
              onClick={() => {
                handleSubmitFile(files);
              }}
              size="medium"
            >
              Submit
            </GKButton>
          )}
        </Grid>
      }
    >
      <Grid container>
        {sheetDetails?.id || responseData ? (
          <Formik
            initialValues={{
              name: sheetDetails?.id ? sheetDetails.name : "",
              sheetFields: sheetDetails?.id
                ? sheetDetails?.formatJson?.map((dataItem: any) => {
                    return {
                      custom_field: dataItem?.customField,
                      gridkey_field: dataItem?.gridkeyField,
                      default_value: dataItem?.defaultValue,
                      default_bool: dataItem?.defaultBool,
                    };
                  })
                : responseData?.data?.customFields?.map((dataItem: any) => {
                    return {
                      custom_field: dataItem,
                      gridkey_field: "",
                      default_value: "",
                      default_bool: false,
                    };
                  }),
            }}
            onSubmit={(values) => {
              const responseData = values?.sheetFields?.map((dataItem: any) => {
                return {
                  ...dataItem,
                  default_value: dataItem?.default_bool
                    ? dataItem.default_value
                    : null,
                  gridkey_field: !dataItem?.default_bool
                    ? dataItem.gridkey_field
                    : null,
                };
              });

              if (sheetDetails?.id) {
                axiosInstance
                  ?.patch(`order-manager-mappings/mapping/`, {
                    name: values?.name,
                    format_json: responseData,
                    id: sheetDetails?.id,
                  })
                  .then(() => {
                    setOpen(false);
                    refetchQuery("ORDER_MANAGER_MAPPING");
                  })
                  .catch((error) => {
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again."
                    );
                  });
              } else {
                axiosInstance
                  ?.post(`order-manager-mappings/mapping/`, {
                    name: values?.name,
                    format_json: responseData,
                  })
                  .then(() => {
                    setOpen(false);
                    refetchQuery("ORDER_MANAGER_MAPPING");
                  })
                  .catch((error) => {
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again."
                    );
                  });
              }
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form id="execution-sheet-form" onSubmit={handleSubmit}>
                  <FieldArray name="sheetFields">
                    {() => (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <FormControl variant="standard" fullWidth>
                            <GKTextField
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`name`}
                              inputLabel="Name"
                              placeholder="Name"
                              value={values.name}
                            />
                          </FormControl>
                        </Grid>
                        {values.sheetFields.map(
                          (friend: any, index: number) => {
                            return (
                              <Grid
                                container
                                item
                                spacing={1}
                                key={index}
                                alignItems={"center"}
                              >
                                <Grid item xs={3}>
                                  <Typography>
                                    {friend?.custom_field}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.sheetFields[index].default_bool
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name={`sheetFields.${index}.default_bool`}
                                      />
                                    }
                                    label="Custom Field"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  {friend?.default_bool ? (
                                    <GKTextField
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`sheetFields.${index}.default_value`}
                                      placeholder="Enter Value"
                                      value={
                                        values.sheetFields[index].default_value
                                      }
                                    />
                                  ) : (
                                    <GKSelect
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name={`sheetFields.${index}.gridkey_field`}
                                      value={
                                        values.sheetFields[index].gridkey_field
                                      }
                                    >
                                      <MenuItem
                                        style={{
                                          display: "none",
                                        }}
                                        value={""}
                                      >
                                        Select field
                                      </MenuItem>
                                      {sheetDetails?.id
                                        ? sheetDetails?.gridkeyFields?.map(
                                            (item: any) => (
                                              <MenuItem key={item} value={item}>
                                                {item}
                                              </MenuItem>
                                            )
                                          )
                                        : responseData?.data?.gridkeyFields?.map(
                                            (item: any) => (
                                              <MenuItem key={item} value={item}>
                                                {item}
                                              </MenuItem>
                                            )
                                          )}
                                    </GKSelect>
                                  )}
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        ) : (
          <Grid item xs={12}>
            <DragDrop
              value={files}
              onChange={setFiles}
              acceptType={".csv"}
              uploadError={uploadError}
              setUploadError={setUploadError}
            />
          </Grid>
        )}
      </Grid>
    </GKSideModal>
  );
};

export default ExecutionSheetModal;
