import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { employeeColumn } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import AddEmployee from "./AddBranchAdmins";

const BranchAdmins = ({ path }: { path?: any }) => {
  useDocumentTitle("Admins");

  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const [addEmployeeModal, setAddEmployeeModal] = useState(false);

  const requestBody = {
    take: commonFilter?.employee?.take,
    skip: commonFilter?.employee?.skip,
    sort: JSON.stringify(
      commonFilter?.employee?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.employee?.search || "",
    filter: JSON.stringify(commonFilter?.employee?.customFilter),
  };

  const {
    data: employeeData,
    isLoading,
    refetch,
  } = useFetchQuery({
    key: [QueryKeys.EMPLOYEE_LIST, requestBody, path],
    route: path || "/employees/",
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="employee"
        totalCount={employeeData?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/employees`}
        name={"EmployeeList"}
        filter={JSON.stringify(commonFilter?.employee?.customFilter)}
      />
    );
  };

  return (
    <>
      <GKDataGrid
        rows={employeeData?.data?.data || []}
        columns={employeeColumn}
        loading={isLoading}
        maxHeight={"calc(100vh - 312px)"}
        onRowClick={(row) => {
          navigate(`/app/admin/${row.id}`);
        }}
        CustomPagination={!isLoading && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        name="employee"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => setAddEmployeeModal(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Admin
          </GKButton>
        }
        tableName="employee_list"
        headerFilter={<TableFiltering name="employee" />}
      />

      {addEmployeeModal && (
        <AddEmployee
          open={addEmployeeModal}
          setOpen={setAddEmployeeModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default BranchAdmins;
