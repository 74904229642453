import { CircularProgress, Grid, IconButton, Switch } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AutoImportModal from "Pages/AutoImport/AutoImportModal";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { FiCheck } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const AutoImportTab = () => {
  const { id } = useParams();

  const [isAutoImportOpen, setIsAutoImportOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [editPassword, setEditPassword] = useState(false);

  const [allAutoData, setAllAutoData] = useState([]);

  // ================================================================================================================================
  const { refetch, isFetching: dataLoading } = useFetchQuery({
    key: ["AUTO_FORWARD_DATA"],
    route: "/auto-forward-data/",
    requestBody: {
      portfolio_id: id,
    },
    success: (data: any) => {
      setAllAutoData(data?.data?.data);
    },
  });

  // ================================================================================================================================
  const { mutate: updateMutation, isLoading: updateLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/auto_forward/${selectedId}/`, data),
    onSuccess: () => {
      refetch();
      setSelectedId(null);
      setEditPassword(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={12}>
        <GKClientDataGrid
          name="autoImport"
          loading={dataLoading}
          rows={allAutoData || []}
          columns={[
            {
              ...extraColumnsField("email", "email", 0, 1),
            },
            {
              ...extraColumnsField("Sender's Email", "setUpEmail", 0, 1),
            },
            {
              ...extraColumnsField("Broker", "broker", 0, 1),
            },
            {
              ...extraColumnsField("Auto Approve", "autoApprove", 0, 1),
              renderCell: (params) => {
                return updateLoading && params?.row?.id === selectedId ? (
                  <CircularProgress size={20} />
                ) : (
                  <Switch
                    checked={params?.row?.autoApproved}
                    onChange={() => {
                      setSelectedId(params?.row?.id);
                      setTimeout(() => {
                        updateMutation({
                          autoApproved: !params?.row?.autoApproved,
                        });
                      }, 300);
                    }}
                  />
                );
              },
            },
            {
              ...extraColumnsField("Contract Notes Password", "password", 0, 1),
              renderCell: (params) => {
                return (
                  <GKTextField
                    placeholder="Password"
                    disabled={!editPassword}
                    value={params?.row?.password}
                    onChange={({ target }) => {
                      setAllAutoData((prev) =>
                        prev?.map((item) => {
                          return {
                            ...item,
                            password:
                              params?.row?.id === item?.id
                                ? target?.value
                                : item?.password,
                          };
                        })
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <Grid container justifyContent={"flex-end"} gap={1}>
                          {!editPassword && (
                            <IconButton
                              onClick={() => {
                                setSelectedId(params?.row?.id);
                                setEditPassword(true);
                              }}
                            >
                              <LuPenSquare />
                            </IconButton>
                          )}
                          {editPassword && selectedId === params?.row?.id && (
                            <IconButton
                              color="success"
                              onClick={() => {
                                updateMutation({
                                  password: params?.row?.password,
                                });
                              }}
                            >
                              <FiCheck />
                            </IconButton>
                          )}
                          {editPassword && selectedId === params?.row?.id && (
                            <IconButton
                              color="error"
                              onClick={() => {
                                setEditPassword(false);
                                setSelectedId(null);
                              }}
                            >
                              <IoClose />
                            </IconButton>
                          )}
                        </Grid>
                      ),
                    }}
                  />
                );
              },
            },
          ]}
          headerComponent={
            <Grid container justifyContent={"flex-end"}>
              <GKButton
                onClick={() => {
                  setIsAutoImportOpen(true);
                }}
                variant="contained"
              >
                Add Setup
              </GKButton>
            </Grid>
          }
        />
      </Grid>

      <Show.When isTrue={isAutoImportOpen}>
        <AutoImportModal
          open={isAutoImportOpen}
          setOpen={setIsAutoImportOpen}
        />
      </Show.When>
    </Grid>
  );
};

export default AutoImportTab;
