/* eslint-disable no-unsafe-optional-chaining */
import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import CustomTable from "./CustomTable";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  portfolioGroup: any;
  portfoliosShortData: any[];
}

const EditPortfolioGroupDetails = (props: Props) => {
  const { isModalOpen, setIsModalOpen, portfolioGroup, portfoliosShortData } =
    props;

  const [portfolioGroupName, setPortfolioGroupName] = useState(
    portfolioGroup?.name
  );

  const [selectedPortfolio, setSelectedPortfolio] = useState([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [dataToSubmit, setDataToSubmit] = useState(
    portfolioGroup?.portfolioList
  );

  const comboBoxData = portfoliosShortData?.map((portfolio) => ({
    ...portfolio,
    name: portfolio.name,
    value: portfolio.id,
    portfolioId: portfolio.portfolioId,
  }));

  const { mutate: handleDeleteGroup } = useMutation({
    mutationFn: (groupId: any) =>
      axiosInstance.delete(`/portfolio-groups/${groupId}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setIsModalOpen(false);
      refetchQuery(QueryKeys.PORTFOLIO_GROUP_LIST);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleUpdateGroup, isLoading } = useMutation({
    mutationFn: ({ dataToBeSent, groupId, groupName }: any) =>
      axiosInstance.patch(`/portfolio-groups/${groupId}/`, {
        id: groupId,
        name: groupName,
        portfolios: dataToBeSent,
      }),
    onSuccess: (response) => {
      setIsModalOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.PORTFOLIO_GROUP_LIST);
      refetchQuery("GET_PORTFOLIOGROUP_BY_ID");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const handlePortfolioGroupSave = async () => {
    const dataToBeSent = [...new Set(dataToSubmit)]?.map(
      (item: any) => item?.id
    );
    handleUpdateGroup({
      groupId: portfolioGroup?.id,
      groupName: portfolioGroupName,
      dataToBeSent: dataToBeSent,
    });
  };

  const selectData = comboBoxData.filter(
    (data: any): any =>
      !selectedPortfolio
        ?.map((dataItem) => dataItem?.portfolioId)
        ?.includes(data?.portfolioId)
  );

  return (
    <GKModal
      modalTitle="Edit Portfolio Group Details"
      open={isModalOpen}
      setOpen={setIsModalOpen}
      footer={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => handlePortfolioGroupSave()}
        >
          Save
        </LoadingButton>
      }
      titleComponent={
        <GKButton
          variant="outlined"
          color="error"
          size="medium"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Portfolio Group
        </GKButton>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <GKTextField
              value={portfolioGroupName}
              inputLabel={"Portfolio Group Name"}
              onChange={(event) => {
                setPortfolioGroupName(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <GKSearchSelect
            multiple
            disableClearable
            getOptionLabel={(option) => option?.name || option}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Portfolio" />
            )}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography fontWeight={600}>{option.clientName}</Typography>
                  <Typography>{option.name}</Typography>
                </li>
              );
            }}
            inputLabel="Select Portfolios to add"
            options={selectData || []}
            value={[]}
            onChange={(value, val) => {
              const dataItems = val?.map((item: any) => {
                return {
                  name: item.name,
                  value: item.value,
                  portfolioId: item.portfolioId,
                  id: item.value,
                  phone: "",
                  clientName: "",
                };
              });
              setDataToSubmit([...dataToSubmit, ...dataItems]);
              setSelectedPortfolio([...selectedPortfolio, ...val]?.flat());
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            dataToSubmit={dataToSubmit}
            setDataToSubmit={setDataToSubmit}
            setSelectedPortfolio={setSelectedPortfolio}
          />
        </Grid>

        {isDeleteModalOpen && (
          <ConfirmDeleteModal
            open={isDeleteModalOpen}
            setOpen={setIsDeleteModalOpen}
            title="Portfolio Group"
            onDelete={() => handleDeleteGroup(portfolioGroup?.id)}
            isDelete
          />
        )}
      </Grid>
    </GKModal>
  );
};

export default EditPortfolioGroupDetails;
