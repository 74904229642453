import { Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ThemeContainer } from "utils/Theme";

interface Props {
  onChange?: any;
  value?: string;
  inputLabel: string;
  helperText?: string;
  requiredField?: boolean;
  error?: boolean;
}

const GKReactQuill = (props: Props) => {
  const { onChange, value, inputLabel, helperText, requiredField, error } =
    props;

  const { theme } = ThemeContainer();

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        // @ts-ignore
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    },
  };

  return (
    <>
      {inputLabel && (
        <Typography
          style={{
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Inter !important",
          }}
        >
          {inputLabel}
          {requiredField && (
            <span
              style={{
                color: theme.palette.error.main,
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <div
        style={{
          border: `1px solid ${theme.palette.border.main}`,
          borderColor: error
            ? theme.palette.error.main
            : theme.palette.border.main,
          borderRadius: "8px",
          marginTop: inputLabel ? "4px" : "0px",
        }}
      >
        <ReactQuill
          onChange={onChange}
          value={value}
          modules={modules}
          formats={formats}
        />
      </div>
      {!!helperText && (
        <Typography
          color={theme.palette.error.main}
          fontSize={10.5}
          sx={{ marginTop: "0 !important" }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default GKReactQuill;
