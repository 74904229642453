/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { ClientRiskProfilingColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import RiskCard from "./RiskCard";

const ClientRiskProfilingPage = () => {
  useDocumentTitle("Client Risk Profile");

  const navigate = useNavigate();

  const { id } = useParams();

  const { data: answers, isFetching: RiskResponseDetailsFetching } =
    useFetchQuery({
      key: ["RISK_PROFILE_LIST_ANSWER"],
      route: `/riskprofileresponse/${id}/`,
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });
  return (
    <DashboardLayout title="Client Risk Profile">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RiskCard answers={answers?.data?.data} />
        </Grid>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="ClientRiskProfiling"
            headerComponent={
              <Grid container justifyContent={"flex-end"} gap={1}>
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  onClick={() => {
                    mutate(id);
                  }}
                >
                  Request Risk Questionnaire
                </LoadingButton>
                <GKButton
                  variant="outlined"
                  color="dark"
                  size="medium"
                  onClick={() => {
                    navigate(`/app/risk-profile/${id}/questionnaire`);
                  }}
                >
                  {answers?.data?.data.length
                    ? "Retake Questionnaire"
                    : "Take Questionnaire"}
                </GKButton>
              </Grid>
            }
            loading={RiskResponseDetailsFetching}
            rows={answers?.data?.data?.response || []}
            columns={ClientRiskProfilingColumns(id)}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ClientRiskProfilingPage;
