import { Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import { Show } from "components/Show";
import IncomeExpense from "Pages/IncomeExpense";
import MutualFunds from "Pages/MutualFunds";
import { useParams } from "react-router-dom";
import { UserRoleTypes } from "utils/common";
import useDocumentTitle from "utils/useDocumentTitle";
import CashEntry from "../CashEntry";
import PortfolioLedger from "../SummaryPages/PortfolioLedger";
import AutoImportTab from "./AutoImportTab";
import BrokerIntegration from "./BrokerIntegration";
import ContractNotes from "./ContractNotes";
import PortfolioSummaryTradeBookTab from "./PortfolioSummaryTradeBookTab";

const PortfolioUtilityEdit = () => {
  useDocumentTitle("Portfolio Import");

  const { id } = useParams();

  const {
    state: { tab, userData },
    dispatch,
  } = useAppContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={tab?.portfolioUtilityTabs}
          onChange={(e, val) => {
            dispatch({
              type: "TAB",
              data: { ...tab, portfolioUtilityTabs: val },
            });
          }}
        >
          <Tab label="Auto Import" disableRipple />
          <Tab label="Contract Notes" disableRipple />
          <Tab label="Ledger" disableRipple />
          <Tab label="Equity/ETF" disableRipple />
          <Tab label="Mutual Funds" disableRipple />
          <Tab label="Cash Entry" disableRipple />
          <Tab label="Income/Expense" disableRipple />
          {userData?.userRole !== UserRoleTypes.client && (
            <Tab label="Broker Integration" disableRipple />
          )}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 0}>
          <AutoImportTab />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 1}>
          <ContractNotes />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 2}>
          <PortfolioLedger />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 3}>
          <PortfolioSummaryTradeBookTab />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 4}>
          <MutualFunds
            id={id}
            name="portfolioMutualFunds"
            tableName="portfolio_mutual_funds"
          />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 5}>
          <CashEntry />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 6}>
          <IncomeExpense
            id={id}
            name="portfolioIncomeExpense"
            tableName="portfolio_income_expense"
          />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 7}>
          <BrokerIntegration />
        </Show.When>
      </Grid>
    </Grid>
  );
};

export default PortfolioUtilityEdit;
