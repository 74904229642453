import { Divider, FormControl, Grid, IconButton } from "@mui/material";
import GKTextField from "components/GKTextField";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { MdAdd, MdDeleteOutline } from "react-icons/md";

const ClientNominee = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.map((bank: any, index: number) => (
          <Grid item container xs={12} spacing={1} my={1} key={index}>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Name" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.percentage`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Percentage" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.relationship`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Relationship" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} textAlign={"right"} alignSelf={"center"} mt={2}>
              <IconButton
                color="error"
                disabled={fields?.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <MdDeleteOutline />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                name: "",
                percentage: "",
                relationship: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid>
    </Grid>
  );
};

export default ClientNominee;
