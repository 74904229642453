import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioFamilyAccessInterface } from "interfaces/Portfolio";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

interface AccessList {
  data: PortfolioFamilyAccessInterface[];
  totalCount?: number;
  clientsAccess?: number[];
  checkAll?: boolean;
}

const PortfolioFamilyAccess = () => {
  const { id } = useParams();

  const [clientList, setClientList] = useState<AccessList>();

  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.portfolioFamilyAccess?.take,
    skip: commonFilter?.portfolioFamilyAccess?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioFamilyAccess?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioFamilyAccess?.search || "",
  };

  const { isFetching, refetch } = useFetchQuery({
    key: ["PORTFOLIO_FAMILY_ACCESS", requestBody],
    route: `/portfolios/${id}/clients_access/`,
    requestBody,
    success: (response: { data: AccessList }) => {
      setClientList(response?.data);
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={clientList?.totalCount || 0}
        name="portfolioFamilyAccess"
      />
    );
  };

  const { mutate: handleToggleAccess, isLoading: toggleLoading } = useMutation({
    mutationKey: ["PORTFOLIO_FAMILY_TOGGLE_ACCESS"],
    mutationFn: (obj: { clientsAccess?: number[]; checkAll?: boolean }) =>
      axiosInstance.patch(`/portfolios/${id}/clients_access/`, obj),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const toggleSingleAccess = (id: number) => {
    const currentAccessIds = clientList?.clientsAccess || [];
    const updatedAccessIds = currentAccessIds.includes(id)
      ? currentAccessIds.filter((accessId) => accessId !== id)
      : [...currentAccessIds, id];

    handleToggleAccess({ clientsAccess: updatedAccessIds });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name", "name", 200, 1),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              {params?.row?.name}
              {params?.row?.isClientsPortfolio && (
                <Typography
                  bgcolor={"rgb(0 101 128 / 11%)"}
                  fontSize={10}
                  fontWeight={500}
                  color={"#006580"}
                  p={"2px 4px"}
                  borderRadius={"4px"}
                  lineHeight={"14px"}
                >
                  Self
                </Typography>
              )}
            </Typography>
            <Typography fontSize={12}>{params?.row?.clientCode}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      ...extraColumnsField("Family Name", "familyName", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return (
          <Switch
            checked={clientList?.clientsAccess?.includes(params.row.id)}
            onChange={() => toggleSingleAccess(params.row.id)}
          />
        );
      },
    },
  ];

  return (
    <GKDataGrid
      columns={columns}
      rows={clientList?.data || []}
      loading={isFetching}
      filterButton={false}
      name="portfolioFamilyAccess"
      CustomPagination={!isFetching && cusPagination}
      headerComponent={
        <Grid>
          <FormControlLabel
            control={
              <Switch
                disabled={isFetching || toggleLoading}
                checked={clientList?.checkAll}
                onChange={() =>
                  handleToggleAccess(
                    clientList?.checkAll
                      ? { checkAll: false }
                      : { checkAll: true }
                  )
                }
              />
            }
            label={clientList?.checkAll ? "Disable All" : "Enable All"}
            labelPlacement="end"
          />
        </Grid>
      }
    />
  );
};

export default PortfolioFamilyAccess;
