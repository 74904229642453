import { Divider, Grid, Typography } from "@mui/material";
import ResponsiveSunburstChart from "components/Highcharts/SunburstChart";
import _ from "lodash";
import { ThemeContainer } from "utils/Theme";
import {
  LARGE_CAP_COLOR,
  MID_CAP_COLOR,
  PieChartColors,
  SMALL_CAP_COLOR,
} from "utils/constants";

interface SunburstSectionProps {
  sunBurstData: any;
  sunBurstClickData: any;
  sunBurstHoverData: any;
  setSunBurstClickData: any;
  setSunBurstHoverData: any;
  sunBurstRef: any;
  capSizes: any;
  pieClickData: any;
}

const SunburstSection = (props: SunburstSectionProps) => {
  const { theme } = ThemeContainer();

  const {
    sunBurstData,
    sunBurstClickData,
    sunBurstHoverData,
    setSunBurstClickData,
    setSunBurstHoverData,
    sunBurstRef,
    capSizes,
    pieClickData,
  } = props;

  const handleSunBurstText = () => {
    if (pieClickData) return pieClickData?.id;
    if (!sunBurstClickData) return "Stocks";
    if (sunBurstClickData.id === "large")
      return (
        <h1
          style={{
            fontSize: 13,
          }}
        >
          Large Cap
        </h1>
      );
    if (sunBurstClickData.id === "small")
      return (
        <h1
          style={{
            fontSize: 13,
          }}
        >
          Small Cap
        </h1>
      );
    if (sunBurstClickData.id === "medium")
      return (
        <h1
          style={{
            fontSize: 13,
          }}
        >
          Mid Cap
        </h1>
      );
    return sunBurstClickData.id;
  };

  const allData = sunBurstData?.children
    ?.map((data: any) => {
      return data?.children;
    })
    ?.flat()
    ?.map((dItem: any, index: any) => {
      return {
        ...dItem,
        color: PieChartColors[index < 12 ? index : index % 12],
      };
    });

  const grouped = {
    name: "GridKey Sunburst",
    children: _.groupBy(allData, "capSize"),
  };

  const handleCapColors = (str: string) => {
    switch (str) {
      case "small cap":
        return SMALL_CAP_COLOR;
      case "medium cap":
        return MID_CAP_COLOR;
      case "large cap":
        return LARGE_CAP_COLOR;

      default:
        return LARGE_CAP_COLOR;
    }
  };

  const totalOfCap = Object.entries(_.groupBy(allData, "capSize"))
    .map(([key]) => {
      return {
        parent: "1.1",
        name: `${key} cap`,
        id: key,
      };
    })
    ?.map((d: any) => {
      return {
        ...d,
        color: handleCapColors(d.name),
      };
    });

  const convertedData = [
    ...totalOfCap,
    ...allData
      ?.map((da: any) => {
        return {
          parent: `${da?.capSize}`,
          name: `${da?.name}`,
          id: `${da?.name}`,
          value: da?.loc,
        };
      })
      ?.map((d: any, index: any) => {
        return {
          ...d,
          color: PieChartColors[index < 12 ? index : index % 12],
        };
      }),
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={7}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ResponsiveSunburstChart
          data={convertedData}
          sunBurstClickData={sunBurstClickData}
          onClick={setSunBurstClickData}
          onHover={setSunBurstHoverData}
          handleSunBurstText={handleSunBurstText}
        />
      </Grid>
      <Grid item xs={5}>
        <Typography
          display={"flex"}
          justifyContent={"center"}
          fontWeight={500}
          color={theme.palette.dark.main}
          fontSize={16}
        >
          Stock-wise Distribution
        </Typography>
        <div
          style={{
            maxHeight: 300,
            overflow: "auto",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {capSizes?.map((data: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "2px 6px",
                      background:
                        sunBurstHoverData?.id === data?.name ||
                        (sunBurstClickData &&
                          sunBurstClickData.id === data.name)
                          ? `${theme?.palette.primary.main}33`
                          : "",
                    }}
                    ref={
                      sunBurstHoverData?.id === data?.name
                        ? sunBurstRef
                        : undefined
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          background: data?.color,
                        }}
                      />
                      <Typography
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {data?.name || "Unknown"}
                      </Typography>
                    </div>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      fontSize={12}
                    >
                      {Number(data?.value)?.toFixed(2) || 0}%{" "}
                    </Typography>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {grouped?.children?.large?.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "2px 6px",
                      background:
                        sunBurstHoverData?.id === data?.name ||
                        (sunBurstClickData &&
                          sunBurstClickData.id === data.name)
                          ? `${theme?.palette.primary.main}33`
                          : "",
                    }}
                    ref={
                      sunBurstHoverData?.id === data?.name
                        ? sunBurstRef
                        : undefined
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          background: data?.color,
                        }}
                      />
                      <Typography
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {data?.name || "Unknown"}
                      </Typography>
                    </div>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      fontSize={12}
                    >
                      {data?.loc?.toFixed(2) || 0}%{" "}
                    </Typography>
                  </div>
                );
              })}
              <Divider />
              {grouped?.children?.medium?.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "2px 6px",
                      background:
                        sunBurstHoverData?.id === data?.name ||
                        (sunBurstClickData &&
                          sunBurstClickData.id === data.name)
                          ? `${theme?.palette.primary.main}33`
                          : "",
                    }}
                    ref={
                      sunBurstHoverData?.id === data?.name
                        ? sunBurstRef
                        : undefined
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          background: data?.color,
                        }}
                      />
                      <Typography
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {data?.name || "Unknown"}
                      </Typography>
                    </div>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      fontSize={12}
                    >
                      {data?.loc?.toFixed(2) || 0}%{" "}
                    </Typography>
                  </div>
                );
              })}
              <Divider />
              {grouped?.children?.small?.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "2px 6px",
                      background:
                        sunBurstHoverData?.id === data?.name ||
                        (sunBurstClickData &&
                          sunBurstClickData.id === data.name)
                          ? `${theme?.palette.primary.main}33`
                          : "",
                    }}
                    ref={
                      sunBurstHoverData?.id === data?.name
                        ? sunBurstRef
                        : undefined
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          background: data?.color,
                        }}
                      />
                      <Typography
                        textAlign={"left"}
                        fontWeight={600}
                        fontSize={12}
                      >
                        {data?.name || "Unknown"}
                      </Typography>
                    </div>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      fontSize={12}
                    >
                      {data?.loc?.toFixed(2) || 0}%{" "}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SunburstSection;
