import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import ImportBrokerModal from "Pages/CustomBrokerImport/ImportBrokerModal";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { PiArrowSquareDownRightDuotone } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import PortfolioMappingsTab from "../UtilityPages/PortfolioMappingsTab";
import { moneyCommaSeparator } from "utils/MathFunction";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 245, 1),
    renderCell: (params) => {
      return (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioCode}
          </Typography>
        </Grid>
      );
    },
  },
  {
    ...extraColumnsField("Particulars", "transactionType", 180, 1),
    renderCell: (params) => {
      return (
        <Typography
          fontWeight={600}
          style={{
            width: "fit-content",
            background: `${returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            )}33`,
            color: returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            ),
            padding: "4px 8px",
            borderRadius: 6,
          }}
          textTransform={"capitalize"}
        >
          {params?.row?.transactionType?.toLowerCase() === "sell" ||
          params?.row?.transactionType?.toLowerCase() === "buy"
            ? `BILLAMOUNT - ${params?.row?.transactionType}`
            : params?.row?.transactionType}
          &nbsp;
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
              ? `/ ${removeUnderscore(
                  params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                    ?.addedField
                )}`
              : ""}
          </span>
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Debit", "debit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.debit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Credit", "credit", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.credit || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Ledger Balance", "ledgerBalance", 150, 1),
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.ledgerBalance || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Remarks", "remarks", 150, 1),
    valueGetter: (params) => params.row.remarks || "-",
  },
];

const PortfolioLedger = () => {
  const { id } = useParams();

  const {
    state: { commonFilter, userData },
  } = useAppContext();

  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [openImportBrokerModal, setOpenImportBrokerModal] = useState(false);

  const requestBody = {
    take: commonFilter?.portfolioBankLedger?.take,
    skip: commonFilter?.portfolioBankLedger?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioBankLedger?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioBankLedger?.search || "",
    filter: JSON.stringify(commonFilter?.portfolioBankLedger?.customFilter),
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: `/ledger/portfolio/${id}/`,
    key: [QueryKeys.PORTFOLIO_BANK_LEDGER_LIST, requestBody],
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="portfolioBankLedger"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/ledger/portfolio/${id}`}
        name={"bankLedgerList"}
        filter={JSON.stringify(commonFilter?.portfolioBankLedger?.customFilter)}
      />
    );
  };

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns || []}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        name="portfolioBankLedger"
        maxHeight={"calc(100vh - 309px)"}
        tableName="portfolio_bank_ledger_list"
        headerFilter={<TableFiltering name="portfolioBankLedger" />}
        headerComponent={
          <Grid container>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              justifyContent={"flex-end"}
            >
              {/* <Typography
                fontSize={14}
                display={"flex"}
                alignItems={"center"}
                fontWeight={600}
              >
                Available Balance :{"   "}
                <Typography ml={2} fontSize={14} fontWeight={700}>
                  {isFetching ? (
                    <Skeleton animation="wave" width={100} />
                  ) : (
                    `₹ ${moneyCommaSeparator(data?.data?.ledgerBalance || 0)}`
                  )}
                </Typography>
              </Typography>{" "} */}
              {userData?.broker?.adityaBirlaSupport && (
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => setOpenImportBrokerModal(true)}
                  startIcon={<PiArrowSquareDownRightDuotone size={16} />}
                >
                  Import from broker
                </GKButton>
              )}
              <GKButton
                onClick={() => {
                  setOpenMappingModal(true);
                }}
                size="medium"
                variant="outlined"
              >
                Mappings
              </GKButton>
            </Grid>
          </Grid>
        }
      />

      <Show.When isTrue={openMappingModal}>
        <PortfolioMappingsTab
          open={openMappingModal}
          setOpen={setOpenMappingModal}
        />
      </Show.When>

      <Show.When isTrue={openImportBrokerModal}>
        <ImportBrokerModal
          open={openImportBrokerModal}
          setOpen={setOpenImportBrokerModal}
          refetch={refetch}
          format="ADITYA_BIRLA_MONEY_LEDGER"
        />
      </Show.When>
    </>
  );
};

export default PortfolioLedger;
