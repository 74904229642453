import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import { LuClipboardCheck } from "react-icons/lu";
import { PiWarningOctagonLight } from "react-icons/pi";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { extraColumnsField } from "utils/helpers";

interface ViewUploadProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  recordData: any;
  recordName: string;
  recordFormat: string;
  recordID: number;
}

const ReconManagerViewUpload = (props: ViewUploadProps) => {
  const { isModalOpen, setIsModalOpen, recordData } = props;

  const { theme } = ThemeContainer();

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle="View Recon Details"
      width={"70%"}
    >
      <GKClientDataGrid
        name="reconMangerViewUpload"
        density="compact"
        columns={[
          {
            ...extraColumnsField("Company Name", "companyName", 140, 1),
          },
          {
            ...extraColumnsField("Gridkey Quantity", "gridQuantity", 140, 1),
          },
          {
            ...extraColumnsField("Broker Quantity", "brokerQuantity", 140, 1),
          },
          {
            ...extraColumnsField(
              "Quantity Deviation",
              "quantityDeviation",
              160,
              1
            ),
            valueFormatter: (val) => moneyCommaSeparator(val.value),
          },
          {
            ...extraColumnsField(
              "Gridkey Avg. Buy Price",
              "gridAvgBuyPrice",
              150,
              1
            ),
            valueFormatter: (val) => moneyCommaSeparator(val.value),
          },
          {
            ...extraColumnsField(
              "Broker Avg. Buy Price",
              "brokerAvgBuyPrice",
              180,
              1
            ),
            valueFormatter: (val) => moneyCommaSeparator(val.value),
          },
          {
            ...extraColumnsField(
              "Avg. Buy Price Deviation",
              "avgBuyPriceDeviation",
              180,
              1
            ),
            valueFormatter: (val) => moneyCommaSeparator(val.value),
          },
          {
            ...extraColumnsField("Status", "status", 120, 1),
            renderCell: (params) => {
              return params?.row?.status ? (
                <span
                  style={{
                    backgroundColor: `${theme.palette.success.main}22`,
                    padding: 2,
                    borderRadius: 4,
                  }}
                >
                  <LuClipboardCheck color={theme.palette.success.main} />
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: `${theme.palette.error.main}22`,
                    padding: 2,
                    borderRadius: 4,
                  }}
                >
                  <PiWarningOctagonLight color={theme.palette.warning.main} />
                </span>
              );
            },
          },
        ]}
        maxHeight={"calc(100vh - 500px)"}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        rows={
          recordData?.map((d: any, index: number) => {
            return {
              ...d,
              id: index,
            };
          }) || []
        }
      />
    </GKModal>
  );
};

export default ReconManagerViewUpload;
