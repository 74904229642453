import { Grid } from "@mui/material";
import GKModal from "components/GKModal";
import Lottie from "lottie-react";
import { ReactNode } from "react";
import AnimationData from "../assets/Lottie/confirmation.json";
import GKButton from "./GKButton";
import GKTypography from "./GKTypography";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  onConfirm: () => void;
  messageContent?: ReactNode;
  customButtonText?: string;
}

const ConfirmationModal = (props: Props) => {
  const {
    open,
    setOpen,
    title,
    onConfirm,
    messageContent,
    customButtonText = "",
  } = props;

  const modalMessageHandler = () => {
    if (messageContent) return messageContent;
    return `Are you sure you want to ${title.toLowerCase()} this item?`;
  };

  const buttonTextHandle = () => {
    if (customButtonText) return customButtonText;
    return "Send";
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={title}
      footer={
        <GKButton
          variant="outlined"
          color="primary"
          onClick={() => {
            onConfirm();
            setOpen(false);
          }}
        >
          {buttonTextHandle()}
        </GKButton>
      }
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Lottie {...defaultOptions} style={{ height: 100, width: 100 }} />
        </Grid>
        <Grid item xs={7} textAlign={"center"}>
          <GKTypography fontWeight={500} fontSize={15}>
            {modalMessageHandler()}
          </GKTypography>
        </Grid>
      </Grid>
    </GKModal>
  );
};
export default ConfirmationModal;
