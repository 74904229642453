import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { useState } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import EmailEditModal from "./EmailEditModal";

interface Props {
  data: any;
  isLoading: boolean;
}

const EmailReply = (props: Props) => {
  const { data, isLoading } = props;

  const [replyData, setReplyData] = useState({});
  const [openReplyEmailModal, setOpenReplyEmailModal] = useState(false);

  const { mutate: handleDeleteReplyEmail } = useMutation({
    mutationFn: (id: number) =>
      axiosInstance?.delete(`reply-email-portfolio/${id}/`),
    onSuccess: (response: any) => {
      refetchQuery("GET_REPLAY_EMAIL_LIST");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Email", "email", 0, 1),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolio", 0, 1),
      type: "number",
      valueGetter: (params) => params.row?.portfolio?.length || "0",
    },
    {
      ...extraColumnsField("Action", "action", 0, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <IconButton
            color="error"
            className="outline"
            onClick={(event) => {
              event?.stopPropagation();
              handleDeleteReplyEmail(params.row.id);
            }}
          >
            <FiTrash2 />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={3}>
        <Typography fontSize={16} fontWeight={500} color={"#697586"}>
          “Reply to” field configuration
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <GKClientDataGrid
          name="replyTo"
          columns={columns}
          loading={isLoading}
          rows={data || []}
          onRowClick={(params) => {
            setReplyData(params.row);
            setOpenReplyEmailModal(true);
          }}
          columnsButton={false}
          headerComponent={
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={(e) => {
                e.stopPropagation();
                setReplyData({});
                setOpenReplyEmailModal(true);
              }}
            >
              Add new
            </LoadingButton>
          }
        />
      </Grid>
      <Show.When isTrue={openReplyEmailModal}>
        <EmailEditModal
          open={openReplyEmailModal}
          setOpen={setOpenReplyEmailModal}
          data={replyData}
        />
      </Show.When>
    </Grid>
  );
};

export default EmailReply;
