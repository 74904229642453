import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKSideModal from "components/GKSideModal";
import StatusField from "components/StatusField";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  orderId: string | number;
  setOrderId: (value: number) => void;
}

const OrderTradeModal = (props: Props) => {
  const { id } = useParams();

  const { open, setOpen, orderId, setOrderId } = props;

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_ORDER_TRADE_DETAILS"],
    route: `/smallcase-order/${id}/transaction/?order_id=${orderId}`,
  });

  const { mutate: handleAddToTradeBook, isLoading } = useMutation({
    mutationKey: ["ADD_TO_TRADE_BOOK"],
    mutationFn: (data) =>
      axiosInstance.post(`/smallcase-order/${id}/transaction-convert/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleRefreshTrade, isLoading: refreshTradeLoading } =
    useMutation({
      mutationKey: ["REFRESH_TRADE"],
      mutationFn: () =>
        axiosInstance.get(
          `/smallcase-order/${id}/refresh-order-execution/?order_id=${orderId}`
        ),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        refetch();
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const form = useForm({
    defaultValues: {
      orderExecutionId: [],
    },
  });

  return (
    <GKSideModal
      width="70%"
      open={open}
      setOpen={() => {
        setOpen(false);
        setOrderId(null);
      }}
      modalTitle="Order Trade Details"
      footerButton={
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            form="order-trade-form"
            size="medium"
            loading={isLoading}
          >
            Add To Trade Book
          </LoadingButton>
        </Grid>
      }
      titleComponent={
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <LoadingButton
            variant="contained"
            size="medium"
            loading={refreshTradeLoading}
            onClick={() => handleRefreshTrade()}
          >
            Refresh
          </LoadingButton>
        </Grid>
      }
    >
      <form
        id="order-trade-form"
        onSubmit={form.handleSubmit((data: any) => handleAddToTradeBook(data))}
      >
        <Controller
          name="orderExecutionId"
          control={form.control}
          render={({ field }) => (
            <GKClientDataGrid
              name="orderTradeDetails"
              loading={isFetching}
              isRowSelectable={(params: GridRowParams) =>
                params.row.smallcaseStatus === "Complete" &&
                !params.row.isConverted
              }
              checkboxSelection
              onRowSelectionModelChange={(params) => {
                field.onChange(params);
              }}
              rowSelectionModel={field.value}
              maxHeight={"calc(100vh - 355px)"}
              rows={data?.data?.data || []}
              columns={[
                {
                  ...extraColumnsField("Date", "orderDate", 150, 1),
                  valueFormatter: (value) => matchDate(value.value),
                },
                {
                  ...extraColumnsField("company", "company", 150, 1),
                },
                {
                  ...extraColumnsField("NSE/BSE Code", "nse", 170, 1),
                  renderCell: (params) => {
                    return (
                      <Typography>
                        {`${params?.row?.nse || "-"}/${
                          params?.row?.bse || "-"
                        }`}
                      </Typography>
                    );
                  },
                },
                {
                  ...extraColumnsField(
                    "Transaction Type",
                    "transactionType",
                    150,
                    1
                  ),
                  valueFormatter: (params) => handleCapitalize(params?.value),
                },
                {
                  ...extraColumnsField("Order Quantity", "quantity", 150, 1),
                  align: "right",
                  headerAlign: "right",
                  valueGetter: (params) => params?.row?.quantity || "0",
                  valueFormatter: (params: any) =>
                    moneyCommaSeparatorPoint(params?.value),
                },
                {
                  ...extraColumnsField(
                    "Executed Quantity",
                    "filledQuantity",
                    150,
                    1
                  ),
                  align: "right",
                  headerAlign: "right",
                  valueGetter: (params) => params?.row?.filledQuantity || "0",
                  valueFormatter: (params: any) =>
                    moneyCommaSeparatorPoint(params?.value),
                },
                {
                  ...extraColumnsField("Price", "price", 150, 1),
                  align: "right",
                  headerAlign: "right",
                  valueGetter: (params) => params?.row?.price || "0",
                  valueFormatter: (params) => moneyCommaSeparator(params.value),
                },
                {
                  ...extraColumnsField("Status", "smallcaseStatus", 150, 1),
                  renderCell: (item) => item?.row?.smallcaseStatus || "-",
                },
                {
                  ...extraColumnsField(
                    "Added To Trade Book",
                    "isConverted",
                    150,
                    1
                  ),
                  renderCell: (row) => (
                    <StatusField
                      statusString={row?.row?.isConverted.toString()}
                    />
                  ),
                },
                {
                  ...extraColumnsField("Error", "smallcaseError", 200, 1),
                  renderCell: (item) => (
                    <Typography sx={{ whiteSpace: "normal", fontSize: 12 }}>
                      {item?.row?.smallcaseError || "-"}
                    </Typography>
                  ),
                },
              ]}
              showFiltersButton={false}
            />
          )}
        />
      </form>
    </GKSideModal>
  );
};

export default OrderTradeModal;
