import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKSearchSelect from "components/GKSearchSelect";
import { Controller, useFormContext } from "react-hook-form";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

interface Props {
  fields: any[];
}

const BrokerPortfolioMapping = (props: Props) => {
  const { fields } = props;

  const form = useFormContext();

  const {
    state: { portfolios },
  } = useAppContext();

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontSize={16} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Alert
                sx={{ mb: 2 }}
                severity="warning"
                icon={<HiOutlineExclamationTriangle size={14} />}
              >
                The Following portfolio codes we have not found in your account,
                please map it to already existing portfolios.
              </Alert>
              {fields.map((item: any, index: number) => (
                <Grid item xs={12} my={1} key={item.id}>
                  <Controller
                    name={`step2.portfolio.${index}.portfolio`}
                    control={form.control}
                    render={({ field }) => (
                      <GKSearchSelect
                        {...field}
                        disableClearable
                        options={portfolios || []}
                        getOptionLabel={(option) => option?.name || option}
                        inputLabel={item?.value}
                        onChange={(e, val) => {
                          field.onChange(val.value);
                        }}
                        value={
                          portfolios?.find(
                            (data: any) => data.value === field.value
                          )?.name || ""
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </Grid>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrokerPortfolioMapping;
