import { Button, FormControl, Grid, Switch, Typography } from "@mui/material";
import GKTextField from "components/GKTextField";
import useAccountSetting from "Pages/AccountSetting/useAccountSetting";
import { Controller, useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";

const EquityBasketSetting = () => {
  useDocumentTitle("Basket");

  const { getCompanySetting, handleUpdateCompanySetting } = useAccountSetting();

  const form = useForm({
    defaultValues: {
      orderPriceRangePercentage: 0,
      capToLedgerBalance: false,
      useBrokerBalance: false,
    },
    values: {
      orderPriceRangePercentage:
        getCompanySetting?.data?.orderPriceRangePercentage,
      capToLedgerBalance: getCompanySetting?.data?.capToLedgerBalance,
      useBrokerBalance: getCompanySetting?.data?.useBrokerBalance,
    },
  });

  return (
    <form
      id="equity-basket-setting-form"
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values)
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
          <Controller
            name="capToLedgerBalance"
            control={form.control}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
              />
            )}
          />
          <Typography fontSize={16}>
            Limit allocation amount to ledger balance
          </Typography>
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
          <Controller
            name="useBrokerBalance"
            control={form.control}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
              />
            )}
          />
          <Typography fontSize={16}>
            Use available margin for allocation/rebalance/exit
          </Typography>
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} gap={2}>
          <Typography width={"70%"}>
            Price range percentage for sending orders to clients
          </Typography>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="orderPriceRangePercentage"
              control={form.control}
              render={({ field }) => (
                <GKTextField
                  {...field}
                  sx={{ width: "20%" }}
                  type="number"
                  inputLabel={""}
                  onChange={(e: any) => field.onChange(e.target.valueAsNumber)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EquityBasketSetting;
