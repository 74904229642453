import { Grid } from "@mui/material";
import GKModal from "components/GKModal";
import { useState } from "react";
import ReactCrop, { type Crop } from "react-image-crop";

interface Props {
  open?: any;
  setOpen?: any;
  img?: any;
}

const EditLogoModal = (props: Props) => {
  const { open, setOpen, img } = props;
  const [crop, setCrop] = useState<Crop>();

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle="Crop Logo">
      <Grid container>
        <Grid item xs={12}>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img
              onError={({ currentTarget }) => {
                currentTarget.src = "/Logos/errorImg.svg";
              }}
              src={img}
              alt=""
            />
          </ReactCrop>
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default EditLogoModal;
