import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import IntroSection from "./IntroSection";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";

const WealthWise = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container>
        <div ref={myRef} />
        <Grid item xs={12}>
          <IntroSection />
        </Grid>
        <Grid item xs={12}>
          <Section1 />
        </Grid>
        <Grid item xs={12}>
          <Section2 />
        </Grid>
        <Grid item xs={12}>
          <Section3 />
        </Grid>
        <Grid item xs={12}>
          <Section4 />
        </Grid>
        <Grid item xs={12}>
          <Section5 />
        </Grid>
        <Grid item xs={12}>
          <Section6 />
        </Grid>
        <Grid item xs={12}>
          <Section7 />
        </Grid>
        <Grid item xs={12}>
          <Section8 />
        </Grid>
        <Grid item xs={12}>
          <Section9 />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default WealthWise;
