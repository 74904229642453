import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Controller } from "react-hook-form";
import useAutoImport from "./useAutoImport";

interface AutoImportProps {
  open: any;
  setOpen: any;
}

const AutoImportModal = (props: AutoImportProps) => {
  const { open, setOpen } = props;

  const {
    handleFormSubmit,
    addLoading,
    portfoliosDataLoading,
    portfolioList,
    brokersList,
    brokerLoading,
    control,
    handleSubmit,
    id,
  } = useAutoImport({ setOpen });

  return (
    <GKModal
      modalTitle="Setup Auto Import"
      open={open}
      setOpen={setOpen}
      footer={
        <LoadingButton
          form="auto-import-form"
          type="submit"
          loading={addLoading}
          variant="contained"
        >
          Save
        </LoadingButton>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} id="auto-import-form">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="portfolio"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    loading={portfoliosDataLoading || brokerLoading}
                    disableClearable
                    disabled={Boolean(id)}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    onChange={(e, val) => {
                      field.onChange(val);
                    }}
                    inputLabel="Select Portfolio"
                    value={portfolioList?.data?.find(
                      (dataItem: any) => dataItem?.id === Number(field?.value)
                    )}
                    options={portfolioList?.data || []}
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Portfolio" />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                control={control}
                name="password"
                render={({ field }) => {
                  return (
                    <GKTextField
                      {...field}
                      loading={portfoliosDataLoading || brokerLoading}
                      inputLabel="Contract Notes Password"
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="broker"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    onChange={(e, val) => {
                      field.onChange(val);
                    }}
                    loading={portfoliosDataLoading || brokerLoading}
                    inputLabel="Select broker"
                    options={brokersList?.data || []}
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Broker" />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AutoImportModal;
