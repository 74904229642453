import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import IncomeExpenseEditModal from "./IncomeExpenseEditModal";
import IncomeExpenseModal from "./IncomeExpenseModal";

interface Props {
  id?: number | string;
  name?: string;
  tableName?: string;
}

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 150, 1),
    valueFormatter: (value) => matchDate(value.value),
  },
  {
    ...extraColumnsField("Portfolio", "clientName", 250, 1),
    renderCell: (params) => {
      return (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography
            fontWeight={700}
            maxWidth={"100%"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {params?.row?.clientName}
          </Typography>
          <Typography fontSize={12}>
            {params?.row?.portfolioName} - {params?.row?.portfolioCode}
          </Typography>
        </Grid>
      );
    },
  },
  {
    ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
    valueFormatter: (params) => handleCapitalize(params?.value),
    renderCell: (params) => {
      return (
        <Typography
          fontWeight={600}
          style={{
            width: "fit-content",
            background: `${returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            )}33`,
            color: returnParticularColor(
              params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ?.addedField || params?.row?.transactionType
            ),
            padding: "4px 8px",
            borderRadius: 6,
          }}
          textTransform={"capitalize"}
        >
          {params?.row?.transactionType?.toLowerCase() === "sell" ||
          params?.row?.transactionType?.toLowerCase() === "buy"
            ? `BILLAMOUNT - ${params?.row?.transactionType}`
            : params?.row?.transactionType}
          &nbsp;
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
              ? `/ ${removeUnderscore(
                  params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                    ?.addedField
                )}`
              : ""}
          </span>
        </Typography>
      );
    },
  },
  {
    ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.billAmount || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("TDS", "tax", 150, 1),
    type: "number",
    valueGetter: (params) => params.row.tax || "0",
    valueFormatter: (params) => moneyCommaSeparator(params.value),
  },
  {
    ...extraColumnsField("Remarks", "remarks", 150, 1),
    valueGetter: (params) => params.row.remarks || "-",
  },
];

const IncomeExpense = (props: Props) => {
  useDocumentTitle("Income/Expense");

  const { id, name, tableName } = props;

  const {
    state: { commonFilter },
  } = useAppContext();

  const [openIncomeExpenseModal, setOpenIncomeExpenseModal] = useState(false);
  const [incomeExpenseData, setIncomeExpenseData] = useState({} as any);
  const [openIncomeExpenseEditModal, setOpenIncomeExpenseEditModal] =
    useState(false);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const filterName = name
    ? commonFilter["portfolioIncomeExpense"]
    : commonFilter?.incomeExpense;

  const requestBody = {
    take: filterName?.take,
    skip: filterName?.skip,
    sort: JSON.stringify(
      filterName?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: filterName?.search || "",
    filter: JSON.stringify(filterName?.customFilter),
  };

  const { data, isFetching } = useFetchQuery({
    route: id ? `/income_expense/portfolio/${id}/` : "/income_expense/",
    key: [
      id
        ? QueryKeys.PORTFOLIO_INCOME_EXPENSE_LIST
        : QueryKeys.INCOME_EXPENSE_LIST,
      requestBody,
    ],
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name={name ? "portfolioIncomeExpense" : "incomeExpense"}
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv
        path={id ? `/income_expense/portfolio/${id}` : "/income_expense"}
        name={"ExportIncomeExpense"}
        filter={JSON.stringify(filterName?.customFilter)}
      />
    );
  };

  const { mutate: bulkDeleteIncomeExpense, isLoading } = useMutation({
    mutationFn: (ids: any) =>
      axiosInstance.delete(`/income_expense/bulk_delete/`, {
        data: { ids: ids },
      }),
    onSuccess: (response) => {
      refetchQuery(
        id
          ? QueryKeys.PORTFOLIO_INCOME_EXPENSE_LIST
          : QueryKeys.INCOME_EXPENSE_LIST
      );
      setSelectedIds([]);
      setDeleteConfirmation(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <Grid container>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        searchPlaceholder="Search for income/expense"
        columns={columns}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        exportCsv={exportCsv}
        name={name ? "portfolioIncomeExpense" : "incomeExpense"}
        maxHeight={"calc(100vh - 320px)"}
        onRowClick={(params) => {
          setIncomeExpenseData(params?.row);
          setOpenIncomeExpenseEditModal(true);
        }}
        headerComponent={
          <Grid container gap={1} justifyContent={"end"}>
            <Show.When isTrue={selectedIds?.length !== 0}>
              <LoadingButton
                variant="outlined"
                loading={isLoading}
                size="medium"
                color="error"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              >
                Bulk Delete
              </LoadingButton>
            </Show.When>
            <Show.When isTrue={selectedIds?.length !== 0}>
              <GKButton
                variant="outlined"
                color="dark"
                size="medium"
                onClick={() => {
                  setSelectedIds([]);
                }}
              >
                Cancel
              </GKButton>
            </Show.When>
            <Show.When isTrue={selectedIds?.length === 0}>
              <GKButton
                variant="contained"
                size="medium"
                onClick={() => setOpenIncomeExpenseModal(true)}
                startIcon={<CgMathPlus size={14} />}
              >
                Add Income/Expense
              </GKButton>
            </Show.When>
          </Grid>
        }
        tableName={tableName || "income_expense_list"}
        headerFilter={
          <TableFiltering
            name={name ? "portfolioIncomeExpense" : "incomeExpense"}
          />
        }
      />

      <Show.When isTrue={openIncomeExpenseModal}>
        <IncomeExpenseModal
          open={openIncomeExpenseModal}
          setOpen={setOpenIncomeExpenseModal}
          portfolioId={id}
        />
      </Show.When>

      <Show.When isTrue={openIncomeExpenseEditModal}>
        <IncomeExpenseEditModal
          open={openIncomeExpenseEditModal}
          setOpen={setOpenIncomeExpenseEditModal}
          data={incomeExpenseData}
          portfolioId={id}
        />
      </Show.When>

      <Show.When isTrue={deleteConfirmation}>
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => bulkDeleteIncomeExpense(selectedIds)}
          title={"Bulk"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected ledger statement & once deleted it cannot be reverted?`}
        />
      </Show.When>
    </Grid>
  );
};

export default IncomeExpense;
