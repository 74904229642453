import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { useAppContext } from "AppContext";
import DashboardLayout from "layouts/DashboardLayout";
import CompanyInformationPage from "Pages/CompanyInformation";
import Mandate from "Pages/Mandate";
import MarketingMaterial from "Pages/MarketingMaterial";
import PaymentGateway from "Pages/PaymentGateway";
import RightIssue from "Pages/RightIssue";
import CompanyMappingPreferences from "Pages/Settings/CompanyMappingPreferences";
import CustomAgreement from "Pages/Settings/CustomAgreement";
import EquityBasketSetting from "Pages/Settings/EquityBasketSetting";
import ExecutionSheet from "Pages/Settings/ExecutionSheet";
import GeneralPreferences from "Pages/Settings/GeneralPreferences";
import RevenueManagerSettings from "Pages/Settings/RevenueManagerSettings";
import RiskProfiling from "Pages/Settings/RiskProfiling";
import SmtpSetup from "Pages/Settings/SmtpSetup";
import UserProfile from "Pages/Settings/UserProfile";
import { FiShoppingBag } from "react-icons/fi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import {
  LuFileCheck2,
  LuFileSignature,
  LuFileSpreadsheet,
  LuMail,
  LuMegaphone,
  LuSettings,
  LuSettings2,
  LuUserCircle2,
  LuUsers2,
} from "react-icons/lu";
import { RiListRadio } from "react-icons/ri";
import { TbInvoice, TbSitemap, TbTransactionRupee } from "react-icons/tb";
import { paletteLightTheme } from "utils/ColorTheme";
import { UserRoleTypes } from "utils/common";
import useDocumentTitle from "utils/useDocumentTitle";
import LedgerSettings from "./LedgerSettings";
import PortfolioMetrics from "./PortfolioMetrics";
import RoleWisePermission from "./RoleWisePermission";

const Sidebar = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const menuItems = [
  {
    text: "Account",
    children: [
      {
        text: "User Profile",
        component: "USER_PROFILE",
        icon: LuUserCircle2,
      },
      {
        text: "Company Profile",
        component: "COMPANY_PROFILE",
        icon: HiOutlineOfficeBuilding,
      },
    ],
  },
  {
    text: "Onboarding",
    children: [
      {
        text: "Risk Profiling",
        component: "RISK_PROFILING",
        icon: RiListRadio,
      },
      {
        text: "Custom Agreement",
        component: "CUSTOM_AGREEMENT",
        icon: LuFileSignature,
      },
    ],
  },
  {
    text: "Preferences",
    children: [
      {
        text: "Portfolio Metrics",
        component: "PORTFOLIO_METRICS",
        icon: LuSettings2,
      },
      {
        text: "Invoice Annexure",
        component: "ANNEXURE_FOR_INVOICE",
        icon: TbInvoice,
      },
      {
        text: "Company Mapping",
        component: "COMPANY_MAPPING",
        icon: TbSitemap,
      },
      {
        text: "Right Issue",
        component: "RIGHT_ISSUE",
        icon: LuFileCheck2,
      },
      {
        text: "Basket",
        component: "BASKET",
        icon: FiShoppingBag,
      },
      {
        text: "Ledger",
        component: "LEDGER",
        icon: TbTransactionRupee,
      },
      {
        text: "General Preferences",
        component: "GENERAL_PREFERENCES",
        icon: LuSettings,
      },
    ],
  },
  {
    text: "Roles & Permissions",
    children: [
      {
        text: "Employee",
        component: "EMPLOYEE",
        icon: LuUsers2,
      },
      {
        text: "Client",
        component: "CLIENT",
        icon: LuFileSpreadsheet,
      },
      {
        text: "Partner",
        component: "PARTNER",
        icon: LuFileSpreadsheet,
      },
    ],
  },
  {
    text: "Integration & Setup",
    children: [
      {
        text: "Execution Sheet",
        component: "EXECUTION_SHEET",
        icon: LuFileSpreadsheet,
      },
      {
        text: "Email Configuration",
        component: "EMAIL_CONFIGURATION",
        icon: LuMail,
      },
      {
        text: "Marketing Material",
        component: "MARKETING_MATERIAL",
        icon: LuMegaphone,
      },
      // {
      //   text: "Payment Gateway",
      //   component: "PAYMENT_GATEWAY",
      //   icon: LuCreditCard,
      // },
      // {
      //   text: "Mandate",
      //   component: "MANDATE",
      //   icon: LuFileCog,
      // },
    ],
  },
];

interface SidebarPropsInterface {
  dispatch: any;
  settingTab: {
    type: string;
    name: string;
  };
}

const SidebarMenu = (props: SidebarPropsInterface) => {
  const { dispatch, settingTab } = props;

  return (
    <Sidebar>
      <List sx={{ pr: 2 }}>
        {menuItems.map((item, index) => {
          return (
            <div key={index}>
              <Typography
                mt={1}
                fontSize={12}
                fontWeight={500}
                color={"#697586"}
                px={2}
                py={1}
              >
                {item?.text}
              </Typography>
              {item?.children &&
                item?.children?.map((item) => {
                  return (
                    <ListItem
                      button
                      key={item.text}
                      onClick={() =>
                        dispatch({
                          type: "SETTING_TAB",
                          data: { name: item.text, type: item.component },
                        })
                      }
                      sx={{
                        backgroundColor:
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}11`
                            : "",
                        color:
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}`
                            : "",
                        borderRadius: "8px",
                      }}
                    >
                      <item.icon
                        size={16}
                        color={
                          settingTab.type === item.component
                            ? `${paletteLightTheme.palette.primary.main}`
                            : "#4B5565"
                        }
                      />
                      &nbsp;&nbsp;
                      <ListItemText
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color:
                            settingTab.type === item.component
                              ? `${paletteLightTheme.palette.primary.main}`
                              : "#697586",
                          "& .MuiTypography-root": {
                            lineHeight: "16px !important",
                          },
                        }}
                        primary={item.text}
                      />
                    </ListItem>
                  );
                })}
            </div>
          );
        })}
      </List>
    </Sidebar>
  );
};

const AccountSetting = () => {
  useDocumentTitle("Settings");

  const {
    state: { sidebarOpen, settingTab, userData },
    dispatch,
  } = useAppContext();

  const renderComponent = () => {
    switch (settingTab.type) {
      case "USER_PROFILE":
        return <UserProfile />;
      case "COMPANY_PROFILE":
        return <CompanyInformationPage />;
      case "RISK_PROFILING":
        return <RiskProfiling />;
      case "CUSTOM_AGREEMENT":
        return <CustomAgreement />;
      case "PORTFOLIO_METRICS":
        return <PortfolioMetrics />;
      case "ANNEXURE_FOR_INVOICE":
        return <RevenueManagerSettings />;
      case "COMPANY_MAPPING":
        return <CompanyMappingPreferences />;
      case "RIGHT_ISSUE":
        return <RightIssue />;
      case "BASKET":
        return <EquityBasketSetting />;
      case "GENERAL_PREFERENCES":
        return <GeneralPreferences />;
      case "EXECUTION_SHEET":
        return <ExecutionSheet />;
      case "EMAIL_CONFIGURATION":
        return <SmtpSetup />;
      case "MARKETING_MATERIAL":
        return <MarketingMaterial />;
      case "EMPLOYEE":
        return <RoleWisePermission type="Employee" />;
      case "CLIENT":
        return <RoleWisePermission type="Client" />;
      case "PARTNER":
        return <RoleWisePermission type="Partner" />;
      case "PAYMENT_GATEWAY":
        return <PaymentGateway />;
      case "MANDATE":
        return <Mandate />;
      case "LEDGER":
        return <LedgerSettings />;
      default:
        return <Typography variant="h4">Page not found!</Typography>;
    }
  };

  return (
    <DashboardLayout title="Account Setting">
      <Grid container display={"flex"}>
        {userData?.userRole !== UserRoleTypes.client && (
          <Grid
            item
            width={sidebarOpen ? "21%" : "20%"}
            height={"calc(100vh - 100px)"}
            overflow={"auto"}
          >
            <SidebarMenu dispatch={dispatch} settingTab={settingTab} />
          </Grid>
        )}
        <Grid
          item
          xs={userData?.userRole === UserRoleTypes.client && 12}
          width={sidebarOpen ? "79%" : "80%"}
          p={"8px 16px"}
          height={"calc(100vh - 100px)"}
          overflow={"auto"}
        >
          <Typography fontSize={24} fontWeight={500} mb={3}>
            {settingTab.name}
          </Typography>
          {renderComponent()}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default AccountSetting;
