import { useParams } from "react-router-dom";

import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import { useFetchQuery } from "hooks/useQueries";
import { leadStatusColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

interface Props {
  open: boolean;
  setOpen: any;
}

const LogsModal = (props: Props) => {
  const { id } = useParams();

  const { open, setOpen } = props;

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.STATUS_LOG],
    route: `/lead-update-status-source/?lead_id=${id}&type=Status`,
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Status History"}
      width={"60%"}
    >
      <GKClientDataGrid
        name="logsModal"
        showSearch={false}
        showFilters={false}
        showHeaderGrid={false}
        loading={isFetching}
        columns={leadStatusColumns}
        rows={data?.data?.data || []}
      />
    </GKModal>
  );
};

export default LogsModal;
