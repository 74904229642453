import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import {
  AvailableCash,
  BankLedger,
  CashManagementMainPage,
  IncomeExpense,
  MutualFunds,
  Transaction,
} from "Pages";
import ReportTab from "Pages/ReportManager/ReportTab";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";

const ReportsMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const tabs = [
    { label: "Report Manager", component: ReportTab },
    { label: "Ledger", component: BankLedger },
    { label: "Ledger Balance", component: AvailableCash },
    { label: "Equity/ETF", component: Transaction },
    { label: "Mutual Funds", component: MutualFunds },
    { label: "Cash Entry", component: CashManagementMainPage },
    { label: "Income/Expense", component: IncomeExpense },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: "#fff",
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfReports: val },
                });
              }}
              value={tabValue?.tabValueOfReports}
              variant="scrollable"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableTouchRipple />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfReports === index}
            >
              <AnimationComponent>
                <tab.component />
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ReportsMenu;
