import {
  Alert,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { FaChartBar } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { TbUserSquareRounded } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import PortfolioHoldingTradeBook from "./PortfolioHoldingsDetails/PortfolioHoldingTradeBook";
import PortfolioLongTermHolding from "./PortfolioHoldingsDetails/PortfolioLongTermHolding";
import PortfolioRealisedInventory from "./PortfolioRealisedInventory";
import { handleReturnPositiveNegative } from "utils/colorHelper";

const PortfolioHoldingTransactions = () => {
  const { state } = useLocation();

  const { theme } = ThemeContainer();

  const [transactionTabs, setTransactionTabs] = useState(0);

  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.portfolioHoldingTransaction?.take,
    skip: commonFilter?.portfolioHoldingTransaction?.skip,
    sort: JSON.stringify(
      commonFilter?.portfolioHoldingTransaction?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.portfolioHoldingTransaction?.search || "",
    folioNo: state?.folioNo,
  };

  const { data, isFetching } = useFetchQuery({
    key: ["HOLDINGS_TRANSACTIONS", requestBody],
    route: `/${state?.type}/${state?.holdingId}/company_transactions/`,
    requestBody: {
      gcode: state?.gcode,
      ...requestBody,
    },
  });

  const { data: companyTransactionSummary } = useFetchQuery({
    key: ["GET_COMPANY_DETAILS_DATA"],
    route: `/holdings/${state?.gcode}/${state.type}/?portfolios=[${state?.holdingId}]`,
  });

  const hasNegativeHoldings =
    data?.data?.data?.length === 0 ||
    data?.data?.data?.filter((dataItem: any) => !dataItem?.valid)?.length !== 0;

  return (
    <DashboardLayout title="Portfolio Trade Book">
      <Grid container spacing={1}>
        {!isFetching && hasNegativeHoldings && (
          <Grid item xs={12}>
            <Alert severity="error">
              We found some discrepancies in the transactions of this asset.
            </Alert>
          </Grid>
        )}
        <Grid
          item
          xs={companyTransactionSummary?.data?.instType === "MF" ? 10 : 7}
        >
          <Typography
            fontWeight={600}
            fontSize={20}
            display={"flex"}
            gap={2}
            alignItems="center"
          >
            {companyTransactionSummary?.data?.fullName}
            {companyTransactionSummary?.data?.folioNo && (
              <span
                style={{
                  fontSize: 14,
                  color: "#697586",
                }}
              >
                {` Folio Number: ${companyTransactionSummary?.data?.folioNo}`}
              </span>
            )}
          </Typography>
          <Typography fontWeight={600} fontSize={16}>
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: "#4B5565",
                marginRight: 4,
              }}
            >
              CMP
            </span>{" "}
            {companyTransactionSummary?.data?.cmp}{" "}
            <span
              style={{
                color: handleReturnPositiveNegative(
                  companyTransactionSummary?.data?.todaysGainPercentage
                ),
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              ( {companyTransactionSummary?.data?.todaysGainPercentage}% )
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={companyTransactionSummary?.data?.instType === "MF" ? 2 : 5}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  {companyTransactionSummary?.data?.instType !== "MF" && (
                    <>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: "8px 16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 8,
                          }}
                        >
                          <Typography fontSize={12} color={"#4B5565"}>
                            NSE
                          </Typography>
                          <Typography fontWeight={600} fontSize={14}>
                            {companyTransactionSummary?.data?.nse || "-"}{" "}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: `solid 1px ${theme.palette.grey[300]}`,
                          borderBottom: "none",
                          padding: "8px 16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 8,
                          }}
                        >
                          <Typography fontSize={12} color={"#4B5565"}>
                            BSE
                          </Typography>
                          <Typography fontWeight={600} fontSize={14}>
                            {companyTransactionSummary?.data?.bse || "-"}
                          </Typography>
                        </div>
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: "8px 16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 8,
                      }}
                    >
                      <Typography fontSize={12} color={"#4B5565"}>
                        ISIN
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {companyTransactionSummary?.data?.isin || "-"}{" "}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={"#697586"}
            fontSize={12}
          >
            <TbUserSquareRounded size={14} style={{ marginRight: 4 }} />
            Client Name:
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {companyTransactionSummary?.data?.clientName}
            </span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color={"#697586"}
            fontSize={12}
          >
            <FaChartBar size={14} style={{ marginRight: 4 }} />
            Portfolio Name/Code:
            <span
              style={{
                color: "#202939",
                marginLeft: 4,
              }}
            >
              {companyTransactionSummary?.data?.portfolioName}/
              {companyTransactionSummary?.data?.portfolioCode}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Holding Qty.
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {companyTransactionSummary?.data?.rawQuantity}
                      <span
                        style={{
                          display: "flex",
                          fontSize: 12,
                          color: "#006580",
                          fontWeight: 400,
                        }}
                      >
                        &nbsp;
                        <img src="/Logos/longTerm.svg" alt="" />
                        &nbsp;
                        {companyTransactionSummary?.data?.ltHoldingsCount}
                      </span>
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Avg. Buy Price
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.avgBuyPrice
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Invested Value
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.investedAmount
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderTop: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Total Holding Value{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.currentAmount
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderTop: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Total Buy Amount
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.totalBuyAmount
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderTop: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Total Sell Amount
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.totalSellAmount
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                      width: "50%",
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      Total Gain{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {numberToCurrency(
                        companyTransactionSummary?.data?.totalGain
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      padding: 16,
                      width: "50%",
                    }}
                  >
                    <Typography fontSize={12} color={"#4B5565"}>
                      XIRR{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {`${companyTransactionSummary?.data?.xirr?.toFixed(
                        2
                      )}%` || "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      borderRight: `solid 1px ${theme.palette.grey[300]}`,
                      borderTop: `solid 1px ${theme.palette.grey[300]}`,
                      borderBottom: "none",
                      padding: 16,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={2.4}>
                        <Typography
                          fontSize={12}
                          color={"#4B5565"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Unrealised Gain
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(
                            companyTransactionSummary?.data?.unrealisedGain
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2.4}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <span
                          style={{
                            background: "#F8FAFC",
                            borderRadius: 4,
                            height: 24,
                            width: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GoPlus />
                        </span>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Typography
                          fontSize={12}
                          color={"#4B5565"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Realised Gain{" "}
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(
                            companyTransactionSummary?.data?.realisedGain
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2.4}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <span
                          style={{
                            background: "#F8FAFC",
                            borderRadius: 4,
                            height: 24,
                            width: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GoPlus />
                        </span>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Typography
                          fontSize={12}
                          color={"#4B5565"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1}
                        >
                          Dividend{" "}
                        </Typography>
                        <Typography fontWeight={600} fontSize={14}>
                          {numberToCurrency(
                            companyTransactionSummary?.data?.dividend
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Tabs
            onChange={(e, val) => {
              setTransactionTabs(val);
            }}
            value={transactionTabs}
          >
            <Tab label="Trade Book" key="tradeBook" />
            <Tab label="Inventory" key="inventory" />
            <Tab label="Realised Inventory" key="realisedInventory" />
          </Tabs>
          <Divider />
        </Grid>
        <Show.When isTrue={transactionTabs === 0}>
          <Grid item xs={12}>
            <PortfolioHoldingTradeBook />
          </Grid>
        </Show.When>
        <Show.When isTrue={transactionTabs === 1}>
          <Grid item xs={12}>
            <PortfolioLongTermHolding portfolioId={state?.holdingId} />
          </Grid>
        </Show.When>
        <Show.When isTrue={transactionTabs === 2}>
          <Grid item xs={12}>
            <PortfolioRealisedInventory portfolioId={state?.holdingId} />
          </Grid>
        </Show.When>
      </Grid>
    </DashboardLayout>
  );
};

export default PortfolioHoldingTransactions;
