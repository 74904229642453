import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { SelectOption } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useUpdateLead = () => {
  const { id } = useParams();

  const {
    state: { cityList, countryList, stateList },
    dispatch,
  } = useAppContext();

  const [leadAssignedTo, setLeadAssignedTo] = useState<SelectOption[]>([]);

  const [statusList, setStatusList] = useState<SelectOption[]>([]);

  const [sourceList, setSourceList] = useState<SelectOption[]>([]);

  const [addStatusModal, setAddStatusModal] = useState(false);

  const [type, setType] = useState("STATUS");

  const [updateValue, setUpdateValue] = useState({});

  const { data: leadData, isFetching } = useFetchQuery({
    key: [QueryKeys.GET_LEAD],
    route: `/lead/${id}/`,
  });

  const [isOpenLogsModal, setIsOpenLogsModal] = useState(false);

  const form = useForm({
    defaultValues: {
      name: "",
      companyName: "",
      leadValue: 0,
      position: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      description: "",
      phoneNumber: "",
      lastContacted: "",
      email: "",
      tags: "",
      leadAssignedTo: "",
      sourceOfLead: "",
      leadStatus: "",
      panNumber: "",
    },
    values: leadData?.data?.data,
  });

  const { isLoading: assignedRefetch } = useFetchQuery({
    key: [QueryKeys.EMPLOYEE_LIST],
    route: "/employees/all/",
    success: (data: any) => {
      setLeadAssignedTo(
        data?.data?.map((item: any) => ({
          ...item,
          name: `${item?.name || "-"}`,
          value: item?.id,
        }))
      );
    },
  });

  const { refetch: statusRefetch, isFetching: statusFetching } = useFetchQuery({
    key: [QueryKeys.GET_STATUS],
    route: "/lead-status/",
    success: (data: any) => {
      refetchQuery("GET_ALL_LEAD_LOGS");
      setStatusList(
        data?.data?.data?.map((status: any) => ({
          ...status,
          name: status.leadstatus,
          value: status.id,
        }))
      );
    },
  });

  const { refetch: sourceRefetch } = useFetchQuery({
    key: [QueryKeys.GET_SOURCE],
    route: "/lead-source/",
    success: (data: any) => {
      refetchQuery("GET_ALL_LEAD_LOGS");
      setSourceList(
        data?.data?.data?.map((s: any) => ({
          name: s.leadsource,
          value: s.id,
        }))
      );
    },
  });

  const { mutate: updateLead } = useMutation({
    mutationFn: (data: any) => axiosInstance.patch(`/lead/${id}/`, data),
    onSuccess: (response) => {
      refetchQuery("GET_ALL_LEAD_LOGS");
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteStatus } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/lead-status/${data}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      statusRefetch();
      refetchQuery("GET_ALL_LEAD_LOGS");
      refetchQuery(QueryKeys.GET_LEAD);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteSource } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/lead-source/${data}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);

      refetchQuery("GET_ALL_LEAD_LOGS");
      sourceRefetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return {
    leadAssignedTo,
    statusList,
    sourceList,
    statusRefetch,
    sourceRefetch,
    updateLead,
    handleDeleteStatus,
    handleDeleteSource,
    assignedRefetch,
    addStatusModal,
    setAddStatusModal,
    type,
    setType,
    updateValue,
    setUpdateValue,
    isOpenLogsModal,
    setIsOpenLogsModal,
    form,
    isFetching,
    statusFetching,
    leadData,
    countryList,
    dispatch,
    cityList,
    stateList,
  };
};
