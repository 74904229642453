import { Alert, Grid, TextField, Typography } from "@mui/material";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import { useFormik } from "formik";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import ApproveAutoContract from "./ApproveAutoContract";

const ApproveModal = ({
  open,
  setOpen,
  nullCompanies,
  approveCompanyId,
}: any) => {
  const { theme } = ThemeContainer();

  const {
    state: { userData: userInfo },
  } = useAppContext();

  const [approveModalData, setApproveModalData] = useState<any>([]);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const { data: companyNames } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: "/intruments/list/",
    staleTime: 24 * 60 * 60 * 1000,
  });

  const companyFormik = useFormik({
    initialValues: {
      nullCompanies: nullCompanies || [],
    },
    onSubmit: async (values) => {
      await axiosInstance
        .post(`/advisor-company-mappings/`, values.nullCompanies)
        .then(async () => {
          await axiosInstance
            .post(`/transaction_upload/review/?id=${approveCompanyId}`)
            .then((reviewApiResponse) => {
              setApproveModalData(reviewApiResponse?.data?.transactions);
              setIsApproveModalOpen(true);
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again."
              );
            });
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          );
        });
    },
  });

  return (
    <>
      <GKModal
        modalTitle={"Company Mapping"}
        open={open}
        setOpen={setOpen}
        width={"40%"}
        footer={
          <Grid container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="contained"
              size={"medium"}
              type="submit"
              form="company-mapping-form"
            >
              Submit
            </GKButton>
          </Grid>
        }
      >
        <form
          onSubmit={companyFormik?.handleSubmit}
          id={"company-mapping-form"}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} my={1}>
              <Alert severity="info">
                The following companies were not found in our database, please
                map them to companies which are present in our database.
              </Alert>
            </Grid>
            {companyFormik.values.nullCompanies &&
              companyFormik.values.nullCompanies?.length !== 0 &&
              companyFormik.values.nullCompanies.map(
                (item: any, index: number) => (
                  <Grid item xs={12} my={1} key={index}>
                    <GKSearchSelect
                      disableClearable
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <Typography
                            color={theme.palette.light.main}
                            fontSize={8}
                            style={{
                              background:
                                option?.instType === "EQ"
                                  ? theme.palette.secondary.main
                                  : theme.palette.warning.main,
                              padding: "2px 4px",
                              borderRadius: 4,
                            }}
                          >
                            {option?.instType}
                          </Typography>
                          <Typography whiteSpace={"nowrap"}>
                            {option?.name}
                          </Typography>
                        </li>
                      )}
                      options={companyNames?.data?.data || []}
                      getOptionLabel={(option) => option?.companyName || option}
                      inputLabel={item?.advisorCompany}
                      renderInput={(params) => (
                        <TextField
                          name={`nullCompanies.${index}.value`}
                          {...params}
                        />
                      )}
                      onChange={(e, val) => {
                        companyFormik?.setFieldValue(
                          `nullCompanies.${index}.value`,
                          val.gridCode
                        );
                        companyFormik?.setFieldValue(
                          `nullCompanies.${index}.nseBseCode`,
                          val.bse || val.nse
                        );
                        companyFormik?.setFieldValue(
                          `nullCompanies.${index}.company`,
                          userInfo.companyId
                        );
                      }}
                      onBlur={companyFormik?.handleBlur}
                      value={
                        companyNames?.data?.data?.find(
                          (data: any) =>
                            data?.gridCode ===
                            companyFormik.values.nullCompanies[index].value
                        )?.companyName
                      }
                    />
                  </Grid>
                )
              )}
          </Grid>
        </form>
      </GKModal>

      {isApproveModalOpen && (
        <ApproveAutoContract
          open={isApproveModalOpen}
          setOpen={setIsApproveModalOpen}
          recordID={approveCompanyId}
          recordData={approveModalData}
          setApproveCompanyModal={setOpen}
          approveCompanyModal={open}
        />
      )}
    </>
  );
};

export default ApproveModal;
