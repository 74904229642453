import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import GKDatePicker from "components/GKDatePicker";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

interface FormErrors {
  step1: {
    fromDate?: {
      value: boolean;
      message: string;
    };
    toDate?: {
      value: boolean;
      message: string;
    };
  };
}

const ImportBrokerStepOne = () => {
  const { id } = useParams();
  const form = useFormContext();

  const {
    formState: { errors },
  } = useFormContext<FormErrors>();

  const handleDateChange = (field: any, value: any) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    field.onChange(formattedDate);
    if (!id) {
      form.setValue("step1.toDate", formattedDate);
    }
  };

  return (
    <Grid container spacing={1}>
      {!id && (
        <Grid item xs={12}>
          <Controller
            name="step1.type"
            control={form.control}
            render={({ field }) => (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="ADITYA_BIRLA_MONEY"
                    control={<Radio />}
                    label="Trades"
                  />
                  <FormControlLabel
                    value="ADITYA_BIRLA_MONEY_LEDGER"
                    control={<Radio />}
                    label="Ledger Entries"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      )}
      <Grid item xs={!id ? 12 : 6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="step1.fromDate"
            control={form.control}
            rules={{
              required: { value: true, message: "Please select start date" },
            }}
            render={({ field }) => (
              <GKDatePicker
                {...field}
                requiredField
                inputLabel={!id ? "Date" : "Start Date"}
                value={dayjs(field.value)}
                onChange={(value) => handleDateChange(field, value)}
                disableFuture
                slotProps={{
                  textField: {
                    error: !!errors.step1?.fromDate,
                    helperText: errors.step1?.fromDate?.message as string,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      {id && (
        <Grid item xs={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="step1.toDate"
              control={form.control}
              rules={{
                required: { value: !!id, message: "Please select end date" },
              }}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  requiredField
                  inputLabel="End Date"
                  value={dayjs(field.value)}
                  minDate={
                    dayjs(form.getValues("step1.fromDate")).isValid()
                      ? dayjs(form.getValues("step1.fromDate"))
                      : null
                  }
                  onChange={(value) =>
                    field.onChange(dayjs(value).format("YYYY-MM-DD"))
                  }
                  disableFuture
                  slotProps={{
                    textField: {
                      error: !!errors.step1?.toDate,
                      helperText: errors.step1?.toDate?.message as string,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default ImportBrokerStepOne;
