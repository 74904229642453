import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ConfirmationModal from "components/ConfirmationModal";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKReactQuill from "components/GKReactQuill";
import GKSearchSelect from "components/GKSearchSelect";
import GKSideModal from "components/GKSideModal";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CgMathPlus } from "react-icons/cg";
import { FiTrash2 } from "react-icons/fi";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DirectTradeModal = (props: Props) => {
  const { theme } = ThemeContainer();

  const { open, setOpen } = props;

  const { id } = useParams();

  const {
    state: { companies },
  } = useAppContext();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { mutate: handleCreateTrade, isLoading } = useMutation({
    mutationFn: (state: any) =>
      axiosInstance.post(`/direct-order-generate/`, {
        ...state,
        portfolios: state?.portfolios?.map((data: any) => data?.value),
      }),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_ORDER_LIST");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      portfolioId: +id,
      isWhatsapp: false,
      isEmail: false,
      rationale: "",
      stockList: [
        {
          gridCode: "",
          transactionType: "",
          quantity: "",
          price: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "stockList",
  });

  form.watch();

  const filterData = companies?.filter(
    (data: any): any =>
      !form
        .getValues("stockList")
        ?.map((dataItem: any) => dataItem?.gridCode)
        ?.includes(data?.gridCode)
  );

  return (
    <>
      <GKSideModal
        open={open}
        setOpen={setOpen}
        modalTitle="Add Trade"
        footerButton={
          <Grid container justifyContent={"flex-end"} gap={1}>
            <LoadingButton
              variant="contained"
              size="medium"
              loading={isLoading}
              disabled={
                !form.getValues("isEmail") && !form.getValues("isWhatsapp")
              }
              onClick={() => setOpenConfirmModal(true)}
            >
              Create
            </LoadingButton>
          </Grid>
        }
      >
        <form
          id="create-trade-form"
          // onSubmit={form.handleSubmit((values) => handleCreateTrade(values))}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ minHeight: 100 }}>
                <Table size="small" aria-label="a dense table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Controller
                          name="isEmail"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                {...field}
                                label="Email"
                                control={
                                  <GKCheckbox
                                    value={field.value}
                                    onChange={(event) => {
                                      field.onChange(event.target.checked);
                                    }}
                                  />
                                }
                              />
                            );
                          }}
                        />
                        <Controller
                          name="isWhatsapp"
                          control={form.control}
                          render={({ field }) => {
                            return (
                              <FormControlLabel
                                {...field}
                                label="Whatsapp"
                                control={
                                  <GKCheckbox
                                    value={field.value}
                                    onChange={(event) => {
                                      field.onChange(event.target.checked);
                                    }}
                                  />
                                }
                              />
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell colSpan={3} />
                      <TableCell>
                        <Typography
                          fontSize={16}
                          fontWeight={700}
                          color={theme.palette.primary.main}
                        >
                          <GKButton
                            variant="outlined"
                            onClick={() => {
                              append({
                                gridCode: "",
                                transactionType: "",
                                quantity: "",
                                price: 0,
                              });
                            }}
                            startIcon={<CgMathPlus size={14} />}
                          >
                            Add Trade
                          </GKButton>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell>Company</TableCell>
                      <TableCell>Transaction Type</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>CMP</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((row, i) => {
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" width={"35%"}>
                            <Controller
                              name={`stockList.${i}.gridCode`}
                              control={form.control}
                              rules={{ required: true }}
                              render={({ field, formState: { errors } }) => {
                                return (
                                  <GKSearchSelect
                                    {...field}
                                    sx={{ width: "100%" }}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 4,
                                        }}
                                      >
                                        <Typography
                                          color={theme.palette.light.main}
                                          fontSize={8}
                                          style={{
                                            background:
                                              option?.instType === "EQ"
                                                ? theme.palette.secondary.main
                                                : theme.palette.warning.main,
                                            padding: "2px 4px",
                                            borderRadius: 4,
                                          }}
                                        >
                                          {option?.instType}
                                        </Typography>
                                        <Typography whiteSpace={"nowrap"}>
                                          {option?.companyName}
                                        </Typography>
                                      </li>
                                    )}
                                    disableClearable
                                    options={filterData}
                                    onChange={(e, val) => {
                                      field.onChange(val ? val.value : null);
                                      form.setValue(
                                        `stockList.${i}.price`,
                                        val.cmp
                                      );
                                    }}
                                    value={
                                      companies?.find(
                                        (data: any) =>
                                          data.value === field.value
                                      )?.companyName || ""
                                    }
                                    getOptionLabel={(option) =>
                                      option.name || option
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Company"
                                        error={
                                          !!errors?.stockList?.[i]?.gridCode
                                        }
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell width={"20%"}>
                            <Controller
                              name={`stockList.${i}.transactionType`}
                              control={form.control}
                              rules={{ required: true }}
                              render={({ field, formState: { errors } }) => (
                                <GKSearchSelect
                                  {...field}
                                  disableClearable
                                  options={
                                    SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE
                                  }
                                  onChange={(e, val) => {
                                    field.onChange(val ? val.value : null);
                                  }}
                                  value={
                                    SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE?.find(
                                      (data: any) => data.value === field.value
                                    )?.name || ""
                                  }
                                  getOptionLabel={(option) =>
                                    option.name || option
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Trade Type"
                                      error={
                                        !!errors?.stockList?.[i]
                                          ?.transactionType
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell width={"15%"}>
                            <Controller
                              name={`stockList.${i}.quantity`}
                              control={form.control}
                              rules={{ required: true }}
                              render={({ field, formState: { errors } }) => (
                                <FormControl variant="standard" fullWidth>
                                  <GKTextField
                                    {...field}
                                    type="number"
                                    placeholder="Enter Quantity"
                                    onChange={(e: any) =>
                                      field.onChange(e.target.valueAsNumber)
                                    }
                                    error={!!errors?.stockList?.[i]?.quantity}
                                  />
                                </FormControl>
                              )}
                            />
                          </TableCell>
                          <TableCell width={"15%"}>
                            <Controller
                              name={`stockList.${i}.price`}
                              control={form.control}
                              render={({ field }) => (
                                <FormControl variant="standard" fullWidth>
                                  <GKTextField
                                    {...field}
                                    type="number"
                                    inputProps={{ readOnly: true }}
                                  />
                                </FormControl>
                              )}
                            />
                          </TableCell>
                          <TableCell align="center" width={"5%"}>
                            <IconButton
                              color="error"
                              disabled={fields.length === 1}
                              onClick={() => remove(i)}
                            >
                              <FiTrash2 size={18} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="rationale"
                control={form.control}
                render={({ field }) => (
                  <GKReactQuill {...field} inputLabel="Rationale" />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </GKSideModal>

      <Show.When isTrue={openConfirmModal}>
        <ConfirmationModal
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          title="Send orders for execution"
          onConfirm={form.handleSubmit((values) => handleCreateTrade(values))}
          messageContent={
            <Typography>
              Are you sure you want to send these orders to the client/s for
              execution?
            </Typography>
          }
          customButtonText="Send"
        />
      </Show.When>
    </>
  );
};

export default DirectTradeModal;
