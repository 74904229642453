import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FiTrash2 } from "react-icons/fi";

/* eslint-disable react/no-array-index-key */
interface Props {
  dataToSubmit: any;
  setDataToSubmit: any;
  setSelectedPortfolio: any;
}

const CustomTable = ({
  dataToSubmit,
  setDataToSubmit,
  setSelectedPortfolio,
}: Props) => {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: 10 }}>Portfolio</TableCell>
            <TableCell align="right" style={{ padding: 10 }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToSubmit?.map((dataItem: any, index: number) => (
            <TableRow key={index}>
              <TableCell style={{ padding: 10 }}>
                <Typography fontWeight={600}>{dataItem.name}</Typography>
                <Typography>{dataItem.portfolioId}</Typography>
              </TableCell>
              <TableCell align="right" style={{ padding: 10 }}>
                {" "}
                <IconButton
                  onClick={() => {
                    const newData = dataToSubmit.filter(
                      (item: any) => item.portfolioId !== dataItem?.portfolioId
                    );
                    setDataToSubmit(newData);
                    setSelectedPortfolio((prev: any) =>
                      prev.filter((item: any) => item !== dataItem?.id)
                    );
                  }}
                  color="error"
                  className="outline"
                >
                  <FiTrash2 size={20} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
