/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  Fade,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CreatePortfolioModal from "Pages/EquityEtf/ClientModal";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { PiWarningOctagonLight } from "react-icons/pi";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { SampleFilesUrls } from "utils/common";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { v4 as uuid } from "uuid";
import UploadManagerStepperModal from "./ReconManagerStepperModal";
import UploadStepOne from "./ReconStepOne";
import { useReconManager } from "./useReconManager";
import { LuClipboardCheck } from "react-icons/lu";

interface Props {
  open: boolean;
  setOpen: any;
  refetch: any;
}

const ReconManagerModal = ({ open, setOpen }: Props) => {
  const { theme } = ThemeContainer();

  const {
    isLoading,
    setIsLoading,
    activeStep,
    formik,
    handleReset,
    handleModalTitle,
    showDuplicates,
    setShowDuplicates,
    portfolioFormik,
    portfolios,
    isPortfolioCreateModalOpen,
    setIsPortfolioCreateModalOpen,
    companyFormik,
    companyNames,
    userInfo,
    downloadPortfolioSampleFile,
    setActiveStep,
    handleNext,
    allFiles,
    setAllFiles,
    handleCheckPassword,
    reviewResponse,
  } = useReconManager();

  const [selectionModal, setSelectionModal] = useState(false);

  const handleDownloadSampleFiles = (formikFn: any) => {
    if (
      formikFn.values.fileUploadType === "Portfolio" &&
      formikFn.values?.fileBrokerType === "GridKey template"
    ) {
      return downloadPortfolioSampleFile(SampleFilesUrls.SAMPLE_PORTFOLIOS);
    }
    if (
      formikFn.values.fileUploadType === "Stock" &&
      formikFn.values.fileVersion !== "" &&
      formikFn.values?.fileBrokerType !== "GridKey template"
    ) {
      return downloadPortfolioSampleFile(
        `${SampleFilesUrls.SAMPLE_STOCK}?broker_format=${formikFn.values.fileVersion}`
      );
    } else if (formikFn.values.fileUploadType === "Cash") {
      return downloadPortfolioSampleFile(SampleFilesUrls.SAMPLE_CASH);
    } else if (formikFn.values.fileUploadType === "Stock") {
      return downloadPortfolioSampleFile(SampleFilesUrls.SAMPLE_STOCK);
    }
    return downloadPortfolioSampleFile(SampleFilesUrls.SAMPLE_PORTFOLIOS);
  };

  const handleReturnSize = (file: any) => {
    const totalBytes = file?.size;
    if (totalBytes < 1000000) {
      return `${(totalBytes / 1000).toFixed(2)}KB`;
    }
    return `${(totalBytes / 1000000).toFixed(2)}MB`;
  };

  console.log("form.", formik?.values);

  return (
    <GKModal
      modalTitle={"Recon manager"}
      open={open}
      setOpen={setOpen}
      width={"70%"}
      footer={
        <Grid
          container
          justifyContent={activeStep !== 0 ? "space-between" : "flex-end"}
          gap={1}
        >
          {activeStep === 4 && <div />}
          {activeStep !== 0 && activeStep !== 4 && (
            <GKButton
              variant="outlined"
              color="dark"
              size={"medium"}
              onClick={() => {
                formik.resetForm();
                setActiveStep(0);
                setIsLoading(false);
                handleReset();
                setAllFiles([]);
              }}
            >
              Cancel
            </GKButton>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 8,
            }}
          >
            {[0].includes(activeStep) && (
              <GKButton
                variant="contained"
                size={"medium"}
                disabled={formik.values?.file?.length === 0}
                onClick={() => {
                  if (formik.values?.isPasswordRequired) {
                    handleCheckPassword(allFiles);
                  }
                  handleNext();
                }}
              >
                Next
              </GKButton>
            )}
            {[1].includes(activeStep) && (
              <GKButton
                variant="contained"
                size={"medium"}
                onClick={() => {
                  formik.resetForm();
                  setActiveStep(0);
                  setIsLoading(false);
                  setShowDuplicates(false);
                  setIsPortfolioCreateModalOpen(false);
                  handleReset();
                  setOpen(false);
                  setAllFiles([]);
                  refetchQuery(QueryKeys.RECON_MANAGER_LIST);
                }}
                disabled={
                  allFiles?.filter((data) => !data?.finished)?.length !== 0
                }
              >
                Finish
              </GKButton>
            )}
            {[2, 3].includes(activeStep) && (
              <GKButton
                variant="contained"
                size={"medium"}
                type="submit"
                form={handleModalTitle(activeStep)?.form}
              >
                Next
              </GKButton>
            )}
            {[4].includes(activeStep) && (
              <GKButton
                variant="contained"
                size={"medium"}
                onClick={() => {
                  formik.resetForm();
                  setActiveStep(0);
                  setIsLoading(false);
                  setShowDuplicates(false);
                  setIsPortfolioCreateModalOpen(false);
                  handleReset();
                  setOpen(false);
                  setAllFiles([]);
                  refetchQuery(QueryKeys.RECON_MANAGER_LIST);
                }}
              >
                Finish
              </GKButton>
            )}
          </div>
        </Grid>
      }
    >
      <>
        {activeStep === 0 && (
          <UploadStepOne
            formik={formik}
            id="upload-manager-form"
            setAllFiles={setAllFiles}
            selectionModal={selectionModal}
            setSelectionModal={setSelectionModal}
            handleDownloadSampleFiles={handleDownloadSampleFiles}
          />
        )}
        {activeStep !== 0 &&
          allFiles?.map((dataItem: any, index: any) => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      borderColor: theme.palette.grey[300],
                      my: 1,
                      p: 1,
                      borderRadius: 2,
                    }}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid
                        item
                        container
                        alignContent={"center"}
                        gap={1}
                        xs={8}
                      >
                        <Grid item>
                          <img
                            src={`/FileIcons/${dataItem?.file?.name
                              .split(".")
                              .pop()}.svg`}
                            height={40}
                            width={40}
                            alt=""
                            style={{
                              borderRadius: 4,
                              border: "1px solid",
                              borderColor: theme.palette.grey[300],
                              padding: 8,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>{dataItem?.file?.name}</Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Typography color={"#9CA3AF"} fontSize={12}>
                              {handleReturnSize(dataItem?.file)}
                            </Typography>
                            <Show.When isTrue={dataItem?.data}>
                              <GoDotFill color={"#9CA3AF"} />
                            </Show.When>
                            <Show.When isTrue={dataItem?.data}>
                              <Typography color={"#9CA3AF"} fontSize={12}>
                                {dataItem?.data?.remarks}
                              </Typography>
                            </Show.When>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <Show.When isTrue={dataItem?.hasPassword}>
                          <Grid item mr={1}>
                            <GKTextField
                              placeholder="Password"
                              sx={{
                                "& .MuiInputBase-input": {
                                  p: 1,
                                },
                              }}
                              onChange={(event) => {
                                setAllFiles(
                                  allFiles?.map(
                                    (item: any, itemIndex: number) => {
                                      return {
                                        ...item,
                                        password:
                                          itemIndex === index
                                            ? event?.target?.value
                                            : "",
                                      };
                                    }
                                  )
                                );
                              }}
                            />
                          </Grid>
                        </Show.When>
                        <Show.When isTrue={!dataItem?.data?.id}>
                          <LoadingButton
                            loading={
                              isLoading && formik.values.fileIndex === index
                            }
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => {
                              formik.setFieldValue("file", [dataItem?.file]);
                              formik.setFieldValue("fileIndex", index);
                              formik.setFieldValue(
                                "filePassword",
                                dataItem?.password
                              );
                              setTimeout(() => {
                                formik.handleSubmit();
                              }, 200);
                            }}
                          >
                            Reconcile
                          </LoadingButton>
                        </Show.When>
                        <Show.When isTrue={dataItem?.data?.id}>
                          <StatusField
                            statusString={dataItem?.data?.status || "Importing"}
                          />
                        </Show.When>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Show.When
                  isTrue={isLoading && formik.values.fileIndex === index}
                >
                  <Grid item xs={12}>
                    <Fade in={isLoading}>
                      <Card
                        sx={{
                          borderColor: theme.palette.grey[300],
                          my: 1,
                          p: 1,
                          borderRadius: 2,
                          borderStyle: "dashed",
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid item xs={12} textAlign={"center"}>
                            <Typography fontSize={16} color={"#9CA3AF"}>
                              Please wait, your file is being processed...
                            </Typography>
                          </Grid>
                          <Grid item xs={12} textAlign={"center"}>
                            <UploadManagerStepperModal
                              activeStep={activeStep}
                              loading={isLoading}
                              formik={formik}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Fade>
                  </Grid>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 2 &&
                    formik.values.fileIndex === index
                  }
                >
                  <Grid item xs={12}>
                    <form
                      onSubmit={portfolioFormik?.handleSubmit}
                      id={"portfolio-mapping-form"}
                      style={{
                        width: "100%",
                      }}
                    >
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography fontSize={16} />
                                <GKButton
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    setIsPortfolioCreateModalOpen(true)
                                  }
                                  title="Add New Portfolio"
                                >
                                  Create New Portfolio
                                </GKButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Alert
                                  sx={{ mb: 2 }}
                                  severity="warning"
                                  icon={<PiWarningOctagonLight size={14} />}
                                >
                                  The Following portfolio codes we have not
                                  found in your account, please map it to
                                  already existing portfolios.
                                </Alert>
                                {portfolioFormik.values.nullPortfolios &&
                                  portfolioFormik.values.nullPortfolios
                                    ?.length !== 0 &&
                                  portfolioFormik.values.nullPortfolios.map(
                                    (item: any, index: number) => (
                                      <Grid item xs={12} my={1} key={index}>
                                        <GKSearchSelect
                                          disableClearable
                                          options={portfolios || []}
                                          getOptionLabel={(option) =>
                                            option?.name || option
                                          }
                                          renderOption={(props, option) => {
                                            return (
                                              <li
                                                {...props}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <Typography fontWeight={600}>
                                                  {option.clientName}
                                                </Typography>
                                                <Typography>
                                                  {option.name}
                                                </Typography>
                                              </li>
                                            );
                                          }}
                                          inputLabel={item?.value}
                                          renderInput={(params) => (
                                            <TextField
                                              name={`nullPortfolios.${index}.portfolio`}
                                              {...params}
                                            />
                                          )}
                                          onChange={(e, val) => {
                                            portfolioFormik?.setFieldValue(
                                              `nullPortfolios.${index}.portfolio`,
                                              val.value
                                            );
                                          }}
                                          onBlur={portfolioFormik?.handleBlur}
                                          value={
                                            portfolios?.find(
                                              (data: any) =>
                                                data?.id ===
                                                portfolioFormik.values
                                                  .nullPortfolios[index].value
                                            )?.name
                                          }
                                        />
                                      </Grid>
                                    )
                                  )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </form>
                  </Grid>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 3 &&
                    formik.values.fileIndex === index
                  }
                >
                  <Grid item xs={12}>
                    <form
                      onSubmit={companyFormik?.handleSubmit}
                      id={"company-mapping-form"}
                    >
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography fontSize={16}>
                                  Company Mapping
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Alert sx={{ mb: 2 }} severity="info">
                                  We couldn&#39;t find the following codes,
                                  please map them to the right companies.
                                </Alert>
                                {companyFormik.values.nullCompanies &&
                                  companyFormik.values.nullCompanies?.length !==
                                    0 &&
                                  companyFormik.values.nullCompanies.map(
                                    (item: any, index: number) => (
                                      <Grid item xs={12} my={1} key={index}>
                                        <GKSearchSelect
                                          disableClearable
                                          renderOption={(props, option) => (
                                            <li
                                              {...props}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 4,
                                              }}
                                            >
                                              <Typography
                                                color={theme.palette.light.main}
                                                fontSize={8}
                                                style={{
                                                  background:
                                                    option?.instType === "EQ"
                                                      ? theme.palette.secondary
                                                          .main
                                                      : theme.palette.warning
                                                          .main,
                                                  padding: "2px 4px",
                                                  borderRadius: 4,
                                                }}
                                              >
                                                {option?.instType}
                                              </Typography>
                                              <Typography whiteSpace={"nowrap"}>
                                                {option?.name}
                                              </Typography>
                                            </li>
                                          )}
                                          options={
                                            companyNames?.data?.data || []
                                          }
                                          getOptionLabel={(option) =>
                                            option?.companyName || option
                                          }
                                          inputLabel={item?.advisorCompany}
                                          renderInput={(params) => (
                                            <TextField
                                              name={`nullCompanies.${index}.value`}
                                              {...params}
                                            />
                                          )}
                                          onChange={(e, val) => {
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.value`,
                                              val.gridCode
                                            );
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.nseBseCode`,
                                              val.bse || val.nse
                                            );
                                            companyFormik?.setFieldValue(
                                              `nullCompanies.${index}.company`,
                                              userInfo.companyId
                                            );
                                          }}
                                          onBlur={companyFormik?.handleBlur}
                                          value={
                                            companyNames?.data?.data?.find(
                                              (data: any) =>
                                                data?.gridCode ===
                                                companyFormik.values
                                                  .nullCompanies[index].value
                                            )?.companyName
                                          }
                                        />
                                      </Grid>
                                    )
                                  )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </form>
                  </Grid>
                </Show.When>
                <Show.When
                  isTrue={
                    !isLoading &&
                    activeStep === 4 &&
                    formik.values.fileIndex === index
                  }
                >
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <GKClientDataGrid
                        name="reconManager"
                        showFiltersButton={false}
                        headerComponent={
                          <Typography
                            whiteSpace={"nowrap"}
                            fontSize={12}
                            fontWeight={600}
                            color={theme?.palette?.grey[700]}
                          >
                            Total Entries: {reviewResponse?.data?.length || "0"}
                          </Typography>
                        }
                        density="compact"
                        filterButton={false}
                        pagination={false}
                        checkboxSelection={showDuplicates}
                        maxHeight={"calc(100vh - 500px)"}
                        getRowId={(opt) => opt.uuid}
                        rows={
                          reviewResponse?.data?.map((da: any) => {
                            return { ...da, uuid: uuid() };
                          }) || []
                        }
                        columns={[
                          {
                            ...extraColumnsField(
                              "Company Name",
                              "companyName",
                              140,
                              1
                            ),
                          },
                          {
                            ...extraColumnsField(
                              "Gridkey Quantity",
                              "gridQuantity",
                              140,
                              1
                            ),
                          },
                          {
                            ...extraColumnsField(
                              "Broker Quantity",
                              "brokerQuantity",
                              140,
                              1
                            ),
                          },
                          {
                            ...extraColumnsField(
                              "Quantity Deviation",
                              "quantityDeviation",
                              160,
                              1
                            ),
                            valueFormatter: (val) =>
                              moneyCommaSeparator(val.value),
                          },
                          {
                            ...extraColumnsField(
                              "Gridkey Avg. Buy Price",
                              "gridAvgBuyPrice",
                              150,
                              1
                            ),
                            valueFormatter: (val) =>
                              moneyCommaSeparator(val.value),
                          },
                          {
                            ...extraColumnsField(
                              "Broker Avg. Buy Price",
                              "brokerAvgBuyPrice",
                              180,
                              1
                            ),
                            valueFormatter: (val) =>
                              moneyCommaSeparator(val.value),
                          },
                          {
                            ...extraColumnsField(
                              "Avg. Buy Price Deviation",
                              "avgBuyPriceDeviation",
                              180,
                              1
                            ),
                            valueFormatter: (val) =>
                              moneyCommaSeparator(val.value),
                          },
                          {
                            ...extraColumnsField("Status", "status", 120, 1),
                            renderCell: (params) => {
                              return params?.row?.status ? (
                                <span
                                  style={{
                                    backgroundColor: `${theme.palette.success.main}22`,
                                    padding: 2,
                                    borderRadius: 4,
                                  }}
                                >
                                  <LuClipboardCheck
                                    color={theme.palette.success.main}
                                  />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: `${theme.palette.error.main}22`,
                                    padding: 2,
                                    borderRadius: 4,
                                  }}
                                >
                                  <PiWarningOctagonLight
                                    color={theme.palette.warning.main}
                                  />
                                </span>
                              );
                            },
                          },
                        ]}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              stt: false,
                              gst: false,
                              stampCharges: false,
                              otherCharges: false,
                              transactionCharges: false,
                              sebiTurnoverFees: false,
                            },
                          },
                          pagination: {
                            paginationModel: {
                              pageSize: 25,
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                      mt={1}
                    >
                      <LuClipboardCheck
                        color={theme.palette.success.main}
                        size={18}
                      />
                      <Typography fontSize={12}>
                        {` Quantity of this asset matches with the quantity in your
                        broker's account.`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <PiWarningOctagonLight
                        color={theme.palette.warning.main}
                        size={18}
                      />{" "}
                      <Typography fontSize={12}>
                        {`Quantity of this asset doesn't match with the quantity in your broker's account.`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Show.When>
              </Grid>
            );
          })}

        {isPortfolioCreateModalOpen && (
          <CreatePortfolioModal
            isModalOpen={isPortfolioCreateModalOpen}
            setIsModalOpen={setIsPortfolioCreateModalOpen}
          />
        )}
      </>
    </GKModal>
  );
};

export default ReconManagerModal;
