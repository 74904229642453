import { Grid, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { removeUnderscore } from "Pages/PortfolioSummary/utils";
import { useState } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import SchedulerModal from "./SchedulerModal";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";

const ReportScheduler = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const requestBody = {
    take: commonFilter?.reportScheduler?.take,
    skip: commonFilter?.reportScheduler?.skip,
    sort: JSON.stringify(
      commonFilter?.reportScheduler?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.reportScheduler?.search || "",
  };

  const { data, isFetching, refetch } = useFetchQuery({
    route: `/report_scheduler/`,
    key: ["REPORT_SCHEDULER_LIST", requestBody],
    requestBody,
  });

  const { mutate: handleScheduleReportDelete, isLoading } = useMutation({
    mutationKey: ["DELETE_SCHEDULE_REPORT"],
    mutationFn: () => axiosInstance.delete(`/report_scheduler/${reportId}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount}
        name="reportScheduler"
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv path={"/report_scheduler"} name={"ReportSchedulerList"} />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Report Name", "reportName", 300, 1),
      renderCell: (params) => removeUnderscore(params?.row?.reportName),
    },
    {
      ...extraColumnsField("Schedule Type", "scheduleType", 150, 1),
    },
    {
      ...extraColumnsField("Format", "format", 50, 1),
    },
    {
      ...extraColumnsField("Time Period", "timePeriod", 250, 1),
      renderCell: (params) => params?.row?.timePeriod || "-",
    },
    {
      ...extraColumnsField("Next Scheduled Date", "scheduledDate", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolios", 120, 1),
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
      type: "number",
      renderCell: (params) => params?.row?.portfolios?.length || 0,
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: (params) => (
        <IconButton
          color="error"
          className="outline"
          disabled={params.row.id === reportId && isLoading}
          onClick={(event) => {
            event.stopPropagation();
            setReportId(params.row.id);
            setConfirmationModal(true);
          }}
        >
          <FiTrash2 size={20} />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container>
      <GKDataGrid
        density="compact"
        rows={data?.data?.data || []}
        columns={columns}
        loading={isFetching}
        CustomPagination={!isFetching && cusPagination}
        filterButton={false}
        maxHeight={"calc(100vh - 357px)"}
        exportCsv={exportCsv}
        name="reportScheduler"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => {
              setReportId(null);
              setIsOpenModal(true);
            }}
          >
            Schedule Report
          </GKButton>
        }
        onRowClick={(params) => {
          setReportId(params.row.id);
          setIsOpenModal(true);
        }}
      />

      <Show.When isTrue={isOpenModal}>
        <SchedulerModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          reportId={reportId}
        />
      </Show.When>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleScheduleReportDelete()}
          title={"Schedule Report"}
          isDelete
        />
      </Show.When>
    </Grid>
  );
};

export default ReportScheduler;
