import { LoadingButton } from "@mui/lab";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKModal from "components/GKModal";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId: string | number;
  setOrderId: (value: number) => void;
}

const ResendOrderModal = (props: Props) => {
  const { open, setOpen, orderId, setOrderId } = props;

  const { mutate: handleResendOrder, isLoading: resendOrderLoading } =
    useMutation({
      mutationKey: ["RESEND_ORDER"],
      mutationFn: (data: any) =>
        axiosInstance.post(`/smallcase-trade/resend-order-email/`, {
          order_id: orderId,
          ...data,
        }),
      onSuccess: (response) => {
        refetchQuery("GET_BROKER_DETAILS");
        toast.success(response?.data?.message);
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const form = useForm({
    defaultValues: {
      isWhatsapp: false,
      isEmail: false,
    },
  });

  return (
    <GKModal
      modalTitle="Resend Order"
      open={open}
      setOpen={() => {
        setOpen(false);
        setOrderId(null);
      }}
      width={"30%"}
      footer={
        <Grid
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            size="medium"
            form="cash-form"
            onClick={() => {
              setOpen(false);
              setOrderId(null);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            form="resend-order-form"
            loading={resendOrderLoading}
          >
            Resend
          </LoadingButton>
        </Grid>
      }
    >
      <form
        id="resend-order-form"
        onSubmit={form.handleSubmit((values: any) => handleResendOrder(values))}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={600}>
              How do you want to resend the orders?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="isEmail"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    {...field}
                    label="Email"
                    control={
                      <GKCheckbox
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.checked);
                        }}
                      />
                    }
                  />
                );
              }}
            />
            <Controller
              name="isWhatsapp"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    {...field}
                    label="Whatsapp"
                    control={
                      <GKCheckbox
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.checked);
                        }}
                      />
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default ResendOrderModal;
