/* eslint-disable jsx-a11y/media-has-caption */
import {
  Card,
  Divider,
  Grid,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";

const KYCExtendedRow = (params: GridRowParams) => {
  const { row } = params;

  const leftData = [
    {
      text: "ID No:",
      value: row?.ocrResult?.idNo || "-",
    },
    {
      text: "Name:",
      value: row?.ocrResult?.name || "-",
    },
    {
      text: "Fathers Name:",
      value: row?.ocrResult?.fathersName || "-",
    },
    {
      text: "DOB:",
      value: row?.ocrResult?.dob || "-",
    },
    {
      text: "Id Type:",
      value: row?.ocrResult?.idType || "-",
    },
  ];

  return (
    <Card style={{ margin: 10 }}>
      <Grid container spacing={1}>
        {row?.ocrResult && (
          <>
            <Grid item xs={5}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  maxHeight: 100,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {leftData?.map((dataItem, index) => (
                  <Grid item display={"flex"} alignItems={"center"} key={index}>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body1"
                          fontWeight={600}
                          fontSize={16}
                        >
                          {dataItem.text}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body1"
                          color="text.primary"
                          fontWeight={500}
                          fontSize={16}
                        >
                          {dataItem.value}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
              </List>
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" />
            </Grid>
          </>
        )}
        <Grid item xs={5} textAlign={"right"}>
          {row?.type === "image" || row?.type === "selfie" ? (
            <img
              onError={({ currentTarget }) => {
                currentTarget.src = "/Logos/errorImg.svg";
              }}
              src={row?.document}
              alt="document"
              style={{
                height: "50%",
              }}
            />
          ) : (
            <video controls width="250">
              <source src={row?.document} type="video/webm" />
            </video>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default KYCExtendedRow;
