import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useAppContext } from "AppContext";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { CommonFilterState } from "interfaces/CommonFilter";
import { Controller, useForm } from "react-hook-form";
import {
  LEDGER_TRANSACTION_TYPE,
  clientOption,
  companiesOption,
  employeeOption,
  feePlanOption,
  importManagerFormats,
  importManagerSource,
  portfolioGroupOption,
  portfolioOption,
  tradeBookTransactionType,
  transactionTypeCashEntry,
  transactionTypeIncomeExpense,
} from "utils/TableFilters";
import GKButton from "./GKButton";
import GKCheckbox from "./GKCheckbox";
import GKDatePicker from "./GKDatePicker";
import GKDateRangePicker from "./GKDateRangePicker";
import GKModal from "./GKModal";
import GKSearchSelect from "./GKSearchSelect";
import GKTypography from "./GKTypography";
import { Show } from "./Show";

interface Props {
  isModalOpen: boolean;
  zeroHolding: boolean;
  setIsModalOpen: (value: boolean) => void;
  tableName?: string;
  name?: string;
}

const GKCommonTableFilterModal = (props: Props) => {
  const { isModalOpen, setIsModalOpen, tableName, name, zeroHolding } = props;

  const {
    state: {
      portfolios,
      companies,
      portfolioGroupList,
      clientList,
      employeeList,
      commonFilter,
    },
    dispatch,
  } = useAppContext();

  const { data: feePlansList, isFetching: getFeePlansFetching } = useFetchQuery(
    {
      key: ["GET_FEE_PLANS_DROPDOWN"],
      route: "/fee-plans-dropdown/",
    }
  );

  const commonFilterWithTable = commonFilter[name as keyof CommonFilterState];

  const form = useForm({
    defaultValues: {
      // code: commonFilterWithTable?.customFilter?.code || [],
      // uploaded_by: commonFilterWithTable?.customFilter?.uploaded_by || [],
      name: commonFilterWithTable?.customFilter?.name || [],
      type: commonFilterWithTable?.customFilter?.type || "",
      date: commonFilterWithTable?.customFilter?.date || "",
      format: commonFilterWithTable?.customFilter?.format || [],
      source: commonFilterWithTable?.customFilter?.source || [],
      start_date: commonFilterWithTable?.customFilter?.start_date || "",
      end_date: commonFilterWithTable?.customFilter?.end_date || "",
      portfolio: commonFilterWithTable?.customFilter?.portfolio || [],
      portfolioFilterName:
        commonFilterWithTable?.customFilter?.portfolioFilterName || [],
      gcode: commonFilterWithTable?.customFilter?.gcode || [],
      company: commonFilterWithTable?.customFilter?.company || [],
      client: commonFilterWithTable?.customFilter?.client || [],
      clientName: commonFilterWithTable?.customFilter?.clientName || [],
      employee: commonFilterWithTable?.customFilter?.employee || [],
      employeeName: commonFilterWithTable?.customFilter?.employeeName || [],
      feePlan: commonFilterWithTable?.customFilter?.feePlan || [],
      feePlanName: commonFilterWithTable?.customFilter?.feePlanName || [],
      group: commonFilterWithTable?.customFilter?.group || [],
      groupName: commonFilterWithTable?.customFilter?.groupName || [],
      financialYear: commonFilterWithTable?.customFilter?.financialYear || [],
    },
  });

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle="Filters"
      width={"50%"}
      footer={
        <Grid container justifyContent={zeroHolding ? "space-between" : "end"}>
          {zeroHolding && (
            <FormControlLabel
              style={{
                marginLeft: 2,
              }}
              control={
                <Switch
                  onChange={(event) => {
                    dispatch({
                      type: "FILTER",
                      data: {
                        ...commonFilter,
                        [name]: {
                          ...commonFilterWithTable,
                          showNonZero: event.target.checked,
                        },
                      },
                    });
                  }}
                  checked={commonFilterWithTable?.showNonZero}
                />
              }
              labelPlacement="end"
              label={
                <b
                  style={{
                    fontSize: 12,
                  }}
                >
                  Show zero holding
                </b>
              }
            />
          )}
          <GKButton
            type="submit"
            form="table-filter"
            variant="contained"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid>
      }
      titleComponent={
        <GKButton
          onClick={() => {
            dispatch({
              type: "FILTER",
              data: {
                ...commonFilter,
                [name]: {
                  ...commonFilterWithTable,
                  customFilter: {},
                },
              },
            });
            form.reset({
              portfolioFilterName: [],
              portfolio: [],
              name: [],
              type: "",
              date: "",
              format: [],
              source: [],
              start_date: "",
              end_date: "",
              gcode: [],
              company: [],
              client: [],
              clientName: [],
              employee: [],
              employeeName: [],
              feePlan: [],
              feePlanName: [],
              group: [],
              groupName: [],
              financialYear: [],
            });
            // setIsModalOpen(false);
          }}
          size="medium"
          variant="outlined"
          color="error"
        >
          Reset
        </GKButton>
      }
    >
      <form
        id="table-filter"
        onSubmit={form.handleSubmit((values) => {
          dispatch({
            type: "FILTER",
            data: {
              ...commonFilter,
              [name]: {
                ...commonFilterWithTable,
                customFilter: values,
              },
            },
          });
          setIsModalOpen(false);
        })}
      >
        <Grid container spacing={1}>
          <Show.When
            isTrue={
              tableName === "portfolio_list" ||
              tableName === "holding_details" ||
              tableName === "bank_statement_list" ||
              tableName === "trade_book_list" ||
              tableName === "available_cash_list" ||
              tableName === "fee_calculation" ||
              tableName === "proforma_invoice" ||
              tableName === "invoice_list" ||
              tableName === "mutual_funds_list" ||
              tableName === "income_expense_list" ||
              tableName === "bank_ledger_list"
            }
          >
            <Grid item xs={12}>
              <Controller
                name="portfolio"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Portfolio"
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <GKTypography fontWeight={600}>
                            {option.clientName}
                          </GKTypography>
                          <GKTypography>{option.name}</GKTypography>
                        </li>
                      );
                    }}
                    options={
                      portfolioOption(
                        portfolios.map((item) => {
                          return {
                            ...item,
                            name: item.name,
                            value: item.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("portfolioFilterName", val);
                    }}
                    value={
                      portfolios?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          field.value.length <= 0 && "Select Portfolio"
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When isTrue={tableName === "portfolio_group_list"}>
            <Grid item xs={12}>
              <Controller
                name="group"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Portfolio Group"
                    options={
                      portfolioGroupOption(portfolioGroupList, form) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("groupName", val);
                    }}
                    value={
                      portfolioGroupList?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Portfolio Group"
                        placeholder={
                          field.value.length <= 0 && "Select Portfolio Group"
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "trade_book_list" ||
              tableName === "portfolio_trade_book_list" ||
              tableName === "mutual_funds_list" ||
              tableName === "portfolio_mutual_funds" ||
              tableName === "portfolio_interest"
            }
          >
            <Grid item xs={12}>
              <Controller
                name="gcode"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Company Name"
                    options={
                      companiesOption(
                        companies.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("company", val);
                    }}
                    value={
                      companies?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          field.value?.length <= 0 && "Select Company Name"
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "fee_calculation" ||
              tableName === "portfolio_calculation" ||
              tableName === "proforma_invoice" ||
              tableName === "portfolio_proforma" ||
              tableName === "invoice_list" ||
              tableName === "portfolio_invoice" ||
              tableName === "trade_book_list" ||
              tableName === "wallet_full_history" ||
              tableName === "portfolio_trade_book_list" ||
              tableName === "bank_ledger_list" ||
              tableName === "mutual_funds_list" ||
              tableName === "portfolio_mutual_funds" ||
              tableName === "bank_statement_list" ||
              tableName === "income_expense_list" ||
              tableName === "portfolio_income_expense" ||
              tableName === "portfolio_bank_ledger_list" ||
              tableName === "portfolio_cash_entry"
            }
          >
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={"start_date"}
                  control={form.control}
                  render={({ field }) => (
                    <GKDateRangePicker
                      {...field}
                      inputLabel="Date Range"
                      name="date"
                      value={[
                        dayjs(form.getValues("start_date")),
                        dayjs(form.getValues("end_date")),
                      ]}
                      onChange={(value: any) => {
                        form.setValue(
                          "start_date",
                          dayjs(value[0]).format("YYYY-MM-DD")
                        );
                        form.setValue(
                          "end_date",
                          dayjs(value[1]).format("YYYY-MM-DD")
                        );
                      }}
                      disableFuture
                      slotProps={{
                        textField: {
                          error: false,
                          helperText: false,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "import_manager_list" ||
              tableName === "custom_broker" ||
              tableName === "recon_manager_list" ||
              tableName === "portfolio_import_manager_list" ||
              tableName === "portfolio_interest"
            }
          >
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="date"
                  control={form.control}
                  render={({ field }) => (
                    <GKDatePicker
                      {...field}
                      inputLabel="Transaction Date"
                      value={dayjs(field.value)}
                      onChange={(value) => {
                        field.onChange(dayjs(value).format("YYYY-MM-DD"));
                      }}
                      disableFuture
                      slotProps={{
                        textField: {
                          error: false,
                          helperText: false,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "bank_statement_list" ||
              tableName === "income_expense_list" ||
              tableName === "portfolio_income_expense" ||
              tableName === "portfolio_cash_entry"
            }
          >
            <Grid item xs={6}>
              <GKTypography fontSize={14}>Transaction Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {(tableName === "income_expense_list" ||
                      tableName === "portfolio_income_expense"
                        ? transactionTypeIncomeExpense
                        : transactionTypeCashEntry
                      ).map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field?.value?.includes(
                                  option.value
                                )
                                  ? field?.value?.filter(
                                      (item: any) => item !== option.value
                                    )
                                  : [...field?.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "trade_book_list" ||
              tableName === "portfolio_trade_book_list" ||
              tableName === "mutual_funds_list" ||
              tableName === "portfolio_mutual_funds" ||
              tableName === "bank_ledger_list" ||
              tableName === "portfolio_bank_ledger_list"
            }
          >
            <Grid item xs={6}>
              <GKTypography fontSize={14}>Transaction Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {tableName !== "bank_ledger_list" &&
                      tableName !== "portfolio_bank_ledger_list"
                        ? tradeBookTransactionType
                            .filter((dataItem) =>
                              tableName === "mutual_funds_list" ||
                              tableName === "portfolio_mutual_funds"
                                ? dataItem.value === "buy" ||
                                  dataItem.value === "sell"
                                : dataItem
                            )
                            .map((option) => (
                              <FormControlLabel
                                key={option.value}
                                control={
                                  <GKCheckbox
                                    {...field}
                                    checked={field.value.includes(option.value)}
                                    onChange={() => {
                                      const updatedOptions =
                                        field.value.includes(option.value)
                                          ? field.value.filter(
                                              (item: any) =>
                                                item !== option.value
                                            )
                                          : [...field.value, option.value];

                                      field.onChange(updatedOptions);
                                    }}
                                  />
                                }
                                label={option.name}
                              />
                            ))
                        : LEDGER_TRANSACTION_TYPE.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              control={
                                <GKCheckbox
                                  {...field}
                                  checked={field.value.includes(option.value)}
                                  onChange={() => {
                                    const updatedOptions = field.value.includes(
                                      option.value
                                    )
                                      ? field.value.filter(
                                          (item: any) => item !== option.value
                                        )
                                      : [...field.value, option.value];

                                    field.onChange(updatedOptions);
                                  }}
                                />
                              }
                              label={option.name}
                            />
                          ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "client_list" ||
              tableName === "proforma_invoice" ||
              tableName === "portfolio_proforma" ||
              tableName === "invoice_list" ||
              tableName === "portfolio_invoice"
            }
          >
            <Grid item xs={12}>
              <Controller
                name="client"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Client"
                    options={
                      clientOption(
                        clientList.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("clientName", val);
                    }}
                    value={
                      clientList?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={field.value.length <= 0 && "Select Client"}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "employee_list" ||
              tableName === "import_manager_list" ||
              tableName === "recon_manager_list" ||
              tableName === "portfolio_import_manager_list"
            }
          >
            <Grid item xs={12}>
              <Controller
                // name={
                //   tableName === "import_manager_list" ||
                //   tableName === "recon_manager_list" ||
                //   tableName === "portfolio_import_manager_list"
                //     ? "uploaded_by"
                //     : "name"
                // }
                name="employee"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel={
                      tableName === "import_manager_list" ||
                      tableName === "recon_manager_list" ||
                      tableName === "portfolio_import_manager_list"
                        ? "Uploaded By"
                        : "Admin"
                    }
                    options={employeeOption(employeeList, form) || []}
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );

                      field.onChange(selectedIds);
                      form.setValue("employeeName", val);
                    }}
                    value={
                      employeeList?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={field.value.length <= 0 && "Select Admin"}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "holding_summary_list" ||
              tableName === "insights_list" ||
              tableName === "portfolio_holding_list" ||
              tableName === "portfolio_group_holding_list" ||
              tableName === "client_holding_list" ||
              tableName === "family_holding_list" ||
              tableName === "branch_holding_list" ||
              tableName === "employee_holding_list"
            }
          >
            <Grid item xs={12}>
              <Controller
                name={"gcode"}
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Company Name"
                    options={
                      companiesOption(
                        companies.map((dataItem) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("company", val);
                    }}
                    value={
                      companies?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          field.value.length <= 0 && "Select Company Name"
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>

          <Show.When isTrue={tableName === "import_manager_list"}>
            <Grid item xs={6}>
              <GKTypography fontSize={14}>File Formats</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name={"format"}
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {importManagerFormats.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field.value.includes(
                                  option.value
                                )
                                  ? field.value.filter(
                                      (item: any) => item !== option.value
                                    )
                                  : [...field.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <GKTypography fontSize={14}>Upload Type</GKTypography>
              <FormControl component="fieldset">
                <Controller
                  name={`source`}
                  control={form.control}
                  render={({ field }) => (
                    <FormGroup>
                      {importManagerSource.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <GKCheckbox
                              {...field}
                              checked={field.value.includes(option.value)}
                              onChange={() => {
                                const updatedOptions = field.value.includes(
                                  option.value
                                )
                                  ? field.value.filter(
                                      (item: any) => item !== option.value
                                    )
                                  : [...field.value, option.value];

                                field.onChange(updatedOptions);
                              }}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid>
          </Show.When>

          <Show.When
            isTrue={
              tableName === "fee_calculation" ||
              tableName === "portfolio_calculation"
            }
          >
            <Grid item xs={12}>
              <Controller
                name={"feePlan"}
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    {...field}
                    multiple
                    disableClearable={false}
                    disableCloseOnSelect
                    limitTags={5}
                    inputLabel="Fee Plan"
                    loading={getFeePlansFetching}
                    options={
                      feePlanOption(
                        feePlansList?.data?.data.map((dataItem: any) => {
                          return {
                            name: dataItem.name,
                            value: dataItem.value,
                          };
                        }),
                        form
                      ) || []
                    }
                    onChange={(e, val) => {
                      const selectedIds = val.map(
                        (option: any) => option.value
                      );
                      field.onChange(selectedIds);
                      form.setValue("feePlanName", val);
                    }}
                    value={
                      feePlansList?.data?.data?.filter((option: any) =>
                        field.value?.includes(option.value)
                      ) || []
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="feePlan"
                        placeholder={
                          field.value.length <= 0 && "Select Fee Plan"
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Show.When>
        </Grid>
      </form>
    </GKModal>
  );
};

export default GKCommonTableFilterModal;
