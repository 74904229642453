import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { emailRegex, validateNotWhitespaceOnly } from "utils/validationSchema";

const EditAdminModal = ({ open, setOpen }: any) => {
  const { id } = useParams<string>();

  const { data } = useFetchQuery({
    key: ["GET_EMPLOYEE", id],
    enabled: Boolean(id),
    route: `/employees/${id}/`,
  });

  const { mutate: submitHandler, isLoading: isSubmitting } = useMutation({
    mutationKey: ["UPDATE_EMPLOYEE"],
    mutationFn: (data: any) => axiosInstance.patch(`/employees/${id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
      refetchQuery(QueryKeys.EMPLOYEE_LIST);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      position: "",
      salary: "",
      user: {
        phone: "",
        countryCode: "",
        personalDetails: {
          name: "",
          email: "",
          aadhar: "",
          dateOfBirth: "",
          emergencyContact: "",
          emergencyContactNum: "",
        },
      },
    },
    values: data?.data,
  });

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Admin Profile Details"
      footer={
        <GKButton
          color="primary"
          disabled={isSubmitting}
          variant="contained"
          size="medium"
          type="submit"
          form="admin-profile-details-form"
        >
          Save
        </GKButton>
      }
    >
      <form
        id="admin-profile-details-form"
        onSubmit={form.handleSubmit((values) => submitHandler(values))}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter name",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Name"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter Email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Email"
                    type="email"
                    requiredField
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.phone"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter a valid Phone Number",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    requiredField
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("user.countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event?.target?.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("user.countryCode")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="position"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Position" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="salary"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} type="number" inputLabel="Salary" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.aadhar"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Aadhar Card"
                    type="number"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.dateOfBirth"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Date of Birth"
                    value={dayjs(field.value)}
                    disableFuture
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.emergencyContact"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Emergency Contact Name" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="user.personalDetails.emergencyContactNum"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    type="tel"
                    inputLabel="Emergency Contact Number"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default EditAdminModal;
