import { useMutation } from "@tanstack/react-query";
import { useFetchQuery } from "hooks/useQueries";
import { useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";

const useModelAllocation = () => {
  useDocumentTitle("Model Portfolio Allocation");

  const { theme } = ThemeContainer();

  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);

  const [responseData, setResponseData] = useState([]);

  const [companyWiseRebalanceData, setCompanyWiseRebalanceData] = useState([]);

  const [freezeData, setFreezeData] = useState<any[]>([]);

  const [holdData, setHoldData] = useState<any[]>([]);

  const [openGenerateRationaleModal, setOpenGenerateRationaleModal] =
    useState(false);

  const { id } = useParams();

  // =================================================================================
  const form = useForm({
    defaultValues: {
      allocationType: "",
      exitWeightage: "",
      rationale: "",
      weightageDistributionMethod: "",
      transactionType: "",
      rebalanceType: false,
      allocationBy: "",
      rebalance_using: "",
      version: {} as any,
      portfolios: [],
      companies: [],
    },
  });

  // =================================================================================
  const { fields, replace, update, remove } = useFieldArray({
    name: "portfolios",
    control: form.control,
  });

  // =================================================================================
  const { fields: companiesFields, replace: companyReplace } = useFieldArray({
    name: "companies",
    control: form.control,
  });
  form.watch();

  // =================================================================================
  const { data: companiesData, isFetching: companiedFetching } = useFetchQuery({
    key: ["FETCH_COMPANIES"],
    route: "/intruments/list/",
  });

  // =================================================================================
  const { data: allVersionsData } = useFetchQuery({
    key: ["MODAL_PORTFILIO_ALLOCATION_VERSIONS"],
    route: `/model_portfolio/${id}/allocation-versions/`,
  });

  // =================================================================================
  const { data: portfolioDataData } = useFetchQuery({
    key: ["MODAL_PORTFILIO_DATA_LIST"],
    route: `/model_portfolio/${id}/allocation/`,
  });

  // =================================================================================
  const transactionType = useMemo(
    () => [
      {
        name: "Allocated",
        value: "allocated",
      },
      {
        name: "Current",
        value: "current",
      },
    ],
    []
  );

  // =================================================================================
  const weightageDistributionMode = useMemo(
    () => [
      { value: "PRORATA", name: "Prorata" },
      { value: "EQUAL", name: "Equal" },
    ],
    []
  );

  // =================================================================================
  const rebalanceRefrenceType = useMemo(
    () => [
      {
        name: "Current",
        value: "current",
      },
      {
        name: "Portfolio",
        value: "portfolio",
      },
      {
        name: "Committed Value",
        value: "committedValue",
      },
      {
        name: "Ledger Balance",
        value: "ledgerBalance",
      },
    ],
    []
  );
  // =================================================================================
  const exitRefrenceType = useMemo(
    () => [
      {
        name: "Current",
        value: "current",
      },
      {
        name: "Portfolio",
        value: "portfolio",
      },
      {
        name: "Committed Value",
        value: "committedValue",
      },
    ],
    []
  );

  // =================================================================================
  const allocationType = useMemo(
    () => [
      {
        name: "Allocation",
        value: "allocation",
      },
      {
        name: "Rebalance",
        value: "rebalance",
      },
      {
        name: "Exit",
        value: "exit",
      },
    ],
    []
  );

  // =================================================================================
  const allocationByData = useMemo(
    () => [
      {
        name: "Model",
        disabled: false,
        value: "version",
      },
      {
        name: "Company",
        value: "company",
        disabled: form.getValues("allocationType") === "rebalance",
      },
    ],
    []
  );

  // =================================================================================
  const allocationByExitData = useMemo(
    () => [
      {
        name: "Model",
        disabled: false,
        value: "version",
      },
      {
        name: "Company-Absolute",
        disabled: false,
        value: "company",
      },
      {
        name: "Portfolio",
        disabled: false,
        value: "portfolio",
      },
      {
        name: "Company-Weightage",
        disabled: false,
        value: "companyWeightage",
      },
    ],
    []
  );

  // =================================================================================
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/model_portfolio/${id}/allocation/`, data),
    onSuccess: (response: any) => {
      if (form.getValues("rebalanceType")) {
        setCompanyWiseRebalanceData(
          response?.data?.map((dataItem: any) => {
            return {
              ...dataItem,
              ...dataItem?.plan[0],
              totalAmount:
                Number(dataItem?.plan[0]?.cmp) *
                Number(dataItem?.plan[0]?.quantity),
            };
          })
        );
      } else {
        setResponseData(response?.data);
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // =================================================================================

  const { mutate: createOrder, isLoading: createLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/model_portfolio/${id}/order-manager/`, data),
    onSuccess: (response) => {
      setOpenGenerateRationaleModal(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // =================================================================================

  const formSubmit = (value: any) => {
    setFreezeData([]);
    setHoldData([]);
    const restData = value?.portfolios?.map((dataItem: any) => {
      return {
        ...dataItem,
        exitWeightage: Number(dataItem?.exitWeightage) || 0,
        cash: Number(dataItem?.cash),
        id: Number(dataItem?.portfolioId),
        // @ts-ignore
        extraStatus: [],
      };
    });

    mutate({
      use_current_allocation: value.transactionType === "current",
      exit_weightage: Number(value.exitWeightage),
      alloc_type: value.allocationType,
      allocation_by: value.allocationBy,
      rebalance_using: value.rebalance_using,
      weightageDistributionMethod: value.weightageDistributionMethod,
      alloc_version: value.version?.id,
      portfolios: restData,
      companies: value?.rebalanceType
        ? value?.companies.map((dataItem: any) => {
            return {
              ...dataItem,
              weightage: 0,
            };
          })
        : [],
      stock_wise: value?.rebalanceType,
    });
  };

  // =================================================================================

  const formCompanySubmit = (value: any) => {
    setFreezeData([]);
    setHoldData([]);
    const restData = value?.portfolios?.map((dataItem: any) => {
      return {
        ...dataItem,
        cash: Number(dataItem?.availableCash) + Number(dataItem?.currentAmount),
        id: Number(dataItem?.portfolioId),
        // @ts-ignore
        extraStatus: [],
      };
    });

    const restCompanies = value?.companies?.map((dataItem: any) => {
      return {
        ...dataItem,
        weightage: Number(dataItem?.weightage),
      };
    });

    mutate({
      alloc_type: value.allocationType,
      allocation_by: value.allocationBy,
      use_current_allocation: value.transactionType === "current",
      rebalance_using: value.rebalance_using,
      weightageDistributionMethod: value.weightageDistributionMethod,
      alloc_version: value.version?.id,
      portfolios: restData,
      companies: restCompanies || [],
    });
  };

  // =================================================================================

  const handleRecalculateStockRebalance = () => {
    mutate({
      use_current_allocation: form.getValues("transactionType") === "current",
      alloc_type: form.getValues("allocationType"),
      weightageDistributionMethod: form.getValues(
        "weightageDistributionMethod"
      ),
      allocation_by: form.getValues("allocationBy"),
      rebalance_using: form.getValues("rebalance_using"),
      alloc_version: form.getValues("version")?.id,
      stock_wise: form?.getValues("rebalanceType"),
      companies: form?.getValues("rebalanceType")
        ? form?.getValues("companies")?.map((dataItem: any) => {
            return {
              ...dataItem,
              weightage: 0,
            };
          })
        : [],
      custom_quantities: companyWiseRebalanceData?.map((data: any) => {
        return {
          quantity: data?.quantity,
          grid_code: data?.gridCode,
          id: data?.id,
        };
      }),
      portfolios: companyWiseRebalanceData,
      frozen_companies: freezeData,
      hold_companies: holdData,
    });
  };

  // =================================================================================

  const handleRecalculate = () => {
    mutate({
      use_current_allocation: form.getValues("transactionType") === "current",
      alloc_type: form.getValues("allocationType"),
      weightageDistributionMethod: form.getValues(
        "weightageDistributionMethod"
      ),
      allocation_by: form.getValues("allocationBy"),
      companies: form.getValues("companies"),
      rebalance_using: form.getValues("rebalance_using"),
      alloc_version: form.getValues("version")?.id,
      portfolios: responseData?.map((data: any) => {
        return {
          ...data,
          id: data?.id,
          cash: data?.cash,
        };
      }),
      frozen_companies: freezeData,
      hold_companies: holdData,
    });
  };

  // =================================================================================
  const handleCreateRebalanceOrder = () => {
    createOrder({
      use_current_allocation: form.getValues("transactionType") === "current",
      alloc_type: form.getValues("allocationType"),
      rationale: form.getValues("rationale"),
      allocation_by: form.getValues("allocationBy"),
      rebalance_using: form.getValues("rebalance_using"),
      weightageDistributionMethod: form.getValues(
        "weightageDistributionMethod"
      ),
      alloc_version: form.getValues("version")?.id,
      stock_wise: form?.getValues("rebalanceType"),
      portfolios: companyWiseRebalanceData,
      companies: form?.getValues("rebalanceType")
        ? form?.getValues("companies")?.map((dataItem: any) => {
            return {
              ...dataItem,
            };
          })
        : [],
      custom_quantities: companyWiseRebalanceData?.map((data: any) => {
        return {
          quantity: data?.quantity,
          grid_code: data?.gridCode,
          id: data?.id,
        };
      }),
      frozen_companies: freezeData,
      hold_companies: holdData,
    });
  };

  // =================================================================================

  const handleCreateOrder = () => {
    createOrder({
      use_current_allocation: form.getValues("transactionType") === "current",
      alloc_type: form.getValues("allocationType"),
      allocation_by: form.getValues("allocationBy"),
      rationale: form.getValues("rationale"),
      weightageDistributionMethod: form.getValues(
        "weightageDistributionMethod"
      ),
      rebalance_using: form.getValues("rebalance_using"),
      alloc_version: form.getValues("version")?.id,
      companies:
        form?.getValues("companies")?.length !== 0
          ? form?.getValues("companies")?.map((dataItem: any) => {
              return {
                ...dataItem,
              };
            })
          : [],
      portfolios: responseData?.map((data: any) => {
        return {
          id: data?.id,
          cash: data?.cash,
          exitWeightage: Number(data?.exitWeightage),
        };
      }),
      frozen_companies: freezeData,
      hold_companies: holdData,
    });
  };

  return {
    theme,
    openPortfolioModal,
    setOpenPortfolioModal,
    id,
    fields,
    replace,
    update,
    remove,
    companiesFields,
    companyReplace,
    form,
    formSubmit,
    allocationType,
    transactionType,
    companiesData,
    companiedFetching,
    allVersionsData,
    portfolioDataData,
    responseData,
    allocationByData,
    rebalanceRefrenceType,
    formCompanySubmit,
    setResponseData,
    isLoading,
    freezeData,
    setFreezeData,
    holdData,
    setHoldData,
    handleRecalculate,
    createLoading,
    handleCreateOrder,
    companyWiseRebalanceData,
    setCompanyWiseRebalanceData,
    handleRecalculateStockRebalance,
    handleCreateRebalanceOrder,
    allocationByExitData,
    openGenerateRationaleModal,
    setOpenGenerateRationaleModal,
    exitRefrenceType,
    weightageDistributionMode,
  };
};

export default useModelAllocation;
