import { Box, IconButton, Popper } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ClickOutside from "components/ClickOutside";
import GKButton from "components/GKButton";
import React, { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";
import PreTradeConfirmationModal from "./PreTradeConfirmationModal";

interface Props {
  item: any;
  type: string[];
  icon: any;
}

const OrderPopOverActions = ({ item, type, icon }: Props) => {
  const { theme } = ThemeContainer();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadName, setDownloadName] = useState("");
  const [orderId, setOrderId] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [sendEmailType, setSendEmailType] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const id = open ? "upload-popper" : undefined;

  const { mutate: DownloadZIP } = useMutation({
    mutationFn: (data: any) => {
      setDownloadName(data?.name);
      return axiosInstance.get(
        `/model_portfolio/${data?.modelPortfolioId}/download-clients-zip/?order=${data?.orderId}`,
        { responseType: "arraybuffer" }
      );
    },
    retry: false,
    onSuccess: (data: any) => {
      const url = window.URL.createObjectURL(
        new Blob([data?.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `${downloadName}.zip`;

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: DownloadCSV } = useMutation({
    mutationFn: (data: any) => {
      setDownloadName(data?.name);
      return axiosInstance.get(
        `/model_portfolio/${data?.modelPortfolioId}/order-download/?order=${data?.orderId}`
      );
    },
    retry: false,
    onSuccess: (data: any) => {
      const blob = new Blob([data?.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <>
      <IconButton onClick={handleClick}>{icon}</IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: type.includes("ORDER") && type.includes("TRADE") ? 170 : 150,
        }}
      >
        <ClickOutside
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.border.main}`,
              borderRadius: "10px",
              boxShadow: `0px 0px 5px 4px ${theme.palette.primary.main}44`,
              p: 1,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {type.includes("ZIP") && (
              <GKButton
                variant="outlined"
                onClick={() => {
                  DownloadZIP({
                    orderId: item.id,
                    modelPortfolioId: item.modelPortfolioId,
                    name: `${item.orderTemplate}`,
                  });
                }}
              >
                Download Zip
              </GKButton>
            )}

            {type.includes("CSV") && (
              <GKButton
                variant="outlined"
                onClick={() => {
                  DownloadCSV({
                    orderId: item.id,
                    modelPortfolioId: item.modelPortfolioId,
                    name: `${item.orderTemplate}`,
                  });
                }}
              >
                Download CSV
              </GKButton>
            )}

            {type.includes("TRADE") && (
              <GKButton
                variant="outlined"
                onClick={() => {
                  setOrderId(item.id);
                  setSendEmailType("TRADE");
                  setConfirmationModal(true);
                }}
              >
                Send Order Sheet
              </GKButton>
            )}

            {type.includes("ORDER") && (
              <GKButton
                variant="outlined"
                onClick={() => {
                  setOrderId(item.id);
                  setSendEmailType("ORDER");
                  setConfirmationModal(true);
                }}
              >
                Send for Execution
              </GKButton>
            )}
          </Box>
        </ClickOutside>
      </Popper>

      {confirmationModal && (
        <PreTradeConfirmationModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          orderId={orderId}
          sendEmailType={sendEmailType}
        />
      )}
    </>
  );
};

export default OrderPopOverActions;
