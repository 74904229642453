import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { handleCapitalize } from "utils/common";
import { matchDate } from "utils/helpers";
import {
  moneyCommaSeparatorPoint,
  moneyCommaSeparator,
} from "utils/MathFunction";

interface Props {
  selectedId: any;
  oldIdSelected: any;
}

const ModelTradebook = ({ selectedId, oldIdSelected }: Props) => {
  const { id } = useParams();

  const { isLoading, data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_TRADEBOOK", oldIdSelected],
    route: `/model_portfolio/${id}/version/${
      oldIdSelected?.snapshotVersion || selectedId
    }/version_transactions/`,
    enabled: Boolean(selectedId),
  });

  const transactionColumns: GridColDef[] = [
    {
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      field: "date",
      valueFormatter: (value) => matchDate(value.value),
      disableColumnMenu: true,
    },

    {
      headerName: "Company Name",
      flex: 1,
      minWidth: 150,
      field: "companyName",
      disableColumnMenu: true,
    },
    {
      headerName: "ISIN Code",
      flex: 1,
      minWidth: 150,
      field: "isinCode",
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
    },
    {
      headerName: "NSE/BSE Code",
      flex: 1,
      minWidth: 150,
      field: "nseCode",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.nseCode || params?.row?.bseCode}
          </Typography>
        );
      },
    },
    {
      headerName: "Transaction Type",
      valueFormatter: (params) => handleCapitalize(params?.value),
      flex: 1,
      minWidth: 150,
      field: "transactionType",
      disableColumnMenu: true,
    },
    {
      headerName: "Quantity",
      align: "right",
      headerAlign: "right",
      flex: 1,
      minWidth: 150,
      field: "quantity",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
    },
    {
      headerName: "Price",
      flex: 1,
      minWidth: 150,
      field: "price",
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
  ];

  return (
    <GKClientDataGrid
      name="modelTradebook"
      loading={isLoading}
      density="compact"
      showFiltersButton={false}
      columns={transactionColumns || []}
      rows={data?.data?.data || []}
    />
  );
};

export default ModelTradebook;
