/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdRestartAlt } from "react-icons/md";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { ThemeContainer } from "utils/Theme";
import {
  LARGE_CAP_COLOR,
  MID_CAP_COLOR,
  PieChartColors,
  SMALL_CAP_COLOR,
} from "utils/constants";
import { extraColumnsField } from "utils/helpers";
import PieChartSection from "./PieChartSection";
import SunburstSection from "./SunburstSection";
import { handleReturnPositiveNegative } from "utils/colorHelper";

interface InsigntsPageContentProps {
  allHoldingsInsightsData?: any[];
  isLoading: boolean;
  onRowClick?: (row: any) => void;
  selectedItems: any[];
}

const InsigntsPageContent = (props: InsigntsPageContentProps) => {
  const {
    allHoldingsInsightsData,
    isLoading = false,
    onRowClick,
    selectedItems,
  } = props;

  const {
    state: { commonFilter },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const [pieClickData, setPieClickData] = useState<any>();

  const [currentValueTabs, setCurrentValueTabs] = useState(0);

  const [sunBurstClickData, setSunBurstClickData] = useState<any>();

  const [sunBurstHoverData, setSunBurstHoverData] = useState<any>();

  const [pieHoverData, setPieHoverData] = useState<any>();

  const sunBurstRef = useRef(null);

  const pieRef = useRef(null);

  const [pieData, setPieData] = useState<any>([]);

  const [sunBurstData, setSunBurstData] = useState<any>([]);

  const [capSizes, setCapSizes] = useState<any>([]);

  const requestBody = {
    take: commonFilter?.insights?.take,
    skip: commonFilter?.insights?.skip,
    sort: JSON.stringify(
      commonFilter?.insights?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.insights?.search || "",
    show_zero_holding: commonFilter?.insights?.showNonZero,
    filter: JSON.stringify(commonFilter?.insights?.customFilter),
    portfolios: JSON.stringify(
      selectedItems
        ?.map((data: any) => data?.portfolios)
        ?.flat()
        ?.map((dataItem) => dataItem?.value)
    ),
  };

  const { data: holdingData, isFetching: holdingLoading } = useFetchQuery({
    key: [
      "INSIGHTS",
      requestBody,
      selectedItems
        ?.map((data: any) => data?.portfolios)
        ?.flat()
        ?.map((dataItem) => dataItem?.value),
    ],
    retry: 0,
    route: "/holdings/table/",
    requestBody,
  });

  // useMemo(() => {
  //   const newData = allHoldingsInsightsData?.map((data) => {
  //     return {
  //       ...data,
  //       name: data?.asset?.name,
  //     };
  //   });
  //   setTableData(
  //     newData?.filter((val: any) => {
  //       if (sunBurstClickData) {
  //         if (sunBurstClickData.id === "Large Cap") {
  //           return val.asset.cap === "large";
  //         }
  //         if (sunBurstClickData.id === "Mid Cap") {
  //           return val.asset.cap === "medium";
  //         }
  //         if (sunBurstClickData.id === "Small Cap") {
  //           return val.asset.cap === "small";
  //         }
  //         return (
  //           val.asset.cap === sunBurstClickData.id ||
  //           val.asset.name === sunBurstClickData.id
  //         );
  //       }
  //       if (pieClickData) {
  //         return val.asset.sector === pieClickData.id;
  //       }
  //       return val;
  //     })
  //   );
  // }, [allHoldingsInsightsData, sunBurstClickData]);

  const checkCurrentValue = (sum: any, current: any) =>
    currentValueTabs === 0
      ? sum + current?.currentAmount
      : sum + current?.investedAmount;

  const totalInvestment = allHoldingsInsightsData?.reduce(
    (sum: any, current: any) => checkCurrentValue(sum, current),
    0
  );

  const sectorsArray = () =>
    allHoldingsInsightsData.map((item: any) => ({
      sector: item.asset.sector,
      companyName: item.asset.name,
      investedAmount: item.investedAmount,
      companyPercentage:
        currentValueTabs === 0
          ? (item.currentAmount / totalInvestment) * 100
          : (item.investedAmount / totalInvestment) * 100,
    }));

  const cumulativeSectorsWithPercentage = () =>
    sectorsArray()?.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.sector === current.sector);
      if (!x) {
        return acc.concat([current]);
      }
      return acc?.map((item: any) =>
        item.sector === current.sector
          ? {
              ...item,
              companyPercentage:
                item.companyPercentage + current.companyPercentage,
            }
          : item
      );
    }, []);

  // ========================================================================================================================================================================

  const handleNewFn = (capName: string, amountType: any) => {
    const groupedData = _.groupBy(
      allHoldingsInsightsData?.map((data) => {
        return {
          ...data,
          cap: data.asset?.cap,
        };
      }),
      "cap"
    );

    const allCapSum = Object.entries(groupedData)?.map(([key, value]) => {
      return {
        children: value,
        cap: key,
        totalCapValue: _.sum(
          value?.map((item) =>
            amountType === 0 ? item?.currentAmount : item?.investedAmount
          )
        ),
        totalCapPercentage:
          (_.sum(
            value?.map((item) =>
              amountType === 0 ? item?.currentAmount : item?.investedAmount
            )
          ) /
            _.sum(
              allHoldingsInsightsData?.map((item) =>
                amountType === 0 ? item?.currentAmount : item?.investedAmount
              )
            )) *
          100,
        totalCurrentValue: _.sum(
          allHoldingsInsightsData?.map((item) =>
            amountType === 0 ? item?.currentAmount : item?.investedAmount
          )
        ),
      };
    });

    return allCapSum
      ?.filter((dataItem) => dataItem?.cap === capName)[0]
      ?.totalCapPercentage?.toFixed(2);
  };

  // ========================================================================================================================================================================

  const totalValue = _.sum(
    allHoldingsInsightsData?.map((data: any) =>
      currentValueTabs === 0 ? data?.currentAmount : data?.investedAmount
    )
  );

  const capData = (capSize: string, sector?: string) =>
    allHoldingsInsightsData
      ?.filter((data: any) => {
        if (sector)
          return data.asset.sector === sector && data.asset.cap === capSize;
        return data.asset.cap === capSize;
      })
      .filter(
        (item: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.asset.name === item.asset.name)
      )
      .map((element: any) => {
        const data = {
          name: element.asset.name,
          color: `#${(0x1000000 + Math.random() * 0xffffff)
            .toString(14)
            .substr(1, 6)}55`,
          loc:
            currentValueTabs === 0
              ? (element.currentAmount / totalValue) * 100
              : (element.investedAmount / totalValue) * 100,
          sector: element.asset.sector,
          capSize,
        };
        return data;
      })
      ?.filter((item: any) => item.loc > 0);

  useMemo(() => {
    if (pieClickData) {
      // setTableData(
      //   allHoldingsInsightsData?.filter(
      //     (val: any) => val.asset.sector === pieClickData.id
      //   )
      // );
      setSunBurstData({
        name: "sunburst",
        children: [
          {
            name: "Large Cap",
            color: LARGE_CAP_COLOR,
            children: capData("large")
              .sort((a: any, b: any) => b.loc - a.loc)
              .filter((item: any) => item.sector === pieClickData?.id),
          },
          {
            name: "Mid Cap",
            color: MID_CAP_COLOR,
            children: capData("medium")
              .sort((a: any, b: any) => b.loc - a.loc)
              .filter((item: any) => item.sector === pieClickData?.id),
          },
          {
            name: "Small Cap",
            color: SMALL_CAP_COLOR,
            children: capData("small")
              .sort((a: any, b: any) => b.loc - a.loc)
              .filter((item: any) => item.sector === pieClickData?.id),
          },
        ],
      });
      setCapSizes([
        {
          name: "Large Cap",
          value: handleNewFn("large", currentValueTabs),
          color: LARGE_CAP_COLOR,
        },
        {
          name: "Mid Cap",
          value: handleNewFn("medium", currentValueTabs),
          color: MID_CAP_COLOR,
        },
        {
          name: "Small Cap",
          value: handleNewFn("small", currentValueTabs),
          color: SMALL_CAP_COLOR,
        },
      ]);
    }
  }, [allHoldingsInsightsData, pieClickData]);

  const defaultData = () => {
    setSunBurstData({
      name: "GridKey Sunburst",
      children: [
        {
          name: "Large Cap",
          color: LARGE_CAP_COLOR,
          children: capData("large")
            .sort((a: any, b: any) => b.loc - a.loc)
            .map((dataItem, index) => {
              return {
                ...dataItem,
                color: PieChartColors[index < 12 ? index : index % 12],
              };
            }),
        },
        {
          name: "Mid Cap",
          color: MID_CAP_COLOR,
          children: capData("medium")
            .sort((a: any, b: any) => b.loc - a.loc)
            .map((dataItem, index) => {
              return {
                ...dataItem,
                color: PieChartColors[index < 12 ? index : index % 12],
              };
            }),
        },
        {
          name: "Small Cap",
          color: SMALL_CAP_COLOR,
          children: capData("small")
            .sort((a: any, b: any) => b.loc - a.loc)
            .map((dataItem, index) => {
              return {
                ...dataItem,
                color: PieChartColors[index < 12 ? index : index % 12],
              };
            }),
        },
      ],
    });
    // cap sizes
    setCapSizes([
      {
        name: "Large Cap",
        value: handleNewFn("large", currentValueTabs),
        color: LARGE_CAP_COLOR,
      },
      {
        name: "Mid Cap",
        value: handleNewFn("medium", currentValueTabs),
        color: MID_CAP_COLOR,
      },
      {
        name: "Small Cap",
        value: handleNewFn("small", currentValueTabs),
        color: SMALL_CAP_COLOR,
      },
    ]);
  };

  useMemo(() => {
    // pie data set
    setPieData(
      cumulativeSectorsWithPercentage()
        ?.map((item: any) => {
          const { sector } = item;
          const data = {
            id: sector,
            value: +item.companyPercentage,
            color: "",
          };

          return data;
        })
        ?.sort((a: any, b: any) => b.value - a.value)
        ?.map((item: any, index: number) => {
          return {
            ...item,
            color: PieChartColors[index < 12 ? index : index % 12],
          };
        })
    );
    defaultData();
  }, [allHoldingsInsightsData, currentValueTabs === 0]);

  useEffect(() => {
    if (sunBurstRef.current)
      sunBurstRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
  }, [sunBurstRef?.current]);

  useEffect(() => {
    if (pieRef.current)
      pieRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
  }, [pieRef?.current]);

  const exportCsv = () => {
    return (
      <ExportCsv path={"/holdings/selected"} name={"HoldingSummaryList"} />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Asset Name", "name", 150, 1),
      editable: false,
      valueGetter: (params) => params.row.name || "-",
    },
    {
      ...extraColumnsField("Quantity", "rawQuantity", 150, 1),
      editable: false,
      headerAlign: "right",
      align: "right",
      valueGetter: (params) => params.row.rawQuantity || 0,
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
    {
      ...extraColumnsField("Avg. Buy Price", "avgBuyPrice", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.avgBuyPrice || moneyCommaSeparator(0),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueGetter: (params) =>
        params.row.investedAmount || moneyCommaSeparator(0),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("CMP", "cmp", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.cmp || moneyCommaSeparator(0),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Current Value", "currentAmount", 150, 1),
      editable: false,
      headerAlign: "right",
      align: "right",
      valueGetter: (params) =>
        params.row.currentAmount || moneyCommaSeparator(0),
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolios", "countPortfolios", 150, 1),
      editable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.countPortfolios || "-",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={holdingData?.data?.totalCount || 0}
        name="insights"
      />
    );
  };

  return (
    <>
      {!isLoading && allHoldingsInsightsData?.length !== 0 && (
        <Card sx={{ mb: 1, padding: 1 }}>
          <Grid container>
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              pt={3}
              pl={2}
            >
              <Typography
                fontWeight={700}
                fontSize={20}
                color={theme.palette.primary.main}
              >
                Asset Allocation{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              pt={3}
              pr={2}
            >
              <IconButton
                onClick={() => {
                  setPieClickData("");
                  setSunBurstClickData("");
                  setSunBurstHoverData("");
                  setPieHoverData("");
                  setCurrentValueTabs(0);
                  defaultData();
                }}
              >
                <MdRestartAlt style={{ fontSize: 23 }} />
              </IconButton>
              <Divider
                orientation="vertical"
                style={{ height: "40px", margin: "0 10px" }}
              />
              <Tabs
                className="gridkey"
                onChange={(e, val) => {
                  setCurrentValueTabs(val);
                }}
                value={currentValueTabs}
                variant="standard"
              >
                <Tab disableRipple label="Current " />
                <Tab disableRipple label="Invested" />
              </Tabs>
            </Grid>
            <Grid item xs={12} py={5} container>
              <Grid item xs={6}>
                <PieChartSection
                  pieData={pieData?.filter((data: any) => data?.value !== 0)}
                  pieClickData={pieClickData}
                  pieHoverData={pieHoverData}
                  setPieClickData={setPieClickData}
                  setPieHoverData={setPieHoverData}
                  pieRef={pieRef}
                />
              </Grid>
              <Grid item xs={6}>
                <SunburstSection
                  sunBurstClickData={sunBurstClickData}
                  sunBurstHoverData={sunBurstHoverData}
                  setSunBurstClickData={setSunBurstClickData}
                  setSunBurstHoverData={setSunBurstHoverData}
                  sunBurstData={sunBurstData}
                  sunBurstRef={sunBurstRef}
                  capSizes={capSizes}
                  pieClickData={pieClickData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      {/* <GKClientDataGrid
        name="holdingSummary"
        loading={isLoading}
        searchPlaceholder="Search for Assets"
        columns={columns}
        rows={
          tableData?.map((data: any) => {
            return {
              ...data,
              name: data.asset.name,
            };
          }) || []
        }
        getRowId={(row) => row?.asset?.gcode}
        onRowClick={onRowClick}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        exportCsv={exportCsv}
        pinnedColumns={{
          left: ["name"],
        }}
      /> */}

      <GKDataGrid
        density="compact"
        searchPlaceholder="Search for Assets"
        loading={holdingLoading}
        columns={columns}
        rows={holdingData?.data?.data || []}
        getRowId={(row) => row?.isin}
        exportCsv={exportCsv}
        zeroHolding
        CustomPagination={!holdingLoading && cusPagination}
        name="insights"
        tableName="insights_list"
        filterButton={false}
        onRowClick={onRowClick}
        pinnedColumns={{
          left: ["name"],
        }}
        headerFilter={<TableFiltering name="insights" />}
        columnsButton={false}
      />
    </>
  );
};

export default InsigntsPageContent;
