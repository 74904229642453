/* eslint-disable react/no-danger */
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKSideModal from "components/GKSideModal";
import GKTextField from "components/GKTextField";
import { useFetchQuery } from "hooks/useQueries";
import { replace } from "lodash";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { CgMathPlus } from "react-icons/cg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface CustomAgreementModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRow?: any;
}

const CustomEditor = styled(ReactQuill)({
  "& .ql-editor": {
    height: "calc(100vh - 372px)",
  },
});

const CustomAgreementModal = (props: CustomAgreementModalProps) => {
  const { open, setOpen, selectedRow } = props;

  const { theme } = ThemeContainer();

  const quillRef = useRef<ReactQuill | null>(null);

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  // ============================================================================
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        // @ts-ignore
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    },
  };

  // ============================================================================
  const { data } = useFetchQuery({
    key: ["GET_ALL_VARIABLES"],
    route: `custom-agreement/variables/`,
  });

  // ============================================================================
  const appendVariable = (variableValue: string) => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, `{{${variableValue}}}`);
        // @ts-ignore
        quill.setSelection(range.index + variableValue.length + 4);
      }
    }
  };

  // ============================================================================
  const replaceVariables = (val: string) => {
    const variablePattern = /{{\s*[^}]+\s*}}/g;
    return val.replace(variablePattern, "_________________");
  };

  // ============================================================================
  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_CUSTOME_AGREEMENT"],
    mutationFn: async (val: any) =>
      axiosInstance.post("/custom-agreement/", {
        content: replace(val?.content, /<p><br\s?\/?><\/p>/g, " "),
        title: val?.title,
      }),
    onSuccess: () => {
      refetchQuery("GET_ALL_CUSTOM_AGREEMENTS");
      setOpen(false);
    },
  });

  // ============================================================================
  const { mutate: handleUpdate, isLoading: updateLoading } = useMutation({
    mutationKey: ["CREATE_CUSTOME_AGREEMENT"],
    mutationFn: async (val: any) =>
      axiosInstance.patch(`/custom-agreement/${selectedRow?.id}/`, {
        content: replace(val?.content, /<p><br\s?\/?><\/p>/g, " "),
        title: val?.title,
      }),
    onSuccess: () => {
      refetchQuery("GET_ALL_CUSTOM_AGREEMENTS");
      setOpen(false);
    },
  });

  const form = useForm({
    defaultValues: {
      title: selectedRow?.title || "",
      content: selectedRow?.content || "",
    },
  });

  return (
    <GKSideModal
      open={open}
      setOpen={setOpen}
      width={"100%"}
      modalTitle="Custom Template"
      footerButton={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            loading={isLoading || updateLoading}
            variant="contained"
            type="submit"
            form="custom-agreement-form"
          >
            Save
          </LoadingButton>
        </Grid>
      }
    >
      <form
        id="custom-agreement-form"
        onSubmit={form.handleSubmit((values) => {
          if (selectedRow) {
            handleUpdate(values);
          } else {
            mutate(values);
          }
        })}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <Controller
                name="title"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter template title",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Template Title"
                    error={!!error}
                    requiredField
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={16}>
              Dynamic Values
            </Typography>
            <Divider sx={{ my: 1 }} />
            {data?.data?.data.map((variable: any) => (
              <GKButton
                sx={{
                  margin: "4px",
                  "& .MuiButton-icon": {
                    marginRight: "2px",
                  },
                }}
                variant="outlined"
                color="dark"
                size="extraSmall"
                key={variable.value}
                onClick={() => appendVariable(variable.value)}
                startIcon={<CgMathPlus size={14} />}
              >
                {variable.name}
              </GKButton>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography>Template Description</Typography>
            <Controller
              name="content"
              control={form.control}
              render={({ field }) => (
                <div
                  style={{
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: "8px",
                    marginTop: "4px",
                  }}
                >
                  <CustomEditor
                    ref={quillRef}
                    modules={modules}
                    formats={formats}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6} mt={"26px"}>
            <Grid container bgcolor={"#F8FAFC"} p={2}>
              <Typography>Template Preview</Typography>
              <Grid item xs={12}>
                <Paper>
                  <Controller
                    name="content"
                    control={form.control}
                    render={({ field }) => (
                      <div
                        style={{
                          borderRadius: "8px",
                          marginTop: "16px",
                          height: "calc(100vh - 375px)",
                          overflow: "auto",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: replaceVariables(field.value),
                        }}
                        className="ql-editor"
                      />
                    )}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </GKSideModal>
  );
};

export default CustomAgreementModal;
