import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const HoldingReportModal = (props: Props) => {
  const { open, setOpen } = props;

  const {
    mutate: handleCombinedHoldingsGenerateReport,
    isLoading: combinedHoldingLoading,
  } = useMutation({
    mutationFn: () =>
      axiosInstance.get(`/portfolios/combined_holdings/csv/report/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `combined_holdings-${new Date().getTime()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    },
  });

  return (
    <GKModal
      width={"30%"}
      modalTitle={"All Holding Report"}
      open={open}
      setOpen={setOpen}
      footer={
        <Grid container gap={1} justifyContent={"flex-end"}>
          <LoadingButton
            color="dark"
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            No
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="medium"
            loading={combinedHoldingLoading}
            onClick={() => handleCombinedHoldingsGenerateReport()}
          >
            Yes
          </LoadingButton>
        </Grid>
      }
    >
      <Grid container>
        <Typography fontWeight={500} fontSize={16}>
          This will generate a report consisting of all holdings across all the
          portfolios in this account as of today. Do you want to generate it?
        </Typography>
      </Grid>
    </GKModal>
  );
};

export default HoldingReportModal;
