import { Avatar, Card, Grid, Popover, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { useFetchQuery } from "hooks/useQueries";
import { useUpdateLead } from "hooks/useUpdateLead";
import { useState } from "react";
import { LiaUserTagSolid } from "react-icons/lia";
import { LuCheck } from "react-icons/lu";
import { paletteLightTheme } from "utils/ColorTheme";

interface Props {
  leadData: {
    id: number;
    leadStatusStr: string;
    leadAssignedToStr: string;
    sourceOfLeadStr: string;
    tagsList: any[];
    hasMeeting: boolean;
    hasNotes: boolean;
    meetingCount: number;
    notesCount: number;
    name: string;
    email: string;
    companyName: string;
    countryCode: string;
    phoneNumber: string;
    lastContacted: string;
    panNumber: string;
    position: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    leadValue: string;
    description: string;
    conversionStatus: false;
    createdAt: string;
    updatedAt: string;
    branch: number;
    leadStatus: number;
    leadAssignedTo: number;
    sourceOfLead: number;
    tags: any[];
  };
}

const LeadAssignPopup = ({ leadData }: Props) => {
  const { updateLead } = useUpdateLead();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: getAllLeadSource } = useFetchQuery({
    route: "/employees/all/",
    key: ["GET_LEAD_EMPLOYEE_ASSIGN"],
  });

  const leadAssignName = getAllLeadSource?.data?.find(
    (d: any) => d?.id === leadData?.leadAssignedTo
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <Typography color={"#9AA4B2"} fontSize={12}>
          Assigned
        </Typography>
        <GKButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          sx={{
            padding: "unset",
            cursor: "pointer",
            ":hover": {
              background: "unset",
            },
          }}
        >
          {leadAssignName ? (
            <Card
              sx={{
                p: "2px",
                display: "flex",
                alignItems: "center",
                borderRadius: 100,
                gap: "4px",
                pr: "4px",
              }}
            >
              <Avatar
                sx={{
                  width: 20,
                  height: 20,

                  color: paletteLightTheme.palette.primary.main,
                  background: `${paletteLightTheme.palette.primary.main}11`,
                }}
              >
                <Typography fontSize={10}>
                  {leadAssignName?.name?.charAt(0)}
                  {leadAssignName?.name?.charAt(1)}
                </Typography>
              </Avatar>
              <Typography fontWeight={400} fontSize={10} color={"#697586"}>
                {leadAssignName?.name}{" "}
              </Typography>
            </Card>
          ) : (
            <Card
              sx={{
                p: "2px",
                display: "flex",
                alignItems: "center",
                borderRadius: 100,
                pr: "4px",
                gap: "4px",
              }}
            >
              <LiaUserTagSolid color={"#697586"} />
              <Typography fontWeight={400} fontSize={10} color={"#697586"}>
                Assign
              </Typography>
            </Card>
          )}
        </GKButton>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: { width: "12%", borderRadius: 8 },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container sx={{ py: "6px" }}>
          {getAllLeadSource?.data?.map(
            (
              dataItem: { id: number; phone: string; name: string },
              index: number
            ) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  borderBottom={
                    index + 1 === getAllLeadSource?.data?.length
                      ? ""
                      : `solid 1px ${paletteLightTheme.palette.border.main}`
                  }
                >
                  <GKButton
                    startIcon={
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          color: paletteLightTheme.palette.primary.main,
                          background: `${paletteLightTheme.palette.primary.main}11`,
                          border:
                            dataItem?.id !== leadData?.leadAssignedTo
                              ? ""
                              : `1px solid ${paletteLightTheme.palette.primary.main}`,
                        }}
                      >
                        <Typography fontSize={10}>
                          {dataItem?.name?.charAt(0)}
                          {dataItem?.name?.charAt(1)}
                        </Typography>
                      </Avatar>
                    }
                    endIcon={
                      dataItem?.id !== leadData?.leadAssignedTo ? (
                        <div />
                      ) : (
                        <LuCheck
                          color={paletteLightTheme?.palette.primary.main}
                        />
                      )
                    }
                    color="dark"
                    fullWidth
                    sx={{
                      px: 2,
                      py: 1,
                      borderRadius: 0,
                      justifyContent: "flex-start",
                      background:
                        dataItem?.id === leadData?.leadAssignedTo
                          ? `${paletteLightTheme.palette.primary.main}11`
                          : "",
                      "& .MuiButton-endIcon": {
                        marginLeft: "auto",
                      },
                    }}
                    onClick={() => updateLead({ leadAssignedTo: dataItem.id })}
                  >
                    {dataItem?.name}
                  </GKButton>
                </Grid>
              );
            }
          )}
        </Grid>
      </Popover>
    </>
  );
};

export default LeadAssignPopup;
