import { Grid, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { MdOfflineBolt } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import AnimationData1 from "../assets/Lottie/qBpo9KCx2d.json";

const AuthenticationLayout = () => {
  const { theme } = ThemeContainer();
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: AnimationData1,
  };

  return (
    <Grid
      container
      height={"100vh"}
      style={{ background: theme.palette.light.main }}
    >
      <Grid
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
        item
        xs={6}
        position={"relative"}
        container
        style={{ background: `${theme.palette.primary.main}` }}
      >
        {" "}
        <div className="texture">
          <p className="one" />
          <p className="two" />
        </div>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          position={"relative"}
        >
          <img src="\Logos\GridKey_LOGO_White.png" alt="" width={200} />
          <Typography
            fontWeight={500}
            fontSize={18}
            color={theme.palette.dark?.main}
            mb={2}
          >
            Wealth management just got smarter
          </Typography>
          <div style={{ position: "relative", width: "50%" }}>
            <MdOfflineBolt
              color={theme.palette.light?.main}
              size={40}
              style={{ position: "absolute", top: -16, left: -16 }}
            />
            <Lottie
              {...defaultOptions1}
              style={{
                border: `${theme.palette.light?.main}44 solid 1px`,
                borderRadius: "10px",
                boxShadow: `inset 0 0  10px 0 ${theme.palette.light?.main}82`,
                height: "auto",
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          boxShadow: {
            xs: `0 0 18px ${theme.palette.dark?.main}57`,
            md: "none",
          },
          border: {
            xs: `solid 1px ${theme.palette.dark?.main}57`,
            md: "unset",
          },
          borderRadius: {
            xs: "10px",
            md: "unset",
          },
          margin: {
            xs: 4,
            md: 0,
          },
          padding: {
            xs: 4,
            md: 0,
          },
        }}
        alignSelf={"center"}
      >
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
