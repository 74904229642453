import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKSideModal from "components/GKSideModal";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { DAYS, MONTH, SCHEDULER_TYPE } from "../utils";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  reportId: number;
}

const SchedulerModal = (props: Props) => {
  const { open, setOpen, reportId } = props;

  const {
    state: { portfolios },
  } = useAppContext();

  const formTab = ["PDF", "EXCEL"];

  const { data: reportList, isFetching } = useFetchQuery({
    key: ["REPORT_LIST"],
    route: "/report/",
  });

  const { data: reportById } = useFetchQuery({
    key: ["REPORT_BY_ID"],
    route: `/report_scheduler/${reportId}/`,
    enabled: !!reportId,
  });

  const form = useForm({
    defaultValues: {
      portfolios: [],
      reportName: "",
      scheduleType: "",
      date: "",
      format: "pdf",
      benchmark: "",
      customData: {},
      timePeriod: "",
    },
    values: {
      portfolios: reportById?.data?.data?.portfolios,
      reportName: reportById?.data?.data?.reportName || "",
      scheduleType: reportById?.data?.data?.scheduleType || "",
      date: reportById?.data?.data?.date || "",
      format: reportById?.data?.data?.format || "pdf",
      benchmark: reportById?.data?.data?.benchmark || "",
      customData: reportById?.data?.data?.customData || {},
      timePeriod: reportById?.data?.data?.timePeriod || "",
    },
  });

  form.watch();

  const notAccessThisSectionReport =
    form.getValues("reportName") !== "custom_report" &&
    form.getValues("reportName") !== "portfolio_performance_report" &&
    form.getValues("reportName") !== "portfolio_summary_detailed_report" &&
    form.getValues("reportName") !== "xirr_report" &&
    form.getValues("reportName") !== "unrealized_capital_gain_summary_report" &&
    form.getValues("reportName") !==
      "unrealized_capital_gain_detailed_report" &&
    form.getValues("reportName") !== "historical_valuation_report" &&
    form.getValues("reportName") !== "stock_wise_holdings_report";

  const { mutate, isLoading } = useMutation({
    mutationKey: [
      reportId ? "UPDATE_SCHEDULE_REPORT" : "CREATE_SCHEDULE_REPORT",
    ],
    mutationFn: (data) =>
      reportId
        ? axiosInstance.patch(`/report_scheduler/${reportId}/`, data)
        : axiosInstance.post("/report_scheduler/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("REPORT_SCHEDULER_LIST");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <GKSideModal
      width="50%"
      open={open}
      setOpen={setOpen}
      modalTitle="Schedule Report"
      footerButton={
        <Grid container justifyContent={"flex-end"}>
          <LoadingButton
            form="report-scheduler-form"
            type="submit"
            variant="contained"
            loading={isLoading}
            size="medium"
          >
            Save
          </LoadingButton>
        </Grid>
      }
    >
      <form
        id="report-scheduler-form"
        onSubmit={form.handleSubmit((values: any) => mutate(values))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="reportName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select Report",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  loading={isFetching}
                  disabled={!!reportById}
                  disableCloseOnSelect={false}
                  inputLabel="Select Report"
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography textTransform={"capitalize"}>
                        {option?.name}
                      </Typography>
                    </li>
                  )}
                  options={
                    reportList?.data?.data
                      ?.filter(
                        (report: any) =>
                          report.name !== "Custom Report" &&
                          report.reportName !== "stock_wise_holdings_report"
                      )
                      .map((dataItem: any) => {
                        return {
                          ...dataItem,
                          name: dataItem?.name,
                          value: dataItem.reportName,
                        };
                      }) || []
                  }
                  value={
                    reportList?.data?.data
                      .map((dataItem: any) => {
                        return {
                          ...dataItem,
                          name: dataItem?.name,
                          value: dataItem.reportName,
                        };
                      })
                      .find((report: any) => report.value === field.value)?.name
                  }
                  onChange={(e, val) => {
                    field.onChange(val.value);
                    form.setValue("customData", val.customData);
                    form.setValue("timePeriod", "");
                    form.setValue("format", "pdf");
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Report"
                      error={Boolean(errors?.reportName)}
                      helperText={errors?.reportName?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="portfolios"
              control={form.control}
              render={({ field }) => (
                <GKClientDataGrid
                  density="compact"
                  name="schedulePortfolio"
                  columnsButton={false}
                  columns={[
                    {
                      ...extraColumnsField("Client Name", "clientName", 200, 1),
                      renderCell: (params) => {
                        return (
                          <Typography fontWeight={600}>
                            {params?.row?.clientName}
                          </Typography>
                        );
                      },
                    },
                    {
                      ...extraColumnsField(
                        "Portfolio",
                        "portfolioName",
                        200,
                        1
                      ),
                      renderCell: (params) => {
                        return (
                          <Typography fontWeight={600}>
                            {params?.row?.portfolioName}
                          </Typography>
                        );
                      },
                    },
                    {
                      ...extraColumnsField(
                        "Portfolio Code",
                        "portfolioId",
                        150,
                        1
                      ),
                      renderCell: (params) => {
                        return (
                          <Typography fontWeight={600}>
                            {params?.row?.portfolioId}
                          </Typography>
                        );
                      },
                    },
                  ]}
                  rows={portfolios || []}
                  pagination={false}
                  checkboxSelection
                  rowSelectionModel={field.value}
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(params) => field.onChange(params)}
                  maxHeight={"calc(100vh - 483px)"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {notAccessThisSectionReport && (
              <Controller
                name="timePeriod"
                control={form.control}
                render={({ field }) => (
                  <Grid container item xs={12} display={"flex"} spacing={1}>
                    <Grid item xs={12}>
                      <Typography>Available Time Periods</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "since_inception" ||
                          field.value === "Since Inception"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          form.setValue("timePeriod", "since_inception")
                        }
                      >
                        Since Inception
                      </GKButton>
                    </Grid>
                    <Grid item xs={4}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "this_fy" || field.value === "This FY"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => form.setValue("timePeriod", "this_fy")}
                      >
                        This FY
                      </GKButton>
                    </Grid>
                    <Grid item xs={4}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "previous_fy" ||
                          field.value === "Previous FY"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          form.setValue("timePeriod", "previous_fy")
                        }
                      >
                        Previous FY
                      </GKButton>
                    </Grid>
                  </Grid>
                )}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="scheduleType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select schedule type",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disabled={!!reportById}
                  loading={isFetching}
                  disableCloseOnSelect={false}
                  inputLabel="Select Schedule Type"
                  options={SCHEDULER_TYPE}
                  onChange={(e, val) => {
                    field.onChange(val.value);
                    form.setValue("date", "");
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Schedule Type"
                      error={Boolean(errors?.scheduleType)}
                      helperText={errors?.scheduleType?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {(form.getValues("scheduleType") === "Quarterly" ||
            form.getValues("scheduleType") === "Yearly") && (
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="date"
                  control={form.control}
                  rules={{
                    required: {
                      value: !!(
                        form.getValues("scheduleType") === "Quarterly" ||
                        form.getValues("scheduleType") === "Yearly"
                      ),
                      message: "Please select date",
                    },
                  }}
                  render={({ field, formState: { errors } }) => (
                    <GKDatePicker
                      {...field}
                      requiredField
                      disabled={!!reportById}
                      inputLabel="Date"
                      value={dayjs(field.value)}
                      onChange={(value) =>
                        field.onChange(dayjs(value).format("YYYY-MM-DD"))
                      }
                      disableFuture
                      slotProps={{
                        textField: {
                          error: !!errors?.date,
                          helperText: errors?.date?.message as string,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {form.getValues("scheduleType") === "Weekly" && (
            <Grid item xs={6}>
              <Controller
                name="date"
                control={form.control}
                rules={{
                  required: {
                    value: !!(form.getValues("scheduleType") === "Weekly"),
                    message: "Please select day",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disabled={!!reportById}
                    disableClearable
                    inputLabel="Select Day"
                    onChange={(event, val) => field.onChange(val.value)}
                    value={
                      DAYS.find((data: any) => data?.value === field.value)
                        ?.name || []
                    }
                    options={DAYS || []}
                    getOptionLabel={(option) =>
                      Array.isArray(option) ? "" : option.name || option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Day"
                        error={!!errors?.date}
                        helperText={errors?.date?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
          {form.getValues("scheduleType") === "Monthly" && (
            <Grid item xs={6}>
              <Controller
                name="date"
                control={form.control}
                rules={{
                  required: {
                    value: !!(form.getValues("scheduleType") === "Monthly"),
                    message: "Please select day",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disabled={!!reportById}
                    disableClearable
                    inputLabel="Select Day"
                    onChange={(event, val) => field.onChange(val.value)}
                    value={
                      MONTH.find((data: any) => data?.value === +field.value)
                        ?.name || []
                    }
                    options={MONTH || []}
                    getOptionLabel={(option) =>
                      Array.isArray(option) ? "" : option.name || option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Day"
                        error={!!errors?.date}
                        helperText={errors?.date?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography fontSize={14}>Format</Typography>
            <Controller
              name="format"
              control={form.control}
              render={({ field }) => (
                <div style={{ display: "flex" }}>
                  <Tabs
                    className="light"
                    variant="standard"
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    value={formTab.indexOf(field.value.toUpperCase())}
                    onChange={(e, val) => {
                      field.onChange(formTab[val].toLowerCase());
                    }}
                  >
                    {formTab
                      // ?.filter((dataItem) =>
                      //   form.getValues("reportName") === "custom_report"
                      //     ? dataItem !== "EXCEL"
                      //     : dataItem
                      // )
                      ?.map((dataItem, index) => (
                        <Tab
                          key={index}
                          label={dataItem}
                          disableRipple
                          disabled={
                            !!reportById ||
                            (dataItem === "EXCEL" &&
                              form.getValues("reportName") ===
                                "custom_report") ||
                            form.getValues("reportName") ===
                              "portfolio_performance_report"
                          }
                        />
                      ))}
                  </Tabs>
                </div>
              )}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Controller
              name="benchmark"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Benchmark"
                  loading={benchmarkLoading}
                  onChange={(event, val) => field.onChange(val.value)}
                  value={
                    benchmarkList.find(
                      (data: any) => data?.value === field.value
                    )?.name || []
                  }
                  options={benchmarkList || []}
                  getOptionLabel={(option) =>
                    Array.isArray(option) ? "" : option.name || option
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select benchmark"
                      error={!!errors?.benchmark}
                      helperText={errors?.benchmark?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid> */}
        </Grid>
      </form>
    </GKSideModal>
  );
};

export default SchedulerModal;
