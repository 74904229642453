import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKTextField from "components/GKTextField";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const ApplyRightIssue = () => {
  useDocumentTitle("Apply Right Issue");

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.APPLY_RIGHT_ISSUE],
    route: `/convert-rightissue/${id}/portfolio/`,
  });

  const { mutate: updateRightIssue, isLoading } = useMutation({
    mutationKey: ["UPDATE_RIGHT_ISSUE"],
    mutationFn: (data: any) => axiosInstance.post("/convert-rightissue/", data),
    onSuccess: (response) => {
      navigate(-1);
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      applyRightIssue: data?.data?.data || [],
    },
    values: {
      applyRightIssue: data?.data?.data || [],
    },
  });

  form.watch();

  const onSubmit = (values: any) => {
    const data = {
      ca_id: state?.id,
      mainGcode: state?.gridCode,
      portfolios: values?.applyRightIssue.filter(
        (data: any) => +data.quantity > 0
      ),
      convertedGcode: state?.convertGridCode,
    };

    updateRightIssue(data);
  };

  return (
    <DashboardLayout title={"Apply Right Issue"}>
      <form id="apply-right-issue-form" onSubmit={form.handleSubmit(onSubmit)}>
        <GKClientDataGrid
          name="rightIssue"
          loading={isFetching}
          columns={[
            {
              ...extraColumnsField("Portfolio Name", "portfolioName", 150, 1),
              valueGetter: (row) => row?.row?.portfolioName || "-",
            },
            {
              ...extraColumnsField(
                "Available Quantity",
                "convertedQuantity",
                150,
                1
              ),
              type: "number",
              valueGetter: (row) => row?.row?.convertedQuantity || 0,
            },
            {
              ...extraColumnsField("Quantity To Convert", "quantity", 150, 1),
              type: "number",
              renderCell: (params) => {
                return (
                  <Controller
                    name={`applyRightIssue.${params.row.index}.quantity`}
                    control={form.control}
                    rules={{
                      validate: (value) =>
                        value <=
                          form.getValues(
                            `applyRightIssue.${params.row.index}.convertedQuantity`
                          ) || "Please enter a valid quantity",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "8px !important",
                          },
                        }}
                        style={{ width: 150 }}
                        placeholder="Quantity"
                        type="number"
                        {...field}
                        onChange={(e) => {
                          field.onChange(Number(e.target.value)); // Convert value to a number
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                );
              },
            },
          ]}
          rows={
            data?.data?.data.map((dataItem: any, index: number) => {
              return {
                ...dataItem,
                id: dataItem?.portfolioId,
                index: index,
              };
            }) || []
          }
          maxHeight={"calc(100vh - 70px)"}
          headerComponent={
            <LoadingButton
              disabled={
                form
                  .getValues("applyRightIssue")
                  ?.filter(
                    (dataItem: any) =>
                      dataItem?.convertedQuantity < dataItem?.quantity
                  )?.length !== 0
              }
              variant="contained"
              size="medium"
              form="apply-right-issue-form"
              type="submit"
              loading={isLoading}
            >
              Save{" "}
            </LoadingButton>
          }
        />
      </form>
    </DashboardLayout>
  );
};

export default ApplyRightIssue;
