/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Card,
  Divider,
  Grid,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import GKButton from "components/GKButton";
import { RiSettingsLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  id?: any;
  key?: any;
  modelPortfolio?: any;
  isFetching?: any;
}

const CustomParentCard = styled(Card)(({ theme }) => {
  return {
    border: `solid 1px ${theme.palette.border.main}`,
    borderRadius: "10px",
    padding: "16px",
    boxShadow: "none",
  };
});

const CustomChildCard = styled(Card)(({ theme }) => {
  return {
    margin: "0",
    border: `solid 1px ${theme.palette.border.main}`,
    borderRadius: "16px",
    padding: "4px",
    boxShadow: "none",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  };
});

const PortfolioCard = ({
  id: key,
  key: index,
  modelPortfolio,
  isFetching,
}: Props) => {
  const navigate = useNavigate();
  const { theme } = ThemeContainer();
  const m = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today =
    modelPortfolio?.lastChange && new Date(modelPortfolio?.lastChange[1]);

  const lastChanges =
    modelPortfolio?.lastChange &&
    `${today.getDate()} ${m[today.getMonth()]} ${today.getFullYear()}`;

  const LastChange =
    modelPortfolio?.lastChange &&
    `${modelPortfolio?.lastChange[0]} / ${lastChanges}`;

  const CardData = [
    {
      title: "Current Value",
      value: isFetching ? (
        <Skeleton animation="wave" />
      ) : (
        `${numberToCurrency(modelPortfolio?.portfoliosValue?.currentValue)}`
      ),
    },
    {
      title: "Invested Value",
      value: isFetching ? (
        <Skeleton animation="wave" />
      ) : (
        `${numberToCurrency(modelPortfolio?.portfoliosValue?.totalInvestment)}`
      ),
    },
    {
      title: "Ledger Balance",
      value: isFetching ? (
        <Skeleton animation="wave" />
      ) : (
        `${numberToCurrency(modelPortfolio?.portfoliosValue?.availableCash)}`
      ),
    },
    {
      title: "Mapped Portfolios",
      value: isFetching ? (
        <Skeleton animation="wave" />
      ) : (
        modelPortfolio?.totalMappedPortfolios
      ),
    },
    {
      title: "No. Of Stocks",
      value: isFetching ? (
        <Skeleton animation="wave" />
      ) : (
        modelPortfolio?.stocksTotal
      ),
    },
    {
      title: "Last Change in Model",
      value: isFetching ? <Skeleton animation="wave" /> : LastChange,
    },
  ];

  return (
    <CustomParentCard key={key}>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          gap="18px"
          alignItems="center"
        >
          <Typography
            fontSize={20}
            fontWeight={500}
            color={theme.palette.primary.main}
          >
            {modelPortfolio?.name}
          </Typography>
          <span
            style={{
              display: "flex",
              gap: "18px",
            }}
          >
            <GKButton
              variant="lightBg"
              size="medium"
              onClick={() => {
                navigate(`/app/equity-basket/${modelPortfolio?.id}/portfolio`);
              }}
            >
              Portfolio
            </GKButton>
            <GKButton
              variant="lightBg"
              size="medium"
              onClick={() => {
                navigate(`/app/equity-basket/form/${modelPortfolio?.id}/`);
              }}
            >
              View/Edit Equity Basket
            </GKButton>
          </span>
        </Grid>
        <Grid item xs={12} my="10px">
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          {CardData?.map((data, index) => (
            <Grid item xs={4} key={index}>
              <CustomChildCard>
                <RiSettingsLine
                  style={{
                    height: 42,
                    width: 42,
                    background: theme.palette.light?.main,
                    padding: 6,
                    borderRadius: "50px",
                    backgroundColor: `${theme.palette.light?.main}1A`,
                    color: theme.palette.primary?.main,
                  }}
                />
                <span>
                  <Typography
                    fontSize={12}
                    fontWeight={700}
                    color={theme.palette.dark.main}
                  >
                    {data.title}
                  </Typography>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={theme.palette.primary?.main}
                    >
                      {data.value}
                    </Typography>
                  </span>
                </span>
              </CustomChildCard>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} my="10px">
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-start"
          gap="18px"
          alignItems="center"
        >
          {/* <Button
            onClick={() => navigate('/modal-portfolio-timeline')}
            size='small'
            variant='contained'
            className='light'
          >
            Timeline
          </Button> */}
          {/* <Button
            onClick={() => {
              navigate('/app/equity-basket/performance')
            }}
            size='small'
            variant='contained'
            className='light'
          >
            Performance
          </Button> */}
          <GKButton
            onClick={() => {
              navigate(
                `/app/equity-basket/${modelPortfolio?.id}/new-allocation`,
                {
                  state: {
                    name: modelPortfolio?.name || "-",
                  },
                }
              );
            }}
            size="medium"
            variant="lightBg"
          >
            Allocation
          </GKButton>
          <GKButton
            onClick={() => {
              navigate(
                `/app/equity-basket/${modelPortfolio?.id}/order-manager`
              );
            }}
            variant="lightBg"
            size="medium"
          >
            Order Manager
          </GKButton>
          {/* <Button variant='contained' size='small' className='light'>
            Insights
          </Button> */}
        </Grid>
      </Grid>
    </CustomParentCard>
  );
};

export default PortfolioCard;
