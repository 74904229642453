import { LoadingButton } from "@mui/lab";
import { Divider, Grid, Typography } from "@mui/material";
import GKReactQuill from "components/GKReactQuill";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

interface EmailConfigFormValues {
  introduction: string;
  footer: string;
}

interface Props {
  data: {
    company: number;
    dailyEmail: string;
    email: string;
    footer: string;
    host: string;
    id: string;
    introduction: string;
    isConnection: false;
    isDefault: boolean;
    password: string;
    senderName: string;
    useTls: string;
  };
  handleUpdate: any;
  isLoading: boolean;
}

const EmailHeaderSection = (props: Props) => {
  const { data, handleUpdate, isLoading } = props;

  const form = useForm<EmailConfigFormValues>({
    defaultValues: {
      introduction: data?.introduction || "",
      footer: data?.footer || "",
    },
    values: {
      introduction: data?.introduction || "",
      footer: data?.footer || "",
    },
  });

  const onSubmit: SubmitHandler<EmailConfigFormValues> = (values) => {
    handleUpdate(values);
  };

  return (
    <form id="email-header-config-form" onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography fontSize={16} fontWeight={500} color={"#697586"}>
            General Instruction for your clients
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Controller
              name="introduction"
              control={form.control}
              render={({ field }) => (
                <GKReactQuill
                  {...field}
                  inputLabel="The content you enter here will be a part of all order emails sent to the client."
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Typography fontSize={16} fontWeight={500} color={"#697586"}>
            Email Footer
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Controller
              name="footer"
              control={form.control}
              render={({ field }) => (
                <GKReactQuill
                  value={field.value}
                  onChange={field.onChange}
                  inputLabel="The content you enter here will be present at the end of all emails sent to the clients."
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mt={1}>
          <LoadingButton
            type="submit"
            variant="contained"
            form="email-header-config-form"
            loading={isLoading}
            onClick={(e) => e.stopPropagation()}
          >
            Save
          </LoadingButton>
        </Grid>

        <Grid item xs={12} my={1}>
          <Divider />
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailHeaderSection;
