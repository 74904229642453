import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate, useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { holdingsMainPageColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const HoldingTab = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.familyHolding?.take,
    skip: commonFilter?.familyHolding?.skip,
    sort: JSON.stringify(
      commonFilter?.familyHolding?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.familyHolding?.search || "",
    show_zero_holding: commonFilter?.familyHolding?.showNonZero,
    filter: JSON.stringify(commonFilter?.familyHolding?.customFilter),
  };

  const { data: familyState, isLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_HOLDINGS, requestBody],
    route: `/family/${id}/holdings/`,
    requestBody,
  });

  const totalCurrentAmount = () =>
    !isLoading &&
    familyState?.data?.data?.reduce(
      (acc: number, obj: any) => acc + obj.currentAmount,
      0
    );

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/family/${id}/holdings`}
        name={"FamilyHoldingsList"}
        filter={JSON.stringify(commonFilter?.familyHolding?.customFilter)}
      />
    );
  };

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={familyState?.data?.totalCount || 0}
        name="familyHolding"
      />
    );
  };

  return (
    <GKDataGrid
      searchPlaceholder="Search for Assets"
      loading={isLoading}
      columns={holdingsMainPageColumns(totalCurrentAmount())}
      rows={familyState?.data?.data || []}
      getRowId={(row) => row?.isin}
      zeroHolding
      onRowClick={(row: any) => {
        navigate(`/app/holdings/${id}/transactions`, {
          state: {
            holdingId: id,
            name: row?.row?.name,
            gcode: row?.row?.gcode,
            currentAmount: row?.row?.currentAmount,
            investedAmount: row?.row?.investedAmount,
            quantity: row?.row?.quantity,
            type: "family",
          },
        });
      }}
      exportCsv={exportCsv}
      CustomPagination={!isLoading && cusPagination}
      name="familyHolding"
      filterButton={false}
      tableName="family_holding_list"
      headerFilter={<TableFiltering name="familyHolding" />}
    />
  );
};

export default HoldingTab;
