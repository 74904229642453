import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useEditClient } from "hooks/useEditClient";
import { Controller, FormProvider } from "react-hook-form";

import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import dayjs from "dayjs";

import { MdExpandMore } from "react-icons/md";
import { MARITAL_STATUS, PERSONAL_GENDER } from "utils/constants";
import { panRegex } from "utils/validationSchema";
import Bank from "./Bank";
import DematPage from "./Demat";
import NomineePage from "./Nominees";

const EditClientDetails = () => {
  const {
    form,
    isLoading,
    onSubmit,
    familyList,
    familyStateLoading,
    basketList,
    basketListFetching,
  } = useEditClient();

  const basketOptionList = () => {
    return basketList?.filter(
      (data: any) =>
        !form
          .getValues("basket")
          ?.map((dataItem: any) => dataItem)
          .includes(data.value)
    );
  };

  return (
    <FormProvider {...form}>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Details
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`personalDetails.name`}
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Name" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="phone"
                    control={form.control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        onSelectChange={(event: any, val: any) => {
                          form.setValue("countryCode", val?.callingCodes);
                        }}
                        onTextChange={(event: any) => {
                          field.onChange(event.target.value);
                        }}
                        onTextValue={field.value}
                        onSelectValue={form.getValues("countryCode")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.email"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Email" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name={`personalDetails.pan`}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter PAN",
                      },
                      pattern: {
                        value: panRegex,
                        message: "PAN should have this format ABCDE1234A",
                      },
                      maxLength: {
                        value: 10,
                        message: "PAN should have 10 character",
                      },
                      minLength: {
                        value: 10,
                        message: "PAN should have 10 character",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        inputLabel="PAN"
                        requiredField
                        error={!!error}
                        helperText={!!error && error.message}
                        onChange={(e) =>
                          field.onChange(e.target.value.toUpperCase())
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="family"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable
                      inputLabel="Family"
                      multiple
                      loading={familyStateLoading}
                      options={familyList || []}
                      onChange={(event, val) => {
                        const selectedIds = val.map(
                          (option: any) => option.value
                        );
                        field.onChange(selectedIds);
                      }}
                      value={
                        familyList?.filter((option: any) =>
                          field.value.includes(option.value)
                        ) || []
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField {...params} name="portfolio" />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="personalDetails.gender"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <GKSearchSelect
                        {...field}
                        disableClearable={false}
                        inputLabel="Gender"
                        options={PERSONAL_GENDER || []}
                        onChange={(event, val) => {
                          field.onChange(val.value);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={"Select Gender"}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="personalDetails.maritalStatus"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      {...field}
                      disableClearable={false}
                      inputLabel="Marital Status"
                      options={MARITAL_STATUS || []}
                      onChange={(event, val) => {
                        field.onChange(val.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Select Marital Status"}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.fatherName"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Father Name" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.motherName"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Mother Name" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.dateOfBirth"
                    control={form.control}
                    render={({ field }) => (
                      <GKDatePicker
                        {...field}
                        inputLabel="Date Of Birth"
                        value={dayjs(field.value)}
                        onChange={(value) => {
                          field.onChange(dayjs(value).format("YYYY-MM-DD"));
                        }}
                        slotProps={{
                          textField: {
                            error: false,
                            helperText: false,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.income"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        type="number"
                        inputLabel="Income"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.occupation"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Occupation" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.state"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="State" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.pincode"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField {...field} inputLabel="Pincode" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.address"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        minRows={1}
                        inputLabel="Address"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.emergencyContact"
                    control={form.control}
                    render={({ field }) => (
                      <GKTextField
                        {...field}
                        inputLabel="ُEmergency Contact Name"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="personalDetails.emergencyContactNum"
                    control={form.control}
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Minimum length is 10 characters",
                      },
                      maxLength: {
                        value: 10,
                        message: "Maximum length is 10 characters",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <GKTextField
                        {...field}
                        type="number"
                        inputLabel="ُEmergency Contact Number"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {form.getValues("type") === "PMS" && (
                <Grid item xs={6}>
                  <Controller
                    name="basket"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKSearchSelect
                        {...field}
                        multiple
                        disableClearable={false}
                        disableCloseOnSelect
                        limitTags={5}
                        inputLabel="Basket"
                        requiredField
                        loading={basketListFetching}
                        options={basketOptionList()}
                        value={
                          basketList?.filter((option: any) =>
                            field.value?.includes(option.value)
                          ) || []
                        }
                        onChange={(e, val) => {
                          const selectedIds = val.map(
                            (option: any) => option.value
                          );
                          field.onChange(selectedIds);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="basket"
                            placeholder="Select Basket"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="medium"
                  loading={isLoading}
                >
                  Update Client
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Bank Details
        </AccordionSummary>
        <AccordionDetails>
          <Bank />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Demat Details
        </AccordionSummary>
        <AccordionDetails>
          <DematPage />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          Nominee Details
        </AccordionSummary>
        <AccordionDetails>
          <NomineePage />
        </AccordionDetails>
      </Accordion>
    </FormProvider>
  );
};

export default EditClientDetails;
