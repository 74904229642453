import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

const WalletHistory = () => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const requestBody = {
    take: commonFilter?.walletHistory?.take,
    skip: commonFilter?.walletHistory?.skip,
    sort: JSON.stringify(
      commonFilter?.walletHistory?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.walletHistory?.search || "",
  };

  const { data: walletHistoryList, isFetching: walletHistoryListFetching } =
    useFetchQuery({
      key: ["GET_WALLET_HISTORY", requestBody],
      route: "/wallet-history/",
      requestBody,
    });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={walletHistoryList?.data?.totalCount || 0}
        name="walletHistory"
      />
    );
  };

  const exportCsv = () => {
    return <ExportCsv path={"/wallet-history"} name={"WalletHistory"} />;
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 100, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Particulars", "particulars", 250, 1),
      valueGetter: (params) => params?.row?.chargesType?.type || "-",
      renderCell: (params) => {
        return (
          <Typography>{`${params?.row?.chargesType?.type} ${
            params?.row?.chargesType?.subType
              ? ` - ${params?.row?.chargesType?.subType}`
              : ""
          } `}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Client", "client", 200, 1),
      valueGetter: (params) => params?.row?.client?.name || "-",
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.client?.id
              ? `${params?.row?.client?.name} - ${params?.row?.client?.clientCode}`
              : "-"}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Amount", "amount", 100, 1),
      type: "number",
      valueGetter: (params) => params.row.amount,
      valueFormatter: (params) => `₹${moneyCommaSeparator(+params.value)}`,
    },
    {
      ...extraColumnsField("Balance", "bal", 100, 1),
      type: "number",
      valueGetter: (params) => params.row.bal,
      valueFormatter: (params) => `₹${moneyCommaSeparator(+params.value)}`,
    },
  ];

  return (
    <GKDataGrid
      density="compact"
      loading={walletHistoryListFetching}
      columns={columns}
      rows={walletHistoryList?.data?.data || []}
      maxHeight={`calc(100vh - 338px)`}
      filterButton={false}
      CustomPagination={!walletHistoryListFetching && cusPagination}
      exportCsv={exportCsv}
      name="walletHistory"
    />
  );
};

export default WalletHistory;
