import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField } from "@mui/material";
import { useAppContext } from "AppContext";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import AddBranchModal from "Pages/Organisations/AddBranchModal";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import useAddClient from "hooks/useAddClient";
import { Controller } from "react-hook-form";
import { CgMathPlus } from "react-icons/cg";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

const ClientModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const {
    isSubmitting,
    setIsBranchCreateModalOpen,
    setIsFamilyCreateModalOpen,
    // branchList,
    familyList,
    isBranchCreateModalOpen,
    isFamilyCreateModalOpen,
    loading,
    form,
    onSubmit,
    basketList,
  } = useAddClient({ setIsModalOpen });

  const {
    state: { userData },
  } = useAppContext();

  const watchClientType = form.watch("type");

  const basketOptionList = () => {
    return basketList?.data?.data
      ?.map((dataItem: { name: string; id: number }) => {
        return {
          name: dataItem.name,
          value: dataItem.id,
        };
      })
      .filter(
        (data: any) =>
          !form
            .getValues("basket")
            ?.map((dataItem: any) => dataItem)
            .includes(data.value)
      );
  };

  return (
    <GKModal
      open={isModalOpen}
      setOpen={setIsModalOpen}
      modalTitle="Add Client"
      footer={
        <Grid container justifyContent={"flex-end"} gap={1}>
          <LoadingButton
            loading={isSubmitting}
            size="medium"
            type="submit"
            variant="contained"
            form="client-form"
          >
            Add Client
          </LoadingButton>
        </Grid>
      }
      titleComponent={
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          {/* <Button
            size="medium"
            variant="outlined"
            onClick={() => setIsBranchCreateModalOpen(true)}
          >
            Add Branch
          </Button> */}
          <GKButton
            size="medium"
            variant="outlined"
            onClick={() => setIsFamilyCreateModalOpen(true)}
            startIcon={<CgMathPlus size={14} />}
          >
            Add Family
          </GKButton>
        </Grid>
      }
    >
      <>
        <form onSubmit={form.handleSubmit(onSubmit)} id="client-form">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="personalDetails.name"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Name"
                      requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="phone"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <PhoneInput
                      {...field}
                      requiredField
                      onSelectChange={(event: any, val: any) => {
                        form.setValue("countryCode", val?.callingCodes);
                      }}
                      onTextChange={(event: any) => {
                        field.onChange(event.target.value);
                      }}
                      onTextValue={field.value}
                      onSelectValue={form.getValues("countryCode")}
                      error={!!error}
                      helperText={!!error && error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="personalDetails.email"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Email"
                      placeholder="Email"
                      requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="personalDetails.pan"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="PAN"
                      placeholder="PAN"
                      requiredField
                      onChange={(event: any) =>
                        field.onChange(event.target.value.toUpperCase())
                      }
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={4}>
              <Controller
                name="branch"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please select branch",
                  },
                }}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable
                    inputLabel="Branch"
                    requiredField
                    options={branchList || []}
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="Branch"
                        placeholder="Select branch"
                        error={Boolean(form?.formState?.errors?.branch)}
                        helperText={form?.formState?.errors?.branch?.message}
                      />
                    )}
                    disabled={loading}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={4}>
              <Controller
                name="family"
                control={form.control}
                render={({ field }) => (
                  <GKSearchSelect
                    disableClearable
                    inputLabel="Family"
                    options={familyList || []}
                    onChange={(e, val) => {
                      field.onChange(val.value);
                    }}
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="family"
                        placeholder="Select family"
                      />
                    )}
                    disabled={loading}
                  />
                )}
              />
            </Grid>
            {userData?.broker?.pmsSupport && (
              <Grid item xs={4}>
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <GKSearchSelect
                      disableClearable
                      inputLabel="Client Type"
                      requiredField
                      options={[
                        { name: "PMS", value: "PMS" },
                        { name: "Advisory", value: "ADVISORY" },
                      ]}
                      onChange={(e, val) => {
                        field.onChange(val.value);
                      }}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="type"
                          placeholder="Select Client Type"
                        />
                      )}
                      disabled={loading}
                    />
                  )}
                />
              </Grid>
            )}
            {watchClientType === "PMS" && (
              <>
                <Grid item xs={4}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="code"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <GKTextField
                          {...field}
                          inputLabel="Client Code"
                          requiredField
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="basket"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKSearchSelect
                        {...field}
                        loading={loading}
                        multiple
                        disableClearable={false}
                        disableCloseOnSelect
                        limitTags={5}
                        inputLabel="Basket"
                        requiredField
                        options={basketOptionList()}
                        value={
                          basketList?.data?.data
                            .map((dataItem: { name: string; id: number }) => {
                              return {
                                name: dataItem.name,
                                value: dataItem.id,
                              };
                            })
                            ?.filter((option: any) =>
                              field.value?.includes(option.value)
                            ) || []
                        }
                        onChange={(e, val) => {
                          const selectedIds = val.map(
                            (option: any) => option.value
                          );
                          field.onChange(selectedIds);
                        }}
                        getOptionLabel={(option) => option.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="basket"
                            placeholder="Select Basket"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        disabled={loading}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
        {isBranchCreateModalOpen && (
          <AddBranchModal
            open={isBranchCreateModalOpen}
            updateData={null}
            isEditModal={false}
            setOpen={setIsBranchCreateModalOpen}
          />
        )}
        {isFamilyCreateModalOpen && (
          <FamilyCreateModal
            isModalOpen={isFamilyCreateModalOpen}
            setIsModalOpen={setIsFamilyCreateModalOpen}
          />
        )}
      </>
    </GKModal>
  );
};

export default ClientModal;
