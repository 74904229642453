import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { MANDATE_MANAGEMENT_CATEGORY } from "Pages/PaymentGateway/helper";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import { object, string } from "yup";
import VerifyModal from "./VerifyModal";

type FormValues = {
  id?: string;
  clientId?: string;
  clientSecret?: string;
  corporateConfigId?: string;
  upiCorporateConfigId?: string;
  corporateAccountNumber?: string;
  managementCategory?: string;
  company?: number;
  token?: string;
};

interface MandateInterface {
  data: {
    data: FormValues[];
  };
}

const validationSchema = object({
  clientId: string()
    .required("Please enter client ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  clientSecret: string()
    .required("Please enter client secret")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  corporateConfigId: string()
    .required("Please enter corporate config ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  upiCorporateConfigId: string()
    .required("Please enter UPI corporate config ID")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  corporateAccountNumber: string()
    .required("Please enter corporate account number")
    .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
      return value && value.trim().length > 0;
    }),
  managementCategory: string().required("Please select management category"),
});

const Mandate = () => {
  useDocumentTitle("Mandate");

  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const { isLoading } = useFetchQuery({
    key: ["MANDATE_LIST"],
    route: "/digio/company-configuration/",
    success: (response: MandateInterface) =>
      form.reset(response?.data?.data[0]),
  });

  const { mutate: handleMandate } = useMutation({
    mutationFn: (data: {
      values: FormValues;
      request: string;
      route: string;
    }) =>
      axiosInstance[data?.request === "post" ? "post" : "patch"](
        data.route,
        data.values
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("MANDATE_LIST");
      setOpenVerifyModal(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: sendOtp, isLoading: otpLoading } = useMutation({
    mutationKey: ["MANDATE_SEND_OTP"],
    mutationFn: () => axiosInstance.post("/company-otp/send/"),
    onSuccess: (response) => {
      setOpenVerifyModal(true);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(() => sendOtp())}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name="clientId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Client ID"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="clientSecret"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Client Secret"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="corporateConfigId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Corporate Config ID"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="upiCorporateConfigId"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="UPI Corporate Config ID"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="corporateAccountNumber"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Corporate Account Number"
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="managementCategory"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disableClearable={false}
                  inputLabel="Management Category"
                  options={MANDATE_MANAGEMENT_CATEGORY || []}
                  value={
                    MANDATE_MANAGEMENT_CATEGORY.find(
                      (dataItem) => dataItem?.value === field.value
                    )?.name
                  }
                  onChange={(event, val) =>
                    field.onChange(val ? val.value : null)
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Select category"}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              size="medium"
              variant="contained"
              type="submit"
              loading={otpLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </form>

      <Show.When isTrue={openVerifyModal}>
        <VerifyModal
          open={openVerifyModal}
          setOpen={setOpenVerifyModal}
          optApi={`company-otp`}
          onSuccess={form.handleSubmit((values) => {
            values.id
              ? handleMandate({
                  values: values,
                  request: "patch",
                  route: `/digio/company-configuration/${values?.id}/`,
                })
              : handleMandate({
                  values: values,
                  request: "post",
                  route: `/digio/company-configuration/`,
                });
          })}
        />
      </Show.When>
    </FormProvider>
  );
};

export default Mandate;
