import GKClientDataGrid from "components/GKClientDataGrid";
import DashboardLayout from "layouts/DashboardLayout";
import { modelPortfolioPerformanceColumns } from "utils/columns";

const data = [
  {
    id: 1,
    asset: "Equity",
    sinceLastChange: "-0.5%",
    "1Month": "-0.5%",
    "3Month": "-0.5%",
    "6Month": "-0.5%",
    "9Month": "-0.5%",
    "12Month": "-0.5%",
    ytd: "-0.5%",
    "3ytd": "-0.5%",
    sinceInception: "-0.5%",
  },
  {
    id: 2,
    asset: "Fixed Income",
    sinceLastChange: "-0.5%",
    "1Month": "-0.5%",
    "3Month": "-0.5%",
    "6Month": "-0.5%",
    "9Month": "-0.5%",
    "12Month": "-0.5%",
    ytd: "-0.5%",
    "3ytd": "-0.5%",
    sinceInception: "-0.5%",
  },
  {
    id: 3,
    asset: "Cash",
    sinceLastChange: "-0.5%",
    "1Month": "-0.5%",
    "3Month": "-0.5%",
    "6Month": "-0.5%",
    "9Month": "-0.5%",
    "12Month": "-0.5%",
    ytd: "-0.5%",
    "3ytd": "-0.5%",
    sinceInception: "-0.5%",
  },
];

const ModelPortfolioPerformance = () => {
  return (
    <DashboardLayout title="Equity Basket Performance">
      <GKClientDataGrid
        name="modelPortfolioPerformance"
        columns={modelPortfolioPerformanceColumns}
        rows={data || []}
      />
    </DashboardLayout>
  );
};

export default ModelPortfolioPerformance;
