import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import GKStaticProgress from "components/GKStaticProgress";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CgMathPlus } from "react-icons/cg";
import { FaCircleCheck } from "react-icons/fa6";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { GoDash } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleOnboardingCalculation } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const ParentAccordion = styled(Accordion)(() => {
  return {
    borderRadius: "14px !important",
    boxShadow: "none",
    border: `1px solid #E1E8F1`,
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 10,
      color: "#000",
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      "& .MuiPaper-root:before": {
        backgroundColor: "transparent",
      },
    },
  };
});

const ChildAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    padding: 0,
    border: `0px solid #E1E8F1`,
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 10,
      color: paletteLightTheme.palette.primary.main,
      background: "#F2F7F9",
      padding: "8px 24px",
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: "8px 24px",
    },
    "& .MuiAccordionDetails-root": {
      background: "#F2F7F9",
      padding: "0px 24px 24px 24px",
      "& .MuiPaper-root:before": {
        backgroundColor: "transparent",
      },
    },
  };
});

const OnboardingTradesAccordian = ({ handleMarkAsComplete }: any) => {
  const navigate = useNavigate();

  const {
    dispatch,
    state: { tab, userData },
  } = useAppContext();

  const [selectedParentAccordian, setSelectedParentAccordian] = useState([0]);

  const [selectedChildAccordian, setSelectedChildAccordian] = useState([0]);

  useDocumentTitle("Company Onboarding");

  return (
    <ParentAccordion
      expanded={selectedParentAccordian?.includes(1)}
      onChange={() => {
        if (selectedParentAccordian?.includes(1)) {
          setSelectedParentAccordian(
            selectedParentAccordian?.filter((e) => e !== 1)
          );
        } else {
          setSelectedParentAccordian([...selectedParentAccordian, 1]);
        }
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {selectedParentAccordian?.includes(1) ? (
              <GoDash />
            ) : (
              <AiOutlinePlus />
            )}
            &nbsp;Step 4: Trade and Cash Entries
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={0.5}
          >
            {userData?.profileDetails?.tradeCashDetails?.hasTrades &&
            userData?.profileDetails?.tradeCashDetails?.hasCashTransactions ? (
              <IconButton color="success">
                <FaCircleCheck size={20} />
              </IconButton>
            ) : (
              <GKStaticProgress
                value={
                  userData?.profileDetails &&
                  handleOnboardingCalculation({
                    obj: userData?.profileDetails,
                    objKey: "tradeCashDetails",
                  }).value
                }
                size={18}
              />
            )}
            <Typography>
              {userData?.profileDetails &&
                handleOnboardingCalculation({
                  obj: userData?.profileDetails,
                  objKey: "tradeCashDetails",
                }).str}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ChildAccordion
          sx={{
            borderBottom: `1px solid #E1E8F1`,
          }}
          expanded={selectedChildAccordian?.includes(0)}
          onChange={() => {
            if (selectedChildAccordian?.includes(0)) {
              setSelectedChildAccordian(
                selectedChildAccordian?.filter((e) => e !== 0)
              );
            } else {
              setSelectedChildAccordian([...selectedChildAccordian, 0]);
            }
          }}
        >
          <AccordionSummary
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid
                item
                xs={8}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                {selectedChildAccordian?.includes(0) ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
                &nbsp;Record Cash Entries
              </Grid>
              <Grid
                item
                xs={4}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                {!userData?.profileDetails?.tradeCashDetails
                  ?.hasCashTransactions && (
                  <GKButton
                    sx={{ textTransform: "none" }}
                    variant="link"
                    color="primary"
                    onClick={() => {
                      handleMarkAsComplete("has_cash_transactions");
                    }}
                  >
                    Mark as Complete
                  </GKButton>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <GKButton sx={{ p: 0, display: "contents" }}>
                  <img
                    height={"99%"}
                    width={"99%"}
                    style={{
                      borderRadius: 16,
                    }}
                    alt=""
                    src="/Logos/comingSoon.svg"
                    draggable="false"
                  />
                </GKButton>
              </Grid>
              <Grid item xs={10} container spacing={1}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Typography fontWeight={600} fontSize={14} color={"#4B5565"}>
                    Enter cash transactions manually or import them.{" "}
                  </Typography>
                  <Typography fontWeight={400} fontSize={12} color={"#697586"}>
                    {`Quick Tip: Use our "Cash Entries" templates in Import
                    Manager for faster setup.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center", gap: 8 }}
                >
                  <GKButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/app/imports`);
                    }}
                  >
                    Import
                  </GKButton>
                  <GKButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: "TAB",
                        data: { ...tab, tabValueOfReports: 5 },
                      });
                      navigate(`/app/reports`);
                    }}
                    startIcon={<CgMathPlus size={14} />}
                  >
                    Add Cash Entry{" "}
                  </GKButton>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </ChildAccordion>
        <ChildAccordion
          sx={{
            borderRadius: "14px important",
          }}
          expanded={selectedChildAccordian?.includes(1)}
          onChange={() => {
            if (selectedChildAccordian?.includes(1)) {
              setSelectedChildAccordian(
                selectedChildAccordian?.filter((e) => e !== 1)
              );
            } else {
              setSelectedChildAccordian([...selectedChildAccordian, 1]);
            }
          }}
        >
          <AccordionSummary
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid
                item
                xs={8}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                {selectedChildAccordian?.includes(1) ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
                &nbsp;Add or Import trades
              </Grid>
              <Grid
                item
                xs={4}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                {!userData?.profileDetails?.tradeCashDetails?.hasTrades && (
                  <GKButton
                    variant="link"
                    sx={{ textTransform: "none" }}
                    color="primary"
                    onClick={() => {
                      handleMarkAsComplete("has_trades");
                    }}
                  >
                    Mark as Complete
                  </GKButton>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <GKButton sx={{ p: 0, display: "contents" }}>
                  <img
                    height={"99%"}
                    width={"99%"}
                    style={{
                      borderRadius: 16,
                    }}
                    alt=""
                    src="/Logos/comingSoon.svg"
                    draggable="false"
                  />
                </GKButton>
              </Grid>
              <Grid item xs={10} container spacing={1}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Typography fontWeight={600} fontSize={14} color={"#4B5565"}>
                    Add or Import trades are accurately recorded.
                  </Typography>
                  <Typography fontWeight={400} fontSize={12} color={"#697586"}>
                    {`Quick Tip: Do you know? Imported trades retain historical
                    data for portfolio tracking via "Trade Gridkey" template.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center", gap: 8 }}
                >
                  <GKButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/app/imports`);
                    }}
                  >
                    Import
                  </GKButton>
                  <GKButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(`/app/reports`);
                      dispatch({
                        type: "TAB",
                        data: { ...tab, tabValueOfReports: 3 },
                      });
                    }}
                    startIcon={<CgMathPlus size={14} />}
                  >
                    Add Trades
                  </GKButton>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </ChildAccordion>
      </AccordionDetails>
    </ParentAccordion>
  );
};

export default OnboardingTradesAccordian;
