/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import Permission from "components/Permission";
import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";

const EmployeesPermissions = () => {
  const { id } = useParams();

  return (
    <Permission
      headerTitle="Admin Permission"
      route={`/employees/${id}/permissions/`}
      queryKey={QueryKeys.EMPLOYEE_PERMISSION}
      permissionType="EMPLOYEE"
    />
  );
};

export default EmployeesPermissions;
