import { Divider, Grid } from "@mui/material";
import GKTypography from "components/GKTypography";
import { useFetchQuery } from "hooks/useQueries";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { QueryKeys } from "utils/queryKey";

const EquityCashSection = () => {
  const { theme } = ThemeContainer();

  const { data: niftyData, isFetching: niftyLoading } = useFetchQuery({
    key: [QueryKeys.NIFTY_SENSEX],
    retry: 0,
    route: `/particular-indices/`,
  });
  return (
    <Grid container alignItems={"center"} justifyContent={"flex-end"}>
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        px={2}
        py={1}
        sx={{
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        }}
      >
        <GKTypography
          color={"#697586"}
          style={{
            display: "flex",
            alignItems: "center",
          }}
          fontSize={12}
          textAlign={"left"}
        >
          NIFTY 50
        </GKTypography>

        <GKTypography
          display={"flex"}
          alignItems={"center"}
          fontWeight={700}
          loading={niftyLoading}
          fontSize={16}
          color={theme.palette.dark.main}
        >
          {moneyCommaSeparator(niftyData?.data?.data?.nifty?.cmp)}{" "}
          <span
            style={{
              color: handleReturnPositiveNegative(
                niftyData?.data?.data?.nifty?.ltp
              ),
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 4,
            }}
          >
            ({moneyCommaSeparator(niftyData?.data?.data?.nifty?.ltp)}%)
          </span>
        </GKTypography>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
          sx={{
            height: 16,
          }}
        />
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        px={2}
        py={1}
        color={"#697586"}
        sx={{
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        <GKTypography
          textAlign={"right"}
          fontSize={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          SENSEX
        </GKTypography>
        <GKTypography
          display={"flex"}
          alignItems={"center"}
          fontWeight={700}
          loading={niftyLoading}
          fontSize={16}
          color={theme.palette.dark.main}
        >
          {moneyCommaSeparator(niftyData?.data?.data?.sensex?.cmp)}{" "}
          <span
            style={{
              color: handleReturnPositiveNegative(
                niftyData?.data?.data?.sensex?.ltp
              ),
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 4,
            }}
          >
            ({moneyCommaSeparator(niftyData?.data?.data?.sensex?.ltp)}%)
          </span>
        </GKTypography>
      </Grid>
    </Grid>
  );
};

export default EquityCashSection;
