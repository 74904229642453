import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import toast from "react-hot-toast";
import { RiSendPlaneLine } from "react-icons/ri";
import { VscDebugStart } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: any;
}

const RiskRequestModal = (props: Props) => {
  const { open, setOpen } = props;

  const { id } = useParams();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
    onSuccess: () => {
      toast.success("Questionnaire requested successfully.");
    },
  });

  return (
    <GKModal
      width={"40%"}
      setOpen={setOpen}
      open={open}
      modalTitle="Risk Assessment"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GKButton
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(`/app/risk-profile/${id}/questionnaire`);
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
              >
                <VscDebugStart size={16} />{" "}
                <Typography fontWeight={600} fontSize={16} color={"#000"}>
                  Take Quiz
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by answering a series of questions tailored to assess the client's financial risk profile.`}
                </Typography>
              </Grid>
            </Grid>
          </GKButton>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              mutate(id);
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.7}
              >
                <RiSendPlaneLine size={16} />{" "}
                <Typography fontWeight={600} fontSize={16} color={"#000"}>
                  Send to Client
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by sending them a series of questions to answer`}
                </Typography>
              </Grid>
            </Grid>
          </LoadingButton>
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default RiskRequestModal;
