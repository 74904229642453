import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";
import AnimationData from "../assets/Lottie/404.json";
import GKButton from "./GKButton";
import GKTypography from "./GKTypography";

const ErrorPage = ({ resetErrorBoundary }: any) => {
  const { theme } = ThemeContainer();

  useDocumentTitle("404");

  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid
      container
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          {" "}
          <img
            style={{
              height: 70,
            }}
            src="\Logos\Gridkey_BrandGuidelines-removebg-preview.png"
            alt=""
          />
        </span>
        <GKTypography
          textAlign={"center"}
          fontSize={22}
          fontWeight={700}
          color={theme.palette.primary.main}
        >
          Some Error Occurred !
        </GKTypography>
        <Lottie {...defaultOptions} style={{ height: 300, width: 300 }} />
        <GKTypography
          textAlign={"center"}
          fontWeight={600}
          fontSize={16}
          color={theme.palette.primary.main}
        >
          Sorry for the inconvenience, we are working on it, please try again .
        </GKTypography>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => {
              navigate("/app/dashboard");
              resetErrorBoundary();
            }}
          >
            {" "}
            Go Back Home
          </GKButton>
        </span>
      </Grid>
    </Grid>
  );
};
export default ErrorPage;
