export const PAYMENT_GATEWAY_BUSINESS_TYPE = [
  { name: "Propreitorship", value: "propreitorship" },
  { name: "Public Limited", value: "public_limited" },
  { name: "Private Limited", value: "private_limited" },
  { name: "LLP", value: "llp" },
  { name: "Partnership", value: "partnership" },
];

export const PAYMENT_GATEWAY_CATEGORIES = [
  { name: "Financial Services", value: "financial_services" },
  { name: "Services", value: "services" },
];

export const FINANCIAL_SERVICES_SUBCATEGORIES = [
  { name: "Mutual Fund", value: "mutual_fund" },
  { name: "Lending", value: "lending" },
  { name: "Cryptocurrency", value: "cryptocurrency" },
  { name: "Insurance", value: "insurance" },
  { name: "NBFC", value: "nbfc" },
  { name: "Cooperatives", value: "cooperatives" },
  { name: "Pension Fund", value: "pension_fund" },
  { name: "Forex", value: "forex" },
  { name: "Securities", value: "securities" },
  { name: "Commodities", value: "commodities" },
  { name: "Accounting", value: "accounting" },
  { name: "Financial Advisor", value: "financial_advisor" },
  { name: "Crowdfunding", value: "crowdfunding" },
  { name: "Trading", value: "trading" },
  { name: "Betting", value: "betting" },
  { name: "Get Rich Schemes", value: "get_rich_schemes" },
  { name: "Moneysend Funding", value: "moneysend_funding" },
  {
    name: "Wire Transfers And Money Orders",
    value: "wire_transfers_and_money_orders",
  },
  { name: "Tax Preparation Services", value: "tax_preparation_services" },
  { name: "Tax Payments", value: "tax_payments" },
  { name: "Digital Goods", value: "digital_goods" },
  { name: "ATMs", value: "atms" },
];

export const SERVICES_SUBCATEGORIES = [
  { name: "Repair And Cleaning", value: "repair_and_cleaning" },
  {
    name: "Interior Design And Architect",
    value: "interior_design_and_architect",
  },
  { name: "Movers And Packers", value: "movers_and_packers" },
  { name: "Legal", value: "legal" },
  { name: "Event Planning", value: "event_planning" },
  { name: "Service Centre", value: "service_centre" },
  { name: "Consulting", value: "consulting" },
  { name: "Ad And Marketing", value: "ad_and_marketing" },
  { name: "Services Classifieds", value: "services_classifieds" },
  { name: "Multi Level Marketing", value: "multi_level_marketing" },
  { name: "Construction Services", value: "construction_services" },
  { name: "Architectural Services", value: "architectural_services" },
  { name: "Car Washes", value: "car_washes" },
  { name: "Motor Home Rentals", value: "motor_home_rentals" },
  {
    name: "Stenographic And Secretarial Support Services",
    value: "stenographic_and_secretarial_support_services",
  },
  { name: "Chiropractors", value: "chiropractors" },
  { name: "Automotive Service Shops", value: "automotive_service_shops" },
  { name: "Shoe Repair Shops", value: "shoe_repair_shops" },
  { name: "Telecommunication Service", value: "telecommunication_service" },
  { name: "Fines", value: "fines" },
  { name: "Security Agencies", value: "security_agencies" },
  { name: "Tailors", value: "tailors" },
  {
    name: "Type Setting And Engraving Services",
    value: "ype_setting_and_engraving_services",
  },
  {
    name: "Small Appliance Repair Shops",
    value: "small_appliance_repair_shops",
  },
  { name: "Photography Labs", value: "photography_labs" },
  { name: "Dry Cleaners", value: "dry_cleaners" },
  { name: "Massage Parlors", value: "massage_parlors" },
  { name: "Electronic Repair Shops", value: "lectronic_repair_shops" },
  {
    name: "Cleaning And Sanitation Services",
    value: "cleaning_and_sanitation_services",
  },
  { name: "Nursing Care Facilities", value: "nursing_care_facilities" },
  { name: "Direct Marketing", value: "direct_marketing" },
  { name: "Lottery", value: "lottery" },
  { name: "Veterinary Services", value: "eterinary_services" },
  { name: "Affiliated Auto Rental", value: "affliated_auto_rental" },
  { name: "Alimony And Child Support", value: "alimony_and_child_support" },
  { name: "Airport Flying Fields", value: "airport_flying_fields" },
  { name: "Golf Courses", value: "golf_courses" },
  {
    name: "Tire Retreading And Repair Shops",
    value: "tire_retreading_and_repair_shops",
  },
  { name: "Television Cable Services", value: "television_cable_services" },
  {
    name: "Recreational And Sporting Camps",
    value: "recreational_and_sporting_camps",
  },
  { name: "Barber And Beauty Shops", value: "barber_and_beauty_shops" },
  { name: "Agricultural Cooperatives", value: "agricultural_cooperatives" },
  { name: "Carpentry Contractors", value: "carpentry_contractors" },
  {
    name: "Wrecking And Salvaging Services",
    value: "wrecking_and_salvaging_services",
  },
  { name: "Automobile Towing Services", value: "automobile_towing_services" },
  { name: "Video Tape Rental Stores", value: "video_tape_rental_stores" },
  { name: "Miscellaneous Repair Shops", value: "miscellaneous_repair_shops" },
  { name: "Motor Homes And Parts", value: "motor_homes_and_parts" },
  { name: "Horse Or Dog Racing", value: "horse_or_dog_racing" },
  { name: "Laundry Services", value: "laundry_services" },
  { name: "Electrical Contractors", value: "lectrical_contractors" },
  {
    name: "Debt Marriage Personal Counseling Service",
    value: "debt_marriage_personal_counseling_service",
  },
  {
    name: "Air Conditioning And Refrigeration Repair Shops",
    value: "air_conditioning_and_refrigeration_repair_shops",
  },
  { name: "Credit Reporting Agencies", value: "credit_reporting_agencies" },
  {
    name: "Heating And Plumbing Contractors",
    value: "heating_and_plumbing_contractors",
  },
  {
    name: "Carpet And Upholstery Cleaning Services",
    value: "carpet_and_upholstery_cleaning_services",
  },
  { name: "Swimming Pools", value: "swimming_pools" },
  {
    name: "Roofing And Metal Work Contractors",
    value: "roofing_and_metal_work_contractors",
  },
  { name: "Internet Service Providers", value: "internet_service_providers" },
  { name: "Recreational Camps", value: "recreational_camps" },
  { name: "Masonry Contractors", value: "masonry_contractors" },
  {
    name: "Exterminating And Disinfecting Services",
    value: "exterminating_and_disinfecting_services",
  },
  { name: "Ambulance Services", value: "ambulance_services" },
  {
    name: "Funeral Services And Crematories",
    value: "funeral_services_and_crematories",
  },
  { name: "Metal Service Centres", value: "metal_service_centres" },
  {
    name: "Copying And Blueprinting Services",
    value: "copying_and_blueprinting_services",
  },
  { name: "Fuel Dispensers", value: "fuel_dispensers" },
  { name: "Welding Repair", value: "welding_repair" },
  { name: "Mobile Home Dealers", value: "mobile_home_dealers" },
  { name: "Concrete Work Contractors", value: "concrete_work_contractors" },
  { name: "Boat Rentals", value: "boat_rentals" },
  {
    name: "Personal Shoppers And Shopping Clubs",
    value: "personal_shoppers_and_shopping_clubs",
  },
  { name: "Door To Door Sales", value: "door_to_door_sales" },
  {
    name: "Travel Related Direct Marketing",
    value: "travel_related_direct_marketing",
  },
  { name: "Lottery And Betting", value: "lottery_and_betting" },
  {
    name: "Bands Orchestras And Miscellaneous Entertainers",
    value: "bands_orchestras_and_miscellaneous_entertainers",
  },
  {
    name: "Furniture Repair And Refinishing",
    value: "furniture_repair_and_refinishing",
  },
  { name: "Contractors", value: "contractors" },
  {
    name: "Direct Marketing And Subscription Merchants",
    value: "direct_marketing_and_subscription_merchants",
  },
  {
    name: "Typewriter Stores Sales Service And Rentals",
    value: "typewriter_stores_sales_service_and_rentals",
  },
  { name: "Recreation Services", value: "recreation_services" },
  {
    name: "Direct Marketing Insurance Services",
    value: "direct_marketing_insurance_services",
  },
  { name: "Business Services", value: "business_services" },
  {
    name: "Inbound Telemarketing Merchants",
    value: "inbound_telemarketing_merchants",
  },
  { name: "Public Warehousing", value: "public_warehousing" },
  {
    name: "Outbound Telemarketing Merchants",
    value: "outbound_telemarketing_merchants",
  },
  { name: "Clothing Rental Stores", value: "clothing_rental_stores" },
  { name: "Transportation Services", value: "transportation_services" },
  { name: "Electric Razor Stores", value: "electric_razor_stores" },
  { name: "Service Stations", value: "service_stations" },
  { name: "Photographic Studio", value: "photographic_studio" },
  { name: "Professional Services", value: "professional_services" },
];

export const MANDATE_MANAGEMENT_CATEGORY = [
  { value: "C001", name: "B2B Corporate" },
  { value: "B001", name: "Bill Payment Credit card" },
  { value: "D001", name: "Destination Bank Mandate" },
  { value: "E001", name: "Education fees" },
  { value: "I001", name: "Insurance Premium" },
  { value: "I002", name: "Insurance other payment" },
  { value: "L099", name: "Legacy One crore and Above" },
  { value: "L002", name: "Loan amount security" },
  { value: "L001", name: "Loan installment payment " },
  { value: "M001", name: "Mutual Fund Payment" },
  { value: "U099", name: "Others" },
  { value: "F001", name: "Subscription Fees" },
  { value: "T002", name: "TReDS" },
  { value: "T001", name: "Tax  Payment" },
  { value: "U001", name: "Utility Bill Payment Electricity" },
  { value: "U003", name: "Utility Bill payment Gas Supply Cos" },
  { value: "U005", name: "Utility Bill payment mobile telephone broadband" },
  { value: "U006", name: "Utility Bill payment water" },
  { value: "S001", name: "Small Value Mandate" },
];
