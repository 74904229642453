import { Box, Grid, Typography } from "@mui/material";
import GKModal from "components/GKModal";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import GKButton from "./GKButton";

interface Props {
  file?: string;
  paginationView?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ViewPdf = (props: Props) => {
  const { file, paginationView = false, open, setOpen } = props;

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const fileUrl =
    "https://gridkey-file-upload-stage.s3.amazonaws.com/report_samples/portfolio_performance_report.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQW3Z5LCMGMQMYYSH%2F20240614%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240614T092233Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c1acc786349fb5880b98fda98c27a6e3330d722c41b5704a9728c2e3c5d2ba17";

  return (
    <GKModal
      modalTitle="Preview"
      open={open}
      setOpen={setOpen}
      width={"60%"}
      footer={
        <GKButton
          variant="contained"
          size="medium"
          onClick={() => setOpen(!open)}
        >
          Ok
        </GKButton>
      }
    >
      <Grid container justifyContent={"center"}>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Document
            onLoadSuccess={onDocumentLoadSuccess}
            file={file || fileUrl}
            renderMode="canvas"
          >
            {!paginationView &&
              Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={700}
                />
              ))}
          </Document>
        </Box>
        {paginationView && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "2px 0",
            }}
          >
            <GKButton
              variant="contained"
              color="primary"
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={pageNumber <= 1}
            >
              Previous
            </GKButton>
            <Typography variant="body1">
              Page {pageNumber} of {numPages}
            </Typography>
            <GKButton
              variant="contained"
              color="primary"
              onClick={() =>
                setPageNumber((prev) => Math.min(prev + 1, numPages))
              }
              disabled={pageNumber >= numPages}
            >
              Next
            </GKButton>
          </Box>
        )}
      </Grid>
    </GKModal>
  );
};

export default ViewPdf;
