import { LoadingButton } from "@mui/lab";
import { Divider, Grid, Popover, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import StatusField from "components/StatusField";
import { useState } from "react";
import toast from "react-hot-toast";
import { BsPlus } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa6";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RiDeleteBinLine, RiListRadio } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import UpdateLead from "../UpdatetLead";
import LeadAssignPopup from "./LeadAssignPopup";
import LeadStatusPopup from "./LeadStatusPopup";
import LeadTagsPopup from "./LeadTagsPopup";
import SendMarketingMaterial from "./SendMarketingMaterial";

interface Props {
  leadData: {
    id: number;
    leadStatusStr: string;
    leadAssignedToStr: string;
    sourceOfLeadStr: string;
    tagsList: any[];
    hasMeeting: boolean;
    hasNotes: boolean;
    meetingCount: number;
    notesCount: number;
    name: string;
    email: string;
    companyName: string;
    countryCode: string;
    phoneNumber: string;
    lastContacted: string;
    riskStatus: string;
    panNumber: string;
    position: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    leadValue: string;
    description: string;
    conversionStatus: false;
    createdAt: string;
    updatedAt: string;
    branch: number;
    leadStatus: number;
    leadAssignedTo: number;
    sourceOfLead: number;
    tags: any[];
  };
}

const LeadCard = (props: Props) => {
  const { leadData } = props;

  const { id } = useParams();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [updateModal, setUpdateModal] = useState(false);

  const [sendMaterialModal, setSendMaterialModal] = useState(false);

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: convertToCustomer } = useMutation({
    mutationKey: ["CONVERT_TO_CUSTOMER"],
    mutationFn: () => axiosInstance.post(`/client/${id}/convert/`),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleSendRisk, isLoading: riskLoading } = useMutation({
    mutationFn: (da: any) =>
      axiosInstance.post(`/lead-questionnaire-request/`, {
        lead_id: da,
      }),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledDeleteLead, isLoading: deleteLoading } = useMutation({
    mutationFn: (da: any) => axiosInstance.delete(`/lead/${da}`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      navigate(-1);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again."
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8} container spacing={1} alignItems={"center"}>
          <Grid item xs={12} container spacing={1}>
            <Grid item>
              <Typography fontWeight={600} fontSize={24}>
                {leadData?.name || "*Update Name"}
              </Typography>
              <Typography fontWeight={400} fontSize={12} color={"#697586"}>
                {leadData?.companyName || "*Update Company Name"}
              </Typography>
            </Grid>
            <Grid item>
              <LeadStatusPopup leadData={leadData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container spacing={1} alignItems={"center"}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <GKButton
              className="convert-to-client"
              variant="contained"
              size="medium"
              onClick={() => convertToCustomer()}
            >
              Convert to client
            </GKButton>
            <GKButton
              color="dark"
              variant="outlined"
              startIcon={<FaWhatsapp size={16} color="#9AA4B2" />}
              onClick={() => {
                setSendMaterialModal(true);
              }}
            >
              Send Marketing
            </GKButton>
            <GKButton
              variant="outlined"
              color="dark"
              style={{
                minWidth: 1,
                width: 40,
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <HiOutlineDotsVertical />
            </GKButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} gap={3}>
          <div>
            <LeadAssignPopup leadData={leadData} />
          </div>
          <div>
            <LeadTagsPopup leadData={leadData} />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography color={"#9AA4B2"} fontSize={12}>
              Risk Profile
            </Typography>
            <StatusField statusString={leadData?.riskStatus || "pending"} />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography color={"#9AA4B2"} fontSize={12}>
              Last Contacted
            </Typography>
            <Typography>{matchDate(leadData?.lastContacted)}</Typography>
          </div>
        </Grid>
      </Grid>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: { width: "15%", borderRadius: 16 },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container sx={{ py: "6px" }}>
          <Grid item xs={12}>
            <GKButton
              startIcon={<BsPlus size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                navigate(`/app/lead/${id}/questionnaire`);
              }}
            >
              Take Quiz{" "}
            </GKButton>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={riskLoading}
              startIcon={<RiListRadio size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                handleSendRisk(id);
              }}
            >
              Request Questionnaire{" "}
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              onClick={() => {
                handledDeleteLead(id);
              }}
              loading={deleteLoading}
              startIcon={<RiDeleteBinLine size={16} color="#9AA4B2" />}
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Delete
            </LoadingButton>
          </Grid>
        </Grid>
      </Popover>

      {updateModal && (
        <UpdateLead setOpen={setUpdateModal} open={updateModal} id={id} />
      )}

      {sendMaterialModal && (
        <SendMarketingMaterial
          setOpen={setSendMaterialModal}
          open={sendMaterialModal}
          id={id}
        />
      )}
    </>
  );
};

export default LeadCard;
