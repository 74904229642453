import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";
import EmailConfig from "./EmailConfig";
import EmailHeaderSection from "./EmailHeaderSection";
import EmailReply from "./EmailReply";

const SmtpSetup = () => {
  useDocumentTitle("Email Configuration");

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["EMAIL_CONFIGURATION"],
    route: "/email-configuration/",
  });

  const { data: handleReplyTo, isFetching: replyToFetching } = useFetchQuery({
    key: ["GET_REPLAY_EMAIL_LIST"],
    route: "/reply-email-portfolio/",
  });

  const { mutate: handleUpdate, isLoading } = useMutation({
    mutationKey: ["UPDATE_EMAIL_CONFIGURATION"],
    mutationFn: (values: any) =>
      axiosInstance.patch(
        `/email-configuration/${data?.data?.data?.id}/`,
        values
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleUpdateEmailConfig, isLoading: emailConfigLoading } =
    useMutation({
      mutationKey: ["HANDLE_UPDATE_EMAIL_CONFIGURATION"],
      mutationFn: (values: any) =>
        axiosInstance.patch(
          `/email-configuration-header/${data?.data?.data?.id}/`,
          values
        ),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        refetch();
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  if (isFetching) return <SimpleLoader />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EmailConfig
          data={data?.data?.data}
          handleUpdate={handleUpdate}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailHeaderSection
          data={data?.data?.data}
          handleUpdate={handleUpdateEmailConfig}
          isLoading={emailConfigLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailReply
          data={handleReplyTo?.data?.data}
          isLoading={replyToFetching}
        />
      </Grid>
    </Grid>
  );
};

export default SmtpSetup;
