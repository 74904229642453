import { FormControl, Grid } from "@mui/material";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";

interface Props {
  open: boolean;
  setOpen: any;
  favoriteRefetch: any;
  setTemplatesTabs: any;
  otherData: any;
}

interface FormValues {
  name: string;
  broker: string;
  file_type: string;
  version: string | number;
}

const AddFavoriteTemplate = ({
  open,
  setOpen,
  otherData,
  favoriteRefetch,
  setTemplatesTabs,
}: Props) => {
  const form = useForm({
    defaultValues: {
      name: "",
      broker: otherData?.fileBrokerType,
      file_type: otherData?.fileType,
      version: otherData?.fileVersion,
    },
  });

  const onSubmit = async (values: FormValues) => {
    await axiosInstance
      .post(`/transaction_upload/fav_upload/`, {
        title: values.name,
        upload_file: "Stock",
        broker: otherData?.fileBrokerType,
        file_type: otherData?.fileType,
        version: otherData?.fileVersion,
      })
      .then(() => {
        setTemplatesTabs(1);
        toast.success(`${values.name} Successfully added to favorites`);
        favoriteRefetch();
        setOpen(false);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Import Template "
      width={"40%"}
      footer={
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap="10px"
        >
          <GKButton
            form="add-favorite-form"
            type="submit"
            variant="contained"
            size="medium"
          >
            Add
          </GKButton>
        </Grid>
      }
    >
      <form id="add-favorite-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Template Title"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AddFavoriteTemplate;
