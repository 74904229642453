import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import StatusField from "components/StatusField";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

const FeeCollectionTab = () => {
  const { id } = useParams();

  const { data, isFetching } = useFetchQuery({
    key: ["PORTFOLIO_FEE_PLANS"],
    route: `/client-fee-collection/get-portfolio-fee-collection/${id}/`,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Collection Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Invoice Date", "invoiceDate", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Invoice Amount", "invoiceAmount", 150, 1),
      valueGetter: (row) => row?.row?.invoiceAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Paid Amount", "paidAmount", 150, 1),
      valueGetter: (row) => row?.row?.paidAmount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Status", "invoiceStatus", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.invoiceStatus.toString()} />
      ),
    },
    {
      ...extraColumnsField("Source", "source", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.source.toString()} />
      ),
    },
  ];

  return (
    <GKClientDataGrid
      loading={isFetching}
      columns={columns}
      rows={data?.data?.data || []}
      filterButton={false}
      name="portfolioFeeCollection"
    />
  );
};

export default FeeCollectionTab;
