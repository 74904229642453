import React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import GKFillRadioButton from "hooks/GKFillRadioButton";

interface PortfolioMetricsComponentProps {
  control: Control<any>;
  name: string;
  label: string;
  trueLabel: string;
  falseLabel: string;
  descriptionTrue: string;
  descriptionFalse: string;
}

const PortfolioMetricsComponent: React.FC<PortfolioMetricsComponentProps> = ({
  control,
  name,
  label,
  trueLabel,
  falseLabel,
  descriptionTrue,
  descriptionFalse,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mb={1}>
        <Typography fontSize={16} fontWeight={500} color={"#4B5565"}>
          {label}
        </Typography>
      </Grid>
      <FormControl component="fieldset">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              value={field.value ? "trueValue" : "falseValue"}
              onChange={(e) => field.onChange(e.target.value === "trueValue")}
            >
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="trueValue"
                    control={<GKFillRadioButton />}
                    label={
                      <Typography
                        fontSize={16}
                        fontWeight={400}
                        display="inline"
                      >
                        {trueLabel}
                      </Typography>
                    }
                  />
                  <Grid item xs={12} ml={"34px"}>
                    <Typography fontSize={12} color={"#4B5565"}>
                      {descriptionTrue}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="falseValue"
                    control={<GKFillRadioButton />}
                    label={
                      <Typography fontSize={16} fontWeight={400}>
                        {falseLabel}
                      </Typography>
                    }
                  />
                  <Grid item xs={12} ml={"34px"}>
                    <Typography fontSize={12} color={"#4B5565"}>
                      {descriptionFalse}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default PortfolioMetricsComponent;
