import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import GKButton from "components/GKButton";
import GKTextField from "components/GKTextField";
import PhoneInput from "components/PhoneInput";
import { useLogin } from "hooks/useLogin";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { IoMdEye } from "react-icons/io";
import { IoEyeOffOutline } from "react-icons/io5";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const LoginPage = () => {
  const { theme } = ThemeContainer();

  const { isSubmitting, navigate, form, onSubmit } = useLogin();

  const [showPassword, setShowPassword] = useState(false);

  useDocumentTitle("Login");

  form.watch();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container gap={2} justifyContent={"center"}>
        <Grid
          item
          xs={12}
          position={"relative"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap="20px"
        >
          <Typography
            fontWeight={700}
            fontSize={24}
            className="textUnderlineLogin"
            color={theme.palette.dark.main}
            sx={{
              "&.textUnderlineLogin::before": {
                width: "140px !important",
              },
            }}
          >
            Hello again !
          </Typography>
          <Typography color={theme.palette.dark.main}>
            If you are already a member, please login to your account.
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={8} md={7} xl={6} spacing={1}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="phone"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("countryCode")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="password"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Password"
                    type={showPassword ? "text" : "password"}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      style: {
                        paddingRight: 12,
                      },
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{
                            padding: 0,
                          }}
                        >
                          {!showPassword ? <IoMdEye /> : <IoEyeOffOutline />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              alignItems={"center"}
              display={"flex"}
            >
              <GKButton
                variant="text"
                style={{ fontWeight: 600 }}
                onClick={() =>
                  navigate("/login/authentication", {
                    state: { loginWithOTP: true },
                  })
                }
              >
                Login Using OTP
              </GKButton>
              <GKButton
                variant="text"
                style={{ fontWeight: 600 }}
                onClick={() => navigate("/login/forgot-password")}
              >
                {" "}
                Forgot your password?
              </GKButton>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={"end"}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="medium"
              type="submit"
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Divider style={{ margin: "10px 0" }} />
          <Typography color={theme.palette.dark.main}>
            Don&apos;t have an account?
            <GKButton
              variant="text"
              onClick={() =>
                navigate("/login/authentication", { state: "forgot" })
              }
              style={{ padding: 0 }}
            >
              {" "}
              Register
            </GKButton>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginPage;
