import { LoadingButton } from "@mui/lab";
import {
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import GKDataGrid from "components/GKDataGrid";
import GKModal from "components/GKModal";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  familyAccessData: FamilyAccessDataInterface;
}

export interface FamilyAccessDataInterface {
  id: number;
  clientCode: string;
  name: string;
  familyName: string;
  phone: string;
  countryCode: string;
  email: string;
  pan: string;
  branch: string;
  portfolioCount: number;
  familyId?: string;
}

export interface AccessList {
  totalCount: number;
  data: Daum[];
  portfoliosAccess: number[];
  checkAll?: boolean;
}

export interface Daum {
  portfolioId: string;
  name: string;
  id: number;
  phone: string;
  clientName: string;
  clientCode: string;
}

const FamilyAccessModal = ({ open, setOpen, familyAccessData }: Props) => {
  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const [portfolioAccess, setPortfolioAccess] = useState<AccessList>();

  const requestBody = {
    take: commonFilter?.familyPortfolioAccess?.take,
    skip: commonFilter?.familyPortfolioAccess?.skip,
    sort: JSON.stringify(
      commonFilter?.familyPortfolioAccess?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.familyPortfolioAccess?.search || "",
  };

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["FAMILY_PORTFOLIO_ACCESS", requestBody],
    route: `/family/${familyAccessData?.familyId}/portfolios_access/${familyAccessData?.id}/`,
    requestBody,
    success: (response: { data: AccessList }) => {
      setPortfolioAccess(response?.data);
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={portfolioAccess?.totalCount || 0}
        name="familyPortfolioAccess"
      />
    );
  };

  const { mutate: handleToggleAccess, isLoading: toggleLoading } = useMutation({
    mutationKey: ["FAMILY_PORTFOLIO_TOGGLE_ACCESS"],
    mutationFn: (obj: { portfoliosAccess?: number[]; checkAll?: boolean }) =>
      axiosInstance.patch(
        `/family/${familyAccessData?.familyId}/portfolios_access/${familyAccessData?.id}/`,
        obj
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      refetchQuery(QueryKeys.FAMILY_ACCESS);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const toggleSingleAccess = (id: number) => {
    const currentAccessIds = portfolioAccess?.portfoliosAccess || [];
    const updatedAccessIds = currentAccessIds.includes(id)
      ? currentAccessIds.filter((accessId) => accessId !== id)
      : [...currentAccessIds, id];

    handleToggleAccess({ portfoliosAccess: updatedAccessIds });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "name", 240, 1),
      renderCell: (params) => {
        return (
          <Grid container>
            <Tooltip title={params?.row?.name}>
              <Grid item xs={11}>
                <Typography
                  fontWeight={700}
                  maxWidth={"100%"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                >
                  {params?.row?.name}
                  {params?.row?.isClientsPortfolio && (
                    <Typography
                      bgcolor={"rgb(0 101 128 / 11%)"}
                      fontSize={10}
                      fontWeight={500}
                      color={"#006580"}
                      p={"2px 4px"}
                      borderRadius={"4px"}
                      lineHeight={"14px"}
                    >
                      Self
                    </Typography>
                  )}
                </Typography>
                <Typography fontSize={12}>
                  {params?.row?.portfolioId}
                </Typography>
              </Grid>
            </Tooltip>{" "}
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Client Name", "clientName", 150, 1),
    },
    // {
    //   ...extraColumnsField("Phone", "phone", 130, 1),
    //   valueGetter: (params) => params?.row?.phone || "-",
    // },
    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <Switch
            checked={portfolioAccess?.portfoliosAccess?.includes(params.row.id)}
            onChange={() => toggleSingleAccess(params.row.id)}
          />
        );
      },
    },
  ];

  function clearPagination() {
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        familyPortfolioAccess: {
          skip: 0,
          page: 0,
          take: 25,
          sortModel: [],
          filterColumn: [],
          search: "",
          showNonZero: false,
          customFilter: {},
        },
      },
    });
    setOpen(false);
  }

  return (
    <GKModal
      modalTitle={`${familyAccessData?.name} - Portfolio Access`}
      open={open}
      setOpen={() => clearPagination()}
      footer={
        <Grid item xs={12} textAlign={"right"}>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={() => clearPagination()}
          >
            Save
          </LoadingButton>
        </Grid>
      }
    >
      <GKDataGrid
        columns={columns}
        rows={data?.data?.data || []}
        loading={isFetching}
        columnsButton={false}
        filterButton={false}
        name="familyPortfolioAccess"
        CustomPagination={!isFetching && cusPagination}
        headerComponent={
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  disabled={isFetching || toggleLoading}
                  checked={portfolioAccess?.checkAll}
                  onChange={() =>
                    handleToggleAccess(
                      portfolioAccess?.checkAll
                        ? { checkAll: false }
                        : { checkAll: true }
                    )
                  }
                />
              }
              label={portfolioAccess?.checkAll ? "Disable All" : "Enable All"}
              labelPlacement="end"
            />
          </Grid>
        }
        maxHeight={"calc(100vh - 398px)"}
      />
    </GKModal>
  );
};

export default FamilyAccessModal;
