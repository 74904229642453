import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ReportsManager from ".";
import ReportScheduler from "./ReportScheduler";

const ReportTab = () => {
  const [reportTab, setReportTab] = useState(0);

  const tabs = ["Report", "Scheduler"];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={reportTab}
          onChange={(e, val) => {
            setReportTab(val);
          }}
        >
          {tabs?.map((dataItem, index) => (
            <Tab key={index} label={dataItem} disableRipple />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {reportTab === 0 && <ReportsManager />}
        {reportTab === 1 && <ReportScheduler />}
      </Grid>
    </Grid>
  );
};

export default ReportTab;
