import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import GKModal from "components/GKModal";
import GKReactQuill from "components/GKReactQuill";
import { Controller } from "react-hook-form";

interface Props {
  open: boolean;
  setOpen: any;
  submit: any;
  form: any;
}

const OrderRationaleModal = (props: Props) => {
  const { open, setOpen, submit, form } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Generate Order Sheet"
      footer={
        <Grid container display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton variant="contained" onClick={submit}>
            Submit
          </LoadingButton>
        </Grid>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="rationale"
            render={({ field }) => {
              return (
                <FormControl variant="standard" fullWidth>
                  <GKReactQuill {...field} inputLabel="Rationale" />
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default OrderRationaleModal;
