import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TableCell as MuiTableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import DragDrop from "components/DragDrop";
import GKButton from "components/GKButton";
import GKModal from "components/GKModal";
import GKSelect from "components/GKSelect";
import GKSideModal from "components/GKSideModal";
import GKTextArea from "components/GKTextBar";
import GKTextField from "components/GKTextField";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { matchDate } from "utils/helpers";

const TableCell = styled(MuiTableCell)`
  padding: 8px !important;
`;

const AddPortfolioDetailsModal = ({
  open,
  setOpen,
  addDetailsData,
  refetchData,
  setAddDetailsData,
}: any) => {
  const [uploadError, setUploadError] = useState<string | null>(null);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [portfoliosStatus, setPortfoliosStatus] = useState<any>([]);

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const { mutate: handleSubmit, isLoading: submitLoading } = useMutation({
    mutationKey: ["ADD_PORTFOLIO_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.id}/`,
        data
      ),
    onSuccess: () => {
      refetchData(id, addDetailsData?.id);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteResearchFiles, isLoading: researchFilesLoading } =
    useMutation({
      mutationKey: ["DELETE_RESEARCH_FILES"],
      mutationFn: (data: any) =>
        axiosInstance.delete(
          `/model_portfolio/${id}/stock_item/${addDetailsData?.id}/delete_research_file/?role=advisor&file_id=${data}`
        ),
      onSuccess: () => {
        refetchData(id, addDetailsData?.id);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const { mutate: handleSubmitLinked, isLoading: linkLoading } = useMutation({
    mutationKey: ["ADD_PORTFOLIO_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.post(
        `/model_portfolio/${id}/stock_item/${addDetailsData?.id}/update_linked_portfolios/`,
        { mappedPortfolioStatus: data }
      ),
    onSuccess: () => {
      refetchData(id, addDetailsData?.id);
      setIsStatusModalOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      stockName: addDetailsData?.companyName || "",
      rationale: addDetailsData?.rationale || "",
      status: addDetailsData?.status || "",
      target_price: addDetailsData?.targetPrice || "",
      stop_loss: addDetailsData?.stopLoss || "",
      research_files: [],
    },
    values: {
      stockName: addDetailsData?.companyName || "",
      rationale: addDetailsData?.rationale || "",
      status: addDetailsData?.status || "",
      target_price: addDetailsData?.targetPrice || "",
      stop_loss: addDetailsData?.stopLoss || "",
      research_files: [],
    },
  });

  useEffect(() => {
    if (addDetailsData?.mappedPortfolioStatus?.length !== 0) {
      setPortfoliosStatus(addDetailsData?.mappedPortfolioStatus);
    }
  }, [addDetailsData?.mappedPortfolioStatus]);

  const handleFormSubmit = (values: any) => {
    const data = new FormData();
    data.append("research_files", values?.research_files[0]);
    data.append("rationale", values?.rationale);
    data.append("status", values?.status);
    data.append("target_price", values?.target_price);
    data.append("stop_loss", values?.stop_loss);
    handleSubmit(data);
  };

  return (
    <>
      <GKSideModal
        width="50%"
        onClose={() => {
          setOpen(false);
          setAddDetailsData(null);
        }}
        open={open}
        setOpen={setOpen}
        modalTitle="Stock Details"
        footerButton={
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={"10px"}
          >
            <GKButton
              color="dark"
              variant="outlined"
              size="medium"
              onClick={() => {
                setAddDetailsData(null);
                setOpen(false);
              }}
            >
              Close
            </GKButton>
            <LoadingButton
              variant="contained"
              type="submit"
              form="stock-details-form"
              size="medium"
              loading={submitLoading}
            >
              {"Submit"}
            </LoadingButton>
          </Grid>
        }
      >
        {addDetailsData?.id !== "" && (
          <form
            onSubmit={form.handleSubmit(handleFormSubmit)}
            id="stock-details-form"
          >
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  color={theme.palette.primary.main}
                >
                  {addDetailsData?.companyName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormControl variant="standard" fullWidth>
                        <GKSelect
                          {...field}
                          inputLabel="Status"
                          placeholder="Status"
                        >
                          <MenuItem style={{ display: "none" }} value="">
                            Select Sttatus
                          </MenuItem>
                          <MenuItem value={"BUY"}>Buy</MenuItem>
                          <MenuItem value={"HOLD"}>Hold</MenuItem>
                          <MenuItem value={"EXIT"}>Exit</MenuItem>
                        </GKSelect>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="target_price"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKTextField
                          {...field}
                          type="number"
                          inputLabel="Latest Target"
                          placeholder="Latest Target"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="stop_loss"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKTextField
                          {...field}
                          type="number"
                          inputLabel="Stop Loss"
                          placeholder="Stop Loss"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="rationale"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKTextArea
                          {...field}
                          minRows={3}
                          inputLabel="Rationale"
                          placeholder="Rationale"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              {addDetailsData?.stockUniverseItemHistory?.length !== 0 && (
                <Grid item xs={12}>
                  <Typography
                    fontWeight={700}
                    fontSize={18}
                    color={theme.palette.primary.main}
                  >
                    Asset History
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Rationale</TableCell>
                          <TableCell>TargetPrice</TableCell>
                          <TableCell>StopLoss</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addDetailsData?.stockUniverseItemHistory?.map(
                          (dataItem: any) => (
                            <TableRow key={`a1D4ads${dataItem.date}`}>
                              <TableCell>{dataItem?.date}</TableCell>
                              <TableCell>
                                <Tooltip title={dataItem?.rationale}>
                                  <Typography
                                    fontSize={12}
                                    sx={{
                                      maxWidth: "90px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {dataItem?.rationale}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell>{dataItem?.targetPrice}</TableCell>
                              <TableCell>{dataItem?.stopLoss}</TableCell>
                              <TableCell>{dataItem?.status}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography color={theme?.palette.primary.main}>
                  Research Files
                </Typography>

                <Controller
                  name="research_files"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <DragDrop
                        uploadError={uploadError}
                        setUploadError={setUploadError}
                        acceptType=".pdf, .csv, .docx"
                        supportName="PDF, CSV & DOCX"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        )}
        <Grid container spacing={1} my={2}>
          {addDetailsData?.linkedVersions?.length !== 0 && (
            <Grid item xs={12}>
              <Typography
                fontWeight={700}
                fontSize={18}
                color={theme.palette.primary.main}
              >
                Linked Basket Version
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Basket Version</TableCell>
                      <TableCell>Weightage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addDetailsData?.linkedVersions?.map((dataItem: any) => (
                      <TableRow key={`a1D4ads${dataItem.itemId}`}>
                        <TableCell>{dataItem?.version}</TableCell>
                        <TableCell>{dataItem?.weightage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {addDetailsData?.researchFiles?.length !== 0 && (
            <Grid item xs={12}>
              <Typography
                fontWeight={700}
                fontSize={18}
                color={theme.palette.primary.main}
              >
                Research Files
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Uploaded By</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addDetailsData?.researchFiles?.map(
                      (dataItem: {
                        id: any;
                        file: any;
                        uploadedBy: any;
                        name: any;
                        createdOn: any;
                      }) => (
                        <TableRow key={`a1D4ads${dataItem.id}`}>
                          <TableCell>{dataItem?.name}</TableCell>
                          <TableCell>{dataItem?.uploadedBy}</TableCell>
                          <TableCell>
                            {matchDate(dataItem?.createdOn)}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              className="outline"
                              onClick={() => {
                                handleDeleteResearchFiles(dataItem.id);
                              }}
                            >
                              {researchFilesLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <FiTrash2 />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </GKSideModal>
      <GKModal
        open={isStatusModalOpen}
        setOpen={() => {
          setIsStatusModalOpen(false);
        }}
        modalTitle="Portfolio List"
        footer={
          <LoadingButton
            variant="contained"
            disabled={linkLoading}
            onClick={() => handleSubmitLinked(portfoliosStatus)}
            loading={linkLoading}
          >
            {"Submit"}
          </LoadingButton>
        }
      >
        {portfoliosStatus?.map((dataItem: any, index: number) => (
          <Grid container my={2} key={index}>
            <Grid
              item
              style={{
                background: theme.palette.light?.main,
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
              xs={6}
            >
              {dataItem.portfolioName}
            </Grid>
            <Grid
              item
              style={{ background: theme.palette.light?.main }}
              container
              xs={6}
            >
              <GKSelect
                onChange={(event) => {
                  setPortfoliosStatus(
                    // eslint-disable-next-line array-callback-return
                    portfoliosStatus?.map((item: any) => ({
                      ...item,
                      status:
                        item?.id === dataItem?.id
                          ? event?.target?.value
                          : item?.status,
                    }))
                  );
                }}
                value={dataItem.status}
                defaultValue={addDetailsData?.status}
                name="status"
              >
                <MenuItem style={{ display: "none" }} value="">
                  Select Status
                </MenuItem>
                <MenuItem value={"BUY"}>Buy</MenuItem>
                <MenuItem value={"HOLD BUY"}>Hold Buy</MenuItem>
                <MenuItem value={"HOLD SELL"}>Hold Sell</MenuItem>
                <MenuItem value={"EXIT"}>Exit</MenuItem>
              </GKSelect>
            </Grid>
          </Grid>
        ))}
      </GKModal>
    </>
  );
};

export default AddPortfolioDetailsModal;
