import { Grid, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const token = localStorage?.getItem("token");

  const navigate = useNavigate();

  return (
    <Grid
      container
      py={12}
      px={3}
      pt={"180px"}
      sx={{
        backgroundImage: `url(LandingPage/heroBg.svg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        px: {
          xs: 3,
          lg: 10,
        },
      }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        sx={{
          alignItems: {
            xs: "center",
            lg: "flex-start",
          },
        }}
      >
        <Typography
          textAlign={"left"}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
            color: " #CCC",
            fontSize: 12,
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "1.2px",
            textTransform: "uppercase",
          }}
        >
          Simplify • Streamline • Succeed
        </Typography>
        <Typography
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
          textAlign={"left"}
          fontSize={{
            xs: 28,
            lg: 44,
          }}
          fontWeight={800}
          lineHeight={"54px"}
        >
          Wealth Management
        </Typography>
        <Typography
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
            background:
              "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
            WebkitBackgroundClip: " text",
            WebkitTextFillColor: "transparent",
          }}
          fontSize={{
            xs: 28,
            lg: 44,
          }}
          fontWeight={800}
          lineHeight={"54px"}
        >
          with Superpowers, Powering growth to your wealth business
        </Typography>
        <Typography
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
          fontSize={{
            xs: 16,
            lg: 22,
          }}
          fontWeight={500}
          mt={3}
          mb={1}
        >
          A comprehensive wealth management solution{" "}
        </Typography>
        <Typography
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
          fontSize={16}
          fontWeight={500}
          color={"#999999"}
        >
          {`Whether you're a financial advisor looking to scale your business or
          an investor seeking a holistic view of your wealth, our suite of
          products has you covered.`}{" "}
        </Typography>
        <div>
          <GKButton
            sx={{
              width: "fit-content",
              mt: 4,
            }}
            variant="landingBtn"
            onClick={() => {
              navigate(!token ? "/login" : `/app/dashboard`);
            }}
          >
            {!token ? "Login / Register" : " Get Started"}
          </GKButton>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img width={"100%"} src="/LandingPage/infographics.svg" alt="" />
      </Grid>
    </Grid>
  );
};

export default HeroSection;
