/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import AnimationComponent from "components/AnimationComponent";
import ClickOutside from "components/ClickOutside";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKCommonTableFilterModal from "components/GKCommonTableFilterModal";
import GKTextField from "components/GKTextField";
import { Show } from "components/Show";
import SimpleLoader from "components/SimpleLoader";
import TableColumnModal from "components/TableColumnModal";
import usePortfolioHoldingDetails from "hooks/usePortfolioHoldingDetails";
import React from "react";
import { BsExclamationOctagon } from "react-icons/bs";
import { CgMathPlus } from "react-icons/cg";
import { IoFilter } from "react-icons/io5";
import { LuSearch, LuTable2 } from "react-icons/lu";
import { MdClose, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioHoldingDetails = () => {
  const { theme } = ThemeContainer();

  useDocumentTitle("Holding Details");

  type Order = "asc" | "desc";

  const {
    id,
    deletePortfolio,
    loading,
    openConfirmationModal,
    setOpenConfirmationModal,
    navigate,
    allHoldings,
    showNonZero,
    setShowNonZero,
    setSearchText,
    searchText,
    columnsPersist,
    dispatch,
    tableColumnModalOpen,
    setTableColumnModalOpen,
    order,
    setOrder,
    anchorEl,
    setAnchorEl,
    popperId,
    filterModal,
    setFilterModal,
    isRowOpened,
    setIsRowOpened,
    orderBy,
    setOrderBy,
    open,
    holdingTableParentRows,
  } = usePortfolioHoldingDetails();

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/portfolios/${+id}/holdings`}
        name={"portfolios-holdings"}
      />
    );
  };

  const isColumnHideable = (columnsPersist: any, columnId: string) =>
    columnsPersist?.["portfolioHoldingList"]?.[columnId];

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Company name", "companyName", 5),
      sortable: false,
      hideable: isColumnHideable(columnsPersist, "companyName"),
    },
    {
      ...extraColumnsField("Quantity", "rawQuantity", 200, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "rawQuantity"),
    },
    {
      ...extraColumnsField("Avg. Buy Price", "avgPrice", 150, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "avgPrice"),
    },
    {
      ...extraColumnsField("Invested Value", "investedAmount", 150, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "investedAmount"),
    },
    {
      ...extraColumnsField("CMP", "cmp", 110, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "cmp"),
    },
    {
      ...extraColumnsField("Current Value", "currentAmount", 140, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "currentAmount"),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 140, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "unrealisedGain"),
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "absoluteReturn"),
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "todaysGain"),
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "todaysGainPercentage"),
    },
    {
      ...extraColumnsField("Weightage(%)", "weightage", 130, 1),
      type: "number",
      hideable: isColumnHideable(columnsPersist, "weightage"),
      disableColumnMenu: true,
    },
  ];

  function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          {columns.map(
            (headCell) =>
              headCell.hideable && (
                <TableCell
                  key={headCell.field}
                  sortDirection={orderBy === headCell.field ? order : false}
                  align={headCell.type !== "number" ? "left" : "right"}
                >
                  <TableSortLabel
                    active={orderBy === headCell.field}
                    direction={orderBy === headCell.field ? order : "desc"}
                    onClick={createSortHandler(headCell.field)}
                  >
                    {headCell.headerName}
                  </TableSortLabel>
                </TableCell>
              )
          )}
        </TableRow>
      </TableHead>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 1;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        allHoldings,
        getComparator(order || "asc", orderBy || "companyName")
      ),
    [order, orderBy, allHoldings]
  );

  return (
    <>
      <Grid container spacing={1}>
        {allHoldings?.length !== 0 && (
          <Grid xs={12} item>
            <Grid container spacing={1}>
              <Grid item xs={8} gap={1} display={"flex"} alignItems={"center"}>
                <GKTextField
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <LuSearch color={theme.palette.grey[400]} />
                    ),
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  sx={{
                    width: "20%",
                  }}
                />
                <GKButton
                  style={{
                    padding: "6px 10px",
                    color: "#697586",
                    border: "solid 1px #E3E8EF",
                  }}
                  onClick={() => {
                    setFilterModal(true);
                  }}
                  startIcon={<IoFilter size={16} />}
                >
                  Filter
                </GKButton>
                <GKButton
                  style={{
                    padding: "6px 10px",
                    color: "#697586",
                    border: "solid 1px #E3E8EF",
                  }}
                  onClick={() => {
                    setTableColumnModalOpen(true);
                  }}
                  startIcon={<LuTable2 size={16} />}
                >
                  Columns
                </GKButton>
                {exportCsv && exportCsv()}
              </Grid>
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                gap={1}
                xs={4}
                justifyContent={"flex-end"}
              >
                <GKButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    navigate(`/app/create-transaction`, {
                      state: { id: id },
                    });
                  }}
                  startIcon={<CgMathPlus size={14} />}
                >
                  Add Trade
                </GKButton>
                <GKButton
                  variant="outlined"
                  color="dark"
                  onClick={() =>
                    navigate(`/app/holdings/portfolio/${id}/etf-transaction`)
                  }
                >
                  ETF Interest
                </GKButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {allHoldings?.length !== 0 && (
          <Grid xs={12} item>
            <AnimationComponent>
              {loading ? (
                <SimpleLoader />
              ) : (
                <TableContainer sx={{ maxHeight: "calc(100vh - 236px)" }}>
                  <Table stickyHeader>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      <TableRow
                        sx={{
                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                        style={{
                          background: theme.palette.grey[100],
                        }}
                        onClick={() => {
                          setIsRowOpened({
                            ...isRowOpened,
                            equity: !isRowOpened.equity,
                          });
                        }}
                      >
                        <TableCell
                          style={{
                            background: theme.palette.grey[100],
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MdOutlineKeyboardArrowRight
                              size={18}
                              style={{
                                transform: isRowOpened?.equity
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s",
                              }}
                            />
                            Equity{" "}
                            {`(${
                              visibleRows?.filter(
                                (item) =>
                                  item?.instType !== "MF" &&
                                  item?.instSubType === "NCE"
                              )?.length
                            })`}
                          </Typography>
                        </TableCell>
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.rawQuantity && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.avgPrice && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.investedAmount && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.equity
                                    ?.investedValue
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.cmp && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.currentAmount && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.equity
                                    ?.currentValue
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.unrealisedGain && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.equity
                                    ?.unrealisedGain >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.equity
                                    ?.unrealisedGain
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.absoluteReturn && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.equity
                                    ?.absoluteReturn >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {holdingTableParentRows?.data?.equity?.absoluteReturn?.toFixed(
                                  2
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGain && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.equity
                                    ?.todaysGain >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.equity
                                    ?.todaysGain
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGainPercentage && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.equity
                                    ?.todaysGainPercentage >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {holdingTableParentRows?.data?.equity?.todaysGainPercentage?.toFixed(
                                  2
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.weightage && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.equity
                                    ?.totalWeightage
                                )}
                              </Typography>
                            </TableCell>
                          )}
                      </TableRow>
                      {isRowOpened?.equity &&
                        visibleRows
                          ?.filter(
                            (item) =>
                              item?.instType !== "MF" &&
                              item?.instSubType === "NCE"
                          )
                          ?.map((dataItem: any, index) => {
                            return (
                              <TableRow
                                onClick={() => {
                                  navigate(
                                    `/app/holdings/${dataItem?.gcode}/transactions`,
                                    {
                                      state: {
                                        holdingId: id,
                                        gcode: dataItem?.gcode,
                                        name: dataItem?.name,
                                        quantity: dataItem?.rawQuantity,
                                        investedAmount:
                                          dataItem?.investedAmount,
                                        currentAmount: dataItem?.currentAmount,
                                        type: "portfolios",
                                      },
                                    }
                                  );
                                }}
                                sx={{
                                  ":hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                key={index}
                              >
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.companyName && (
                                    <TableCell align="left">
                                      <Typography
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={1}
                                      >
                                        {dataItem?.name}
                                        {!dataItem?.isValid &&
                                          Boolean(dataItem?.isin) && (
                                            <Tooltip title="There might be some discrepancies in the trades of this asset.">
                                              <IconButton
                                                color="error"
                                                sx={{
                                                  background: "transparent",
                                                  "&:hover": {
                                                    background: "transparent",
                                                    borderColor: "transparent",
                                                    color:
                                                      theme.palette.error.main,
                                                  },
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                <BsExclamationOctagon
                                                  size={14}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.rawQuantity && (
                                    <TableCell
                                      align="right"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                      }}
                                    >
                                      <Typography>
                                        {dataItem?.rawQuantity || 0}
                                      </Typography>
                                      {dataItem?.ltHoldingsCount > 0 && (
                                        <Tooltip
                                          title="Long Term Holding"
                                          arrow
                                          placement="top"
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "auto",
                                              color: theme.palette.primary.main,
                                              padding: 0,
                                              pr: 3,
                                            }}
                                          >
                                            <img
                                              src="/Logos/longTerm.svg"
                                              alt=""
                                            />
                                            &nbsp;
                                            {dataItem?.instSubType !== "CE" ||
                                            dataItem.isin === "INGridkeyCash"
                                              ? dataItem?.ltHoldingsCount
                                              : dataItem?.ltHoldingsCount}
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.avgPrice && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.avgBuyPrice
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.investedAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.investedAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.cmp && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.cmp)}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.currentAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.currentAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.unrealisedGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.unrealisedGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.unrealisedGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.absoluteReturn && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.absoluteReturn >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.absoluteReturn
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGainPercentage && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGainPercentage >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGainPercentage
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.weightage && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.weightage)}
                                    </TableCell>
                                  )}
                              </TableRow>
                            );
                          })}
                      <TableRow
                        sx={{
                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                        style={{
                          background: theme.palette.grey[100],
                        }}
                        onClick={() => {
                          setIsRowOpened({
                            ...isRowOpened,
                            mutualFund: !isRowOpened.mutualFund,
                          });
                        }}
                      >
                        <TableCell
                          style={{
                            background: theme.palette.grey[100],
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MdOutlineKeyboardArrowRight
                              size={18}
                              style={{
                                transform: isRowOpened?.mutualFund
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s",
                              }}
                            />
                            Mutual Funds{" "}
                            {`(${
                              visibleRows?.filter(
                                (item) => item?.instType === "MF"
                              )?.length
                            })`}
                          </Typography>
                        </TableCell>
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.rawQuantity && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.avgPrice && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.investedAmount && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.investedValue
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.cmp && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.currentAmount && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.currentValue
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.unrealisedGain && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.unrealisedGain >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.unrealisedGain
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.absoluteReturn && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.absoluteReturn >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {holdingTableParentRows?.data?.mutualFunds?.absoluteReturn?.toFixed(
                                  2
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGain && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.todaysGain >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.todaysGain
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGainPercentage && (
                            <TableCell align="right">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                color={
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.todaysGainPercentage >= 0
                                    ? theme.palette.success.main
                                    : theme.palette.error.main
                                }
                              >
                                {holdingTableParentRows?.data?.mutualFunds?.todaysGainPercentage?.toFixed(
                                  2
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.weightage && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.mutualFunds
                                    ?.totalWeightage
                                )}
                              </Typography>
                            </TableCell>
                          )}
                      </TableRow>
                      {isRowOpened?.mutualFund &&
                        visibleRows
                          ?.filter((item) => item?.instType === "MF")
                          ?.map((dataItem: any, index) => {
                            return (
                              <TableRow
                                onClick={() => {
                                  navigate(
                                    `/app/holdings/${dataItem?.gcode}/transactions`,
                                    {
                                      state: {
                                        holdingId: id,
                                        gcode: dataItem?.gcode,
                                        folioNo: dataItem?.folioNo,
                                        name: dataItem?.name,
                                        quantity: dataItem?.rawQuantity,
                                        investedAmount:
                                          dataItem?.investedAmount,
                                        currentAmount: dataItem?.currentAmount,
                                        type: "portfolios",
                                      },
                                    }
                                  );
                                }}
                                sx={{
                                  ":hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                key={index}
                              >
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.companyName && (
                                    <TableCell align="left">
                                      <Typography
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={1}
                                      >
                                        {dataItem?.name}{" "}
                                        {dataItem?.instType === "MF" &&
                                          dataItem.folioNo &&
                                          `- ${dataItem.folioNo}`}
                                        {!dataItem?.isValid &&
                                          Boolean(dataItem?.isin) && (
                                            <Tooltip title="There might be some discrepancies in the trades of this asset.">
                                              <IconButton
                                                color="error"
                                                sx={{
                                                  background: "transparent",
                                                  "&:hover": {
                                                    background: "transparent",
                                                    borderColor: "transparent",
                                                    color:
                                                      theme.palette.error.main,
                                                  },
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                <BsExclamationOctagon
                                                  size={14}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.rawQuantity && (
                                    <TableCell
                                      align="right"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                      }}
                                    >
                                      <Typography>
                                        {dataItem?.rawQuantity || 0}
                                      </Typography>
                                      {dataItem?.ltHoldingsCount > 0 && (
                                        <Tooltip
                                          title="Long Term Holding"
                                          arrow
                                          placement="top"
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "auto",
                                              color: theme.palette.primary.main,
                                              padding: 0,
                                              pr: 3,
                                            }}
                                          >
                                            <img
                                              src="/Logos/longTerm.svg"
                                              alt=""
                                            />
                                            &nbsp;
                                            {dataItem?.instSubType !== "CE" ||
                                            dataItem.isin === "INGridkeyCash"
                                              ? dataItem?.ltHoldingsCount
                                              : dataItem?.ltHoldingsCount}
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.avgPrice && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.avgBuyPrice
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.investedAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.investedAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.cmp && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.cmp)}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.currentAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.currentAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.unrealisedGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.unrealisedGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.unrealisedGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.absoluteReturn && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.absoluteReturn >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.absoluteReturn
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGainPercentage && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGainPercentage >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGainPercentage
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.weightage && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.weightage)}
                                    </TableCell>
                                  )}
                              </TableRow>
                            );
                          })}

                      <TableRow
                        sx={{
                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                        style={{
                          background: theme.palette.grey[100],
                        }}
                        onClick={() => {
                          setIsRowOpened({
                            ...isRowOpened,
                            cash: !isRowOpened.cash,
                          });
                        }}
                      >
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.companyName && (
                            <TableCell
                              style={{
                                background: theme.palette.grey[100],
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                fontWeight={600}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <MdOutlineKeyboardArrowRight
                                  size={18}
                                  style={{
                                    transform: isRowOpened?.cash
                                      ? "rotate(90deg)"
                                      : "rotate(0deg)",
                                    transition: "transform 0.3s",
                                  }}
                                />
                                Cash & Cash Equivalent{" "}
                                {`(${
                                  visibleRows?.filter(
                                    (item) =>
                                      item?.instType !== "MF" &&
                                      item?.instSubType === "CE"
                                  )?.length
                                })`}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.rawQuantity && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.avgPrice && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.investedAmount && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.cmp && (
                            <TableCell />
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.currentAmount && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.ledger
                                    ?.availableCash
                                )}
                              </Typography>
                            </TableCell>
                          )}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.unrealisedGain && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.absoluteReturn && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGain && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]
                            ?.todaysGainPercentage && <TableCell />}
                        {columnsPersist &&
                          columnsPersist["portfolioHoldingList"]?.weightage && (
                            <TableCell align="right">
                              <Typography fontWeight={600} fontSize={14}>
                                {moneyCommaSeparator(
                                  holdingTableParentRows?.data?.ledger
                                    ?.totalWeightage
                                )}
                              </Typography>
                            </TableCell>
                          )}
                      </TableRow>
                      {isRowOpened?.cash &&
                        visibleRows
                          ?.filter(
                            (item) =>
                              item?.instType !== "MF" &&
                              item?.instSubType === "CE"
                          )
                          ?.map((dataItem: any, index) => {
                            return (
                              <TableRow
                                onClick={() => {
                                  if (dataItem?.isin === "INGridkeyCash")
                                    return;
                                  navigate(
                                    `/app/holdings/${dataItem?.gcode}/transactions`,
                                    {
                                      state: {
                                        holdingId: id,
                                        gcode: dataItem?.gcode,
                                        name: dataItem?.name,
                                        quantity: dataItem?.rawQuantity,
                                        investedAmount:
                                          dataItem?.investedAmount,
                                        currentAmount: dataItem?.currentAmount,
                                        type: "portfolios",
                                      },
                                    }
                                  );
                                }}
                                sx={{
                                  ":hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                key={index}
                              >
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.companyName && (
                                    <TableCell align="left">
                                      <Typography
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={1}
                                      >
                                        {dataItem?.name}
                                        {!dataItem?.isValid &&
                                          Boolean(dataItem?.isin) && (
                                            <Tooltip title="There might be some discrepancies in the trades of this asset.">
                                              <IconButton
                                                color="error"
                                                sx={{
                                                  background: "transparent",
                                                  "&:hover": {
                                                    background: "transparent",
                                                    borderColor: "transparent",
                                                    color:
                                                      theme.palette.error.main,
                                                  },
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                <BsExclamationOctagon
                                                  size={14}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.rawQuantity && (
                                    <TableCell
                                      align="right"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography>
                                        {dataItem?.rawQuantity
                                          ? dataItem?.rawQuantity
                                          : ""}
                                      </Typography>
                                      {dataItem?.ltHoldingsCount > 0 && (
                                        <Tooltip
                                          title="Long Term Holding"
                                          arrow
                                          placement="top"
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "auto",
                                              color: theme.palette.primary.main,
                                              padding: 0,
                                              pr: 3,
                                            }}
                                          >
                                            <img
                                              src="/Logos/longTerm.svg"
                                              alt=""
                                            />
                                            &nbsp;
                                            {dataItem?.instSubType !== "CE" ||
                                            dataItem.isin === "INGridkeyCash"
                                              ? dataItem?.ltHoldingsCount
                                              : dataItem?.ltHoldingsCount}
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.avgPrice && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.avgBuyPrice
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.investedAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.investedAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.cmp && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.cmp)}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.currentAmount && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(
                                        dataItem?.currentAmount
                                      )}
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.unrealisedGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.unrealisedGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.unrealisedGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.absoluteReturn && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.absoluteReturn >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.absoluteReturn
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGain && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGain >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGain
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.todaysGainPercentage && (
                                    <TableCell align="right">
                                      <Typography
                                        color={
                                          dataItem?.todaysGainPercentage >= 0
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                        }
                                      >
                                        {moneyCommaSeparator(
                                          dataItem?.todaysGainPercentage
                                        )}
                                      </Typography>
                                    </TableCell>
                                  )}
                                {columnsPersist &&
                                  columnsPersist["portfolioHoldingList"]
                                    ?.weightage && (
                                    <TableCell align="right">
                                      {moneyCommaSeparator(dataItem?.weightage)}
                                    </TableCell>
                                  )}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AnimationComponent>
          </Grid>
        )}
        {openConfirmationModal && (
          <ConfirmDeleteModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title="Portfolio"
            onDelete={() => deletePortfolio(Number(id))}
            isDelete
          />
        )}
      </Grid>
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          zIndex: 99999,
        }}
      >
        <ClickOutside onClose={() => setAnchorEl(null)}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",
              bgcolor: "background.paper",
              boxShadow: `-10px 10px 21px ${theme.palette.primary.main}44`,
              border: `solid 1px ${theme.palette.primary.main}33`,
              width: 350,
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: "-15px",
                right: "-14px",
              }}
              color="error"
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <MdClose />
            </IconButton>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography fontWeight={600} color={theme.palette.grey[700]}>
                  Columns & Other Filters
                </Typography>
                <Divider
                  style={{
                    margin: "4px 0",
                  }}
                />
                <br />
                <FormControlLabel
                  style={{
                    marginLeft: 2,
                  }}
                  control={
                    <Switch
                      onChange={(event) => {
                        setShowNonZero(event.target.checked);
                        setTimeout(() => {
                          setAnchorEl(null);
                        }, 200);
                      }}
                      checked={showNonZero}
                    />
                  }
                  labelPlacement="end"
                  label={
                    <b
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Show zero holding
                    </b>
                  }
                />
              </Grid>
              <Show.When isTrue={exportCsv}>
                <Grid item xs={12}>
                  <Typography fontWeight={600} color={theme.palette.grey[700]}>
                    Export Data
                  </Typography>
                  <Divider
                    style={{
                      margin: "4px 0",
                    }}
                  />
                  {exportCsv && exportCsv()}
                </Grid>
              </Show.When>
            </Grid>
          </Box>
        </ClickOutside>
      </Popper>

      <Show.When isTrue={filterModal}>
        <GKCommonTableFilterModal
          isModalOpen={filterModal}
          setIsModalOpen={setFilterModal}
          tableName={"portfolio_holding_list"}
          name={"portfolioHolding"}
          zeroHolding
        />
      </Show.When>
      <Show.When isTrue={tableColumnModalOpen}>
        <TableColumnModal
          open={tableColumnModalOpen}
          setOpen={setTableColumnModalOpen}
          columns={columns}
          columnVisibilityModel={columnsPersist}
          dispatch={dispatch}
          name={"portfolioHoldingList"}
        />
      </Show.When>
    </>
  );
};

export default PortfolioHoldingDetails;
