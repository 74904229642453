import { Grid, IconButton, Typography } from "@mui/material";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { LuPenSquare } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import AvailableMarginModal from "./AvailableMarginModal";

interface LedgerBalanceInterface {
  portfolioId: number;
  brokerLedgerBalance: number;
}

const AvailableCash = () => {
  useDocumentTitle("Ledger Balance");
  const navigate = useNavigate();

  const {
    state: { commonFilter },
  } = useAppContext();

  const [openMarginModal, setOpenMarginModal] = useState(false);
  const [ledgerBalanceData, setLedgerBalanceData] =
    useState<LedgerBalanceInterface>();

  const avlRequestBody = {
    take: commonFilter?.avlCashBalance?.take,
    skip: commonFilter?.avlCashBalance?.skip,
    sort: JSON.stringify(
      commonFilter?.avlCashBalance?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.avlCashBalance?.search || "",
    show_zero_holding: commonFilter?.avlCashBalance?.showNonZero,
    filter: JSON.stringify(commonFilter?.avlCashBalance?.customFilter),
  };

  const { data: portfolioCash, isFetching: portfolioLoading } = useFetchQuery({
    route: "/portfolios/avl_balance/",
    key: [QueryKeys.PORTFOLIO_CASH_LIST, avlRequestBody],
    requestBody: avlRequestBody,
  });

  const exportCsvForCashBalance = () => {
    return (
      <ExportCsv
        path={"/portfolios/avl_balance"}
        name={"CashBalanceList"}
        filter={JSON.stringify(commonFilter?.avlCashBalance?.customFilter)}
      />
    );
  };

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={portfolioCash?.data?.totalCount || 0}
        name="avlCashBalance"
      />
    );
  };

  return (
    <>
      <GKDataGrid
        rows={portfolioCash?.data?.data || []}
        maxHeight={"calc(100vh - 256px)"}
        loading={portfolioLoading}
        getRowId={(params) => params?.portfolioId}
        exportCsv={exportCsvForCashBalance}
        zeroHolding
        CustomPagination={!portfolioLoading && cusPagination}
        name="avlCashBalance"
        filterButton={false}
        tableName="available_cash_list"
        onRowClick={(params) => {
          navigate(`/app/nav/${params.row.portfolioId}/`, {
            state: params?.row,
          });
        }}
        columns={[
          {
            ...extraColumnsField("Portfolio", "clientName", 250, 1),
            renderCell: (params) => {
              return (
                <Grid
                  container
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    fontWeight={700}
                    maxWidth={"100%"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {params?.row?.clientName}
                  </Typography>
                  <Typography fontSize={12}>
                    {params?.row?.portfolioName} - {params?.row?.portfolioCode}
                  </Typography>
                </Grid>
              );
            },
          },
          {
            ...extraColumnsField(
              "Last Cash Entry Date",
              "lastCashDate",
              100,
              1
            ),
            valueGetter: (params) => matchDate(params?.row?.lastCashDate),
          },
          {
            ...extraColumnsField(
              "Available Balance",
              "availableLedgerBalance",
              150,
              1
            ),
            type: "number",
            valueFormatter: (params) =>
              moneyCommaSeparator(params?.value) || "0",
          },
          {
            ...extraColumnsField(
              "Available Margin",
              "brokerLedgerBalance",
              150,
              1
            ),
            type: "number",
            valueFormatter: (params) =>
              moneyCommaSeparator(params?.value) || "0",
          },
          {
            ...extraColumnsField("Actions", "action", 100, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setLedgerBalanceData(params?.row);
                  setOpenMarginModal(true);
                }}
              >
                <LuPenSquare size={18} />
              </IconButton>
            ),
          },
        ]}
        headerFilter={<TableFiltering name="avlCashBalance" />}
      />

      <Show.When isTrue={openMarginModal}>
        <AvailableMarginModal
          open={openMarginModal}
          setOpen={setOpenMarginModal}
          ledgerBalanceData={ledgerBalanceData}
        />
      </Show.When>
    </>
  );
};

export default AvailableCash;
