import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ExecutionSheetModal from "Pages/Settings/ExecutionSheetModal";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { IoIosSend } from "react-icons/io";
import { LuDownload } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import OrderPopOverActions from "./OrderPopOverActions";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";

const TotalOrders = () => {
  useDocumentTitle("Equity Total Order");

  const [showSheetModal, setShowSheetModal] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const { data, isLoading } = useFetchQuery({
    key: ["ORDER_MANAGER_MERGED"],
    route: `/model_portfolio/order-manager-merged/`,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "employeeName", 150, 1),
    },
    {
      ...extraColumnsField("Order Type", "orderType", 400, 1),
    },
    {
      ...extraColumnsField("Model Portfolio", "version", 150, 1),
    },
    {
      ...extraColumnsField("Order Date", "orderDate", 100, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.orderDate).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Order Time", "orderDateTime", 100, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.orderDate).format("hh:mm a")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "numPortfolios", 120, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (param) => param?.row?.numPortfolios || "-",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid container justifyContent={"flex-end"} gap={1} display={"flex"}>
            <OrderPopOverActions
              item={params.row}
              icon={<LuDownload size={20} />}
              type={["CSV", "ZIP"]}
            />
            <OrderPopOverActions
              item={params.row}
              icon={<IoIosSend size={20} />}
              type={["TRADE", "ORDER"]}
            />
          </Grid>
        );
      },
    },
  ];

  return (
    <DashboardLayout title="Total Orders">
      <Grid container>
        <Grid item xs={12}>
          <GKClientDataGrid
            name="totalOrders"
            columns={columns}
            loading={isLoading}
            rows={data?.data?.data || []}
            onRowClick={(params) => {
              navigate(
                `/app/equity-basket/${id}/order-manager/${params?.row?.id}/portfolios`,
                {
                  state: {
                    data: params?.row,
                  },
                }
              );
            }}
            headerComponent={
              <GKButton
                variant="contained"
                size="medium"
                onClick={() => {
                  setShowSheetModal(true);
                }}
              >
                Add Execution Sheet
              </GKButton>
            }
          />
        </Grid>
      </Grid>

      {showSheetModal && (
        <ExecutionSheetModal
          open={showSheetModal}
          setOpen={setShowSheetModal}
        />
      )}
    </DashboardLayout>
  );
};

export default TotalOrders;
