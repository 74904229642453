import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import DematModal from "./DematModal";

const DematInfoTab = ({ portfolioData }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_DEMAT_ACCOUNT],
    route: `/client/${portfolioData?.client}/demats/`,
  });

  const { mutate: handleAssignMutation, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${portfolioData?.id}/`, {
        ...portfolioData,
        dematAccount: data,
      }),
    onSuccess: () => {
      refetchQuery("PORTFOLIO_UPDATE_DATA_BY_ID");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <>
      <GKClientDataGrid
        name="demateInfo"
        density="compact"
        showFiltersButton={false}
        headerComponent={
          <GKButton
            variant="outlined"
            size="small"
            color="dark"
            onClick={() => {
              setOpenModal(true);
              setUpdateData(null);
            }}
          >
            Add Demat Account
          </GKButton>
        }
        loading={isLoading || isFetching}
        rows={data?.data?.dematAccounts || []}
        showFilters={false}
        onRowClick={(params) => {
          setOpenModal(true);
          setUpdateData(params.row);
        }}
        columns={[
          {
            ...extraColumnsField("Broker Name", "brokerName", 150, 1),
          },
          {
            ...extraColumnsField("Demat ID", "dematId", 150, 1),
          },
          {
            ...extraColumnsField("Depository", "depositary", 150, 1),
          },
          {
            ...extraColumnsField(
              "Unique Client Code",
              "uniqClientCode",
              100,
              1
            ),
          },
          {
            ...extraColumnsField("Assign", "assign", 100, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              return (
                params?.row?.id !== portfolioData?.dematAccount && (
                  <GKButton
                    variant="link"
                    color="dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssignMutation(params?.row?.id);
                    }}
                  >
                    Assign
                  </GKButton>
                )
              );
            },
          },
        ]}
      />
      {openModal && (
        <DematModal
          open={openModal}
          setOpen={setOpenModal}
          allAccounts={data?.data}
          updateData={updateData}
          clientId={portfolioData?.client}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default DematInfoTab;
