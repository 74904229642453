import { FormControl, Grid, IconButton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import GKButton from "components/GKButton";
import GKTextField from "components/GKTextField";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

const NomineePage = () => {
  const { id } = useParams<{ id: string }>();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [clientBankId, setClientBankId] = useState(0);

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.CLIENT_NOMINEE_ACCOUNT],
    route: `/client/${id}/nominees/`,
  });

  const { mutate: onSubmitHandler } = useMutation({
    mutationKey: ["UPDATE_CLIENT_BANK"],
    mutationFn: (data) => axiosInstance.patch(`/client/${id}/nominees/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CLIENT_NOMINEE"],
    mutationFn: () => axiosInstance.delete(`/client/${clientBankId}/nominees/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const form = useForm({
    defaultValues: {
      nominees: [
        {
          name: "",
          percentage: "",
          relationship: "",
        },
      ],
    },
    values: {
      nominees: data?.data?.nominees.length
        ? data?.data?.nominees.map((dataItem: any) => {
            return {
              ...dataItem,
              rowId: dataItem.id,
            };
          })
        : [
            {
              name: "",
              percentage: "",
              relationship: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  return (
    <>
      <form
        id="nominees-form"
        onSubmit={form.handleSubmit((values: any) => onSubmitHandler(values))}
      >
        {isFetching ? (
          <SimpleLoader />
        ) : (
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              {fields.map((bank: any, index: number) => (
                <Grid item container xs={12} spacing={1} my={1} key={index}>
                  <Grid item xs={3}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name={`nominees.${index}.name`}
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField {...field} inputLabel="Nominee Name" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name={`nominees.${index}.percentage`}
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            inputLabel="Nominee Percentage"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name={`nominees.${index}.relationship`}
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField {...field} inputLabel="Relationship" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    textAlign={"right"}
                    alignSelf={"center"}
                    mt={2}
                  >
                    <IconButton
                      color="error"
                      disabled={fields?.length === 1}
                      onClick={() => {
                        if (bank?.rowId) {
                          setClientBankId(bank?.rowId);
                          setConfirmationModal(true);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      <MdDeleteOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={() =>
                  append({
                    name: "",
                    percentage: "",
                    relationship: "",
                  })
                }
              >
                Add Nominee
              </GKButton>
              <GKButton type="submit" variant="contained" size="medium">
                Save
              </GKButton>
            </Grid>
          </Grid>
        )}
      </form>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation()}
          title={"Nominee"}
          isDelete
        />
      )}
    </>
  );
};

export default NomineePage;
