import { useParams } from "react-router-dom";
import { useFetchQuery } from "./useQueries";

export const useMeeting = () => {
  const { id } = useParams<string>();

  const {
    data: getAllLeadMeeting,
    isLoading: isLeadMeetingLoading,
    refetch,
  } = useFetchQuery({
    key: ["GET_ALL_LEAD_MEETINGS", id],
    retry: 0,
    route: `/lead/${id}/meetings/`,
  });

  return { getAllLeadMeeting, isLeadMeetingLoading, refetch };
};
