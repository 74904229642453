import { Alert, Card, Grid, Typography } from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import { ThemeContainer } from "utils/Theme";

interface Props {
  answers: any;
}

const RiskCard = (props: Props) => {
  const { answers } = props;
  const { theme } = ThemeContainer();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card
          style={{
            display: "flex",
            padding: 8,
          }}
        >
          <Grid
            item
            xs={9}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography fontSize={14} fontWeight={500}>
              Client Name
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={700}
              color={theme.palette.primary.main}
            >
              {answers?.name || "-"}
            </Typography>
          </Grid>
          {answers && answers?.response?.length === 0 ? (
            <Grid
              item
              xs={9}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Alert severity="warning">
                Please take the questionnaire to view risk score
              </Alert>
            </Grid>
          ) : (
            <Grid
              item
              xs={3}
              display={"flex"}
              justifyContent={"flex-end"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {answers && answers?.response?.length !== 0 && (
                <ReactSpeedometer
                  maxSegmentLabels={3}
                  height={150}
                  segmentColors={[
                    "rgb(90, 240, 91)",
                    "rgb(100, 240, 91)",
                    "rgb(120, 240, 91)",
                    "rgb(130, 240, 91)",
                    "rgb(140, 240, 91)",
                    "rgb(150, 240, 91)",
                    "rgb(160, 240, 91)",
                    "rgb(170, 240, 91)",
                    "rgb(180, 240, 91)",
                    "rgb(190, 240, 91)",
                    "rgb(200, 240, 91)",
                    "rgb(210, 240, 91)",
                    "rgb(220, 240, 91)",
                    "rgb(230, 240, 91)",
                    "rgb(240, 240, 91)",
                    "rgb(250, 240, 91)",
                    "rgb(255, 250, 91)",
                    "rgb(255, 240, 91)",
                    "rgb(255, 230, 91)",
                    "rgb(255, 220, 91)",
                    "rgb(255,210, 91)",
                    "rgb(255, 200, 91)",
                    "rgb(255, 190, 91)",
                    "rgb(255, 180, 91)",
                    "rgb(255, 170, 91)",
                    "rgb(255, 160, 91)",
                    "rgb(255, 150, 91)",
                    "rgb(255, 140, 91)",
                    "rgb(255, 130, 91)",
                    "rgb(255, 120, 91)",
                    "rgb(255, 110, 91)",
                    "rgb(255, 100, 91)",
                    "rgb(255, 90, 91)",
                  ]}
                  customSegmentLabels={[
                    {
                      text: "  ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      position: "OUTSIDE" as any,
                      color: theme.palette.dark.main,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                    {
                      text: " ",
                      color: theme.palette.dark.main,
                      position: "OUTSIDE" as any,
                    },
                  ]}
                  needleHeightRatio={0.7}
                  segments={33}
                  value={answers?.response[0]?.points}
                  maxValue={answers?.response[0]?.totalPoints}
                  currentValueText=" "
                />
              )}
              {answers && answers?.response?.length !== 0 && (
                <Typography fontSize={14} fontWeight={700}>
                  Risk Score{" "}
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: 800,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {answers?.response[0]?.points}
                  </span>
                </Typography>
              )}
              {answers && answers?.response?.length !== 0 && (
                <Typography fontSize={10} fontWeight={500}>
                  {`[Min Value: 0 - Max Value : ${
                    answers?.response[0]?.totalPoints || 0
                  }]`}
                </Typography>
              )}
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default RiskCard;
