import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import SecuritySection from "./SecuritySection";
import WealthSection from "./WealthSection";

const HomePage = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div ref={myRef} />
      <Grid container>
        <Grid item xs={12}>
          <HeroSection />
        </Grid>
        <Grid item xs={12}>
          <WealthSection />
        </Grid>

        <Grid item xs={12}>
          <SecuritySection />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default HomePage;
