/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import { Grid, Typography } from "@mui/material";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKTextField from "components/GKTextField";
import { handleCapitalize } from "utils/common";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

interface Props {
  companyWiseRebalanceData: any[];
  setCompanyWiseRebalanceData: any;
}

const StockWiseRebalanceOrders = (props: Props) => {
  const { companyWiseRebalanceData, setCompanyWiseRebalanceData } = props;

  return (
    <GKClientDataGrid
      name="stockWiseRebalanceOrders"
      pagination={false}
      pinnedColumns={{
        left: ["portfolioName"],
      }}
      showFiltersButton={false}
      showSearch={false}
      headerComponent={
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Company Name&nbsp;:&nbsp;
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {companyWiseRebalanceData[0]?.company}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              CMP&nbsp;:&nbsp;
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {companyWiseRebalanceData[0]?.cmp}
              </span>
            </Typography>
          </Grid>
        </Grid>
      }
      columns={[
        {
          ...extraColumnsField("Portfolio", "portfolioName", 240, 0),
          editable: false,
          renderCell: (params) => {
            const { portfolioName, portfolioCode } = params?.row;
            return (
              <Typography fontWeight={600}>
                {portfolioName}&nbsp;/&nbsp;{portfolioCode}
              </Typography>
            );
          },
        },
        {
          ...extraColumnsField("Model Weightage", "versionWeightage", 150, 1),
          editable: false,
          renderCell: (params) => {
            return (
              <Typography>
                {Number(params?.row?.versionWeightage)?.toFixed(2)}%
              </Typography>
            );
          },
        },
        {
          ...extraColumnsField(
            "Portfolio Weightage",
            "portfolioWeightage",
            100,
            1
          ),
          editable: false,
          valueFormatter: (value) => Number(value?.value || 0)?.toFixed(2),
        },
        {
          ...extraColumnsField("Deviation", "deviationPercent", 160, 1),
          editable: false,

          renderCell: (params) => {
            return (
              <Typography>
                {Number(params?.row?.deviationPercent)?.toFixed(2)}%
              </Typography>
            );
          },
        },
        // {
        //   field: "allocation",
        //   headerName: "Final Weightage",
        //   flex: 1,
        //   minWidth: 160,
        //   disableColumnMenu: true,
        //   editable: false,
        //   valueGetter: (value) => value.value,
        //   valueFormatter: (value) => Number(value?.value || 0)?.toFixed(2),
        // },
        {
          ...extraColumnsField("Transaction Type", "transaction type", 150, 1),
          valueFormatter: (params) => handleCapitalize(params?.value),
          editable: false,
        },
        {
          ...extraColumnsField("Ledger Balance", "ledgerBalance", 130, 1),
          editable: false,
          valueGetter: (value) => moneyCommaSeparator(value.value),
        },
        // {
        //   field: "quantity",
        //   headerName: "Deviation Qty.",
        //   valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
        //   flex: 1,
        //   valueGetter: (params) => params.row.quantity || "0",
        //   minWidth: 170,
        //   disableColumnMenu: true,
        //   editable: false,
        // },
        {
          ...extraColumnsField("Order Qty.", "newQuantity", 150, 0),
          editable: false,
          renderCell: (params) => {
            const { id, cmp, quantity } = params?.row;
            return (
              <GKTextField
                value={quantity}
                onChange={({ target }) => {
                  const newData = companyWiseRebalanceData
                    ?.map((dataItem) => {
                      return {
                        ...dataItem,
                        ...dataItem?.plan[0],
                      };
                    })
                    ?.map((data) => {
                      return {
                        ...data,
                        quantity:
                          data?.id === id ? target?.value : data?.quantity,
                        totalAmount:
                          data?.id === id
                            ? Number(target?.value) * Number(cmp)
                            : data?.totalAmount,
                      };
                    });
                  setCompanyWiseRebalanceData(newData);
                }}
              />
            );
          },
        },
        {
          ...extraColumnsField("Order Amount", "totalAmount", 150, 1),
          editable: false,
          valueGetter: (value) => moneyCommaSeparator(value.value),
        },
        {
          ...extraColumnsField(
            "After Trade Weightage",
            "afterTradeWeightage",
            150,
            1
          ),
          editable: false,
          renderCell: (params) => {
            return (
              <Typography>
                {Number(params?.row?.afterTradeWeightage)?.toFixed(2)}%
              </Typography>
            );
          },
        },
      ]}
      rows={companyWiseRebalanceData || []}
    />
  );
};

export default StockWiseRebalanceOrders;
