import {
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material";
import { paletteLightTheme } from "utils/ColorTheme";

interface Props {
  size?: number;
  thickness?: number;
  value?: number;
}

const GKStaticProgress = (props: Props & CircularProgressProps) => {
  const { size, thickness, value } = props;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: paletteLightTheme.palette.grey[200],
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.success.main,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={thickness}
        value={value}
        {...props}
      />
    </div>
  );
};

export default GKStaticProgress;
