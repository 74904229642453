/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { MdExpandMore } from "react-icons/md";
import { handleCapitalize } from "utils/common";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";

interface Props {
  responseData: any[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

const CompanyAllocationCreatOrder = (props: Props) => {
  const { responseData, form } = props;

  const handleReturnCloumn = (type: string) => {
    switch (type) {
      case "allocation":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio Name / Code",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            field: "portfolioWeightage",
            headerName: "Portfolio Weightage",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.portfolioWeightage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            field: "ledgerBalance",
            headerName: "Ledger Balance",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.ledgerBalance || 0)}
                </Typography>
              );
            },
          },
          {
            field: "transaction type",
            headerName: "Transaction Type",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => handleCapitalize(params?.value),
            disableColumnMenu: true,
            editable: false,
          },
          {
            field: "quantity",
            headerName: "Quantity",
            valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
            flex: 1,
            valueGetter: (params) => params.row.quantity || "0",
            minWidth: 100,
            disableColumnMenu: true,
            editable: false,
          },
          {
            field: "amount",
            headerName: "Trade Amount",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.amount)}{" "}
                </Typography>
              );
            },
          },
          {
            field: "afterTradeWeightage",
            headerName: "After Trade Weightage",
            minWidth: 200,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "exit":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio Name / Code",
            flex: 1,
            minWidth: 200,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            field: "portfolioWeightage",
            headerName: "Portfolio Weightage",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.portfolioWeightage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            field: "currentQuantity",
            headerName: "Holding Qty",
            minWidth: 130,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.currentQuantity)?.toFixed(0)}
                </Typography>
              );
            },
          },

          {
            field: "prevAmount",
            headerName: "Holding Amount",
            minWidth: 150,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(Number(params?.row?.prevAmount))}
                </Typography>
              );
            },
          },
          {
            field: "transaction type",
            headerName: "Transaction Type",
            minWidth: 150,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row["transaction type"])}{" "}
                </Typography>
              );
            },
          },
          {
            field: "allocation",
            headerName: "Exit Weightage",
            minWidth: 180,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.allocation)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            field: "quantity",
            headerName: "Exit Qty",
            minWidth: 100,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return <Typography>{params?.row?.quantity} </Typography>;
            },
          },
          {
            field: "amount",
            headerName: "Exit Amount",
            minWidth: 120,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.amount)}{" "}
                </Typography>
              );
            },
          },
          {
            field: "afterTradeLedgerBalance",
            headerName: "After Trade Ledger Balance",
            minWidth: 230,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
          {
            field: "afterTradeWeightage",
            headerName: "After Trade Weightage",
            minWidth: 200,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => Number(value.value).toFixed(2),
          },
        ] as GridColDef[];

      default:
        return [] as GridColDef[];
    }
  };

  return (
    <div style={{ margin: "8px 0" }}>
      {form?.getValues("allocationType") === "allocation" ? (
        responseData?.map((dataItem: any) => {
          return (
            <Accordion key={dataItem?.id}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontWeight={600} fontSize={14}>
                      {dataItem?.portfolioName}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={400} fontSize={12}>
                      {dataItem?.portfolioCode}
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <GKClientDataGrid
                  name="modelCompanyAllocationCreatOrder"
                  density="compact"
                  showFiltersButton={false}
                  columns={[
                    {
                      field: "company",
                      headerName: "Company Name",
                      minWidth: 180,
                      flex: 1,
                      disableColumnMenu: true,
                    },

                    {
                      field: "portfolioWeightage",
                      headerName: "Portfolio Weightage",
                      minWidth: 180,
                      flex: 1,
                      disableColumnMenu: true,
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {Number(params?.row?.portfolioWeightage)?.toFixed(
                              2
                            )}
                            %
                          </Typography>
                        );
                      },
                    },
                    {
                      field: "ledgerBalance",
                      headerName: "Ledger Balance",
                      minWidth: 180,
                      flex: 1,
                      disableColumnMenu: true,
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {moneyCommaSeparator(
                              params?.row?.ledgerBalance || 0
                            )}
                          </Typography>
                        );
                      },
                    },
                    {
                      field: "transaction type",
                      headerName: "Transaction Type",
                      flex: 1,
                      minWidth: 150,
                      valueFormatter: (params) =>
                        handleCapitalize(params?.value),
                      disableColumnMenu: true,
                      editable: false,
                    },
                    {
                      field: "quantity",
                      headerName: "Quantity",
                      valueFormatter: (params) =>
                        moneyCommaSeparatorPoint(params.value),
                      flex: 1,
                      valueGetter: (params) => params.row.quantity || "0",
                      minWidth: 100,
                      disableColumnMenu: true,
                      editable: false,
                    },
                    {
                      field: "amount",
                      headerName: "Trade Amount",
                      minWidth: 180,
                      flex: 1,
                      disableColumnMenu: true,
                      renderCell: (params) => {
                        return (
                          <Typography>
                            {moneyCommaSeparator(params?.row?.amount)}{" "}
                          </Typography>
                        );
                      },
                    },
                    {
                      field: "afterTradeWeightage",
                      headerName: "After Trade Weightage",
                      minWidth: 200,
                      flex: 1,
                      disableColumnMenu: true,
                      valueGetter: (value) => moneyCommaSeparator(value.value),
                    },
                  ]}
                  rows={
                    dataItem?.plan?.map((data: any, i: number) => {
                      return {
                        ...data,
                        index: i,
                        id: i,
                      };
                    }) || []
                  }
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <GKClientDataGrid
          name="modelCompanyAllocationCreatOrder"
          showFiltersButton={false}
          showSearch={false}
          headerComponent={
            <Show.When
              isTrue={form.getValues("allocationType") === "rebalance"}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    Company Name:
                    <Typography fontWeight={600}>
                      {responseData[0]?.plan[0]?.company || "-"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize={14}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    CMP:
                    <Typography fontWeight={600}>
                      {responseData[0]?.plan[0]?.cmp || "-"}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Show.When>
          }
          columns={handleReturnCloumn(form?.getValues("allocationType")) || []}
          rows={
            responseData?.map((data: any, i: number) => {
              return {
                ...data,
                ...data?.plan[0],
                index: i,
              };
            }) || []
          }
        />
      )}
    </div>
  );
};

export default CompanyAllocationCreatOrder;
