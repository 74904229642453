import {
  Divider,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKDatePicker from "components/GKDatePicker";
import GKSearchSelect from "components/GKSearchSelect";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { sumBy } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { numberToCurrency } from "utils/MathFunction";
import { schema } from "utils/validationSchema";

const EquityFormHeader = () => {
  const form = useFormContext();

  const { state } = useLocation();
  const { id } = useParams();

  const {
    state: { portfolios },
  } = useAppContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Controller
          name="portfolio"
          control={form.control}
          rules={{
            required: {
              value: !Number(state?.id),
              message: "Please select portfolio",
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                {!!id || !!state?.id ? (
                  <FormControl fullWidth variant="standard">
                    <GKTextField
                      inputLabel="Select Portfolio"
                      disabled
                      value={
                        portfolios?.find(
                          (data: any) => +data?.value === +field.value
                        )?.name
                      }
                    />
                  </FormControl>
                ) : (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Select Portfolio"
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    options={portfolios || []}
                    getOptionLabel={(option) => option?.name || option}
                    onChange={(event, val) => field.onChange(val?.value)}
                    value={
                      portfolios?.find(
                        (data: any) => +data?.value === +field.value
                      )?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Portfolio"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="date"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please select date",
              },
              validate: async (value) => {
                try {
                  await schema("date").validate({
                    date: dayjs(value).format("YYYY-MM-DD"),
                  });
                } catch (error: any) {
                  return error.message;
                }

                return {};
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKDatePicker
                requiredField
                inputLabel="Trade Date"
                name="date"
                value={dayjs(field.value)}
                onChange={(value) => {
                  field.onChange(dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="brokerName"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Broker Name" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="contractNoteNum"
            control={form.control}
            render={({ field }) => (
              <GKTextField {...field} inputLabel="Contract Note Number" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    background: "#F8FAFC",
                    padding: 16,
                    borderBottom: "none",
                    borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={"#9AA4B2"}
                    >
                      Total Brokerage
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions"),
                          (data: any) => +data?.brokerage || 0
                        )
                      )}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: 16,
                    background: "#F8FAFC",
                    borderBottom: "none",
                    borderRight: `1px solid ${paletteLightTheme.palette.border.main}`,
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={"#9AA4B2"}
                    >
                      Total Other Charges
                    </Typography>

                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions"),
                          (data: any) => +data?.totalTransactionCharges || 0
                        )
                      )}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: 16,
                    background: "#F8FAFC",
                    borderBottom: "none",
                  }}
                >
                  <div
                    style={{
                      gap: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color={"#9AA4B2"}
                    >
                      Total Amount
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {numberToCurrency(
                        sumBy(
                          form.getValues("transactions"),
                          (dataItem: any) => {
                            return ["SELL", "BUY_BACK"].includes(
                              dataItem.transactionType
                            )
                              ? Number(dataItem?.quantity) *
                                  Number(dataItem?.price) -
                                  (Number(dataItem?.totalTransactionCharges) +
                                    Number(dataItem?.brokerage))
                              : Number(dataItem?.quantity) *
                                  Number(dataItem?.price) +
                                  (Number(dataItem?.totalTransactionCharges) +
                                    Number(dataItem?.brokerage));
                          }
                        )
                      )}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default EquityFormHeader;
