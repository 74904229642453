import {
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useAppContext } from "AppContext";
import GKButton from "components/GKButton";
import dayjs from "dayjs";
import { useState } from "react";
import { LuCalendarClock, LuStickyNote } from "react-icons/lu";
import { MdArrowForward } from "react-icons/md";
import { PiHandsClappingLight, PiTagSimple } from "react-icons/pi";
import { paletteLightTheme } from "utils/ColorTheme";
import { getHexaColor } from "utils/constants";
import { matchDate } from "utils/helpers";

interface LeadObject {
  id: number;
  activityType: string;
  idOfTask: string;
  data: {
    data: {
      id: number;
      city: string;
      name: string;
      tags: number[];
      email: string;
      state: string;
      branch: number;
      address: string;
      country: string;
      pincode: string;
      position: string;
      hasNotes: boolean;
      tagsList: {
        id: number;
        tag: string;
        branch: number;
        createdAt: string;
        updatedAt: string;
      }[];
      createdAt: string;
      leadValue: number;
      panNumber: string;
      updatedAt: string;
      description: string;
      hasMeeting: boolean;
      leadStatus: number;
      notesCount: number;
      riskStatus: string;
      companyName: string;
      countryCode: string;
      phoneNumber: string;
      meetingCount: number;
      lastContacted: string;
      sourceOfLead: number;
      leadStatusStr: string;
      leadAssignedTo: number;
      conversionStatus: boolean;
      sourceOfLeadStr: string;
      leadAssignedToStr: string;
    };
    current: string;
    message: string;
    previous: string;
  };
  action: string;
  createdAt: string;
  updatedAt: string;
  lead: number;
}

enum ActivityType {
  MEETING = "Meeting",
  NOTES = "Notes",
  STATUS = "Status",
  CONVERSION = "Conversion",
}

const LeadLogs = ({ data }: any) => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleReturnTitle = (item: any) => {
    if (item.activityType === ActivityType.STATUS) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={600}>
            {item.data.data.leadAssignedToStr || "-"}
          </Typography>
          <Typography color={"#697586"} fontWeight={14}>
            Changed status from
          </Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.MEETING) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={600}>
            {item.data.data.leadAssignedToStr || "-"}
          </Typography>
          <Typography color={"#697586"} fontWeight={14}>
            added{" "}
          </Typography>
          <Typography fontWeight={600}>Meeting</Typography>
          <Typography fontWeight={600}>{item.data.data.note}</Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.NOTES) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={600}>
            {item.data.data.leadAssignedToStr || "-"}
          </Typography>
          <Typography color={"#697586"} fontWeight={14}>
            added{" "}
          </Typography>
          <Typography fontWeight={600}>Notes</Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.CONVERSION) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={600}>
            {item.data.data.leadAssignedToStr || "-"}
          </Typography>
          <Typography color={"#697586"} fontWeight={14}>
            Converted
          </Typography>
          <Typography fontWeight={600}>Lead to Client</Typography>
        </div>
      );
    }

    return "-";
  };

  const handleReturnSummary = (item: LeadObject) => {
    if (item.activityType === ActivityType.STATUS) {
      return (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography
              fontWeight={500}
              color={getHexaColor(`${item.data.previous}`)}
            >
              {item.data.previous || "-"}
            </Typography>
            <MdArrowForward color="#9AA4B2" />
            <Typography
              fontWeight={500}
              color={getHexaColor(`${item.data.current}`)}
            >
              {item.data.current || "-"}
            </Typography>
          </div>
          <Typography fontWeight={500} color={"#9AA4B2"} fontSize={10}>
            {matchDate(item?.updatedAt) || "-"} of{" "}
            {dayjs(item?.updatedAt).format(`h:mm A`)}
          </Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.MEETING) {
      return (
        <div
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <GKButton
            variant="link"
            onClick={() => {
              dispatch({
                type: "TAB",
                data: { ...tab, leadDetailsTabs: 1 },
              });
            }}
          >
            View Meeting
          </GKButton>
          <Typography fontWeight={500} color={"#9AA4B2"} fontSize={10}>
            {matchDate(item?.updatedAt) || "-"} of{" "}
            {dayjs(item?.updatedAt).format(`h:mm A`)}
          </Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.NOTES) {
      return (
        <div
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <GKButton
            variant="link"
            onClick={() => {
              dispatch({
                type: "TAB",
                data: { ...tab, leadDetailsTabs: 2 },
              });
            }}
          >
            View Notes
          </GKButton>
          <Typography fontWeight={500} color={"#9AA4B2"} fontSize={10}>
            {matchDate(item?.updatedAt) || "-"} of{" "}
            {dayjs(item?.updatedAt).format(`h:mm A`)}
          </Typography>
        </div>
      );
    }
    if (item.activityType === ActivityType.CONVERSION) {
      return (
        <div
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography fontWeight={500} color={"#9AA4B2"} fontSize={10}>
            {matchDate(item?.updatedAt) || "-"} of{" "}
            {dayjs(item?.updatedAt).format(`h:mm A`)}
          </Typography>
        </div>
      );
    }

    return " ";
  };

  const handleReturnIcons = (item: any) => {
    if (item.activityType === ActivityType.STATUS) {
      return <PiTagSimple color="#697586" />;
    }
    if (item.activityType === ActivityType.MEETING) {
      return <LuCalendarClock color="#697586" />;
    }
    if (item.activityType === ActivityType.NOTES) {
      return <LuStickyNote color="#697586" />;
    }
    if (item.activityType === ActivityType.CONVERSION) {
      return <PiHandsClappingLight color="#697586" />;
    }
    return <PiTagSimple color="#697586" />;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography fontWeight={600} fontSize={16}>
            Status Logs
          </Typography>
        </Grid>
        {/* <Grid item xs={6} textAlign={"right"}>
          <GKButton
            style={{
              padding: "6px 10px",
              color: "#697586",
              border: "1px solid #E3E8EF",
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            startIcon={<IoFilter size={16} />}
          >
            Filter
          </GKButton>
        </Grid> */}
        <Grid item xs={12} maxHeight={`calc(100vh - 270px)`} overflow={"auto"}>
          <Stepper orientation="vertical">
            {data?.map((step: any) => (
              <Step key={step.activityType} active>
                <StepLabel
                  sx={{ padding: 0 }}
                  icon={
                    <div
                      style={{
                        padding: 6,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `solid 1px ${paletteLightTheme.palette.border.main}`,
                        borderRadius: 100,
                      }}
                    >
                      {handleReturnIcons(step)}
                    </div>
                  }
                >
                  {handleReturnTitle(step)}
                </StepLabel>
                <StepContent>{handleReturnSummary(step)}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: {
            width: "12%",
            boxShadow: ` 4px 2px 8px 0px rgba(0, 0, 0, 0.13)`,
            borderRadius: 8,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container sx={{ py: "6px" }}>
          <Grid
            item
            xs={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
            py={1}
            px={2}
          >
            <Typography fontWeight={400} fontSize={12} color={"#9AA4B2"}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} flexDirection={"column"}>
            <FormControlLabel control={<Checkbox />} label="All" />
            <FormControlLabel control={<Checkbox />} label="Status" />
            <FormControlLabel control={<Checkbox />} label="Notes" />
            <FormControlLabel control={<Checkbox />} label="Meetings" />
          </Grid>
          <Grid
            item
            xs={12}
            borderTop={`solid 1px ${paletteLightTheme.palette.border.main}`}
            py={1}
            px={2}
            display={"flex"}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <GKButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              Reset
            </GKButton>
            <GKButton
              variant="contained"
              size="small"
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              Done
            </GKButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default LeadLogs;
