import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import StatusField from "components/StatusField";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const CompliancePage = () => {
  const {
    state: { tab, commonFilter },
    dispatch,
  } = useAppContext();

  const navigate = useNavigate();

  useDocumentTitle("Onboarding");

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.compliance?.take,
    skip: commonFilter?.compliance?.skip,
    sort: JSON.stringify(
      commonFilter?.compliance?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.compliance?.search || "",
  };

  const {
    data: complianceData,
    isFetching,
    isLoading,
  } = useFetchQuery({
    key: [QueryKeys.COMPLIANCE_LIST, requestBody],
    route: "/client/compliance/",
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={complianceData?.data.totalCount || 0}
        name="compliance"
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv path={"/client/compliance"} name={"ClientComplianceList"} />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "clientName", 250),
      renderCell: (params) => {
        return (
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography>{params?.row?.clientCode}</Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Risk Profile", "riskScore", 150),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.riskScore || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("KYC", "kycStatus", 150),
      renderCell: (props) => {
        return (
          <StatusField statusString={props?.row?.kycStatus || "Pending"} />
        );
      },
    },
    {
      ...extraColumnsField("Agreement", "agreementStatus", 150),
      renderCell: (props) => {
        return (
          <StatusField
            statusString={props?.row?.agreementStatus || "Pending"}
          />
        );
      },
    },
    {
      ...extraColumnsField("Actions", "action", 200),
      align: "right",
      headerAlign: "right",
      renderCell: (props) => {
        return (
          <Grid container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, complianceTabs: 2, clientERMPageTabs: 4 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              Risk
            </GKButton>
            &nbsp;
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, clientERMPageTabs: 4 },
                });
                dispatch({
                  type: "TAB",
                  data: { ...tab, complianceTabs: 0, clientERMPageTabs: 4 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              KYC
            </GKButton>
            &nbsp;
            <GKButton
              variant="link"
              onClick={() => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, clientERMPageTabs: 4, complianceTabs: 1 },
                });
                navigate(`/app/clients/${props.row.id}`, {
                  state: { name: props.row?.clientName },
                });
              }}
            >
              Agreement
            </GKButton>
          </Grid>
        );
      },
    },
  ];

  return (
    <GKDataGrid
      loading={isFetching}
      searchPlaceholder="Search for Compliance"
      rows={complianceData?.data?.data || []}
      columns={columns}
      CustomPagination={!isLoading && cusPagination}
      filterButton={false}
      exportCsv={exportCsv}
      name="compliance"
    />
  );
};

export default CompliancePage;
