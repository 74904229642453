import { FormControl, Grid, TextField } from "@mui/material";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKSearchSelect from "components/GKSearchSelect";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type FormValues = {
  partner: any[];
  fromDate: string;
  toDate: string;
};

const CreateCalculationCommissionModal = (props: Props) => {
  const { open, setOpen } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      partner: [],
      fromDate: "",
      toDate: "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Create Calculation Commission"
      width={"50%"}
      footer={
        <GKButton
          type="submit"
          variant="contained"
          size="medium"
          form="calculation-commission-form"
        >
          Save
        </GKButton>
      }
    >
      <form>
        <Grid container spacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <GKSearchSelect
              multiple
              disableClearable={false}
              disableCloseOnSelect
              limitTags={5}
              inputLabel="Partner"
              // loading={portFolioFetching}
              options={[]}
              // onChange={(e, val) => {
              //   // const selectedIds = val.map((option: any) => option.value);
              // }}
              value={[]}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="partner"
                  placeholder={
                    form.getValues("partner").length <= 0 && "Select Portfolio"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <GKDatePicker
                inputLabel="From Date"
                name="fromDate"
                value={dayjs(form.getValues("fromDate"))}
                onChange={(value) => {
                  form.setValue("fromDate", dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <GKDatePicker
                inputLabel="To Date"
                name="toDate"
                value={dayjs(form.getValues("toDate"))}
                onChange={(value) => {
                  form.setValue("toDate", dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default CreateCalculationCommissionModal;
