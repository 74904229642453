import { Skeleton, Typography, TypographyProps } from "@mui/material";

interface Props {
  loading?: boolean;
}

const GKTypography = (props: TypographyProps & Props) => {
  const { children, loading } = props;

  return loading ? (
    <Skeleton width={"100%"} />
  ) : (
    <Typography {...props}>{children}</Typography>
  );
};

export default GKTypography;
