import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useAppContext } from "AppContext";
import PortfolioList from "Pages/Portfolio/PortfolioList";
import PortfolioGroupList from "Pages/PortfolioGrouping/PortfolioGroupList";
import HoldingsSummary from "Pages/PortfolioSummary";
import EquityCashSection from "Pages/PortfolioSummary/EquityCashSection";
import AnimationComponent from "components/AnimationComponent";
import { Show } from "components/Show";
import DashboardLayout from "layouts/DashboardLayout";

const DashboardMenu = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  return (
    <DashboardLayout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                <Tabs
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      backgroundColor: "#fff",
                    },
                  }}
                  onChange={(e, val) => {
                    dispatch({
                      type: "TAB",
                      data: { ...tab, tabValueDashboard: val },
                    });
                  }}
                  value={tab?.tabValueDashboard}
                >
                  <Tab label="Summary" disableRipple />
                  <Tab label="Portfolio" disableRipple />
                  <Tab label="Portfolio Group" disableRipple />
                </Tabs>
              </Grid>
              <Show.When isTrue={tab?.tabValueDashboard !== 2}>
                <Grid item xs={8} textAlign={"right"}>
                  <EquityCashSection />
                </Grid>
              </Show.When>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Show.When isTrue={tab?.tabValueDashboard === 0}>
            <AnimationComponent>
              <HoldingsSummary />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueDashboard === 1}>
            <AnimationComponent>
              <PortfolioList />
            </AnimationComponent>
          </Show.When>
          <Show.When isTrue={tab?.tabValueDashboard === 2}>
            <AnimationComponent>
              <PortfolioGroupList />
            </AnimationComponent>
          </Show.When>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default DashboardMenu;
