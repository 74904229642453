/* eslint-disable react/no-unstable-nested-components */
import { Grid } from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";

const ModelPortfolioTimeLine = () => {
  return (
    <DashboardLayout title="Equity Basket Time Line">
      <Grid container>
        <Grid item xs={12} height={470} mb={2}>
          {" "}
          asd
        </Grid>
        <Grid item xs={12}>
          asd
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ModelPortfolioTimeLine;
