export enum QueryKeys {
  DASHBOARD_CARD_SUMMARY = "dashboardCardSummary",
  HOLDINGS_LIST = "holdingsList",
  TRANSACTIONS_LIST = "transactionsList",
  PORTFOLIOS_LIST = "portfoliosList",
  CLIENTS_LIST = "clientsList",
  CLIENTS_COMPLIANCE_LIST = "clientComplianceList",
  COMPANY_NAMES = "companyNames",
  COMPANY_LIST = "companyList",
  MODEL_PORTFOLIO_LIST = "modelPortfolioList",
  MODEL_PORTFOLIO_MAPPING_LIST = "modelPortfolioMappingList",
  UPLOAD_MANAGER_LIST = "uploadManagerList",
  INSIGHTS_HOLDINGS = "insightsHoldings",
  BRANCH_LIST = "branchList",
  EMPLOYEE_LIST = "employeeList",
  NIFTY_SENSEX = "nifty_sensex",
  DASHBOARD_HIGHCHARTS_LINE = "dashboardHighchartsLine",
  CASH_TRANSACTIONS_LIST = "cashTransactionsList",
  PORTFOLIO_CASH_LIST = "portfolioCashList",
  FAMILY_LIST = "familyList",
  BROKERS_LIST = "brokersList",
  PAGINATED_TABLE = "paginatedTable",
  UPLOAD_MANAGER_SELECT_OPTIONS = "uploadManagerSelectOptions",
  DASHBOARD_AUMS = "dashboardAUMs",
  DASHBOARD_MODEL_MULTI_LINE = "dashboardModelMultiLine",
  DASHBOARD_VERTICAL_BAR = "dashboardVerticalBar",
  DASHBOARD_AVAILABLE_CASH = "dashboardAvailableCash",
  PORTFOLIO_TRANSACTION_LIST = "portfolioTransactionList",
  CLIENTS_SHORT_DATA = "clientsShortData",
  PORTFOLIOS_SHORT_DATA = "portfoliosShortData",
  PORTFOLIO_NAV_LIST = "portfolioNavList",
  ASSOCIATES_LIST = "associatesList",
  PORTFOLIO_SUMMARY_CARDS_DATA = "portfolioSummaryCardsData",
  PORTFOLIO_SUMMARY_NAV_DATA = "portfolioSummaryNavData",
  BRANCH_SHORT_DATA = "branchShortData",
  COMPANY_INFO = "companyInfo",
  DEFAULT_PERMISSIONS = "defaultPermissions",
  LEAD_LIST = "leadList",
  MEETING_LIST = "meetings",
  NOTES_LIST = "notes",
  CORPORATE_ACTIONS_LIST = "corporateActionsList",
  UPLOAD_MANAGER_FAVORITE_SAMPLES = "uploadManagerFavoriteSamples",
  PORTFOLIO_SHARING_NUMBERS = "portfolioSharingNumbers",
  PORTFOLIO_OVERVIEW = "portfolioOverview",
  ACCOUNTS_LIST = "accountsList",
  PORTFOLIO_GROUP_LIST = "portfolioGroupList",
  HOLDINGS_SUMMARY_LINE_CHART_DATA = "holdingsSummaryChartData",
  INSIGHTS_HOLDINGS_DETAILS = "insightsHoldingsDetails",
  PARTNER_LIST = "partnerList",
  REPORTS_LIST = "reportsList",
  AGREEMENT_LIST = "agreements",
  CARD_SUMMARY = "cardSummary",
  CLIENT_HOLDINGS = "clientHoldings",
  FAMILY_SUMMARY = "familySummary",
  FAMILY_HOLDINGS = "familyHoldings",
  FAMILY_CLIENT_LIST = "familyClintList",
  BRANCH_SUMMARY = "branchSummary",
  BRANCH_CLIENT = "branchClient",
  BRANCH_PORTFOLIO = "branchPortfolio",
  COMPLIANCE_LIST = "complianceList",
  KYC_CLIENT = "kycClient",
  CLIENT_DETAILS = "clientDetails",
  VIEW_QUESTIONNAIRE = "viewQuestionnaire",
  CLIENT_ACCESS = "clientAccess",
  PORTFOLIO_ACCESS = "portfolioAccess",
  FAMILY_ACCESS = "familyAccess",
  GET_STATUS = "getStatus",
  GET_SOURCE = "getSource",
  NOTE_TYPE = "noteType",
  LEAD_MEETING = "leadMeeting",
  GET_LEAD = "getLead",
  STATUS_LOG = "statusLog",
  EMPLOYEE_PERMISSION = "employeePermission",
  BRANCH_PERMISSION = "barnchPermission",
  CLIENT_PERMISSION = "clientPermission",
  PARTNER_PERMISSION = "partnerPermission",
  DEFAULT_CLIENT = "defaultClient",
  DEFAULT_BRANCH = "defaultBranch",
  DEFAULT_PARTNER = "defaultPartner",
  DEFAULT_EMPLOYEE = "defaultEmployee",
  TRANSACTION_UPLOAD_ARCHIVE = "TRANSACTION_UPLOAD_ARCHIVE",
  CLIENT_BANK_ACCOUNT = "CLIENT_BANK_ACCOUNT",
  CLIENT_DEMAT_ACCOUNT = "CLIENT_DEMAT_ACCOUNT",
  CLIENT_NOMINEE_ACCOUNT = "CLIENT_NOMINEE_ACCOUNT",
  PORTFOLIO_BANK_ACCOUNT = "PORTFOLIO_BANK_ACCOUNT",
  PORTFOLIO_DEMAT_ACCOUNT = "PORTFOLIO_DEMAT_ACCOUNT",
  PORTFOLIO_NOMINEE_ACCOUNT = "PORTFOLIO_NOMINEE_ACCOUNT",
  RIGHT_ISSUE_LIST = "RIGHT_ISSUE_LIST",
  APPLY_RIGHT_ISSUE = "APPLY_RIGHT_ISSUE",
  EDIT_RIGHT_ISSUE = "EDIT_RIGHT_ISSUE",
  LISTING_QUERY = "LISTING_QUERY",
  HOLDING_SUMMARY = "HOLDING_SUMMARY",
  HOLDINGS_DETAILS = "HOLDINGS_DETAILS",
  PREFERENCES = "PREFERENCES",
  HOLDING_DETAILS = "HOLDING_DETAILS",
  EMPLOYEE_ACCESS = "EMPLOYEE_ACCESS",
  COMPANY_TRANSACTION_SUMMARY = "COMPANY_TRANSACTION_SUMMARY",
  GET_ALL_PORTFOLIOS = "GET_ALL_PORTFOLIOS",
  GET_ALL_FAMILIES = "GET_ALL_FAMILIES",
  GET_ALL_CLIENTS = "GET_ALL_CLIENTS",
  GET_ALL_IMPORT_FILES = "GET_ALL_IMPORT_FILES",
  GET_ALL_PORTFOLIO_GROUP = "GET_ALL_PORTFOLIO_GROUP",
  GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES",
  GET_ALL_INSTRUMENT_COMPANIES = "GET_ALL_INSTRUMENT_COMPANIES",
  GET_ALL_BRANCHES = "GET_ALL_BRANCHES",
  GET_ALL_LEADS = "GET_ALL_LEADS",
  GET_ALL_BANK_LEDGER = "GET_ALL_BANK_LEDGER",
  GET_ALL_TRADE_BOOK = "GET_ALL_TRADE_BOOK",
  GET_ALL_REPORTS = "GET_ALL_REPORTS",
  GET_ALL_EQUITY_BASKET = "GET_ALL_EQUITY_BASKET",
  GET_USER_DETAIL = "GET_USER_DETAIL",
  GET_ALL_NOTES_LISTS = "GET_ALL_NOTES_LISTS",
  RECON_MANAGER_LIST = "RECON_MANAGER_LIST",
  MUTUAL_FUND_LIST = "MUTUAL_FUND_LIST",
  ADD_MUTUAL_FUND_ENTRY = "ADD_MUTUAL_FUND_ENTRY",
  UPDATE_MUTUAL_FUND_ENTRY = "UPDATE_MUTUAL_FUND_ENTRY",
  DELETE_MUTUAL_FUND_ENTRY = "DELETE_MUTUAL_FUND_ENTRY",
  INCOME_EXPENSE_LIST = "INCOME_EXPENSE_LIST",
  BANK_LEDGER_LIST = "BANK_LEDGER_LIST",
  PORTFOLIO_BANK_LEDGER_LIST = "PORTFOLIO_BANK_LEDGER_LIST",
  PORTFOLIO_EFT_TRANSACTION_LIST = "PORTFOLIO_EFT_TRANSACTION_LIST",
  PORTFOLIO_CASH_TRANSACTIONS_LIST = "PORTFOLIO_CASH_TRANSACTIONS_LIST",
  PORTFOLIO_INCOME_EXPENSE_LIST = "PORTFOLIO_INCOME_EXPENSE_LIST",
  PORTFOLIO_MUTUAL_FUND = "PORTFOLIO_MUTUAL_FUND",
}

export enum FeedKeys {
  REPORT = "report",
  TRANSACTION = "transaction",
  TRANSACTION_UPLOAD = "transaction_upload",
  UPLOAD = "upload",
  LEAD = "lead",
  CLIENT = "client",
  MODAL_PORTFOLIO = "model_portfolio",
  CREATE_ORDER = "create_orders",
  NOTE = "note",
  MEETING = "meeting",
  KYC = "kyc",
  AGREEMENT = "agreement",
  RISKPROFOLI = "riskprofile",
  MODAL_PROFILE_VERSION = "model_portfolio_version",
  MODAL_PROFILE_REBALANCE = "model_portfolio_rebalance",
  PORTFOLIO = "portfolio",
  ORDER_MANAGER = "order_manager",
  LEAD_MEETING = "lead_meeting",
  LEAD_NOTE = "lead_notes",
  AUTO_FORWARD = "setup_auto_forward",
  DAILY_UPLOAD = " daily_upload",
  MODAL_PORTFOLIO_MAPPING = "model_portfolio_mapping",
  RISK_PROFILE_RESPONSE = "riskprofile_response",
  PROFILE = "profile",
  EMPLOYEE = "employee",
  FAMILY = "family",
  BRANCH = "branch",
  PORFOLIO_GROUP = "portfolio_group",
  COMPANY = "company",
  DEFAULT_PERMISSION = "default_permission",
  ENTITY_PERMISSION = "entity_permission",
  PARTNER = "partner",
  WALLET = "wallet_topup",
  EXECUTION_SHEET = "execution_sheet",
  EXECUTION_SHEET_PORTFOLIO_MAPPING = "execution_sheet_portfolio_mapping",
  MODEL_PORTFOLIO_ALLOCATION = "model_portfolio_allocation",
  MODEL_PORTFOLIO_STOCKS = "model_portfolio_stock",
}

export enum FeedKeysColor {
  REPORT = "#53B795",
  TRANSACTION = "#02A6B3",
  TRANSACTION_UPLOAD = "#D78180",
  UPLOAD = "#7FA0C0",
  LEAD = "#5F7F8D",
  CLIENT = "#115847",
  MODAL_PORTFOLIO = "#F36A61",
  NOTE = "#F11834",
  MEETING = "#447724",
  KYC = "#B192C3",
  AGREEMENT = "#72CCD2",
  RISKPROFOLI = "#CEDBA9",
  MODAL_PROFILE_VERSION = "#8D2B7C",
  MODAL_PROFILE_REBALANCE = "",
  PORTFOLIO = "#DF8327",
  ORDER_MANAGER = "#0C84C4",
  LEAD_MEETING = "#FABB1A",
  LEAD_NOTE = "#AF9964",
  AUTO_FORWARD = "#014C69",
  DAILY_UPLOAD = "#8F9F3C",
  MODAL_PORTFOLIO_MAPPING = "#049C67",
  RISK_PROFILE_RESPONSE = "",
  PROFILE = "#ACE1F7",
  EMPLOYEE = "#C4988E",
  BRANCH = "#773F60",
  PORFOLIO_GROUP = "#98D4C4",
  FAMILY = "#900C3F",
  RiskProfileResponseFeed = "#8214B6",
}

export const NotesColor: { [key: string]: any } = {
  Advice: "#53B795",
  General: "#02A6B3",
  Call: "#D78180",
  Meeting: "#7FA0C0",
};

export const AdviceRegisterColor: { [key: string]: any } = {
  riskprofile: "#53B795",
  kyc: "#02A6B3",
  agreement: "#D78180",
  advice: "#7FA0C0",
  order: "#115847",
};

export enum AdviceRegisterKey {
  RISK_PROFILE = "riskprofile",
  KYC = "kyc",
  AGREEMENT = "agreement",
  ADVICE = "advice",
  ORDER = "order",
}
export const CompanyProfileFields: any = {
  id: "ID",
  sameBillingChecked: "Same Billing Checked",
  createdOn: "Created On",
  updatedOn: "Updated On",
  phoneNumber: "Phone Number",
  panCard: "PAN Card",
  address: "Address",
  pincode: "Pincode",
  state: "State",
  gst: "GST",
  country: "Country",
  city: "City",
  sebiNumber: "SEBI Number",
  corrAddress: "Corr Address",
  corrPincode: "Corr Pincode",
  corrState: "Corr State",
  corrCountry: "Corr Country",
  corrCity: "Corr City",
  accountName: "Account Name",
  accountNumber: "Account Number",
  isfcCode: "IFSC Code",
  accountType: "Account Type",
  bank: "Bank",
  primaryContactName: "Primary Contact Name",
  primaryContactNumber: "Primary Contact Number",
  primaryContactEmail: "Primary Contact Email",
  whatsappNumber: "Whatsapp Number",
  secondaryContactName: "Secondary Contact Name",
  secondaryContactNumber: "Secondary Contact Number",
  secondaryContactEmail: "Secondary Contact Email",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedin: "Linkedin",
  instagram: "Instagram",
  website: "Website",
  youtube: "Youtube",
  name: "Name",
  licenseNo: "License Number",
  logo: "Logo",
  internal: "Internal",
  headBranch: "HeadBranch",
};
