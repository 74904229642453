/* eslint-disable @typescript-eslint/no-unused-expressions */
import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ExportCsv from "components/ExportCsv";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { MapUnmapPortfoliosColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";

const MapUnmapPortfolios = () => {
  useDocumentTitle("Equity Basket Portfolios");

  const { id } = useParams();

  const [selectedId, setSelectedId] = useState(null);

  const queryClient = useQueryClient();

  const {
    isFetching: isLoading,
    data,
    refetch,
  } = useFetchQuery({
    key: ["ALL_UNMAPPED_MAPPED_PORTFOLIOS"],
    route: `/model_portfolio/${id}/map/?get_all=true`,
  });

  const { isLoading: mapLoading, mutate: mapMutation } = useMutation({
    mutationFn: (portfolioId) =>
      axiosInstance.post(`/model_portfolio/${id}/map/`, {
        portfolio: portfolioId,
      }),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
      queryClient.refetchQueries(["ALL_MAPPED_PORTFOLIOS"]);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { isLoading: unmapLoading, mutate: unmapMutation } = useMutation({
    mutationFn: (portfolioId) =>
      axiosInstance.post(`/model_portfolio/${id}/unmap/`, {
        portfolio: portfolioId,
      }),
    onSuccess: (response) => {
      refetch();
      queryClient.refetchQueries(["ALL_MAPPED_PORTFOLIOS"]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const exportCsv = () => {
    return (
      <ExportCsv
        path={`/model_portfolio/${id}/map`}
        name={"ModelPortfolioList"}
      />
    );
  };

  return (
    <GKClientDataGrid
      name="mapUnmapPortfolios"
      columns={MapUnmapPortfoliosColumns(
        selectedId,
        setSelectedId,
        mapLoading,
        mapMutation,
        unmapMutation,
        unmapLoading
      )}
      maxHeight={`calc(100vh - 354px)`}
      headerComponent={
        <FormControlLabel
          control={
            unmapLoading || mapLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Switch
                checked={
                  data?.data?.filter(
                    (dataItem: any) => dataItem?.status === "UNMAPPED"
                  )?.length === 0
                }
                onChange={(event) => {
                  event.target.checked
                    ? mapMutation(
                        data?.data?.map((dataItem: any) => dataItem?.id)
                      )
                    : unmapMutation(
                        data?.data?.map((dataItem: any) => dataItem?.id)
                      );
                }}
              />
            )
          }
          label={
            data?.data?.filter((dataItem: any) => dataItem?.status === "MAPPED")
              ?.length !== 0
              ? "Unmap All"
              : "Map All"
          }
        />
      }
      rows={data?.data || []}
      loading={isLoading}
      exportCsv={exportCsv}
    />
  );
};

export default MapUnmapPortfolios;
