import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type FormValues = {
  advanceAmount: number;
  portfolio: number | string;
  highWatermark: number;
  advanceAmountDate: string;
  highWatermarkDate: string;
};

const AddRevenueModal = (props: Props) => {
  const { id } = useParams();
  const { open, setOpen } = props;

  const { data } = useFetchQuery({
    key: ["GET_ADVANCE_FEE_COLLECTION"],
    route: `/advance-fee-collection/${id}/`,
  });

  const form = useForm<FormValues>({
    defaultValues: {
      advanceAmount: 0,
      portfolio: id,
      highWatermark: 0,
      advanceAmountDate: "",
      highWatermarkDate: "",
    },
    values: {
      advanceAmount: data?.data?.data?.advanceAmount,
      portfolio: data?.data?.data?.portfolio,
      highWatermark: data?.data?.data?.highWatermark,
      advanceAmountDate: data?.data?.data?.advanceAmountDate || "",
      highWatermarkDate: data?.data?.data?.highWatermarkDate || "",
    },
  });

  const { mutate: handleUpdateAmount } = useMutation({
    mutationKey: ["UPDATE_ADVANCE_AMOUNT"],
    mutationFn: (data) => axiosInstance.post("/advance-fee-collection/", data),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_ADVANCE_FEE_COLLECTION_TAB");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"40%"}
      modalTitle="Add Advance Amount"
      footer={
        <LoadingButton
          variant="contained"
          form="update-advance-amount-form"
          type="submit"
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="update-advance-amount-form"
        onSubmit={form.handleSubmit((values: any) => {
          handleUpdateAmount(values);
        })}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="advanceAmount"
                control={form.control}
                render={({ field }) => {
                  return (
                    <GKTextField
                      {...field}
                      type="number"
                      inputLabel="Advance Amount"
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="highWatermark"
                control={form.control}
                render={({ field }) => {
                  return (
                    <GKTextField
                      {...field}
                      type="number"
                      inputLabel="High Water Mark"
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="advanceAmountDate"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter advance amount date",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Advance Amount Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(dayjs(value).format("YYYY-MM-DD"))
                    }
                    requiredField
                    slotProps={{
                      textField: {
                        error: !!errors?.advanceAmountDate,
                        helperText: errors?.advanceAmountDate?.message,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="highWatermarkDate"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter high water mark date",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="High Water mark Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(dayjs(value).format("YYYY-MM-DD"))
                    }
                    requiredField
                    disableFuture
                    slotProps={{
                      textField: {
                        error: !!errors?.highWatermarkDate,
                        helperText: errors?.highWatermarkDate?.message,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </GKModal>
  );
};

export default AddRevenueModal;
