import { GridColDef } from "@mui/x-data-grid";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useState } from "react";
import { extraColumnsField } from "utils/helpers";
import CreateCalculationCommissionModal from "./CreateCalculationCommissionModal";

const CommissionTab = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Partner Name", "partnerName", 150, 1),
    },
    {
      ...extraColumnsField("Calculation Date", "calculationDate", 150, 1),
    },
    {
      ...extraColumnsField("Share Amount", "shareAmount", 150, 1),
    },
    {
      ...extraColumnsField("Fee Amount", "feeAmount", 150, 1),
    },
    {
      ...extraColumnsField("Fee Date Range", "feeDateRange", 150, 1),
    },
    {
      ...extraColumnsField("Invoice", "invoice", 150, 1),
    },
    {
      ...extraColumnsField("Auto/Manual", "auto", 150, 1),
    },
  ];

  return (
    <>
      <GKClientDataGrid
        name="commissionManager"
        columns={columns}
        rows={[]}
        headerComponent={
          <GKButton variant="contained" onClick={() => setIsOpenModal(true)}>
            Calculation Commission
          </GKButton>
        }
      />

      {CreateCalculationCommissionModal && (
        <CreateCalculationCommissionModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
        />
      )}
    </>
  );
};

export default CommissionTab;
