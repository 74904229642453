import { CircularProgress, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKCheckbox from "components/GKCheckbox";
import GKClientDataGrid from "components/GKClientDataGrid";
import GKSideModal from "components/GKSideModal";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  reportData: any;
  setReportData: (reportData: any) => void;
}

type FormValues = {
  portfolioIds: any[];
  emailPortfolios: any[];
};

const SendNotificationModal = (props: Props) => {
  const { open, setOpen, reportData, setReportData } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      portfolioIds: [],
      emailPortfolios: [],
    },
  });

  form.watch();

  const { mutate: handleWhatsAppReportSend, isLoading } = useMutation({
    mutationKey: ["WHATSAPP_REPORT_SEND"],
    mutationFn: (data: any) => axiosInstance.post(`/send_reports/`, data),
    onSuccess: (response) => {
      setReportData({});
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const onSubmit = (data: FormValues) => {
    handleWhatsAppReportSend({ ...data, reportId: reportData.id });
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.name} - {params?.row?.portfolioId}
            </Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Email", "clientEmail", 300, 1),
      valueGetter: (params) => params.row.clientEmail || "-",
      renderHeader: () => {
        const allEmailsExist = reportData?.portfolioList?.every(
          (item: any) => !item.clientEmail
        );

        const validPortfolios = reportData?.portfolioList?.filter(
          (dataItem: any) => dataItem.clientEmail
        );

        return (
          <Grid container gap={1} display={"flex"} alignItems={"center"}>
            <GKCheckbox
              disabled={allEmailsExist}
              indeterminate={allEmailsExist}
              checked={
                validPortfolios.length ===
                  form.getValues("emailPortfolios").length || false
              }
              onChange={(e) => {
                e.stopPropagation();
                form.setValue(
                  "emailPortfolios",
                  e.target.checked
                    ? validPortfolios.map((dataItem: any) => dataItem.id)
                    : []
                );
              }}
            />
            <Typography fontWeight={400} fontSize={12} color={"#6B7280"}>
              Email
            </Typography>
          </Grid>
        );
      },
      renderCell: (params) => {
        return (
          <Grid container gap={1} display={"flex"} alignItems={"center"}>
            <Controller
              name="emailPortfolios"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <GKCheckbox
                  sx={{
                    padding: 0,
                  }}
                  disabled={!params.row.clientEmail}
                  indeterminate={!params.row.clientEmail}
                  checked={value.includes(params.row.id)}
                  onChange={(e) =>
                    onChange(
                      e.target.checked
                        ? [...value, params.row.id]
                        : value.filter((id: string) => id !== params.row.id)
                    )
                  }
                />
              )}
            />
            <Typography>{params.row.clientEmail || "-"}</Typography>
          </Grid>
        );
      },
    },
    {
      ...extraColumnsField("Phone", "clientPhoneNumber", 170, 1),
      valueGetter: (params) => params.row.clientPhoneNumber || "-",
      renderHeader: () => {
        const allEmailsExist = reportData?.portfolioList?.every(
          (item: any) => !item.clientPhoneNumber
        );

        const validPortfolios = reportData?.portfolioList?.filter(
          (dataItem: any) => dataItem.clientPhoneNumber
        );

        return (
          <Grid container gap={1} display={"flex"} alignItems={"center"}>
            <GKCheckbox
              disabled={allEmailsExist}
              indeterminate={allEmailsExist}
              checked={
                validPortfolios.length ===
                  form.getValues("portfolioIds").length || false
              }
              onChange={(e) => {
                e.stopPropagation();
                form.setValue(
                  "portfolioIds",
                  e.target.checked
                    ? validPortfolios.map((dataItem: any) => dataItem.id)
                    : []
                );
              }}
            />
            <Typography fontWeight={400} fontSize={12} color={"#6B7280"}>
              Phone
            </Typography>
          </Grid>
        );
      },
      renderCell: (params) => {
        return (
          <Grid container gap={1} display={"flex"} alignItems={"center"}>
            <Controller
              name="portfolioIds"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <GKCheckbox
                  sx={{ padding: 0 }}
                  disabled={!params.row.clientPhoneNumber}
                  indeterminate={!params.row.clientPhoneNumber}
                  checked={value.includes(params.row.id)}
                  onChange={(e) =>
                    onChange(
                      e.target.checked
                        ? [...value, params.row.id]
                        : value.filter((id: string) => id !== params.row.id)
                    )
                  }
                />
              )}
            />
            <Typography>{params.row.clientPhoneNumber || "-"}</Typography>
          </Grid>
        );
      },
    },
    // {
    //   headerName: "Whatsapp",
    //   field: "whatsapp",
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Controller
    //         name="portfolioIds"
    //         control={form.control}
    //         render={({ field: { onChange, value } }) => (
    //           <GKCheckbox
    //             disabled={!params.row.clientPhoneNumber}
    //             indeterminate={!params.row.clientPhoneNumber}
    //             checked={value.includes(params.row.id)}
    //             onChange={(e) =>
    //               onChange(
    //                 e.target.checked
    //                   ? [...value, params.row.id]
    //                   : value.filter((id: string) => id !== params.row.id)
    //               )
    //             }
    //           />
    //         )}
    //       />
    //     );
    //   },
    // },
    // {
    //   headerName: "Email",
    //   field: "mail",
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Controller
    //         name="emailPortfolios"
    //         control={form.control}
    //         render={({ field: { onChange, value } }) => (
    //           <GKCheckbox
    //             disabled={!params.row.clientEmail}
    //             indeterminate={!params.row.clientEmail}
    //             checked={value.includes(params.row.id)}
    //             onChange={(e) =>
    //               onChange(
    //                 e.target.checked
    //                   ? [...value, params.row.id]
    //                   : value.filter((id: string) => id !== params.row.id)
    //               )
    //             }
    //           />
    //         )}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <GKSideModal
      width="60%"
      open={open}
      setOpen={setOpen}
      onClose={() => {
        setReportData({});
        setOpen(false);
      }}
      modalTitle="Send Notification"
      footerButton={
        <Grid container justifyContent={"flex-end"}>
          <GKButton
            variant="contained"
            type="submit"
            disabled={
              isLoading ||
              (!form.getValues("portfolioIds").length &&
                !form.getValues("emailPortfolios").length)
            }
            form="whatsapp-form"
          >
            {isLoading ? <CircularProgress size={20} /> : "Send"}
          </GKButton>
        </Grid>
      }
    >
      <form id="whatsapp-form" onSubmit={form.handleSubmit(onSubmit)}>
        <GKClientDataGrid
          name="sendNotification"
          showHeaderGrid={false}
          columns={columns}
          rows={reportData?.portfolioList}
        />
      </form>
    </GKSideModal>
  );
};

export default SendNotificationModal;
