import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

export const ProfileAPI = () => {
  const {
    state: { userData },
  } = useAppContext();

  const navigate = useNavigate();

  const { mutate: handleUpdateProfile, isLoading: updateProfileUpdateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/personal-details-update/${userData?.id}/`, data),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        refetchQuery(QueryKeys.GET_USER_DETAIL);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const {
    mutate: handleUpdateProfileRole,
    isLoading: updateRoleUpdateLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/user-role/`, { userRole: data }),
    onSuccess: () => {
      navigate(`/app/holdings`);
      setTimeout(() => {
        refetchQuery(QueryKeys.GET_USER_DETAIL);
        window?.location?.reload();
      }, 100);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return {
    handleUpdateProfile,
    updateProfileUpdateLoading,
    handleUpdateProfileRole,
    updateRoleUpdateLoading,
  };
};
