import { Grid, TableContainer, TablePagination } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { LuSearch, LuTable2 } from "react-icons/lu";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import { CustomLoader, EmptyData } from "./DatagridUtils";
import GKButton from "./GKButton";
import GKTextField from "./GKTextField";
import { Show } from "./Show";
import TableColumnModal from "./TableColumnModal";

interface Props {
  headerComponent?: any;
  headerGridStyle?: any;
  showSearch?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densityButton?: boolean;
  exportButton?: boolean;
  searchPlaceholder?: string;
  showFilters?: boolean;
  ref?: any;
  CustomPagination?: any;
  setFilterColumn?: any;
  filterColumn?: any;
  exportCsv?: any;
  maxHeight?: any;
  hideHeaderGrid?: any;
  showHeaderGrid?: any;
  showFiltersButton?: any;
  setShowNonZero?: any;
  handleSortChange?: any;
  rightPinnes?: any;
  initialPageSize?: any;
  minHeight?: any;
  headerTitle?: any;
  emptyButtons?: any;
  footerComponent?: any;
  name: string;
}

const GKClientDataGrid = (props: DataGridProProps & Props) => {
  const { theme } = ThemeContainer();

  const {
    rows,
    headerComponent,
    showSearch = true,
    loading,
    ref,
    columnsButton = true,
    exportCsv,
    maxHeight,
    showHeaderGrid = true,
    pagination = true,
    pinnedColumns,
    handleSortChange,
    onRowClick,
    initialPageSize,
    rightPinnes,
    minHeight,
    columns,
    headerTitle,
    name,
    emptyButtons,
    footerComponent,
  } = props;

  const {
    state: { columnsPersist },
    dispatch,
    apiRef,
  } = useAppContext();

  const [searchText, setSearchText] = useState("");

  const [tableColumnModalOpen, setTableColumnModalOpen] = useState(false);

  const Pagination = (props: any) => {
    const apiRef = useGridApiContext();

    const handlePageChange = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      apiRef?.current?.setPage(newPage);
    };

    const handleRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      apiRef?.current?.setPageSize(newRowsPerPage);
    };

    return pagination ? (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: `solid 1px ${paletteLightTheme.palette.border.main}`,
        }}
      >
        {footerComponent || <div />}
        <TablePagination
          {...props}
          component="div"
          labelRowsPerPage="Rows"
          count={apiRef?.current.getRowsCount()}
          page={apiRef?.current.state.pagination.paginationModel.page}
          rowsPerPage={
            apiRef?.current.state.pagination.paginationModel.pageSize
          }
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={initialPageSize ? [] : [25, 50, 100]}
        />
      </div>
    ) : (
      <div> </div>
    );
  };

  const handleRowsReturn = () => {
    const filteredRows =
      rows?.length !== 0 &&
      matchSorter(rows, searchText, {
        keys: Object.keys((rows && rows[0]) || {}),
        sorter: (rankedItems) => rankedItems,
      });
    if (searchText) {
      return filteredRows;
    }
    if (loading) {
      return [];
    }
    return rows || [];
  };

  return (
    <>
      <Grid
        container
        sx={{
          "& .MuiTableContainer-root": {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        }}
      >
        <Grid item xs={12}>
          <Show.When isTrue={showHeaderGrid}>
            <Grid
              minHeight={"52px"}
              container
              justifyContent={"space-between"}
              p={1}
              alignItems={"center"}
              ref={ref}
              border={`solid 1px ${theme.palette.grey[300]}`}
              borderBottom={`solid 0px ${theme.palette.grey[300]}`}
              sx={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                  width: "100%",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <Show.When isTrue={headerTitle}>{headerTitle}</Show.When>
                  <Show.When isTrue={showSearch}>
                    <GKTextField
                      style={{ width: 200 }}
                      placeholder={"Search..."}
                      value={searchText}
                      sx={{
                        "& .MuiInputBase-root ": {
                          height: "35px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #E3E8EF",
                        },
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      type="search"
                      InputProps={{
                        startAdornment: (
                          <LuSearch size={26} color={theme.palette.grey[500]} />
                        ),
                      }}
                    />
                  </Show.When>
                  <Show.When isTrue={exportCsv}>
                    {exportCsv && exportCsv()}
                  </Show.When>
                  <Show.When isTrue={columnsButton}>
                    <GKButton
                      style={{
                        padding: "6px 10px",
                        color: "#697586",
                        border: "1px solid #E3E8EF",
                      }}
                      onClick={() => {
                        setTableColumnModalOpen(true);
                      }}
                      startIcon={<LuTable2 size={16} />}
                    >
                      Columns
                    </GKButton>
                  </Show.When>
                </span>
                <Show.When isTrue={headerComponent}>
                  {headerComponent}
                </Show.When>
              </div>
            </Grid>
          </Show.When>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <DataGridPro
              {...props}
              apiRef={apiRef}
              onSortModelChange={handleSortChange}
              pagination={pagination}
              rows={handleRowsReturn()}
              columnVisibilityModel={
                columnsPersist && name && (columnsPersist[name] as any)
              }
              slots={{
                footer: Pagination,
                loadingOverlay: CustomLoader,
                detailPanelExpandIcon: () => <MdExpandMore size={20} />,
                detailPanelCollapseIcon: () => <MdExpandLess size={20} />,
                noRowsOverlay: () => <EmptyData emptyButtons={emptyButtons} />,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: initialPageSize || 25,
                  },
                },
              }}
              getDetailPanelHeight={() => "auto"}
              slotProps={{
                toolbar: {
                  value: searchText,
                  onInputChange: (event: any) =>
                    setSearchText(event.target.value),
                },
              }}
              pinnedColumns={{
                ...pinnedColumns,
                right: rightPinnes || ["action"],
              }}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  maxHeight: maxHeight || "calc(100vh - 256px)",
                  minHeight: minHeight || "",
                },
                "&.MuiDataGrid-root .MuiTablePagination-root": {
                  padding: "0 8px",
                },

                "& .MuiDataGrid-row": {
                  "&:hover": {
                    cursor: onRowClick ? "pointer" : "",
                  },
                },
              }}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <Show.When isTrue={tableColumnModalOpen}>
        <TableColumnModal
          open={tableColumnModalOpen}
          setOpen={setTableColumnModalOpen}
          columns={columns}
          columnVisibilityModel={columnsPersist}
          dispatch={dispatch}
          name={name}
        />
      </Show.When>
    </>
  );
};

export default GKClientDataGrid;
