import { paletteLightTheme } from "./ColorTheme";

export const returnInstaTypeColor = (str: string) => {
  switch (str) {
    case "CE":
      return paletteLightTheme.palette.primary.main;
    case "NCE":
      return paletteLightTheme.palette.secondary.main;
    case "MF":
      return paletteLightTheme.palette.warning.main;
    default:
      return paletteLightTheme.palette.primary.main;
  }
};

export const handleReturnPositiveNegative = (val: number) => {
  return val < 0
    ? paletteLightTheme.palette.error.main
    : paletteLightTheme.palette.success.main;
};
