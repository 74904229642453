import { Card, Chip, Grid, IconButton, Typography } from "@mui/material";
import GKButton from "components/GKButton";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { groupBy } from "lodash";
import { CgMathPlus } from "react-icons/cg";
import { IoLockClosedOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  type: string;
}

const RoleWisePermission = (props: Props) => {
  const { type } = props;

  useDocumentTitle(`${type} Permission`);

  const navigate = useNavigate();

  const { data, isLoading } = useFetchQuery({
    key: ["ROLE_PERMISSION"],
    route: "/role_permissions/",
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <>
      <div
        style={{
          gap: 16,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
        }}
      >
        {Object.entries(groupBy(data?.data?.data, "permissionType"))
          .map(([key, value]) => {
            return (
              key === type.toLowerCase() &&
              value.map((permission) => {
                return (
                  <Card
                    sx={{
                      padding: 2,
                      maxWidth: "280px",
                      cursor:
                        permission?.permissionTags?.length > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      permission?.permissionTags?.length > 0 &&
                        navigate(
                          `/app/${permission?.permissionType}/permission/${permission?.id}`
                        );
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography fontSize={18} fontWeight={500}>
                          {permission.name}
                        </Typography>
                      </Grid>

                      {permission?.permissionTags?.length > 0 ? (
                        <>
                          <Grid item xs={12} mt={1}>
                            <Typography fontSize={12} fontWeight={400}>
                              Permissions Granted for
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            {permission?.permissionTags?.map((type: string) => (
                              <Chip
                                key={type}
                                label={type}
                                variant="outlined"
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: 500,
                                  borderRadius: "6px",
                                  border: "1px solid #EEF2F6",
                                  background: "#F8FAFC",
                                  margin: "8px 8px 0px 0px",
                                  height: "24px",
                                  color: "#64748B",
                                }}
                              />
                            ))}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            mt={3}
                          >
                            <IconButton
                              sx={{
                                borderRadius: "5.269px",
                                border:
                                  "0.527px solid var(--Neutrals-Neutrals200, #E2E8F0)",
                                background: " var(--White, #FFF)",
                                boxShadow:
                                  "0px 0.527px 1.054px 0px rgba(16, 24, 40, 0.05)",
                                p: "6px",
                              }}
                            >
                              <IoLockClosedOutline size={14} />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            mt={0.5}
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              color={"#9AA4B2"}
                            >
                              No Permissions Granted
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            mt={1}
                          >
                            <GKButton
                              variant="outlined"
                              color="dark"
                              className="small"
                              startIcon={<CgMathPlus size={14} />}
                              onClick={() =>
                                navigate(
                                  `/app/${permission?.permissionType}/permission/${permission?.id}`
                                )
                              }
                            >
                              Set Permissions
                            </GKButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Card>
                );
              })
            );
          })
          .flat()}
      </div>

      {type.toLowerCase() === "employee" && (
        <Grid container mt={2}>
          <Grid item xs={12}>
            <GKButton
              variant="outlined"
              color="dark"
              className="small"
              startIcon={<CgMathPlus size={14} />}
              onClick={() => navigate(`/app/employee/permission/`)}
            >
              Add Role
            </GKButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RoleWisePermission;
