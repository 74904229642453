import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { extraColumnsField } from "utils/helpers";

const PartnerTab = () => {
  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Partner Name", "partnerName", 150, 1),
    },
    {
      ...extraColumnsField("Joining Date", "joiningDate", 150, 1),
    },
    {
      ...extraColumnsField("Total AUM", "Total AUM", 150, 1),
    },
    {
      ...extraColumnsField("Billed Revenue", "Billed Revenue", 150, 1),
    },
    {
      ...extraColumnsField("Share Paid", "Share Paid", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "actions", 150, 1),
    },
  ];

  return <GKClientDataGrid name="partnerTable" columns={columns} rows={[]} />;
};

export default PartnerTab;
