import { Grid, styled } from "@mui/material";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import GKTypography from "./GKTypography";

interface Props {
  value: number;
  name: string;
  max: any;
  bgColor?: any;
  progressColor?: any;
  isPercent?: any;
  key?: any;
}

const Bars = ({
  value,
  name,
  max,
  progressColor,
  bgColor,
  key,
  isPercent,
}: Props) => {
  const { theme } = ThemeContainer();
  const BAR = styled("progress")(({ theme }) => {
    return {
      width: "100%",
      "&::-webkit-progress-bar": {
        backgroundColor:
          Number(value) > 0
            ? bgColor || `${theme.palette.border.main}33`
            : `${theme?.palette?.error?.main}33`,

        border: `0`,
        borderRadius: " 4px",
      },
      "&::-webkit-progress-value": {
        border: "0",
        backgroundColor:
          Number(value) > 0
            ? progressColor || theme?.palette?.primary?.main
            : `${theme?.palette?.error?.main}`,
        borderRadius: " 4px",
      },
      "&::-moz-progress-bar": {
        border: "0",
        backgroundColor: progressColor || theme?.palette?.primary?.main,
        borderRadius: " 4px",
      },
    };
  });

  const roundToCroreOrLakh = (number: any) => {
    if (number >= 10000000) {
      return Math.ceil(Number(Number(number / 10000000).toFixed(3))) * 10000000;
    }
    if (number >= 100000) {
      return Math.ceil(Number(Number(number / 100000).toFixed(3))) * 100000;
    }
    if (number >= 1000) {
      return Math.ceil(Number(Number(number / 1000).toFixed(3))) * 1000;
    }
    return number;
  };

  return (
    <Grid container my={2} key={key}>
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <GKTypography
          fontSize={12}
          fontWeight={600}
          textTransform={"capitalize"}
        >
          {name}
        </GKTypography>
        <GKTypography
          fontSize={12}
          fontWeight={700}
          color={theme.palette.primary.main}
        >
          {isPercent ? `${value?.toFixed(2)} %` : numberToCurrency(value)}
        </GKTypography>
      </Grid>
      <BAR
        className="progress"
        value={Math.abs(value)}
        max={Math.abs(roundToCroreOrLakh(max))}
      />
    </Grid>
  );
};

export default Bars;
