import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import GKSearchSelect from "components/GKSearchSelect";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const SettingTab = (props: {
  data: {
    clientAccess: boolean;
    ccEmail: string[];
    active: boolean;
    tradesPlusCash: boolean;
  };
}) => {
  useDocumentTitle("Portfolio Settings");

  const { data } = props;

  const { id } = useParams();
  const { theme } = ThemeContainer();

  const [expanded, setExpanded] = useState(0);

  const form = useForm({
    defaultValues: {
      ccEmail: data?.ccEmail || [],
    },
    values: {
      ccEmail: data?.ccEmail,
    },
  });

  form.watch();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${id}/cc-email/`, data),
    onSuccess: (response) => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const {
    mutate: handlePortfolioSettingUpdate,
    isLoading: portfolioSettingLoading,
  } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${id}/settings/`, {
        tradesPlusCash: data,
      }),
    onSuccess: (response) => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
      refetchQuery("GET_PORTFOLIO_DETAIL_CARDS");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleActivatePortfolio, isLoading: activeInActive } =
    useMutation({
      mutationFn: (data) =>
        axiosInstance.post(`/portfolios/${id}/active/?show_inactive=True`, {
          active: data,
        }),
      onSuccess: () => {
        refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
        refetchQuery("GET_PORTFOLIO_DETAIL_FOR_ACCESS");
        window.location.reload();
      },
      onError: (error: any) => {
        refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
        refetchQuery("GET_PORTFOLIO_DETAIL_FOR_ACCESS");
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const { mutate: handleClientAccess, isLoading: clientAccessLoading } =
    useMutation({
      mutationFn: (data) =>
        axiosInstance.post(`/portfolios/${id}/client_access/`, {
          client_access: data,
        }),
      onSuccess: (response) => {
        refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
        refetchQuery("GET_PORTFOLIO_DETAIL_FOR_ACCESS");
        toast.success(
          response.data?.message || "Action completed successfully."
        );
      },
      onError: (error: any) => {
        refetchQuery("GET_PORTFOLIO_DETAIL_FOR_ACCESS");
        refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const permissionArray = [
    {
      title: "Client Permissions",
      description:
        "Enable clients access to track their portfolios via unique logins with real-time updates.",
      permission: "CLIENT",
      value: data?.clientAccess,
    },
    {
      title: "Portfolio Permissions",
      description:
        "Manage Portfolio: Easily Switch Between Active and Inactive Items",
      permission: "PORTFOLIO",
      value: data?.active,
    },
    {
      title: "CC Email Configuration",
      description: "",
      permission: "CC_EMAIL",
      value: data?.ccEmail,
    },
    {
      title: "Portfolio metrics configuration",
      description: "Calculate NAV, XIRR and Net Contribution with cash flows.",
      permission: "PORTFOLIO_METRICS",
      value: data?.tradesPlusCash,
    },
  ];

  const validateEmail = (email: string) => {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      {permissionArray.map((dataItem, index) => {
        const loading =
          portfolioSettingLoading || clientAccessLoading || activeInActive;
        // dataItem.permission === "CLIENT"
        //   ? clientAccessLoading
        //   : activeInActive;

        return (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={() => {
              setExpanded(index);
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >
              <Typography fontSize={16} fontWeight={600} textTransform={"none"}>
                {dataItem?.title}
              </Typography>
              {expanded === index && index === 2 && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  loading={isLoading}
                  form="cc-email-form"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Save
                </LoadingButton>
              )}
            </AccordionSummary>
            <Divider />
            <AccordionDetails
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {dataItem.permission !== "CC_EMAIL" && (
                <>
                  <Switch
                    checked={
                      dataItem.permission === "CC_EMAIL"
                        ? false
                        : (dataItem.value as boolean)
                    }
                    onChange={(e: any) => {
                      if (loading) return;
                      if (dataItem.permission === "CLIENT") {
                        handleClientAccess(e.target.checked);
                      } else if (dataItem.permission === "PORTFOLIO_METRICS") {
                        handlePortfolioSettingUpdate(e.target.checked);
                      } else if (dataItem.permission !== "CC_EMAIL") {
                        handleActivatePortfolio(e.target.checked);
                      }
                    }}
                  />
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    textTransform={"none"}
                    color={theme.palette.dark?.main}
                  >
                    {dataItem.description}
                  </Typography>
                </>
              )}

              {dataItem.permission === "CC_EMAIL" && (
                <div style={{ width: "100%" }}>
                  <form
                    id="cc-email-form"
                    onSubmit={form.handleSubmit((values) => mutate(values))}
                  >
                    <Controller
                      name="ccEmail"
                      control={form.control}
                      rules={{
                        required: {
                          value: false,
                          message: "Please enter email",
                        },
                        validate: (value) => {
                          if (value.length > 5) {
                            return "You can add a maximum of 5 email addresses.";
                          }
                          if (value.some((email) => !validateEmail(email))) {
                            return "Please enter a valid email address.";
                          }
                          return true;
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          requiredField
                          sx={{ width: "100%" }}
                          inputLabel={""}
                          freeSolo
                          multiple
                          options={[]}
                          onChange={(e, val) => {
                            const selected = val.map((option: any) => option);
                            field.onChange(selected);
                          }}
                          renderTags={(value, props) => {
                            return value?.map((option, index) => (
                              <Chip
                                label={option}
                                {...props({ index })}
                                deleteIcon={<MdClose size={12} />}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </form>
                  {data?.ccEmail.length === 0 && (
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={"#98A4b3"}
                    >
                      You can add maximum 5 email addresses in CC.
                    </Typography>
                  )}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default SettingTab;
