import dayjs from "dayjs";

const generateFinancialYears = (startYear: number, endYear: number) => {
  const financialYears = [];

  for (let year = startYear; year <= endYear; year++) {
    const nextYear = year + 1;
    financialYears.push({
      name: `FY${year}-${nextYear}`,
      value: financialYears.length + 1,
      dates: {
        startDate: dayjs(`${year}-04-01`).format("YYYY-MM-DD"),
        endDate: dayjs(`${nextYear}-03-31`).format("YYYY-MM-DD"),
      },
    });
  }

  return financialYears;
};

const startYear = 2000;
const endYear = dayjs().get("year");

export const financialYearFilter = generateFinancialYears(startYear, endYear);
