import { GridColDef } from "@mui/x-data-grid";
import GKClientDataGrid from "components/GKClientDataGrid";
import { extraColumnsField } from "utils/helpers";

const PortfolioCommissionTab = () => {
  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name", "portfolioName", 150, 1),
    },
    {
      ...extraColumnsField("Fee Plan", "feePlan", 150, 1),
    },
    {
      ...extraColumnsField("Start Date", "startDate", 150, 1),
      type: "date",
    },
    {
      ...extraColumnsField("Last Fee Collected", "lastFeeCollected", 150, 1),
    },
    {
      ...extraColumnsField("Billed Revenue", "BilledRevenue", 150, 1),
    },
    {
      ...extraColumnsField("Unbilled Revenue", "unbilledRevenue", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "actions", 150, 1),
    },
  ];

  return (
    <GKClientDataGrid name="portfolioCommission" columns={columns} rows={[]} />
  );
};

export default PortfolioCommissionTab;
