import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import DragDrop from "components/DragDrop";
import GKModal from "components/GKModal";
import React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { generateUUID } from "utils/helpers";
import { v4 as uuid } from "uuid";

interface Props {
  open: any;
  setOpen: any;
  form: any;
}

const VersionUploadModal = ({ open, setOpen, form }: Props) => {
  const { id } = useParams();

  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const [files, setFiles] = React.useState([]);

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ modelId, file }: { modelId: any; file: any }) =>
      axiosInstance.post(`model_portfolio/${modelId}/upload/`, file),
    onSuccess: (response: any) => {
      const data = response?.data?.map((item: any, index: number) => {
        return {
          ...item,
          id: `${generateUUID(10)}${index}`,
        };
      });
      form?.setValue(
        "stocks",
        data?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
          };
        })
      );
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  const { mutate: handleDownloadSample } = useMutation({
    mutationFn: () =>
      axiosInstance.get(`/model_portfolio/export_sample_version_csv/`),
    onSuccess: (response: any) => {
      try {
        const blobData = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement("a");
        a.href = url;
        a.download = "SampleVersionUpload.csv";
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast.error("An error occurred while processing the file.");
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Upload Model"
      titleComponent={
        <Button
          variant="outlined"
          size="medium"
          onClick={() => handleDownloadSample()}
        >
          Download Sample File
        </Button>
      }
      footer={
        <LoadingButton
          variant="contained"
          size="medium"
          disabled={isLoading}
          onClick={() => {
            const appendedFiles = new FormData();
            files.forEach((file) => {
              appendedFiles.append("file", file);
            });
            mutate({ file: appendedFiles, modelId: id });
          }}
          loading={isLoading}
        >
          {"Submit"}
        </LoadingButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <DragDrop
            value={files}
            onChange={setFiles}
            acceptType={".csv"}
            uploadError={uploadError}
            setUploadError={setUploadError}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default VersionUploadModal;
