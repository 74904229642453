import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { Show } from "components/Show";
import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";

interface DragDropProps {
  value: File[];
  onChange: (files: File[]) => void;
  setUploadError: (files: string) => void;
  uploadError: string;
  isLoading?: boolean;
  showSupportedFormat?: boolean;
  supportName?: string;
  className?: string;
  maxHeight?: string;
  acceptType?: string;
  error?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

const FileInput = styled("input")(() => {
  return {
    cursor: "pointer",
    zIndex: 99,
    height: "100%",
    width: "100%",
    top: 0,
    position: "absolute",
    opacity: 0,
  };
});

const DragDrop = (props: DragDropProps) => {
  const { theme } = ThemeContainer();
  const {
    onChange,
    isLoading = false,
    value,
    supportName,
    acceptType,
    multiple,
    disabled,
    error,
    showSupportedFormat = true,
    uploadError,
    setUploadError,
  } = props;

  const [isDragOver, setIsDragOver] = useState(false);

  const acceptedTypes = acceptType
    ?.split(",")
    .map((type) => type.trim().toLowerCase());

  const isValidFileType = (file: File) => {
    if (!acceptedTypes) return true;
    const fileType = file.type.toLowerCase();
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    return (
      acceptedTypes.includes(fileType) ||
      acceptedTypes.includes(`.${fileExtension}`)
    );
  };

  const handleDragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const filteredFiles = Array.from(event.dataTransfer.files).filter(
        isValidFileType
      );
      if (filteredFiles.length > 0) {
        onChange(filteredFiles);
        setUploadError(null);
      } else {
        setUploadError("Unsupported file type.");
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.files && event.target.files.length > 0) {
      const filteredFiles = Array.from(event.target.files).filter(
        isValidFileType
      );
      if (filteredFiles.length > 0) {
        onChange(filteredFiles);
        setUploadError(null);
      } else {
        setUploadError("Unsupported file type.");
      }
    }
    event.target.value = "";
  };

  const handleDelete = (index: number) => {
    const newValue = value?.filter((data, i) => i !== index);
    onChange(newValue);
  };

  const handleReturnSize = (file: any) => {
    const totalBytes = file?.size;
    if (totalBytes < 1000000) {
      return `${(totalBytes / 1000).toFixed(2)}KB`;
    }
    return `${(totalBytes / 1000000).toFixed(2)}MB`;
  };

  return (
    <div style={{ width: "99.8%", position: "relative" }}>
      <Show.When isTrue={value?.length === 0}>
        <label
          htmlFor="file-upload-input"
          style={{
            border: `1px`,
            backgroundColor: disabled ? "#F3F4F6" : "#fff ",
            width: "99.7%",
            borderColor:
              error || uploadError ? theme.palette.error.main : "#D1D5DB",
            borderStyle: error || uploadError ? "solid" : "dashed",
            display: "block",
            borderRadius: "0.75rem",
            height: "0",
            cursor: "pointer",
            paddingTop: "80px",
            paddingBottom: "80px",
            ...(isDragOver && {
              backgroundColor: theme.palette.action.hover,
              borderColor: theme.palette.primary.main,
            }),
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div
            style={{
              pointerEvents: "none",
              height: "100%",
              width: "99.7%",
              borderRadius: "0.75rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={
                    disabled
                      ? "/LandingPage/disableUpload.svg"
                      : "/LandingPage/importModal.svg"
                  }
                  alt=""
                  width={60}
                  height={"auto"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Typography
                  fontSize={16}
                  textAlign={"center"}
                  fontWeight={400}
                  color={theme.palette.dark.main}
                >
                  Drag & Drop your file here or
                  <span
                    style={{
                      color: "#006580",
                      textDecoration: "underline",
                      marginLeft: 3,
                    }}
                  >
                    Choose Files
                  </span>
                </Typography>
                {showSupportedFormat && (
                  <Typography
                    fontSize={12}
                    display={"flex"}
                    color={"#9CA3AF"}
                    justifyContent={"center"}
                    fontWeight={400}
                  >
                    Supported formats : {supportName || "CSV"}
                  </Typography>
                )}
                {uploadError && (
                  <Typography
                    fontSize={12}
                    color={theme.palette.error.main}
                    textAlign={"center"}
                  >
                    {uploadError}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {isLoading && <CircularProgress />}
          </div>
          <FileInput
            type="file"
            onChange={handleChange}
            accept={acceptType}
            disabled={disabled}
            multiple={multiple}
          />
        </label>
      </Show.When>

      <Show.When isTrue={value?.length !== 0}>
        {value?.map((dataItem: any, index: any) => {
          return (
            <Grid container key={index}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    borderColor: theme.palette.grey[300],
                    mb: 1,
                    p: 1,
                    borderRadius: 2,
                  }}
                >
                  <Grid container alignItems={"center"}>
                    <Grid
                      item
                      container
                      alignContent={"center"}
                      gap={1}
                      xs={11}
                    >
                      <Grid item display={"flex"} alignItems={"center"}>
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.src =
                              "https://gridkey.in/Logos/iconLogo.png";
                          }}
                          src={`/FileIcons/${dataItem?.name
                            .split(".")
                            .pop()}.svg`}
                          height={30}
                          width={30}
                          alt=""
                          style={{
                            borderRadius: 4,
                            border: "1px solid",
                            borderColor: theme.palette.grey[300],
                            padding: 4,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography fontSize={12} fontWeight={600}>
                          {dataItem?.name}
                        </Typography>
                        <Typography color={"#9CA3AF"} fontSize={12}>
                          {handleReturnSize(dataItem)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} textAlign={"right"}>
                      <IconButton
                        className="outline"
                        color="error"
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        <FiTrash2 size={18} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          );
        })}
      </Show.When>
    </div>
  );
};

export default DragDrop;
