import { LoadingButton } from "@mui/lab";
import { FormControl, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { v4 as uuid } from "uuid";

interface Props {
  form: any;
  open: any;
  setOpen: any;
  selectedId: any;
  id: any;
}

const UpdateRebalanceDate = ({
  open,
  setOpen,
  form,
  selectedId,
  id,
}: Props) => {
  // =+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+
  const { mutate: handleUpdateModelPortfolio, isLoading } = useMutation({
    mutationFn: (item: any) =>
      axiosInstance.post(`/model_portfolio/${id}/get_version_on_date/`, {
        id: item?.id,
        date: item?.date,
      }),
    onSuccess: (response: any) => {
      form.setValue(
        "stocks",
        response?.data?.allocItem?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
            allocation: item?.currentAllocation,
          };
        })
      );
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again."
      );
    },
  });

  // =+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+
  const handleUpdateVersion = (values: any) => {
    handleUpdateModelPortfolio({
      id: selectedId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    });
  };

  return (
    <GKModal
      open={open}
      modalTitle="Rebalance Date"
      setOpen={setOpen}
      footer={
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            handleUpdateVersion(form.getValues());
          }}
          variant="contained"
        >
          Submit
        </LoadingButton>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <GKDatePicker
                  {...field}
                  onChange={(e) => {
                    field.onChange(dayjs(e).format("YYYY-MM-DD"));
                  }}
                  value={dayjs(field.value)}
                  inputLabel="Model Portfolio start date"
                  disableFuture={false}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </GKModal>
  );
};

export default UpdateRebalanceDate;
