import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

interface Props extends ButtonProps {
  href?: string;
  target?: string;
}

const GKButton: FC<Props> = ({
  children,
  component = "button",
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      component={buttonProps.href ? "a" : component}
      href={buttonProps?.href}
      target={buttonProps?.href ? buttonProps?.target : undefined}
    >
      {children}
    </Button>
  );
};

export default GKButton;
