import { LoadingButton } from "@mui/lab";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKModal from "components/GKModal";
import { useFetchQuery } from "hooks/useQueries";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoiceId?: string | number;
}

const SendPaymentModal = (props: Props) => {
  const { open, setOpen, invoiceId } = props;

  const { data, isLoading } = useFetchQuery({
    key: ["INVOICE_CHARGES_DETAILS"],
    route: `/invoice-history/${invoiceId}/invoice-payment-details/`,
    enabled: !!invoiceId,
  });

  const { mutate: generatePaymentLink, isLoading: paymentLinkLoading } =
    useMutation({
      mutationKey: ["GENERATE_PAYMENT_LINK"],
      mutationFn: (id: string | number) =>
        axiosInstance.post(`/invoice-history/${id}/generate-payment-link/`),
      onSuccess: (response) => {
        refetchQuery("INVOICE_LIST");
        toast.success(
          response.data?.message || "Action completed successfully."
        );
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong please try again."
        );
      },
    });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Send Payment Link"}
      loading={isLoading}
      footer={
        <Grid
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <LoadingButton
            variant="contained"
            loading={paymentLinkLoading}
            onClick={() => generatePaymentLink(invoiceId)}
          >
            Send
          </LoadingButton>
        </Grid>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: 10 }}>Invoice Amount</TableCell>
              <TableCell style={{ padding: 10 }}>
                Payment Gateway Charges
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                Net Receivable Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 10 }}>
                ₹
                {moneyCommaSeparator(
                  data?.data?.data?.invoiceRemainingAmount || 0
                )}
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                ₹{moneyCommaSeparator(data?.data?.data?.gatewayCharges || 0)}
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                ₹{moneyCommaSeparator(data?.data?.data?.finalGetAmount || 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GKModal>
  );
};

export default SendPaymentModal;
