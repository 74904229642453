/* eslint-disable import/no-named-as-default-member */
import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const ViewQuestionnaire = () => {
  useDocumentTitle("View Questionnaire");

  const { questionnaireId } = useParams();

  const { theme } = ThemeContainer();

  const { data: answer, isFetching } = useFetchQuery({
    key: [QueryKeys.VIEW_QUESTIONNAIRE],
    route: `/single-riskprofile-response/${questionnaireId}/`,
  });

  return (
    <DashboardLayout title="Client Risk Profile">
      <Grid container spacing={1}>
        {isFetching ? (
          <SimpleLoader />
        ) : (
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {answer?.data?.data?.map((dataItem: any, index: number) => {
                    return (
                      <Grid item container key={dataItem?.id}>
                        <Grid item xs={12}>
                          <FormControl>
                            <Typography
                              color={theme.palette.primary.main}
                              fontWeight={800}
                            >
                              <span>{index + 1}. </span>
                              {dataItem.questionText}
                            </Typography>
                            {dataItem?.options?.map((optionItem: any) => {
                              return (
                                <RadioGroup key={optionItem?.id}>
                                  <FormControlLabel
                                    key={optionItem?.id}
                                    checked={dataItem.option === optionItem.id}
                                    control={<Radio />}
                                    label={optionItem?.option}
                                  />
                                </RadioGroup>
                              );
                            })}
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default ViewQuestionnaire;
