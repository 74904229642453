import AddPortfolioModal from "Pages/Portfolio/AddPortfolioModal";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

const ClientModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  return (
    isModalOpen && (
      <AddPortfolioModal setOpen={setIsModalOpen} open={isModalOpen} />
    )
  );
};

export default ClientModal;
