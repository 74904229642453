import { InputLabel, styled } from "@mui/material";
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { FiCalendar } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";
import GKTypography from "./GKTypography";

interface GKDatePickerProps {
  inputLabel?: string;
  id?: string;
  name?: string;
  requiredField?: boolean;
  error?: boolean;
  helperText?: string;
  disableToday?: boolean;
}

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: "12px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline ": {
    border: `solid 1px`,
  },
  "& .MuiOutlinedInput-root": {
    height: "42px !important",
    borderRadius: 8,
    paddingRight: "18px !important",
  },
  "& .MuiFormHelperText-root ": {
    margin: 0,
    color: theme.palette.error.main,
    fontWeight: 400,
  },
  "& .MuiButtonBase-root": {
    background: theme.palette.light.main,
    color: theme.palette.grey[600],
    "&:hover": {
      color: theme.palette.primary.main,
      background: `${theme.palette.light.main}`,
      borderColor: theme.palette.light.main,
    },
  },
}));

const GKDatePicker = (props: GKDatePickerProps & DatePickerProps<any>) => {
  const { theme } = ThemeContainer();
  const {
    inputLabel,
    id,
    name,
    requiredField,
    helperText,
    error,
    disableToday = false,
    minDate,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem
        label={
          inputLabel && (
            <InputLabel
              style={{
                color: "#000000",
                fontSize: "18px",
                fontFamily: "Inter !important",
                textTransform: "capitalize",
              }}
              shrink
              htmlFor={name || id}
            >
              {inputLabel}
              {requiredField && (
                <span
                  style={{
                    color: theme.palette.error.main,
                  }}
                >
                  *
                </span>
              )}
            </InputLabel>
          )
        }
      >
        <CustomDatePicker
          disableFuture
          slots={{
            openPickerIcon: FiCalendar,
          }}
          slotProps={{
            openPickerIcon: {
              fontSize: 20,
              color: "#333333",
            },
          }}
          minDate={minDate || dayjs("01-01-1980")}
          sx={{
            marginTop: inputLabel ? "20px !important" : 0,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error
                ? theme.palette.error.main
                : theme.palette.grey[400],
            },
          }}
          {...props}
          format="DD-MM-YYYY"
          shouldDisableDate={(date: any) =>
            disableToday ? date.isSame(dayjs(), "day") : ""
          }
        />
        {Boolean(helperText) && (
          <GKTypography
            color={theme.palette.error.main}
            fontSize={12}
            fontWeight={550}
            sx={{
              marginTop: "0 !important",
            }}
          >
            {helperText}
          </GKTypography>
        )}
      </DemoItem>
    </LocalizationProvider>
  );
};

export default GKDatePicker;
