import { FormControlLabel, Switch } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import CommonPagination from "components/CommanPagination";
import ExportCsv from "components/ExportCsv";
import GKDataGrid from "components/GKDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { clientPageClientAccess } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioAccess = () => {
  const { id } = useParams();

  useDocumentTitle("Client Access");

  const {
    state: { commonFilter },
  } = useAppContext();

  const [employeeList, setEmployeeList]: any = useState([]);

  const [switchLoading, setSwitchLoading] = useState("");

  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.clientAccess?.take,
    skip: commonFilter?.clientAccess?.skip,
    sort: JSON.stringify(
      commonFilter?.clientAccess?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      })
    ),
    search: commonFilter?.clientAccess?.search || "",
  };

  const { isFetching, refetch, isRefetching, isLoading } = useFetchQuery({
    key: [QueryKeys.CLIENT_ACCESS, requestBody],
    route: `/client/access/${id}/`,
    requestBody,
    success: (response: any) => {
      setEmployeeList(response?.data);
      setSelectedEmployeeList(response?.data?.employees);
    },
  });

  const { mutate: enableDisableCLient, isLoading: enableDisableCLientLodaing } =
    useMutation({
      mutationFn: (arr: any) =>
        axiosInstance.patch(`/client/access/${id}/`, {
          employees: arr,
        }),
      onSuccess: () => {
        refetch();
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again."
        );
      },
    });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={employeeList.totalCount || 0}
        name="clientAccess"
      />
    );
  };

  const exportCsv = () => {
    return (
      <ExportCsv path={`/client/access/${id}`} name={"ClientAccessList"} />
    );
  };

  return (
    <GKDataGrid
      columns={clientPageClientAccess(
        enableDisableCLientLodaing,
        isRefetching,
        isFetching,
        switchLoading,
        selectedEmployeeList,
        setSwitchLoading,
        enableDisableCLient
      )}
      maxHeight={"calc(100vh - 228px)"}
      rows={employeeList?.data || []}
      searchPlaceholder={"Search for Portfolios"}
      loading={selectedEmployeeList?.length === 0 && isFetching}
      headerComponent={
        <FormControlLabel
          control={
            <Switch
              checked={employeeList?.enableAll}
              onChange={() => {
                axiosInstance
                  .patch(`/client/access/${id}/all_employees/`)
                  .then(() => {
                    refetch();
                  })
                  .catch((error) => {
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again."
                    );
                  });
              }}
            />
          }
          label={employeeList?.enableAll ? "Disable All" : "Enable All"}
          labelPlacement="end"
        />
      }
      CustomPagination={!isLoading && cusPagination}
      filterButton={false}
      exportCsv={exportCsv}
      name="clientAccess"
    />
  );
};

export default PortfolioAccess;
