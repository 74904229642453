import { Grid } from "@mui/material";
import DragDrop from "components/DragDrop";
import GKButton from "components/GKButton";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MdOutlineFileDownload } from "react-icons/md";
import { ThemeContainer } from "utils/Theme";

const ImportStep1 = () => {
  const form = useFormContext();
  const { theme } = ThemeContainer();

  const [uploadError, setUploadError] = useState<string | null>(null);

  return (
    <Grid
      item
      xs={12}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
      maxHeight={250}
      px={1}
      overflow={"auto"}
    >
      <Controller
        name="step1.file"
        control={form.control}
        render={({ field }) => (
          <DragDrop
            uploadError={uploadError}
            setUploadError={setUploadError}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            acceptType={".csv, .pdf,.xlsx"}
            supportName="CSV or PDF or XLSX"
          />
        )}
      />
      <GKButton
        sx={{
          p: 0,
          color: theme.palette.grey[700],
          display: "flex",
          alignItems: "center",
          "&:hover": {
            background: "transparent",
          },
        }}
        href="https://frontend-logo.s3.amazonaws.com/Fees-Historical_Import.xlsx?versionId=MqznVA8XujJtYV2cHNZUuockUU3suPU0"
        target="_blank"
        startIcon={<MdOutlineFileDownload size={14} />}
      >
        Download Sample File
      </GKButton>
    </Grid>
  );
};

export default ImportStep1;
